import { Alert, Checkbox, Snackbar } from '@mui/material';
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BsCalendar3, BsCopy } from 'react-icons/bs';
import { FaRegCalendarDays } from 'react-icons/fa6';
import { FcCheckmark, FcHighPriority, FcOk } from 'react-icons/fc';
import { FiMoreVertical, FiPackage } from 'react-icons/fi';
import { HiDocumentText } from 'react-icons/hi2';
import { IoIosArrowForward } from 'react-icons/io';
import { IoCopyOutline, IoLocationSharp } from 'react-icons/io5';
import { MdOutlineClear, MdOutlineTrackChanges, MdOutlineViewInAr } from 'react-icons/md';
import OutsideClickHandler from 'react-outside-click-handler';
import { useNavigate } from 'react-router-dom';

function DashboardBody({
  rows,
  row,
  getTableBodyProps,
  handleSelectedShipment,
  handleSelectedContextMenu,
  index,
  handleCloseContextMenu,
  setSelectedShipment,
  handleShowShipment,
}) {
  const [displayCopy, setDisplayCopy] = useState(false);
  const [copyText, setCopyText] = useState('');
  const navigate = useNavigate();
  function resetCopy(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setDisplayCopy(false);
  }
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        handleCloseContextMenu(row.original.hawb);
      }}
    >
      <div
        className={`manifest-tr ${
          row.original.select
            ? 'bg-blue-500 hover:bg-blue-600 active:bg-blue-700'
            : 'hover:bg-slate-100 active:bg-slate-200 text-popup'
        } `}
      >
        {displayCopy && (
          <Snackbar
            open={displayCopy}
            autoHideDuration={5000}
            onClose={resetCopy}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert elevation={5} variant="filled" onClose={resetCopy} severity="success">
              Copied {copyText}
            </Alert>
          </Snackbar>
        )}
        <div
          className={`${
            row.original.contextMenu ? 'search-column-input1 z-20' : ''
          } sticky left-0 bg-white z-10 flex items-center shadow-[rgba(0,0,0,0.15)_2.4px_2.4px_3.2px]`}
          style={{ border: '1px solid lightgray', borderRight: 'none' }}
        >
          <Checkbox
            color="primary"
            style={{ padding: 7, margin: 1, height: 44 }}
            checked={row.original.select}
            onChange={(e) => {
              let value = e.target.checked;
              handleSelectedShipment(rows, row.id, value);
            }}
          />
          <div
            className={`w-[30px] h-[30px] ${
              row.original.contextMenu
                ? 'bg-[darkblue] hover:opacity-70 active:opacity-50 text-white'
                : 'bg-slate-100 hover:bg-slate-200 active:bg-slate-300'
            }  mr-1 cursor-pointer flex items-center justify-center rounded-full`}
            onClick={() => {
              handleSelectedContextMenu(row.original.hawb);
            }}
          >
            <FiMoreVertical size={25} />
          </div>
          {row.original.contextMenu && (
            <div className="absolute bg-white w-[225px] z-[9999] ml-[320px] shadow-[rgba(136,165,191,0.48)_6px_2px_16px_0px,rgba(255,255,255,0.8)_-6px_-2px_16px_0px] mt-[130px] border-slate-200 border-solid border-[1px] search-column-input1">
              <div className="flex flex-col p-1.5">
                <p className="p-1 text-lg font-semibold tracking-wide" style={{ borderBottom: '1px solid' }}>
                  {row.original.hawb}
                </p>
                <div
                  className="p-1 px-2.5 flex items-center select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300 "
                  onClick={() => {
                    handleShowShipment(row.original);
                  }}
                >
                  <MdOutlineViewInAr size={23} />
                  <p className="mx-1.5">View shipment</p>
                  <IoIosArrowForward size={23} className="ml-auto" />
                </div>
                <div
                  className="p-1 px-2.5 flex items-center select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300"
                  style={{ borderBottom: '1px solid' }}
                  onClick={() => {
                    navigate(`/tracking-result/${row.original.hawb}`);
                  }}
                >
                  <MdOutlineTrackChanges size={23} />

                  <p className="mx-1.5">Track shipment</p>
                </div>
                <CopyToClipboard
                  onCopy={() => {
                    setCopyText(row.original.hawb);
                    setDisplayCopy(true);
                    handleCloseContextMenu(row.original.hawb);
                  }}
                  text={row.original.hawb}
                >
                  <div className="p-1 px-2.5 flex items-center select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300">
                    <IoCopyOutline size={23} />
                    <p className="mx-1.5">Copy shipment</p>
                  </div>
                </CopyToClipboard>
                <div
                  className="p-1 px-2.5 flex items-center select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300"
                  onClick={() => handleCloseContextMenu(row.original.hawb)}
                >
                  <MdOutlineClear size={23} className="text-rose-500" />
                  <p className="mx-1.5">Close context</p>
                </div>
              </div>
            </div>
          )}
          <div
            className="flex items-center w-[50px]"
            style={{
              borderRight: '1px solid rgb(227, 227, 227)',
              borderLeft: '1px solid rgb(227, 227, 227)',
              padding: 11,
              fontSize: 17,
            }}
          >
            <p className="text-center w-full">{index}</p>
          </div>
          <div
            className="flex items-center w-[190px]"
            style={{
              borderRight: '1px solid rgb(227, 227, 227)',
              borderLeft: '1px solid rgb(227, 227, 227)',
              padding: 11,
              fontSize: 17,
            }}
          >
            <p className="tracking-wide text-[darkblue]">{row.original.hawb}</p>
          </div>
        </div>
        <div {...getTableBodyProps}>
          <div {...row.getRowProps()} className="tr3">
            <div style={{ display: 'flex' }}>
              {row.cells.map((cell, index) => {
                return (
                  <div>
                    {cell.column.active && (
                      <div {...cell.getCellProps()} className={`td flex items-center ${index === 0 ? '' : ''} `}>
                        {index === 4 && (
                          <img
                            src={`https://flagsapi.com/${cell.row.original.code}/shiny/64.png`}
                            className="w-[25px] h-[25px] mr-2"
                          />
                        )}
                        {index === 5 && <IoLocationSharp size={25} className="mr-2 text-slate-500" />}
                        {index === 8 && cell.row.original.shipmentType === 'WPX' && (
                          <FiPackage size={25} className="mr-2 text-slate-500" />
                        )}
                        {index === 8 && cell.row.original.shipmentType === 'DOX' && (
                          <HiDocumentText size={25} className="mr-2 text-slate-500" />
                        )}
                        {index !== 1 && (
                          <p
                            style={
                              cell.row.original.select
                                ? { color: 'white', fontWeight: 600, textAlign: index === 0 ? 'center' : '' }
                                : index === 2 && cell.row.original.service === 'DUS'
                                  ? {
                                      fontWeight: 600,
                                      color: 'white',
                                      background: 'black',
                                      textAlign: 'center',
                                      borderRadius: 3,
                                    }
                                  : index === 2 && cell.row.original.service === 'UPS'
                                    ? {
                                        color: 'rgb(247,202,136)',
                                        background: 'brown',
                                        textAlign: 'center',
                                        fontWeight: 600,
                                        borderRadius: 3,
                                      }
                                    : index === 2 && cell.row.original.service === 'FIP'
                                      ? {
                                          background: 'purple',
                                          color: 'white',
                                          textAlign: 'center',
                                          fontWeight: 600,
                                          borderRadius: 3,
                                        }
                                      : index === 2 && cell.row.original.service === 'FIE'
                                        ? {
                                            background: 'purple',
                                            color: 'white',
                                            textAlign: 'center',
                                            fontWeight: 600,
                                            borderRadius: 3,
                                          }
                                        : index === 2 && cell.row.original.service === 'F3P'
                                          ? {
                                              background: 'purple',
                                              color: 'white',
                                              textAlign: 'center',
                                              fontWeight: 600,
                                              borderRadius: 3,
                                            }
                                          : index === 2 && cell.row.original.service === 'DHL-VN'
                                            ? {
                                                borderRadius: 3,
                                                fontWeight: 600,
                                                background: 'yellow',
                                                color: 'red',
                                                textAlign: 'center',
                                              }
                                            : index === 2 && cell.row.original.service === 'DHL'
                                              ? {
                                                  borderRadius: 3,
                                                  fontWeight: 600,
                                                  background: 'yellow',
                                                  color: 'red',
                                                  textAlign: 'center',
                                                }
                                              : {}
                            }
                            className={
                              index === 0
                                ? 'text-slate-600 underline'
                                : index === 5
                                  ? 'w-full overflow-hidden text-ellipsis whitespace-nowrap'
                                  : index === 6
                                    ? ''
                                    : index === 9
                                      ? 'text-emerald-600 underline'
                                      : index === 12
                                        ? 'text-rose-600'
                                        : index === 8
                                          ? ''
                                          : '' + 'text-black w-full overflow-hidden text-ellipsis whitespace-nowrap'
                            }
                          >
                            {cell.value} {index === 9 ? 'KG' : index === 10 ? 'PCS' : index === 12 ? 'USD' : ''}
                          </p>
                        )}
                        {index === 1 && (
                          <BsCalendar3
                            size={25}
                            className="mr-2"
                            style={
                              cell.row.original.select
                                ? { color: 'white', fontWeight: 600, textAlign: index === 0 ? 'center' : '' }
                                : {}
                            }
                          />
                        )}
                        {index === 1 && (
                          <p
                            className="w-full overflow-hidden text-ellipsis whitespace-nowrap"
                            style={
                              cell.row.original.select
                                ? { color: 'white', fontWeight: 600, textAlign: index === 0 ? 'center' : '' }
                                : {}
                            }
                          >
                            {cell.value}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          className="sticky right-0 bg-white z-10 flex items-center shadow-[rgba(136,165,191,0.48)_6px_2px_16px_0px,rgba(255,255,255,0.8)_-6px_-2px_16px_0px]"
          style={{ borderBottom: '1px solid rgb(220,220,220)' }}
        >
          <div className="w-[30px] h-[30px] mx-1.5 flex items-center justify-center">
            {row.original.booked === 'false' && (
              <FcHighPriority size={30} className="bg-rose-50 text-rose-500 rounded-full p-1" />
            )}
            {row.original.booked === 'true' && (
              <FcCheckmark size={30} className="bg-emerald-50 text-emerald-500 rounded-full p-1" />
            )}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
}
export default React.memo(DashboardBody, (prevProps, nextProps) => nextProps.isColumnResizing);
