import { Alert, Checkbox, IconButton, LinearProgress, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaFilterCircleXmark, FaSortDown, FaSortUp } from 'react-icons/fa6';
import NewBookingBody from './NewBookingBody';
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs';
import './NewBookingFilterBar.css';
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdKeyboardArrowRight,
  MdAutoFixHigh,
  MdOutlineClear,
  MdOutlineViewInAr,
  MdOutlineTrackChanges,
} from 'react-icons/md';
import { FcCheckmark, FcHighPriority, FcOk } from 'react-icons/fc';
import { FiMoreVertical } from 'react-icons/fi';
import OutsideClickHandler from 'react-outside-click-handler';
import { PiColumnsLight } from 'react-icons/pi';
import { AiOutlineSortAscending } from 'react-icons/ai';
import { IoIosArrowForward } from 'react-icons/io';
import { FaRegEdit } from 'react-icons/fa';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IoCopyOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

export default function NewBookingGrid({
  loading,
  headerGroups,
  rows,
  prepareRow,
  onSelectingEdit,
  handleSelectedShipment,
  getTableBodyProps,
  isColumnResizing,
  handleSelectedAllShipment,
  renderPageNumbers,
  handlePrevPage,
  handleNextPage,
  handleFirstPage,
  handleLastPage,
  pages,
  currentPage,
  handleSelectedContext,
  handleCloseContext,
  itemPerPage,
  setItemPerPage,
  handleChangeInputColumn,
  handleResetSearchColumn,
  searchCus,
  searchClass,
  searchHawb,
  searchCountry,
  searchService,
  searchType,
  handleSelectedAllSign,
  searchWeight,
  searchPcs,
  searchDescription,
  searchSin,
  handleSelectedType,
  searchSale,
  searchOps,
  searchCode,
  handleChangeCode,
  duplicateWeight,
  duplicateCode,
  handleSelectedHeader,
  handleSortShipmentColumn,
  handleSelectedContextMenu,
  handleCloseContextMenu,
  handleShowShipmentLabel,
}) {
  const [showEmpty, setShowEmpty] = useState(false);
  const [showSelectOption, setShowSelectOption] = useState(false);
  const [copyText, setCopyText] = useState(false);
  const [displayCopy, setDisplayCopy] = useState(false);
  const navigate = useNavigate();
  const onShowNoneShipment = () => {
    setTimeout(() => {
      setShowEmpty(true);
    }, 5000);
  };
  useEffect(() => {
    if (rows.length === 0) {
      setTimeout(() => {
        setShowEmpty(true);
      }, 5000);
    } else {
      setShowEmpty(false);
    }
  }, [rows]);
  function resetCopy(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setDisplayCopy(false);
  }
  return (
    <div className="mt-1" style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
      {displayCopy && (
        <Snackbar
          open={displayCopy}
          autoHideDuration={5000}
          onClose={resetCopy}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert elevation={5} variant="filled" onClose={resetCopy} severity="success">
            Copied {copyText}
          </Alert>
        </Snackbar>
      )}
      <div className="table-wrapper">
        <div className="flex items-center sticky top-0 bg-white z-[9998] w-fit shadow-[rgba(0,0,0,0.15)_2.4px_2.4px_3.2px]">
          <div
            className="sticky left-0 z-[9999] bg-white flex items-center shadow-[rgba(0,0,0,0.15)_2.4px_0px_0px]"
            style={{ border: '1px solid lightgray', borderBottom: 'none', borderRight: 'none' }}
          >
            <div className=" flex items-center">
              <Checkbox
                style={{ padding: 11, margin: 1, height: 50 }}
                onChange={(e) => {
                  handleSelectedAllShipment(e);
                }}
              />
              <OutsideClickHandler
                onOutsideClick={() => {
                  setShowSelectOption(false);
                }}
              >
                <div className="relative">
                  <FiMoreVertical
                    size={25}
                    className="rounded-full hover:bg-slate-200 active:bg-slate-300 active:scale-90 cursor-pointer"
                    onClick={() => setShowSelectOption(!showSelectOption)}
                  />
                  {showSelectOption && (
                    <div className="absolute bg-white z-10 w-[230px] shadow-[rgba(0,0,0,0.1)_0px_10px_15px_-3px,rgba(0,0,0,0.15)_0px_4px_6px_-2px] mt-2 rounded-[10px]">
                      <div
                        className="p-1.5 m-1 rounded-[10px] cursor-pointer select-none hover:bg-slate-200 active:bg-slate-300"
                        onClick={() => {
                          handleSelectedType('DOX');
                          setShowSelectOption(false);
                        }}
                      >
                        <p>Select all DOX</p>
                      </div>
                      <div
                        className="p-1.5 m-1 rounded-[10px] cursor-pointer select-none hover:bg-slate-200 active:bg-slate-300"
                        onClick={() => {
                          handleSelectedType('WPX');
                          setShowSelectOption(false);
                        }}
                      >
                        <p>Select all WPX</p>
                      </div>
                      <div
                        className="p-1.5 m-1 rounded-[10px] cursor-pointer select-none hover:bg-slate-200 active:bg-slate-300"
                        onClick={() => {
                          handleSelectedType('DUS');
                          setShowSelectOption(false);
                        }}
                      >
                        <p>Select all DUS</p>
                      </div>
                      <div
                        className="p-1.5 m-1 rounded-[10px] cursor-pointer select-none hover:bg-slate-200 active:bg-slate-300"
                        onClick={() => {
                          handleSelectedType('CurrentPage');
                          setShowSelectOption(false);
                        }}
                      >
                        <p>Select all only current page</p>
                      </div>
                      <div
                        className="p-1.5 m-1 rounded-[10px] cursor-pointer select-none hover:bg-slate-200 active:bg-slate-300"
                        onClick={() => {
                          handleSelectedType('All');
                          setShowSelectOption(false);
                        }}
                      >
                        <p>Select all shipments</p>
                      </div>
                    </div>
                  )}
                </div>
              </OutsideClickHandler>
              <div className="w-[45px]"></div>
            </div>
          </div>
          {headerGroups.map((headerGroup) => (
            <div
              {...headerGroup.getHeaderGroupProps({
                // style: { paddingRight: '15px' },
              })}
              className="tr"
            >
              {headerGroup.headers.map((column, index) => (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    handleCloseContext(headerGroup, column.id, column.active);
                  }}
                >
                  <div className="relative">
                    {column.active && (
                      <div
                        {...column.getHeaderProps()}
                        className="th flex items-center justify-between text-gray-600 relative"
                      >
                        {index === 1 && searchClass !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 2 && searchCus !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 3 && searchHawb !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 5 && searchCountry !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 6 && searchService !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 7 && searchType !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 8 && searchWeight !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 9 && searchPcs !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 10 && searchDescription !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 13 && searchSin !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 14 && searchSale !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 15 && searchOps !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        <p
                          style={
                            index + 1 === 1
                              ? { color: 'black', textAlign: 'center' }
                              : { color: 'black', textAlign: 'left' }
                          }
                          className="elipe-text"
                        >
                          {column.render('Header')}
                        </p>
                        <div
                          className="w-[30px] h-[30px] flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 active:scale-90 rounded-full cursor-pointer"
                          onClick={() => handleSelectedContext(headerGroup, column.id, column.active)}
                        >
                          {column.context ? (
                            <FaSortUp size={28} className="mt-[14px]" />
                          ) : (
                            <FaSortDown size={28} className="mt-[-10px]" />
                          )}
                        </div>
                        {column.canResize && (
                          <div
                            {...column.getResizerProps()}
                            className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                          />
                        )}
                      </div>
                    )}
                    {column.context && (
                      <div className="absolute bg-white w-[320px] z-[9999] mt-1.5 rounded-[10px] shadow-[rgba(0,0,0,0.1)_0px_10px_15px_-3px,rgba(0,0,0,0.15)_0px_4px_6px_-2px]">
                        <p className="p-[10px] text-slate-500 tracking-wide text-[15px]">
                          <span className="underline font-bold text-black">{column.render('Header')}</span>'s context
                          menu
                        </p>
                        <div className="p-2" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
                          {index !== 0 && index !== 4 && index !== 8 && index !== 11 && index !== 12 && (
                            <div className="relative">
                              <input
                                type="text"
                                placeholder="Enter search value"
                                className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input"
                                value={
                                  index === 1
                                    ? searchClass
                                    : index === 2
                                      ? searchCus
                                      : index === 3
                                        ? searchHawb
                                        : index === 5
                                          ? searchCountry
                                          : index === 6
                                            ? searchService
                                            : index === 7
                                              ? searchType
                                              : index === 8
                                                ? searchWeight
                                                : index === 9
                                                  ? searchPcs
                                                  : index === 10
                                                    ? searchDescription
                                                    : index === 13
                                                      ? searchSin
                                                      : index === 14
                                                        ? searchSale
                                                        : index === 15
                                                          ? searchOps
                                                          : ''
                                }
                                onChange={(e) => handleChangeInputColumn(index, e)}
                                autoFocus
                              />

                              <div
                                className="absolute right-1 w-[25px] h-[25px] rounded-full flex items-center justify-center top-[5.5px] cursor-pointer hover: bg-slate-100 active:bg-slate-200 active:scale-90"
                                onClick={() => {
                                  handleResetSearchColumn(index);
                                }}
                              >
                                <MdOutlineClear size={23} />
                              </div>
                            </div>
                          )}
                          {index === 5 && (
                            <div>
                              <p className="p-1 mt-1 text-slate-600">Search by country code:</p>
                            </div>
                          )}
                          {index === 5 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchCode}
                              onChange={(e) => handleChangeCode(e)}
                            >
                              <option value="">Select value</option>
                              {duplicateCode
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 8 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchWeight}
                              onChange={(e) => handleChangeInputColumn(index, e)}
                            >
                              <option value="">Select value</option>
                              {duplicateWeight
                                .sort((x, y) => {
                                  if (x > 0 && y > 0) {
                                    return x - y;
                                  }
                                  return y - x;
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                        </div>
                        <div className="mx-1 pb-1.5">
                          <div
                            className="flex items-center p-1 m-0.5 rounded-[10px] hover:bg-slate-100 active:bg-slate-200 cursor-pointer select-none"
                            onClick={() => {
                              handleSortShipmentColumn(index);
                              handleCloseContext(headerGroup, column.id, column.active);
                            }}
                          >
                            <AiOutlineSortAscending size={27} />
                            <p className="ml-1.5">Sort {column.render('Header')}</p>
                          </div>
                          <div
                            className="flex items-center p-1 m-0.5 rounded-[10px] hover:bg-slate-100 active:bg-slate-200 cursor-pointer select-none"
                            onClick={() => {
                              handleSelectedHeader(headerGroup, column.id, column.active);
                              handleCloseContext(headerGroup, column.id, column.active);
                            }}
                          >
                            <PiColumnsLight size={27} />

                            <p className="ml-1.5">Hide column</p>
                          </div>
                          <div
                            className="flex items-center p-1 m-0.5 rounded-[10px] hover:bg-slate-100 active:bg-slate-200 cursor-pointer select-none"
                            onClick={() => {
                              handleCloseContext(headerGroup, column.id, column.active);
                            }}
                          >
                            <MdOutlineClear size={27} />

                            <p className="ml-1.5">Close context</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </OutsideClickHandler>
              ))}
            </div>
          ))}
        </div>
        {rows?.length === 0 && (
          <div className="flex items-center justify-center h-full text-xl tracking-wide flex-col">
            <p className={`${showEmpty ? 'text-slate-400' : ''}`}>System is looking for shipments, please wait...</p>
            {onShowNoneShipment()}
            {showEmpty && (
              <div className="flex flex-col items-center">
                <p>Seems like we can't find any shipment recently</p>
                <button
                  type="button"
                  className="p-2 bg-sky-50 text-sky-600 font-bold rounded-[12px] px-7 cursor-pointer hover:bg-sky-100 active:bg-sky-200 my-2.5 mt-3.5 flex items-center"
                  onClick={() => window.location.reload()}
                >
                  <MdAutoFixHigh size={30} className="mr-2" />
                  Retry now
                </button>
              </div>
            )}
          </div>
        )}
        {rows.length !== 0 &&
          rows.map((row, index) => {
            prepareRow(row);
            return (
              <div
                className={`manifest-tr ${
                  row.original.select
                    ? 'bg-blue-500 hover:bg-blue-600 active:bg-blue-700'
                    : row.original.deliverySign
                      ? 'bg-emerald-600 active:bg-emerald-700'
                      : 'hover:bg-slate-100 active:bg-slate-200'
                }`}
              >
                <div
                  className="sticky left-0 bg-white z-[8888] flex items-center shadow-[rgba(0,0,0,0.15)_2.4px_2.4px_3.2px]"
                  style={{ border: '1px solid lightgray', borderRight: 'none' }}
                >
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      handleCloseContextMenu(row.original.hawb);
                    }}
                  >
                    <div className={` flex items-center ${row.original.contextMenu ? 'search-column-input1' : ''}`}>
                      <Checkbox
                        color="primary"
                        style={{ padding: 11, margin: 1 }}
                        checked={row.original.select}
                        onChange={(e) => {
                          let value = e.target.checked;
                          handleSelectedShipment(rows, row.id, value);
                        }}
                      />

                      <FiMoreVertical
                        size={25}
                        className="rounded-full hover:bg-slate-200 active:bg-slate-300 active:scale-90 cursor-pointer"
                        onClick={() => handleSelectedContextMenu(row.original.hawb)}
                      />
                      <div className="w-[45px] flex items-center justify-center">
                        {row.original.booked === 'true' && (
                          <div className="w-[30px] h-[30px] rounded-full bg-emerald-50 flex items-center justify-center skd-import-container-popup">
                            <FcCheckmark size={25} />
                          </div>
                        )}
                        {row.original.booked === 'false' && (
                          <div className="w-[30px] h-[30px] rounded-full bg-rose-50 flex items-center justify-center skd-import-container-popup">
                            <FcHighPriority size={25} />
                          </div>
                        )}
                      </div>
                      {row.original.contextMenu && (
                        <div className="absolute bg-white w-[225px] z-[9999] ml-[128px] shadow-[rgba(136,165,191,0.48)_6px_2px_16px_0px,rgba(255,255,255,0.8)_-6px_-2px_16px_0px] mt-[175.5px] border-slate-200 border-solid border-[1px] search-column-input1">
                          <div className="flex flex-col p-1.5">
                            <p
                              className="p-1 text-lg font-semibold tracking-wide"
                              style={{ borderBottom: '1px solid' }}
                            >
                              {row.original.hawb}
                            </p>
                            <div
                              className="p-1 px-2.5 flex items-center select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300 mt-1.5"
                              onClick={() => {
                                handleShowShipmentLabel(row.original);
                              }}
                            >
                              <MdOutlineViewInAr size={23} />
                              <p className="mx-1.5">View shipment</p>
                              <IoIosArrowForward size={23} className="ml-auto" />
                            </div>
                            <div
                              className="p-1 px-2.5 flex items-center select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300 "
                              onClick={() => {
                                onSelectingEdit(row.original);
                              }}
                            >
                              <FaRegEdit size={23} />
                              <p className="mx-1.5">Edit shipment</p>
                            </div>
                            <div
                              className="p-1 px-2.5 flex items-center select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300"
                              style={{ borderBottom: '1px solid' }}
                              onClick={() => {
                                navigate(`/tracking-result/${row.original.hawb}`);
                              }}
                            >
                              <MdOutlineTrackChanges size={23} />

                              <p className="mx-1.5">Track shipment</p>
                            </div>
                            <CopyToClipboard
                              onCopy={() => {
                                setCopyText(row.original.hawb);
                                setDisplayCopy(true);
                                handleCloseContextMenu(row.original.hawb);
                              }}
                              text={row.original.hawb}
                            >
                              <div className="p-1 px-2.5 flex items-center select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300 mt-1.5">
                                <IoCopyOutline size={23} />
                                <p className="mx-1.5">Copy shipment</p>
                              </div>
                            </CopyToClipboard>
                            <div
                              className="p-1 px-2.5 flex items-center select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300"
                              onClick={() => handleCloseContextMenu(row.original.hawb)}
                            >
                              <MdOutlineClear size={23} className="text-rose-500" />
                              <p className="mx-1.5">Close context</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </OutsideClickHandler>
                </div>
                <NewBookingBody
                  onSelectingEdit={onSelectingEdit}
                  getTableBodyProps={getTableBodyProps}
                  prepareRow={prepareRow}
                  isColumnResizing={isColumnResizing}
                  row={row}
                  id={index}
                />
              </div>
            );
          })}
      </div>
      <div className="flex items-center" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
        <p className="m-[9px] text-slate-600 tracking-wide select-none">
          Skydart Express © 2024 | <span className="text-black">New Booking</span>
        </p>
        <p>|</p>
        <div className="ml-1.5 flex items-center group cursor-pointer ">
          <div
            className="w-[45px] h-[30px] rounded-[5px] bg-emerald-600 hover:bg-emerald-500 active:scale-90"
            onClick={() => handleSelectedAllSign()}
          ></div>

          <div className="hidden group-hover:block absolute p-1 bg-white shadow-[rgba(17,17,26,0.1)_0px_4px_16px,rgba(17,17,26,0.1)_0px_8px_24px,rgba(17,17,26,0.1)_0px_16px_56px] w-[300px] mb-[222px] rounded-[5px]">
            <div className="p-1.5">
              <p className="text-lg tracking-wide font-semibold text-emerald-600">Vận đơn nền màu xanh:</p>
              <p className="text-[16px]">
                Là những lô hàng được FWD đăng ký sử dụng dịch vụ ký tên xác nhận sau khi nhận hàng ở phía người nhận,
                anh chị hãy chọn đại lý phù hợp cho những lô hàng được đánh dấu.
              </p>
            </div>
          </div>
        </div>
        <select
          value={itemPerPage}
          className="p-[5px] ml-2 hover:bg-slate-200 active:bg-slate-300 cursor-pointer bg-slate-100 rounded-[5px] outline-none"
          onChange={(e) => setItemPerPage(e.target.value)}
        >
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="70">70</option>
          <option value="100">100</option>
          <option value="1000">1000</option>
        </select>
        <div className="paginate">
          <IconButton
            type="button"
            disabled={currentPage == pages[0] ? true : false}
            style={{ width: 35, height: 35 }}
            onClick={() => handleFirstPage()}
          >
            <MdKeyboardDoubleArrowLeft className="prev-btn-page" size={40} />
          </IconButton>
          <IconButton
            type="button"
            disabled={currentPage == pages[0] ? true : false}
            style={{ width: 35, height: 35 }}
            onClick={() => handlePrevPage()}
          >
            <MdKeyboardArrowLeft className="prev-btn-page" size={40} />
          </IconButton>
          {renderPageNumbers}
          <IconButton
            type="button"
            disabled={currentPage == pages[pages.length - 1] ? true : false}
            style={{ width: 35, height: 35 }}
            onClick={() => handleNextPage()}
          >
            <MdKeyboardArrowRight className="prev-btn-page" />
          </IconButton>
          <IconButton
            type="button"
            disabled={currentPage == pages[pages.length - 1] ? true : false}
            style={{ width: 35, height: 35 }}
            onClick={() => handleLastPage()}
          >
            <MdKeyboardDoubleArrowRight className="prev-btn-page" />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
