import React, { useState, useEffect } from 'react';
import { IoPerson, IoSettings } from 'react-icons/io5';
import { MdDarkMode, MdLightMode, MdAutoAwesome } from 'react-icons/md';
import skydartLogo from '../../skydartLogo.png';
import { useNavigate } from 'react-router-dom';
import { NavLink, useLocation } from 'react-router-dom';
import { FaDatabase, FaSortDown } from 'react-icons/fa6';
import { FaSignOutAlt } from 'react-icons/fa';
import { auth } from '../../server/firebase';
import OutsideClickHandler from 'react-outside-click-handler';
import { GiPull } from 'react-icons/gi';
import { GrDatabase } from 'react-icons/gr';
import { PiPackage } from 'react-icons/pi';
import { BsFillCloudyFill } from 'react-icons/bs';
export default function SkydartHeader({ theme, handleSelectTheme, user, handleShowPullShipment }) {
  // Variable declared
  const [routes, setRoutes] = useState([]);
  const navigate = useNavigate();
  const [themesList, setThemesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayUserOption, setDisplayUserOption] = useState(false);
  const { pathname } = useLocation();

  // Get all fixed array
  useEffect(() => {
    const navArrays = [
      { label: 'New Booking', id: 0, path: '/new-booking' },
      { label: 'Shipments', id: 1, path: '/shipments' },
      { label: 'Packing', id: 2, path: '/packing' },
      { label: 'Invoices', id: 3, path: '/invoices' },
      { label: 'Customers', id: 4, path: '/customers' },
      { label: 'Transactions', id: 5, path: '/transaction' },
      // { label: 'Debit list', id: 5, path: '/debit' },
      // { label: 'Report Error', id: 6, path: '/report' },
      // { label: 'Market', id: 7, path: '/market' },
    ];

    const themeArrays = [
      { label: 'dark', id: 0, icon: <MdDarkMode size={28} /> },
      { label: 'light', id: 1, icon: <MdLightMode size={28} /> },
      { label: 'auto', id: 2, icon: <MdAutoAwesome size={28} /> },
    ];
    setRoutes(navArrays);
    setThemesList(themeArrays);
  }, []);

  // Show & Hide user option

  const handleUserOption = () => {
    setDisplayUserOption(!displayUserOption);
  };

  // Active theme css

  const inActive =
    'p-2 select-none font-semibold transition-[0.1s] cursor-pointer group hover:text-black text-slate-500 active:opacity-50';
  const active =
    'p-2 select-none font-semibold transition-[0.1s] cursor-pointer group active:opacity-50 text-emerald-600 border-l-2 border-t-0 border-r-0 border-b-0 border-emerald-500';

  // const newRoutes = routes.map((s) => {
  //   if (user.role === 'CEO') {

  //   }
  // })

  return (
    <div
      className="p-2 bg-white border-solid flex items-center sticky top-0 z-10"
      style={{ borderBottom: '1px solid rgb(230,230,230)' }}
    >
      <div className="flex w-[250px] items-center">
        <img
          src={skydartLogo}
          alt="skydart-logo"
          loading="lazy"
          className="w-[40px] h-[40px] select-none cursor-pointer object-contain"
          onClick={() => navigate('/')}
        />
        <h3 className="text-blue-900 font-semibold text-[20px] tracking-wide leading-5 ml-2">SKYDART EXPRESS</h3>
      </div>
      <div className="flex responsive-hidden">
        {routes.map((item) => (
          <>
            <NavLink className={pathname === item.path ? active : inActive} key={item.id} to={item.path}>
              <p className="group-hover:underline mx-1.5">{item.label}</p>
            </NavLink>
          </>
        ))}
        {/* <div className={inActive}>
          <p>Track</p>
        </div> */}
      </div>
      <div className="flex ml-auto">
        <div
          className="p-1.5 text-white rounded-[7px] font-semibold tracking-wide px-3.5 flex items-center main-1 mr-[7px] select-none cursor-pointer hover:opacity-70 active:opacity-50 group"
          onClick={() => handleShowPullShipment()}
        >
          <BsFillCloudyFill size={23} />
          <div className="absolute bg-white text-black mt-[75px] z-10 ml-[-44px] p-1 px-3 rounded-[7px] shadow-[rgba(50,50,105,0.15)_0px_2px_5px_0px,rgba(0,0,0,0.15)_0px_1px_1px_0px] hidden group-hover:block transition-[0.1s]">
            <p className="text-[13px] tracking-wide">Pull shipment</p>
          </div>
        </div>
        <OutsideClickHandler
          onOutsideClick={() => {
            setDisplayUserOption(false);
          }}
        >
          <div className="relative">
            <div
              className=" flex items-center transition-[0.1s] cursor-pointer hover:bg-slate-200 active:bg-slate-300 p-1.5 bg-white rounded-[7px] border-solid border-slate-200 border-[1px]"
              onClick={handleUserOption}
            >
              <IoPerson size={23} className="ml-1" />
              <p className="tracking-wide mx-2 text-[16px] select-none">{user?.displayName}</p>
              <FaSortDown size={23} className="mt-[-10px]" />
            </div>
            {displayUserOption && (
              <div className="absolute mt-2 bg-white shadow-[rgba(0,0,0,0.25)_0px_0.0625em_0.0625em,rgba(0,0,0,0.25)_0px_0.125em_0.5em,rgba(255,255,255,0.1)_0px_0px_0px_1px_inset] z-10 right-0 w-[250px] rounded-[10px]">
                <div className="m-1 p-1.5 hover:bg-slate-100 rounded-[10px] select-none cursor-pointer active:bg-slate-200 flex items-center">
                  <IoSettings size={23} />
                  <p className="ml-2">Setting account</p>
                </div>
                <div
                  className="m-1 p-1.5 hover:bg-slate-100 rounded-[10px] select-none cursor-pointer active:bg-slate-200 flex items-center"
                  onClick={() => {
                    auth.signOut();
                    navigate('/');
                  }}
                >
                  <FaSignOutAlt size={23} />

                  <p className="ml-2">Sign out</p>
                </div>
              </div>
            )}
          </div>
        </OutsideClickHandler>

        {/* <div className="flex bg-slate-100 p-0.5 ml-3 rounded-[10px]">
                    {themesList.map((item) => (
                        <div className={theme === item.label ? activeTheme : unActiveTheme} onClick={() => handleSelectTheme(item.label)}>
                            {item.icon}
                        </div>
                    ))}
                </div> */}
      </div>
    </div>
  );
}
