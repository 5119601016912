import { CircularProgress, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { MdOutlineClear } from 'react-icons/md';
import OutsideClickHandler from 'react-outside-click-handler';

export default function SurchargeScreen({ user, handleCloseSurcharge, surchargeData, loading, handleSurcharge }) {
  const [fetchDhl, setFetchDhl] = useState('');
  const [fetchFedex, setFetchFedex] = useState('');
  const [fetchUps, setFetchUps] = useState('');
  const [exchangeCurrency, setExchangeCurrency] = useState('');

  useEffect(() => {
    if (surchargeData) {
      setFetchDhl(surchargeData.dhl);
      setFetchFedex(surchargeData.fedex);
      setFetchUps(surchargeData.ups);
      setExchangeCurrency(surchargeData.exchange);
    }
  }, [surchargeData]);
  return (
    <div className="skydart-create-print">
      <OutsideClickHandler
        onOutsideClick={() => {
          handleCloseSurcharge();
        }}
      >
        <div className="shipment-note-container relative" style={{ marginTop: '14%' }}>
          {loading && (
            <div className="absolute top-0 right-0 left-0 bottom-0 bg-[rgba(0,0,0,0.55] flex items-center justify-center z-10">
              <div className="w-[150px] h-[120px] rounded-[10px] bg-white flex items-center flex-col justify-center shadow-[rgba(0,0,0,0.1)_0px_10px_15px_-3px,rgba(0,0,0,0.05)_0px_4px_6px_-2px]">
                <CircularProgress size={25} />
                <p className="mt-2">Loading</p>
              </div>
            </div>
          )}
          <div className="skydart-create-surcharge">
            <h3 style={{ fontWeight: 600, fontSize: 20 }}>Update fuel charge and currency exchange</h3>
            <IconButton
              onClick={() => handleCloseSurcharge()}
              type="button"
              style={{ width: 35, height: 35, background: 'rgb(230,230,230)' }}
            >
              <MdOutlineClear style={{ fontSize: 18 }} />
            </IconButton>
          </div>
          <div className="skydart-create-surcharge-container">
            <div className="skydart-create-surcharge-container-header">
              <div className="skydart-create-surcharge-option">
                <h5>DHL FSC</h5>
                <input
                  type="number"
                  disabled={user.role === 'Pricing' ? false : user.role === 'CEO' ? false : true}
                  value={fetchDhl}
                  onChange={(e) => setFetchDhl(e.target.value)}
                  placeholder="DHL fuel surcharge"
                />
              </div>
              <div className="skydart-create-surcharge-option">
                <h5>Fedex FSC</h5>
                <input
                  type="number"
                  disabled={user.role === 'Pricing' ? false : user.role === 'CEO' ? false : true}
                  value={fetchFedex}
                  onChange={(e) => setFetchFedex(e.target.value)}
                  placeholder="FEDEX fuel surcharge"
                />
              </div>
              <div className="skydart-create-surcharge-option">
                <h5>UPS FSC</h5>
                <input
                  type="number"
                  disabled={user.role === 'Pricing' ? false : user.role === 'CEO' ? false : true}
                  value={fetchUps}
                  onChange={(e) => setFetchUps(e.target.value)}
                  placeholder="UPS fuel surcharge"
                />
              </div>
              <div className="skydart-create-surcharge-option">
                <h5>Currency Exchange</h5>
                <input
                  type="number"
                  disabled={user.role === 'Finance' ? false : user.role === 'CEO' ? false : true}
                  value={exchangeCurrency}
                  onChange={(e) => setExchangeCurrency(e.target.value)}
                  placeholder="Enter currency value"
                />
              </div>
              <div className="skydart-create-surcharge-option">
                <button
                  type="button"
                  onClick={() => {
                    handleSurcharge(fetchDhl, fetchFedex, fetchUps, exchangeCurrency);
                  }}
                >
                  Update surcharge
                </button>
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}
