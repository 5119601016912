import { React, useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Shipments from './staff/pages/shipments/Shipments';
import Packing from './staff/pages/packing/Packing';
import Debt from './staff/pages/finance/Debt';
import Customer from './staff/pages/customers/Customer';
import MapComponent from './staff/pages/study/MapTest';
import MarketBooking from './staff/pages/market/MarketBooking';
import SkydartHeader from './staff/components/SkydartHeader';
import NewBooking from './staff/pages/new-booking/NewBooking';
import Landing from './staff/pages/main/Landing';
import Loading from './Loading';
import TrackingResult from './staff/pages/tracking/TrackingResult';
import Scanning from './staff/pages/scanning/Scanning';
import SearchShipment from './staff/components/SearchShipment';
import axios from 'axios';
import { db } from './server/firebase';
import Debit from './staff/pages/debit/Debit';
import Invoices from './staff/pages/invoices/Invoices';
export default function StaffView({ user }) {
  const [displayShipment, setDisplayShipment] = useState(false);

  const handleShowPullShipment = () => {
    setDisplayShipment(!displayShipment);
  };

  return (
    <Router>
      <div className="app">
        {displayShipment && <SearchShipment user={user} setDisplayShipment={setDisplayShipment} />}
        <SkydartHeader user={user} handleShowPullShipment={handleShowPullShipment} />
        <div className="flex">
          <Routes>
            <Route exact path="/" element={<Landing user={user} />} />
            <Route exact path="/login" element={<Landing user={user} />} />
            <Route exact path="/market" element={<MarketBooking user={user} />} />
            <Route exact path="/new-booking" element={<NewBooking user={user} />} />
            <Route exact path="/shipments" element={<Shipments user={user} />} />
            <Route exact path="/scan/:hawb" element={<Scanning user={user} />} />
            <Route exact path="/packing" element={<Packing user={user} />} />
            <Route exact path="/invoices" element={<Invoices user={user} />} />
            <Route exact path="/transaction" element={<Debt user={user} />} />
            <Route exact path="/customers" element={<Customer user={user} />} />
            <Route exact path="/tracking-result/:hawb" element={<TrackingResult user={user} />} />
            <Route exact path="/debit" element={<Debit user={user} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}
