import { Switch, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { MdOutlineClear } from 'react-icons/md';
import ReactToExcel from 'react-html-table-to-excel';
export default function ExportInvoice({
  returnFunction,
  exportStatus,
  shipments,
  selected,
  includeHawb,
  setIncludeHawb,
}) {
  const [exportType, setExportType] = useState('');
  const [fileName, setFileName] = useState('');
  const [invoiceList, setInvoiceList] = useState([]);
  const [mergeInvoice, setMergeInvoice] = useState([]);
  const [loading, setLoading] = useState(false);
  const exportTypeArray = [
    { label: 'Export theo MAWB', value: 'mawb' },
    { label: 'Export theo Packing list', value: 'packing' },
  ];

  useEffect(() => {
    if (shipments) {
      const newData = shipments.filter((s) => s.shipmentType !== 'DOX');
      const mergedAllInvoice = newData.flatMap((item) => item.invoiceValue);
      const mergedData = mergedAllInvoice.reduce((acc, curr) => {
        const existingItem = acc.find((item) => item.itemVietnamCommonity === curr.itemVietnamCommonity);
        if (existingItem) {
          existingItem.itemUnitPrice = +existingItem.itemUnitPrice + +curr.itemUnitPrice;
          existingItem.itemQuantity = +existingItem.itemQuantity + +curr.itemQuantity;
          existingItem.itemValue = +existingItem.itemValue + +curr.itemValue;
        } else {
          acc.push({ ...curr });
        }
        return acc;
      }, []);
      setInvoiceList(mergedData);
    }
  }, [shipments, setInvoiceList]);
  // useEffect(() => {
  //   if (invoiceList) {
  //     const mergedData = invoiceList?.reduce((acc, curr) => {
  //       const existingItem = acc?.find((item) => item.itemVietnamCommonity === curr.itemVietnamCommonity);
  //       if (existingItem) {
  //         existingItem.itemUnitPrice = +existingItem.itemUnitPrice + +curr.itemUnitPrice;
  //         existingItem.itemQuantity = +existingItem.itemQuantity + +curr.itemQuantity;
  //         existingItem.itemValue = +existingItem.itemValue + +curr.itemValue;
  //       } else {
  //         acc.push({ ...curr });
  //       }
  //       return acc;
  //     });
  //   }
  // }, [invoiceList]);

  const headers = [
    { label: 'Mô tả hàng hoá', key: 'itemVietnamCommonity' },
    { label: 'Mã số hàng hoá', key: 'itemHsCode' },
    { label: 'Xuất xứ', key: 'itemCountryOrigin' },
    { label: 'Lượng hàng', key: 'itemQuantity' },
    { label: 'Đơn vị tính', key: 'itemUnit' },
    { label: 'Đơn giá nguyên tệ', key: 'itemUnitPrice' },
    { label: 'Trị giá nguyên tệ', key: 'itemValue' },
  ];

  const exportFormat = {
    data: invoiceList || [],
    headers: headers,
    filename: fileName,
  };

  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 bg-[rgba(255,255,255,0.55)] flex items-center justify-center z-[9999]">
      <div className="w-[400px] bg-white shadow-[rgba(0,0,0,0.1)_0px_4px_6px_-1px,rgba(0,0,0,0.06)_0px_2px_4px_-1px] p-1 rounded-[10px]">
        <div className="p-2.5 flex items-center border-b-[1px] border-t-0 border-r-0 border-l-0 border-solid border-slate-200">
          <p className="text-slate-500">Export invoices file</p>
          <div
            className="w-[30px] h-[30px] rounded-[10px] bg-slate-200 hover:bg-slate-300 active:bg-slate-400 flex items-center justify-center cursor-pointer ml-auto"
            onClick={() => returnFunction()}
          >
            <MdOutlineClear size={25} />
          </div>
        </div>
        <div className="p-2.5">
          <p className="text-[25px] font-semibold text-slate-600 my-2">{exportStatus || 'Export tất cả'}</p>
          <p className="text-[11px] my-2">Phân loại file xuất:</p>
          {exportTypeArray.map((item) => (
            <div
              className="p-1.5 bg-white hover:bg-slate-200 flex items-center rounded-[5px] px-2.5 cursor-pointer active:bg-slate-300 select-none"
              onClick={() => {
                setExportType(item.value);
              }}
            >
              <div className="w-[20px] h-[20px] flex items-center justify-center rounded-full bg-white border-solid border-slate-300 border-[1px]">
                {item.value === exportType && <div className="w-[12px] h-[12px] bg-sky-600 rounded-full"></div>}
              </div>
              <p className="mx-2 font-medium">{item.label}</p>
            </div>
          ))}
          <p className="text-[11px] my-2">Lựa chọn nâng cao:</p>
          <div className="p-1.5 bg-white hover:bg-slate-200 flex items-center rounded-[5px] px-2.5 cursor-pointer active:bg-slate-300 select-none">
            <Switch size="small" checked={includeHawb} onChange={(e) => setIncludeHawb(e.target.checked)} />
            <p className="mx-2 font-medium">Đính kèm số SKD</p>
          </div>
          <p className="text-[11px] my-2">Tên file:</p>
          <TextField
            size="small"
            fullWidth
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            label="Nhập tên file"
            autoComplete="off"
          />
          <div className="flex my-3">
            <div className="flex items-center ml-auto gap-1">
              <div
                className="p-1.5 px-3 bg-slate-200 font-semibold  rounded-[7px] cursor-pointer  select-none active:scale-95 hover:bg-slate-300 active:bg-slate-400"
                onClick={() => returnFunction()}
              >
                <p>Huỷ bỏ</p>
              </div>
              {exportType === 'mawb' && (
                <CSVLink
                  {...exportFormat}
                  className="p-1.5 px-3 bg-sky-600 font-semibold text-white rounded-[7px] cursor-pointer hover:bg-sky-700 active:bg-sky-800 select-none active:scale-95"
                >
                  <p>Xuất file</p>
                </CSVLink>
              )}
              {exportType === 'packing' && (
                <ReactToExcel
                  className="p-1.5 px-3 bg-sky-600 font-semibold text-white rounded-[7px] cursor-pointer hover:bg-sky-700 active:bg-sky-800 select-none active:scale-95"
                  table="invoice-table"
                  // filename={excelFileName || `${exporterName} debit from ${startDay} to ${endDay}`}
                  filename="invoice-clearance-report"
                  sheet="sheet"
                  buttonText="Xuất file"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
