import { Alert, CircularProgress, Snackbar } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { AiOutlineAppstore, AiOutlineCopy, AiOutlineMinus } from 'react-icons/ai';
import { BiError, BiPrinter } from 'react-icons/bi';
import { BsBox, BsNewspaper, BsReplyAll } from 'react-icons/bs';
import { FaFileInvoice } from 'react-icons/fa6';
import { FcOk } from 'react-icons/fc';
import { FiEdit } from 'react-icons/fi';
import { GrAddCircle } from 'react-icons/gr';
import { IoMdArrowDropdown } from 'react-icons/io';
import { MdBuild, MdOutlineClear, MdOutlineLocalShipping } from 'react-icons/md';
import { RiBillLine, RiUserReceived2Line } from 'react-icons/ri';
import { VscServerProcess } from 'react-icons/vsc';
import { useReactToPrint } from 'react-to-print';
import Inv2 from '../../components/Inv2';
import ShippingMark from '../../components/ShippingMark';
import StaffBill2 from '../../components/StaffBill2';
import { db } from '../../server/firebase';
import { countries } from '../api/countries';
import { serviceArray } from '../api/services';

export default function EditShipment({ displayEditScreen, propsHawb, handleCloseEdit, refreshShipment, selectedEdit }) {
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertShipment, setAlertShipment] = useState(false);
  const [alertUpdate, setAlertUpdate] = useState(false);
  const [loadShipment, setLoadShipment] = useState(false);
  const [envelope, setEnvelope] = useState(0);
  const [hawb, setHawb] = useState('');
  const [pickup, setPickup] = useState('');
  const [arrive, setArrive] = useState('');
  const [process, setProcess] = useState('');
  const [clearance, setClearance] = useState('');
  const [clear, setClear] = useState('');
  const [hold, setHold] = useState('');
  const [doxWeight, setDoxWeight] = useState(0.5);
  const [value, setValue] = useState(0);
  const [status, setStatus] = useState('');
  const [type, setType] = useState('');
  const [service1, setService1] = useState('');
  const [controller, setController] = useState('');
  const [code, setCode] = useState('');
  const [country, setCountry] = useState('');
  const [fromCountry, setFromCountry] = useState('');
  const [fromCity, setFromCity] = useState('');
  const [importer, setImporter] = useState('');
  const [city, setCity] = useState('');
  const [stateInput, setStateInput] = useState('');
  const [town, setTown] = useState('');
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [address3, setAddress3] = useState('');
  const [postal, setPostal] = useState('');
  const [phone, setPhone] = useState('');
  const [toName, setToName] = useState('');
  const [email, setEmail] = useState('');
  const [packageValue, setPackageValue] = useState([]);
  const [invoiceValue, setInvoiceValue] = useState([]);
  const [eori, setEori] = useState('');
  const [term, setTerm] = useState('');
  const [transport, setTransport] = useState('');
  const [reason, setReason] = useState('');
  const [currentState, setCurrentState] = useState(1);
  const [selection, setSelection] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState([]);
  const [displayFromCountry, setDisplayFromCountry] = useState(false);
  const [displayToCountry, setDisplayToCountry] = useState(false);
  const [mst, setMst] = useState('');
  const [description, setDescription] = useState('');
  const [exporter, setExporter] = useState('');
  const [fromAddress, setFromAddress] = useState('');
  const [fromAddress2, setFromAddress2] = useState('');
  const [fromAddress3, setFromAddress3] = useState('');
  const [fromTown, setFromTown] = useState('');
  const [fromState, setFromState] = useState('');
  const [fromPostal, setFromPostal] = useState('');
  const [fromName, setFromName] = useState('');
  const [fromPhone, setFromPhone] = useState('');
  const [fromEmail, setFromEmail] = useState('');
  const [createAt, setCreateAt] = useState('');
  const [stat, setStat] = useState('');
  const [service, setService] = useState('');
  const [eamService, setEamService] = useState('');
  const [printOption, setPrintOption] = useState(false);
  const [dusValue, setDusValue] = useState([]);
  function resetUpdate(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setAlertUpdate(false);
  }
  let fromCountryRef = useRef(null);
  let toCountryRef = useRef(null);
  const closeEdit = () => {
    setSelection([]);
    setHawb('');
    handleCloseEdit();
    setCurrentState(0);
  };
  const updateDox = () => {
    setLoadShipment(true);
    if (status === 'Pending') {
      createDox();
    } else if (status !== 'Pending') {
      createDox();
    }
  };
  const updateShipment = () => {
    setLoadShipment(true);
    if (status === 'Pending') {
      createShipment();
    } else if (status !== 'Pending') {
      createShipment();
    }
  };
  const printLabel = useReactToPrint({
    content: () => labelRef.current,
  });
  const printInvoice = useReactToPrint({
    content: () => invoiceRef.current,
  });
  const printShippingMark = useReactToPrint({
    content: () => shippingMarkRef.current,
  });
  const printAll = useReactToPrint({
    content: () => printAllRef.current,
  });
  let printAllRef = useRef();
  let labelRef = useRef();
  let invoiceRef = useRef();
  let shippingMarkRef = useRef();
  let printRefContainer = useRef();
  let hawbRef = useRef();
  const createDox = () => {
    setLoadShipment(true);
    if (doxWeight < 0.5) {
      alert('Your document can not be less than 0.5kg');
      setDoxWeight(0.5);

      setLoadShipment(false);
    } else {
    }
    if (doxWeight > 2) {
      alert('Your document can not be more than 2kg');
      setDoxWeight(0.5);

      setLoadShipment(false);
    } else {
    }
    db.collection('shipments')
      .doc(hawb)
      .update({
        shipmentType: type,
        service: service1,
        controller: controller,
        eamFormat: type === 'DOX' ? 'DOX' : 'NDX',
        code: code,
        value: 0,
        toPhone2: '',
        toCountry: country,
        fromCountry: fromCountry,
        fromCity: fromCity,
        importer: importer,
        toCity: city,
        toState: stateInput,
        toTown: town,
        toAddress: address,
        toAddress2: address2,
        toAddress3: address3,
        toPostal: postal,
        toPhone: phone,
        toName,
        toEmail: email,
        invoiceValue: type === 'DOX' ? 'Pending' : invoiceValue,
        packageValue: type === 'DOX' ? 'Pending' : packageValue,
        totalCharge: type === 'DOX' ? doxWeight : finalCharge,
        reweight: type === 'DOX' ? doxWeight : finalCharge,
        weight: type === 'DOX' ? doxWeight : finalGross,
        totalPackage: 1,
        totalItem: 1,
        description: 'Document',
      })
      .then(() => {
        next();
      })
      .catch((err) => {
        alert(err);
      });
  };
  const createShipment = () => {
    let arr = [];
    invoiceValue?.map((s) => {
      arr.push(s.itemCommonity);
    });
    setLoadShipment(true);
    setTimeout(() => {
      db.collection('shipments')
        .doc(hawb)
        .update({
          shipmentType: type,
          service: service1,
          controller: controller,
          eamFormat: type === 'DOX' ? 'DOX' : 'NDX',
          code: code,
          value: totalInvoiceValue,
          toPhone2: '',
          fromCountry: fromCountry,
          fromCity: fromCity,
          toCountry: country,
          importer: importer,
          toCity: city,
          toState: stateInput,
          toTown: town,
          toAddress: address,
          toAddress2: address2,
          toAddress3: address3,
          toPostal: postal,
          toPhone: phone,
          toName,
          toEmail: email,
          invoiceValue: invoiceValue,
          packageValue: packageValue,
          totalCharge: type === 'DOX' ? doxWeight : finalCharge,
          reweight: type === 'DOX' ? doxWeight : finalCharge,
          weight: type === 'DOX' ? doxWeight : finalGross,
          totalPackage: type === 'DOX' ? envelope : packageValue.length,
          totalItem: type === 'DOX' ? envelope : invoiceValue.length,
          eori: eori,
          description: type === 'DOX' ? 'Document' : arr + ',',
          term: type === 'DOX' ? 'Document' : term,
          transportation: transport,
          totalItemValue: finalInvoiceValue.toFixed(2),
          totalInvoiceValue: totalInvoiceValue.toFixed(2),
          usage: reason,
        })
        .then(() => {
          next();
        });
    }, 1000);
  };
  const finalCharge = packageValue?.reduce((a, v) => +a + +v?.packageCharge, 0);
  const finalGross = packageValue?.reduce((a, v) => +a + +v?.packageWeight, 0);
  const finalInvoiceValue = invoiceValue?.reduce((a, v) => (a = +a?.toFixed(2) + +v.itemValue?.toFixed(2)), 0);
  const totalInvoiceValue = +finalInvoiceValue + +transport;
  let handleChange = (i, e) => {
    let newFormValues = [...packageValue];
    newFormValues[i][e.target.name] = e.target.value;
    setPackageValue(newFormValues);
  };
  // let invoiceChange = (i, e) => {
  //   let newInvoiceValues = [...invoiceValue];
  //   newInvoiceValues[i][e.target.name] = e.target.value;
  //   setInvoiceValue(newInvoiceValues);
  // };
  let invoiceChange = (i, e) => {
    const { name, value } = e.target;
    if (name === 'itemUnitPrice') {
      const newValue = value.replace(',', '.'); // Replace comma with dot for decimal input

      const decimalParts = newValue.split('.'); // Split the input into parts separated by the decimal point

      if (decimalParts.length > 1 && decimalParts[1].length > 2) {
        // Check if the input has more than two decimal places
        return;
      }

      // Update the invoiceValue state with the modified value
      setInvoiceValue((prevState) => {
        const updatedInvoiceValue = prevState.map((item, index) => {
          if (index === i) {
            return { ...item, [name]: newValue };
          }
          return item;
        });
        return updatedInvoiceValue;
      });
    } else {
      let newInvoiceValues = [...invoiceValue];
      newInvoiceValues[i][e.target.name] = e.target.value;
      setInvoiceValue(newInvoiceValues);
    }
  };
  const next = () => {
    setLoadShipment(false);
    if (currentState + 1 === selection?.length) {
      closeEdit();
      refreshShipment();
    } else {
      setAlertUpdate(true);
      setCurrentState(currentState + 1);
      setSelectedShipment(
        selection.map((val) => {
          let updated = 'true';
          if (val.hawb === hawb) {
            val.updated = updated;
            setHawb(selection[currentState + 1].hawb);
          }
          return val;
        })
      );
    }
  };
  let removeFormField = (index, e) => {
    // let newFormValues = [...packageValue];
    // newFormValues.splice(i, 1);
    // setPackageValue(newFormValues);
    setPackageValue(packageValue.filter((v, i) => i !== index));
  };
  let addFormFields = () => {
    let x = packageValue?.length;
    setPackageValue([
      ...packageValue,
      {
        packNo: x + 1,
        packageLength: 0,
        packageWidth: 0,
        packageHeight: 0,
        packageWeight: 0.0,
        packageCharge: 0,
      },
    ]);
  };
  let removeInvoice = (index, e) => {
    setInvoiceValue(invoiceValue.filter((v, i) => i !== index));
  };
  let addCarton = () => {
    let x = invoiceValue?.length;
    setInvoiceValue([
      ...invoiceValue,
      {
        itemNo: x + 1,
        itemBrand: '',
        itemIngredients: '',
        itemUnit2: '',
        itemPackType2: '',
        itemCommonity: '',
        itemUnit: '',
        itemUnitPirce: 0,
        itemQuantity: 0,
        itemValue: 0,
        itemManufact: '',
        itemPurpose: '',
        itemHsCode: '',
        itemPackType: '',
        itemManufactAddress: '',
        itemCountryOrigin: 'Vietnam',
        itemFda: '',
      },
    ]);
  };
  const handleChangeService = (e) => {
    if (e.target.value === 'DHL') {
      setEamService('INT');
    }
    if (e.target.value === 'FIP') {
      setEamService('FIP');
    }
    setService1(e.target.value);
  };

  useEffect(() => {
    if (selectedEdit) {
      if (selectedEdit?.type === 'WPX') {
        setType(selectedEdit?.type);
        setHawb(selectedEdit?.hawb);
        setValue(selectedEdit?.value);
        setCode(selectedEdit?.code);
        setCountry(selectedEdit?.country);
        setMst(selectedEdit?.mst);
        setAddress(selectedEdit?.address);
        setAddress2(selectedEdit?.address2);
        setAddress3(selectedEdit?.address3);
        setTown(selectedEdit?.town);
        setCity(selectedEdit?.city);
        setStateInput(selectedEdit?.toState);
        setImporter(selectedEdit?.company);
        setPostal(selectedEdit?.postal);
        setToName(selectedEdit?.contact);
        setPhone(selectedEdit?.phone);
        setEmail(selectedEdit?.toEmail);
        setPackageValue(selectedEdit?.packageValue);
        setInvoiceValue(selectedEdit?.invoiceValue);
        setTerm(selectedEdit?.term);
        setTransport(selectedEdit?.transportation);
        setReason(selectedEdit?.reason);
        setService(selectedEdit?.service);
        setService1(selectedEdit?.service);
        setEori(selectedEdit?.eori);
        setExporter(selectedEdit?.sender);
        setFromCountry(selectedEdit?.fromCountry);
        setFromAddress(selectedEdit?.fromAddress);
        setFromAddress2(selectedEdit?.fromAddress2);
        setFromAddress3(selectedEdit?.fromAddress3);
        setFromTown(selectedEdit?.fromTown);
        setFromCity(selectedEdit?.fromCity);
        setFromState(selectedEdit?.fromState);
        setFromPostal(selectedEdit?.fromPostal);
        setFromName(selectedEdit?.fromName);
        setFromPhone(selectedEdit?.fromPhone);
        setTerm(selectedEdit?.term);
        setFromEmail(selectedEdit?.fromEmail);
        setController(selectedEdit?.controller);
        setDescription(selectedEdit?.description);
        setStat(selectedEdit?.status);
        setCreateAt(selectedEdit?.date);
        setPickup(selectedEdit?.pickupBy);
        setArrive(selectedEdit?.receiveBy);
        setProcess(selectedEdit?.processBy);
        setClearance(selectedEdit?.clearanceBy);
        setClear(selectedEdit?.clearedBy);
        setHold(selectedEdit?.holdBy);
        setInvoiceValue(selectedEdit?.invoiceValue);
        setPackageValue(selectedEdit?.packageValue);
      } else if (selectedEdit?.type === 'DOX') {
        setType(selectedEdit?.type);
        setCode(selectedEdit?.code);
        setCountry(selectedEdit?.country);
        setHawb(selectedEdit?.hawb);
        setImporter(selectedEdit?.company);
        setValue(selectedEdit?.value);
        setMst(selectedEdit?.mst);
        setService1(selectedEdit?.service);
        setAddress(selectedEdit?.address);
        setAddress2(selectedEdit?.address2);
        setAddress3(selectedEdit?.address3);
        setTown(selectedEdit?.town);
        setCity(selectedEdit?.city);
        setStateInput(selectedEdit?.toState);
        setPostal(selectedEdit?.postal);
        setToName(selectedEdit?.contact);
        setPhone(selectedEdit?.phone);
        setEmail(selectedEdit?.toEmail);
        setTerm(selectedEdit?.term);
        setTransport(selectedEdit?.transportation);
        setEnvelope(selectedEdit?.totalPackage);
        setDoxWeight(selectedEdit?.chargeable);
        setReason(selectedEdit?.reason);
        setService(selectedEdit?.service);
        setEori(selectedEdit?.eori);
        setExporter(selectedEdit?.sender);
        setFromCountry(selectedEdit?.fromCountry);
        setFromAddress(selectedEdit?.fromAddress);
        setFromAddress2(selectedEdit?.fromAddress2);
        setFromAddress3(selectedEdit?.fromAddress3);
        setFromTown(selectedEdit?.fromTown);
        setFromCity(selectedEdit?.fromCity);
        setFromState(selectedEdit?.fromState);
        setFromPostal(selectedEdit?.fromPostal);
        setFromName(selectedEdit?.fromName);
        setFromPhone(selectedEdit?.fromPhone);
        setFromEmail(selectedEdit?.fromEmail);
        setController(selectedEdit?.controller);
        setStat(selectedEdit?.status);
        setCreateAt(selectedEdit?.date);
        setDescription('Document');
        setPickup(selectedEdit?.pickupBy);
        setArrive(selectedEdit?.receiveBy);
        setProcess(selectedEdit?.processBy);
        setClearance(selectedEdit?.clearanceBy);
        setClear(selectedEdit?.clearedBy);
        setHold(selectedEdit?.holdBy);
        setInvoiceValue([]);
        setPackageValue([]);
      }
    }
  }, [selectedEdit]);

  return (
    <div>
      <div className="skd-edit-new-booking">
        <Snackbar open={alertUpdate} autoHideDuration={1000} onClose={resetUpdate}>
          <Alert elevation={1} variant="filled" onClose={resetUpdate} severity="success">
            Shipment has been updated successfully
          </Alert>
        </Snackbar>
        <div className="skd-edit-new-booking-container">
          <div className="skd-edit-new-booking-header" style={{ zIndex: 9999, position: 'relative' }}>
            <h5>Edit shipment</h5>
            {loadShipment && (
              <div className="skd-edit-new-booking-loading10">
                <CircularProgress size={25} style={{ padding: 5, color: '#0178da' }} />
              </div>
            )}
            <MdOutlineClear className="skd-edit-new-booking-header-icon" onClick={closeEdit} />
          </div>
          <div className="skd-edit-new-booking-body">
            <div className="skd-edit-new-booking-left">
              <h5
                style={{
                  marginTop: 5,
                  fontSize: 11,
                  marginLeft: 0,
                  marginBottom: 5,
                  fontWeight: 600,
                }}
              >
                Selecting:
              </h5>
              {selection?.map((s, index) => (
                <div key={index}>
                  {s.updated === 'false' && (
                    <div
                      className={
                        hawb === s.hawb ? 'skd-edit-new-booking-left-option' : 'skd-edit-new-booking-left-option1'
                      }
                      onClick={
                        hawb === s.hawb
                          ? () => {
                              return;
                            }
                          : () => {
                              setHawb(s.hawb);
                              setCurrentState(index);
                            }
                      }
                      key={index}
                    >
                      <h5>{s.hawb}</h5>
                      {hawb === s.hawb && <FiEdit style={{ marginLeft: 'auto' }} />}
                    </div>
                  )}
                  {s.updated === 'true' && (
                    <div
                      className="skd-edit-new-booking-left-option3"
                      onClick={
                        hawb === s.hawb
                          ? () => {
                              return;
                            }
                          : () => {
                              setHawb(s.hawb);
                              setCurrentState(index);
                            }
                      }
                      key={index}
                    >
                      <h5>{s.hawb}</h5>
                      {hawb === s.hawb ? (
                        <FiEdit style={{ marginLeft: 'auto' }} />
                      ) : (
                        <FcOk style={{ marginLeft: 'auto' }} />
                      )}
                    </div>
                  )}
                </div>
              ))}
              <div className="skd-edit-new-booking-left-option2">
                <MdOutlineLocalShipping />
                <h5>{pickup || 'Pending...'}</h5>
              </div>
              <div className="skd-edit-new-booking-left-option2">
                <RiUserReceived2Line style={{ fontSize: 18 }} />
                <h5>{arrive || 'Pending...'}</h5>
              </div>
              <div className="skd-edit-new-booking-left-option2">
                <MdBuild style={{ fontSize: 18 }} />
                <h5>{process || 'Pending...'}</h5>
              </div>
              <div className="skd-edit-new-booking-left-option2">
                <BiError style={{ fontSize: 18 }} />
                <h5>{hold || 'Pending...'}</h5>
              </div>
              <div className="skd-edit-new-booking-left-option2">
                <VscServerProcess style={{ fontSize: 18 }} />
                <h5>{clearance || 'Pending...'}</h5>
              </div>
              <div className="skd-edit-new-booking-left-option2">
                <BsBox size={18} />
                <h5>{clear || 'Pending...'}</h5>
              </div>
              <div className="skd-shipment-dus-label">
                {dusValue?.length !== 0 &&
                  dusValue?.map((s, index) => (
                    <div style={{ padding: 5 }} key={index}>
                      <a href={s.url} target="_blank">
                        {s.tracking_number}
                      </a>
                    </div>
                  ))}
              </div>
            </div>
            <div className="skd-edit-new-booking-center">
              <div className="skd-edit-new-booking-center-container">
                <div className="skd-edit-new-booking-center-type">
                  <h5 style={{ fontWeight: 600 }}>
                    Editing: <strong>{hawb}</strong>
                  </h5>
                  <select value={type} onChange={(e) => setType(e.target.value)}>
                    <option value="WPX">WPX</option>
                    <option value="DOX">DOX</option>
                  </select>
                </div>
                <div className="skd-edit-new-booking-center-shipping-info">
                  <div className="skd-edit-new-booking-center-shipping-info-header">
                    <h5>Country (Sender/Recipient)</h5>
                  </div>
                  <div className="skd-edit-new-booking-center-shipping-info-body">
                    <div ref={displayEditScreen ? fromCountryRef : null} style={{ position: 'relative' }}>
                      <div className="skd-edit-new-booking-country">
                        <h5>Ship From</h5>
                        <input
                          type="text"
                          value={fromCountry}
                          onChange={(e) => setFromCountry(e.target.value)}
                          onClick={() => setDisplayFromCountry(true)}
                        />
                      </div>
                      {displayFromCountry && (
                        <div className="skd-edit-new-booking-country1">
                          {countries
                            .filter((s) => {
                              if (s.label.toLowerCase().startsWith(fromCountry.toLowerCase())) {
                                return s;
                              }
                            })
                            .map((s, index) => (
                              <div
                                className="skd-edit-new-booking-country-box"
                                key={index}
                                onClick={() => {
                                  setFromCountry(s.label);
                                  setDisplayFromCountry(false);
                                }}
                              >
                                <img
                                  loading="lazy"
                                  width="20"
                                  src={`https://flagcdn.com/w20/${s.code.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${s.code.toLowerCase()}.png 2x`}
                                  alt=""
                                />
                                <h5>{s.label}</h5>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                    <div ref={displayEditScreen ? toCountryRef : null} style={{ position: 'relative' }}>
                      <div className="skd-edit-new-booking-country">
                        <h5>Ship To</h5>
                        <input
                          type="text"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          onClick={() => setDisplayToCountry(true)}
                        />
                      </div>
                      {displayToCountry && (
                        <div className="skd-edit-new-booking-country1">
                          {countries
                            .filter((s) => {
                              if (s.label.toLowerCase().startsWith(country.toLowerCase())) {
                                return s;
                              }
                            })
                            .map((s, index) => (
                              <div
                                key={index}
                                className="skd-edit-new-booking-country-box"
                                onClick={() => {
                                  setCountry(s.label);
                                  setCode(s.code);
                                  setDisplayToCountry(false);
                                }}
                              >
                                <img
                                  loading="lazy"
                                  width="20"
                                  src={`https://flagcdn.com/w20/${s.code.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${s.code.toLowerCase()}.png 2x`}
                                  alt=""
                                />
                                <h5>{s.label}</h5>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="skd-edit-new-booking-center-shipping-info-header">
                    <h5>Recipient Information</h5>
                  </div>
                  <div className="skd-edit-new-booking-center-shipping-info-body">
                    <div className="skd-edit-new-booking-country">
                      <h5>Company Name (Importer)</h5>
                      <input
                        type="text"
                        value={importer}
                        onChange={(e) => setImporter(e.target.value)}
                        maxLength={30}
                      />
                    </div>
                    <div className="skd-edit-new-booking-country">
                      <h5>Address</h5>
                      <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} maxLength={30} />
                    </div>
                    <div className="skd-edit-new-booking-country">
                      <h5>Address 2</h5>
                      <input
                        type="text"
                        value={address2}
                        onChange={(e) => setAddress2(e.target.value)}
                        maxLength={30}
                      />
                    </div>
                    <div className="skd-edit-new-booking-country">
                      <h5>Address 3</h5>
                      <input
                        type="text"
                        value={address3}
                        onChange={(e) => setAddress3(e.target.value)}
                        maxLength={30}
                      />
                    </div>
                    <div className="skd-edit-new-booking-country">
                      <h5>Town</h5>
                      <input type="text" value={town} onChange={(e) => setTown(e.target.value)} maxLength={30} />
                    </div>
                    <div className="skd-edit-new-booking-country">
                      <h5>City</h5>
                      <input type="text" value={city} onChange={(e) => setCity(e.target.value)} maxLength={30} />
                    </div>
                    <div className="skd-edit-new-booking-country">
                      <h5>State</h5>
                      <input
                        type="text"
                        value={stateInput}
                        onChange={(e) => setStateInput(e.target.value)}
                        maxLength={30}
                      />
                    </div>
                    <div className="skd-edit-new-booking-country">
                      <h5>Postal Code</h5>
                      <input type="text" value={postal} onChange={(e) => setPostal(e.target.value)} maxLength={30} />
                    </div>
                  </div>
                  <div className="skd-edit-new-booking-center-shipping-info-header">
                    <h5>Recipient Contact</h5>
                  </div>
                  <div className="skd-edit-new-booking-center-shipping-info-body">
                    <div className="skd-edit-new-booking-country">
                      <h5>Contact Name</h5>
                      <input type="text" value={toName} onChange={(e) => setToName(e.target.value)} maxLength={30} />
                    </div>
                    <div className="skd-edit-new-booking-country">
                      <h5>Phone Number</h5>
                      <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} maxLength={30} />
                    </div>
                    <div className="skd-edit-new-booking-country">
                      <h5>Email Address</h5>
                      <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                  </div>
                  <div className="skd-edit-new-booking-center-shipping-info-header">
                    <h5>Sender Information</h5>
                  </div>
                  <div className="skd-edit-new-booking-center-shipping-info-body">
                    <div className="skd-edit-new-booking-country">
                      <h5>Company Name (Exporter)</h5>
                      <input type="text" disabled value={exporter} />
                    </div>
                    <div className="skd-edit-new-booking-country">
                      <h5>Address</h5>
                      <input type="text" disabled value={fromAddress} />
                    </div>
                    <div className="skd-edit-new-booking-country">
                      <h5>Address 2</h5>
                      <input type="text" disabled value={fromAddress2} />
                    </div>
                    <div className="skd-edit-new-booking-country">
                      <h5>Address 3</h5>
                      <input type="text" disabled value={fromAddress3} />
                    </div>
                    <div className="skd-edit-new-booking-country">
                      <h5>Town</h5>
                      <input type="text" disabled value={fromTown} />
                    </div>
                    <div className="skd-edit-new-booking-country">
                      <h5>City</h5>
                      <input type="text" value={fromCity} onChange={(e) => setFromCity(e.target.value)} />
                    </div>
                    <div className="skd-edit-new-booking-country">
                      <h5>State</h5>
                      <input type="text" disabled value={fromState} />
                    </div>
                    <div className="skd-edit-new-booking-country">
                      <h5>Postal Code</h5>
                      <input type="text" disabled value={fromPostal} />
                    </div>
                  </div>
                  <div className="skd-edit-new-booking-center-shipping-info-header">
                    <h5>Sender Contact</h5>
                  </div>
                  <div className="skd-edit-new-booking-center-shipping-info-body">
                    <div className="skd-edit-new-booking-country">
                      <h5>Contact Name</h5>
                      <input type="text" disabled value={fromName} />
                    </div>
                    <div className="skd-edit-new-booking-country">
                      <h5>Phone Number</h5>
                      <input type="text" disabled value={fromPhone} />
                    </div>
                    <div className="skd-edit-new-booking-country">
                      <h5>Email Address</h5>
                      <input type="text" disabled value={fromEmail} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="skd-edit-new-booking-right">
              <div className="skd-edit-new-booking-right-header">
                <h5>Current Status: {stat}</h5>
              </div>
              <div className="skd-edit-new-booking-right-packing">
                <div className="skd-edit-new-booking-pack-main">
                  <h5>Packing Information</h5>
                  <AiOutlineAppstore />
                </div>
                {type === 'WPX' && (
                  <div className="skd-edit-new-booking-pack-main1">
                    <div className="skd-edit-new-booking-pack-option">
                      <h5>Total packages:</h5>
                      <h5>{packageValue?.length} PCS</h5>
                    </div>
                    <div className="skd-edit-new-booking-pack-option">
                      <h5>Total gross weight:</h5>
                      <h5>{(Math.round(finalGross * 100) / 100).toFixed(2)} KG</h5>
                    </div>
                    <div className="skd-edit-new-booking-pack-option">
                      <h5>Total chargeable</h5>
                      <h5>{(Math.round(finalCharge * 100) / 100).toFixed(2)} KG</h5>
                    </div>
                  </div>
                )}
                {type === 'DOX' && (
                  <div className="skd-edit-new-booking-pack-main1">
                    <div className="skd-edit-new-booking-pack-option">
                      <h5>Total number of envelope:</h5>
                      <h5>{envelope}</h5>
                    </div>
                    <div className="skd-edit-new-booking-pack-option">
                      <h5>Total gross weight:</h5>
                      <h5>{(Math.round(doxWeight * 100) / 100).toFixed(2)} KG</h5>
                    </div>
                    <div className="skd-edit-new-booking-pack-option">
                      <h5>Total chargeable</h5>
                      <h5>{(Math.round(doxWeight * 100) / 100).toFixed(2)} KG</h5>
                    </div>
                  </div>
                )}
                {type === 'WPX' && (
                  <div className="skd-edit-new-booking-right-dim">
                    <div className="skd-edit-new-booking-right-dim1" style={{ justifyContent: 'space-between' }}>
                      <div className="skd-new-booking-left-dim">
                        <h5>Weight:</h5>
                        {/* <input type="number" /> */}
                      </div>
                      <div className="skd-new-booking-center-dim">
                        <h5 style={{ marginLeft: 15 }}>Dim:</h5>
                        {/* <div>
                                                <input type="number" placeholder="L" />

                                                <input type="number" placeholder="W" />

                                                <input type="number" placeholder="H" />
                                            </div> */}
                      </div>
                      <div className="skd-new-booking-left-dim">
                        <h5 style={{ marginLeft: 35 }}>Chargeable:</h5>
                        {/* <input type="number" /> */}
                      </div>
                      <div className="skd-new-booking-empty"></div>
                    </div>

                    {packageValue?.map((element, index) => {
                      let dimCharge = 0;

                      if (service === 'DAU') {
                        dimCharge = (element.packageWidth * element.packageHeight * element.packageLength) / 6000;
                      } else if (service === 'DUS') {
                        dimCharge = (element.packageWidth * element.packageHeight * element.packageLength) / 6000;
                      } else {
                        dimCharge = (element.packageWidth * element.packageHeight * element.packageLength) / 5000;
                      }
                      const grossWeight = element.packageWeight;

                      if (dimCharge < grossWeight) {
                        element.packageCharge = grossWeight * 1;
                        element.packageWeight = grossWeight * 1;
                      } else if (grossWeight < dimCharge) {
                        element.packageCharge = dimCharge;
                        element.packageWeight = grossWeight * 1;
                      }
                      if (element.packageCharge >= 30) {
                        element.packageCharge = Math.ceil(element.packageCharge);
                      } else if (element.packageCharge < 30) {
                        element.packageCharge = Math.ceil(element.packageCharge / 0.5) * 0.5;
                      }
                      if (element.packageWeight === '') {
                        element.packageCharge = '0.00';
                      }
                      return (
                        <div className="skd-edit-new-booking-right-dim1">
                          <div className="skd-new-booking-left-dim">
                            <input
                              type="number"
                              value={element.packageWeight || ''}
                              onChange={(e) => handleChange(index, e)}
                              name="packageWeight"
                            />
                          </div>
                          <div className="skd-new-booking-center-dim">
                            <div>
                              <input
                                type="number"
                                placeholder="L"
                                name="packageLength"
                                value={element.packageLength || ''}
                                onChange={(e) => handleChange(index, e)}
                              />
                              <input
                                type="number"
                                placeholder="W"
                                name="packageWidth"
                                value={element.packageWidth || ''}
                                onChange={(e) => handleChange(index, e)}
                              />
                              <input
                                type="number"
                                placeholder="H"
                                name="packageHeight"
                                value={element.packageHeight || ''}
                                onChange={(e) => handleChange(index, e)}
                              />
                            </div>
                          </div>
                          <div className="skd-new-booking-left-dim">
                            <input
                              type="number"
                              disabled
                              value={(Math.round(element.packageCharge * 100) / 100).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                              name="packageCharge"
                            />
                          </div>
                          <div className="skydart-pack-option">
                            <AiOutlineCopy
                              size={13}
                              className="skydart-pack-copy"
                              onClick={() => {
                                setPackageValue([
                                  ...packageValue,
                                  {
                                    packageLength: element.packageLength,
                                    packageWidth: element.packageWidth,
                                    packageHeight: element.packageHeight,
                                    packageWeight: element.packageWeight,
                                    packageCharge: element.packageCharge,
                                  },
                                ]);
                              }}
                            />
                            {packageValue?.length !== 1 && (
                              <AiOutlineMinus
                                size={13}
                                className="skydart-pack-copy"
                                onClick={(e) => removeFormField(index, e)}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}

                    <div className="skydart-pack-add-btn">
                      <button type="button" onClick={addFormFields}>
                        <GrAddCircle style={{ fontSize: 17 }} />
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="skd-edit-new-booking-right-packing">
                <div className="skd-edit-new-booking-pack-main">
                  <h5>Description Of Goods</h5>
                  <AiOutlineAppstore />
                </div>
                {type === 'WPX' && (
                  <div className="skd-edit-new-booking-pack-main1">
                    <div className="skd-edit-new-booking-pack-option">
                      <h5>Total items:</h5>
                      <h5>{invoiceValue?.length} items</h5>
                    </div>
                    <div className="skd-edit-new-booking-pack-option">
                      <h5>Total item value:</h5>
                      <h5>{(Math.round(finalInvoiceValue * 100) / 100).toFixed(2)} USD</h5>
                    </div>
                    <div className="skd-edit-new-booking-pack-option">
                      <h5>Total invoice value:</h5>
                      <h5>{(Math.round(totalInvoiceValue * 100) / 100).toFixed(2)} USD</h5>
                    </div>
                  </div>
                )}
                {type === 'DOX' && (
                  <div className="skd-edit-new-booking-pack-main1">
                    <div className="skd-edit-new-booking-pack-option">
                      <h5 style={{ color: 'black' }}>There is no invoice required for Document (DOX)</h5>
                    </div>
                  </div>
                )}
              </div>
              {type === 'WPX' && (
                <div className="skd-edit-new-booking-right-invoice">
                  {invoiceValue?.map((s, index) => {
                    let donGia1 = s.itemUnitPrice;
                    let soLuong1 = s.itemQuantity;
                    let tong1 = donGia1 * soLuong1;
                    s.itemValue = tong1;
                    return (
                      <div style={{ marginBottom: 10 }}>
                        <div className="skd-edit-new-booking-right-invoice-header">
                          <div className="skd-edit-new-booking-right-invoice-header-left">
                            <h5>Item {s.itemNo}</h5>
                          </div>
                          <div className="skydart-pack-option">
                            <AiOutlineCopy
                              size={13}
                              className="skydart-pack-copy"
                              onClick={() => {
                                let x = invoiceValue?.length;
                                setInvoiceValue([
                                  ...invoiceValue,
                                  {
                                    itemNo: x + 1,
                                    itemBrand: s.itemBrand,
                                    itemIngredients: s.itemIngredients,
                                    itemUnit2: s.itemUnit2,
                                    itemPackType2: s.itemPackType2,
                                    itemCommonity: s.itemCommonity,
                                    itemUnit: s.itemUnit,
                                    itemUnitPirce: s.itemUnitPrice,
                                    itemQuantity: s.itemQuantity,
                                    itemValue: s.itemValue,
                                    itemManufact: s.itemManufact,
                                    itemPurpose: s.itemPurpose,
                                    itemHsCode: s.itemHsCode,
                                    itemPackType: s.itemPackType,
                                    itemManufactAddress: s.itemManufactAddress,
                                    itemCountryOrigin: s.itemCountryOrigin,
                                    itemFda: s.itemFda,
                                  },
                                ]);
                              }}
                            />
                            {invoiceValue?.length !== 1 && (
                              <AiOutlineMinus
                                size={13}
                                className="skydart-pack-copy"
                                onClick={(e) => removeInvoice(index, e)}
                              />
                            )}
                          </div>
                        </div>
                        <div className="skd-edit-new-booking-right-invoice-body">
                          <div className="skd-edit-new-booking-right-invoice-itemName">
                            <input
                              type="text"
                              placeholder="Declare item name here"
                              name="itemCommonity"
                              value={s.itemCommonity}
                              onChange={(e) => invoiceChange(index, e)}
                            />
                          </div>
                          <div className="skd-edit-new-booking-right-invoice-itemName">
                            <input
                              type="text"
                              placeholder="Tên mặt hàng"
                              name="itemVietnamCommonity"
                              value={s.itemVietnamCommonity}
                              onChange={(e) => invoiceChange(index, e)}
                            />
                          </div>
                          <div className="skd-edit-new-booking-right-invoice-content">
                            <div className="skd-edit-new-booking-right-invoice-option">
                              <h5>Brand Name</h5>
                              <input
                                type="text"
                                value={s.itemBrand}
                                name="itemBrand"
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                            <div className="skd-edit-new-booking-right-invoice-option">
                              <h5>Packing Type</h5>
                              <select
                                value={s.itemPackType || ''}
                                name="itemPackType"
                                onChange={(e) => invoiceChange(index, e)}
                              >
                                <option value="">Type</option>
                                <option value="Bag">Bag</option>
                                <option value="Box">Box</option>
                                <option value="Carton">Carton</option>
                                <option value="Wooden Pack">Wooden Pack</option>
                              </select>
                            </div>
                            <div className="skd-edit-new-booking-right-invoice-option">
                              <h5>Ingredient</h5>
                              <input
                                type="text"
                                value={s.itemIngredients}
                                name="itemIngredients"
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                          </div>
                          <div className="skd-edit-new-booking-right-invoice-content">
                            <div className="skd-edit-new-booking-right-invoice-option">
                              <h5>Usage</h5>
                              <input
                                type="text"
                                value={s.itemPurpose}
                                name="itemPurpose"
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                            <div className="skd-edit-new-booking-right-invoice-option">
                              <h5>Origin Country</h5>
                              <input
                                type="text"
                                value={s.itemCountryOrigin}
                                name="itemCountryOrigin"
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                            <div className="skd-edit-new-booking-right-invoice-option">
                              <h5>Manufacturer Name</h5>
                              <input
                                type="text"
                                name="itemManufact"
                                value={s.itemManufact}
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                          </div>
                          <div className="skd-edit-new-booking-right-invoice-itemName" style={{ marginTop: 5 }}>
                            <h5
                              style={{
                                fontWeight: 600,
                                fontSize: 12,
                                color: 'gray',
                              }}
                            >
                              Manufacturer Address
                            </h5>
                            <textarea
                              type="text"
                              placeholder="Declare item name here"
                              name="itemManufactAddress"
                              value={s.itemManufactAddress}
                              onChange={(e) => invoiceChange(index, e)}
                            />
                          </div>
                          <div className="skd-edit-new-booking-right-invoice-content">
                            <div className="skd-edit-new-booking-right-invoice-option">
                              <h5>Hs Code</h5>
                              <input
                                type="text"
                                name="itemHsCode"
                                value={s.itemHsCode}
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                            <div className="skd-edit-new-booking-right-invoice-option">
                              <h5>FDA</h5>
                              <input
                                type="text"
                                value={s.itemFda || ''}
                                name="itemFda"
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                            <div className="skd-edit-new-booking-right-invoice-option">
                              <h5>Quantity</h5>
                              <input
                                type="number"
                                value={s.itemQuantity || ''}
                                name="itemQuantity"
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                          </div>
                          <div className="skd-edit-new-booking-right-invoice-content">
                            <div className="skd-edit-new-booking-right-invoice-option">
                              <h5>Unit</h5>
                              {s.itemUnit !== 'Other' && (
                                <select name="itemUnit" value={s.itemUnit} onChange={(e) => invoiceChange(index, e)}>
                                  <option value="">Unit</option>
                                  <option value="Pcs">Pcs</option>
                                  <option value="Pair">Pair</option>
                                  <option value="Kg">Kg</option>
                                  <option value="Set">Set</option>
                                  <option value="Bag">Bag</option>
                                  <option value="Other">Other</option>
                                </select>
                              )}
                              {s.itemUnit === 'Other' && (
                                <div style={{ display: 'flex' }}>
                                  <input
                                    type="text"
                                    value={s.itemUnit2 || ''}
                                    name="itemUnit2"
                                    onChange={(e) => invoiceChange(index, e)}
                                  />
                                  <select
                                    value={s.itemUnit || ''}
                                    name="itemUnit"
                                    onChange={(e) => invoiceChange(index, e)}
                                    style={{ width: '18%' }}
                                  >
                                    <option value="">Unit</option>
                                    <option value="Pcs">Pcs</option>
                                    <option value="Pair">Pair</option>
                                    <option value="Kg">Kg</option>
                                    <option value="Set">Set</option>
                                    <option value="Bag">Bag</option>
                                    <option value="Other">Other</option>
                                  </select>
                                </div>
                              )}
                            </div>
                            <div className="skd-edit-new-booking-right-invoice-option">
                              <h5>Unit Price (USD)</h5>
                              <input
                                type="number"
                                required
                                value={s.itemUnitPrice || ''}
                                name="itemUnitPrice"
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                            <div className="skd-edit-new-booking-right-invoice-option">
                              <h5>Total amount (USD)</h5>
                              <input
                                type="text"
                                style={{ background: 'rgb(240,240,240)' }}
                                disabled
                                value={s.itemValue.toFixed(2)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="skydart-pack-add-btn">
                    <button type="button" onClick={addCarton} style={{ marginRight: 10 }}>
                      <GrAddCircle style={{ fontSize: 17 }} />
                    </button>
                  </div>
                </div>
              )}
              <div className="skd-edit-new-booking-right-packing" style={{ paddingBottom: 30 }}>
                <div className="skd-edit-new-booking-pack-main">
                  <h5>Term Of Transportation</h5>
                  <AiOutlineAppstore />
                </div>
                <div className="skd-edit-new-booking-pack-main1">
                  {type === 'WPX' && (
                    <div className="skd-edit-new-booking-term">
                      <h5>Transport Term</h5>
                      <select value={term} onChange={(e) => setTerm(e.target.value)}>
                        <option value="">Select your transport term</option>
                        <option value="EXW">EX-WORD (EXW)</option>
                        <option value="FOB">FREE ON BOARD (FOB)</option>
                        <option value="CNF">COST & FREIGHT (CNF)</option>
                        <option value="CIF">COST INSURANCE & FREIGHT (CIF)</option>
                      </select>
                    </div>
                  )}
                  {type === 'WPX' && (
                    <div className="skd-edit-new-booking-term">
                      <h5>Transport Value</h5>
                      {term !== 'FOB' && term !== 'EXW' && (
                        <input
                          type="number"
                          style={{ padding: 5, width: '100%' }}
                          className="px-2"
                          placeholder="Enter your transportation cost"
                          value={transport}
                          onChange={(e) => setTransport(e.target.value)}
                        />
                      )}
                      {term === 'FOB' && (
                        <input
                          type="number"
                          style={{
                            background: 'rgb(230,230,230)',
                            padding: 7,
                            width: '96%',
                          }}
                          placeholder="Enter your transportation cost"
                          value={transport}
                          onChange={(e) => setTransport(e.target.value)}
                          disabled
                        />
                      )}
                      {term === 'EXW' && (
                        <input
                          type="number"
                          style={{
                            background: 'rgb(230,230,230)',
                            padding: 7,
                            width: '96%',
                          }}
                          placeholder="Enter your transportation cost"
                          value={transport}
                          onChange={(e) => setTransport(e.target.value)}
                          disabled
                        />
                      )}
                    </div>
                  )}
                  {type === 'WPX' && (
                    <div className="skd-edit-new-booking-term">
                      <h5>Export Reason</h5>
                      <select value={reason} onChange={(e) => setReason(e.target.value)}>
                        <option value="">Select your export reason</option>
                        <option value="THE GOODS ARE GIFT FROM FAMILY AND NON COMMERCIAL VALUE">
                          THE GOODS ARE GIFT FROM FAMILY AND NON COMMERCIAL VALUE
                        </option>
                        <option value="THE GOODS ARE E-COMMERCE SELLING">THE GOODS ARE E-COMMERCE SELLING</option>
                        <option value="THE GOODS ARE RETURNED FOR MAINTENANCE">
                          THE GOODS ARE RETURNED FOR MAINTENANCE
                        </option>
                        <option value="THE GOODS ARE SAMPLE AND NON COMMERCIAL VALUE">
                          THE GOODS ARE SAMPLE AND NON COMMERCIAL VALUE
                        </option>
                        <option value="THE GOODS ARE COMMERCIAL WITH ALL NECESSARY DOCUMENTS ENCLOSED">
                          THE GOODS ARE COMMERCIAL WITH ALL NECESSARY DOCUMENTS ENCLOSED
                        </option>
                      </select>
                    </div>
                  )}
                  <div className="skd-edit-new-booking-term">
                    <h5>Service</h5>
                    <select value={service1} onChange={handleChangeService}>
                      <option value="">Select a service</option>
                      {serviceArray.map((item) => (
                        <option value={item.label}>{item.label}</option>
                      ))}
                    </select>
                  </div>
                  {type === 'WPX' && (
                    <div className="skd-edit-new-booking-term">
                      <h5>IOSS / TIN / EORI Number / ID Tax Number</h5>
                      <input
                        type="text"
                        style={{ width: '100%', padding: 5 }}
                        value={eori}
                        onChange={(e) => setEori(e.target.value)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="skd-edit-new-booking-button">
            <div className="skd-edit-new-booking-button-option">
              <div className="skd-new-booking-cancel" onClick={closeEdit}>
                <h5>Cancel</h5>
              </div>
              <div ref={printOption ? printRefContainer : null}>
                {printOption && (
                  <div className="skd-new-booking-print-container">
                    <div className="skd-new-booking-print-option" onClick={printLabel}>
                      <RiBillLine />
                      <h5>Print Label</h5>
                    </div>
                    {type === 'WPX' && (
                      <div className="skd-new-booking-print-option" onClick={printInvoice}>
                        <FaFileInvoice />
                        <h5>Print Invoice</h5>
                      </div>
                    )}
                    {type === 'DOX' && (
                      <div className="skd-new-booking-print-option1">
                        <FaFileInvoice />
                        <h5>Print Invoice</h5>
                      </div>
                    )}
                    {type === 'WPX' && (
                      <div className="skd-new-booking-print-option" onClick={printShippingMark}>
                        <BsNewspaper />
                        <h5>Print Shipping Mark</h5>
                      </div>
                    )}
                    {type === 'DOX' && (
                      <div className="skd-new-booking-print-option1">
                        <BsNewspaper />
                        <h5>Print Shipping Mark</h5>
                      </div>
                    )}
                    <div className="skd-new-booking-print-option" onClick={printAll}>
                      <BsReplyAll />
                      <h5>Print All</h5>
                    </div>
                  </div>
                )}
                <div
                  className="skd-new-booking-print"
                  onClick={() => {
                    printOption ? setPrintOption(false) : setPrintOption(true);
                  }}
                >
                  <BiPrinter />
                  <h5>Print</h5>
                  <IoMdArrowDropdown />
                </div>
              </div>
              <button
                type="button"
                className="skd-new-booking-update"
                onClick={type === 'WPX' ? updateShipment : updateDox}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>

      <div ref={displayEditScreen ? null : fromCountryRef}></div>
      <div ref={displayEditScreen ? null : toCountryRef}></div>
      <div ref={printAllRef}>
        <div className="skydart-booking-hawb1" ref={labelRef}>
          <div className="printing3">
            <StaffBill2
              hawb={hawb}
              exporter={exporter}
              fromAddress={fromAddress}
              fromAddress2={fromAddress2}
              fromAddress3={fromAddress3}
              fromState={fromState}
              fromTown={fromTown}
              fromCity={fromCity}
              fromPostal={fromPostal}
              fromCountry={fromCountry}
              fromPhone={fromPhone}
              fromName={fromName}
              fromEmail={fromEmail}
              importer={importer}
              toAddress={address}
              toAddress2={address2}
              toAddress3={address3}
              toState={stateInput}
              toTown={town}
              toCity={city}
              toPostal={postal}
              toCountry={country}
              toPhone={phone}
              toName={toName}
              invoiceValue={invoiceValue}
              toEmail={email}
              amount={packageValue.length}
              weight={
                type === 'WPX'
                  ? (Math.round(finalGross * 100) / 100).toFixed(2)
                  : (Math.round(doxWeight * 100) / 100).toFixed(2)
              }
              chargeable={
                type === 'WPX'
                  ? (Math.round(finalCharge * 100) / 100).toFixed(2)
                  : (Math.round(doxWeight * 100) / 100).toFixed(2)
              }
              type={type}
              date={createAt}
              description={description}
            />
          </div>
        </div>
        <div className="skydart-booking-hawb1" ref={invoiceRef}>
          {type === 'WPX' && (
            <Inv2
              invoice={invoiceValue}
              hawb={hawb}
              datePicker={createAt}
              fromCountry={fromCountry}
              toCountry={country}
              usage={reason}
              exporter={exporter}
              fromAddress={fromAddress}
              fromAddress2={fromAddress2}
              fromAddress3={fromAddress3}
              fromTown={fromTown}
              fromState={fromState}
              fromCity={fromCity}
              fromPostal={fromPostal}
              fromName={fromName}
              fromPhone={fromPhone}
              fromEmail={fromEmail}
              mst={mst}
              importer={importer}
              toAddress={address}
              toAddress2={address2}
              toAddress3={address3}
              toState={stateInput}
              toPostal={postal}
              toTown={town}
              toCity={city}
              toName={toName}
              toPhone={phone}
              toEmail={email}
              eori={eori}
              transportation={transport}
              totalGross={(Math.round(finalGross * 100) / 100).toFixed(2)}
              totalPackage={packageValue.length}
              totalItemValue={(Math.round(finalInvoiceValue * 100) / 100).toFixed(2)}
              totalInvoiceValue={(Math.round(totalInvoiceValue * 100) / 100).toFixed(2)}
            />
          )}
        </div>
        {type === 'WPX' && (
          <div className="skydart-booking-hawb1" ref={shippingMarkRef}>
            {packageValue.map((item, index) => (
              <div className="printing4">
                <ShippingMark
                  no={index + 1}
                  date={createAt}
                  length={item.packageLength}
                  width={item.packageWidth}
                  height={item.packageHeight}
                  total={packageValue.length}
                  hawb={hawb}
                  weight={item.packageWeight}
                  chargeable={item.packageCharge}
                  importer={importer}
                  address={address}
                  address2={address2}
                  address3={address3}
                  city={city}
                  state={stateInput}
                  town={town}
                  postal={postal}
                  country={country}
                  phone={phone}
                  contact={toName}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
