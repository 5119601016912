import React, { useState, useEffect } from 'react';

import StaffView from './StaffView';
import CustomerView from './CustomerView';
import { Button, CircularProgress } from '@mui/material';
import { auth, db } from './server/firebase';
export default function Welcome({ user }) {
  const [verify, setVerify] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayWelcome, setDisplayWelcome] = useState(false);
  useEffect(() => {
    if (user) {
      setVerify(user.employee);
    }
  }, []);
  // const getDoc = () => {
  //     setDisplayWelcome(false);
  //     setTimeout(() => {
  //         window.location.reload();
  //     },1500)
  // }

  if (verify === 'yes') {
    return <StaffView user={user} />;
  }
  if (verify === 'no') {
    return <CustomerView user={user} />;
  }
  return (
    <div className="welcome h-[100vh] flex items-center justify-center w-full">
      {!displayWelcome && (
        <center className="alert__loading">
          <CircularProgress size={40} />
          <p className="mt-2.5 font-semibold text-slate-600 tracking-wide">Loading your booking software</p>
        </center>
      )}
      {displayWelcome && (
        <center className="alert__loading">
          <CircularProgress size={50} />
          <Button
            variant="contained"
            onClick={() => auth.signOut()}
            style={{ background: 'green', color: 'white', fontWeight: 600, textTransform: 'capitalize' }}
          >
            Try Again
          </Button>
        </center>
      )}
    </div>
  );
}
