import { CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FaRegUser, FaSortDown } from 'react-icons/fa6';
import { FcLeave, FcOk } from 'react-icons/fc';
import { GoPackage } from 'react-icons/go';
import { IoDocumentTextSharp } from 'react-icons/io5';
import { MdOutlineClear, MdOutlineSubdirectoryArrowRight } from 'react-icons/md';
import OutsideClickHandler from 'react-outside-click-handler';

export default function UpdateCustomerScreen({
  updateStatus,
  selected,
  shipments,
  staffs,
  plan,
  handleCloseUpdate,
  handleHideShipment,
  handleUpdateNewController,
  handleUpdateNewClass,
  staffInput,
  setStaffInput,
  planInput,
  setPlanInput,
  loading,
}) {
  const [isPageLeaving, setIsPageLeaving] = useState(false);
  const [displayStaff, setDisplayStaff] = useState(false);
  const [searchSale, setSearchSale] = useState('');
  const [searchClass, setSearchClass] = useState('');
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isPageLeaving) {
        const confirmationMessage = 'Changes you made may not be saved. Are you sure you want to leave?';
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    const handleWindowClose = (e) => {
      setIsPageLeaving(true);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleWindowClose);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleWindowClose);
    };
  }, [isPageLeaving]);
  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 bg-[rgba(0,0,0,0.55)] flex items-center justify-center z-[9999]">
      <div className="h-[calc(100vh_-_5rem)] bg-white w-[calc(100vw_-_20rem)] rounded-[10px] overflow-auto">
        <div
          className="p-2.5 flex items-center sticky top-0 z-10 bg-white"
          style={{ borderBottom: '1px solid rgb(230,230,230)' }}
        >
          <p className="text-xl font-semibold">Update {updateStatus}</p>
          <div
            className="w-[35px] h-[35px] rounded-[10px] bg-slate-100 hover:bg-slate-200 active:bg-slate-300 active:scale-90 cursor-pointer flex items-center justify-center ml-auto"
            onClick={() => handleCloseUpdate()}
          >
            <MdOutlineClear size={30} />
          </div>
        </div>
        <div>
          <OutsideClickHandler
            onOutsideClick={() => {
              setDisplayStaff(false);
            }}
          >
            <div className="relative w-[370px]">
              <div
                className={`m-3 p-1.5 border-solid border-slate-300 border-[1px] rounded-[10px] flex items-center w-[370px] hover:bg-slate-200 active:bg-slate-300 select-none cursor-pointer ${
                  displayStaff ? 'bg-slate-200' : 'bg-white'
                }`}
                onClick={() => {
                  setDisplayStaff(!displayStaff);
                }}
              >
                <p className="ml-1">
                  {updateStatus === 'controller'
                    ? staffInput || `Asign a ${updateStatus} for selected customer`
                    : planInput || `Asign a ${updateStatus} for selected customer`}
                </p>
                <FaSortDown size={23} className="mt-[-10px] ml-auto mr-1" />
              </div>
              {displayStaff && (
                <div className="absolute bg-white shadow-[rgba(0,0,0,0.25)_2.4px_2.4px_3.2px] mx-3 w-full rounded-[10px] z-10 max-h-[250px] overflow-auto">
                  {updateStatus === 'controller' && (
                    <input
                      type="text"
                      value={searchSale}
                      onChange={(e) => setSearchSale(e.target.value)}
                      placeholder="Search sales"
                      className="p-2 bg-slate-100 rounded-[10px] m-2 w-[96%] px-3.5 focus:white focus:search-column-input outline-none"
                    />
                  )}
                  {updateStatus === 'class' && (
                    <input
                      type="text"
                      value={searchClass}
                      onChange={(e) => setSearchClass(e.target.value)}
                      placeholder="Search class"
                      className="p-2 bg-slate-100 rounded-[10px] m-2 w-[96%] px-3.5 focus:white focus:search-column-input outline-none"
                    />
                  )}
                  {updateStatus === 'controller' &&
                    staffs
                      ?.filter((s) => {
                        return s.displayName.toLowerCase().includes(searchSale.toLowerCase());
                      })
                      .map((member) => {
                        const customerPerStaff = selected.filter((s) => {
                          return s.controller === member.displayName;
                        });
                        return (
                          <div
                            className="m-1 p-1.5 border-slate-300 border-solid border-[1px] rounded-[10px] flex items-center hover:bg-slate-100 active:bg-slate-200 cursor-pointer"
                            onClick={() => {
                              setStaffInput(member.displayName);
                              setDisplayStaff(false);
                            }}
                          >
                            <div className="w-[20px] h-[20px] rounded-full border-slate-300 border-solid border-[1px] flex items-center justify-center bg-white ml-1">
                              {member.displayName === staffInput && (
                                <div className="w-[12px] h-[12px] rounded-full bg-sky-600 flex items-center justify-center"></div>
                              )}
                            </div>
                            <div>
                              <p className="ml-2 leading-3 mt-0.5">{member.displayName}</p>
                              <p className="leading-3 mt-[7px] text-[15px] text-slate-400 ml-[7px]">
                                <span className={`${customerPerStaff?.length === 0 ? '' : 'text-black underline'}`}>
                                  {customerPerStaff.length}
                                </span>{' '}
                                customers served from list
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  {updateStatus === 'class' &&
                    plan
                      ?.filter((s) => {
                        return s.name.toLowerCase().includes(searchClass.toLowerCase());
                      })
                      .map((member) => {
                        return (
                          <div
                            className="m-1 p-1.5 border-slate-300 border-solid border-[1px] rounded-[10px] flex items-center hover:bg-slate-100 active:bg-slate-200 cursor-pointer"
                            onClick={() => {
                              setPlanInput(member.name);
                              setDisplayStaff(false);
                            }}
                          >
                            <div className="w-[20px] h-[20px] rounded-full border-slate-300 border-solid border-[1px] flex items-center justify-center bg-white ml-1">
                              {member.displayName === staffInput && (
                                <div className="w-[12px] h-[12px] rounded-full bg-sky-600 flex items-center justify-center"></div>
                              )}
                            </div>
                            <div>
                              <p className="ml-2 leading-3 mt-0.5">{member.name}</p>
                            </div>
                          </div>
                        );
                      })}
                  {/* {staffs?.map((member) => (
                    <div
                      className="m-1 p-1.5 border-slate-300 border-solid border-[1px] rounded-[10px] flex items-center hover:bg-slate-100 active:bg-slate-200 cursor-pointer"
                      onClick={() => {
                        setStaffInput(member.displayName);
                        setDisplayStaff(false);
                      }}
                    >
                      <div className="w-[20px] h-[20px] rounded-full border-slate-300 border-solid border-[1px] flex items-center justify-center bg-white ml-1">
                        {member.displayName === staffInput && (
                          <div className="w-[12px] h-[12px] rounded-full bg-sky-600 flex items-center justify-center"></div>
                        )}
                      </div>
                      <p className="ml-2">{member.displayName}</p>
                    </div>
                  ))} */}
                </div>
              )}
            </div>
          </OutsideClickHandler>
          {selected?.map((item) => {
            const shipmentPerCustomer = shipments.filter((s) => {
              return s.makh === item.makh;
            });
            return (
              <div>
                <div className="shadow-[rgba(50,50,93,0.15)_0px_2px_5px_-1px,rgba(0,0,0,0.3)_0px_1px_3px_-1px] m-1 my-3 pb-1 rounded-[7px] mx-3 font-semibold">
                  <div
                    className="bg-emerald-50 
                  text-emerald-600  p-1.5 flex items-center cursor-pointer"
                    onClick={() => handleHideShipment(item.makh)}
                  >
                    <FaRegUser size={20} />
                    <p className="rounded-full px-3 bg-white text-black font-normal ml-2">{item.exporter}</p>
                    <p className="ml-auto mr-2">
                      <span className="bg-[darkblue] text-white p-1 rounded-[5px]">
                        {shipmentPerCustomer?.length} shipments found
                      </span>{' '}
                      / {item.controller} / {item.plan}
                    </p>
                    <FaSortDown size={28} className="mt-[-10px]" />
                  </div>
                  {!item.hide &&
                    shipmentPerCustomer.slice(0, item.showAll ? shipmentPerCustomer?.length : 10).map((element) => (
                      <div
                        className="flex items-center m-2 flex-wrap pb-2"
                        style={{ borderBottom: '1px solid rgb(230,230,230)' }}
                      >
                        {!element.loading && !element.updateStatus && (
                          <div className="w-[30px] h-[30px] flex items-center justify-center mr-1 border-slate-300 border-solid border-[1px] rounded-full">
                            {element.type === 'DOX' ? <IoDocumentTextSharp size={23} /> : <GoPackage size={23} />}
                          </div>
                        )}
                        {element.loading && (
                          <div className="w-[30px] h-[30px] flex items-center justify-center mr-1 border-slate-300 border-solid border-[1px] rounded-full">
                            <CircularProgress size={20} />
                          </div>
                        )}
                        {!element.loading && element.updateStatus === 'true' && (
                          <div className="w-[30px] h-[30px] flex items-center justify-center mr-1 border-slate-300 border-solid border-[1px] rounded-full">
                            <FcOk size={22} />
                          </div>
                        )}
                        {!element.loading && element.updateStatus === 'false' && (
                          <div className="w-[30px] h-[30px] flex items-center justify-center mr-1 border-slate-300 border-solid border-[1px] rounded-full">
                            <FcLeave size={22} />
                          </div>
                        )}
                        <p className="mx-1 w-[158px] text-[darkblue]">{element.hawb}</p>
                        <div className="flex items-center w-[200px]">
                          <img
                            src={`https://flagsapi.com/${element.code}/shiny/64.png`}
                            className="w-[23px] h-[23px] mr-2 ml-[1px]"
                          />
                          <p>{element.country}</p>
                        </div>
                        <p className="w-[200px] text-orange-600 underline">{element.chargeable} KG</p>
                        <p className="w-[200px] text-slate-400 font-normal">
                          RW: <span className="text-black font-semibold">{element.reweight} KG</span>
                        </p>
                        <p className="w-[200px] text-slate-400 font-normal">
                          Rate: <span className="text-emerald-600 font-semibold">${element.rate}</span>
                        </p>
                        <p className="w-[200px] text-slate-400 font-normal">
                          Extra: <span className="text-emerald-600  font-semibold">${element.comestic}</span>
                        </p>
                        <p className="w-[200px] text-slate-400 font-normal">
                          Add correct:{' '}
                          <span className="text-emerald-600  font-semibold">${element.addressCorrection}</span>
                        </p>
                        <p className="w-[200px] text-slate-400 font-normal">
                          Remote: <span className="text-emerald-600  font-semibold">${element.remote}</span>
                        </p>
                        <p className="w-[200px] text-slate-400 font-normal">
                          Extra 1: <span className="text-emerald-600  font-semibold">${element.surcharge}</span>
                        </p>
                        <p className="w-[200px] text-slate-400 font-normal">
                          FSC:{' '}
                          <span className="font-semibold text-emerald-600 ">
                            $
                            {element.fscValue.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </span>
                        </p>
                        <p className="w-[200px] text-slate-400 font-normal">
                          Extra 2:{' '}
                          <span className="text-emerald-600  font-semibold">
                            $
                            {element.surcharge2.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </span>
                        </p>
                        <p className="w-[200px] text-slate-400 font-normal">
                          VAT:{' '}
                          <span className="text-emerald-600  font-semibold">
                            $
                            {element.vatValue.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </span>
                        </p>
                        <p className="w-[200px] text-slate-400 font-normal">
                          Extra 3:{' '}
                          <span className="text-emerald-600  font-semibold">
                            $
                            {element.surcharge3.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </span>
                        </p>
                        <p className="w-[200px] text-[purple] font-normal">
                          Total value:{' '}
                          <span className="text-rose-500  font-semibold underline bg-rose-50 px-1.5">
                            $
                            {element.totalValue.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </span>
                        </p>
                        <p className="w-[200px] text-black font-normal ">
                          Controller:{' '}
                          <span className="text-black  font-semibold underline bg-slate-100 px-1.5">
                            {element.controller}
                          </span>
                        </p>
                        <p className="w-[200px] text-black font-normal">
                          Class:{' '}
                          <span className="text-black  font-semibold underline bg-slate-100 px-1.5">
                            {element.plan}
                          </span>
                        </p>
                      </div>
                    ))}
                  {!item.hide && (
                    <div
                      className="m-2 p-2 bg-slate-100 rounded-[10px] flex items-center cursor-pointer hover:bg-slate-200 active:bg-slate-300"
                      onClick={() => {}}
                    >
                      <MdOutlineSubdirectoryArrowRight size={23} />
                      <p className="ml-1.5">View all shipments {shipmentPerCustomer.length}</p>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          <div className="sticky bottom-0 z-3 bg-white" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
            <div className="p-2">
              <p className="ml-2">
                Warning: please wait until all the showing shipments finish the loading part, do not refresh or close
                the browser while updating
              </p>
              {loading && (
                <div
                  className={`p-1.5 m-1.5 px-3.5 bg-emerald-500 text-white rounded-[10px] text-[20px] font-semibold text-center hover:bg-emerald-600 active:bg-emerald-700 select-none opacity-50 cursor-not-allowed flex items-center justify-center`}
                >
                  <CircularProgress size={25} className="mr-2" style={{ color: 'white' }} />
                  <p>System is updating shipments, please wait and don't refresh page or close the browser</p>
                </div>
              )}
              {updateStatus === 'controller' && !loading && (
                <div
                  className={`p-1.5 m-1.5 px-3.5 bg-emerald-500 text-white rounded-[10px] text-[20px] font-semibold text-center hover:bg-emerald-600 active:bg-emerald-700 select-none ${
                    staffInput === '' ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                  }`}
                  onClick={() => {
                    if (staffInput !== '') {
                      handleUpdateNewController();
                    }
                  }}
                >
                  <p>{staffInput === '' ? `Please select a ${updateStatus} to update` : 'Update'}</p>
                </div>
              )}
              {updateStatus === 'class' && !loading && (
                <div
                  className={`p-1.5 m-1.5 px-3.5 bg-emerald-500 text-white rounded-[10px] text-[20px] font-semibold text-center hover:bg-emerald-600 active:bg-emerald-700 select-none ${
                    planInput === '' ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                  }`}
                  onClick={() => {
                    if (planInput !== '') {
                      handleUpdateNewClass();
                    }
                  }}
                >
                  <p>{planInput === '' ? `Please select a ${updateStatus} to update` : 'Update'}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
