import React, { useState, useEffect } from 'react';
import { IoIosSearch, IoMdMore } from 'react-icons/io';
import { BsColumnsGap } from 'react-icons/bs';
import { FaFileInvoice, FaSortDown } from 'react-icons/fa6';
import OutsideClickHandler from 'react-outside-click-handler';
import { Snackbar, TextareaAutosize, Alert, CircularProgress, IconButton, Checkbox } from '@mui/material';
import { MdOutlineClear } from 'react-icons/md';
import { IoCreate } from 'react-icons/io5';
import { GiCardPickup } from 'react-icons/gi';
import { FiDownload, FiRefreshCcw, FiUpload } from 'react-icons/fi';
import { GoPackage } from 'react-icons/go';
import { IoDocument } from 'react-icons/io5';
import { db } from '../../../../server/firebase';
import firebase from 'firebase/compat/app';
import './NewBookingFilterBar.css';
import * as XLSX from 'xlsx';
import { GrDocumentPdf } from 'react-icons/gr';

import { VscSend } from 'react-icons/vsc';
import emptyfile from '../assets/emptyfile.png';
import { TiArrowBackOutline } from 'react-icons/ti';

import { v1 as uuid } from 'uuid';
import axios from 'axios';
import { FaRegTrashAlt, FaUpload } from 'react-icons/fa';

import { saveAs } from 'file-saver';
import { CSVLink } from 'react-csv';
import { FcOk } from 'react-icons/fc';
export default function NewBookingFilterBar({
  searchInput,
  handleSearchShipment,
  handleBookingType,
  handleBookingStatus,
  handleCloseBookingStatus,
  bookingTypeList,
  onSearchFocus,
  displaySearchOption,
  onCloseSearch,
  headerGroups,
  handleSelectedHeader,
  handleSelectedAllHeader,
  selected,
  resetSearch,
  user,
  startDay,
  setSelectedShipment,
  endDay,
  fetchShipment,
  bookingType,
  displayBookingStatus,
  fetchMultipleSearched,
}) {
  const [displayColumn, setDisplayColumn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [displayBookingType, setDisplayBookingType] = useState(false);
  const [displayAction, setDisplayAction] = useState(false);
  const [displayPickupConfirm, setDisplayPickupConfirm] = useState(false);
  const [displayExport, setDisplayExport] = useState(false);
  const [displayImport, setDisplayImport] = useState(false);
  const [searchMultipleShipment, setSearchMultipleShipment] = useState('');
  const [traceTime, setTraceTime] = useState('');
  const [dateTime, setDateTime] = useState('');
  const [fileName, setFileName] = useState('');
  const [exportStatus, setExportStatus] = useState('');
  const [includeInfo, setIncludeInfo] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileListUrl, setFileListUrl] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const [trackResult, setTrackResult] = useState('');
  useEffect(() => {
    let newDate = new Date();
    let date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
    let year = newDate.getFullYear();
    const monthName = months[newDate.getMonth()];
    setTraceTime(`${year}-${month}-${date}`);
    const hour = (newDate.getHours() < 10 ? '0' : '') + newDate.getHours();
    const min = (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes();
    setDateTime(`${hour}:${min}`);
  }, []);
  const handleColumn = () => {
    setDisplayColumn(!displayColumn);
  };

  const handleChangeFile = (e) => {
    e.preventDefault();
    if (e.target.files.length === 0) {
      return;
    } else if (e.target.files.length === 5) {
      alert('Bạn chỉ được đăng tối đa 5 tệp');
    } else {
      let arr = [];
      for (let i = 0; i < e.target.files.length; i++) {
        arr.push({
          file: e.target.files[i],
          name: e.target.files[i].name,
          id: uuid(),
        });
      }
      setFileList([...arr]);
      e.target.value = null;
      return;
    }
  };
  const bookedShipment = (labelUrl) => {
    exportSelected.forEach((s) => {
      db.collection('shipments')
        .doc(s.hawb)
        .update({
          booked: 'true',
          bookedBy: user.displayName,
          labelUrl: labelUrl,
        })
        .then(() => {
          setSelectedShipment(
            selected.map((val) => {
              let value = 'true';
              if (val.hawb === s.hawb) {
                val.booked = value;
                val.labelUrl = labelUrl;
              }
              return val;
            })
          );
          setAlertSuccess(true);
        });
    });
  };

  const loadingLabel = fileListUrl.filter((s) => {
    return s.loading;
  });

  const emptyHawb = fileListUrl.filter((s) => {
    return s.hawb === '';
  });

  const publishLabel = async () => {
    fileListUrl.forEach(async (item) => {
      setSelectedItem(
        fileListUrl.map((el) => {
          if (item.id === el.id) {
            item.loading = true;
          }
          return el;
        })
      );
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(item.name);
      try {
        await fileRef.put(item.file);
        const url = await fileRef.getDownloadURL();
        await db
          .collection('label')
          .add({
            hawb: item.hawb,
            receiver: item.receiver,
            url: url,
            amount: 1,
            fileName: item.name,
            email: item.email,
            chargeable: item.chargeable,
            shipmentType: item.shipmentType,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            date: traceTime,
          })
          .then(() => {
            setTimeout(() => {
              setSelectedItem(
                fileListUrl.map((sd, index) => {
                  if (item.id === sd.id) {
                    sd.loading = false;
                    sd.success = true;
                    sd.hawb = '';
                    sd.url = '';
                    sd.email = '';
                    sd.receiver = '';
                  }
                  return sd;
                })
              );
              bookedShipment(url);
            }, 2000);
          });
        // alert('Files uploaded successfully');
        // setLoading(false);
      } catch (error) {
        console.error('Error uploading files: ', error);
        alert('Error uploading files. Please try again.');
      }
    });
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      setFileListUrl([
        ...fileListUrl,
        {
          url: URL.createObjectURL(file),
          name: file.name,
          type: file.type,
          hawb: '',
          open: false,
          receiver: '',
          email: '',
          id: uuid(),
          file: file,
        },
      ]);
    } else if (file.length > 1) {
      return;
    }
    e.target.value = null;
  };
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  useEffect(() => {
    if (fileList.length < 1) return;
    const newImageUrls = [];
    for (let i = 0; i < fileList.length; i++) {
      newImageUrls.push({
        url: URL.createObjectURL(fileList[i].file),
        name: fileList[i].name,
        type: fileList[i].type,
        hawb: '',
        open: false,
        receiver: '',
        email: '',
        id: fileList[i].id,
        file: fileList[i].file,
        playing: false,
      });
    }
    setFileListUrl([...fileListUrl, ...newImageUrls]);
  }, [fileList]);

  const handleAction = () => {
    if (selected?.length === 0) return;
    setDisplayAction(!displayAction);
  };
  const closeAction = () => {
    setDisplayAction(false);
  };
  const onPickupConfirm = () => {
    setDisplayAction(false);
    setDisplayPickupConfirm(!displayPickupConfirm);
  };

  const handleExport = () => {
    if (selected?.length === 0) return;
    setDisplayExport(!displayExport);
  };
  const handleImport = () => {
    setDisplayImport(!displayImport);
  };
  const exportSelected = selected
    .sort((a, b) => {
      if (includeInfo) {
        a = a.sender || '';
        b = b.sender || '';
        return a.localeCompare(b);
      }
    })
    .map((s, index) => {
      return {
        no: s.no,
        plan: s.plan,
        sender: s.sender,
        hawb: s.hawb,
        date: s.date,
        fromCity: s.fromCity,
        country: s.country,
        status: s.status,
        type: s.type,
        chargeable: s.chargeable,
        exactWeight: s.exactWeight,
        exactCharge: s.exactCharge,
        reweight: s.reweight,
        amount: s.amount,
        pack: s.pack,
        airline: s.airline,
        service: s.service,
        agent: s.agent,
        mawb: s.mawb,
        sin: s.sin,
        rate: s.rate,
        comestic: s.comestic,
        addressCorrection: s.addressCorrection,
        remote: s.remote,
        surcharge: s.surcharge,
        fsc: s.fsc,
        fscValue: s.fscValue,
        surcharge2: s.surcharge2,
        vat: s.vat,
        vatValue: s.vatValue,
        surcharge3: s.surcharge3,
        totalValue: s.totalValue,
        rateUpdate: s.rateUpdate,
        controller: s.controller,
        paymentAcc: s.paymentAcc,
        paymentCompany: s.paymentCompany,
        note: s.note,
        sellNote: s.sellNote,
        accNote: s.accNote,
        pickupNote: s.pickupNote,
        gnNote: s.gnNote,
        account: s.account,
        eamService: s.eamService,
        company: s.company,
        contact: s.contact,
        code: s.code,
        postal: s.postal,
        phone: s.phone,
        eamFormat: s.eamFormat,
        description: s.description,
        value: s.value,
        fromEmail: s.fromEmail,

        currency: s.currency,
        packageValue: s.packageValue,
        invoiceValue: s.invoiceValue,
        // itemName: s.itemName,
        // itemName2: s.itemName2,
        // itemName3: s.itemName3,
        // itemName4: s.itemName4,
        // itemName5: s.itemName5,
        // itemName6: s.itemName6,
        // itemName7: s.itemName7,
        // itemName8: s.itemName8,
        // itemName9: s.itemName9,
        // itemName10: s.itemName10,
        // itemName11: s.itemName11,
        // itemName12: s.itemName12,
        // itemName13: s.itemName13,
        // itemName14: s.itemName14,
        // itemName15: s.itemName15,
        // itemName16: s.itemName16,
        // itemName17: s.itemName17,
        // itemName18: s.itemName18,
        // itemName19: s.itemName19,
        // itemName20: s.itemName20,
        // quantity: s.quantity,
        // quantity: s.quantity,
        // quantity: s.quantity,
        // quantity: s.quantity,
        // quantity: s.quantity,
        // quantity: s.quantity,
        // quantity: s.quantity,
        // quantity: s.quantity,
        // quantity: s.quantity,
        // quantity: s.quantity,
        // quantity: s.quantity,
        // quantity: s.quantity,
        // quantity: s.quantity,
        // quantity: s.quantity,
        // quantity: s.quantity,
        // quantity: s.quantity,
        // quantity: s.quantity,
        // quantity: s.quantity,
        // quantity: s.quantity,
        // quantity: s.quantity,
      };
    });

  const exportToExcel = () => {
    const data = prepareDataForExport();
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'exported_data.xlsx');
  };
  const prepareDataForExport = () => {
    let preparedData = [
      [
        'Order Number',
        'Consignee',
        'ID Card Number',
        'ConsigneePhone',
        'Consignee CellPhone',
        'Email',
        'Address 1',
        'Address 2',
        'City',
        'Suburb',
        'Province',
        'Zipcode',
        'ISO3166',
        'Description',
        'Qty',
        'Weight (kg)',
        'Value',
        'Currency',
        'H (cm)',
        'W (cm)',
        'L (cm)',
        'SKU',
        'SKU Quantity',
        'HsCode',
        'Service Type',
        'Remark',
        'Seller Account',
        "Sender's Name",
        "Sender's Tel",
        "Sender's Country",
        "Sender's State",
        "Sender's City",
        "Sender's Address",
        "Sender's Address1",
        "Sender's ZipCode",
        "Sender's Email",
        'Sender VAT',
        'dutyPaid',
        'taxCode',
        'ABN',
        'GST Exemption Code',
        'CH VAT No.',
        'CH Register Name',
        'Origin Country',
        'SKU1',
        'SK1 Quantity',
        'SKU2',
        'SKU2 Quantity',
        'SKU3',
        'SKU3 Quantity',
        'SKU4',
        'SKU4 Quantity',
        'SKU5',
        'SKU5 Quantity',
        'SKU6',
        'SKU6 Quantity',
        'SKU7',
        'SKU7 Quantity',
        'SKU8',
        'SKU8 Quantity',
        'SKU9',
        'SKU9 Quantity',
        'SKU10',
        'SKU10 Quantity',
        'Warehouse ID',
        'DescriptionCN',
        'CPF No.',
        'PostagePrice',
        'PostageCurrency',
        'IOSS number',
        'ProductURL',
        'Additional Service',
      ],
    ]; // Add headers
    let previousHawb = null; // Variable to store the previous hawb value

    selected?.forEach((shipment, index) => {
      if (shipment.type === 'DOX') {
        const currentHawb = shipment.removeSkd;
        const currentD3pConsignee = currentHawb === previousHawb ? '' : shipment.contact;
        const currentConsigneePhone = currentHawb === previousHawb ? '' : shipment.phone;
        const currentConsigneeEmail = currentHawb === previousHawb ? '' : shipment.toEmail;
        const currentAddress = currentHawb === previousHawb ? '' : shipment.address;
        const currentAddress2 = currentHawb === previousHawb ? '' : shipment.address2;
        const currentCity = currentHawb === previousHawb ? '' : shipment.city;
        const currentTown = currentHawb === previousHawb ? '' : shipment.town;
        const currentState = currentHawb === previousHawb ? '' : shipment.toState;
        const currentPostal = currentHawb === previousHawb ? '' : shipment.postal;
        const currentCode = currentHawb === previousHawb ? '' : shipment.code;
        const currentDescription = currentHawb === previousHawb ? '' : shipment.description;
        const currentQty = currentHawb === previousHawb ? '' : shipment.amount;
        const currentWeight = currentHawb === previousHawb ? '' : shipment.chargeable;
        const currentValue = currentHawb === previousHawb ? '' : shipment.value;
        const currentCurrency = currentHawb === previousHawb ? '' : shipment.currency;
        const currentServiceType = currentHawb === previousHawb ? '' : shipment.d3pServiceType;
        const currentSenderName = currentHawb === previousHawb ? '' : shipment.d3pSenderName;
        const currentSenderPhone = currentHawb === previousHawb ? '' : shipment.fromPhone;
        const currentSenderCity = currentHawb === previousHawb ? '' : 'VN';
        const currentSenderAddress = currentHawb === previousHawb ? '' : shipment.fromAddress;
        const currentSenderPostal = currentHawb === previousHawb ? '' : shipment.fromPostal;
        const currentSenderEmail = currentHawb === previousHawb ? '' : shipment.fromEmail;
        const currentDutyPaid = currentHawb === previousHawb ? '' : shipment.dutyPaid;
        const currentSenderCountry = currentHawb === previousHawb ? '' : 'Vietnam';
        const currentHsCode = currentHawb === previousHawb ? '' : shipment.hsCode;
        const hawbValue = currentHawb === previousHawb ? '' : currentHawb;

        preparedData.push([
          hawbValue,
          currentD3pConsignee,
          '',
          currentConsigneePhone,
          '',
          currentConsigneeEmail,
          currentAddress,
          currentAddress2,
          currentCity,
          currentTown,
          currentState,
          currentPostal,
          currentCode,
          currentDescription,
          currentQty,
          currentWeight,
          currentValue,
          currentCurrency,
          '1',
          '1',
          '1',
          '',
          '',
          currentHsCode,
          currentServiceType,
          '',
          '',
          currentSenderName,
          currentSenderPhone,
          currentSenderCountry,
          '',
          currentSenderCity,
          currentSenderAddress,
          '',
          currentSenderPostal,
          currentSenderEmail,
          '',
          currentDutyPaid,
          '',
          '',
          '',
          '',
          '',
          currentSenderCountry,
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          currentCurrency,
          '',
          '',
          '',
        ]);

        previousHawb = currentHawb; // Update the previous hawb value
      } else {
        shipment.packageValue?.forEach((packageItem) => {
          const currentHawb = shipment.removeSkd;
          const currentD3pConsignee = currentHawb === previousHawb ? '' : shipment.contact;
          const currentConsigneePhone = currentHawb === previousHawb ? '' : shipment.phone;
          const currentConsigneeEmail = currentHawb === previousHawb ? '' : shipment.toEmail;
          const currentAddress = currentHawb === previousHawb ? '' : shipment.address;
          const currentAddress2 = currentHawb === previousHawb ? '' : shipment.address2;
          const currentCity = currentHawb === previousHawb ? '' : shipment.city;
          const currentTown = currentHawb === previousHawb ? '' : shipment.town;
          const currentState = currentHawb === previousHawb ? '' : shipment.toState;
          const currentPostal = currentHawb === previousHawb ? '' : shipment.postal;
          const currentCode = currentHawb === previousHawb ? '' : shipment.code;
          const currentDescription = currentHawb === previousHawb ? '' : shipment.description;
          const currentQty = currentHawb === previousHawb ? '' : shipment.amount;
          const currentWeight = currentHawb === previousHawb ? '' : shipment.chargeable;
          const currentValue = currentHawb === previousHawb ? '' : shipment.value;
          const currentCurrency = currentHawb === previousHawb ? '' : shipment.currency;
          const currentServiceType = currentHawb === previousHawb ? '' : shipment.d3pServiceType;
          const currentSenderName = currentHawb === previousHawb ? '' : shipment.d3pSenderName;
          const currentSenderPhone = currentHawb === previousHawb ? '' : shipment.fromPhone;
          const currentSenderCity = currentHawb === previousHawb ? '' : 'VN';
          const currentSenderAddress = currentHawb === previousHawb ? '' : shipment.fromAddress;
          const currentSenderPostal = currentHawb === previousHawb ? '' : shipment.fromPostal;
          const currentSenderEmail = currentHawb === previousHawb ? '' : shipment.fromEmail;
          const currentDutyPaid = currentHawb === previousHawb ? '' : shipment.dutyPaid;
          const currentSenderCountry = currentHawb === previousHawb ? '' : 'Vietnam';
          const currentHsCode = currentHawb === previousHawb ? '' : shipment.hsCode;
          const hawbValue = currentHawb === previousHawb ? '' : currentHawb;

          preparedData.push([
            hawbValue,
            currentD3pConsignee,
            '',
            currentConsigneePhone,
            '',
            currentConsigneeEmail,
            currentAddress,
            currentAddress2,
            currentCity,
            currentTown,
            currentState,
            currentPostal,
            currentCode,
            currentDescription,
            currentQty,
            currentWeight,
            currentValue,
            currentCurrency,
            packageItem.packageLength,
            packageItem.packageWidth,
            packageItem.packageHeight,
            '',
            '',
            currentHsCode,
            currentServiceType,
            '',
            '',
            currentSenderName,
            currentSenderPhone,
            currentSenderCountry,
            '',
            currentSenderCity,
            currentSenderAddress,
            '',
            currentSenderPostal,
            currentSenderEmail,
            '',
            currentDutyPaid,
            '',
            '',
            '',
            '',
            '',
            currentSenderCountry,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '0',
            currentCurrency,
            '',
            '',
            '',
          ]);

          previousHawb = currentHawb; // Update the previous hawb value
        });
      }
    });

    return preparedData;
  };

  const onPickupShipment = () => {
    setLoading(true);
    const pickupData = {
      status: 'Picked Up',
      desc: firebase.firestore.FieldValue.arrayUnion({
        status: 'Picked Up',
        time: traceTime + 'T' + dateTime + ':00',
        location: 'VIETNAM - VIETNAM - HCM',
        stat: 'Shipment picked up by Skydart',
      }),
      pickupBy: user?.displayName,
    };
    selected?.forEach((s, index) => {
      db.collection('shipments')
        .doc(s.hawb)
        .update(pickupData)
        .then(() => {
          if (s.type === 'WPX') {
            axios.post('https://test.skydart.com.vn/api/create/shipment', {
              account: '786009',
              currency: 'USD',
              service: s.service,
              date: traceTime,
              hawb: s.hawb,
              mawb: 'Pending',
              makh: s.makh,
              company: s.company,
              contact: s.contact,
              otherTerm: s.otherTerm,
              type: s.type,
              status: 'Picked Up',
              term: s.term,
              direct: s.direct,
              time: dateTime,
              address: s.address,
              address2: s.address2 || '.',
              address3: s.address3,
              city: s.city,
              country: s.country,
              code: s.code,
              phone: s.phone,
              stateCode: s.stateCode,
              phone2: s.phone2 || '.',
              postal: s.postal,
              state: s.state,
              plan: s.plan || 'Pending',
              value: s.value,
              town: s.town,
              chargeable: s.chargeable,
              amount: s.amount,
              totalItem: s.totalItem,
              transport: s.transport,
              description: s.description,
              controller: s.controller || 'Pending',
              eamFormat: 'NDX',
              dateTime: new Date(),
              airline: 'Pending',
              reweight: s.reweight,
              etd: 'Pending',
              agent: 'Pending',
              subStatus: 'Pending',
              sin: 'Null',
              sender: s.sender,
              note: 'Null',
              perform: 'Null',
              fromEmail: s.fromEmail,
              fromCity: s.fromCity,
              totalValue: '0.00',
              rate: s.rate || '0.00',
              comestic: '0.00',
              addressCorrection: '0.00',
              remote: '0.00',
              surcharge: '0.00',
              surcharge2: '0.00',
              surcharge3: '0.00',
              fsc: s.fsc || 0,
              fscValue: s.fscValue,
              vat: '0.00',
              vatValue: '0.00',
              paymentPickup: 'CRE',
              paymentAcc: 'CRE',
              paymentCompany: 'CRE',
              paymentStatus: 'Un Paid',
              pickupBy: user.displayName,
              collectBy: 'Pending',
              rateUpdate: s.date,
              sellNote: 'Pending',
              com: '0.00',
              sellNote: 'Pending',
              paymentSubStatus: 'Rebook',
              eamService: s.eamService,
              usage: s.usage,
              pack: 'Pending',
            });
          } else if (s.type === 'DOX') {
            axios.post(' https://test.skydart.com.vn/api/create/shipment', {
              account: '786009',
              currency: 'USD',
              service: s.service,
              date: traceTime,
              hawb: s.hawb,
              mawb: 'Pending',
              makh: s.makh,
              company: s.company,
              contact: s.contact,
              otherTerm: s.otherTerm,
              type: s.type,
              status: 'Picked Up',
              term: s.term,
              time: s.time,
              direct: s.direct,
              address: s.address,
              address2: s.address2 || '.',
              address3: s.address3,
              city: s.city,
              country: s.country,
              code: s.code,
              phone: s.phone,
              phone2: s.phone2 || '.',
              postal: s.postal,
              state: s.state,
              value: s.value,
              town: s.town,
              plan: s.plan || 'Pending',
              chargeable: s.chargeable,
              amount: s.amount,
              totalItem: s.totalItem,
              transport: s.trasnport,
              description: 'Document',
              controller: s.controller || 'Pending',
              eamFormat: 'DOX',
              dateTime: new Date(),
              airline: 'Pending',
              reweight: s.reweight,
              etd: 'Pending',
              agent: 'Pending',
              subStatus: 'Pending',
              sin: 'Null',
              sender: s.sender,
              note: 'Null',
              perform: 'Null',
              fromEmail: s.fromEmail,
              fromCity: s.fromCity,
              totalValue: '0.00',
              rate: s.rate || '0.00',
              comestic: '0.00',
              addressCorrection: '0.00',
              remote: '0.00',
              surcharge: '0.00',
              surcharge2: '0.00',
              surcharge3: '0.00',
              fsc: s.fsc || 0,
              fscValue: s.fscValue,
              vat: '0.00',
              vatValue: '0.00',
              paymentPickup: 'CRE',
              paymentAcc: 'CRE',
              paymentCompany: 'CRE',
              paymentStatus: 'Un Paid',
              pickupBy: user?.displayName,
              collectBy: 'Pending',
              rateUpdate: s.date,
              sellNote: 'Pending',
              com: '0.00',
              sellNote: 'Pending',
              paymentSubStatus: 'Rebook',
              eamService: s.eamService,
              usage: s.usage,
              pack: 'Pending',
            });
          }
          if (index === selected?.length - 1) {
            setLoading(false);
            setAlertSuccess(true);
            setDisplayPickupConfirm(false);
          }
        });
    });
  };
  const getArrayMax = (array) => {
    return Math.max.apply(null, array);
  };
  const highestItem = getArrayMax(exportSelected.map((s) => s.invoiceValue.length));
  const highestDim = getArrayMax(exportSelected.map((s) => s.packageValue.length));
  function resetSuccess(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setAlertSuccess(false);
  }
  const getSelected = selected.map((s) => {
    return s;
  });
  const mockHeader = headerGroups[0].headers.filter((s) => {
    return s.active;
  });
  const trueHeader = mockHeader.map((s) => {
    return {
      label: s.Header,
      key: s.key,
    };
  });

  const linexHeader = [
    { label: 'Nos', key: 'no' },
    { label: 'customer_ref', key: 'hawb' },
    { label: 'tracking_number', key: '' },
    { label: 'sender_name', key: 'sender' },
    { label: 'sender_tel', key: 'fromPhone' },
    { label: 'sender_address', key: 'fromAddress' },
    { label: 'consignee_name', key: 'importer' },
    { label: 'receiverContactPerson', key: 'contact' },
    { label: 'consignee_address', key: 'address' },
    { label: 'consignee_country', key: 'code' },
    { label: 'consignee_province', key: 'state' },
    { label: 'consignee_city', key: 'city' },
    { label: 'consignee_town', key: 'town' },
    { label: 'consignee_zip', key: 'postal' },
    { label: 'consignee_tel', key: 'phone' },
    { label: '' },
  ];
  const eamHeader = [
    { label: 'ACCOUNT', key: 'account' },
    { label: 'HAWB', key: 'removeSkd' },
    { label: 'SERVICE', key: 'eamService' },
    { label: 'REF', key: '' },
    { label: 'DATE', key: 'date' },
    { label: 'COMPANY', key: 'company' },
    { label: 'CONTACT', key: 'contact' },
    { label: 'ADDRESS1', key: 'address' },
    { label: 'ADDRESS2', key: 'address2' },
    { label: 'ADDRESS3', key: 'address3' },
    { label: 'TOWN', key: 'town' },
    { label: 'COUNTRY', key: 'code' },
    { label: 'POSTCODE', key: 'postal' },
    { label: 'TELNO', key: 'phone' },
    { label: 'NO. OF PCS', key: 'amount' },
    { label: 'WEIGHT', key: 'chargeable' },
    { label: 'DOX/NDX', key: 'eamFormat' },
    { label: 'DESCRIP', key: 'descriptionCut' },
    { label: 'VALUE', key: 'value' },
    { label: 'CURRENCY', key: 'currency' },
    { label: 'NOTES / PLS USE DHL#', key: 'note' },
    { label: 'email', key: 'toEmail' },
    { label: 'shipper_company', key: 'sender' },
    { label: 'shipper_contact_person', key: 'fromName' },
    { label: 'shipper_address_line1', key: 'shortAddress' },
    { label: 'shipper_address_line2', key: 'fromAddress2' },
    { label: 'shipper_city', key: 'fromCity' },
    { label: 'shipper_postcode', key: 'fromPostal' },
    { label: 'shipper_country', key: 'fromCode' },
    { label: 'shipper_telephone', key: 'fromPhone' },
    { label: 'dhl_awb', key: 'sin' },
    { label: 'other_charges_1', key: 'transport' },
    { label: 'export_reason_type', key: '' },
    { label: 'export_reason', key: 'reason' },
    { label: 'tax_vat_id', key: 'taxIT' },
    { label: '', key: 'dimSlash' },
    { label: 'length', key: 'lengthValue' },
    { label: 'width', key: 'widthValue' },
    { label: 'height', key: 'heightValue' },
    highestDim >= 2 ? { label: '', key: 'dimSlash2' } : { label: '', key: '' },
    highestDim >= 2 ? { label: 'length', key: 'lengthValue2' } : { label: '', key: '' },
    highestDim >= 2 ? { label: 'width', key: 'widthValue2' } : { label: '', key: '' },
    highestDim >= 2 ? { label: 'height', key: 'heightValue2' } : { label: '', key: '' },
    highestDim >= 3 ? { label: '', key: 'dimSlash3' } : { label: '', key: '' },
    highestDim >= 3 ? { label: 'length', key: 'lengthValue3' } : { label: '', key: '' },
    highestDim >= 3 ? { label: 'width', key: 'widthValue3' } : { label: '', key: '' },
    highestDim >= 3 ? { label: 'height', key: 'heightValue3' } : { label: '', key: '' },
    highestDim >= 4 ? { label: '', key: 'dimSlash4' } : { label: '', key: '' },
    highestDim >= 4 ? { label: 'length', key: 'lengthValue4' } : { label: '', key: '' },
    highestDim >= 4 ? { label: 'width', key: 'widthValue4' } : { label: '', key: '' },
    highestDim >= 4 ? { label: 'height', key: 'heightValue4' } : { label: '', key: '' },
    highestDim >= 5 ? { label: '', key: 'dimSlash5' } : { label: '', key: '' },
    highestDim >= 5 ? { label: 'length', key: 'lengthValue5' } : { label: '', key: '' },
    highestDim >= 5 ? { label: 'width', key: 'widthValue5' } : { label: '', key: '' },
    highestDim >= 5 ? { label: 'height', key: 'heightValue5' } : { label: '', key: '' },
    highestDim >= 6 ? { label: '', key: 'dimSlash6' } : { label: '', key: '' },
    highestDim >= 6 ? { label: 'length', key: 'lengthValue6' } : { label: '', key: '' },
    highestDim >= 6 ? { label: 'width', key: 'widthValue6' } : { label: '', key: '' },
    highestDim >= 6 ? { label: 'height', key: 'heightValue6' } : { label: '', key: '' },
    highestDim >= 7 ? { label: '', key: 'dimSlash7' } : { label: '', key: '' },
    highestDim >= 7 ? { label: 'length', key: 'lengthValue7' } : { label: '', key: '' },
    highestDim >= 7 ? { label: 'width', key: 'widthValue7' } : { label: '', key: '' },
    highestDim >= 7 ? { label: 'height', key: 'heightValue7' } : { label: '', key: '' },
    highestDim >= 8 ? { label: '', key: 'dimSlash8' } : { label: '', key: '' },
    highestDim >= 8 ? { label: 'length', key: 'lengthValue8' } : { label: '', key: '' },
    highestDim >= 8 ? { label: 'width', key: 'widthValue8' } : { label: '', key: '' },
    highestDim >= 8 ? { label: 'height', key: 'heightValue8' } : { label: '', key: '' },
    highestDim >= 9 ? { label: '', key: 'dimSlash9' } : { label: '', key: '' },
    highestDim >= 9 ? { label: 'length', key: 'lengthValue9' } : { label: '', key: '' },
    highestDim >= 9 ? { label: 'width', key: 'widthValue9' } : { label: '', key: '' },
    highestDim >= 9 ? { label: 'height', key: 'heightValue9' } : { label: '', key: '' },
    highestDim >= 10 ? { label: '', key: 'dimSlash10' } : { label: '', key: '' },
    highestDim >= 10 ? { label: 'length', key: 'lengthValue10' } : { label: '', key: '' },
    highestDim >= 10 ? { label: 'width', key: 'widthValue10' } : { label: '', key: '' },
    highestDim >= 10 ? { label: 'height', key: 'heightValue10' } : { label: '', key: '' },
    highestDim >= 11 ? { label: '', key: 'dimSlash11' } : { label: '', key: '' },
    highestDim >= 11 ? { label: 'length', key: 'lengthValue11' } : { label: '', key: '' },
    highestDim >= 11 ? { label: 'width', key: 'widthValue11' } : { label: '', key: '' },
    highestDim >= 11 ? { label: 'height', key: 'heightValue11' } : { label: '', key: '' },
    highestDim >= 12 ? { label: '', key: 'dimSlash12' } : { label: '', key: '' },
    highestDim >= 12 ? { label: 'length', key: 'lengthValue12' } : { label: '', key: '' },
    highestDim >= 12 ? { label: 'width', key: 'widthValue12' } : { label: '', key: '' },
    highestDim >= 12 ? { label: 'height', key: 'heightValue12' } : { label: '', key: '' },
    highestDim >= 13 ? { label: '', key: 'dimSlash13' } : { label: '', key: '' },
    highestDim >= 13 ? { label: 'length', key: 'lengthValue13' } : { label: '', key: '' },
    highestDim >= 13 ? { label: 'width', key: 'widthValue13' } : { label: '', key: '' },
    highestDim >= 13 ? { label: 'height', key: 'heightValue13' } : { label: '', key: '' },
    highestDim >= 14 ? { label: '', key: 'dimSlash14' } : { label: '', key: '' },
    highestDim >= 14 ? { label: 'length', key: 'lengthValue14' } : { label: '', key: '' },
    highestDim >= 14 ? { label: 'width', key: 'widthValue14' } : { label: '', key: '' },
    highestDim >= 14 ? { label: 'height', key: 'heightValue14' } : { label: '', key: '' },
    highestDim >= 15 ? { label: '', key: 'dimSlash15' } : { label: '', key: '' },
    highestDim >= 15 ? { label: 'length', key: 'lengthValue15' } : { label: '', key: '' },
    highestDim >= 15 ? { label: 'width', key: 'widthValue15' } : { label: '', key: '' },
    highestDim >= 15 ? { label: 'height', key: 'heightValue15' } : { label: '', key: '' },
    highestDim >= 16 ? { label: '', key: 'dimSlash16' } : { label: '', key: '' },
    highestDim >= 16 ? { label: 'length', key: 'lengthValue16' } : { label: '', key: '' },
    highestDim >= 16 ? { label: 'width', key: 'widthValue16' } : { label: '', key: '' },
    highestDim >= 16 ? { label: 'height', key: 'heightValue16' } : { label: '', key: '' },
    highestDim >= 17 ? { label: '', key: 'dimSlash17' } : { label: '', key: '' },
    highestDim >= 17 ? { label: 'length', key: 'lengthValue17' } : { label: '', key: '' },
    highestDim >= 17 ? { label: 'width', key: 'widthValue17' } : { label: '', key: '' },
    highestDim >= 17 ? { label: 'height', key: 'heightValue17' } : { label: '', key: '' },
    highestDim >= 18 ? { label: '', key: 'dimSlash18' } : { label: '', key: '' },
    highestDim >= 18 ? { label: 'length', key: 'lengthValue18' } : { label: '', key: '' },
    highestDim >= 18 ? { label: 'width', key: 'widthValue18' } : { label: '', key: '' },
    highestDim >= 18 ? { label: 'height', key: 'heightValue18' } : { label: '', key: '' },
    highestDim >= 19 ? { label: '', key: 'dimSlash19' } : { label: '', key: '' },
    highestDim >= 19 ? { label: 'length', key: 'lengthValue19' } : { label: '', key: '' },
    highestDim >= 19 ? { label: 'width', key: 'widthValue19' } : { label: '', key: '' },
    highestDim >= 19 ? { label: 'height', key: 'heightValue19' } : { label: '', key: '' },
    highestDim >= 20 ? { label: '', key: 'dimSlash20' } : { label: '', key: '' },
    highestDim >= 20 ? { label: 'length', key: 'lengthValue20' } : { label: '', key: '' },
    highestDim >= 20 ? { label: 'width', key: 'widthValue20' } : { label: '', key: '' },
    highestDim >= 20 ? { label: 'height', key: 'heightValue20' } : { label: '', key: '' },
    { label: '', key: 'slash' },
    { label: 'item number', key: 'item_number' },
    { label: 'qty', key: 'quantity' },
    { label: 'Qty unit', key: 'unit' },
    { label: 'Item description', key: 'itemName' },
    { label: 'Unit Price', key: 'unitPrice' },
    { label: 'net weight', key: 'itemGrossWeight' },
    { label: 'gross weight', key: 'itemGrossWeight' },
    { label: 'country manufactured', key: 'countryManufactured' },
    { label: 'hs code', key: 'hsCode' },
    highestItem >= 2 ? { label: '', key: 'slash2' } : { label: '', key: '' },
    highestItem >= 2 ? { label: 'item number', key: 'item_number2' } : { label: '', key: '' },
    highestItem >= 2 ? { label: 'qty', key: 'quantity2' } : { label: '', key: '' },
    highestItem >= 2 ? { label: 'Qty unit', key: 'unit2' } : { label: '', key: '' },
    highestItem >= 2 ? { label: 'Item description', key: 'itemName2' } : { label: '', key: '' },
    highestItem >= 2 ? { label: 'Unit Price', key: 'unitPrice2' } : { label: '', key: '' },
    highestItem >= 2 ? { label: 'net weight', key: 'itemGrossWeight2' } : { label: '', key: '' },
    highestItem >= 2 ? { label: 'gross weight', key: 'itemGrossWeight2' } : { label: '', key: '' },
    highestItem >= 2 ? { label: 'country manufactured', key: 'countryManufactured2' } : { label: '', key: '' },
    highestItem >= 2 ? { label: 'hs code', key: 'hsCode2' } : { label: '', key: '' },
    highestItem >= 3 ? { label: '', key: 'slash3' } : { label: '', key: '' },
    highestItem >= 3 ? { label: 'item number', key: 'item_number3' } : { label: '', key: '' },
    highestItem >= 3 ? { label: 'qty', key: 'quantity3' } : { label: '', key: '' },
    highestItem >= 3 ? { label: 'Qty unit', key: 'unit3' } : { label: '', key: '' },
    highestItem >= 3 ? { label: 'Item description', key: 'itemName3' } : { label: '', key: '' },
    highestItem >= 3 ? { label: 'Unit Price', key: 'unitPrice3' } : { label: '', key: '' },
    highestItem >= 3 ? { label: 'net weight', key: 'itemGrossWeight3' } : { label: '', key: '' },
    highestItem >= 3 ? { label: 'gross weight', key: 'itemGrossWeight3' } : { label: '', key: '' },
    highestItem >= 3 ? { label: 'country manufactured', key: 'countryManufactured3' } : { label: '', key: '' },
    highestItem >= 3 ? { label: 'hs code', key: 'hsCode3' } : { label: '', key: '' },
    highestItem >= 4 ? { label: '', key: 'slash4' } : { label: '', key: '' },
    highestItem >= 4 ? { label: 'item number', key: 'item_number4' } : { label: '', key: '' },
    highestItem >= 4 ? { label: 'qty', key: 'quantity4' } : { label: '', key: '' },
    highestItem >= 4 ? { label: 'Qty unit', key: 'unit4' } : { label: '', key: '' },
    highestItem >= 4 ? { label: 'Item description', key: 'itemName4' } : { label: '', key: '' },
    highestItem >= 4 ? { label: 'Unit Price', key: 'unitPrice4' } : { label: '', key: '' },
    highestItem >= 4 ? { label: 'net weight', key: 'itemGrossWeight4' } : { label: '', key: '' },
    highestItem >= 4 ? { label: 'gross weight', key: 'itemGrossWeight4' } : { label: '', key: '' },
    highestItem >= 4 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 4 ? { label: 'hs code', key: 'hsCode4' } : { label: '', key: '' },
    highestItem >= 5 ? { label: '', key: 'slash5' } : { label: '', key: '' },
    highestItem >= 5 ? { label: 'item number', key: 'item_number5' } : { label: '', key: '' },
    highestItem >= 5 ? { label: 'qty', key: 'quantity5' } : { label: '', key: '' },
    highestItem >= 5 ? { label: 'Qty unit', key: 'unit5' } : { label: '', key: '' },
    highestItem >= 5 ? { label: 'Item description', key: 'itemName5' } : { label: '', key: '' },
    highestItem >= 5 ? { label: 'Unit Price', key: 'unitPrice5' } : { label: '', key: '' },
    highestItem >= 5 ? { label: 'net weight', key: 'itemGrossWeight5' } : { label: '', key: '' },
    highestItem >= 5 ? { label: 'gross weight', key: 'itemGrossWeight5' } : { label: '', key: '' },
    highestItem >= 5 ? { label: 'country manufactured', key: 'countryManufactured5' } : { label: '', key: '' },
    highestItem >= 5 ? { label: 'hs code', key: 'hsCode5' } : { label: '', key: '' },
    highestItem >= 6 ? { label: '', key: 'slash6' } : { label: '', key: '' },
    highestItem >= 6 ? { label: 'item number', key: 'item_number6' } : { label: '', key: '' },
    highestItem >= 6 ? { label: 'qty', key: 'quantity6' } : { label: '', key: '' },
    highestItem >= 6 ? { label: 'Qty unit', key: 'unit6' } : { label: '', key: '' },
    highestItem >= 6 ? { label: 'Item description', key: 'itemName6' } : { label: '', key: '' },
    highestItem >= 6 ? { label: 'Unit Price', key: 'unitPrice6' } : { label: '', key: '' },
    highestItem >= 6 ? { label: 'net weight', key: 'itemGrossWeight6' } : { label: '', key: '' },
    highestItem >= 6 ? { label: 'gross weight', key: 'itemGrossWeight6' } : { label: '', key: '' },
    highestItem >= 6 ? { label: 'country manufactured', key: 'countryManufactured6' } : { label: '', key: '' },
    highestItem >= 6 ? { label: 'hs code', key: 'hsCode6' } : { label: '', key: '' },
    highestItem >= 7 ? { label: '', key: 'slash7' } : { label: '', key: '' },
    highestItem >= 7 ? { label: 'item number', key: 'item_number7' } : { label: '', key: '' },
    highestItem >= 7 ? { label: 'qty', key: 'quantity7' } : { label: '', key: '' },
    highestItem >= 7 ? { label: 'Qty unit', key: 'unit7' } : { label: '', key: '' },
    highestItem >= 7 ? { label: 'Item description', key: 'itemName7' } : { label: '', key: '' },
    highestItem >= 7 ? { label: 'Unit Price', key: 'unitPrice7' } : { label: '', key: '' },
    highestItem >= 7 ? { label: 'net weight', key: 'itemGrossWeight7' } : { label: '', key: '' },
    highestItem >= 7 ? { label: 'gross weight', key: 'itemGrossWeight7' } : { label: '', key: '' },
    highestItem >= 7 ? { label: 'country manufactured', key: 'countryManufactured7' } : { label: '', key: '' },
    highestItem >= 7 ? { label: 'hs code', key: 'hsCode7' } : { label: '', key: '' },
    highestItem >= 8 ? { label: '', key: 'slash8' } : { label: '', key: '' },
    highestItem >= 8 ? { label: 'item number', key: 'item_number8' } : { label: '', key: '' },
    highestItem >= 8 ? { label: 'qty', key: 'quantity8' } : { label: '', key: '' },
    highestItem >= 8 ? { label: 'Qty unit', key: 'unit8' } : { label: '', key: '' },
    highestItem >= 8 ? { label: 'Item description', key: 'itemName8' } : { label: '', key: '' },
    highestItem >= 8 ? { label: 'Unit Price', key: 'unitPrice8' } : { label: '', key: '' },
    highestItem >= 8 ? { label: 'net weight', key: 'itemGrossWeight8' } : { label: '', key: '' },
    highestItem >= 8 ? { label: 'gross weight', key: 'itemGrossWeight8' } : { label: '', key: '' },
    highestItem >= 8 ? { label: 'country manufactured', key: 'countryManufactured8' } : { label: '', key: '' },
    highestItem >= 8 ? { label: 'hs code', key: 'hsCode8' } : { label: '', key: '' },
    highestItem >= 9 ? { label: '', key: 'slash9' } : { label: '', key: '' },
    highestItem >= 9 ? { label: 'item number', key: 'item_number9' } : { label: '', key: '' },
    highestItem >= 9 ? { label: 'qty', key: 'quantity9' } : { label: '', key: '' },
    highestItem >= 9 ? { label: 'Qty unit', key: 'unit9' } : { label: '', key: '' },
    highestItem >= 9 ? { label: 'Item description', key: 'itemName9' } : { label: '', key: '' },
    highestItem >= 9 ? { label: 'Unit Price', key: 'unitPrice9' } : { label: '', key: '' },
    highestItem >= 9 ? { label: 'net weight', key: 'itemGrossWeight9' } : { label: '', key: '' },
    highestItem >= 9 ? { label: 'gross weight', key: 'itemGrossWeight9' } : { label: '', key: '' },
    highestItem >= 9 ? { label: 'country manufactured', key: 'countryManufactured9' } : { label: '', key: '' },
    highestItem >= 9 ? { label: 'hs code', key: 'hsCode9' } : { label: '', key: '' },
    highestItem >= 10 ? { label: '', key: 'slash10' } : { label: '', key: '' },
    highestItem >= 10 ? { label: 'item number', key: 'item_number10' } : { label: '', key: '' },
    highestItem >= 10 ? { label: 'qty', key: 'quantity10' } : { label: '', key: '' },
    highestItem >= 10 ? { label: 'Qty unit', key: 'unit10' } : { label: '', key: '' },
    highestItem >= 10 ? { label: 'Item description', key: 'itemName10' } : { label: '', key: '' },
    highestItem >= 10 ? { label: 'Unit Price', key: 'unitPrice10' } : { label: '', key: '' },
    highestItem >= 10 ? { label: 'net weight', key: 'itemGrossWeight10' } : { label: '', key: '' },
    highestItem >= 10 ? { label: 'gross weight', key: 'itemGrossWeight10' } : { label: '', key: '' },
    highestItem >= 10 ? { label: 'country manufactured', key: 'countryManufactured10' } : { label: '', key: '' },
    highestItem >= 10 ? { label: 'hs code', key: 'hsCode10' } : { label: '', key: '' },
    highestItem >= 11 ? { label: '', key: 'slash11' } : { label: '', key: '' },
    highestItem >= 11 ? { label: 'item number', key: 'item_number11' } : { label: '', key: '' },
    highestItem >= 11 ? { label: 'qty', key: 'quantity11' } : { label: '', key: '' },
    highestItem >= 11 ? { label: 'Qty unit', key: 'unit11' } : { label: '', key: '' },
    highestItem >= 11 ? { label: 'Item description', key: 'itemName11' } : { label: '', key: '' },
    highestItem >= 11 ? { label: 'Unit Price', key: 'unitPrice11' } : { label: '', key: '' },
    highestItem >= 11 ? { label: 'net weight', key: 'itemGrossWeight11' } : { label: '', key: '' },
    highestItem >= 11 ? { label: 'gross weight', key: 'itemGrossWeight11' } : { label: '', key: '' },
    highestItem >= 11 ? { label: 'country manufactured', key: 'countryManufactured11' } : { label: '', key: '' },
    highestItem >= 11 ? { label: 'hs code', key: 'hsCode11' } : { label: '', key: '' },
    highestItem >= 12 ? { label: '', key: 'slash12' } : { label: '', key: '' },
    highestItem >= 12 ? { label: 'item number', key: 'item_number12' } : { label: '', key: '' },
    highestItem >= 12 ? { label: 'qty', key: 'quantity12' } : { label: '', key: '' },
    highestItem >= 12 ? { label: 'Qty unit', key: 'unit12' } : { label: '', key: '' },
    highestItem >= 12 ? { label: 'Item description', key: 'itemName12' } : { label: '', key: '' },
    highestItem >= 12 ? { label: 'Unit Price', key: 'unitPrice12' } : { label: '', key: '' },
    highestItem >= 12 ? { label: 'net weight', key: 'itemGrossWeight12' } : { label: '', key: '' },
    highestItem >= 12 ? { label: 'gross weight', key: 'itemGrossWeight12' } : { label: '', key: '' },
    highestItem >= 12 ? { label: 'country manufactured', key: 'countryManufactured12' } : { label: '', key: '' },
    highestItem >= 12 ? { label: 'hs code', key: 'hsCode12' } : { label: '', key: '' },
    highestItem >= 13 ? { label: '', key: 'slash13' } : { label: '', key: '' },
    highestItem >= 13 ? { label: 'item number', key: 'item_number13' } : { label: '', key: '' },
    highestItem >= 13 ? { label: 'qty', key: 'quantity13' } : { label: '', key: '' },
    highestItem >= 13 ? { label: 'Qty unit', key: 'unit13' } : { label: '', key: '' },
    highestItem >= 13 ? { label: 'Item description', key: 'itemName13' } : { label: '', key: '' },
    highestItem >= 13 ? { label: 'Unit Price', key: 'unitPrice13' } : { label: '', key: '' },
    highestItem >= 13 ? { label: 'net weight', key: 'itemGrossWeight13' } : { label: '', key: '' },
    highestItem >= 13 ? { label: 'gross weight', key: 'itemGrossWeight13' } : { label: '', key: '' },
    highestItem >= 13 ? { label: 'country manufactured', key: 'countryManufactured13' } : { label: '', key: '' },
    highestItem >= 13 ? { label: 'hs code', key: 'hsCode13' } : { label: '', key: '' },
    highestItem >= 14 ? { label: '', key: 'slash14' } : { label: '', key: '' },
    highestItem >= 14 ? { label: 'item number', key: 'item_number14' } : { label: '', key: '' },
    highestItem >= 14 ? { label: 'qty', key: 'quantity14' } : { label: '', key: '' },
    highestItem >= 14 ? { label: 'Qty unit', key: 'unit14' } : { label: '', key: '' },
    highestItem >= 14 ? { label: 'Item description', key: 'itemName14' } : { label: '', key: '' },
    highestItem >= 14 ? { label: 'Unit Price', key: 'unitPrice14' } : { label: '', key: '' },
    highestItem >= 14 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 14 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 14 ? { label: 'country manufactured', key: 'countryManufactured14' } : { label: '', key: '' },
    highestItem >= 14 ? { label: 'hs code', key: 'hsCode14' } : { label: '', key: '' },
    highestItem >= 15 ? { label: '', key: 'slash15' } : { label: '', key: '' },
    highestItem >= 15 ? { label: 'item number', key: 'item_number15' } : { label: '', key: '' },
    highestItem >= 15 ? { label: 'qty', key: 'quantity15' } : { label: '', key: '' },
    highestItem >= 15 ? { label: 'Qty unit', key: 'unit15' } : { label: '', key: '' },
    highestItem >= 15 ? { label: 'Item description', key: 'itemName15' } : { label: '', key: '' },
    highestItem >= 15 ? { label: 'Unit Price', key: 'unitPrice15' } : { label: '', key: '' },
    highestItem >= 15 ? { label: 'net weight', key: 'itemGrossWeight15' } : { label: '', key: '' },
    highestItem >= 15 ? { label: 'gross weight', key: 'itemGrossWeight15' } : { label: '', key: '' },
    highestItem >= 15 ? { label: 'country manufactured', key: 'countryManufactured15' } : { label: '', key: '' },
    highestItem >= 15 ? { label: 'hs code', key: 'hsCode15' } : { label: '', key: '' },
    highestItem >= 16 ? { label: '', key: 'slash16' } : { label: '', key: '' },
    highestItem >= 16 ? { label: 'item number', key: 'item_number16' } : { label: '', key: '' },
    highestItem >= 16 ? { label: 'qty', key: 'quantity16' } : { label: '', key: '' },
    highestItem >= 16 ? { label: 'Qty unit', key: 'unit16' } : { label: '', key: '' },
    highestItem >= 16 ? { label: 'Item description', key: 'itemName16' } : { label: '', key: '' },
    highestItem >= 16 ? { label: 'Unit Price', key: 'unitPrice16' } : { label: '', key: '' },
    highestItem >= 16 ? { label: 'net weight', key: 'itemGrossWeight16' } : { label: '', key: '' },
    highestItem >= 16 ? { label: 'gross weight', key: 'itemGrossWeight16' } : { label: '', key: '' },
    highestItem >= 16 ? { label: 'country manufactured', key: 'countryManufactured16' } : { label: '', key: '' },
    highestItem >= 16 ? { label: 'hs code', key: 'hsCode16' } : { label: '', key: '' },
    highestItem >= 17 ? { label: '', key: 'slash17' } : { label: '', key: '' },
    highestItem >= 17 ? { label: 'item number', key: 'item_number17' } : { label: '', key: '' },
    highestItem >= 17 ? { label: 'qty', key: 'quantity17' } : { label: '', key: '' },
    highestItem >= 17 ? { label: 'Qty unit', key: 'unit17' } : { label: '', key: '' },
    highestItem >= 17 ? { label: 'Item description', key: 'itemName17' } : { label: '', key: '' },
    highestItem >= 17 ? { label: 'Unit Price', key: 'unitPrice17' } : { label: '', key: '' },
    highestItem >= 17 ? { label: 'net weight', key: 'itemGrossWeight17' } : { label: '', key: '' },
    highestItem >= 17 ? { label: 'gross weight', key: 'itemGrossWeight17' } : { label: '', key: '' },
    highestItem >= 17 ? { label: 'country manufactured', key: 'countryManufactured17' } : { label: '', key: '' },
    highestItem >= 17 ? { label: 'hs code', key: 'hsCode17' } : { label: '', key: '' },
    highestItem >= 18 ? { label: '', key: 'slash18' } : { label: '', key: '' },
    highestItem >= 18 ? { label: 'item number', key: 'item_number18' } : { label: '', key: '' },
    highestItem >= 18 ? { label: 'qty', key: 'quantity18' } : { label: '', key: '' },
    highestItem >= 18 ? { label: 'Qty unit', key: 'unit18' } : { label: '', key: '' },
    highestItem >= 18 ? { label: 'Item description', key: 'itemName18' } : { label: '', key: '' },
    highestItem >= 18 ? { label: 'Unit Price', key: 'unitPrice18' } : { label: '', key: '' },
    highestItem >= 18 ? { label: 'net weight', key: 'itemGrossWeight18' } : { label: '', key: '' },
    highestItem >= 18 ? { label: 'gross weight', key: 'itemGrossWeight18' } : { label: '', key: '' },
    highestItem >= 18 ? { label: 'country manufactured', key: 'countryManufactured18' } : { label: '', key: '' },
    highestItem >= 18 ? { label: 'hs code', key: 'hsCode18' } : { label: '', key: '' },
    highestItem >= 19 ? { label: '', key: 'slash19' } : { label: '', key: '' },
    highestItem >= 19 ? { label: 'item number', key: 'item_number19' } : { label: '', key: '' },
    highestItem >= 19 ? { label: 'qty', key: 'quantity19' } : { label: '', key: '' },
    highestItem >= 19 ? { label: 'Qty unit', key: 'unit19' } : { label: '', key: '' },
    highestItem >= 19 ? { label: 'Item description', key: 'itemName19' } : { label: '', key: '' },
    highestItem >= 19 ? { label: 'Unit Price', key: 'unitPrice19' } : { label: '', key: '' },
    highestItem >= 19 ? { label: 'net weight', key: 'itemGrossWeight19' } : { label: '', key: '' },
    highestItem >= 19 ? { label: 'gross weight', key: 'itemGrossWeight19' } : { label: '', key: '' },
    highestItem >= 19 ? { label: 'country manufactured', key: 'countryManufactured19' } : { label: '', key: '' },
    highestItem >= 19 ? { label: 'hs code', key: 'hsCode19' } : { label: '', key: '' },
    highestItem >= 20 ? { label: '', key: 'slash20' } : { label: '', key: '' },
    highestItem >= 20 ? { label: 'item number', key: 'item_number20' } : { label: '', key: '' },
    highestItem >= 20 ? { label: 'qty', key: 'quantity20' } : { label: '', key: '' },
    highestItem >= 20 ? { label: 'Qty unit', key: 'unit20' } : { label: '', key: '' },
    highestItem >= 20 ? { label: 'Item description', key: 'itemName20' } : { label: '', key: '' },
    highestItem >= 20 ? { label: 'Unit Price', key: 'unitPrice20' } : { label: '', key: '' },
    highestItem >= 20 ? { label: 'net weight', key: 'itemGrossWeight20' } : { label: '', key: '' },
    highestItem >= 20 ? { label: 'gross weight', key: 'itemGrossWeight20' } : { label: '', key: '' },
    highestItem >= 20 ? { label: 'country manufactured', key: 'countryManufactured20' } : { label: '', key: '' },
    highestItem >= 20 ? { label: 'hs code', key: 'hsCode20' } : { label: '', key: '' },
    highestItem >= 21 ? { label: '', key: 'slash21' } : { label: '', key: '' },
    highestItem >= 21 ? { label: 'item number', key: 'item_number21' } : { label: '', key: '' },
    highestItem >= 21 ? { label: 'qty', key: 'quantity21' } : { label: '', key: '' },
    highestItem >= 21 ? { label: 'Qty unit', key: 'unit21' } : { label: '', key: '' },
    highestItem >= 21 ? { label: 'Item description', key: 'itemName21' } : { label: '', key: '' },
    highestItem >= 21 ? { label: 'Unit Price', key: 'unitPrice21' } : { label: '', key: '' },
    highestItem >= 21 ? { label: 'net weight', key: 'itemGrossWeight20' } : { label: '', key: '' },
    highestItem >= 21 ? { label: 'gross weight', key: 'itemGrossWeight20' } : { label: '', key: '' },
    highestItem >= 21 ? { label: 'country manufactured', key: 'countryManufactured20' } : { label: '', key: '' },
    highestItem >= 21 ? { label: 'hs code', key: 'hsCode21' } : { label: '', key: '' },
    highestItem >= 22 ? { label: '', key: 'slash22' } : { label: '', key: '' },
    highestItem >= 22 ? { label: 'item number', key: 'item_number22' } : { label: '', key: '' },
    highestItem >= 22 ? { label: 'qty', key: 'quantity22' } : { label: '', key: '' },
    highestItem >= 22 ? { label: 'Qty unit', key: 'unit22' } : { label: '', key: '' },
    highestItem >= 22 ? { label: 'Item description', key: 'itemName22' } : { label: '', key: '' },
    highestItem >= 22 ? { label: 'Unit Price', key: 'unitPrice22' } : { label: '', key: '' },
    highestItem >= 22 ? { label: 'net weight', key: 'itemGrossWeight20' } : { label: '', key: '' },
    highestItem >= 22 ? { label: 'gross weight', key: 'itemGrossWeight20' } : { label: '', key: '' },
    highestItem >= 22 ? { label: 'country manufactured', key: 'countryManufactured20' } : { label: '', key: '' },
    highestItem >= 22 ? { label: 'hs code', key: 'hsCode22' } : { label: '', key: '' },
    highestItem >= 23 ? { label: '', key: 'slash23' } : { label: '', key: '' },
    highestItem >= 23 ? { label: 'item number', key: 'item_number23' } : { label: '', key: '' },
    highestItem >= 23 ? { label: 'qty', key: 'quantity23' } : { label: '', key: '' },
    highestItem >= 23 ? { label: 'Qty unit', key: 'unit23' } : { label: '', key: '' },
    highestItem >= 23 ? { label: 'Item description', key: 'itemName23' } : { label: '', key: '' },
    highestItem >= 23 ? { label: 'Unit Price', key: 'unitPrice23' } : { label: '', key: '' },
    highestItem >= 23 ? { label: 'net weight', key: 'itemGrossWeight20' } : { label: '', key: '' },
    highestItem >= 23 ? { label: 'gross weight', key: 'itemGrossWeight20' } : { label: '', key: '' },
    highestItem >= 23 ? { label: 'country manufactured', key: 'countryManufactured20' } : { label: '', key: '' },
    highestItem >= 23 ? { label: 'hs code', key: 'hsCode23' } : { label: '', key: '' },
    highestItem >= 24 ? { label: '', key: 'slash24' } : { label: '', key: '' },
    highestItem >= 24 ? { label: 'item number', key: 'item_number24' } : { label: '', key: '' },
    highestItem >= 24 ? { label: 'qty', key: 'quantity24' } : { label: '', key: '' },
    highestItem >= 24 ? { label: 'Qty unit', key: 'unit24' } : { label: '', key: '' },
    highestItem >= 24 ? { label: 'Item description', key: 'itemName24' } : { label: '', key: '' },
    highestItem >= 24 ? { label: 'Unit Price', key: 'unitPrice24' } : { label: '', key: '' },
    highestItem >= 24 ? { label: 'net weight', key: 'itemGrossWeight20' } : { label: '', key: '' },
    highestItem >= 24 ? { label: 'gross weight', key: 'itemGrossWeight20' } : { label: '', key: '' },
    highestItem >= 24 ? { label: 'country manufactured', key: 'countryManufactured20' } : { label: '', key: '' },
    highestItem >= 24 ? { label: 'hs code', key: 'hsCode24' } : { label: '', key: '' },
    highestItem >= 25 ? { label: '', key: 'slash25' } : { label: '', key: '' },
    highestItem >= 25 ? { label: 'item number', key: 'item_number25' } : { label: '', key: '' },
    highestItem >= 25 ? { label: 'qty', key: 'quantity25' } : { label: '', key: '' },
    highestItem >= 25 ? { label: 'Qty unit', key: 'unit25' } : { label: '', key: '' },
    highestItem >= 25 ? { label: 'Item description', key: 'itemName25' } : { label: '', key: '' },
    highestItem >= 25 ? { label: 'Unit Price', key: 'unitPrice25' } : { label: '', key: '' },
    highestItem >= 25 ? { label: 'net weight', key: 'itemGrossWeight20' } : { label: '', key: '' },
    highestItem >= 25 ? { label: 'gross weight', key: 'itemGrossWeight20' } : { label: '', key: '' },
    highestItem >= 25 ? { label: 'country manufactured', key: 'countryManufactured20' } : { label: '', key: '' },
    highestItem >= 25 ? { label: 'hs code', key: 'hsCode25' } : { label: '', key: '' },
    highestItem >= 26 ? { label: '', key: 'slash26' } : { label: '', key: '' },
    highestItem >= 26 ? { label: 'item number', key: 'item_number26' } : { label: '', key: '' },
    highestItem >= 26 ? { label: 'qty', key: 'quantity26' } : { label: '', key: '' },
    highestItem >= 26 ? { label: 'Qty unit', key: 'unit26' } : { label: '', key: '' },
    highestItem >= 26 ? { label: 'Item description', key: 'itemName26' } : { label: '', key: '' },
    highestItem >= 26 ? { label: 'Unit Price', key: 'unitPrice26' } : { label: '', key: '' },
    highestItem >= 26 ? { label: 'net weight', key: 'itemGrossWeight20' } : { label: '', key: '' },
    highestItem >= 26 ? { label: 'gross weight', key: 'itemGrossWeight20' } : { label: '', key: '' },
    highestItem >= 26 ? { label: 'country manufactured', key: 'countryManufactured20' } : { label: '', key: '' },
    highestItem >= 26 ? { label: 'hs code', key: 'hsCode26' } : { label: '', key: '' },
    highestItem >= 27 ? { label: '', key: 'slash27' } : { label: '', key: '' },
    highestItem >= 27 ? { label: 'item number', key: 'item_number27' } : { label: '', key: '' },
    highestItem >= 27 ? { label: 'qty', key: 'quantity27' } : { label: '', key: '' },
    highestItem >= 27 ? { label: 'Qty unit', key: 'unit27' } : { label: '', key: '' },
    highestItem >= 27 ? { label: 'Item description', key: 'itemName27' } : { label: '', key: '' },
    highestItem >= 27 ? { label: 'Unit Price', key: 'unitPrice27' } : { label: '', key: '' },
    highestItem >= 27 ? { label: 'net weight', key: 'itemGrossWeight20' } : { label: '', key: '' },
    highestItem >= 27 ? { label: 'gross weight', key: 'itemGrossWeight20' } : { label: '', key: '' },
    highestItem >= 27 ? { label: 'country manufactured', key: 'countryManufactured20' } : { label: '', key: '' },
    highestItem >= 27 ? { label: 'hs code', key: 'hsCode27' } : { label: '', key: '' },
    highestItem >= 28 ? { label: '', key: 'slash28' } : { label: '', key: '' },
    highestItem >= 28 ? { label: 'item number', key: 'item_number28' } : { label: '', key: '' },
    highestItem >= 28 ? { label: 'qty', key: 'quantity28' } : { label: '', key: '' },
    highestItem >= 28 ? { label: 'Qty unit', key: 'unit28' } : { label: '', key: '' },
    highestItem >= 28 ? { label: 'Item description', key: 'itemName28' } : { label: '', key: '' },
    highestItem >= 28 ? { label: 'Unit Price', key: 'unitPrice28' } : { label: '', key: '' },
    highestItem >= 28 ? { label: 'net weight', key: 'itemGrossWeight8' } : { label: '', key: '' },
    highestItem >= 28 ? { label: 'gross weight', key: 'itemGrossWeight8' } : { label: '', key: '' },
    highestItem >= 28 ? { label: 'country manufactured', key: 'countryManufactured8' } : { label: '', key: '' },
    highestItem >= 28 ? { label: 'hs code', key: 'hsCode28' } : { label: '', key: '' },
    highestItem >= 29 ? { label: '', key: 'slash29' } : { label: '', key: '' },
    highestItem >= 29 ? { label: 'item number', key: 'item_number29' } : { label: '', key: '' },
    highestItem >= 29 ? { label: 'qty', key: 'quantity29' } : { label: '', key: '' },
    highestItem >= 29 ? { label: 'Qty unit', key: 'unit29' } : { label: '', key: '' },
    highestItem >= 29 ? { label: 'Item description', key: 'itemName29' } : { label: '', key: '' },
    highestItem >= 29 ? { label: 'Unit Price', key: 'unitPrice29' } : { label: '', key: '' },
    highestItem >= 29 ? { label: 'net weight', key: 'itemGrossWeight20' } : { label: '', key: '' },
    highestItem >= 29 ? { label: 'gross weight', key: 'itemGrossWeight20' } : { label: '', key: '' },
    highestItem >= 29 ? { label: 'country manufactured', key: 'countryManufactured20' } : { label: '', key: '' },
    highestItem >= 29 ? { label: 'hs code', key: 'hsCode29' } : { label: '', key: '' },
    highestItem >= 30 ? { label: '', key: 'slash30' } : { label: '', key: '' },
    highestItem >= 30 ? { label: 'item number', key: 'item_number30' } : { label: '', key: '' },
    highestItem >= 30 ? { label: 'qty', key: 'quantity30' } : { label: '', key: '' },
    highestItem >= 30 ? { label: 'Qty unit', key: 'unit30' } : { label: '', key: '' },
    highestItem >= 30 ? { label: 'Item description', key: 'itemName30' } : { label: '', key: '' },
    highestItem >= 30 ? { label: 'Unit Price', key: 'unitPrice30' } : { label: '', key: '' },
    highestItem >= 30 ? { label: 'net weight', key: 'itemGrossWeight8' } : { label: '', key: '' },
    highestItem >= 30 ? { label: 'gross weight', key: 'itemGrossWeight8' } : { label: '', key: '' },
    highestItem >= 30 ? { label: 'country manufactured', key: 'countryManufactured20' } : { label: '', key: '' },
    highestItem >= 30 ? { label: 'hs code', key: 'hsCode30' } : { label: '', key: '' },
    highestItem >= 31 ? { label: '', key: 'slash31' } : { label: '', key: '' },
    highestItem >= 31 ? { label: 'item number', key: 'item_number31' } : { label: '', key: '' },
    highestItem >= 31 ? { label: 'qty', key: 'quantity31' } : { label: '', key: '' },
    highestItem >= 31 ? { label: 'Qty unit', key: 'unit31' } : { label: '', key: '' },
    highestItem >= 31 ? { label: 'Item description', key: 'itemName31' } : { label: '', key: '' },
    highestItem >= 31 ? { label: 'Unit Price', key: 'unitPrice31' } : { label: '', key: '' },
    highestItem >= 31 ? { label: 'net weight', key: 'itemGrossWeight3' } : { label: '', key: '' },
    highestItem >= 31 ? { label: 'gross weight', key: 'itemGrossWeight3' } : { label: '', key: '' },
    highestItem >= 31 ? { label: 'country manufactured', key: 'countryManufactured3' } : { label: '', key: '' },
    highestItem >= 31 ? { label: 'hs code', key: 'hsCode31' } : { label: '', key: '' },
    highestItem >= 32 ? { label: '', key: 'slash32' } : { label: '', key: '' },
    highestItem >= 32 ? { label: 'item number', key: 'item_number32' } : { label: '', key: '' },
    highestItem >= 32 ? { label: 'qty', key: 'quantity32' } : { label: '', key: '' },
    highestItem >= 32 ? { label: 'Qty unit', key: 'unit32' } : { label: '', key: '' },
    highestItem >= 32 ? { label: 'Item description', key: 'itemName32' } : { label: '', key: '' },
    highestItem >= 32 ? { label: 'Unit Price', key: 'unitPrice32' } : { label: '', key: '' },
    highestItem >= 32 ? { label: 'net weight', key: 'itemGrossWeight2' } : { label: '', key: '' },
    highestItem >= 32 ? { label: 'gross weight', key: 'itemGrossWeight2' } : { label: '', key: '' },
    highestItem >= 32 ? { label: 'country manufactured', key: 'countryManufactured2' } : { label: '', key: '' },
    highestItem >= 32 ? { label: 'hs code', key: 'hsCode32' } : { label: '', key: '' },
    highestItem >= 33 ? { label: '', key: 'slash33' } : { label: '', key: '' },
    highestItem >= 33 ? { label: 'item number', key: 'item_number33' } : { label: '', key: '' },
    highestItem >= 33 ? { label: 'qty', key: 'quantity33' } : { label: '', key: '' },
    highestItem >= 33 ? { label: 'Qty unit', key: 'unit33' } : { label: '', key: '' },
    highestItem >= 33 ? { label: 'Item description', key: 'itemName33' } : { label: '', key: '' },
    highestItem >= 33 ? { label: 'Unit Price', key: 'unitPrice33' } : { label: '', key: '' },
    highestItem >= 33 ? { label: 'net weight', key: 'itemGrossWeight8' } : { label: '', key: '' },
    highestItem >= 33 ? { label: 'gross weight', key: 'itemGrossWeight8' } : { label: '', key: '' },
    highestItem >= 33 ? { label: 'country manufactured', key: 'countryManufactured33' } : { label: '', key: '' },
    highestItem >= 33 ? { label: 'hs code', key: 'hsCode33' } : { label: '', key: '' },
    highestItem >= 34 ? { label: '', key: 'slash34' } : { label: '', key: '' },
    highestItem >= 34 ? { label: 'item number', key: 'item_number34' } : { label: '', key: '' },
    highestItem >= 34 ? { label: 'qty', key: 'quantity34' } : { label: '', key: '' },
    highestItem >= 34 ? { label: 'Qty unit', key: 'unit34' } : { label: '', key: '' },
    highestItem >= 34 ? { label: 'Item description', key: 'itemName34' } : { label: '', key: '' },
    highestItem >= 34 ? { label: 'Unit Price', key: 'unitPrice34' } : { label: '', key: '' },
    highestItem >= 34 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 34 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 34 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 34 ? { label: 'hs code', key: 'hsCode34' } : { label: '', key: '' },
    highestItem >= 35 ? { label: '', key: 'slash35' } : { label: '', key: '' },
    highestItem >= 35 ? { label: 'item number', key: 'item_number35' } : { label: '', key: '' },
    highestItem >= 35 ? { label: 'qty', key: 'quantity35' } : { label: '', key: '' },
    highestItem >= 35 ? { label: 'Qty unit', key: 'unit35' } : { label: '', key: '' },
    highestItem >= 35 ? { label: 'Item description', key: 'itemName35' } : { label: '', key: '' },
    highestItem >= 35 ? { label: 'Unit Price', key: 'unitPrice35' } : { label: '', key: '' },
    highestItem >= 35 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 35 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 35 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 35 ? { label: 'hs code', key: 'hsCode35' } : { label: '', key: '' },
    highestItem >= 36 ? { label: '', key: 'slash36' } : { label: '', key: '' },
    highestItem >= 36 ? { label: 'item number', key: 'item_number36' } : { label: '', key: '' },
    highestItem >= 36 ? { label: 'qty', key: 'quantity36' } : { label: '', key: '' },
    highestItem >= 36 ? { label: 'Qty unit', key: 'unit36' } : { label: '', key: '' },
    highestItem >= 36 ? { label: 'Item description', key: 'itemName36' } : { label: '', key: '' },
    highestItem >= 36 ? { label: 'Unit Price', key: 'unitPrice36' } : { label: '', key: '' },
    highestItem >= 36 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 36 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 36 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 36 ? { label: 'hs code', key: 'hsCode36' } : { label: '', key: '' },
    highestItem >= 37 ? { label: '', key: 'slash37' } : { label: '', key: '' },
    highestItem >= 37 ? { label: 'item number', key: 'item_number37' } : { label: '', key: '' },
    highestItem >= 37 ? { label: 'qty', key: 'quantity37' } : { label: '', key: '' },
    highestItem >= 37 ? { label: 'Qty unit', key: 'unit37' } : { label: '', key: '' },
    highestItem >= 37 ? { label: 'Item description', key: 'itemName37' } : { label: '', key: '' },
    highestItem >= 37 ? { label: 'Unit Price', key: 'unitPrice37' } : { label: '', key: '' },
    highestItem >= 37 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 37 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 37 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 37 ? { label: 'hs code', key: 'hsCode37' } : { label: '', key: '' },
    highestItem >= 38 ? { label: '', key: 'slash38' } : { label: '', key: '' },
    highestItem >= 38 ? { label: 'item number', key: 'item_number38' } : { label: '', key: '' },
    highestItem >= 38 ? { label: 'qty', key: 'quantity38' } : { label: '', key: '' },
    highestItem >= 38 ? { label: 'Qty unit', key: 'unit38' } : { label: '', key: '' },
    highestItem >= 38 ? { label: 'Item description', key: 'itemName38' } : { label: '', key: '' },
    highestItem >= 38 ? { label: 'Unit Price', key: 'unitPrice38' } : { label: '', key: '' },
    highestItem >= 38 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 38 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 38 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 38 ? { label: 'hs code', key: 'hsCode38' } : { label: '', key: '' },
    highestItem >= 39 ? { label: '', key: 'slash39' } : { label: '', key: '' },
    highestItem >= 39 ? { label: 'item number', key: 'item_number39' } : { label: '', key: '' },
    highestItem >= 39 ? { label: 'qty', key: 'quantity39' } : { label: '', key: '' },
    highestItem >= 39 ? { label: 'Qty unit', key: 'unit39' } : { label: '', key: '' },
    highestItem >= 39 ? { label: 'Item description', key: 'itemName39' } : { label: '', key: '' },
    highestItem >= 39 ? { label: 'Unit Price', key: 'unitPrice39' } : { label: '', key: '' },
    highestItem >= 39 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 39 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 39 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 39 ? { label: 'hs code', key: 'hsCode39' } : { label: '', key: '' },
    highestItem >= 40 ? { label: '', key: 'slash40' } : { label: '', key: '' },
    highestItem >= 40 ? { label: 'item number', key: 'item_number40' } : { label: '', key: '' },
    highestItem >= 40 ? { label: 'qty', key: 'quantity40' } : { label: '', key: '' },
    highestItem >= 40 ? { label: 'Qty unit', key: 'unit40' } : { label: '', key: '' },
    highestItem >= 40 ? { label: 'Item description', key: 'itemName40' } : { label: '', key: '' },
    highestItem >= 40 ? { label: 'Unit Price', key: 'unitPrice40' } : { label: '', key: '' },
    highestItem >= 40 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 40 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 40 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 40 ? { label: 'hs code', key: 'hsCode40' } : { label: '', key: '' },
    highestItem >= 41 ? { label: '', key: 'slash41' } : { label: '', key: '' },
    highestItem >= 41 ? { label: 'item number', key: 'item_number41' } : { label: '', key: '' },
    highestItem >= 41 ? { label: 'qty', key: 'quantity41' } : { label: '', key: '' },
    highestItem >= 41 ? { label: 'Qty unit', key: 'unit41' } : { label: '', key: '' },
    highestItem >= 41 ? { label: 'Item description', key: 'itemName41' } : { label: '', key: '' },
    highestItem >= 41 ? { label: 'Unit Price', key: 'unitPrice41' } : { label: '', key: '' },
    highestItem >= 41 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 41 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 41 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 41 ? { label: 'hs code', key: 'hsCode41' } : { label: '', key: '' },
    highestItem >= 42 ? { label: '', key: 'slash42' } : { label: '', key: '' },
    highestItem >= 42 ? { label: 'item number', key: 'item_number42' } : { label: '', key: '' },
    highestItem >= 42 ? { label: 'qty', key: 'quantity42' } : { label: '', key: '' },
    highestItem >= 42 ? { label: 'Qty unit', key: 'unit42' } : { label: '', key: '' },
    highestItem >= 42 ? { label: 'Item description', key: 'itemName42' } : { label: '', key: '' },
    highestItem >= 42 ? { label: 'Unit Price', key: 'unitPrice42' } : { label: '', key: '' },
    highestItem >= 42 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 42 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 42 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 42 ? { label: 'hs code', key: 'hsCode42' } : { label: '', key: '' },
    highestItem >= 43 ? { label: '', key: 'slash43' } : { label: '', key: '' },
    highestItem >= 43 ? { label: 'item number', key: 'item_number43' } : { label: '', key: '' },
    highestItem >= 43 ? { label: 'qty', key: 'quantity43' } : { label: '', key: '' },
    highestItem >= 43 ? { label: 'Qty unit', key: 'unit43' } : { label: '', key: '' },
    highestItem >= 43 ? { label: 'Item description', key: 'itemName43' } : { label: '', key: '' },
    highestItem >= 43 ? { label: 'Unit Price', key: 'unitPrice43' } : { label: '', key: '' },
    highestItem >= 43 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 43 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 43 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 43 ? { label: 'hs code', key: 'hsCode43' } : { label: '', key: '' },
    highestItem >= 44 ? { label: '', key: 'slash44' } : { label: '', key: '' },
    highestItem >= 44 ? { label: 'item number', key: 'item_number44' } : { label: '', key: '' },
    highestItem >= 44 ? { label: 'qty', key: 'quantity44' } : { label: '', key: '' },
    highestItem >= 44 ? { label: 'Qty unit', key: 'unit44' } : { label: '', key: '' },
    highestItem >= 44 ? { label: 'Item description', key: 'itemName44' } : { label: '', key: '' },
    highestItem >= 44 ? { label: 'Unit Price', key: 'unitPrice44' } : { label: '', key: '' },
    highestItem >= 44 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 44 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 44 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 44 ? { label: 'hs code', key: 'hsCode44' } : { label: '', key: '' },
    highestItem >= 45 ? { label: '', key: 'slash45' } : { label: '', key: '' },
    highestItem >= 45 ? { label: 'item number', key: 'item_number45' } : { label: '', key: '' },
    highestItem >= 45 ? { label: 'qty', key: 'quantity45' } : { label: '', key: '' },
    highestItem >= 45 ? { label: 'Qty unit', key: 'unit45' } : { label: '', key: '' },
    highestItem >= 45 ? { label: 'Item description', key: 'itemName45' } : { label: '', key: '' },
    highestItem >= 45 ? { label: 'Unit Price', key: 'unitPrice45' } : { label: '', key: '' },
    highestItem >= 45 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 45 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 45 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 45 ? { label: 'hs code', key: 'hsCode45' } : { label: '', key: '' },
    highestItem >= 46 ? { label: '', key: 'slash46' } : { label: '', key: '' },
    highestItem >= 46 ? { label: 'item number', key: 'item_number46' } : { label: '', key: '' },
    highestItem >= 46 ? { label: 'qty', key: 'quantity46' } : { label: '', key: '' },
    highestItem >= 46 ? { label: 'Qty unit', key: 'unit46' } : { label: '', key: '' },
    highestItem >= 46 ? { label: 'Item description', key: 'itemName46' } : { label: '', key: '' },
    highestItem >= 46 ? { label: 'Unit Price', key: 'unitPrice46' } : { label: '', key: '' },
    highestItem >= 46 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 46 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 46 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 46 ? { label: 'hs code', key: 'hsCode46' } : { label: '', key: '' },
    highestItem >= 47 ? { label: '', key: 'slash47' } : { label: '', key: '' },
    highestItem >= 47 ? { label: 'item number', key: 'item_number47' } : { label: '', key: '' },
    highestItem >= 47 ? { label: 'qty', key: 'quantity47' } : { label: '', key: '' },
    highestItem >= 47 ? { label: 'Qty unit', key: 'unit47' } : { label: '', key: '' },
    highestItem >= 47 ? { label: 'Item description', key: 'itemName47' } : { label: '', key: '' },
    highestItem >= 47 ? { label: 'Unit Price', key: 'unitPrice47' } : { label: '', key: '' },
    highestItem >= 47 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 47 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 47 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 47 ? { label: 'hs code', key: 'hsCode47' } : { label: '', key: '' },
    highestItem >= 48 ? { label: '', key: 'slash48' } : { label: '', key: '' },
    highestItem >= 48 ? { label: 'item number', key: 'item_number48' } : { label: '', key: '' },
    highestItem >= 48 ? { label: 'qty', key: 'quantity48' } : { label: '', key: '' },
    highestItem >= 48 ? { label: 'Qty unit', key: 'unit48' } : { label: '', key: '' },
    highestItem >= 48 ? { label: 'Item description', key: 'itemName48' } : { label: '', key: '' },
    highestItem >= 48 ? { label: 'Unit Price', key: 'unitPrice48' } : { label: '', key: '' },
    highestItem >= 48 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 48 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 48 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 48 ? { label: 'hs code', key: 'hsCode48' } : { label: '', key: '' },
    highestItem >= 49 ? { label: '', key: 'slash49' } : { label: '', key: '' },
    highestItem >= 49 ? { label: 'item number', key: 'item_number49' } : { label: '', key: '' },
    highestItem >= 49 ? { label: 'qty', key: 'quantity49' } : { label: '', key: '' },
    highestItem >= 49 ? { label: 'Qty unit', key: 'unit49' } : { label: '', key: '' },
    highestItem >= 49 ? { label: 'Item description', key: 'itemName49' } : { label: '', key: '' },
    highestItem >= 49 ? { label: 'Unit Price', key: 'unitPrice49' } : { label: '', key: '' },
    highestItem >= 49 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 49 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 49 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 49 ? { label: 'hs code', key: 'hsCode49' } : { label: '', key: '' },
    highestItem >= 50 ? { label: '', key: 'slash50' } : { label: '', key: '' },
    highestItem >= 50 ? { label: 'item number', key: 'item_number50' } : { label: '', key: '' },
    highestItem >= 50 ? { label: 'qty', key: 'quantity50' } : { label: '', key: '' },
    highestItem >= 50 ? { label: 'Qty unit', key: 'unit50' } : { label: '', key: '' },
    highestItem >= 50 ? { label: 'Item description', key: 'itemName50' } : { label: '', key: '' },
    highestItem >= 50 ? { label: 'Unit Price', key: 'unitPrice50' } : { label: '', key: '' },
    highestItem >= 50 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 50 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 50 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 50 ? { label: 'hs code', key: 'hsCode50' } : { label: '', key: '' },
    highestItem >= 51 ? { label: '', key: 'slash51' } : { label: '', key: '' },
    highestItem >= 51 ? { label: 'item number', key: 'item_number51' } : { label: '', key: '' },
    highestItem >= 51 ? { label: 'qty', key: 'quantity51' } : { label: '', key: '' },
    highestItem >= 51 ? { label: 'Qty unit', key: 'unit51' } : { label: '', key: '' },
    highestItem >= 51 ? { label: 'Item description', key: 'itemName51' } : { label: '', key: '' },
    highestItem >= 51 ? { label: 'Unit Price', key: 'unitPrice51' } : { label: '', key: '' },
    highestItem >= 51 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 51 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 51 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 51 ? { label: 'hs code', key: 'hsCode51' } : { label: '', key: '' },
    highestItem >= 52 ? { label: '', key: 'slash52' } : { label: '', key: '' },
    highestItem >= 52 ? { label: 'item number', key: 'item_number52' } : { label: '', key: '' },
    highestItem >= 52 ? { label: 'qty', key: 'quantity52' } : { label: '', key: '' },
    highestItem >= 52 ? { label: 'Qty unit', key: 'unit52' } : { label: '', key: '' },
    highestItem >= 52 ? { label: 'Item description', key: 'itemName52' } : { label: '', key: '' },
    highestItem >= 52 ? { label: 'Unit Price', key: 'unitPrice52' } : { label: '', key: '' },
    highestItem >= 52 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 52 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 52 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 52 ? { label: 'hs code', key: 'hsCode52' } : { label: '', key: '' },
    highestItem >= 53 ? { label: '', key: 'slash53' } : { label: '', key: '' },
    highestItem >= 53 ? { label: 'item number', key: 'item_number53' } : { label: '', key: '' },
    highestItem >= 53 ? { label: 'qty', key: 'quantity53' } : { label: '', key: '' },
    highestItem >= 53 ? { label: 'Qty unit', key: 'unit53' } : { label: '', key: '' },
    highestItem >= 53 ? { label: 'Item description', key: 'itemName53' } : { label: '', key: '' },
    highestItem >= 53 ? { label: 'Unit Price', key: 'unitPrice53' } : { label: '', key: '' },
    highestItem >= 53 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 53 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 53 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 53 ? { label: 'hs code', key: 'hsCode53' } : { label: '', key: '' },
    highestItem >= 54 ? { label: '', key: 'slash54' } : { label: '', key: '' },
    highestItem >= 54 ? { label: 'item number', key: 'item_number54' } : { label: '', key: '' },
    highestItem >= 54 ? { label: 'qty', key: 'quantity54' } : { label: '', key: '' },
    highestItem >= 54 ? { label: 'Qty unit', key: 'unit54' } : { label: '', key: '' },
    highestItem >= 54 ? { label: 'Item description', key: 'itemName54' } : { label: '', key: '' },
    highestItem >= 54 ? { label: 'Unit Price', key: 'unitPrice54' } : { label: '', key: '' },
    highestItem >= 54 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 54 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 54 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 54 ? { label: 'hs code', key: 'hsCode54' } : { label: '', key: '' },
    highestItem >= 55 ? { label: '', key: 'slash55' } : { label: '', key: '' },
    highestItem >= 55 ? { label: 'item number', key: 'item_number55' } : { label: '', key: '' },
    highestItem >= 55 ? { label: 'qty', key: 'quantity55' } : { label: '', key: '' },
    highestItem >= 55 ? { label: 'Qty unit', key: 'unit55' } : { label: '', key: '' },
    highestItem >= 55 ? { label: 'Item description', key: 'itemName55' } : { label: '', key: '' },
    highestItem >= 55 ? { label: 'Unit Price', key: 'unitPrice55' } : { label: '', key: '' },
    highestItem >= 55 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 55 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 55 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 55 ? { label: 'hs code', key: 'hsCode55' } : { label: '', key: '' },
    highestItem >= 56 ? { label: '', key: 'slash56' } : { label: '', key: '' },
    highestItem >= 56 ? { label: 'item number', key: 'item_number56' } : { label: '', key: '' },
    highestItem >= 56 ? { label: 'qty', key: 'quantity56' } : { label: '', key: '' },
    highestItem >= 56 ? { label: 'Qty unit', key: 'unit56' } : { label: '', key: '' },
    highestItem >= 56 ? { label: 'Item description', key: 'itemName56' } : { label: '', key: '' },
    highestItem >= 56 ? { label: 'Unit Price', key: 'unitPrice56' } : { label: '', key: '' },
    highestItem >= 56 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 56 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 56 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 56 ? { label: 'hs code', key: 'hsCode56' } : { label: '', key: '' },
    highestItem >= 57 ? { label: '', key: 'slash57' } : { label: '', key: '' },
    highestItem >= 57 ? { label: 'item number', key: 'item_number57' } : { label: '', key: '' },
    highestItem >= 57 ? { label: 'qty', key: 'quantity57' } : { label: '', key: '' },
    highestItem >= 57 ? { label: 'Qty unit', key: 'unit57' } : { label: '', key: '' },
    highestItem >= 57 ? { label: 'Item description', key: 'itemName57' } : { label: '', key: '' },
    highestItem >= 57 ? { label: 'Unit Price', key: 'unitPrice57' } : { label: '', key: '' },
    highestItem >= 57 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 57 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 57 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 57 ? { label: 'hs code', key: 'hsCode57' } : { label: '', key: '' },
    highestItem >= 58 ? { label: '', key: 'slash58' } : { label: '', key: '' },
    highestItem >= 58 ? { label: 'item number', key: 'item_number58' } : { label: '', key: '' },
    highestItem >= 58 ? { label: 'qty', key: 'quantity58' } : { label: '', key: '' },
    highestItem >= 58 ? { label: 'Qty unit', key: 'unit58' } : { label: '', key: '' },
    highestItem >= 58 ? { label: 'Item description', key: 'itemName58' } : { label: '', key: '' },
    highestItem >= 58 ? { label: 'Unit Price', key: 'unitPrice58' } : { label: '', key: '' },
    highestItem >= 58 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 58 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 58 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 58 ? { label: 'hs code', key: 'hsCode58' } : { label: '', key: '' },
    highestItem >= 59 ? { label: '', key: 'slash59' } : { label: '', key: '' },
    highestItem >= 59 ? { label: 'item number', key: 'item_number59' } : { label: '', key: '' },
    highestItem >= 59 ? { label: 'qty', key: 'quantity59' } : { label: '', key: '' },
    highestItem >= 59 ? { label: 'Qty unit', key: 'unit59' } : { label: '', key: '' },
    highestItem >= 59 ? { label: 'Item description', key: 'itemName59' } : { label: '', key: '' },
    highestItem >= 59 ? { label: 'Unit Price', key: 'unitPrice59' } : { label: '', key: '' },
    highestItem >= 59 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 59 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 59 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 59 ? { label: 'hs code', key: 'hsCode59' } : { label: '', key: '' },
    highestItem >= 60 ? { label: '', key: 'slash60' } : { label: '', key: '' },
    highestItem >= 60 ? { label: 'item number', key: 'item_number60' } : { label: '', key: '' },
    highestItem >= 60 ? { label: 'qty', key: 'quantity60' } : { label: '', key: '' },
    highestItem >= 60 ? { label: 'Qty unit', key: 'unit60' } : { label: '', key: '' },
    highestItem >= 60 ? { label: 'Item description', key: 'itemName60' } : { label: '', key: '' },
    highestItem >= 60 ? { label: 'Unit Price', key: 'unitPrice60' } : { label: '', key: '' },
    highestItem >= 60 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 60 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 60 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 60 ? { label: 'hs code', key: 'hsCode60' } : { label: '', key: '' },
    highestItem >= 61 ? { label: '', key: 'slash61' } : { label: '', key: '' },
    highestItem >= 61 ? { label: 'item number', key: 'item_number61' } : { label: '', key: '' },
    highestItem >= 61 ? { label: 'qty', key: 'quantity61' } : { label: '', key: '' },
    highestItem >= 61 ? { label: 'Qty unit', key: 'unit61' } : { label: '', key: '' },
    highestItem >= 61 ? { label: 'Item description', key: 'itemName61' } : { label: '', key: '' },
    highestItem >= 61 ? { label: 'Unit Price', key: 'unitPrice61' } : { label: '', key: '' },
    highestItem >= 61 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 61 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 61 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 61 ? { label: 'hs code', key: 'hsCode61' } : { label: '', key: '' },
    highestItem >= 62 ? { label: '', key: 'slash62' } : { label: '', key: '' },
    highestItem >= 62 ? { label: 'item number', key: 'item_number62' } : { label: '', key: '' },
    highestItem >= 62 ? { label: 'qty', key: 'quantity62' } : { label: '', key: '' },
    highestItem >= 62 ? { label: 'Qty unit', key: 'unit62' } : { label: '', key: '' },
    highestItem >= 62 ? { label: 'Item description', key: 'itemName62' } : { label: '', key: '' },
    highestItem >= 62 ? { label: 'Unit Price', key: 'unitPrice62' } : { label: '', key: '' },
    highestItem >= 62 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 62 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 62 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 62 ? { label: 'hs code', key: 'hsCode62' } : { label: '', key: '' },
    highestItem >= 63 ? { label: '', key: 'slash63' } : { label: '', key: '' },
    highestItem >= 63 ? { label: 'item number', key: 'item_number63' } : { label: '', key: '' },
    highestItem >= 63 ? { label: 'qty', key: 'quantity63' } : { label: '', key: '' },
    highestItem >= 63 ? { label: 'Qty unit', key: 'unit63' } : { label: '', key: '' },
    highestItem >= 63 ? { label: 'Item description', key: 'itemName63' } : { label: '', key: '' },
    highestItem >= 63 ? { label: 'Unit Price', key: 'unitPrice63' } : { label: '', key: '' },
    highestItem >= 63 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 63 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 63 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 63 ? { label: 'hs code', key: 'hsCode63' } : { label: '', key: '' },
    highestItem >= 64 ? { label: '', key: 'slash64' } : { label: '', key: '' },
    highestItem >= 64 ? { label: 'item number', key: 'item_number64' } : { label: '', key: '' },
    highestItem >= 64 ? { label: 'qty', key: 'quantity64' } : { label: '', key: '' },
    highestItem >= 64 ? { label: 'Qty unit', key: 'unit64' } : { label: '', key: '' },
    highestItem >= 64 ? { label: 'Item description', key: 'itemName64' } : { label: '', key: '' },
    highestItem >= 64 ? { label: 'Unit Price', key: 'unitPrice64' } : { label: '', key: '' },
    highestItem >= 64 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 64 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 64 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 64 ? { label: 'hs code', key: 'hsCode64' } : { label: '', key: '' },
    highestItem >= 65 ? { label: '', key: 'slash65' } : { label: '', key: '' },
    highestItem >= 65 ? { label: 'item number', key: 'item_number65' } : { label: '', key: '' },
    highestItem >= 65 ? { label: 'qty', key: 'quantity65' } : { label: '', key: '' },
    highestItem >= 65 ? { label: 'Qty unit', key: 'unit65' } : { label: '', key: '' },
    highestItem >= 65 ? { label: 'Item description', key: 'itemName65' } : { label: '', key: '' },
    highestItem >= 65 ? { label: 'Unit Price', key: 'unitPrice65' } : { label: '', key: '' },
    highestItem >= 65 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 65 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 65 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 65 ? { label: 'hs code', key: 'hsCode65' } : { label: '', key: '' },
    highestItem >= 66 ? { label: '', key: 'slash66' } : { label: '', key: '' },
    highestItem >= 66 ? { label: 'item number', key: 'item_number66' } : { label: '', key: '' },
    highestItem >= 66 ? { label: 'qty', key: 'quantity66' } : { label: '', key: '' },
    highestItem >= 66 ? { label: 'Qty unit', key: 'unit66' } : { label: '', key: '' },
    highestItem >= 66 ? { label: 'Item description', key: 'itemName66' } : { label: '', key: '' },
    highestItem >= 66 ? { label: 'Unit Price', key: 'unitPrice66' } : { label: '', key: '' },
    highestItem >= 66 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 66 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 66 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 66 ? { label: 'hs code', key: 'hsCode66' } : { label: '', key: '' },
    highestItem >= 67 ? { label: '', key: 'slash67' } : { label: '', key: '' },
    highestItem >= 67 ? { label: 'item number', key: 'item_number67' } : { label: '', key: '' },
    highestItem >= 67 ? { label: 'qty', key: 'quantity67' } : { label: '', key: '' },
    highestItem >= 67 ? { label: 'Qty unit', key: 'unit67' } : { label: '', key: '' },
    highestItem >= 67 ? { label: 'Item description', key: 'itemName67' } : { label: '', key: '' },
    highestItem >= 67 ? { label: 'Unit Price', key: 'unitPrice67' } : { label: '', key: '' },
    highestItem >= 67 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 67 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 67 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 67 ? { label: 'hs code', key: 'hsCode67' } : { label: '', key: '' },
    highestItem >= 68 ? { label: '', key: 'slash68' } : { label: '', key: '' },
    highestItem >= 68 ? { label: 'item number', key: 'item_number68' } : { label: '', key: '' },
    highestItem >= 68 ? { label: 'qty', key: 'quantity68' } : { label: '', key: '' },
    highestItem >= 68 ? { label: 'Qty unit', key: 'unit68' } : { label: '', key: '' },
    highestItem >= 68 ? { label: 'Item description', key: 'itemName68' } : { label: '', key: '' },
    highestItem >= 68 ? { label: 'Unit Price', key: 'unitPrice68' } : { label: '', key: '' },
    highestItem >= 68 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 68 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 68 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 68 ? { label: 'hs code', key: 'hsCode68' } : { label: '', key: '' },
    highestItem >= 69 ? { label: '', key: 'slash69' } : { label: '', key: '' },
    highestItem >= 69 ? { label: 'item number', key: 'item_number69' } : { label: '', key: '' },
    highestItem >= 69 ? { label: 'qty', key: 'quantity69' } : { label: '', key: '' },
    highestItem >= 69 ? { label: 'Qty unit', key: 'unit69' } : { label: '', key: '' },
    highestItem >= 69 ? { label: 'Item description', key: 'itemName69' } : { label: '', key: '' },
    highestItem >= 69 ? { label: 'Unit Price', key: 'unitPrice69' } : { label: '', key: '' },
    highestItem >= 69 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 69 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 69 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 69 ? { label: 'hs code', key: 'hsCode69' } : { label: '', key: '' },
    highestItem >= 70 ? { label: '', key: 'slash70' } : { label: '', key: '' },
    highestItem >= 70 ? { label: 'item number', key: 'item_number70' } : { label: '', key: '' },
    highestItem >= 70 ? { label: 'qty', key: 'quantity70' } : { label: '', key: '' },
    highestItem >= 70 ? { label: 'Qty unit', key: 'unit70' } : { label: '', key: '' },
    highestItem >= 70 ? { label: 'Item description', key: 'itemName70' } : { label: '', key: '' },
    highestItem >= 70 ? { label: 'Unit Price', key: 'unitPrice70' } : { label: '', key: '' },
    highestItem >= 70 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 70 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 70 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 70 ? { label: 'hs code', key: 'hsCode70' } : { label: '', key: '' },
    highestItem >= 71 ? { label: '', key: 'slash71' } : { label: '', key: '' },
    highestItem >= 71 ? { label: 'item number', key: 'item_number71' } : { label: '', key: '' },
    highestItem >= 71 ? { label: 'qty', key: 'quantity71' } : { label: '', key: '' },
    highestItem >= 71 ? { label: 'Qty unit', key: 'unit71' } : { label: '', key: '' },
    highestItem >= 71 ? { label: 'Item description', key: 'itemName71' } : { label: '', key: '' },
    highestItem >= 71 ? { label: 'Unit Price', key: 'unitPrice71' } : { label: '', key: '' },
    highestItem >= 71 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 71 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 71 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 71 ? { label: 'hs code', key: 'hsCode71' } : { label: '', key: '' },
    highestItem >= 72 ? { label: '', key: 'slash72' } : { label: '', key: '' },
    highestItem >= 72 ? { label: 'item number', key: 'item_number72' } : { label: '', key: '' },
    highestItem >= 72 ? { label: 'qty', key: 'quantity72' } : { label: '', key: '' },
    highestItem >= 72 ? { label: 'Qty unit', key: 'unit72' } : { label: '', key: '' },
    highestItem >= 72 ? { label: 'Item description', key: 'itemName72' } : { label: '', key: '' },
    highestItem >= 72 ? { label: 'Unit Price', key: 'unitPrice72' } : { label: '', key: '' },
    highestItem >= 72 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 72 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 72 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 72 ? { label: 'hs code', key: 'hsCode72' } : { label: '', key: '' },
    highestItem >= 73 ? { label: '', key: 'slash73' } : { label: '', key: '' },
    highestItem >= 73 ? { label: 'item number', key: 'item_number73' } : { label: '', key: '' },
    highestItem >= 73 ? { label: 'qty', key: 'quantity73' } : { label: '', key: '' },
    highestItem >= 73 ? { label: 'Qty unit', key: 'unit73' } : { label: '', key: '' },
    highestItem >= 73 ? { label: 'Item description', key: 'itemName73' } : { label: '', key: '' },
    highestItem >= 73 ? { label: 'Unit Price', key: 'unitPrice73' } : { label: '', key: '' },
    highestItem >= 73 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 73 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 73 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 73 ? { label: 'hs code', key: 'hsCode73' } : { label: '', key: '' },
    highestItem >= 74 ? { label: '', key: 'slash74' } : { label: '', key: '' },
    highestItem >= 74 ? { label: 'item number', key: 'item_number74' } : { label: '', key: '' },
    highestItem >= 74 ? { label: 'qty', key: 'quantity74' } : { label: '', key: '' },
    highestItem >= 74 ? { label: 'Qty unit', key: 'unit74' } : { label: '', key: '' },
    highestItem >= 74 ? { label: 'Item description', key: 'itemName74' } : { label: '', key: '' },
    highestItem >= 74 ? { label: 'Unit Price', key: 'unitPrice74' } : { label: '', key: '' },
    highestItem >= 74 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 74 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 74 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 74 ? { label: 'hs code', key: 'hsCode74' } : { label: '', key: '' },
    highestItem >= 75 ? { label: '', key: 'slash75' } : { label: '', key: '' },
    highestItem >= 75 ? { label: 'item number', key: 'item_number75' } : { label: '', key: '' },
    highestItem >= 75 ? { label: 'qty', key: 'quantity75' } : { label: '', key: '' },
    highestItem >= 75 ? { label: 'Qty unit', key: 'unit75' } : { label: '', key: '' },
    highestItem >= 75 ? { label: 'Item description', key: 'itemName75' } : { label: '', key: '' },
    highestItem >= 75 ? { label: 'Unit Price', key: 'unitPrice75' } : { label: '', key: '' },
    highestItem >= 75 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 75 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 75 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 75 ? { label: 'hs code', key: 'hsCode75' } : { label: '', key: '' },
    highestItem >= 76 ? { label: '', key: 'slash76' } : { label: '', key: '' },
    highestItem >= 76 ? { label: 'item number', key: 'item_number76' } : { label: '', key: '' },
    highestItem >= 76 ? { label: 'qty', key: 'quantity76' } : { label: '', key: '' },
    highestItem >= 76 ? { label: 'Qty unit', key: 'unit76' } : { label: '', key: '' },
    highestItem >= 76 ? { label: 'Item description', key: 'itemName76' } : { label: '', key: '' },
    highestItem >= 76 ? { label: 'Unit Price', key: 'unitPrice76' } : { label: '', key: '' },
    highestItem >= 76 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 76 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 76 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 76 ? { label: 'hs code', key: 'hsCode76' } : { label: '', key: '' },
    highestItem >= 77 ? { label: '', key: 'slash77' } : { label: '', key: '' },
    highestItem >= 77 ? { label: 'item number', key: 'item_number77' } : { label: '', key: '' },
    highestItem >= 77 ? { label: 'qty', key: 'quantity77' } : { label: '', key: '' },
    highestItem >= 77 ? { label: 'Qty unit', key: 'unit77' } : { label: '', key: '' },
    highestItem >= 77 ? { label: 'Item description', key: 'itemName77' } : { label: '', key: '' },
    highestItem >= 77 ? { label: 'Unit Price', key: 'unitPrice77' } : { label: '', key: '' },
    highestItem >= 77 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 77 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 77 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 77 ? { label: 'hs code', key: 'hsCode77' } : { label: '', key: '' },
    highestItem >= 78 ? { label: '', key: 'slash78' } : { label: '', key: '' },
    highestItem >= 78 ? { label: 'item number', key: 'item_number78' } : { label: '', key: '' },
    highestItem >= 78 ? { label: 'qty', key: 'quantity78' } : { label: '', key: '' },
    highestItem >= 78 ? { label: 'Qty unit', key: 'unit78' } : { label: '', key: '' },
    highestItem >= 78 ? { label: 'Item description', key: 'itemName78' } : { label: '', key: '' },
    highestItem >= 78 ? { label: 'Unit Price', key: 'unitPrice78' } : { label: '', key: '' },
    highestItem >= 78 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 78 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 78 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 78 ? { label: 'hs code', key: 'hsCode78' } : { label: '', key: '' },
    highestItem >= 79 ? { label: '', key: 'slash79' } : { label: '', key: '' },
    highestItem >= 79 ? { label: 'item number', key: 'item_number79' } : { label: '', key: '' },
    highestItem >= 79 ? { label: 'qty', key: 'quantity79' } : { label: '', key: '' },
    highestItem >= 79 ? { label: 'Qty unit', key: 'unit79' } : { label: '', key: '' },
    highestItem >= 79 ? { label: 'Item description', key: 'itemName79' } : { label: '', key: '' },
    highestItem >= 79 ? { label: 'Unit Price', key: 'unitPrice79' } : { label: '', key: '' },
    highestItem >= 79 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 79 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 79 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 79 ? { label: 'hs code', key: 'hsCode79' } : { label: '', key: '' },
    highestItem >= 80 ? { label: '', key: 'slash80' } : { label: '', key: '' },
    highestItem >= 80 ? { label: 'item number', key: 'item_number80' } : { label: '', key: '' },
    highestItem >= 80 ? { label: 'qty', key: 'quantity80' } : { label: '', key: '' },
    highestItem >= 80 ? { label: 'Qty unit', key: 'unit80' } : { label: '', key: '' },
    highestItem >= 80 ? { label: 'Item description', key: 'itemName80' } : { label: '', key: '' },
    highestItem >= 80 ? { label: 'Unit Price', key: 'unitPrice80' } : { label: '', key: '' },
    highestItem >= 80 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 80 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 80 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 80 ? { label: 'hs code', key: 'hsCode80' } : { label: '', key: '' },
    highestItem >= 81 ? { label: '', key: 'slash81' } : { label: '', key: '' },
    highestItem >= 81 ? { label: 'item number', key: 'item_number81' } : { label: '', key: '' },
    highestItem >= 81 ? { label: 'qty', key: 'quantity81' } : { label: '', key: '' },
    highestItem >= 81 ? { label: 'Qty unit', key: 'unit81' } : { label: '', key: '' },
    highestItem >= 81 ? { label: 'Item description', key: 'itemName81' } : { label: '', key: '' },
    highestItem >= 81 ? { label: 'Unit Price', key: 'unitPrice81' } : { label: '', key: '' },
    highestItem >= 81 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 81 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 81 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 81 ? { label: 'hs code', key: 'hsCode81' } : { label: '', key: '' },
    highestItem >= 82 ? { label: '', key: 'slash82' } : { label: '', key: '' },
    highestItem >= 82 ? { label: 'item number', key: 'item_number82' } : { label: '', key: '' },
    highestItem >= 82 ? { label: 'qty', key: 'quantity82' } : { label: '', key: '' },
    highestItem >= 82 ? { label: 'Qty unit', key: 'unit82' } : { label: '', key: '' },
    highestItem >= 82 ? { label: 'Item description', key: 'itemName82' } : { label: '', key: '' },
    highestItem >= 82 ? { label: 'Unit Price', key: 'unitPrice82' } : { label: '', key: '' },
    highestItem >= 82 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 82 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 82 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 82 ? { label: 'hs code', key: 'hsCode82' } : { label: '', key: '' },
    highestItem >= 83 ? { label: '', key: 'slash83' } : { label: '', key: '' },
    highestItem >= 83 ? { label: 'item number', key: 'item_number83' } : { label: '', key: '' },
    highestItem >= 83 ? { label: 'qty', key: 'quantity83' } : { label: '', key: '' },
    highestItem >= 83 ? { label: 'Qty unit', key: 'unit83' } : { label: '', key: '' },
    highestItem >= 83 ? { label: 'Item description', key: 'itemName83' } : { label: '', key: '' },
    highestItem >= 83 ? { label: 'Unit Price', key: 'unitPrice83' } : { label: '', key: '' },
    highestItem >= 83 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 83 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 83 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 83 ? { label: 'hs code', key: 'hsCode83' } : { label: '', key: '' },
    highestItem >= 84 ? { label: '', key: 'slash84' } : { label: '', key: '' },
    highestItem >= 84 ? { label: 'item number', key: 'item_number84' } : { label: '', key: '' },
    highestItem >= 84 ? { label: 'qty', key: 'quantity84' } : { label: '', key: '' },
    highestItem >= 84 ? { label: 'Qty unit', key: 'unit84' } : { label: '', key: '' },
    highestItem >= 84 ? { label: 'Item description', key: 'itemName84' } : { label: '', key: '' },
    highestItem >= 84 ? { label: 'Unit Price', key: 'unitPrice84' } : { label: '', key: '' },
    highestItem >= 84 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 84 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 84 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 84 ? { label: 'hs code', key: 'hsCode84' } : { label: '', key: '' },
    highestItem >= 85 ? { label: '', key: 'slash85' } : { label: '', key: '' },
    highestItem >= 85 ? { label: 'item number', key: 'item_number85' } : { label: '', key: '' },
    highestItem >= 85 ? { label: 'qty', key: 'quantity85' } : { label: '', key: '' },
    highestItem >= 85 ? { label: 'Qty unit', key: 'unit85' } : { label: '', key: '' },
    highestItem >= 85 ? { label: 'Item description', key: 'itemName85' } : { label: '', key: '' },
    highestItem >= 85 ? { label: 'Unit Price', key: 'unitPrice85' } : { label: '', key: '' },
    highestItem >= 85 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 85 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 85 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 85 ? { label: 'hs code', key: 'hsCode85' } : { label: '', key: '' },
    highestItem >= 86 ? { label: '', key: 'slash86' } : { label: '', key: '' },
    highestItem >= 86 ? { label: 'item number', key: 'item_number86' } : { label: '', key: '' },
    highestItem >= 86 ? { label: 'qty', key: 'quantity86' } : { label: '', key: '' },
    highestItem >= 86 ? { label: 'Qty unit', key: 'unit86' } : { label: '', key: '' },
    highestItem >= 86 ? { label: 'Item description', key: 'itemName86' } : { label: '', key: '' },
    highestItem >= 86 ? { label: 'Unit Price', key: 'unitPrice86' } : { label: '', key: '' },
    highestItem >= 86 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 86 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 86 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 86 ? { label: 'hs code', key: 'hsCode86' } : { label: '', key: '' },
    highestItem >= 87 ? { label: '', key: 'slash87' } : { label: '', key: '' },
    highestItem >= 87 ? { label: 'item number', key: 'item_number87' } : { label: '', key: '' },
    highestItem >= 87 ? { label: 'qty', key: 'quantity87' } : { label: '', key: '' },
    highestItem >= 87 ? { label: 'Qty unit', key: 'unit87' } : { label: '', key: '' },
    highestItem >= 87 ? { label: 'Item description', key: 'itemName87' } : { label: '', key: '' },
    highestItem >= 87 ? { label: 'Unit Price', key: 'unitPrice87' } : { label: '', key: '' },
    highestItem >= 87 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 87 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 87 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 87 ? { label: 'hs code', key: 'hsCode87' } : { label: '', key: '' },
    highestItem >= 88 ? { label: '', key: 'slash88' } : { label: '', key: '' },
    highestItem >= 88 ? { label: 'item number', key: 'item_number88' } : { label: '', key: '' },
    highestItem >= 88 ? { label: 'qty', key: 'quantity88' } : { label: '', key: '' },
    highestItem >= 88 ? { label: 'Qty unit', key: 'unit88' } : { label: '', key: '' },
    highestItem >= 88 ? { label: 'Item description', key: 'itemName88' } : { label: '', key: '' },
    highestItem >= 88 ? { label: 'Unit Price', key: 'unitPrice88' } : { label: '', key: '' },
    highestItem >= 88 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 88 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 88 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 88 ? { label: 'hs code', key: 'hsCode88' } : { label: '', key: '' },
    highestItem >= 89 ? { label: '', key: 'slash89' } : { label: '', key: '' },
    highestItem >= 89 ? { label: 'item number', key: 'item_number89' } : { label: '', key: '' },
    highestItem >= 89 ? { label: 'qty', key: 'quantity89' } : { label: '', key: '' },
    highestItem >= 89 ? { label: 'Qty unit', key: 'unit89' } : { label: '', key: '' },
    highestItem >= 89 ? { label: 'Item description', key: 'itemName89' } : { label: '', key: '' },
    highestItem >= 89 ? { label: 'Unit Price', key: 'unitPrice89' } : { label: '', key: '' },
    highestItem >= 89 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 89 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 89 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 89 ? { label: 'hs code', key: 'hsCode89' } : { label: '', key: '' },
    highestItem >= 90 ? { label: '', key: 'slash90' } : { label: '', key: '' },
    highestItem >= 90 ? { label: 'item number', key: 'item_number90' } : { label: '', key: '' },
    highestItem >= 90 ? { label: 'qty', key: 'quantity90' } : { label: '', key: '' },
    highestItem >= 90 ? { label: 'Qty unit', key: 'unit90' } : { label: '', key: '' },
    highestItem >= 90 ? { label: 'Item description', key: 'itemName90' } : { label: '', key: '' },
    highestItem >= 90 ? { label: 'Unit Price', key: 'unitPrice90' } : { label: '', key: '' },
    highestItem >= 90 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 90 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 90 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 90 ? { label: 'hs code', key: 'hsCode90' } : { label: '', key: '' },
    highestItem >= 91 ? { label: '', key: 'slash91' } : { label: '', key: '' },
    highestItem >= 91 ? { label: 'item number', key: 'item_number91' } : { label: '', key: '' },
    highestItem >= 91 ? { label: 'qty', key: 'quantity91' } : { label: '', key: '' },
    highestItem >= 91 ? { label: 'Qty unit', key: 'unit91' } : { label: '', key: '' },
    highestItem >= 91 ? { label: 'Item description', key: 'itemName91' } : { label: '', key: '' },
    highestItem >= 91 ? { label: 'Unit Price', key: 'unitPrice91' } : { label: '', key: '' },
    highestItem >= 91 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 91 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 91 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 91 ? { label: 'hs code', key: 'hsCode91' } : { label: '', key: '' },
    highestItem >= 92 ? { label: '', key: 'slash92' } : { label: '', key: '' },
    highestItem >= 92 ? { label: 'item number', key: 'item_number92' } : { label: '', key: '' },
    highestItem >= 92 ? { label: 'qty', key: 'quantity92' } : { label: '', key: '' },
    highestItem >= 92 ? { label: 'Qty unit', key: 'unit92' } : { label: '', key: '' },
    highestItem >= 92 ? { label: 'Item description', key: 'itemName92' } : { label: '', key: '' },
    highestItem >= 92 ? { label: 'Unit Price', key: 'unitPrice92' } : { label: '', key: '' },
    highestItem >= 92 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 92 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 92 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 92 ? { label: 'hs code', key: 'hsCode92' } : { label: '', key: '' },
    highestItem >= 93 ? { label: '', key: 'slash93' } : { label: '', key: '' },
    highestItem >= 93 ? { label: 'item number', key: 'item_number93' } : { label: '', key: '' },
    highestItem >= 93 ? { label: 'qty', key: 'quantity93' } : { label: '', key: '' },
    highestItem >= 93 ? { label: 'Qty unit', key: 'unit93' } : { label: '', key: '' },
    highestItem >= 93 ? { label: 'Item description', key: 'itemName93' } : { label: '', key: '' },
    highestItem >= 93 ? { label: 'Unit Price', key: 'unitPrice93' } : { label: '', key: '' },
    highestItem >= 93 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 93 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 93 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 93 ? { label: 'hs code', key: 'hsCode93' } : { label: '', key: '' },
    highestItem >= 94 ? { label: '', key: 'slash94' } : { label: '', key: '' },
    highestItem >= 94 ? { label: 'item number', key: 'item_number94' } : { label: '', key: '' },
    highestItem >= 94 ? { label: 'qty', key: 'quantity94' } : { label: '', key: '' },
    highestItem >= 94 ? { label: 'Qty unit', key: 'unit94' } : { label: '', key: '' },
    highestItem >= 94 ? { label: 'Item description', key: 'itemName94' } : { label: '', key: '' },
    highestItem >= 94 ? { label: 'Unit Price', key: 'unitPrice94' } : { label: '', key: '' },
    highestItem >= 94 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 94 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 94 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 94 ? { label: 'hs code', key: 'hsCode94' } : { label: '', key: '' },
    highestItem >= 95 ? { label: '', key: 'slash95' } : { label: '', key: '' },
    highestItem >= 95 ? { label: 'item number', key: 'item_number95' } : { label: '', key: '' },
    highestItem >= 95 ? { label: 'qty', key: 'quantity95' } : { label: '', key: '' },
    highestItem >= 95 ? { label: 'Qty unit', key: 'unit95' } : { label: '', key: '' },
    highestItem >= 95 ? { label: 'Item description', key: 'itemName95' } : { label: '', key: '' },
    highestItem >= 95 ? { label: 'Unit Price', key: 'unitPrice95' } : { label: '', key: '' },
    highestItem >= 95 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 95 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 95 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 95 ? { label: 'hs code', key: 'hsCode95' } : { label: '', key: '' },
    highestItem >= 96 ? { label: '', key: 'slash96' } : { label: '', key: '' },
    highestItem >= 96 ? { label: 'item number', key: 'item_number96' } : { label: '', key: '' },
    highestItem >= 96 ? { label: 'qty', key: 'quantity96' } : { label: '', key: '' },
    highestItem >= 96 ? { label: 'Qty unit', key: 'unit96' } : { label: '', key: '' },
    highestItem >= 96 ? { label: 'Item description', key: 'itemName96' } : { label: '', key: '' },
    highestItem >= 96 ? { label: 'Unit Price', key: 'unitPrice96' } : { label: '', key: '' },
    highestItem >= 96 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 96 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 96 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 96 ? { label: 'hs code', key: 'hsCode96' } : { label: '', key: '' },
    highestItem >= 97 ? { label: '', key: 'slash97' } : { label: '', key: '' },
    highestItem >= 97 ? { label: 'item number', key: 'item_number97' } : { label: '', key: '' },
    highestItem >= 97 ? { label: 'qty', key: 'quantity97' } : { label: '', key: '' },
    highestItem >= 97 ? { label: 'Qty unit', key: 'unit97' } : { label: '', key: '' },
    highestItem >= 97 ? { label: 'Item description', key: 'itemName97' } : { label: '', key: '' },
    highestItem >= 97 ? { label: 'Unit Price', key: 'unitPrice97' } : { label: '', key: '' },
    highestItem >= 97 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 97 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 97 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 97 ? { label: 'hs code', key: 'hsCode97' } : { label: '', key: '' },
    highestItem >= 98 ? { label: '', key: 'slash98' } : { label: '', key: '' },
    highestItem >= 98 ? { label: 'item number', key: 'item_number98' } : { label: '', key: '' },
    highestItem >= 98 ? { label: 'qty', key: 'quantity98' } : { label: '', key: '' },
    highestItem >= 98 ? { label: 'Qty unit', key: 'unit98' } : { label: '', key: '' },
    highestItem >= 98 ? { label: 'Item description', key: 'itemName98' } : { label: '', key: '' },
    highestItem >= 98 ? { label: 'Unit Price', key: 'unitPrice98' } : { label: '', key: '' },
    highestItem >= 98 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 98 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 98 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 98 ? { label: 'hs code', key: 'hsCode98' } : { label: '', key: '' },
    highestItem >= 99 ? { label: '', key: 'slash99' } : { label: '', key: '' },
    highestItem >= 99 ? { label: 'item number', key: 'item_number99' } : { label: '', key: '' },
    highestItem >= 99 ? { label: 'qty', key: 'quantity99' } : { label: '', key: '' },
    highestItem >= 99 ? { label: 'Qty unit', key: 'unit99' } : { label: '', key: '' },
    highestItem >= 99 ? { label: 'Item description', key: 'itemName99' } : { label: '', key: '' },
    highestItem >= 99 ? { label: 'Unit Price', key: 'unitPrice99' } : { label: '', key: '' },
    highestItem >= 99 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 99 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 99 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 99 ? { label: 'hs code', key: 'hsCode99' } : { label: '', key: '' },
    highestItem >= 100 ? { label: '', key: 'slash100' } : { label: '', key: '' },
    highestItem >= 100 ? { label: 'item number', key: 'item_number100' } : { label: '', key: '' },
    highestItem >= 100 ? { label: 'qty', key: 'quantity100' } : { label: '', key: '' },
    highestItem >= 100 ? { label: 'Qty unit', key: 'unit100' } : { label: '', key: '' },
    highestItem >= 100 ? { label: 'Item description', key: 'itemName100' } : { label: '', key: '' },
    highestItem >= 100 ? { label: 'Unit Price', key: 'unitPrice100' } : { label: '', key: '' },
    highestItem >= 100 ? { label: 'net weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 100 ? { label: 'gross weight', key: 'itemGrossWeight14' } : { label: '', key: '' },
    highestItem >= 100 ? { label: 'country manufactured', key: 'countryManufactured4' } : { label: '', key: '' },
    highestItem >= 100 ? { label: 'hs code', key: 'hsCode100' } : { label: '', key: '' },
  ];
  const dauHeader = [
    { label: 'Order Number', key: 'hawb' },
    { label: 'Consignee', key: 'contact' },
    { label: 'ID Card Number', key: '' },
    { label: 'Consignee Phone', key: 'phone' },
    { label: 'Consignee CellPhone', key: '' },
    { label: 'Email', key: '' },
    { label: 'Address 1', key: 'address' },
    { label: 'Address 2', key: 'address2' },
    { label: 'City', key: 'city' },
    { label: 'Suburb', key: 'town' },
    { label: 'Province', key: 'toState' },
    { label: 'Zipcode', key: 'postal' },
    { label: 'ISO3166', key: 'auCode' },
    { label: 'Description', key: 'description' },
    { label: 'Qty', key: 'amount' },
    { label: 'Weight (kg)', key: 'chargeable' },
    { label: 'Value', key: 'value' },
    { label: 'Currency', key: 'currency' },
    { label: 'H (cm)', key: 'firstHeight' },
    { label: 'W (cm)', key: 'firstWidth' },
    { label: 'L (cm)', key: 'firstLength' },
    { label: 'SKU', key: '' },
    { label: 'SKU Quantity', key: '' },
    { label: 'HsCode', key: 'hsCode' },
    { label: 'Service Type', key: 'serviceType' },
    { label: 'Remark', key: '' },
    { label: 'Seller Account', key: '' },
    { label: "Sender's Name", key: 'sender' },
    { label: "Sender's Tel", key: 'fromPhone' },
    { label: "Sender's Country", key: 'fromCode' },
    { label: "Sender's State", key: 'fromState' },
    { label: "Sender's City", key: 'fromCity' },
    { label: "Sender's Address", key: 'fromAddress' },
    { label: "Sender's Address1", key: '' },
    { label: "Sender's ZipCode", key: 'fromPostal' },
    { label: "Sender's Email", key: 'fromEmail' },
    { label: 'Sender VAT', key: '' },
    { label: 'dutyPaid', key: 'dutyPaid' },
    { label: 'taxCode', key: '' },
    { label: 'ABN', key: '' },
    { label: 'GST Exemption Code', key: '' },
    { label: 'CH VAT No.', key: '' },
    { label: 'CH Register Name', key: '' },
    { label: 'Origin Country', key: 'fromCode' },
    { label: 'SKU1', key: '' },
    { label: 'SK1 Quantity', key: '' },
    { label: 'SKU2', key: '' },
    { label: 'SKU2 Quantity', key: '' },
    { label: 'SKU3', key: '' },
    { label: 'SKU3 Quantity', key: '' },
    { label: 'SKU4', key: '' },
    { label: 'SKU4 Quantity', key: '' },
    { label: 'SKU5', key: '' },
    { label: 'SKU5 Quantity', key: '' },
    { label: 'SKU6', key: '' },
    { label: 'SKU6 Quantity', key: '' },
    { label: 'SKU7', key: '' },
    { label: 'SKU7 Quantity', key: '' },
    { label: 'SKU8', key: '' },
    { label: 'SKU8 Quantity', key: '' },
    { label: 'SKU9', key: '' },
    { label: 'SKU9 Quantity', key: '' },
    { label: 'SKU10', key: '' },
    { label: 'SKU10 Quantity', key: '' },
    { label: 'Warehouse ID', key: '' },
    { label: 'DescriptionCN', key: '' },
    { label: 'CPF No.', key: '' },
    { label: 'PostagePrice', key: '' },
    { label: 'PostageCurrency', key: '' },
    { label: 'IOSS number', key: '' },
    { label: 'ProductURL', key: '' },
    { label: 'Additional Service', key: '' },
  ];
  const trackingHeader = [{ label: 'HAWB', key: 'hawb' }];
  const d3pHeader = [
    { label: 'Order Number', key: 'removeSkd' },
    { label: 'Consignee', key: 'd3pConsigneeName' },
    { label: 'ID Card Number', key: '' },
    { label: 'ConsigneePhone', key: 'phone' },
    { label: 'Consignee CellPhone', key: '' },
    { label: 'Email', key: 'toEmail' },
    { label: 'Address 1', key: 'address' },
    { label: 'Address 2', key: 'address2' },
    { label: 'City', key: 'city' },
    { label: 'Suburb', key: 'town' },
    { label: 'Province', key: 'toState' },
    { label: 'Zipcode', key: 'postal' },
    { label: 'ISO3166', key: 'code' },
    { label: 'Description', key: 'description' },
    { label: 'Qty', key: 'amount' },
    { label: 'Weight (kg)', key: 'chargeable' },
    { label: 'Value', key: 'value' },
    { label: 'Currency', key: 'currency' },
    { label: 'H (cm)', key: 'firstHeight' },
    { label: 'W (cm)', key: 'firstWidth' },
    { label: 'L (cm)', key: 'firstLength' },
    { label: 'SKU', key: '' },
    { label: 'SKU Quantity', key: '' },
    { label: 'HsCode', key: 'hsCode' },
    { label: 'Service Type', key: 'serviceType' },
    { label: 'Remark', key: '' },
    { label: 'Seller Account', key: '' },
    { label: "Sender's Name", key: 'd3pSenderName' },
    { label: "Sender's Tel", key: 'd3pSenderTel' },
    { label: "Sender's Country", key: 'd3pSenderCountry' },
    { label: "Sender's State", key: '' },
    { label: "Sender's City", key: 'd3pSenderCity' },
    { label: "Sender's Address", key: 'd3pSenderAddress' },
    { label: "Sender's Address1", key: '' },
    { label: "Sender's ZipCode", key: 'd3pSenderZipcode' },
    { label: "Sender's Email", key: 'd3pSenderEmail' },
    { label: 'Sender VAT', key: '' },
    { label: 'dutyPaid', key: 'dutyPaid' },
    { label: 'taxCode', key: '' },
    { label: 'ABN', key: '' },
    { label: 'GST Exemption Code', key: '' },
    { label: 'CH VAT No.', key: '' },
    { label: 'CH Register Name', key: '' },
    { label: 'Origin Country', key: 'd3pSenderCountry' },
    { label: 'SKU1', key: '' },
    { label: 'SK1 Quantity', key: '' },
    { label: 'SKU2', key: '' },
    { label: 'SKU2 Quantity', key: '' },
    { label: 'SKU3', key: '' },
    { label: 'SKU3 Quantity', key: '' },
    { label: 'SKU4', key: '' },
    { label: 'SKU4 Quantity', key: '' },
    { label: 'SKU5', key: '' },
    { label: 'SKU5 Quantity', key: '' },
    { label: 'SKU6', key: '' },
    { label: 'SKU6 Quantity', key: '' },
    { label: 'SKU7', key: '' },
    { label: 'SKU7 Quantity', key: '' },
    { label: 'SKU8', key: '' },
    { label: 'SKU8 Quantity', key: '' },
    { label: 'SKU9', key: '' },
    { label: 'SKU9 Quantity', key: '' },
    { label: 'SKU10', key: '' },
    { label: 'SKU10 Quantity', key: '' },
    { label: 'Warehouse ID', key: '' },
    { label: 'DescriptionCN', key: '' },
    { label: 'CPF No.', key: '' },
    { label: 'PostagePrice', key: '' },
    { label: 'PostageCurrency', key: 'currency' },
    { label: 'IOSS number', key: '' },
    { label: 'ProductURL', key: '' },
    { label: 'Additional Service', key: '' },
  ];
  const debitExport = {
    data: getSelected,
    headers: eamHeader,
    filename: fileName || `Report ${startDay} to ${endDay}`,
  };
  const linexExport = {
    data: getSelected,
    headers: eamHeader,
    filename: fileName || `Report ${startDay} to ${endDay}`,
  };
  const dauExport = {
    data: getSelected,
    headers: dauHeader,
    filename: fileName || `Report ${startDay} to ${endDay}`,
  };
  const trackingExport = {
    data: getSelected,
    headers: trackingHeader,
    filename: fileName || `Report ${startDay} to ${endDay}`,
  };
  const d3pExport = {
    data: getSelected,
    headers: d3pHeader,
    filename: fileName || `Report D3P ${startDay} to ${endDay}`,
  };
  const customizeExport = {
    data: exportSelected,
    headers: trueHeader,
    filename: fileName || `Report ${startDay} to ${endDay}`,
  };

  function toFixedIfNecessary(value, dp) {
    return +parseFloat(value).toFixed(dp);
  }
  function exportText() {
    let text = [];
    selected
      .sort((a, b) => {
        if (includeInfo) {
          a = a.sender || '';
          b = b.sender || '';
          return a.localeCompare(b);
        }
      })
      .map((s, index) => {
        if (s.type === 'WPX') {
          text.push(`
${index + 1}|SHIP_FROM|${s.cleName}|${s.sender}|${s.cleAddress}||${s.cleAddress2}|${s.cleAddress3}|${s.clePostal}|${
            s.cleCity
          }|||${s.cleCode}|${s.cleEmail}|O|65|${s.clePhone}||||||||||
${index + 1}|SHIP_TO|${s.contact}|${s.company}|${s.address}||${s.address2}|${s.address3}|${s.postal.trim()}|${s.city}|${
            s.town
          }|${s.toState}|${s.code}|${s.toEmail}|M|${s.toPhoneCode}|${s.phone}||||||${s.eori}||||||
${index + 1}|SHIPMENT||${s.cleDate}|${s.cleShipmentType}|${s.type}|${s.cleShipmentType}|${s.cleShipmentType}|${
            s.cleDesc
          }|${s.hawb}|${s.value}|USD|||${s.dhlAccount}|${s.dhlAccount}|||||${s.incoterm}|${s.reason}|||||||
${index + 1}|PACKAGING|${s.firstPcs}|KG|${s.firstLength}|${s.firstWidth}|${s.firstHeight}|CM|
${index + 1}|EXTRA_SERVICE||||||N|||||||||||||||||||||||
${index + 1}|INVOICE_HEADER||${s.hawb}|${s.cleDate}|${s.makh}|${s.makh}||||||||||||||||
${index + 1}|INV_ITEM|${s.itemName}||${s.quantity}|${s.uom}|${toFixedIfNecessary(s.item_value, 4)}|USD|${
            s.itemNetWeight
          }|${s.itemGrossWeight}|${s.countryManufactured}
${
  s.invoiceValue.length >= 2
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName2 +
      '||' +
      s.quantity2 +
      '|' +
      s.uom2 +
      '|' +
      toFixedIfNecessary(s?.item_value2, 4) +
      '|USD|' +
      s.itemNetWeight2 +
      '|' +
      s.itemGrossWeight2 +
      '|' +
      s.countryManufactured2
    : ''
}
${
  s.invoiceValue.length >= 3
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName3 +
      '||' +
      s.quantity3 +
      '|' +
      s.uom3 +
      '|' +
      toFixedIfNecessary(s?.item_value3, 4) +
      '|USD|' +
      s.itemNetWeight3 +
      '|' +
      s.itemGrossWeight3 +
      '|' +
      s.countryManufactured3
    : ''
}
${
  s.invoiceValue.length >= 4
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName4 +
      '||' +
      s.quantity4 +
      '|' +
      s.uom4 +
      '|' +
      toFixedIfNecessary(s?.item_value4, 4) +
      '|USD|' +
      s.itemNetWeight4 +
      '|' +
      s.itemGrossWeight4 +
      '|' +
      s.countryManufactured4
    : ''
}
${
  s.invoiceValue.length >= 5
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName5 +
      '||' +
      s.quantity5 +
      '|' +
      s.uom5 +
      '|' +
      toFixedIfNecessary(s?.item_value5, 4) +
      '|USD|' +
      s.itemNetWeight5 +
      '|' +
      s.itemGrossWeight5 +
      '|' +
      s.countryManufactured5
    : ''
}
${
  s.invoiceValue.length >= 6
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName6 +
      '||' +
      s.quantity6 +
      '|' +
      s.uom6 +
      '|' +
      toFixedIfNecessary(s?.item_value6, 4) +
      '|USD|' +
      s.itemNetWeight6 +
      '|' +
      s.itemGrossWeight6 +
      '|' +
      s.countryManufactured6
    : ''
}
${
  s.invoiceValue.length >= 7
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName7 +
      '||' +
      s.quantity7 +
      '|' +
      s.uom7 +
      '|' +
      toFixedIfNecessary(s?.item_value7, 4) +
      '|USD|' +
      s.itemNetWeight7 +
      '|' +
      s.itemGrossWeight7 +
      '|' +
      s.countryManufactured7
    : ''
}
${
  s.invoiceValue.length >= 8
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName8 +
      '||' +
      s.quantity8 +
      '|' +
      s.uom8 +
      '|' +
      toFixedIfNecessary(s?.item_value8, 4) +
      '|USD|' +
      s.itemNetWeight8 +
      '|' +
      s.itemGrossWeight8 +
      '|' +
      s.countryManufactured8
    : ''
}
${
  s.invoiceValue.length >= 9
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName9 +
      '||' +
      s.quantity9 +
      '|' +
      s.uom9 +
      '|' +
      toFixedIfNecessary(s?.item_value9, 4) +
      '|USD|' +
      s.itemNetWeight9 +
      '|' +
      s.itemGrossWeight9 +
      '|' +
      s.countryManufactured9
    : ''
}
${
  s.invoiceValue.length >= 10
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName10 +
      '||' +
      s.quantity10 +
      '|' +
      s.uom10 +
      '|' +
      toFixedIfNecessary(s?.item_value10, 4) +
      '|USD|' +
      s.itemNetWeight10 +
      '|' +
      s.itemGrossWeight10 +
      '|' +
      s.countryManufactured10
    : ''
}
${
  s.invoiceValue.length >= 11
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName11 +
      '||' +
      s.quantity11 +
      '|' +
      s.uom11 +
      '|' +
      toFixedIfNecessary(s?.item_value11, 4) +
      '|USD|' +
      s.itemNetWeight11 +
      '|' +
      s.itemGrossWeight11 +
      '|' +
      s.countryManufactured11
    : ''
}
${
  s.invoiceValue.length >= 12
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName12 +
      '||' +
      s.quantity12 +
      '|' +
      s.uom12 +
      '|' +
      toFixedIfNecessary(s?.item_value12, 4) +
      '|USD|' +
      s.itemNetWeight12 +
      '|' +
      s.itemGrossWeight12 +
      '|' +
      s.countryManufactured12
    : ''
}
${
  s.invoiceValue.length >= 13
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName13 +
      '||' +
      s.quantity13 +
      '|' +
      s.uom13 +
      '|' +
      toFixedIfNecessary(s?.item_value13, 4) +
      '|USD|' +
      s.itemNetWeight13 +
      '|' +
      s.itemGrossWeight13 +
      '|' +
      s.countryManufactured13
    : ''
}
${
  s.invoiceValue.length >= 14
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName14 +
      '||' +
      s.quantity14 +
      '|' +
      s.uom14 +
      '|' +
      toFixedIfNecessary(s?.item_value14, 4) +
      '|USD|' +
      s.itemNetWeight14 +
      '|' +
      s.itemGrossWeight14 +
      '|' +
      s.countryManufactured14
    : ''
}
${
  s.invoiceValue.length >= 15
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName15 +
      '||' +
      s.quantity15 +
      '|' +
      s.uom15 +
      '|' +
      toFixedIfNecessary(s?.item_value15, 4) +
      '|USD|' +
      s.itemNetWeight15 +
      '|' +
      s.itemGrossWeight15 +
      '|' +
      s.countryManufactured15
    : ''
}
${
  s.invoiceValue.length >= 16
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName16 +
      '||' +
      s.quantity16 +
      '|' +
      s.uom16 +
      '|' +
      toFixedIfNecessary(s?.item_value16, 4) +
      '|USD|' +
      s.itemNetWeight16 +
      '|' +
      s.itemGrossWeight16 +
      '|' +
      s.countryManufactured16
    : ''
}
${
  s.invoiceValue.length >= 17
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName17 +
      '||' +
      s.quantity17 +
      '|' +
      s.uom17 +
      '|' +
      toFixedIfNecessary(s?.item_value17, 4) +
      '|USD|' +
      s.itemNetWeight17 +
      '|' +
      s.itemGrossWeight17 +
      '|' +
      s.countryManufactured17
    : ''
}
${
  s.invoiceValue.length >= 18
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName18 +
      '||' +
      s.quantity18 +
      '|' +
      s.uom18 +
      '|' +
      toFixedIfNecessary(s?.item_value18, 4) +
      '|USD|' +
      s.itemNetWeight18 +
      '|' +
      s.itemGrossWeight18 +
      '|' +
      s.countryManufactured18
    : ''
}
${
  s.invoiceValue.length >= 19
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName19 +
      '||' +
      s.quantity19 +
      '|' +
      s.uom19 +
      '|' +
      toFixedIfNecessary(s?.item_value19, 4) +
      '|USD|' +
      s.itemNetWeight19 +
      '|' +
      s.itemGrossWeight19 +
      '|' +
      s.countryManufactured19
    : ''
}
${
  s.invoiceValue.length == 20
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName20 +
      '||' +
      s.quantity20 +
      '|' +
      s.uom20 +
      '|' +
      toFixedIfNecessary(s?.item_value20, 4) +
      '|USD|' +
      s.itemNetWeight20 +
      '|' +
      s.itemGrossWeight20 +
      '|' +
      s.countryManufactured20
    : ''
}
${
  s.transport !== 0
    ? exportSelected.length +
      1 +
      '|INV_ITEM|' +
      'FREIGHT COST' +
      '||' +
      '1' +
      '|' +
      'PCS' +
      '|' +
      s.transport +
      '|USD|' +
      '0.1' +
      '|' +
      '0.1' +
      '|' +
      'VN'
    : ''
}
`);
        } else if (s.type === 'DOX') {
          text.push(`
${index + 1}|SHIP_FROM|${s.cleName}|${s.sender}|${s.cleAddress}||${s.cleAddress2}|${s.cleAddress3}|${s.clePostal}|${
            s.cleCity
          }|||${s.cleCode}|${s.cleEmail}|O|65|${s.clePhone}||||||||||
${index + 1}|SHIP_TO|${s.contact}|${s.company}|${s.address}||${s.address2}||${s.postal.trim()}|${s.city}|${s.town}|${
            s.toState
          }|${s.code}|${s.toEmail}|M|${s.toPhoneCode}|${s.phone}||||||${s.eori}||||||
${index + 1}|SHIPMENT||${s.cleDate}|${s.cleShipmentType}|${s.type}|${s.cleShipmentType}|${s.cleShipmentType}|${
            s.cleDesc
          }|${s.hawb}|${s.value}|USD|||${s.dhlAccount}|${s.dhlAccount}|||||${s.incoterm}|${s.reason}|||||||
${index + 1}|PACKAGING|${s.firstPcs}|KG|${s.firstLength}|${s.firstWidth}|${s.firstHeight}|CM|
${index + 1}|EXTRA_SERVICE||||||N|||||||||||||||||||||||`);
        }
      });
    const finalText = text.toString().replaceAll(',', '').trim();
    var blob = new Blob([finalText], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'skydart_shipment_upload_file');
  }
  function exportDgp() {
    let text = [];
    selected
      .sort((a, b) => {
        if (includeInfo) {
          a = a.sender || '';
          b = b.sender || '';
          return a.localeCompare(b);
        }
      })
      .map((s, index) => {
        if (s.type === 'WPX') {
          text.push(`
${index + 1}|SHIP_FROM|${
            s.sender
          }|DG PACKAGING C/O Comtext (S) Pte.Ltd|1557 KEPPEL ROAD||089066 SINGAPORE||089066|SINGAPORE|||${
            s.cleCode
          }|bs2@dg-packaging.com|O|65|65437067||||||||||
${index + 1}|SHIP_TO|${s.contact}|${s.company}|${s.address}||${s.address2}|${s.address3}|${s.postal.trim()}|${s.city}|${
            s.town
          }|${s.toState}|${s.code}|${s.toEmail}|M|${s.toPhoneCode}|${s.phone}||||||${s.eori}||||||
${index + 1}|SHIPMENT||${s.cleDate}|${s.cleShipmentType}|${s.type}|${s.cleShipmentType}|${s.cleShipmentType}|${
            s.cleDesc
          }|${s.hawb}|${s.value}|USD|||${s.code === 'US' ? '611071514' : '611149712'}|${
            s.code === 'US' ? '611071514' : '611149712'
          }|||||${s.incoterm}|${s.reason}|||||||
${index + 1}|PACKAGING|${s.firstPcs}|KG|${s.firstLength}|${s.firstWidth}|${s.firstHeight}|CM|
${index + 1}|EXTRA_SERVICE||||||N|||||||||||||||||||||||
${index + 1}|INVOICE_HEADER||${s.hawb}|${s.cleDate}|${s.makh}|${s.makh}||||||||||||||||
${index + 1}|INV_ITEM|${s.itemName}||${s.quantity}|${s.uom}|${toFixedIfNecessary(s.item_value, 4)}|USD|${
            s.itemNetWeight
          }|${s.itemGrossWeight}|${s.countryManufactured}
${
  s.invoiceValue.length >= 2
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName2 +
      '||' +
      s.quantity2 +
      '|' +
      s.uom2 +
      '|' +
      toFixedIfNecessary(s?.item_value2, 4) +
      '|USD|' +
      s.itemNetWeight2 +
      '|' +
      s.itemGrossWeight2 +
      '|' +
      s.countryManufactured2
    : ''
}
${
  s.invoiceValue.length >= 3
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName3 +
      '||' +
      s.quantity3 +
      '|' +
      s.uom3 +
      '|' +
      toFixedIfNecessary(s?.item_value3, 4) +
      '|USD|' +
      s.itemNetWeight3 +
      '|' +
      s.itemGrossWeight3 +
      '|' +
      s.countryManufactured3
    : ''
}
${
  s.invoiceValue.length >= 4
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName4 +
      '||' +
      s.quantity4 +
      '|' +
      s.uom4 +
      '|' +
      toFixedIfNecessary(s?.item_value4, 4) +
      '|USD|' +
      s.itemNetWeight4 +
      '|' +
      s.itemGrossWeight4 +
      '|' +
      s.countryManufactured4
    : ''
}
${
  s.invoiceValue.length >= 5
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName5 +
      '||' +
      s.quantity5 +
      '|' +
      s.uom5 +
      '|' +
      toFixedIfNecessary(s?.item_value5, 4) +
      '|USD|' +
      s.itemNetWeight5 +
      '|' +
      s.itemGrossWeight5 +
      '|' +
      s.countryManufactured5
    : ''
}
${
  s.invoiceValue.length >= 6
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName6 +
      '||' +
      s.quantity6 +
      '|' +
      s.uom6 +
      '|' +
      toFixedIfNecessary(s?.item_value6, 4) +
      '|USD|' +
      s.itemNetWeight6 +
      '|' +
      s.itemGrossWeight6 +
      '|' +
      s.countryManufactured6
    : ''
}
${
  s.invoiceValue.length >= 7
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName7 +
      '||' +
      s.quantity7 +
      '|' +
      s.uom7 +
      '|' +
      toFixedIfNecessary(s?.item_value7, 4) +
      '|USD|' +
      s.itemNetWeight7 +
      '|' +
      s.itemGrossWeight7 +
      '|' +
      s.countryManufactured7
    : ''
}
${
  s.invoiceValue.length >= 8
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName8 +
      '||' +
      s.quantity8 +
      '|' +
      s.uom8 +
      '|' +
      toFixedIfNecessary(s?.item_value8, 4) +
      '|USD|' +
      s.itemNetWeight8 +
      '|' +
      s.itemGrossWeight8 +
      '|' +
      s.countryManufactured8
    : ''
}
${
  s.invoiceValue.length >= 9
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName9 +
      '||' +
      s.quantity9 +
      '|' +
      s.uom9 +
      '|' +
      toFixedIfNecessary(s?.item_value9, 4) +
      '|USD|' +
      s.itemNetWeight9 +
      '|' +
      s.itemGrossWeight9 +
      '|' +
      s.countryManufactured9
    : ''
}
${
  s.invoiceValue.length >= 10
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName10 +
      '||' +
      s.quantity10 +
      '|' +
      s.uom10 +
      '|' +
      toFixedIfNecessary(s?.item_value10, 4) +
      '|USD|' +
      s.itemNetWeight10 +
      '|' +
      s.itemGrossWeight10 +
      '|' +
      s.countryManufactured10
    : ''
}
${
  s.invoiceValue.length >= 11
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName11 +
      '||' +
      s.quantity11 +
      '|' +
      s.uom11 +
      '|' +
      toFixedIfNecessary(s?.item_value11, 4) +
      '|USD|' +
      s.itemNetWeight11 +
      '|' +
      s.itemGrossWeight11 +
      '|' +
      s.countryManufactured11
    : ''
}
${
  s.invoiceValue.length >= 12
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName12 +
      '||' +
      s.quantity12 +
      '|' +
      s.uom12 +
      '|' +
      toFixedIfNecessary(s?.item_value12, 4) +
      '|USD|' +
      s.itemNetWeight12 +
      '|' +
      s.itemGrossWeight12 +
      '|' +
      s.countryManufactured12
    : ''
}
${
  s.invoiceValue.length >= 13
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName13 +
      '||' +
      s.quantity13 +
      '|' +
      s.uom13 +
      '|' +
      toFixedIfNecessary(s?.item_value13, 4) +
      '|USD|' +
      s.itemNetWeight13 +
      '|' +
      s.itemGrossWeight13 +
      '|' +
      s.countryManufactured13
    : ''
}
${
  s.invoiceValue.length >= 14
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName14 +
      '||' +
      s.quantity14 +
      '|' +
      s.uom14 +
      '|' +
      toFixedIfNecessary(s?.item_value14, 4) +
      '|USD|' +
      s.itemNetWeight14 +
      '|' +
      s.itemGrossWeight14 +
      '|' +
      s.countryManufactured14
    : ''
}
${
  s.invoiceValue.length >= 15
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName15 +
      '||' +
      s.quantity15 +
      '|' +
      s.uom15 +
      '|' +
      toFixedIfNecessary(s?.item_value15, 4) +
      '|USD|' +
      s.itemNetWeight15 +
      '|' +
      s.itemGrossWeight15 +
      '|' +
      s.countryManufactured15
    : ''
}
${
  s.invoiceValue.length >= 16
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName16 +
      '||' +
      s.quantity16 +
      '|' +
      s.uom16 +
      '|' +
      toFixedIfNecessary(s?.item_value16, 4) +
      '|USD|' +
      s.itemNetWeight16 +
      '|' +
      s.itemGrossWeight16 +
      '|' +
      s.countryManufactured16
    : ''
}
${
  s.invoiceValue.length >= 17
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName17 +
      '||' +
      s.quantity17 +
      '|' +
      s.uom17 +
      '|' +
      toFixedIfNecessary(s?.item_value17, 4) +
      '|USD|' +
      s.itemNetWeight17 +
      '|' +
      s.itemGrossWeight17 +
      '|' +
      s.countryManufactured17
    : ''
}
${
  s.invoiceValue.length >= 18
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName18 +
      '||' +
      s.quantity18 +
      '|' +
      s.uom18 +
      '|' +
      toFixedIfNecessary(s?.item_value18, 4) +
      '|USD|' +
      s.itemNetWeight18 +
      '|' +
      s.itemGrossWeight18 +
      '|' +
      s.countryManufactured18
    : ''
}
${
  s.invoiceValue.length >= 19
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName19 +
      '||' +
      s.quantity19 +
      '|' +
      s.uom19 +
      '|' +
      toFixedIfNecessary(s?.item_value19, 4) +
      '|USD|' +
      s.itemNetWeight19 +
      '|' +
      s.itemGrossWeight19 +
      '|' +
      s.countryManufactured19
    : ''
}
${
  s.invoiceValue.length == 20
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName20 +
      '||' +
      s.quantity20 +
      '|' +
      s.uom20 +
      '|' +
      toFixedIfNecessary(s?.item_value20, 4) +
      '|USD|' +
      s.itemNetWeight20 +
      '|' +
      s.itemGrossWeight20 +
      '|' +
      s.countryManufactured20
    : ''
}
`);
        } else if (s.type === 'DOX') {
          text.push(`
                ${index + 1}|SHIP_FROM|${
                  s.sender
                }|DG PACKAGING C/O Comtext (S) Pte.Ltd|1557 KEPPEL ROAD||089066 SINGAPORE||089066|SINGAPORE|||${
                  s.cleCode
                }|bs2@dg-packaging.com|O|65|65437067||||||||||
${index + 1}|SHIP_TO|${s.contact}|${s.company}|${s.address}||${s.address2}||${s.postal.trim()}|${s.city}|${s.town}|${
            s.toState
          }|${s.code}|${s.toEmail}|M|${s.toPhoneCode}|${s.phone}||||||${s.eori}||||||
${index + 1}|SHIPMENT||${s.cleDate}|${s.cleShipmentType}|${s.type}|${s.cleShipmentType}|${s.cleShipmentType}|${
            s.cleDesc
          }|${s.hawb}|${s.value}|USD|||${s.code === 'US' ? '611071514' : '611149712'}|${
            s.code === 'US' ? '611071514' : '611149712'
          }|||||${s.incoterm}|${s.reason}|||||||
${index + 1}|PACKAGING|${s.firstPcs}|KG|${s.firstLength}|${s.firstWidth}|${s.firstHeight}|CM|
${index + 1}|EXTRA_SERVICE||||||N|||||||||||||||||||||||`);
        }
      });
    const finalText = text.toString().replaceAll(',', '').trim();
    var blob = new Blob([finalText], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'skydart_shipment_upload_file');
  }
  //   function exportAsendia() {
  //     let text = [];
  //     selected
  //       .sort((a, b) => {
  //         if (includeInfo) {
  //           a = a.sender || '';
  //           b = b.sender || '';
  //           return a.localeCompare(b);
  //         }
  //       })
  //       .map((s, index) => {
  //         if (s.type === 'WPX') {
  //           text.push(`
  // ${
  //   index + 1
  // }|SHIP_FROM|Quynh Vu Co.,Ltd|Nguyen Tuan|32 Lam Son, Ward 2, Tan Binh District, HCM , VN||70500|VIETNAM||70500|Ho Chi Minh|||VN|bbgardenvn@gmail.com|O|84|903140235||||||||||
  // ${index + 1}|SHIP_TO|${s.contact}|${s.company}|${s.address}||${s.address2}|${s.address3}|${s.postal.trim()}|${s.city}|${
  //             s.town
  //           }|${s.toState}|${s.code}|${s.toEmail}|M|${s.toPhoneCode}|${s.phone}||||||${s.eori}||||||
  // ${index + 1}|SHIPMENT||${s.cleDate}|${s.cleShipmentType}|${s.type}|${s.cleShipmentType}|${s.cleShipmentType}|${
  //             s.cleDesc
  //           }|${s.hawb}|${s.value}|USD|||612081123|612081123|||||${s.incoterm}|${s.reason}|||||||
  // ${index + 1}|PACKAGING|${s.firstPcs}|KG|${s.firstLength}|${s.firstWidth}|${s.firstHeight}|CM|
  // ${index + 1}|EXTRA_SERVICE||||||N|||||||||||||||||||||||
  // ${index + 1}|INVOICE_HEADER||${s.hawb}|${s.cleDate}|${s.makh}|${s.makh}||||||||||||||||
  // ${index + 1}|INV_ITEM|${s.itemName}||${s.quantity}|${s.uom}|${toFixedIfNecessary(s.item_value, 4)}|USD|${
  //             s.itemNetWeight
  //           }|${s.itemGrossWeight}|${s.countryManufactured}
  // ${
  //   s.invoiceValue.length >= 2
  //     ? index +
  //       1 +
  //       '|INV_ITEM|' +
  //       s.itemName2 +
  //       '||' +
  //       s.quantity2 +
  //       '|' +
  //       s.uom2 +
  //       '|' +
  //       toFixedIfNecessary(s?.item_value2, 4) +
  //       '|USD|' +
  //       s.itemNetWeight2 +
  //       '|' +
  //       s.itemGrossWeight2 +
  //       '|' +
  //       s.countryManufactured2
  //     : ''
  // }
  // ${
  //   s.invoiceValue.length >= 3
  //     ? index +
  //       1 +
  //       '|INV_ITEM|' +
  //       s.itemName3 +
  //       '||' +
  //       s.quantity3 +
  //       '|' +
  //       s.uom3 +
  //       '|' +
  //       toFixedIfNecessary(s?.item_value3, 4) +
  //       '|USD|' +
  //       s.itemNetWeight3 +
  //       '|' +
  //       s.itemGrossWeight3 +
  //       '|' +
  //       s.countryManufactured3
  //     : ''
  // }
  // ${
  //   s.invoiceValue.length >= 4
  //     ? index +
  //       1 +
  //       '|INV_ITEM|' +
  //       s.itemName4 +
  //       '||' +
  //       s.quantity4 +
  //       '|' +
  //       s.uom4 +
  //       '|' +
  //       toFixedIfNecessary(s?.item_value4, 4) +
  //       '|USD|' +
  //       s.itemNetWeight4 +
  //       '|' +
  //       s.itemGrossWeight4 +
  //       '|' +
  //       s.countryManufactured4
  //     : ''
  // }
  // ${
  //   s.invoiceValue.length >= 5
  //     ? index +
  //       1 +
  //       '|INV_ITEM|' +
  //       s.itemName5 +
  //       '||' +
  //       s.quantity5 +
  //       '|' +
  //       s.uom5 +
  //       '|' +
  //       toFixedIfNecessary(s?.item_value5, 4) +
  //       '|USD|' +
  //       s.itemNetWeight5 +
  //       '|' +
  //       s.itemGrossWeight5 +
  //       '|' +
  //       s.countryManufactured5
  //     : ''
  // }
  // ${
  //   s.invoiceValue.length >= 6
  //     ? index +
  //       1 +
  //       '|INV_ITEM|' +
  //       s.itemName6 +
  //       '||' +
  //       s.quantity6 +
  //       '|' +
  //       s.uom6 +
  //       '|' +
  //       toFixedIfNecessary(s?.item_value6, 4) +
  //       '|USD|' +
  //       s.itemNetWeight6 +
  //       '|' +
  //       s.itemGrossWeight6 +
  //       '|' +
  //       s.countryManufactured6
  //     : ''
  // }
  // ${
  //   s.invoiceValue.length >= 7
  //     ? index +
  //       1 +
  //       '|INV_ITEM|' +
  //       s.itemName7 +
  //       '||' +
  //       s.quantity7 +
  //       '|' +
  //       s.uom7 +
  //       '|' +
  //       toFixedIfNecessary(s?.item_value7, 4) +
  //       '|USD|' +
  //       s.itemNetWeight7 +
  //       '|' +
  //       s.itemGrossWeight7 +
  //       '|' +
  //       s.countryManufactured7
  //     : ''
  // }
  // ${
  //   s.invoiceValue.length >= 8
  //     ? index +
  //       1 +
  //       '|INV_ITEM|' +
  //       s.itemName8 +
  //       '||' +
  //       s.quantity8 +
  //       '|' +
  //       s.uom8 +
  //       '|' +
  //       toFixedIfNecessary(s?.item_value8, 4) +
  //       '|USD|' +
  //       s.itemNetWeight8 +
  //       '|' +
  //       s.itemGrossWeight8 +
  //       '|' +
  //       s.countryManufactured8
  //     : ''
  // }
  // ${
  //   s.invoiceValue.length >= 9
  //     ? index +
  //       1 +
  //       '|INV_ITEM|' +
  //       s.itemName9 +
  //       '||' +
  //       s.quantity9 +
  //       '|' +
  //       s.uom9 +
  //       '|' +
  //       toFixedIfNecessary(s?.item_value9, 4) +
  //       '|USD|' +
  //       s.itemNetWeight9 +
  //       '|' +
  //       s.itemGrossWeight9 +
  //       '|' +
  //       s.countryManufactured9
  //     : ''
  // }
  // ${
  //   s.invoiceValue.length >= 10
  //     ? index +
  //       1 +
  //       '|INV_ITEM|' +
  //       s.itemName10 +
  //       '||' +
  //       s.quantity10 +
  //       '|' +
  //       s.uom10 +
  //       '|' +
  //       toFixedIfNecessary(s?.item_value10, 4) +
  //       '|USD|' +
  //       s.itemNetWeight10 +
  //       '|' +
  //       s.itemGrossWeight10 +
  //       '|' +
  //       s.countryManufactured10
  //     : ''
  // }
  // ${
  //   s.invoiceValue.length >= 11
  //     ? index +
  //       1 +
  //       '|INV_ITEM|' +
  //       s.itemName11 +
  //       '||' +
  //       s.quantity11 +
  //       '|' +
  //       s.uom11 +
  //       '|' +
  //       toFixedIfNecessary(s?.item_value11, 4) +
  //       '|USD|' +
  //       s.itemNetWeight11 +
  //       '|' +
  //       s.itemGrossWeight11 +
  //       '|' +
  //       s.countryManufactured11
  //     : ''
  // }
  // ${
  //   s.invoiceValue.length >= 12
  //     ? index +
  //       1 +
  //       '|INV_ITEM|' +
  //       s.itemName12 +
  //       '||' +
  //       s.quantity12 +
  //       '|' +
  //       s.uom12 +
  //       '|' +
  //       toFixedIfNecessary(s?.item_value12, 4) +
  //       '|USD|' +
  //       s.itemNetWeight12 +
  //       '|' +
  //       s.itemGrossWeight12 +
  //       '|' +
  //       s.countryManufactured12
  //     : ''
  // }
  // ${
  //   s.invoiceValue.length >= 13
  //     ? index +
  //       1 +
  //       '|INV_ITEM|' +
  //       s.itemName13 +
  //       '||' +
  //       s.quantity13 +
  //       '|' +
  //       s.uom13 +
  //       '|' +
  //       toFixedIfNecessary(s?.item_value13, 4) +
  //       '|USD|' +
  //       s.itemNetWeight13 +
  //       '|' +
  //       s.itemGrossWeight13 +
  //       '|' +
  //       s.countryManufactured13
  //     : ''
  // }
  // ${
  //   s.invoiceValue.length >= 14
  //     ? index +
  //       1 +
  //       '|INV_ITEM|' +
  //       s.itemName14 +
  //       '||' +
  //       s.quantity14 +
  //       '|' +
  //       s.uom14 +
  //       '|' +
  //       toFixedIfNecessary(s?.item_value14, 4) +
  //       '|USD|' +
  //       s.itemNetWeight14 +
  //       '|' +
  //       s.itemGrossWeight14 +
  //       '|' +
  //       s.countryManufactured14
  //     : ''
  // }
  // ${
  //   s.invoiceValue.length >= 15
  //     ? index +
  //       1 +
  //       '|INV_ITEM|' +
  //       s.itemName15 +
  //       '||' +
  //       s.quantity15 +
  //       '|' +
  //       s.uom15 +
  //       '|' +
  //       toFixedIfNecessary(s?.item_value15, 4) +
  //       '|USD|' +
  //       s.itemNetWeight15 +
  //       '|' +
  //       s.itemGrossWeight15 +
  //       '|' +
  //       s.countryManufactured15
  //     : ''
  // }
  // ${
  //   s.invoiceValue.length >= 16
  //     ? index +
  //       1 +
  //       '|INV_ITEM|' +
  //       s.itemName16 +
  //       '||' +
  //       s.quantity16 +
  //       '|' +
  //       s.uom16 +
  //       '|' +
  //       toFixedIfNecessary(s?.item_value16, 4) +
  //       '|USD|' +
  //       s.itemNetWeight16 +
  //       '|' +
  //       s.itemGrossWeight16 +
  //       '|' +
  //       s.countryManufactured16
  //     : ''
  // }
  // ${
  //   s.invoiceValue.length >= 17
  //     ? index +
  //       1 +
  //       '|INV_ITEM|' +
  //       s.itemName17 +
  //       '||' +
  //       s.quantity17 +
  //       '|' +
  //       s.uom17 +
  //       '|' +
  //       toFixedIfNecessary(s?.item_value17, 4) +
  //       '|USD|' +
  //       s.itemNetWeight17 +
  //       '|' +
  //       s.itemGrossWeight17 +
  //       '|' +
  //       s.countryManufactured17
  //     : ''
  // }
  // ${
  //   s.invoiceValue.length >= 18
  //     ? index +
  //       1 +
  //       '|INV_ITEM|' +
  //       s.itemName18 +
  //       '||' +
  //       s.quantity18 +
  //       '|' +
  //       s.uom18 +
  //       '|' +
  //       toFixedIfNecessary(s?.item_value18, 4) +
  //       '|USD|' +
  //       s.itemNetWeight18 +
  //       '|' +
  //       s.itemGrossWeight18 +
  //       '|' +
  //       s.countryManufactured18
  //     : ''
  // }
  // ${
  //   s.invoiceValue.length >= 19
  //     ? index +
  //       1 +
  //       '|INV_ITEM|' +
  //       s.itemName19 +
  //       '||' +
  //       s.quantity19 +
  //       '|' +
  //       s.uom19 +
  //       '|' +
  //       toFixedIfNecessary(s?.item_value19, 4) +
  //       '|USD|' +
  //       s.itemNetWeight19 +
  //       '|' +
  //       s.itemGrossWeight19 +
  //       '|' +
  //       s.countryManufactured19
  //     : ''
  // }
  // ${
  //   s.invoiceValue.length == 20
  //     ? index +
  //       1 +
  //       '|INV_ITEM|' +
  //       s.itemName20 +
  //       '||' +
  //       s.quantity20 +
  //       '|' +
  //       s.uom20 +
  //       '|' +
  //       toFixedIfNecessary(s?.item_value20, 4) +
  //       '|USD|' +
  //       s.itemNetWeight20 +
  //       '|' +
  //       s.itemGrossWeight20 +
  //       '|' +
  //       s.countryManufactured20
  //     : ''
  // }
  // ${
  //   s.transport !== 0
  //     ? exportSelected.length +
  //       1 +
  //       '|INV_ITEM|' +
  //       'FREIGHT COST' +
  //       '||' +
  //       '1' +
  //       '|' +
  //       'PCS' +
  //       '|' +
  //       s.transport +
  //       '|USD|' +
  //       '0.1' +
  //       '|' +
  //       '0.1' +
  //       '|' +
  //       'VN'
  //     : ''
  // }
  // `);
  //         } else if (s.type === 'DOX') {
  //           text.push(`
  //             ${
  //               index + 1
  //             }|SHIP_FROM|Quynh Vu Co.,Ltd|Nguyen Tuan|71 Alps Ave #04-01||70500|SINGAPORE||70500|Ho Chi Minh|||VN|bbgardenvn@gmail.com|O|84|903140235||||||||||
  // ${index + 1}|SHIP_TO|${s.contact}|${s.company}|${s.address}||${s.address2}||${s.postal.trim()}|${s.city}|${s.town}|${
  //             s.toState
  //           }|${s.code}|${s.toEmail}|M|${s.toPhoneCode}|${s.phone}||||||${s.eori}||||||
  // ${index + 1}|SHIPMENT||${s.cleDate}|${s.cleShipmentType}|${s.type}|${s.cleShipmentType}|${s.cleShipmentType}|${
  //             s.cleDesc
  //           }|${s.hawb}|${s.value}|USD|||612081123|612081123|||||${s.incoterm}|${s.reason}|||||||
  // ${index + 1}|PACKAGING|${s.firstPcs}|KG|${s.firstLength}|${s.firstWidth}|${s.firstHeight}|CM|
  // ${index + 1}|EXTRA_SERVICE||||||N|||||||||||||||||||||||`);
  //         }
  //       });
  //     const finalText = text.toString().replaceAll(',', '').trim();
  //     var blob = new Blob([finalText], { type: 'text/plain;charset=utf-8' });
  //     saveAs(blob, 'skydart_shipment_upload_file_d3p');
  //   }
  function exportAsendia() {
    let text = [];
    selected
      .sort((a, b) => {
        if (includeInfo) {
          a = a.sender || '';
          b = b.sender || '';
          return a.localeCompare(b);
        }
      })
      .map((s, index) => {
        if (s.type === 'WPX') {
          text.push(`
${index + 1}|SHIP_FROM|${s.fromName}|${s.sender}|71 Alps Ave #04-01||498745 SINGAPORE||498745|SINGAPORE|||${
            s.cleCode
          }|t.xuan@skydart.com.vn|O|65|65959272||||||||||
${index + 1}|SHIP_TO|${s.contact}|${s.company}|${s.address}||${s.address2}|${s.address3}|${s.postal.trim()}|${s.city}|${
            s.town
          }|${s.toState}|${s.code}|${s.toEmail}|M|${s.toPhoneCode}|${s.phone}||||||${s.eori}||||||
${index + 1}|SHIPMENT||${s.cleDate}|${s.cleShipmentType}|${s.type}|${s.cleShipmentType}|${s.cleShipmentType}|${
            s.cleDesc
          }|${s.hawb}|${s.value}|USD|||${s.code === 'US' ? '611071514' : '611149712'}|${
            s.code === 'US' ? '611071514' : '611149712'
          }|||||${s.incoterm}|${s.reason}|||||||
${index + 1}|PACKAGING|${s.firstPcs}|KG|${s.firstLength}|${s.firstWidth}|${s.firstHeight}|CM|
${index + 1}|EXTRA_SERVICE||||||N|||||||||||||||||||||||
${index + 1}|INVOICE_HEADER||${s.hawb}|${s.cleDate}|${s.makh}|${s.makh}||||||||||||||||
${index + 1}|INV_ITEM|${s.itemName}||${s.quantity}|${s.uom}|${toFixedIfNecessary(s.item_value, 4)}|USD|${
            s.itemNetWeight
          }|${s.itemGrossWeight}|${s.countryManufactured}
${
  s.invoiceValue.length >= 2
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName2 +
      '||' +
      s.quantity2 +
      '|' +
      s.uom2 +
      '|' +
      toFixedIfNecessary(s?.item_value2, 4) +
      '|USD|' +
      s.itemNetWeight2 +
      '|' +
      s.itemGrossWeight2 +
      '|' +
      s.countryManufactured2
    : ''
}
${
  s.invoiceValue.length >= 3
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName3 +
      '||' +
      s.quantity3 +
      '|' +
      s.uom3 +
      '|' +
      toFixedIfNecessary(s?.item_value3, 4) +
      '|USD|' +
      s.itemNetWeight3 +
      '|' +
      s.itemGrossWeight3 +
      '|' +
      s.countryManufactured3
    : ''
}
${
  s.invoiceValue.length >= 4
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName4 +
      '||' +
      s.quantity4 +
      '|' +
      s.uom4 +
      '|' +
      toFixedIfNecessary(s?.item_value4, 4) +
      '|USD|' +
      s.itemNetWeight4 +
      '|' +
      s.itemGrossWeight4 +
      '|' +
      s.countryManufactured4
    : ''
}
${
  s.invoiceValue.length >= 5
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName5 +
      '||' +
      s.quantity5 +
      '|' +
      s.uom5 +
      '|' +
      toFixedIfNecessary(s?.item_value5, 4) +
      '|USD|' +
      s.itemNetWeight5 +
      '|' +
      s.itemGrossWeight5 +
      '|' +
      s.countryManufactured5
    : ''
}
${
  s.invoiceValue.length >= 6
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName6 +
      '||' +
      s.quantity6 +
      '|' +
      s.uom6 +
      '|' +
      toFixedIfNecessary(s?.item_value6, 4) +
      '|USD|' +
      s.itemNetWeight6 +
      '|' +
      s.itemGrossWeight6 +
      '|' +
      s.countryManufactured6
    : ''
}
${
  s.invoiceValue.length >= 7
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName7 +
      '||' +
      s.quantity7 +
      '|' +
      s.uom7 +
      '|' +
      toFixedIfNecessary(s?.item_value7, 4) +
      '|USD|' +
      s.itemNetWeight7 +
      '|' +
      s.itemGrossWeight7 +
      '|' +
      s.countryManufactured7
    : ''
}
${
  s.invoiceValue.length >= 8
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName8 +
      '||' +
      s.quantity8 +
      '|' +
      s.uom8 +
      '|' +
      toFixedIfNecessary(s?.item_value8, 4) +
      '|USD|' +
      s.itemNetWeight8 +
      '|' +
      s.itemGrossWeight8 +
      '|' +
      s.countryManufactured8
    : ''
}
${
  s.invoiceValue.length >= 9
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName9 +
      '||' +
      s.quantity9 +
      '|' +
      s.uom9 +
      '|' +
      toFixedIfNecessary(s?.item_value9, 4) +
      '|USD|' +
      s.itemNetWeight9 +
      '|' +
      s.itemGrossWeight9 +
      '|' +
      s.countryManufactured9
    : ''
}
${
  s.invoiceValue.length >= 10
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName10 +
      '||' +
      s.quantity10 +
      '|' +
      s.uom10 +
      '|' +
      toFixedIfNecessary(s?.item_value10, 4) +
      '|USD|' +
      s.itemNetWeight10 +
      '|' +
      s.itemGrossWeight10 +
      '|' +
      s.countryManufactured10
    : ''
}
${
  s.invoiceValue.length >= 11
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName11 +
      '||' +
      s.quantity11 +
      '|' +
      s.uom11 +
      '|' +
      toFixedIfNecessary(s?.item_value11, 4) +
      '|USD|' +
      s.itemNetWeight11 +
      '|' +
      s.itemGrossWeight11 +
      '|' +
      s.countryManufactured11
    : ''
}
${
  s.invoiceValue.length >= 12
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName12 +
      '||' +
      s.quantity12 +
      '|' +
      s.uom12 +
      '|' +
      toFixedIfNecessary(s?.item_value12, 4) +
      '|USD|' +
      s.itemNetWeight12 +
      '|' +
      s.itemGrossWeight12 +
      '|' +
      s.countryManufactured12
    : ''
}
${
  s.invoiceValue.length >= 13
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName13 +
      '||' +
      s.quantity13 +
      '|' +
      s.uom13 +
      '|' +
      toFixedIfNecessary(s?.item_value13, 4) +
      '|USD|' +
      s.itemNetWeight13 +
      '|' +
      s.itemGrossWeight13 +
      '|' +
      s.countryManufactured13
    : ''
}
${
  s.invoiceValue.length >= 14
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName14 +
      '||' +
      s.quantity14 +
      '|' +
      s.uom14 +
      '|' +
      toFixedIfNecessary(s?.item_value14, 4) +
      '|USD|' +
      s.itemNetWeight14 +
      '|' +
      s.itemGrossWeight14 +
      '|' +
      s.countryManufactured14
    : ''
}
${
  s.invoiceValue.length >= 15
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName15 +
      '||' +
      s.quantity15 +
      '|' +
      s.uom15 +
      '|' +
      toFixedIfNecessary(s?.item_value15, 4) +
      '|USD|' +
      s.itemNetWeight15 +
      '|' +
      s.itemGrossWeight15 +
      '|' +
      s.countryManufactured15
    : ''
}
${
  s.invoiceValue.length >= 16
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName16 +
      '||' +
      s.quantity16 +
      '|' +
      s.uom16 +
      '|' +
      toFixedIfNecessary(s?.item_value16, 4) +
      '|USD|' +
      s.itemNetWeight16 +
      '|' +
      s.itemGrossWeight16 +
      '|' +
      s.countryManufactured16
    : ''
}
${
  s.invoiceValue.length >= 17
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName17 +
      '||' +
      s.quantity17 +
      '|' +
      s.uom17 +
      '|' +
      toFixedIfNecessary(s?.item_value17, 4) +
      '|USD|' +
      s.itemNetWeight17 +
      '|' +
      s.itemGrossWeight17 +
      '|' +
      s.countryManufactured17
    : ''
}
${
  s.invoiceValue.length >= 18
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName18 +
      '||' +
      s.quantity18 +
      '|' +
      s.uom18 +
      '|' +
      toFixedIfNecessary(s?.item_value18, 4) +
      '|USD|' +
      s.itemNetWeight18 +
      '|' +
      s.itemGrossWeight18 +
      '|' +
      s.countryManufactured18
    : ''
}
${
  s.invoiceValue.length >= 19
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName19 +
      '||' +
      s.quantity19 +
      '|' +
      s.uom19 +
      '|' +
      toFixedIfNecessary(s?.item_value19, 4) +
      '|USD|' +
      s.itemNetWeight19 +
      '|' +
      s.itemGrossWeight19 +
      '|' +
      s.countryManufactured19
    : ''
}
${
  s.invoiceValue.length == 20
    ? index +
      1 +
      '|INV_ITEM|' +
      s.itemName20 +
      '||' +
      s.quantity20 +
      '|' +
      s.uom20 +
      '|' +
      toFixedIfNecessary(s?.item_value20, 4) +
      '|USD|' +
      s.itemNetWeight20 +
      '|' +
      s.itemGrossWeight20 +
      '|' +
      s.countryManufactured20
    : ''
}
`);
        } else if (s.type === 'DOX') {
          text.push(`
              ${index + 1}|SHIP_FROM|${s.fromName}|${
                s.sender
              }|71 Alps Ave #04-01||498745 SINGAPORE||498745|SINGAPORE|||${
                s.cleCode
              }|t.xuan@skydart.com.vn|O|65|65959272||||||||||
${index + 1}|SHIP_TO|${s.contact}|${s.company}|${s.address}||${s.address2}||${s.postal.trim()}|${s.city}|${s.town}|${
            s.toState
          }|${s.code}|${s.toEmail}|M|${s.toPhoneCode}|${s.phone}||||||${s.eori}||||||
${index + 1}|SHIPMENT||${s.cleDate}|${s.cleShipmentType}|${s.type}|${s.cleShipmentType}|${s.cleShipmentType}|${
            s.cleDesc
          }|${s.hawb}|${s.value}|USD|||${s.code === 'US' ? '611071514' : '611149712'}|${
            s.code === 'US' ? '611071514' : '611149712'
          }|||||${s.incoterm}|${s.reason}|||||||
${index + 1}|PACKAGING|${s.firstPcs}|KG|${s.firstLength}|${s.firstWidth}|${s.firstHeight}|CM|
${index + 1}|EXTRA_SERVICE||||||N|||||||||||||||||||||||`);
        }
      });
    const finalText = text.toString().replaceAll(',', '').trim();
    var blob = new Blob([finalText], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'skydart_shipment_upload_file');
  }

  const trackArray = searchMultipleShipment.split(/\r?\n/);

  return (
    <div className="flex items-center p-3 pb-0 pt-2.5 px-2">
      <Snackbar open={alertSuccess} autoHideDuration={3000} onClose={resetSuccess}>
        <Alert elevation={5} variant="filled" onClose={resetSuccess} severity="success">
          Update Succeed
        </Alert>
      </Snackbar>
      {displayImport && (
        <div className="manifest-action-import">
          <OutsideClickHandler
            onOutsideClick={() => {
              handleImport();
            }}
          >
            <div className="w-[650px] bg-white rounded-[10px] skd-import-container-popup">
              <div className="p-2 flex items-center shadow-[rgba(0,0,0,0.05)_2.4px_2.4px_3.2px] mb-1">
                <p className="text-xl font-semibold">Upload label</p>
                <div
                  className="ml-auto w-[35px] h-[35px] rounded-full flex items-center justify-center bg-slate-100 hover:bg-slate-200 active:bg-slate-300 active:scale-90 cursor-pointer mr-1.5"
                  onClick={() => {
                    setFileList([]);
                    setFileListUrl([]);
                  }}
                >
                  <FiRefreshCcw size={30} />
                </div>
                <div
                  className="w-[35px] h-[35px] rounded-full flex items-center justify-center bg-slate-100 hover:bg-slate-200 active:bg-slate-300 active:scale-90 cursor-pointer"
                  onClick={handleImport}
                >
                  <MdOutlineClear size={30} />
                </div>
              </div>
              <div className="p-2 mb-1 flex">
                <div
                  className={`w-[50%] rounded-[7px] flex flex-col items-center justify-center p-2.5 border-dashed border-[3px] ${
                    dragActive ? 'border-emerald-500' : 'border-slate-300'
                  }`}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  <FaUpload size={35} className="text-sky-600 mt-7" />
                  <input
                    type="file"
                    id="label-upload-file"
                    style={{ display: 'none' }}
                    onChange={handleChangeFile}
                    multiple
                    accept="application/pdf,application/vnd.ms-excel"
                  />
                  <label
                    htmlFor="label-upload-file"
                    className="p-1.5 bg-sky-600 text-white font-semibold rounded-full tracking-wide w-[150px] text-center cursor-pointer hover:bg-sky-700 active:bg-sky-800 select-none mt-3 text-[17px]"
                  >
                    <p>Browse</p>
                  </label>
                  <p className="text-slate-500 my-1">Or drop a file here</p>
                  <p className="mb-7">
                    <strong className="text-rose-500">*</strong> File supported: PDF only
                  </p>
                </div>
                <div className="w-[50%] ml-2">
                  <div
                    className="p-2 pt-0 flex items-center justify-between"
                    style={{ borderBottom: '1px solid rgb(230,230,230)' }}
                  >
                    <p className="text-slate-600 font-semibold">Uploaded files</p>
                    <p>{fileListUrl?.length} files</p>
                  </div>
                  {fileListUrl?.length === 0 && (
                    <div className="flex items-center justify-center mt-2 flex-col">
                      <img src={emptyfile} alt="" loading="lazy" className="w-[125px] h-[125px] object-cover" />
                      <p>No file were found</p>
                    </div>
                  )}
                  {fileListUrl?.length !== 0 && (
                    <div className="p-2 h-[350px] pt-0 overflow-y-auto">
                      {fileListUrl?.map((item) => (
                        <div
                          className="my-3"
                          key={item.id}
                          style={{
                            borderLeft: '10px solid #0084C6',
                            borderTopLeftRadius: 9,
                            borderBottomLeftRadius: 9,
                          }}
                        >
                          <div className="flex items-center">
                            {!item.loading && !item.success && <FaFileInvoice size={20} className="ml-1.5" />}
                            {!item.loading && item.success && <FcOk size={20} className="ml-1.5" />}
                            {item.loading && <CircularProgress size={20} className="ml-1.5" />}
                            <p className="font-semibold tracking-wide text-[14px] w-full break-words ml-1.5">
                              {item.name}
                            </p>
                            <div
                              className="w-[30px] h-[30px] rounded-[10px] bg-rose-50 text-rose-500 flex items-center justify-center hover:bg-rose-100 active:bg-rose-200 cursor-pointer ml-auto"
                              onClick={() => {
                                setFileListUrl(
                                  fileListUrl.filter((s) => {
                                    return s.id !== item.id;
                                  })
                                );
                              }}
                            >
                              <FaRegTrashAlt size={20} />
                            </div>
                          </div>
                          <OutsideClickHandler
                            onOutsideClick={() => {
                              setSelectedItem(
                                fileListUrl.map((sd) => {
                                  if (sd.id === item.id) {
                                    sd.open = false;
                                  }
                                  return sd;
                                })
                              );
                            }}
                          >
                            <div className="relative">
                              <div
                                className="flex items-center mt-1.5 p-1.5 bg-slate-100 rounded-[10px] m-1.5 px-2 select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300"
                                onClick={() => {
                                  setSelectedItem(
                                    fileListUrl.map((sd) => {
                                      if (sd.id === item.id) {
                                        sd.open = !sd.open;
                                      }
                                      return sd;
                                    })
                                  );
                                }}
                              >
                                <p>{item.hawb || 'Select a matching HAWB'}</p>
                                <FaSortDown size={23} className="mt-[-10px] ml-auto" />
                              </div>
                              {item.open && (
                                <div className="bg-white absolute border-solid border-slate-300 border-[1px] w-[95%] shadow-[rgba(50,50,93,0.25)_0px_2px_5px_-1px,rgba(0,0,0,0.3)_0px_1px_3px_-1px] mx-2 rounded-[10px] z-10 cursor-pointer">
                                  {selected.map((element) => (
                                    <div
                                      className="m-1 p-1.5 rounded-[10px] bg-slate-100 hover:bg-slate-200 active:bg-slate-300 select-none"
                                      onClick={() => {
                                        setSelectedItem(
                                          fileListUrl.map((sd) => {
                                            if (sd.id === item.id) {
                                              sd.hawb = element.hawb;
                                              sd.open = false;
                                              sd.receiver = element.sender;
                                              sd.chargeable = element.chargeable;
                                              sd.shipmentType = element.type;
                                              sd.email = element.fromEmail;
                                            }
                                            return sd;
                                          })
                                        );
                                      }}
                                    >
                                      <div className="flex items-center">
                                        <div className="w-[20px] h-[20px] rounded-full border-slate-300 border-solid border-[1px] flex items-center justify-center bg-white mr-1">
                                          {element.hawb === item.hawb && (
                                            <div className="w-[12px] h-[12px] bg-sky-600 flex items-center justify-center rounded-full"></div>
                                          )}
                                        </div>
                                        <div>
                                          <p>{element.hawb}</p>
                                          <p className="leading-3 text-slate-500 text-[13px]">{element.sender}</p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </OutsideClickHandler>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {fileListUrl.length !== 0 && (
                <div className="p-2 flex items-center gap-x-1" style={{ borderTop: '1px solid rgb(235,235,235)' }}>
                  <p>
                    Selecting <span className="font-bold underline">{selected.length}</span> shipments
                  </p>
                  <button
                    type="button"
                    className="p-2 rounded-[10px] bg-slate-200 text-[16px] font-bold ml-auto w-[120px] hover:bg-slate-300 active:bg-slate-400 flex items-center justify-center"
                    onClick={handleImport}
                  >
                    <TiArrowBackOutline size={23} className="mr-1.5" />
                    Cancel
                  </button>
                  {loadingLabel.length === 0 && (
                    <button
                      type="button"
                      className={`p-2 rounded-[10px] bg-emerald-600 text-white text-[16px] font-bold w-[180px] hover:bg-emerald-500 active:bg-emerald-400 flex items-center justify-center ${
                        emptyHawb.length > 0 ? 'opacity-50' : ''
                      }`}
                      onClick={
                        emptyHawb.length > 0
                          ? () => {
                              return;
                            }
                          : publishLabel
                      }
                    >
                      Send label
                      <VscSend size={23} className="ml-1.5" />
                    </button>
                  )}
                  {loadingLabel.length > 0 && (
                    <button
                      type="button"
                      className="p-2 rounded-[10px] bg-emerald-600 text-white text-[16px] font-bold w-[180px] hover:bg-emerald-500 active:bg-emerald-400 flex items-center justify-center opacity-50"
                    >
                      Send label
                      <VscSend size={23} className="ml-1.5" />
                    </button>
                  )}
                </div>
              )}
            </div>
          </OutsideClickHandler>
        </div>
      )}
      {displayExport && (
        <div className="manifest-action-import">
          <OutsideClickHandler
            onOutsideClick={() => {
              handleExport();
            }}
          >
            <div className="manifest-action-export-container">
              <div className="manifest-action-import-header">
                <h5>Export File</h5>
                <IconButton type="button" style={{ padding: 5 }} onClick={() => setDisplayExport(false)}>
                  <MdOutlineClear />
                </IconButton>
              </div>
              <div className="skd-user-info-export-name">
                <h5>Export file name:</h5>
                <input
                  type="text"
                  placeholder={`Report ${startDay} to ${endDay} `}
                  value={fileName}
                  onChange={(e) => setFileName(e.target.value)}
                />
                {fileName !== '' && (
                  <MdOutlineClear size={25} className="clear-export-name-skd-user" onClick={() => setFileName('')} />
                )}
                <p style={{ fontSize: 12, marginTop: 5, marginLeft: 5 }}>File name should not has any space</p>
              </div>
              <div className="skd-user-info-export-select">
                <h5>Export as:</h5>
                <div
                  className="skd-user-info-export-select-option"
                  onClick={() => {
                    setExportStatus('EAM');
                  }}
                >
                  <input
                    type="radio"
                    id="debit-note"
                    name="export-group"
                    checked={exportStatus === 'EAM' ? true : false}
                  />
                  <label
                    style={{ fontSize: 15, display: 'flex', alignItems: 'center', marginLeft: 7 }}
                    for="debit-note"
                  >
                    EAM - format (.csv)
                  </label>
                </div>

                <div
                  className="skd-user-info-export-select-option"
                  onClick={() => {
                    setExportStatus('CLE');
                  }}
                >
                  <input type="radio" id="pdf" name="export-group" checked={exportStatus === 'CLE' ? true : false} />
                  <label style={{ fontSize: 15, display: 'flex', alignItems: 'center', marginLeft: 7 }} for="pdf">
                    CLE - format (.txt)
                  </label>
                </div>
                <div
                  className="skd-user-info-export-select-option"
                  onClick={() => {
                    setExportStatus('DGP');
                  }}
                >
                  <input type="radio" id="dgp" name="export-group" checked={exportStatus === 'DGP' ? true : false} />
                  <label style={{ fontSize: 15, display: 'flex', alignItems: 'center', marginLeft: 7 }} for="dgp">
                    DGP - format (.txt)
                  </label>
                </div>
                <div
                  className="skd-user-info-export-select-option"
                  onClick={() => {
                    setExportStatus('DAU');
                  }}
                >
                  <input type="radio" id="dau" name="export-group" checked={exportStatus === 'DAU' ? true : false} />
                  <label style={{ fontSize: 15, display: 'flex', alignItems: 'center', marginLeft: 7 }} for="dau">
                    Asendia- format (.csv)
                  </label>
                </div>
                <div
                  className="skd-user-info-export-select-option"
                  onClick={() => {
                    setExportStatus('D3P');
                  }}
                >
                  <input type="radio" id="d3p" name="export-group" checked={exportStatus === 'D3P' ? true : false} />
                  <label style={{ fontSize: 15, display: 'flex', alignItems: 'center', marginLeft: 7 }} for="d3p">
                    D3P - format (.txt)
                  </label>
                </div>
                <div
                  className="skd-user-info-export-select-option"
                  onClick={() => {
                    setExportStatus('Tracking');
                  }}
                >
                  <input
                    type="radio"
                    id="tracking"
                    name="export-group"
                    checked={exportStatus === 'Tracking' ? true : false}
                  />
                  <label style={{ fontSize: 15, display: 'flex', alignItems: 'center', marginLeft: 7 }} for="tracking">
                    Tracking file (.csv)
                  </label>
                </div>
                <div
                  className="skd-user-info-export-select-option"
                  onClick={() => {
                    setExportStatus('Customizing Columns');
                  }}
                >
                  <input
                    type="radio"
                    id="customize"
                    name="export-group"
                    checked={exportStatus === 'Customizing Columns' ? true : false}
                  />
                  <label style={{ fontSize: 15, display: 'flex', alignItems: 'center', marginLeft: 7 }} for="customize">
                    Customizing Columns (.csv)
                  </label>
                </div>
              </div>
              <div className="skd-user-info-export-select">
                <h5>Export Option:</h5>
                <div
                  className="skd-user-shipment-last-header"
                  style={{ padding: 7, cursor: 'pointer' }}
                  onClick={() => {
                    includeInfo ? setIncludeInfo(false) : setIncludeInfo(true);
                  }}
                >
                  <Checkbox checked={includeInfo ? true : false} size="small" />
                  <p style={{ marginLeft: 2, fontSize: 15 }}>Ordering data (by sender's name)</p>
                </div>
              </div>
              <div className="skd-user-info-export-button">
                <div className="skd-user-info-export-button-cancel" onClick={() => setDisplayExport(false)}>
                  <h5>Cancel</h5>
                </div>
                {exportStatus === 'EAM' && (
                  <CSVLink {...debitExport} className="skd-user-info-export-button-apply2">
                    Export
                  </CSVLink>
                )}
                {exportStatus === 'DAU' && (
                  <CSVLink {...dauExport} className="skd-user-info-export-button-apply2">
                    Export
                  </CSVLink>
                )}
                {/* {exportStatus === 'D3P' && (
                  <CSVLink {...d3pExport} className="skd-user-info-export-button-apply2">
                    Export
                  </CSVLink>
                )} */}
                {exportStatus === 'D3P' && (
                  <button onClick={exportAsendia} className="skd-user-info-export-button-apply2">
                    Export
                  </button>
                )}
                {exportStatus === 'Tracking' && (
                  <CSVLink {...trackingExport} className="skd-user-info-export-button-apply2">
                    Export
                  </CSVLink>
                )}

                {exportStatus === 'DGP' && (
                  <div onClick={exportDgp} className="skd-user-info-export-button-apply2">
                    Export
                  </div>
                )}
                {exportStatus === 'CLE' && (
                  <div onClick={exportText} className="skd-user-info-export-button-apply2">
                    Export
                  </div>
                )}
                {exportStatus === 'Customizing Columns' && (
                  <CSVLink {...customizeExport} className="skd-user-info-export-button-apply2">
                    Export
                  </CSVLink>
                )}
                {/* {exportStatus === "Raw" && <button type="button" className="skd-user-info-export-button-apply" onClick={handleExportExcel}>Export</button>}
                            {exportStatus === "Debit Note" && <div onClick={() => { setDisplayExport(false); setExportStatus("Raw"); setGetExcel([]); setFileName("") }}>
                                <ReactToExcel className="skd-user-info-export-button-apply1" table="export-table" filename={excelFileName || `${exporterName} debit from ${startDay} to ${endDay}`} sheet="sheet" buttonText="Export" />
                            </div>}
                            {exportStatus === "CSV" && <CSVLink {...debitExport} className="skd-user-info-export-button-apply2">Export</CSVLink>}
                            {exportStatus === "PDF" && <button type="button" className="skd-user-info-export-button-apply" onClick={generatePDF}>Export</button>} */}
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      )}
      {displayPickupConfirm && (
        <div className="fixed top-0 right-0 left-0 bottom-0 bg-[rgba(0,0,0,0.55)] flex items-center justify-center z-[9999]">
          <OutsideClickHandler
            onOutsideClick={() => {
              onPickupConfirm();
            }}
          >
            <div className="w-[500px] max-h-[400px] bg-white rounded-[7px] overflow-y-auto flex flex-col relative">
              {loading && (
                <div className="absolute top-0 left-0 right-0 bottom-0 z-[9999] bg-[rgba(255,255,255,0.75)] flex items-center justify-center">
                  <CircularProgress size={32} />
                  <p className="font-semibold tracking-wide ml-2">Picking up, please wait for a few seconds</p>
                </div>
              )}
              <div
                className="p-2 flex items-center justify-between sticky bg-white z-10 top-0"
                style={{ borderBottom: '1px solid rgb(220,220,220)' }}
              >
                <p className="font-semibold text-lg">Are you sure you want to pick up the selected list ?</p>
                <div
                  className="w-[35px] h-[35px] flex items-center justify-center rounded-full bg-slate-100 hover:bg-slate-200 active:bg-slate-300 active:scale-90 cursor-pointer"
                  onClick={onPickupConfirm}
                >
                  <MdOutlineClear size={27} />
                </div>
              </div>
              <div className="p-2">
                {selected?.map((item) => (
                  <div
                    className="p-2 flex items-center cursor-pointer rounded-[10px] hover:bg-slate-100 active:bg-slate-200"
                    key={item.hawb}
                  >
                    {item.type === 'WPX' && <GoPackage size={25} />}
                    {item.type === 'DOX' && <IoDocument size={25} />}
                    <p className="text-sky-700 font-semibold tracking-wide ml-1.5">{item.hawb}</p>
                    <p className="ml-auto mr-1 font-semibold">{item.chargeable} KG</p>
                  </div>
                ))}
              </div>
              <div className="p-2 sticky bottom-0 bg-white z-10 shadow-[rgba(100,100,111,0.2)_0px_7px_29px_0px] mt-auto flex items-center">
                <button
                  type="button"
                  className="m-1 p-1.5 bg-slate-200 font-semibold px-2 rounded-[12px] hover:bg-slate-300 active:bg-slate-400 select-none ml-auto"
                  onClick={onPickupConfirm}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-1 p-1.5 bg-sky-600 text-white font-semibold px-2 rounded-[12px] hover:bg-sky-700 active:bg-sky-800 select-none"
                  onClick={onPickupShipment}
                >
                  Pick up shipment
                </button>
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      )}
      <OutsideClickHandler
        onOutsideClick={() => {
          onCloseSearch();
        }}
      >
        <div className="flex items-center relative">
          <IoIosSearch size={27} className="absolute left-1 text-gray-500" />
          <input
            type="text"
            placeholder="Search shipments"
            value={searchInput}
            onFocus={() => onSearchFocus()}
            onChange={(e) => handleSearchShipment(e)}
            className="p-[5px] px-[36px] w-[283px] border-solid border-slate-400 border-[1px] rounded-[10px] newBooking-search"
          />
          {searchInput !== '' && (
            <div
              className="absolute right-1.5 mt-[-12px] rounded-full hover:bg-slate-100 active:bg-slate-200 flex items-center justify-center w-[28px] h-[28px] top-[15px] cursor-pointer"
              onClick={() => resetSearch()}
            >
              <MdOutlineClear size={27} />
            </div>
          )}
          {displaySearchOption && searchInput === '' && (
            <div className="absolute bg-white top-10 w-full shadow-[rgba(50,50,93,0.25)_0px_13px_27px_-5px,rgba(0,0,0,0.3)_0px_8px_16px_-8px] z-[9999] max-h-[400px] overflow-auto rounded-[7px]">
              <div className="p-2" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
                <TextareaAutosize
                  placeholder="Enter HAWB List"
                  className="w-full resize-none p-2 mt-1 bg-slate-200 outline-none rounded-[7px] text-[15px]"
                  value={searchMultipleShipment}
                  onChange={(e) => setSearchMultipleShipment(e.target.value)}
                />
              </div>
              <p className="p-2" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
                Separate shipments by hitting "Enter" to break the line
              </p>
              <div
                className="p-1.5 sticky bottom-0 z-10 bg-sky-600 text-white flex items-center justify-center text-[17px] font-semibold hover:opacity-70 active:opacity-50 select-none cursor-pointer"
                style={{ borderBottomLeftRadius: 7, borderBottomRightRadius: 7 }}
                onClick={() => {
                  fetchMultipleSearched(trackArray);
                  setSearchMultipleShipment('');
                }}
              >
                <p>Search</p>
              </div>
            </div>
          )}
        </div>
      </OutsideClickHandler>
      <OutsideClickHandler
        onOutsideClick={() => {
          setDisplayColumn(false);
        }}
      >
        <div className="relative">
          <div
            className="flex items-center cursor-pointer select-none p-1.5 font-semibold text-slate-600 hover:text-black border-solid border-slate-300 border-[1px] rounded-[10px] hover:bg-slate-200 active:bg-slate-300 ml-2"
            onClick={handleColumn}
          >
            <BsColumnsGap size={24} />
            <p className="tracking-wide mx-1.5">Columns</p>
            <FaSortDown size={24} className="mt-[-10px]" />
          </div>
          {displayColumn && (
            <div className="skydart-shipment-break-down">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps({})} style={{ display: 'grid' }}>
                  {headerGroup.headers.map((column, index) => (
                    <div
                      className="skydart-shipment-break-down-option cursor-pointer hover:bg-slate-200 active:bg-slate-300"
                      key={index}
                      onClick={() => {
                        handleSelectedHeader(headerGroup, column.id, column.active);
                      }}
                    >
                      <div className="w-[23px] h-[23px] rounded-full flex items-center justify-center bg-white border-solid border-slate-200 border-[1px]">
                        {column.active && <div className="w-[15px] h-[15px] bg-sky-600 rounded-full"></div>}
                      </div>
                      <label style={{ fontSize: 17, marginLeft: 7 }}>{column.render('Header')}</label>
                    </div>
                  ))}
                </div>
              ))}
              <div
                className="p-1.5 rounded-[5px] hover:bg-slate-200 active:bg-gray-300 cursor-pointer select-none text-[17px] font-semibold"
                onClick={() => {
                  handleSelectedAllHeader();
                  setDisplayColumn(false);
                }}
              >
                <p>Reset column</p>
              </div>
            </div>
          )}
        </div>
      </OutsideClickHandler>
      <OutsideClickHandler
        onOutsideClick={() => {
          closeAction();
        }}
      >
        <div className="relative">
          <div
            className={`flex items-center cursor-pointer select-none p-1.5 font-semibold text-slate-600 hover:text-black border-solid border-slate-300 border-[1px] rounded-[10px] hover:bg-slate-200 active:bg-slate-300 ml-1.5 ${
              selected?.length === 0 ? 'opacity-50' : ''
            }`}
            onClick={handleAction}
          >
            <IoCreate size={24} />
            <p className="tracking-wide mx-1.5">Action</p>
            <FaSortDown size={24} className="mt-[-10px]" />
          </div>
          {displayAction && (
            <div className="absolute bg-white top-10 w-full shadow-[rgba(50,50,93,0.25)_0px_13px_27px_-5px,rgba(0,0,0,0.3)_0px_8px_16px_-8px] z-[9999] max-h-[400px] overflow-auto rounded-[7px] left-1">
              <div
                className="p-1.5 font-semibold cursor-pointer tracking-wide bg-white hover:bg-slate-100 active:bg-slate-200 select-none flex items-center"
                onClick={onPickupConfirm}
              >
                <GiCardPickup size={20} />
                <p className="mx-1.5">Pick up</p>
              </div>
              <div
                className="p-1.5 font-semibold cursor-pointer tracking-wide bg-white hover:bg-slate-100 active:bg-slate-200 select-none flex items-center"
                onClick={onPickupConfirm}
              >
                <GrDocumentPdf size={20} />

                <p className="mx-1.5">View label</p>
              </div>
            </div>
          )}
        </div>
      </OutsideClickHandler>
      <div
        className={`flex items-center cursor-pointer select-none p-1.5 font-semibold text-slate-600 hover:text-black border-solid border-slate-300 border-[1px] rounded-[10px] hover:bg-slate-200 active:bg-slate-300 ml-1.5 ${
          selected?.length === 0 ? 'opacity-50' : ''
        }`}
        onClick={handleExport}
      >
        <FiDownload size={24} />
        <p className="tracking-wide mx-1.5">Export</p>
        <IoMdMore size={24} />
      </div>
      <div
        className={`flex items-center select-none p-1.5 font-semibold text-slate-600 hover:text-black border-solid border-slate-300 border-[1px] rounded-[10px] hover:bg-slate-200 active:bg-slate-300 ml-1.5 ${
          user.role !== 'OPS' && user.role !== 'CEO' ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
        }`}
        onClick={() => {
          if (user.role === 'OPS') {
            handleImport();
          } else if (user.role === 'CEO') {
            handleImport();
          } else {
            return;
          }
        }}
      >
        <FiUpload size={24} />
        <p className="tracking-wide mx-1.5">Upload Label</p>
        <IoMdMore size={24} />
      </div>
      <div
        className="flex items-center cursor-pointer select-none p-1.5 font-semibold text-slate-600 hover:text-black border-solid border-slate-300 border-[1px] rounded-full hover:bg-slate-200 active:bg-slate-300 ml-1.5 active:scale-90"
        onClick={() => fetchShipment()}
      >
        <FiRefreshCcw size={24} />
      </div>
      {/* <p className="ml-2  font-semibold text-[15px]">Found {finalShipmentLength} shipments</p> */}
      {/* <div className="ml-auto flex items-center">
                {bookingTypeList.map((item) => (
                    <div key={item.id} className="relative">
                        <div className="m-2 mt-1 p-1.5 bg-slate-200 rounded-[10px] font-semibold tracking-wider select-none flex items-center justify-center hover:bg-slate-300 active:scale-95 cursor-pointer mr-0">
                            <p className="mx-2">{item.label}</p>
                        </div>
                    </div>
                ))}
            </div> */}
      <div className="ml-auto flex items-center">
        <p className="text-slate-600 font-semibold">Booking status:</p>
        <OutsideClickHandler
          onOutsideClick={() => {
            handleCloseBookingStatus();
          }}
        >
          <div className="m-2 mt-1 relative mr-0">
            <div
              className="p-1.5 bg-slate-200 rounded-[10px] font-semibold tracking-wider select-none flex items-center justify-center hover:bg-slate-300 active:scale-95 cursor-pointer w-[150px]"
              onClick={() => handleBookingStatus()}
            >
              <p className="mx-2">
                {bookingType === 'true' ? 'Booked' : bookingType === 'false' ? 'Un Booked' : 'All booking'}
              </p>
              <FaSortDown size={24} className="mt-[-10px] mr-1" />
            </div>
            {displayBookingStatus && (
              <div className="absolute bg-white w-full z-[9999] shadow-[rgba(0,0,0,0.15)_1.95px_1.95px_2.6px] mt-1 rounded-[5px]">
                <div
                  className="p-1.5 m-0.5 select-none hover:bg-slate-100 active:bg-slate-200 rounded-[7px] cursor-pointer"
                  onClick={() => handleBookingType('')}
                >
                  <p>All booking</p>
                </div>
                <div
                  className="p-1.5 m-0.5 select-none hover:bg-slate-100 active:bg-slate-200 rounded-[7px] cursor-pointer"
                  onClick={() => handleBookingType('true')}
                >
                  <p>Booked</p>
                </div>
                <div
                  className="p-1.5 m-0.5 select-none hover:bg-slate-100 active:bg-slate-200 rounded-[7px] cursor-pointer"
                  onClick={() => handleBookingType('false')}
                >
                  <p>Un Booked</p>
                </div>
              </div>
            )}
          </div>
        </OutsideClickHandler>
      </div>
    </div>
  );
}
