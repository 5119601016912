import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
const firebaseConfig = {
  apiKey: 'AIzaSyAPGlzsudu5idcoOqZMKTsklE5CXir90_M',
  authDomain: 'skydart-booking-app.firebaseapp.com',
  databaseURL: 'https://skydart-booking-app-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'skydart-booking-app',
  storageBucket: 'skydart-booking-app.appspot.com',
  messagingSenderId: '454509138802',
  appId: '1:454509138802:web:f49a7da8fd60489de5c3eb',
  measurementId: 'G-SQHN9T4H5E',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const app = firebase.storage();
export { db, auth, app };
