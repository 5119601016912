import React, { useState, useEffect } from 'react';
import { FaRegFileExcel } from 'react-icons/fa6';
import { GiFiles } from 'react-icons/gi';
import { MdOutlineClear } from 'react-icons/md';
import { VscFileSymlinkDirectory } from 'react-icons/vsc';
import OutsideClickHandler from 'react-outside-click-handler';
import uploadFile from './uploadFile.png';
import * as XLSX from 'xlsx';
import { BsPersonCheckFill } from 'react-icons/bs';
import axios from 'axios';
import { db } from '../../../../server/firebase';
import { CircularProgress } from '@mui/material';
import { FcCheckmark } from 'react-icons/fc';
export default function ImportScreen({
  uploadStatus,
  fixedCss,
  handleCloseImport,
  user,
  loading,
  alertSuccess,
  updateBatchTracking,
  updateBatchReweight,
}) {
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [fileType, setFileType] = useState('');
  const [reweightResult, setReweightResult] = useState([]);
  const [trackingResult, setTrackingResult] = useState([]);
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    setFileSize(file.size);
    setFileType(file.type);
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      if (uploadStatus === 'tracking') {
        processTracking(data);
      } else {
        processData(data);
      }
    };
    reader.readAsBinaryString(file);
    e.target.value = null;
  };
  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    setReweightResult(list);
  };
  const processTracking = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    setTrackingResult(list);
  };
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const niceBytes = (x) => {
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && 1 > 0 ? 1 : 0) + ' ' + units[l];
  };

  const resetList = () => {
    setTrackingResult([]);
    setReweightResult([]);
    setFileName('');
    setFileSize('');
    setFileType('');
  };

  const startImport = () => {
    if (uploadStatus === 'tracking') {
      updateBatchTracking(trackingResult);
    } else {
      updateBatchReweight(reweightResult);
    }
  };

  return (
    <div className={fixedCss}>
      <OutsideClickHandler
        onOutsideClick={() => {
          handleCloseImport();
        }}
      >
        <div className="w-[500px] relative p-2 bg-white rounded-[10px]">
          {loading && (
            <div className="absolute left-0 right-0 top-0 bottom-0 bg-[rgba(255,255,255,0.75)] z-[9999] flex items-center justify-center rounded-[10px]">
              <div className="flex items-center justify-center flex-col">
                <CircularProgress size={30} />
                <p className="mt-3 text-[18px]">Loading</p>
              </div>
            </div>
          )}
          {alertSuccess && (
            <div className="absolute left-0 right-0 top-0 bottom-0 bg-[rgba(255,255,255,0.75)] z-[9999] flex items-center justify-center rounded-[10px]">
              <div className="flex items-center justify-center flex-col">
                <FcCheckmark size={30} />
                <p className="mt-3 text-[18px] text-emerald-600">Update succeed, please wait</p>
              </div>
            </div>
          )}
          <div className="p-2 flex items-center justify-between shadow-[rgba(99,99,99,0.2)_0px_2px_8px_0px] rounded-[10px]">
            <img src={uploadFile} alt="" loading="lazy" className="w-[38px] h-[38px] object-cover" />
            <p className="text-xl font-semibold tracking-wide">
              {uploadStatus === 'tracking' ? 'Tracing file' : 'Reweight file'}
            </p>
            <div
              className="w-[35px] h-[35px] rounded-[10px] bg-slate-100 flex items-center justify-center cursor-pointer hover:bg-slate-200 active:bg-slate-300 active:scale-90"
              onClick={() => handleCloseImport()}
            >
              <MdOutlineClear size={30} />
            </div>
          </div>
          {!fileName && (
            <div>
              <input
                type="file"
                style={{ display: 'none' }}
                id="import-screen-input"
                onChange={handleFileUpload}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <label
                htmlFor="import-screen-input"
                className="p-2 mt-3.5 bg-slate-100 px-3 rounded-[10px] m-2 font-semibold hover:bg-slate-200 active:bg-slate-300 cursor-pointer select-none text-center flex items-center justify-center"
              >
                <p>Upload file</p>
              </label>
            </div>
          )}
          {fileName && (
            <div className="p-2 mt-3.5 bg-slate-100 px-3 rounded-[10px] m-2 font-semibold hover:bg-slate-200 active:bg-slate-300 cursor-pointer select-none text-center flex items-center justify-center">
              <p>{uploadStatus === 'tracking' ? trackingResult?.length : reweightResult?.length} rows found</p>
            </div>
          )}
          {fileName && (
            <div className="m-2">
              <div className="flex items-center my-3">
                <FaRegFileExcel size={23} />
                <p className="ml-2">{fileName}</p>
              </div>
              <div className="flex items-center my-3">
                <GiFiles size={23} />
                <p className="ml-2 w-full break-words">{fileType}</p>
              </div>
              <div className="flex items-center my-3">
                <VscFileSymlinkDirectory size={23} />
                <p className="ml-2">{niceBytes(fileSize)}</p>
              </div>
              <div className="flex items-center my-3">
                <BsPersonCheckFill size={23} />

                <p className="ml-2">{user.displayName}</p>
              </div>
            </div>
          )}
          {fileName && (
            <div className="m-2 mt-4 flex items-center">
              <div
                className="p-1.5 px-3.5 bg-sky-600 hover:bg-sky-700 active:bg-sky-800 text-white rounded-[10px] font-semibold select-none cursor-pointer ml-auto mr-1.5 w-full text-center"
                onClick={startImport}
              >
                <p>
                  Update shipments file ({uploadStatus === 'tracking' ? trackingResult?.length : reweightResult?.length}
                  )
                </p>
              </div>
              <div
                className="p-1.5 px-3.5 bg-slate-100 hover:bg-slate-200 active:bg-slate-300 rounded-[10px] font-semibold select-none cursor-pointer mr-1.5 whitespace-nowrap"
                onClick={resetList}
              >
                <p>Reset list</p>
              </div>
              <div
                className="p-1.5 px-3.5 bg-rose-50 hover:bg-rose-100 active:bg-rose-200 text-rose-500 rounded-[10px] font-semibold select-none cursor-pointer"
                onClick={() => handleCloseImport()}
              >
                <p>Cancel</p>
              </div>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
}
