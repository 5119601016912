import { Checkbox } from '@mui/material';
import React from 'react';
import { FaSortDown } from 'react-icons/fa6';

function DashboardBottom({ headerGroups, totalPieces, totalValue, totalWeight }) {
  return (
    <div className="flex items-center sticky bottom-0 bg-white z-10 w-fit shadow-[rgba(0,0,0,0.15)_2.4px_2.4px_3.2px] mt-auto">
      <div
        className="sticky left-0 z-[9998] bg-white flex items-center shadow-[rgba(0,0,0,0.05)_2.4px_0px_0px]"
        style={{ border: '1px solid lightgray', borderBottom: 'none', borderRight: 'none' }}
      >
        <Checkbox
          style={{ padding: 7, margin: 1, height: 30, opacity: 0 }}
          onChange={(e) => {
            //   handleSelectedAllShipment(e);
          }}
        />
        <div className="th flex items-center justify-between text-gray-600 relative w-[50px] opacity-0">
          <p className="font-semibold elipe-text">No.</p>
          <div
            className="w-[30px] h-[30px] flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 active:scale-90 rounded-full cursor-pointer"
            //   onClick={() => handleSelectedContext(headerGroup, column.id, column.active)}
          >
            {/* {column.context ? (
                    <FaSortUp size={28} className="mt-[14px]" />
                  ) : (
                    <FaSortDown size={28} className="mt-[-10px]" />
                  )} */}
            {/* <FaSortDown size={28} className="mt-[-10px]" /> */}
          </div>
        </div>
        <div className="th flex items-center justify-between text-gray-600 relative w-[190px] opacity-0">
          <p className="font-semibold text-[darkblue] elipe-text">HAWB</p>
          <div
            className="w-[30px] h-[30px] flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 active:scale-90 rounded-full cursor-pointer"
            //   onClick={() => handleSelectedContext(headerGroup, column.id, column.active)}
          >
            {/* {column.context ? (
                    <FaSortUp size={28} className="mt-[14px]" />
                  ) : (
                    <FaSortDown size={28} className="mt-[-10px]" />
                  )} */}
            {/* <FaSortDown size={28} className="mt-[-10px]" /> */}
          </div>
        </div>
        <div className="w-[30px] h-[30px] mr-1 cursor-pointer flex items-center justify-center rounded-full"></div>
      </div>
      {headerGroups?.map((headerGroup) => (
        <div {...headerGroup.getHeaderGroupProps({})} className="tr">
          {headerGroup.headers.map((column, index) => (
            <div>
              {column.active && (
                <div
                  {...column.getHeaderProps()}
                  className="th flex items-center justify-between text-gray-600 relative"
                >
                  <p
                    style={index === 1 ? { color: 'darkblue', textAlign: 'center' } : {}}
                    className={`elipe-text ${
                      index === 1
                        ? 'font-semibold text-[darkblue]'
                        : index === 9
                          ? 'font-semibold text-emerald-500'
                          : index === 12
                            ? 'font-semibold text-rose-500'
                            : ''
                    }`}
                  >
                    {/* {column.render('Header')} */}
                    {index === 9 ? totalWeight?.toFixed(2) + ' ' + 'KG' : ''}
                    {index === 10 ? totalPieces + ' ' + 'PCS' : ''}
                    {index === 12 ? totalValue?.toFixed(2) + ' ' + 'USD' : ''}
                  </p>
                  <div className="h-[30px] flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 active:scale-90 rounded-full cursor-pointer"></div>
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
      <div
        className="bg-white sticky right-0 z-10 flex items-center shadow-[rgba(0,0,0,0.05)_2.4px_0px_0px]"
        style={{ border: '1px solid lightgray', borderBottom: 'none', borderRight: 'none' }}
      >
        <div className="w-[37px] h-[52px]  mr-1 cursor-pointer flex items-center justify-center rounded-full"></div>
      </div>
    </div>
  );
}
export default React.memo(DashboardBottom, (prevProps, nextProps) => nextProps.isColumnResizing);
