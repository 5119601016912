import React, { useState } from 'react';
import { IoIosArrowDropdown } from 'react-icons/io';
import { GoCalendar } from 'react-icons/go';
import DatepickerContext from './datepickerContext';
import { AiOutlineMinus } from 'react-icons/ai';
import { FcCheckmark } from 'react-icons/fc';
import Month1 from './Month1';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import OutsideClickHandler from 'react-outside-click-handler';
import { MdOutlineClear } from 'react-icons/md';
import CreateShipment from '../../../components/CreateShipment';
import { BiQuestionMark } from 'react-icons/bi';
export default function NewBookingHeader({
  dateStatus,
  startDay,
  endDay,
  startDate,
  endDate,
  selected,
  finalShipment,
  totalWeight,
  totalDus,
  displayDate,
  focusedDate,
  isDateFocused,
  isDateSelected,
  isDateHovered,
  isDateBlocked,
  isFirstOrLastSelectedDate,
  onDateSelect,
  onDateFocus,
  onDateHover,
  handleToday,
  handleYesterday,
  handleThisWeek,
  handleLastWeek,
  handleThisMonth,
  handleLastMonth,
  handleThisYear,
  activeMonths,
  goToPreviousMonths,
  goToNextMonths,
  firstDayOfWeek,
  dateRange,
  handleCancelDate,
  handleApplyDate,
  handleCloseDate,
  handleShowDate,
}) {
  const [displayCreate, setDisplayCreate] = useState(false);
  const handleCreateShipment = () => {
    setDisplayCreate(!displayCreate);
  };
  const handleCloseShipment = () => {
    setDisplayCreate(false);
  };

  return (
    <div className="flex items-center justify-between p-3 pt-0 pb-0 px-2">
      {displayCreate && <CreateShipment handleCloseShipment={handleCloseShipment} />}
      <div className="flex gap-x-1.5 items-center">
        <div
          className="flex items-center p-1.5 bg-sky-600 rounded-[10px] text-white font-semibold hover:bg-sky-700 active:bg-sky-800 transition-[0.1s] select-none cursor-pointer"
          onClick={handleCreateShipment}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            dataSlot="icon"
            className="w-6 h-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
          <p className="tracking-wide mx-1.5">Create shipment</p>
        </div>
        <p className="ml-1 tracking-wide text-slate-600">
          Found <strong className="text-black underline">{finalShipment?.length}</strong> shipments
        </p>
        <div className="bg-emerald-50 flex items-center p-1.5 rounded-[10px]">
          <p className="text-emerald-700">Total:</p>
          <p className="bg-emerald-600 text-white font-bold p-[1px] px-1 rounded-[7px] mx-1">{totalWeight} KG</p>
        </div>
        <div className="bg-slate-100 flex items-center p-1.5 rounded-[10px]">
          <p>DUS:</p>
          <p className="bg-black text-white font-bold p-[1px] px-1 rounded-[7px] mx-1">{totalDus} KG</p>
        </div>
      </div>
      <div className="flex items-center">
        {selected?.length !== 0 && (
          <div className="p-1.5 mr-2 bg-emerald-600 text-white rounded-full font-semibold">
            <p className="mx-1.5 text-[16px]">{selected?.length} selected</p>
          </div>
        )}
        <OutsideClickHandler
          onOutsideClick={() => {
            handleCloseDate();
          }}
        >
          <div className="relative">
            <div
              className="flex p-2 bg-slate-200 rounded-[10px] hover:bg-slate-300 active:bg-slate-400 select-none transition-[0.1s] cursor-pointer"
              onClick={() => handleShowDate()}
            >
              <GoCalendar size={24} />
              <p style={{ marginRight: 7, marginLeft: 7 }}>{dateStatus}:</p>
              <p style={{ marginRight: 7 }}>{startDay}</p>
              <AiOutlineMinus size={20} style={{ marginRight: 7 }} />
              <p style={{ marginRight: 7 }}>{endDay}</p>
              <IoIosArrowDropdown size={24} />
            </div>
            {displayDate && (
              <div className="skd-user-date" style={{ right: 0 }}>
                <DatepickerContext.Provider
                  value={{
                    focusedDate,
                    isDateFocused,
                    isDateSelected,
                    isDateHovered,
                    isDateBlocked,
                    isFirstOrLastSelectedDate,
                    onDateSelect,
                    onDateFocus,
                    onDateHover,
                  }}
                >
                  <div className="skd-user-date-content">
                    <div className="skd-user-date-left">
                      <div
                        className={
                          dateStatus === 'Today'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleToday}
                      >
                        {dateStatus === 'Today' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>Today</p>
                      </div>
                      <div
                        className={
                          dateStatus === 'Yesterday'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleYesterday}
                      >
                        {dateStatus === 'Yesterday' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>Yesterday</p>
                      </div>
                      <div
                        className={
                          dateStatus === 'This week'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleThisWeek}
                      >
                        {dateStatus === 'This week' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>This week</p>
                      </div>
                      <div
                        className={
                          dateStatus === 'Last week'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleLastWeek}
                      >
                        {dateStatus === 'Last week' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>Last week</p>
                      </div>
                      <div
                        className={
                          dateStatus === 'This month'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleThisMonth}
                      >
                        {dateStatus === 'This month' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>This Month</p>
                      </div>
                      <div
                        className={
                          dateStatus === 'Last month'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleLastMonth}
                      >
                        {dateStatus === 'Last month' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>Last Month</p>
                      </div>
                      <div
                        className={
                          dateStatus === 'This year'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleThisYear}
                      >
                        {dateStatus === 'This year' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>This Year</p>
                      </div>
                    </div>
                    <div className="skd-user-date-right">
                      <div
                        style={{
                          display: 'flex',
                          margin: '15px 0 0',
                          gridTemplateColumns: `repeat(${activeMonths.length}, 300px)`,
                          gridGap: '0 24px',
                        }}
                      >
                        <div className="date-month-back" onClick={goToPreviousMonths}>
                          <BsArrowLeft />
                        </div>
                        <div className="date-month-back1" onClick={goToNextMonths}>
                          <BsArrowRight />
                        </div>
                        {activeMonths.map((month) => (
                          <Month1
                            key={`${month.year}-${month.month}`}
                            year={month.year}
                            month={month.month}
                            firstDayOfWeek={firstDayOfWeek}
                          />
                        ))}
                      </div>
                      <div className="skd-user-date-shown">
                        <div className="skd-user-date-shown-range">
                          <h5 style={{ fontWeight: 450 }}>
                            {!dateRange.startDate ? 'Start Date' : dateRange.startDate.toDateString()}
                          </h5>
                          <AiOutlineMinus />
                          <h5 style={{ fontWeight: 450, marginLeft: 7 }}>
                            {!dateRange.endDate ? 'End Date' : dateRange.endDate.toDateString()}
                          </h5>
                          <p style={{ fontSize: 10 }}>Ho Chi Minh City time</p>
                        </div>
                        <div className="skd-user-date-update-button">
                          <div className="skd-user-date-update-cancel" onClick={handleCancelDate}>
                            <h5 style={{ color: 'black', textAlign: 'center' }}>Cancel</h5>
                          </div>
                          <div className="skd-user-date-update-succeed" onClick={handleApplyDate}>
                            <h5 style={{ color: 'white', textAlign: 'center' }}>Update</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DatepickerContext.Provider>
              </div>
            )}
          </div>
        </OutsideClickHandler>
      </div>
    </div>
  );
}
