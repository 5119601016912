import React, { useState, useEffect, useRef } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { auth } from '../server/firebase';
import { AiOutlineMail } from 'react-icons/ai';
import { RiLockPasswordLine } from 'react-icons/ri';
import { FcSupport } from 'react-icons/fc';
import { VscSignIn, VscDebugRestart } from 'react-icons/vsc';
import { CiWarning } from 'react-icons/ci';
import { Button, CircularProgress, InputAdornment, TextField } from '@mui/material';
import LoginHeader from './components/LoginHeader';

export default function LoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectLang, setSelectLang] = useState(false);
  const navigate = useNavigate();
  const signIn = () => {
    setLoading(true);
    auth
      .signInWithEmailAndPassword(email, password)
      .then((auth) => {
        navigate('/');
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  };
  const logIn = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      signIn();
    }, 1200);
  };
  function createAccount() {
    setLoading(true);
    setTimeout(function () {
      setLoading(false);
    }, 500);
    setTimeout(function () {
      navigate('/registration');
    }, 500);
  }

  function upgradeAccount() {
    setLoading(true);
    setTimeout(function () {
      setLoading(false);
    }, 500);
  }
  function forgotPassword() {
    setLoading(true);
    setTimeout(function () {
      setLoading(false);
    }, 500);
  }
  const customersType = [
    {
      type: 'Individual Account',
    },
    {
      type: 'Business Account',
    },
  ];
  const renderLang = () => {
    setSelectLang(true);
  };
  return (
    <>
      <div className="login">
        {loading && (
          <div className="loading-screen">
            <div className="loading-circle">
              <CircularProgress fontSize="small" />
            </div>
          </div>
        )}
        {error && (
          <div className="loading-screen">
            <div className="info-alert">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CiWarning size={34} style={{ color: 'red' }} />
                <div className="ml-2">
                  <p style={{ fontWeight: 600, marginLeft: '5px' }}>Invalid Sign In Information</p>
                  <p style={{ fontWeight: 600, marginLeft: '5px' }}>Wrong Email Or Password</p>
                </div>
              </div>
              <Button
                variant="contained"
                type="button"
                startIcon={<FcSupport />}
                className="retry-btn"
                onClick={() => setError(false)}
              >
                Try Again
              </Button>
            </div>
          </div>
        )}
        <div className="bg-slate-50 flex items-center justify-center  h-[calc(100vh_-_5rem)] overflow-auto">
          <form className="login__container1" onSubmit={logIn}>
            <p className="font-semibold tracking-wide">Sign in to your Skydart account</p>
            <TextField
              required
              label="Email address"
              className="bg-white w-[300px]"
              style={{ marginTop: 10 }}
              size="small"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              className="bg-white w-[300px]"
              style={{ marginTop: 10 }}
              size="small"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <RiLockPasswordLine fontSize="large" />
                  </InputAdornment>
                ),
              }}
            />
            <button
              type="submit"
              className="bg-[#246ecf] text-white font-bold w-[300px] hover:opacity-70 active:opacity-50 transition-[0.1s] m-[10px] p-[7px] text-[18px] rounded-[12px] shadow-[rgba(0,0,0,0.15)_0px_2px_8px]"
            >
              Sign In
            </button>
            <p
              className="mt-[10px] font-semibold text-slate-500 hover:text-black hover:bg-slate-300 rounded-[12px] p-2 px-4 cursor-pointer active:bg-slate-400 select-none"
              onClick={() => navigate('/registration')}
            >
              Create an account
            </p>
          </form>
        </div>
      </div>
      <div className="login-phone">
        <div className="login-phone-container">
          <div className="login-phone-header">
            <img src="http://skydart.com.vn/vnt_upload/weblink/logo.png" alt="skd-logo-phone" />
          </div>
          <div className="login-phone-body">
            <div className="login-phone-body-content">
              <input
                type="text"
                value={email}
                placeholder="Type in your email address / username"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="login-phone-body-content">
              <input
                type="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="login-phone-body-content">
              <button type="button" onClick={logIn}>
                Login
              </button>
              {/* <p onClick={forGotPW}>Forgot Account ?</p>
                            <h5 onClick={createAccount}>Create An Account</h5> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
