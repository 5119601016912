import React, { useEffect, useState } from 'react';
import skydartLogo from '../../skydartLogo.png';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { TbLayoutSidebarLeftCollapseFilled, TbLayoutSidebarRightCollapseFilled } from 'react-icons/tb';
import { LuGrid } from 'react-icons/lu';
import {
  IoAdd,
  IoCreateOutline,
  IoDocumentAttachOutline,
  IoHomeOutline,
  IoPricetagsOutline,
  IoSettingsOutline,
} from 'react-icons/io5';
import { PiUserCircleGearFill } from 'react-icons/pi';
import { FaQuestion, FaRegNoteSticky, FaSortDown } from 'react-icons/fa6';
import { MdMoreVert, MdOutlineClass, MdOutlineTrackChanges } from 'react-icons/md';
import { IoMdOptions } from 'react-icons/io';
import { GoPackageDependents } from 'react-icons/go';
import { VscCommentDraft } from 'react-icons/vsc';
import { FaSignOutAlt } from 'react-icons/fa';
import { db, auth } from '../../server/firebase';
import OutsideClickHandler from 'react-outside-click-handler';
export default function CustomerSidebar({ user, setCurrentSidebar }) {
  const pathName = useLocation();
  const navigate = useNavigate();
  const [displaySidebar, setDisplaySidebar] = useState(true);
  const [displayUser, setDisplayUser] = useState(false);

  useEffect(() => {
    setCurrentSidebar(displaySidebar);
  }, [displaySidebar]);
  const menuArray = [
    { label: 'Home', path: '/', icon: <IoHomeOutline size={30} className="p-1" /> },
    { label: 'Create shipments', path: '/create', icon: <IoCreateOutline size={30} className="p-1" /> },
    {
      label: 'Manage shipments',
      path: '/shipments',
      icon: <LuGrid size={30} className="p-1" />,
    },
    {
      label: 'Draft bookings',
      path: '/drafts',
      icon: <VscCommentDraft size={30} className="p-1" />,
    },
    {
      label: 'Labels',
      path: '/labels',
      icon: <MdOutlineClass size={30} className="p-1" />,
    },
    {
      label: 'Track shipments',
      path: '/tracking',
      icon: <MdOutlineTrackChanges size={30} className="p-1" />,
    },

    // {
    //   label: 'Get quotes',
    //   path: '/shipping-pricing',
    //   icon: <IoPricetagsOutline size={30} className="p-1" />,
    // },
    // {
    //   label: 'Docs & Use cases',
    //   path: '/docs',
    //   icon: <IoDocumentAttachOutline size={30} className="p-1" />,
    // },
  ];

  const currentPath = pathName.pathname;
  const inActive =
    'flex items-center p-[7px] rounded-[7px] border-slate-300 hover:bg-slate-100 select-none active:bg-slate-200 cursor-pointer my-0.5 transition-[0.1s] group';
  const active =
    'flex items-center p-[7px] rounded-[7px] border-slate-300 select-none cursor-pointer my-0.5 shadow-[rgba(0,0,0,0.1)_0px_3px_12px] transition-[0.1s] group';
  return (
    <>
      {!displaySidebar && (
        <div
          className={` bg-white h-[100vh] shadow-[rgba(0,0,0,0.025)_1.95px_1.95px_2.6px] flex flex-col overflow-y-auto`}
        >
          <div
            className="p-2 px-[9px] flex items-center sticky top-0 bg-white z-10"
            style={{ borderBottom: '1px solid rgb(230,230,230)' }}
          >
            <img src={skydartLogo} alt="" loading="lazy" className="w-[40px] h-[40px] rounded-full object-contain" />
          </div>
          <div className="flex items-center justify-center group">
            <div className="absolute left-[65px] bg-white z-[9999] mt-1 p-1 text-[14px] px-2.5 rounded-[7px] shadow-[rgba(17,17,26,0.05)_0px_4px_16px,rgba(17,17,26,0.05)_0px_8px_32px] group-hover:block hidden">
              <p>Expand sidebar</p>
            </div>
            <div
              className="flex items-center justify-center w-[35px] h-[35px] rounded-[10px] bg-slate-100 hover:bg-slate-200 active:bg-slate-300 active:scale-90 cursor-pointer mt-1.5"
              onClick={() => {
                setDisplaySidebar(!displaySidebar);
              }}
            >
              <TbLayoutSidebarRightCollapseFilled size={25} />
            </div>
          </div>
          <div className="p-2 flex flex-col">
            <OutsideClickHandler
              onOutsideClick={() => {
                setDisplayUser(false);
              }}
            >
              <div className="relative p-[7px]">
                <PiUserCircleGearFill size={30} className="p-1 rounded-full bg-emerald-600 text-white" />
              </div>
            </OutsideClickHandler>
            {menuArray.map((item) => (
              <NavLink exact to={item.path} className={currentPath === item.path ? active : inActive}>
                <div
                  className={
                    currentPath === item.path
                      ? 'bg-gradient-to-r from-emerald-500 via-blue-800 to-emerald-600 rounded-full text-white'
                      : ''
                  }
                >
                  {item.icon}
                </div>
                <div className="absolute left-[65px] bg-white z-[9999] mt-1 p-1 text-[14px] px-2.5 rounded-[7px] shadow-[rgba(17,17,26,0.05)_0px_4px_16px,rgba(17,17,26,0.05)_0px_8px_32px] group-hover:block hidden ">
                  <p>{item.label}</p>
                </div>
              </NavLink>
            ))}
          </div>
          <div className="p-2 mt-auto" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
            {/* <div className="flex items-center p-[7px] rounded-[7px] border-slate-300 bg-amberr-50 bg-amber-50 hover:bg-amber-100 select-none active:bg-amber-200 cursor-pointer my-1">
            <FaQuestion size={30} className="p-1" />

            <p className="ml-3">Services support</p>
          </div> */}
            <NavLink exact to="/settings" className={currentPath === '/settings' ? active : inActive}>
              <div
                className={
                  currentPath === '/settings'
                    ? 'bg-gradient-to-r from-emerald-500 via-blue-800 to-emerald-600 rounded-full text-white'
                    : ''
                }
              >
                <IoSettingsOutline size={30} className="p-1" />
                <div className="absolute left-[65px] bg-white z-[9999] mt-[-30px] p-1 text-[14px] px-2.5 rounded-[7px] shadow-[rgba(17,17,26,0.05)_0px_4px_16px,rgba(17,17,26,0.05)_0px_8px_32px] group-hover:block hidden ">
                  <p style={{ fontWeight: 500, color: 'black' }}>Setting</p>
                </div>
              </div>
            </NavLink>
            <div
              className="flex items-center p-[7px] rounded-[7px] border-slate-300  hover:bg-rose-100 select-none active:bg-rose-200 cursor-pointer my-1 text-rose-600 font-semibold group"
              onClick={() => {
                auth.signOut();
                navigate('/');
              }}
            >
              <FaSignOutAlt size={30} className="p-1" />
              <div className="absolute left-[65px] bg-white z-[9999] mt-1 p-1 text-[14px] px-2.5 rounded-[7px] shadow-[rgba(17,17,26,0.05)_0px_4px_16px,rgba(17,17,26,0.05)_0px_8px_32px] group-hover:block hidden ">
                <p style={{ fontWeight: 500, color: 'black' }}>Sign out</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {displaySidebar && (
        <div
          className={`w-[400px] bg-white h-[100vh] shadow-[rgba(0,0,0,0.025)_1.95px_1.95px_2.6px] flex flex-col overflow-y-auto`}
        >
          <div
            className="p-2 px-[9px] flex items-center sticky top-0 bg-white z-10"
            style={{ borderBottom: '1px solid rgb(230,230,230)' }}
          >
            <img src={skydartLogo} alt="" loading="lazy" className="w-[40px] h-[40px] rounded-full object-contain" />
            <p className="text-xl ml-1.5 font-semibold text-[darkblue] tracking-wide">SKYDART EXPRESS</p>
            <div
              className="flex items-center justify-center w-[35px] h-[35px] rounded-[10px] bg-slate-100 hover:bg-slate-200 active:bg-slate-300 active:scale-90 cursor-pointer ml-auto"
              onClick={() => {
                setDisplaySidebar(!displaySidebar);
              }}
            >
              <TbLayoutSidebarLeftCollapseFilled size={25} />
            </div>
          </div>
          <div className="p-2 flex flex-col">
            <p className="text-[23px] font-semibold tracking-wide mx-1.5 mb-2">Packages booking tool</p>
            <OutsideClickHandler
              onOutsideClick={() => {
                setDisplayUser(false);
              }}
            >
              <div className="relative">
                <div
                  className={`flex items-center p-[7px] rounded-[7px]  hover:bg-slate-100 select-none active:bg-slate-200 cursor-pointer my-1.5 border-slate-300 border-solid border-[1px] ${
                    displayUser ? 'search-column-input1' : ''
                  }`}
                  onClick={() => setDisplayUser(!displayUser)}
                >
                  <PiUserCircleGearFill size={30} className="p-1 rounded-full bg-emerald-600 text-white" />

                  <p className="ml-3 font-semibold">{user.exporter}</p>
                  <FaSortDown size={30} className="mt-[-12px] ml-auto" />
                </div>
                {displayUser && (
                  <div className="absolute mt-1.5 bg-white rounded-[10px] w-full shadow-[rgba(50,50,105,0.25)_0px_2px_5px_0px,rgba(0,0,0,0.25)_0px_1px_1px_0px]">
                    <div
                      className="p-1.5 px-3 select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300 rounded-[10px] m-1"
                      onClick={() => navigate('/settings')}
                    >
                      <p>Setting account</p>
                    </div>
                    <div
                      className="p-1.5 px-3 select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300 rounded-[10px] m-1"
                      onClick={() => {
                        auth.signOut().then(() => {
                          window.open('/', 'self');
                        });
                      }}
                    >
                      <p>Sign out</p>
                    </div>
                  </div>
                )}
              </div>
            </OutsideClickHandler>
            {menuArray.map((item) => (
              <NavLink exact to={item.path} className={currentPath === item.path ? active : inActive}>
                <div
                  className={
                    currentPath === item.path
                      ? 'bg-gradient-to-r from-emerald-500 via-blue-800 to-emerald-600 rounded-full text-white'
                      : ''
                  }
                >
                  {item.icon}
                </div>
                <p className={`ml-3 ${currentPath === item.path ? 'skydart-main-primary-left-header2' : ''}`}>
                  {item.label}
                </p>
              </NavLink>
            ))}
          </div>
          <div className="p-2 mt-auto" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
            {/* <div className="flex items-center p-[7px] rounded-[7px] border-slate-300 bg-amberr-50 bg-amber-50 hover:bg-amber-100 select-none active:bg-amber-200 cursor-pointer my-1">
            <FaQuestion size={30} className="p-1" />

            <p className="ml-3">Services support</p>
          </div> */}
            <NavLink exact to="/settings" className={currentPath === '/settings' ? active : inActive}>
              <div
                className={
                  currentPath === '/settings'
                    ? 'bg-gradient-to-r from-emerald-500 via-blue-800 to-emerald-600 rounded-full text-white'
                    : ''
                }
              >
                <IoSettingsOutline size={30} className="p-1" />
              </div>
              <p className={`ml-3 ${currentPath === '/settings' ? 'skydart-main-primary-left-header2' : ''}`}>
                Setting account
              </p>
            </NavLink>
            <div
              className="flex items-center p-[7px] rounded-[7px] border-slate-300  hover:bg-rose-100 select-none active:bg-rose-200 cursor-pointer my-1 text-rose-600 font-semibold"
              onClick={() => {
                auth.signOut();
                navigate('/');
              }}
            >
              <FaSignOutAlt size={30} className="p-1" />

              <p className="ml-3">Sign out</p>
            </div>
            <p className="text-slate-600 text-[15px] p-1" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
              Skydart Express © 2024
            </p>
          </div>
        </div>
      )}
    </>
  );
}
