import { CircularProgress, TextareaAutosize } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { FaStickyNote } from 'react-icons/fa';
import { FcCheckmark, FcHighPriority } from 'react-icons/fc';
import { MdOutlineClear } from 'react-icons/md';
import OutsideClickHandler from 'react-outside-click-handler';
import { db } from '../../../../server/firebase';

export default function NoteScreen({ fixedCss, selected, handleCloseNote, user, newSingleNote, onUpdateNote }) {
  const [noteShipment, setNoteShipment] = useState([]);
  const [selectedNote, setSelectedNote] = useState([]);
  const [note, setNote] = useState('');
  useEffect(() => {
    const finalNoteShipment = selected?.map((s) => {
      return {
        hawb: s.hawb,
        noteContent: '',
      };
    });
    setNoteShipment(finalNoteShipment);
  }, []);
  const handleChangeNote = (i, e) => {
    let newFormValues = [...noteShipment];
    newFormValues[i][e.target.name] = e.target.value;
    setNoteShipment(newFormValues);
  };

  const updateSingleNote = (noteHawb) => {
    setSelectedNote(
      noteShipment.map((item) => {
        if (item.hawb === noteHawb) {
          item.loading = true;
          if (user.role === 'Finance') {
            axios
              .put('https://test.skydart.com.vn/api/accountant/note', {
                hawb: noteHawb,
                accountNote: item.noteContent,
                accountNoteBy: user.displayName,
              })
              .then(() => {
                setSelectedNote(
                  noteShipment.map((sd) => {
                    if (sd.hawb === noteHawb) {
                      sd.loading = false;
                      sd.success = 'true';
                    }
                  })
                );
                newSingleNote(noteHawb, item.noteContent);
              })
              .catch((err) => {
                setSelectedNote(
                  noteShipment.map((sd) => {
                    if (sd.hawb === noteHawb) {
                      sd.loading = false;
                      sd.success = 'false';
                      sd.errContent = err;
                    }
                  })
                );
              });
          } else if (user.role === 'OPS') {
            axios
              .put('https://test.skydart.com.vn/api/update/note', {
                hawb: noteHawb,
                note: item.noteContent,
                opsNoteBy: user.displayName,
              })
              .then(() => {
                setSelectedNote(
                  noteShipment.map((sd) => {
                    if (sd.hawb === noteHawb) {
                      sd.loading = false;
                      sd.success = 'true';
                    }
                  })
                );
                newSingleNote(noteHawb, item.noteContent);
              })
              .catch((err) => {
                setSelectedNote(
                  noteShipment.map((sd) => {
                    if (sd.hawb === noteHawb) {
                      sd.loading = false;
                      sd.success = 'false';
                      sd.errContent = err;
                    }
                  })
                );
              });
          } else if (user.role === 'Courier') {
            axios
              .put('https://test.skydart.com.vn/api/courier/note', {
                hawb: noteHawb,
                gnNote: item.noteContent,
                // gnNoteBy: user.displayName,
              })
              .then(() => {
                setSelectedNote(
                  noteShipment.map((sd) => {
                    if (sd.hawb === noteHawb) {
                      sd.loading = false;
                      sd.success = 'true';
                    }
                  })
                );
                newSingleNote(noteHawb, item.noteContent);
              })
              .catch((err) => {
                setSelectedNote(
                  noteShipment.map((sd) => {
                    if (sd.hawb === noteHawb) {
                      sd.loading = false;
                      sd.success = 'false';
                      sd.errContent = err;
                    }
                  })
                );
              });
          } else if (user.role === 'Courier Manager') {
            axios
              .put('https://test.skydart.com.vn/api/courier/note', {
                hawb: noteHawb,
                gnNote: item.noteContent,
                // gnNoteBy: user.displayName,
              })
              .then(() => {
                setSelectedNote(
                  noteShipment.map((sd) => {
                    if (sd.hawb === noteHawb) {
                      sd.loading = false;
                      sd.success = 'true';
                    }
                  })
                );
                newSingleNote(noteHawb, item.noteContent);
              })
              .catch((err) => {
                setSelectedNote(
                  noteShipment.map((sd) => {
                    if (sd.hawb === noteHawb) {
                      sd.loading = false;
                      sd.success = 'false';
                      sd.errContent = err;
                    }
                  })
                );
              });
          } else if (user.role === 'CEO') {
            axios
              .put('https://test.skydart.com.vn/api/update/note', {
                hawb: noteHawb,
                note: item.noteContent,
                opsNoteBy: user.displayName,
              })
              .then(() => {
                setSelectedNote(
                  noteShipment.map((sd) => {
                    if (sd.hawb === noteHawb) {
                      sd.loading = false;
                      sd.success = 'true';
                    }
                  })
                );
                newSingleNote(noteHawb, item.noteContent);
              })
              .catch((err) => {
                setSelectedNote(
                  noteShipment.map((sd) => {
                    if (sd.hawb === noteHawb) {
                      sd.loading = false;
                      sd.success = 'false';
                      sd.errContent = err;
                    }
                  })
                );
              });
          } else {
            alert('You do not have permission to note on this window, please contact IT to learn more');
            item.loading = false;
          }
        }
      })
    );
  };

  return (
    <div className={fixedCss}>
      <OutsideClickHandler
        onOutsideClick={() => {
          handleCloseNote();
        }}
      >
        <div className="w-[600px] max-h-[550px] overflow-y-auto rounded-[10px] bg-white">
          <div className="px-3 p-2 flex items-center bg-white z-10 sticky top-0 shadow-[rgba(60,64,67,0.05)_0px_1px_2px_0px,rgba(60,64,67,0.05)_0px_2px_6px_2px]">
            <p className="text-xl font-semibold">Note shipment</p>
            <div
              className="w-[35px] h-[35px] rounded-[10px] flex items-center justify-center bg-slate-100 hover:bg-slate-200 active:bg-slate-300 active:scale-90 cursor-pointer ml-auto"
              onClick={() => handleCloseNote()}
            >
              <MdOutlineClear size={28} />
            </div>
          </div>
          <div className="p-3 pt-0 flex">
            <div className="flex flex-col">
              {noteShipment.map((item, index) => (
                <div className="my-1">
                  <div className="flex items-center">
                    <p className="p-1">{item.hawb}</p>
                    <div className="w-[30px] h-[30px] rounded-full"></div>
                  </div>
                  <div className="flex items-start relative">
                    <FaStickyNote size={23} className="absolute left-2 top-2" />
                    <TextareaAutosize
                      placeholder="Enter note for this shipment"
                      name="noteContent"
                      value={item.noteContent}
                      onChange={(e) => handleChangeNote(index, e)}
                      className="w-[270px] rounded-[10px] p-1.5 bg-slate-100 outline-none border-slate-300 border-solid border-[1px] focus:bg-white resize-none px-3 pl-[35px] search-column-input"
                    />
                  </div>
                  {item.noteContent !== '' && (
                    <div
                      className="m-1 text-sky-600 font-semibold text-[15px] cursor-pointer w-fit hover:underline active:opacity-50"
                      onClick={() => updateSingleNote(item.hawb)}
                    >
                      <p>Update single note</p>
                    </div>
                  )}
                  {item.loading && (
                    <div className="m-1 my-2 flex items-center">
                      <CircularProgress size={18} />
                      <p className="text-[14px] ml-2">Loading</p>
                    </div>
                  )}
                  {item.success === 'true' && (
                    <div className="m-1 my-2 flex items-center">
                      <FcCheckmark size={30} className="p-0.5 rounded-full bg-emerald-50" />
                      <p className="text-[14px] ml-2">Success</p>
                    </div>
                  )}
                  {item.success === 'false' && (
                    <div className="m-1 my-2 flex items-center">
                      <FcHighPriority size={30} className="p-0.5 rounded-full bg-emerald-50" />
                      <p className="text-[14px] ml-2">{item?.errContent}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="ml-auto mr-1.5 mt-2 w-[250px]">
              <p className="font-semibold text-xl ml-1.5">Note all shipment</p>
              <TextareaAutosize
                placeholder="Enter a note for every shipment from the selected list"
                className="w-full mt-2 p-2 bg-slate-100 rounded-[10px] search-column-input focus:bg-white resize-none"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              <p className="font-semibold text-emerald-600 text-xl ml-1.5 mt-1">{selected?.length} selected</p>
            </div>
          </div>
          <div className="p-1 px-3 flex items-center bg-white z-10 sticky bottom-0 shadow-[rgba(60,64,67,0.05)_0px_1px_2px_0px,rgba(60,64,67,0.05)_0px_2px_6px_2px]">
            <p className="text-slate-500">
              Note person: <span className="text-black underline font-semibold">{user.displayName}</span>
            </p>
            <div className="ml-auto flex items-center gap-x-2">
              <button
                type="button"
                className="p-1.5 rounded-[7px] px-3 font-semibold bg-slate-100 hover:bg-slate-200 active:bg-slate-300"
                onClick={() => handleCloseNote()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="p-1.5 rounded-[7px] px-3 font-semibold bg-sky-600 hover:bg-sky-700 active:bg-sky-800 text-white"
                onClick={() => onUpdateNote(note)}
              >
                Update all note
              </button>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}
