import { CircularProgress, TextField } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { LuPackageSearch } from 'react-icons/lu';
import { MdOutlineClear, MdSmsFailed } from 'react-icons/md';
import { RiArrowRightUpLine } from 'react-icons/ri';
import OutsideClickHandler from 'react-outside-click-handler';
import DashboardEdit from '../../customer/components/DashboardEdit';
import { db } from '../../server/firebase';
import searchShipment from './searchShipment.png';
export default function SearchShipment({ user, displayShipment, setDisplayShipment }) {
  const [status, setStatus] = useState('');
  const [searchShipmentInput, setSearchShipmentInput] = useState('');

  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('Loading shipments...');

  const [shipmentData, setShipmentData] = useState([]);
  const url = `https://test.skydart.com.vn/getShipment/${searchShipmentInput}`;

  //   const handleClick = () => {
  //     setDisplayShipment(!displayShipment);
  //     if (status !== '') {
  //       setStatus('');
  //     }
  //   };

  //   useEffect(() => {
  //     const handleKeyPress = (event) => {
  //       if (event.key === 'Enter') {
  //         if (searchShipmentInput === '') {
  //           handleClick();
  //         } else {
  //           if (loading) return;
  //           onGoing();
  //         }
  //       }
  //     };

  //     document.addEventListener('keypress', handleKeyPress);

  //     return () => {
  //       document.removeEventListener('keypress', handleKeyPress);
  //     };
  //   }, [handleClick]);
  const onGoing = () => {
    if (searchShipmentInput === '') alert('Please enter a SKD HAWB');
    setLoading(true);
    const url = `https://test.skydart.com.vn/getShipment1/${searchShipmentInput}`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.length === 1) {
          setLoading(false);
          setStatus('exist');
          setSearchShipmentInput('');
          setShipmentData(res.data);
        } else {
          db.collection('shipments')
            .doc(searchShipmentInput)
            .get()
            .then((doc) => {
              if (doc.exists) {
                if (doc.data().status === 'Picked Up') {
                  setLoading(false);
                  setStatus('cloud');
                  setShipmentData(doc.data());
                  setSearchShipmentInput('');
                } else {
                  setLoading(false);
                  setStatus('new');
                  setShipmentData(doc.data());
                  setSearchShipmentInput('');
                }
              } else {
                setLoading(false);
                setStatus('null');
                setSearchShipmentInput('');
              }
            })
            .catch((err) => {
              setLoading(false);
              setStatus('err');
              setSearchShipmentInput('');
            });
        }
      })
      .catch((err) => {
        if (err) {
          db.collection('shipments')
            .doc(searchShipmentInput)
            .get()
            .then((doc) => {
              if (doc.exists) {
                if (doc.data().status === 'Picked Up') {
                  setLoading(false);
                  setStatus('cloud');
                  setSearchShipmentInput('');
                  setShipmentData(doc.data());
                } else {
                  setLoading(false);
                  setStatus('new');
                  setSearchShipmentInput('');
                  setShipmentData(doc.data());
                }
              } else {
                alert('Shipment does not exist');
                setLoading(false);
                setStatus('null');
                setSearchShipmentInput('');
              }
            })
            .catch((err) => {
              setLoading(false);
              setStatus('err');
              setSearchShipmentInput('');
            });
        }
      });
  };

  const handleClose = () => {
    setStatus('');
    setShipmentData([]);
  };

  return (
    <>
      <div className="fixed top-0 right-0 bottom-0 left-0 bg-[rgba(255,255,255,0.55)] flex items-center justify-center z-[9999] retriveShipment">
        {status !== '' && (
          <DashboardEdit
            handleCloseShipment={handleClose}
            shipmentData={shipmentData[0] || shipmentData}
            user={user}
            stat={status}
          />
        )}
        {status === '' && (
          <OutsideClickHandler
            onOutsideClick={() => {
              setDisplayShipment(false);
            }}
          >
            <form
              className="w-[500px] bg-white rounded-[15px] shadow-[rgba(0,0,0,0.2)_0px_3px_12px] retriveShipment1 relative "
              onSubmit={(e) => {
                e.preventDefault();
                onGoing();
              }}
            >
              <div
                className="p-3 flex items-center shadow-[rgba(0,0,0,0.05)_2.4px_2.4px_3.2px] px-5"
                style={{ borderTopRightRadius: 15, borderTopLeftRadius: 15 }}
              >
                <p className="font-semibold text-2xl  tracking-wider">Searching shipment</p>
                <div
                  className="w-[32px] h-[32px] rounded-[10px] bg-slate-100 hover:bg-slate-200 active:bg-slate-300 active:scale-90 flex items-center justify-center ml-auto cursor-pointer"
                  onClick={() => setDisplayShipment(false)}
                >
                  <MdOutlineClear size={25} />
                </div>
              </div>
              <div className="w-full flex items-center justify-center my-1 flex-col">
                <img src={searchShipment} alt="" loading="lazy" className="w-[200px] h-[200px] object-cover" />
                <div className="flex items-center">
                  <MdSmsFailed size={23} className="text-rose-600" />
                  <p className="ml-1.5">Ex: SKD1234567890</p>
                </div>
              </div>
              <div className="w-full flex my-1 relative">
                <div
                  className="p-1 bg-[darkblue] text-white
           rounded-full absolute w-[30px] h-[30px] flex items-center justify-center shadow-[rgba(0,0,0,0.25)_0px_0.0625em_0.0625em,rgba(0,0,0,0.25)_0px_0.125em_0.5em,rgba(255,255,255,0.1)_0px_0px_0px_1px_inset] left-[21px] top-[12px]"
                >
                  <LuPackageSearch size={25} />
                </div>
                <input
                  type="text"
                  required
                  placeholder="Enter shipment air way bill"
                  className="p-1.5 m-1.5 rounded-full bg-white border-slate-300 border-solid border-[1px] text-[18px] px-3.5 outline-none w-full search-column-input mx-3.5 pl-[43.5px]"
                  autoFocus
                  value={searchShipmentInput}
                  onChange={(e) => setSearchShipmentInput(e.target.value)}
                />
              </div>
              <div className="w-full flex items-center justify-center  mb-3.5 mt-3.5 pb-3.5">
                {!loading && (
                  <div
                    role="button"
                    className="p-2 bg-sky-50 text-[darkblue] font-semibold rounded-[10px] flex items-center justify-center hover:bg-sky-100 active:bg-sky-200 cursor-pointer select-none px-5"
                    onClick={onGoing}
                  >
                    <p>Go</p>
                    <RiArrowRightUpLine size={25} className="ml-1.5" />
                  </div>
                )}
                {loading && (
                  <div className="p-2 bg-sky-50 text-[darkblue] font-semibold rounded-[10px] flex items-center justify-center hover:bg-sky-100 active:bg-sky-200 select-none px-5 opacity-50 cursor-not-allowed">
                    <CircularProgress size={25} className="mr-2" style={{ color: 'darkblue' }} />
                    <p>Searching shipments...</p>
                  </div>
                )}
              </div>
            </form>
          </OutsideClickHandler>
        )}
      </div>
    </>
  );
}
