import React, { useState, useEffect, useRef } from 'react';
import { FaPrint } from 'react-icons/fa6';
import { useReactToPrint } from 'react-to-print';

export default function InvoicePerShipment({ shipments, setDisplayInvoicePerShipment }) {
  const [selectedShipment, setSelectedShipment] = useState([]);
  let invoiceRef = useRef();
  const printInvoice = useReactToPrint({
    content: () => invoiceRef.current,
  });
  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 bg-white z-[9999] h-screen overflow-auto">
      <div ref={invoiceRef}>
        {shipments.map((item, index) => (
          <div className="invoice3">
            <div className="flex items-center justify-center packing-header-hawb">
              <div className="w-[35%] h-[1px] bg-black mr-2"></div>
              <div className="flex whitespace-nowrap">
                {/* <p className="m-2 bg-amber-500 text-white px-2">Packing: 000000</p> */}
                <p className="m-2 bg-[darkblue] text-white px-2">HAWB: {item.hawb}</p>
              </div>
              <div className="w-[35%] h-[1px] bg-black ml-2"></div>
            </div>
            <div className="p-2 flex items-center font-semibold">
              <p>HẢI QUAN VIỆT NAM</p>
              <p className="ml-auto">PHỤ LỤC TỜ KHAI HÀNG HOÁ XUẤT KHẨU</p>
              <p className="font-normal text-[13px] italic ml-2">HQ/2015-PLXK</p>
            </div>
            <div className=" mt-2 border-solid border-[1px] border-black" style={{ borderBottom: 'none' }}>
              <div style={{ borderBottom: '1px solid' }} className="p-2 py-[2px]">
                <div className="flex items-center ">
                  <p className="text-[13px] w-[210px]">Chi cục Hải quan đăng ký tờ khai:</p>
                  <p className="font-semibold text-[15px] w-[25%]">TP. Hố Chí Minh</p>
                  <p className="text-[13px] w-[26%]">
                    Phụ lục số: <span className="font-bold ml-2 text-[15px]">01</span>
                  </p>
                  <p className="text-[13px]">Số tờ khai:</p>
                </div>
                <div className="flex mt-1">
                  <p className="text-[13px] w-[210px]">Chi cục Hải quan cửa khẩu xuất:</p>
                  <p className="font-semibold w-[25%] text-[15px]" style={{ borderTop: '1px solid' }}>
                    SB. Tân Sơn Nhất
                  </p>
                  <p className="text-[13px] w-[26%]">Ngày, giờ đăng ký:</p>
                  <p className="text-[13px]">Loại hình:</p>
                  <p className="font-semibold">H21</p>
                </div>
              </div>
              <div className="flex" style={{ borderBottom: '1px solid' }}>
                <div
                  className="w-[6%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid',
                    boxSizing: 'border-box',
                  }}
                >
                  <p>Số TT</p>
                </div>
                <div
                  className="w-[80%] break-words p-1 text-[12px] px-[1px]"
                  style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                >
                  <p>15. Mô tả hàng hoá</p>
                </div>
                <div
                  className="w-[25%] break-words p-1 text-[12px] text-center px-[1px]"
                  style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                >
                  <p>16. Mã số hàng hoá</p>
                </div>
                <div
                  className="w-[19%]  p-1 text-[12px] text-center px-[1px] "
                  style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                >
                  <p>17. Xuất xứ</p>
                </div>
                <div
                  className="w-[25%]  p-1 text-[12px] text-center px-[1px]"
                  style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                >
                  <p>18. Lượng hàng</p>
                </div>
                <div
                  className="w-[19%] break-words p-1 text-[12px] text-center px-[1px]"
                  style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                >
                  <p>19. Đơn vị tính</p>
                </div>
                <div
                  className="w-[30%] break-words p-1 text-[12px] text-center px-[1px]"
                  style={{
                    boxSizing: 'border-box',
                  }}
                >
                  <p>20. Đơn giá nguyên tệ</p>
                </div>
                <div
                  className="w-[30%] break-words p-1 text-[12px] text-center px-[1px]"
                  style={{ boxSizing: 'border-box' }}
                >
                  <p>21. Trị giá nguyên tệ</p>
                </div>
              </div>
              <div className="flex packing-header-hawb" style={{ borderBottom: '1px solid' }}>
                <div
                  className="w-[6%] break-words p-1 px-[1px] text-[9px] text-center"
                  style={{
                    borderRight: '1px solid',
                    boxSizing: 'border-box',
                  }}
                >
                  <p>HAWB</p>
                </div>
                <div className="w-[80%] break-words p-1 text-[12px] px-[4px]" style={{ boxSizing: 'border-box' }}>
                  <p>{item.hawb}</p>
                </div>
                <div
                  className="w-[25%] break-words p-1 text-[12px] text-center px-[1px]"
                  style={{ boxSizing: 'border-box' }}
                ></div>
                <div
                  className="w-[19%]  p-1 text-[12px] text-center px-[1px] "
                  style={{ boxSizing: 'border-box' }}
                ></div>
                <div
                  className="w-[25%]  p-1 text-[12px] text-center px-[1px]"
                  style={{ boxSizing: 'border-box' }}
                ></div>
                <div
                  className="w-[19%] break-words p-1 text-[12px] text-center px-[1px]"
                  style={{ boxSizing: 'border-box' }}
                ></div>
                <div
                  className="w-[30%] break-words p-1 text-[12px] text-center px-[1px]"
                  style={{
                    boxSizing: 'border-box',
                  }}
                ></div>
                <div
                  className="w-[30%] break-words p-1 text-[12px] text-center px-[1px]"
                  style={{ boxSizing: 'border-box' }}
                ></div>
              </div>
              {item.shipmentType === 'WPX' && item.invoiceValue?.map((el, i) => {
                if (item.shipmentType === 'WPX') {
                  return (
                    <div className="flex" style={{ borderBottom: '1px solid' }}>
                      <div
                        className="w-[6%] break-words p-1 px-[1px] text-[12px] text-center"
                        style={{
                          borderRight: '1px solid',
                          boxSizing: 'border-box',
                        }}
                      >
                        <p>{i + 1}</p>
                      </div>
                      <div
                        className="w-[80%] break-words p-1 text-[12px] px-[1px]"
                        style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                      >
                        <p className="ml-1">
                          {/* <span>{getAlphabetLabel(i)}. </span> */}
                          {el.itemVietnamCommonity || el.itemCommonity}
                        </p>
                      </div>

                      <div
                        className="w-[25%] break-words p-1 text-[12px] text-center px-[1px]"
                        style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                      >
                        <p>{el.itemHsCode}</p>
                      </div>

                      <div
                        className="w-[19%]  p-1 text-[12px] text-center px-[1px] whitespace-nowrap"
                        style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                      >
                        <p>VN</p>
                      </div>
                      <div
                        className="w-[25%] whitespace-nowrap p-1 text-[12px] text-center px-[1px]"
                        style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                      >
                        <p>{el.itemQuantity}</p>
                      </div>
                      <div
                        className="w-[18.9%] break-words text-[12px] text-center flex items-center justify-center px-[1.5px]"
                        style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                      >
                        <p className="unit-after-edit">{el.itemUnit}</p>
                        <input
                          type="text"
                          className="w-[90%] p-1 text-center h-full outline-none"
                          value={el.itemUnit}
                          name="itemUnit"
                          onChange={(e) => {
                            setSelectedShipment(
                              shipments.map((sd7) => {
                                if (sd7.hawb === item.hawb) {
                                  return {
                                    ...sd7,
                                    invoiceValue: sd7.invoiceValue.map((element1, index) => {
                                      if (index === i) {
                                        element1.itemUnit = e.target.value;
                                      }
                                      return element1;
                                    }),
                                  };
                                }
                                return sd7;
                              })
                            );
                          }}
                        />
                      </div>
                      <div
                        className="w-[30%] break-words p-1 text-[12px] text-center px-[1px]"
                        style={{ boxSizing: 'border-box' }}
                      >
                        <p>{Number(el.itemUnitPrice).toFixed(2)}</p>
                      </div>
                      <div
                        className="w-[30%] break-words text-[12px] text-center px-[1px] flex"
                        style={{ boxSizing: 'border-box', borderLeft: '1px solid' }}
                      >
                        <div className="w-[60%] flex p-1">
                          <p className="ml-auto">{el.itemValue?.toFixed(2)}</p>
                        </div>
                        <div className="w-[40%] p-1" style={{ borderLeft: '1px solid' }}>
                          <p>USD</p>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="flex" style={{ borderBottom: '1px solid' }}>
                      <div
                        className="w-[6%] break-words p-1 px-[1px] text-[12px] text-center"
                        style={{
                          borderRight: '1px solid',
                          boxSizing: 'border-box',
                        }}
                      >
                        <p>{index + 1}</p>
                      </div>
                      <div
                        className="w-[80%] break-words p-1 text-[12px] px-[1px]"
                        style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                      >
                        <p className="ml-1">
                          <span>a. </span>
                          Chứng từ
                        </p>
                      </div>

                      <div
                        className="w-[25%] break-words p-1 text-[12px] text-center px-[1px]"
                        style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                      ></div>

                      <div
                        className="w-[19%]  p-1 text-[12px] text-center px-[1px] whitespace-nowrap"
                        style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                      >
                        <p>VN</p>
                      </div>
                      <div
                        className="w-[25%] whitespace-nowrap p-1 text-[12px] text-center px-[1px]"
                        style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                      >
                        1
                      </div>
                      <div
                        className="w-[19%] break-words text-[12px] text-center px-[1px] flex items-center justify-center"
                        style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                      >
                        DOX
                      </div>
                      <div
                        className="w-[30%] break-words p-1 text-[12px] text-center px-[1px]"
                        style={{ boxSizing: 'border-box' }}
                      >
                        {/* <p>{Number(el.itemUnitPrice).toFixed(2)}</p> */}
                        <p>#</p>
                      </div>
                      <div
                        className="w-[30%] break-words text-[12px] text-center px-[1px] flex"
                        style={{ boxSizing: 'border-box', borderLeft: '1px solid' }}
                      >
                        <div className="w-[60%] flex p-1">
                          {/* <p className="ml-auto">{el.itemValue?.toFixed(2)}</p> */}
                          <p className="ml-auto">#</p>
                        </div>
                        <div className="w-[40%] p-1" style={{ borderLeft: '1px solid' }}>
                          <p>USD</p>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              <div className="flex" style={{ borderBottom: '1px solid' }}>
                <div className="w-[6%] break-words p-1 px-[1px] text-[11px] text-center">
                  <p>#</p>
                </div>
                <div
                  className="w-[100%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center"
                  style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                >
                  <p>Hàng mới 100%</p>
                </div>
                <div
                  className="w-[200px] break-words p-1 text-[12px] text-center px-[1px]"
                  style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                >
                  Cộng
                </div>

                <div
                  className="w-[200px] break-words p-1 text-[12px] text-center px-[1px]"
                  style={{ boxSizing: 'border-box' }}
                ></div>
              </div>
              <div className="flex" style={{ borderBottom: '1px solid' }}>
                <div
                  className="w-[2.73%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid',
                    boxSizing: 'border-box',
                  }}
                >
                  <p>Số TT</p>
                </div>
                <div className="w-full">
                  <div className="flex" style={{ borderBottom: '1px solid' }}>
                    <div
                      className="w-[100%] break-words p-1 text-[12px] px-[1px] flex items-cneter justify-center"
                      style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                    >
                      <p>22. Thuế xuất nhập khẩu</p>
                    </div>
                    <div
                      className="w-[400px] break-words p-1 text-[12px] text-center px-[1px] flex items-center justify-center"
                      style={{ boxSizing: 'border-box' }}
                    >
                      <p>23. Thu khác</p>
                    </div>
                  </div>
                  <div className="flex w-[100%]">
                    <div
                      className="w-[25%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center text-center"
                      style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                    >
                      <p>Trị giá tính thuế</p>
                    </div>
                    <div
                      className="w-[59.7%] break-words p-1 text-[12px] text-center px-[1px]"
                      style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                    >
                      <p>Thuế xuất (%)</p>
                    </div>
                    <div
                      className="w-[15%]  p-1 text-[12px] text-center px-[1px] "
                      style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                    >
                      Tiền thuế
                    </div>
                    <div
                      className="w-[400px]  text-[12px] text-center px-[1px] flex"
                      style={{ boxSizing: 'border-box' }}
                    >
                      <div className="w-[90px] p-1" style={{ borderRight: '1px solid' }}>
                        <p>tính thu</p>
                      </div>
                      <div className="w-[220px] p-1">
                        <p>Tỷ lệ (%)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div
                  className="w-[2.73%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid',
                    boxSizing: 'border-box',
                  }}
                >
                  <p>1</p>
                </div>
                <div className="flex w-[100%]">
                  <div
                    className="w-[25%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center text-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  >
                    <p className="opacity-0">.</p>
                  </div>
                  <div
                    className="w-[59.7%] break-words p-1 text-[12px] text-center px-[1px]"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  ></div>
                  <div
                    className="w-[15%]  p-1 text-[12px] text-center px-[1px] "
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  ></div>
                  <div className="w-[400px] text-[12px] text-center px-[1px] flex" style={{ boxSizing: 'border-box' }}>
                    <div className="w-[90px] p-1" style={{ borderRight: '1px solid' }}></div>
                    <div className="w-[220px]">
                      <div
                        className="w-[50px] flex items-center justify-center p-1"
                        style={{ borderRight: '1px solid' }}
                      >
                        <p className="opacity-0">.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex">
                <div
                  className="w-[2.73%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid',
                    boxSizing: 'border-box',
                  }}
                >
                  <p>2</p>
                </div>
                <div className="flex w-[100%]">
                  <div
                    className="w-[25%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center text-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  >
                    <p className="opacity-0">.</p>
                  </div>
                  <div
                    className="w-[59.7%] break-words p-1 text-[12px] text-center px-[1px]"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  ></div>
                  <div
                    className="w-[15%]  p-1 text-[12px] text-center px-[1px] "
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  ></div>
                  <div className="w-[400px] text-[12px] text-center px-[1px] flex" style={{ boxSizing: 'border-box' }}>
                    <div className="w-[90px] p-1" style={{ borderRight: '1px solid' }}></div>
                    <div className="w-[220px]">
                      <div
                        className="w-[50px] flex items-center justify-center p-1"
                        style={{ borderRight: '1px solid' }}
                      >
                        <p className="opacity-0">.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div
                  className="w-[2.73%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid',
                    boxSizing: 'border-box',
                    borderBottom: '1px solid',
                  }}
                >
                  <p>3</p>
                </div>
                <div className="flex w-[100%]">
                  <div
                    className="w-[25%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center text-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box', borderBottom: '1px solid' }}
                  >
                    <p className="opacity-0">.</p>
                  </div>
                  <div
                    className="w-[59.7%] break-words p-1 text-[12px] text-center px-[1px]"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box', borderBottom: '1px solid' }}
                  ></div>
                  <div
                    className="w-[15%]  p-1 text-[12px] text-center px-[1px] "
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  ></div>
                  <div className="w-[400px] text-[12px] text-center px-[1px] flex" style={{ boxSizing: 'border-box' }}>
                    <div className="w-[90px] p-1" style={{ borderRight: '1px solid' }}></div>
                    <div className="w-[220px]">
                      <div
                        className="w-[50px] flex items-center justify-center p-1"
                        style={{ borderRight: '1px solid' }}
                      >
                        <p className="opacity-0">.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex" style={{ borderBottom: '1px solid' }}>
                <div
                  className="w-[2.73%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid transparent',
                    boxSizing: 'border-box',
                    borderBottom: '1px solid',
                  }}
                >
                  <p className="opacity-0">.</p>
                </div>
                <div className="flex w-[100%]">
                  <div
                    className="w-[25%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center text-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box', borderBottom: '1px solid' }}
                  >
                    <p className="opacity-0">.</p>
                  </div>
                  <div
                    className="w-[59.7%] break-words p-1 text-[12px] text-end px-[1px]"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box', borderBottom: '1px solid' }}
                  >
                    <p>Cộng:</p>
                  </div>
                  <div
                    className="w-[15%]  p-1 text-[12px] text-center px-[1px] "
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  ></div>
                  <div className="w-[400px] text-[12px] text-center px-[1px] flex" style={{ boxSizing: 'border-box' }}>
                    <div className="w-[90px] p-1" style={{ borderRight: '1px solid' }}></div>
                    <div className="w-[220px]">
                      <div className="w-[50px] flex items-center justify-center p-1" style={{ border: '1px solid' }}>
                        <p>Cộng:</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center p-1" style={{ borderBottom: '1px solid' }}>
                <p className="text-[12px]">25. Lượng hàng, số hiệu container</p>
              </div>
              <div className="flex" style={{ borderBottom: '1px solid' }}>
                <div
                  className="w-[2.73%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid',
                    boxSizing: 'border-box',
                  }}
                >
                  <p>Số TT</p>
                </div>

                <div className="flex w-[100%]">
                  <div
                    className="w-[40%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center text-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  >
                    <p>a. Số hiệu container</p>
                  </div>
                  <div
                    className="w-[90%] break-words p-1 text-[12px] text-center px-[1px] flex items-center justify-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  >
                    <p>b. Số lượng kiện trong container</p>
                  </div>
                  <div className="w-[250px] text-[12px] text-center px-[1px] flex" style={{ boxSizing: 'border-box' }}>
                    <div
                      className="w-[125px] p-1 flex items-center justify-center"
                      style={{ borderRight: '1px solid' }}
                    >
                      <p>Lượng hàng</p>
                    </div>
                    <div className="w-[125px] p-1 flex items-center justify-center">
                      <p>Địa điẻm đóng phí</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div
                  className="w-[2.73%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid',
                    boxSizing: 'border-box',
                  }}
                >
                  <p>1</p>
                </div>

                <div className="flex w-[100%]">
                  <div
                    className="w-[40%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center text-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  >
                    <p className="opacity-0">a. Số hiệu container</p>
                  </div>
                  <div
                    className="w-[90%] break-words p-1 text-[12px] text-center px-[1px] flex items-center justify-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  >
                    <p>Kiện</p>
                  </div>
                  <div className="w-[250px] text-[12px] text-center px-[1px] flex" style={{ boxSizing: 'border-box' }}>
                    <div
                      className="w-[125px] p-1 flex items-center justify-center"
                      style={{ borderRight: '1px solid' }}
                    ></div>
                    <div className="w-[125px] p-1 flex items-center justify-center"></div>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div
                  className="w-[2.73%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid',
                    boxSizing: 'border-box',
                  }}
                >
                  <p>2</p>
                </div>

                <div className="flex w-[100%]">
                  <div
                    className="w-[40%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center text-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  >
                    <p className="opacity-0">a. Số hiệu container</p>
                  </div>
                  <div
                    className="w-[90%] break-words p-1 text-[12px] text-center px-[1px] flex items-center justify-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  ></div>
                  <div className="w-[250px] text-[12px] text-center px-[1px] flex" style={{ boxSizing: 'border-box' }}>
                    <div
                      className="w-[125px] p-1 flex items-center justify-center"
                      style={{ borderRight: '1px solid' }}
                    ></div>
                    <div className="w-[125px] p-1 flex items-center justify-center"></div>
                  </div>
                </div>
              </div>
              <div className="flex h-[50px]" style={{ borderBottom: '1px solid' }}>
                <div
                  className="w-[2.73%] break-words p-1 px-[1px] text-[11px] text-center"
                  style={{
                    borderRight: '1px solid',
                    boxSizing: 'border-box',
                  }}
                >
                  <p>3</p>
                </div>

                <div className="flex w-[100%]">
                  <div
                    className="w-[40%] break-words p-1 text-[12px] px-[1px] flex items-center justify-center text-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  >
                    <p className="opacity-0">a. Số hiệu container</p>
                  </div>
                  <div
                    className="w-[90%] break-words p-1 text-[12px] text-center px-[1px] flex items-center justify-center"
                    style={{ borderRight: '1px solid', boxSizing: 'border-box' }}
                  ></div>
                  <div className="w-[250px] text-[12px] text-center px-[1px] flex" style={{ boxSizing: 'border-box' }}>
                    <div
                      className="w-[125px] p-1 flex items-center justify-center"
                      style={{ borderRight: '1px solid' }}
                    ></div>
                    <div className="w-[125px] p-1 flex items-center justify-center"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex">
              <div className="ml-auto w-[200px] break-words text-center mr-[50px] mt-2 text-[12px]">
                <p>
                  27. Tôi xin cam đoan, chịu trách nhiệm trước pháp luật về nội dung khai trên tờ khai <br /> Ngày tháng
                  năm 2024 <br /> (Người khai ký, ghi rõ họ tên, đóng dấu)
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="sticky bottom-0 bg-white z-10 p-2" style={{ borderTop: '1px solid' }}>
        <div className="flex items-center">
          <div
            className="p-1.5 rounded-[7px] bg-slate-200 hover:bg-slate-300 cursor-pointer px-3.5 font-semibold active:bg-slate-400 select-none"
            onClick={() => {
              setDisplayInvoicePerShipment(false);
            }}
          >
            <p>Quay trở lại</p>
          </div>
          <div
            className="p-1.5 rounded-[7px] bg-slate-200 hover:bg-slate-300 cursor-pointer px-3.5 flex items-center ml-1.5 active:bg-slate-400 select-none font-semibold"
            onClick={printInvoice}
          >
            <FaPrint size={20} />
            <p className="ml-1.5">In tờ khai</p>
          </div>
        </div>
      </div>
    </div>
  );
}
