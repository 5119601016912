import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';

export default function CustomerBill({
  type,
  exportSelected,
  description,
  invoice,
  toEmail,
  pickupBy,
  usage,
  eori,
  totalInvoiceValue,
  invoiceValue,
  weight,
  uid,
  grossWeight,
  fromPhone2,
  toPhone2,
  chargeable,
  fromEmail,
  amount,
  hawb,
  date,
  summaryGood,
  exporter,
  fromCountry,
  fromCity,
  fromState,
  fromTown,
  fromAddress,
  fromAddress2,
  fromAddress3,
  fromPostal,
  fromPhone,
  fromName,
  importer,
  toCountry,
  toCity,
  toState,
  toTown,
  toAddress,
  toAddress2,
  toAddress3,
  toPostal,
  toPhone,
  toName,
  skdAccount,
}) {
  const [itemValue, setItemValue] = useState([]);

  return (
    <>
      <div className="new-bill">
        <div className="new-hawb-bill">
          <div className="hawb-bill-content">
            <div className="hawb-bill-left">
              <div className="hawb-bill-left-header" style={{ padding: 2 }}>
                <h5 style={{ fontSize: 10 }}>From (Exporter)</h5>
              </div>
              <div className="hawb-bill-address-info" style={{ padding: 2 }}>
                <div className="hawb-bill-address-detail">
                  <h5 style={{ width: '35%', fontSize: 10 }}>Name / Company:</h5>
                  <p style={{ fontSize: 10 }}>{exporter}</p>
                </div>
                <div className="hawb-bill-address-detail">
                  <h5 style={{ fontSize: 10 }}>Address:</h5>
                  <div>
                    <p style={{ fontSize: 10 }}>{fromAddress}</p>
                    <p style={{ height: '1.5vh', fontSize: 10 }}>{fromAddress2}</p>
                    <p style={{ height: '1.5vh', fontSize: 10 }}>{fromAddress3}</p>
                  </div>
                </div>
                {fromCountry === 'Vietnam' && (
                  <div className="hawb-bill-address-detail">
                    <h5 style={{ fontSize: 10 }}>Ward:</h5>
                    <p style={{ fontSize: 10 }}>{fromState}</p>
                  </div>
                )}
                {fromCountry === 'Vietnam' && (
                  <div className="hawb-bill-address-detail">
                    <h5 style={{ fontSize: 10 }}>District:</h5>
                    <p style={{ fontSize: 10 }}>{fromTown}</p>
                  </div>
                )}
                {fromCountry === 'Vietnam' && (
                  <div className="hawb-bill-address-detail">
                    <h5 style={{ fontSize: 10 }}>City:</h5>
                    <p style={{ fontSize: 10 }}>{fromCity}</p>
                  </div>
                )}
                {fromCountry !== 'Vietnam' && (
                  <div className="hawb-bill-address-detail">
                    <h5 style={{ fontSize: 10 }}>City:</h5>
                    <p style={{ fontSize: 10 }}>{fromCity}</p>
                  </div>
                )}
                {fromCountry !== 'Vietnam' && (
                  <div className="hawb-bill-address-detail">
                    <h5 style={{ fontSize: 10 }}>Town:</h5>
                    <p style={{ fontSize: 10 }}>{fromTown}</p>
                  </div>
                )}
                {fromCountry !== 'Vietnam' && (
                  <div className="hawb-bill-address-detail">
                    <h5 style={{ fontSize: 10 }}>State:</h5>
                    <p style={{ fontSize: 10 }}>{fromState}</p>
                  </div>
                )}
                <div className="hawb-bill-address-detail">
                  <h5 style={{ fontSize: 10 }}>Postal Code:</h5>
                  <p style={{ fontSize: 10 }}>{fromPostal}</p>
                </div>
                <div className="hawb-bill-address-detail">
                  <h5 style={{ fontSize: 10 }}>Country:</h5>
                  <p style={{ fontSize: 10 }}>{fromCountry}</p>
                </div>
                <div className="hawb-bill-address-detail">
                  <h5 style={{ fontSize: 10 }}>Telephone:</h5>
                  <p style={{ fontSize: 10 }}>{fromPhone}</p>
                </div>
                <div className="hawb-bill-address-detail">
                  <h5 style={{ fontSize: 10 }}>Contact Name:</h5>
                  <p style={{ fontSize: 10 }}>{fromName}</p>
                </div>
                <div className="hawb-bill-address-detail">
                  <h5 style={{ fontSize: 10 }}>Email Address:</h5>
                  <p style={{ fontSize: 10 }}>{fromEmail}</p>
                </div>
              </div>
              <div className="hawb-bill-left-header" style={{ padding: 2 }}>
                <h5 style={{ fontSize: 10 }}>To (Importer)</h5>
              </div>
              <div className="hawb-bill-address-info" style={{ padding: 2 }}>
                <div className="hawb-bill-address-detail">
                  <h5 style={{ fontSize: 10 }}>Name / Company:</h5>
                  <p style={{ fontSize: 10 }}>{importer}</p>
                </div>
                <div className="hawb-bill-address-detail">
                  <h5 style={{ fontSize: 10 }}>Address:</h5>
                  <div>
                    <p style={{ fontSize: 10 }}>{toAddress}</p>
                    <p style={{ height: '1.5vh', fontSize: 10 }}>{toAddress2}</p>
                    <p style={{ height: '1.5vh', fontSize: 10 }}>{toAddress3}</p>
                  </div>
                </div>
                {toCountry === 'Vietnam' && (
                  <div className="hawb-bill-address-detail">
                    <h5 style={{ fontSize: 10 }}>Ward:</h5>
                    <p style={{ fontSize: 10 }}>{toState}</p>
                  </div>
                )}
                {toCountry === 'Vietnam' && (
                  <div className="hawb-bill-address-detail">
                    <h5 style={{ fontSize: 10 }}>District:</h5>
                    <p style={{ fontSize: 10 }}>{toTown}</p>
                  </div>
                )}
                {toCountry === 'Vietnam' && (
                  <div className="hawb-bill-address-detail">
                    <h5 style={{ fontSize: 10 }}>City:</h5>
                    <p style={{ fontSize: 10 }}>{toCity}</p>
                  </div>
                )}
                {toCountry !== 'Vietnam' && (
                  <div className="hawb-bill-address-detail">
                    <h5 style={{ fontSize: 10 }}>Town:</h5>
                    <p style={{ fontSize: 10 }}>{toTown}</p>
                  </div>
                )}
                {toCountry !== 'Vietnam' && (
                  <div className="hawb-bill-address-detail">
                    <h5 style={{ fontSize: 10 }}>City:</h5>
                    <p style={{ fontSize: 10 }}>{toCity}</p>
                  </div>
                )}
                {toCountry !== 'Vietnam' && (
                  <div className="hawb-bill-address-detail">
                    <h5 style={{ fontSize: 10 }}>State:</h5>
                    <p style={{ fontSize: 10 }}>{toState}</p>
                  </div>
                )}
                <div className="hawb-bill-address-detail">
                  <h5 style={{ fontSize: 10 }}>Postal Code:</h5>
                  <p style={{ fontSize: 10 }}>{toPostal}</p>
                </div>
                <div className="hawb-bill-address-detail">
                  <h5 style={{ fontSize: 10 }}>Country:</h5>
                  <p style={{ fontSize: 10 }}>{toCountry}</p>
                </div>
                <div className="hawb-bill-address-detail">
                  <h5 style={{ fontSize: 10 }}>Telephone:</h5>
                  <p style={{ fontSize: 10 }}>{toPhone}</p>
                </div>
                <div className="hawb-bill-address-detail">
                  <h5 style={{ fontSize: 10 }}>Contact Name:</h5>
                  <p style={{ fontSize: 10 }}>{toName}</p>
                </div>
                <div className="hawb-bill-address-detail">
                  <h5 style={{ fontSize: 10 }}>Email Address:</h5>
                  <p style={{ fontSize: 10 }}>{toEmail}</p>
                </div>
              </div>
            </div>
            <div className="hawb-bill-right flex flex-col">
              <div className="hawb-bill-right-primary-option" style={{ padding: 2 }}>
                <div className="hawb-bill-little-helper">
                  <div className="hawb-bill-right-logo">
                    <img src="https://i.imgur.com/L6PnhXq.png" alt="" style={{ width: 38, height: 38 }} />
                    <div className="hawb-bill-right-primary-text" style={{ marginLeft: 5 }}>
                      <h3 style={{ fontSize: 15 }}>SKYDART</h3>
                      <h3 style={{ fontSize: 15 }}>EXPRESS</h3>
                    </div>
                  </div>
                  <p className="document-parcel1">Document and parcel worldwide express</p>
                </div>
                <div className="hawb-bill-right-qrcode">
                  <QRCode
                    value={`https://skydartexpress.com/scan/${hawb}`}
                    size={50}
                    imageSettings={{ src: 'https://i.imgur.com/L6PnhXq.png', excavate: true, size: 100 }}
                    level={'H'}
                    includeMargin={true}
                  />
                </div>
              </div>
              <center>
                <h5 style={{ color: 'darkblue', fontWeight: 600, fontSize: 13 }}>https://www.skydart.com.vn</h5>
              </center>
              <div className="hawb-bill-number" style={{ borderBottom: 'none' }}>
                <div className="hawb-bill-number-left" style={{ borderBottom: '1px solid' }}>
                  <h5 style={{ marginTop: 5, marginLeft: 5, marginRight: 5, fontSize: 12 }}>Waybill</h5>
                </div>
                <div className="w-full flex items-center justify-center" style={{ padding: 5 }}>
                  <h4 style={{ fontSize: 13, fontWeight: 600 }}>{hawb}</h4>
                </div>
              </div>
              <div className="hawb-bill-package-info">
                <div className="hawb-bill-package-header">
                  <div className="hawb-bill-package-header-option">
                    <h5 style={{ fontSize: 8 }}>Total packages</h5>
                  </div>
                  <div className="hawb-bill-package-header-option">
                    <h5 style={{ fontSize: 8 }}>Gross Weight</h5>
                  </div>
                  <div className="hawb-bill-package-header-option">
                    <h5 style={{ fontSize: 8 }}>Chargeable Weight</h5>
                  </div>
                </div>
                <div className="hawb-bill-package-body">
                  <div className="hawb-bill-package-body-option" style={{ borderBottom: '1px solid' }}>
                    <h5 style={{ fontSize: 12 }}>{amount}</h5>
                  </div>
                  <div className="hawb-bill-package-body-option" style={{ borderBottom: '1px solid' }}>
                    <h5 style={{ fontSize: 12 }}>{(Math.round(weight * 100) / 100).toFixed(2)} KG</h5>
                  </div>
                  <div className="hawb-bill-package-body-option" style={{ borderBottom: '1px solid' }}>
                    <h5 style={{ fontSize: 12 }}>{(Math.round(chargeable * 100) / 100).toFixed(2)} KG</h5>
                  </div>
                </div>
              </div>
              <div className="hawb-bill-package-description" style={{ padding: 2 }}>
                <h5>Full description of goods</h5>
              </div>
              <div className="hawb-bill-items" style={{ height: 100, borderBottom: '1px solid' }}>
                {type === 'WPX' && (
                  <div className="hawb-bill-items-option">
                    {exportSelected ? (
                      <div>
                        <h5>{description}</h5>
                      </div>
                    ) : (
                      invoiceValue.map((s, index) => (
                        <div key={index}>
                          <h5 style={{ fontSize: 11 }}>{s.itemCommonity + ',' || ''}</h5>
                        </div>
                      ))
                    )}
                  </div>
                )}
                {type === 'DOX' && (
                  <div className="hawb-bill-items-option">
                    <center>
                      <h5 style={{ textAlign: 'center', fontSize: 11 }}>Document</h5>
                    </center>
                  </div>
                )}
                {type === 'ECS' && (
                  <div className="hawb-bill-items-option1">
                    {invoiceValue.map((s, index) => (
                      <div key={index}>
                        <div className="hawb-bill-ecs">
                          <h5 style={{ width: '40%' }}>Commodity:</h5>
                          <h5>{s.itemCommonity}</h5>
                        </div>
                        <div className="hawb-bill-ecs">
                          <h5 style={{ width: '40%' }}>Quantity:</h5>
                          <h5>
                            {s.itemQuantity} {s.itemUnit}
                          </h5>
                        </div>
                        <div className="hawb-bill-ecs">
                          <h5 style={{ width: '40%' }}>Unit Price:</h5>
                          <h5>{(Math.round(s.itemUnitPrice * 100) / 100).toFixed(2)} USD</h5>
                        </div>
                        <div className="hawb-bill-ecs">
                          <h5 style={{ width: '40%' }}>Amount:</h5>
                          <h5>{(Math.round(totalInvoiceValue * 100) / 100).toFixed(2)} USD</h5>
                        </div>
                        <div className="hawb-bill-ecs">
                          <h5>{usage}</h5>
                        </div>
                        <div className="hawb-bill-ecs">
                          <h5>Consignee IOSS / TIN / EORI NUMBER / ID Tax Number: </h5>
                        </div>
                        <div className="hawb-bill-ecs">
                          <h5>{eori}</h5>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span style={{ padding: 5, fontSize: 12, fontWeight: 600 }}>Create date: {date}</span>
                <div>
                  <h1 style={{ padding: 10, fontSize: 27, fontWeight: 700, background: 'rgb(140, 186, 255)' }}>
                    {type}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="hawb-bill-footer">
                        <div className="hawb-bill-footer-left">
                            <div>
                                <div className="hawb-bill-footer-left-option">
                                    <h5 style={{fontSize: 10}}>Shipper's Account</h5>
                                    <p style={{ fontSize: 9 }}>{fromEmail}</p>
                                </div>
                                <div className="hawb-bill-footer-pay-option">
                                    <h5>Credit</h5>
                                    <input type="checkbox" />
                                </div>
                                <div className="hawb-bill-footer-pay-option">
                                    <h5>Prepaid</h5>
                                    <input type="checkbox" />
                                </div>
                            </div>
                            <div className="hawb-bill-footer-agreement-option" >
                                <h5 style={{ fontSize: 12 }}>Shipper's Agreement And Signature</h5>
                            </div>
                        </div>
                        <div className="hawb-bill-footer-right">
                            <div>
                                <div className="hawb-bill-footer-right-option">
                                    <h5>Picked up by: {pickupBy}</h5>
                                </div>
                                <div className="hawb-bill-footer-shipment-date" style={{ border: "none", marginLeft: -1, borderLeft: "1px solid", background: "white" }}>
                                    <h5>Shipment Date:  {date}</h5>
                                </div>
                            </div>
                            <div className="hawb-bill-footer-right-shipment-type" style={{ borderTop: "1px solid" }}>
                                <center><h1 style={{ fontSize: 25 }}>{type}</h1></center>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </>
  );
}
