import React, { useState, useEffect } from 'react';
import { FcCheckmark } from 'react-icons/fc';
import { AiOutlineMail } from 'react-icons/ai';
import { RiLockPasswordLine } from 'react-icons/ri';
import address1 from './address1.png';

import { useNavigate } from 'react-router-dom';
import { auth, db } from '../server/firebase';
import validator from 'validator';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import { Button, Checkbox, CircularProgress, InputAdornment, TextField } from '@mui/material';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import LoginHeader from './components/LoginHeader';
export default function Registration() {
  const countries = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    {
      code: 'AE',
      label: 'United Arab Emirates',
      phone: '971',
    },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    {
      code: 'AG',
      label: 'Antigua and Barbuda',
      phone: '1-268',
    },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    {
      code: 'AU',
      label: 'Australia',
      phone: '61',
      suggested: true,
    },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    {
      code: 'BA',
      label: 'Bosnia and Herzegovina',
      phone: '387',
    },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    {
      code: 'CA',
      label: 'Canada',
      phone: '1',
      suggested: true,
    },
    {
      code: 'CC',
      label: 'Cocos (Keeling) Islands',
      phone: '61',
    },
    {
      code: 'CD',
      label: 'Congo, Democratic Republic of the',
      phone: '243',
    },
    {
      code: 'CF',
      label: 'Central African Republic',
      phone: '236',
    },
    {
      code: 'CG',
      label: 'Congo, Republic of the',
      phone: '242',
    },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    {
      code: 'DE',
      label: 'Germany',
      phone: '49',
      suggested: true,
    },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    {
      code: 'DO',
      label: 'Dominican Republic',
      phone: '1-809',
    },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    {
      code: 'FK',
      label: 'Falkland Islands (Malvinas)',
      phone: '500',
    },
    {
      code: 'FM',
      label: 'Micronesia, Federated States of',
      phone: '691',
    },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    {
      code: 'FR',
      label: 'France',
      phone: '33',
      suggested: true,
    },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    {
      code: 'GS',
      label: 'South Georgia and the South Sandwich Islands',
      phone: '500',
    },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    {
      code: 'HM',
      label: 'Heard Island and McDonald Islands',
      phone: '672',
    },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    {
      code: 'IO',
      label: 'British Indian Ocean Territory',
      phone: '246',
    },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    {
      code: 'IR',
      label: 'Iran, Islamic Republic of',
      phone: '98',
    },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    {
      code: 'JP',
      label: 'Japan',
      phone: '81',
      suggested: true,
    },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    {
      code: 'KN',
      label: 'Saint Kitts and Nevis',
      phone: '1-869',
    },
    {
      code: 'KP',
      label: "Korea, Democratic People's Republic of",
      phone: '850',
    },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    {
      code: 'LA',
      label: "Lao People's Democratic Republic",
      phone: '856',
    },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    {
      code: 'MD',
      label: 'Moldova, Republic of',
      phone: '373',
    },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    {
      code: 'MF',
      label: 'Saint Martin (French part)',
      phone: '590',
    },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    {
      code: 'MK',
      label: 'Macedonia, the Former Yugoslav Republic of',
      phone: '389',
    },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    {
      code: 'MP',
      label: 'Northern Mariana Islands',
      phone: '1-670',
    },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    {
      code: 'PM',
      label: 'Saint Pierre and Miquelon',
      phone: '508',
    },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    {
      code: 'PS',
      label: 'Palestine, State of',
      phone: '970',
    },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    {
      code: 'SJ',
      label: 'Svalbard and Jan Mayen',
      phone: '47',
    },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    {
      code: 'ST',
      label: 'Sao Tome and Principe',
      phone: '239',
    },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    {
      code: 'SX',
      label: 'Sint Maarten (Dutch part)',
      phone: '1-721',
    },
    {
      code: 'SY',
      label: 'Syrian Arab Republic',
      phone: '963',
    },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    {
      code: 'TC',
      label: 'Turks and Caicos Islands',
      phone: '1-649',
    },
    { code: 'TD', label: 'Chad', phone: '235' },
    {
      code: 'TF',
      label: 'French Southern Territories',
      phone: '262',
    },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    {
      code: 'TT',
      label: 'Trinidad and Tobago',
      phone: '1-868',
    },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    {
      code: 'TW',
      label: 'Taiwan, Province of China',
      phone: '886',
    },
    {
      code: 'TZ',
      label: 'United Republic of Tanzania',
      phone: '255',
    },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    {
      code: 'US',
      label: 'United States',
      phone: '1',
      suggested: true,
    },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    {
      code: 'VA',
      label: 'Holy See (Vatican City State)',
      phone: '379',
    },
    {
      code: 'VC',
      label: 'Saint Vincent and the Grenadines',
      phone: '1-784',
    },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    {
      code: 'VG',
      label: 'British Virgin Islands',
      phone: '1-284',
    },
    {
      code: 'VI',
      label: 'US Virgin Islands',
      phone: '1-340',
    },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
  ];
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState(false);
  const [finishSignUp, setFinishSignUp] = useState(false);
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [ids, setIds] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passConfirm, setPassConfirm] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [nameError, setNameError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passConfirmError, setPassConfirmError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [nameExist, setNameExist] = useState(false);
  const [emailAddressExist, setEmailAddressExist] = useState(false);
  const [streetError, setStreetError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [postalError, setPostalError] = useState(false);
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('Vietnam');
  const [state, setState] = useState('');
  const [postal, setPostal] = useState('');
  const navigate = useNavigate();
  const [filterCountry, setFilterCountry] = useState('');
  const [flag, setFlag] = useState('vn');
  const [chooseFromCountry, setChooseFromCountry] = useState(false);
  const [displayCountry, setDisplayCountry] = useState(false);
  const [town, setTown] = useState('');
  const [mst, setMst] = useState('');
  const [role, setRole] = useState('');
  const [datePicker, setDatePicker] = useState('');
  const [checkboxState, setCheckboxState] = useState(false);
  const [shortName, setShortName] = useState('');
  useEffect(() => {
    const newDate = new Date();
    const date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
    const month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
    const year = newDate.getFullYear();
    setDatePicker(`${date}/${month}/${year}`);
  }, []);
  const renderCountry = () => {
    setDisplayCountry(true);
  };
  const cancelCountry = () => {
    setDisplayCountry(false);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
    const start = e.target.value.toUpperCase().charAt(0);
    const max = 999999999;
    const txt = start + Math.floor(Math.random() * max);
    setIds(txt);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  function loGin() {
    setLoading(true);
    setTimeout(function () {
      setLoading(false);
    }, 500);
    setTimeout(function () {
      navigate('/login');
    }, 500);
  }
  const nextStep = () => {
    if (name.length < 5) {
      setNameError(true);
      setNameExist(false);
      setNext(false);
    } else if (name.length >= 5) {
      setNameError(false);
      setNameExist(false);
    }
    if (company.length < 5) {
      setCompanyError(true);
      setNext(false);
    } else if (company.length >= 5) {
      setCompanyError(false);
    }
    if (validator.isEmail(email)) {
      setEmailError(false);
    } else {
      setEmailError(true);
      setNext(false);
    }

    if (password.length < 6) {
      setPasswordError(true);
      setNext(false);
    } else if (password.length >= 6) {
      setPasswordError(false);
    }
    if (passConfirm !== password) {
      setPassConfirmError(true);
      setNext(false);
    } else if (passConfirm === password) {
      setPassConfirmError(false);
    }
    if (phoneNumber.length < 10) {
      setPhoneError(true);
      setNext(false);
    } else if (phoneNumber.length === 10) {
      setPhoneError(false);
    }
    if (
      name.length >= 5 &&
      company.length >= 5 &&
      validator.isEmail(email) &&
      password.length >= 6 &&
      passConfirm === password &&
      phoneNumber.length >= 10
    ) {
      setLoading(true);
      setTimeout(() => {
        setNext(true);
        setLoading(false);
      }, 700);
    }
  };
  const returnAccountInfo = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setNext(false);
    }, 500);
  };
  const create = () => {
    if (street.length < 5) {
      setFinishSignUp(false);
      setStreetError(true);
    } else if (street.length >= 5) {
      setStreetError(false);
    }
    if (city.length < 5) {
      setFinishSignUp(false);
      setCityError(true);
    } else if (city.length >= 5) {
      setCityError(false);
    }
    if (country.length < 1) {
      setFinishSignUp(false);
      setStateError(true);
    } else if (country.length >= 3) {
      setStateError(false);
    }
    if (postal.length < 1) {
      setFinishSignUp(false);
      setPostalError(true);
    } else if (postal.length >= 1) {
      setPostalError(false);
    }
    if (street.length >= 5 && city.length >= 5 && country.length >= 3 && postal.length >= 1) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        signUp();
      }, 700);
    }
  };
  const setCountryDex = (country) => {
    setFilterCountry(country);
    setChooseFromCountry(true);
  };
  const setFromFlag = (flag) => {
    setFlag(flag);
  };
  const saveQuickCreate = () => {
    setDisplayCountry(false);
    setCountry(filterCountry);
    setFlag(flag);
    setChooseFromCountry(false);
  };
  const register = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      signUp();
    }, 1200);
  };

  const onSignOut = () => {
    auth.signOut();
    navigate('/');
  };
  const cusEmail = email.toLowerCase().trim();
  const signUp = () => {
    setLoading(true);
    auth.createUserWithEmailAndPassword(cusEmail, password).then((auth) => {
      if (auth.user) {
        auth.user
          .updateProfile({
            displayName: name,
          })
          .then(() => {
            db.collection('users')
              .doc(cusEmail)
              .set({
                displayName: auth.user.displayName,
                email: auth.user.email,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                createAt: datePicker,
                exporter: company,
                uid: auth.user.uid,
                country: country,
                makh: ids,
                password: password,
                city: city,
                town: town,
                state: state,
                address: street,
                address2: '',
                address3: '',
                postal: postal,
                phone: phoneNumber,
                mst: mst,
                plan: 'Pending',
                acceptedTerm: true,
                employee: 'no',
                controller: 'Pending',
              })
              .then((auth) => {
                setLoading(false);
                onSignOut();
              })
              .catch((error) => {
                alert('Please try again your email address may be taken');
                setLoading(false);
              });
          });
      }
    });
  };
  const handleCheck = (e) => {
    setCheckboxState(e.target.checked);
  };

  return (
    <>
      {!finishSignUp && (
        <div className="login">
          {loading && (
            <div className="loading-screen">
              <div className="loading-circle">
                <CircularProgress fontSize="small" />
              </div>
            </div>
          )}
          <div className="signUp__container">
            <div className="signUp__header">
              {!next && !finishSignUp && (
                <h2 style={{ marginLeft: '7px', marginTop: '15px', fontWeight: 600, color: 'rgba(0,0,0,0.75)' }}>
                  Create for yourself a Skydart account to get shipped anytime
                </h2>
              )}
              {next && (
                <h2 className="next-content">
                  Great! You have finished the first step, please provide your address for pick up reason.
                </h2>
              )}
            </div>
            {!next && !finishSignUp && (
              <div className="signUp__body">
                <div className="signUp__body__container">
                  <div className="customer__info__signUp">
                    {!nameError && !nameExist && (
                      <TextField
                        className="signUp_outline"
                        label="Full Name"
                        variant="outlined"
                        type="text"
                        size="small"
                        value={name}
                        onChange={handleNameChange}
                      />
                    )}
                    {nameError && (
                      <TextField
                        className="signUp_outline"
                        value={name}
                        onChange={handleNameChange}
                        variant="outlined"
                        error
                        size="small"
                        label="Name Is Too Short"
                      />
                    )}

                    {!companyError && (
                      <TextField
                        className="signUp_outline"
                        label="Company"
                        variant="outlined"
                        type="text"
                        placeholder="Use personal name if you are not a company"
                        size="small"
                        value={company}
                        InputProps={{}}
                        onChange={(e) => setCompany(e.target.value)}
                      />
                    )}
                    {companyError && (
                      <TextField
                        className="signUp_outline"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        variant="outlined"
                        error
                        type="text"
                        size="small"
                        label="Company name is required"
                      />
                    )}
                    {!emailError && !emailAddressExist && (
                      <TextField
                        className="signUp_outline"
                        label="Email"
                        variant="outlined"
                        type="email"
                        placeholder="Ex: abc@skydart.com.vn or your email address"
                        value={email.toLowerCase()}
                        onChange={handleEmailChange}
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AiOutlineMail fontSize="large" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    {emailError && (
                      <TextField
                        className="signUp_outline"
                        value={email.toLowerCase()}
                        onChange={handleEmailChange}
                        variant="outlined"
                        error
                        placeholder="Ex: abc@skydart.com.vn or your email address"
                        type="email"
                        size="small"
                        label="Please Enter A Valid Email"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AiOutlineMail fontSize="large" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    <div
                      style={{
                        display: 'flex',
                        width: '95%',
                        marginLeft: '10px',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {!passwordError && (
                        <TextField
                          style={{ width: '50%' }}
                          label="Password"
                          variant="outlined"
                          type="password"
                          size="small"
                          autoComplete="off"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      )}
                      {passwordError && (
                        <TextField
                          style={{ width: '50%' }}
                          label="Weak Password"
                          error
                          variant="outlined"
                          type="password"
                          autoComplete="off"
                          size="small"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      )}
                      {!passConfirmError && (
                        <TextField
                          style={{ width: '45%' }}
                          label="Password Confirm"
                          variant="outlined"
                          autoComplete="off"
                          size="small"
                          value={passConfirm}
                          onChange={(e) => setPassConfirm(e.target.value)}
                          type="password"
                        />
                      )}
                      {passConfirmError && (
                        <TextField
                          style={{ width: '45%' }}
                          label="Password Does Not Match "
                          variant="outlined"
                          error
                          size="small"
                          autoComplete="off"
                          value={passConfirm}
                          onChange={(e) => setPassConfirm(e.target.value)}
                        />
                      )}
                    </div>
                    {!phoneError && (
                      <TextField
                        className="signUp_outline"
                        label="Phone Number"
                        variant="outlined"
                        type="number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    )}
                    {phoneError && (
                      <TextField
                        className="signUp_outline"
                        label="Your Number Should Not Be Shorter Than 10 Digit Number"
                        variant="outlined"
                        type="number"
                        error
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    )}
                  </div>
                  <div className="signUp__option">
                    <h5 className="prev-logIn font-semibold" onClick={loGin}>
                      Already Have Account ?
                    </h5>
                    <Button variant="contained" className="next-signUp" endIcon={<BiChevronRight />} onClick={nextStep}>
                      Next
                    </Button>
                  </div>
                </div>
                <div className="w-[50%]">
                  <img src={address1} className="w-[300px] h-[300px] object-cover" />
                </div>
              </div>
            )}
            {next && (
              <div className="signUp__next">
                <div className="signUp__addressForm">
                  <div className="signUp__addressFormContainer">
                    {!stateError && (
                      <TextField
                        className="signUp_outline"
                        label="Country"
                        variant="outlined"
                        type="text"
                        size="small"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      />
                    )}

                    {stateError && (
                      <TextField
                        className="signUp_outline"
                        label="Country is required"
                        variant="outlined"
                        type="text"
                        error
                        size="small"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      />
                    )}
                    {!cityError && country !== 'Vietnam' && (
                      <TextField
                        className="signUp_outline"
                        label="City"
                        variant="outlined"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        type="text"
                        size="small"
                      />
                    )}
                    {cityError && country !== 'Vietnam' && (
                      <TextField
                        className="signUp_outline"
                        label="Please Enter A Valid City"
                        variant="outlined"
                        error
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        type="text"
                        size="small"
                      />
                    )}
                    {!cityError && country === 'Vietnam' && (
                      <select
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        style={{
                          padding: 10,
                          border: '1px solid lightgray',
                          outline: 'none',
                          borderRadius: 5,
                          cursor: 'pointer',
                          marginLeft: 10,
                        }}
                      >
                        <option value="">Please select a city</option>
                        {vietnamCity.map((s, index) => (
                          <option key={index} value={s.value}>
                            {s.label}
                          </option>
                        ))}
                      </select>
                    )}
                    {cityError && country === 'Vietnam' && (
                      <>
                        <select
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          style={{
                            padding: 10,
                            border: '1px solid red',
                            outline: 'none',
                            borderRadius: 5,
                            cursor: 'pointer',
                            marginLeft: 10,
                          }}
                        >
                          <option value="">City / Thành Phố</option>
                          {vietnamCity.map((s, index) => (
                            <option key={index} value={s.value}>
                              {s.label}
                            </option>
                          ))}
                        </select>
                        <p style={{ fontSize: 10, fontWeight: 600, color: 'red', marginLeft: 10 }}>
                          Please select your city{' '}
                        </p>
                      </>
                    )}
                    {country === 'Vietnam' && (
                      <>
                        {city === 'Hồ Chí Minh' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {hcmTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Hà Nội' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {hanoiTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Đà Nẵng' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {danangTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Khánh Hòa' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {khanhhoaTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Hải Phòng' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {haiphongTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Long An' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {longanTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Quảng Nam' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {quangnamTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Bà Rịa Vũng Tàu' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {vungtauTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Đắk Lắk' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {daklakTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Cần Thơ' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {canthoTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Bình Thuận' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {binhthuanTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Lâm Đồng' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {lamdongTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Thừa Thiên Huế' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {hueTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Kiên Giang' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {kiengiangTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Bắc Ninh' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {bacninhTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Quảng Ninh' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {quangninhTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Thanh Hóa' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {thanhhoaTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Nghệ An' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {ngheanTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Hải Dương' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {haiduongTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Gia Lai' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {gialaiTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Bình Phước' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {binhphuocTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Hưng Yên' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {hungyenTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Bình Định' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {binhdinhTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Tiền Giang' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {tiengiangTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Thái Bình' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {thaibinhTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Bắc Giang' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {bacgiangTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Hòa Bình' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {hoabinhTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'An Giang' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {angiangTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Vĩnh Phúc' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {vinhphucTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Tây Ninh' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {tayninhTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Thái Nguyên' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {thainguyenTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Lào Cai' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {laocaiTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Nam Định' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {namdinhTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Quảng Ngãi' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {quangngaiTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Bến Tre' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {bentreTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Đắk Nông' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {daknongTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Cà Mau' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {camauTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Vĩnh Long' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {vinhlongTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Ninh Bình' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {ninhbinhTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Phú Thọ' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {phuthoTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Ninh Thuận' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {ninhthuanTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Phú Yên' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {phuyenTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Hà Nam' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {hanamTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Hà Tĩnh' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {hatinhTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Đồng Tháp' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {dongthapTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Sóc Trăng' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {soctrangTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Kon Tum' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {kontumTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Quảng Bình' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {quangbinhTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Quảng Trị' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {quangtriTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Trà Vinh' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {travinhTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Hậu Giang' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {haugiangTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Sơn La' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {sonlaTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Bạc Liêu' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {baclieuTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Yên Bái' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {yenbaiTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Tuyên Quang' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {tuyenquangTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Điện Biên' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {dienbienTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Lai Châu' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {laichauTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Lạng Sơn' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {langsonTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Hà Giang' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {hagiangTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Bắc Kạn' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {backanTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {city === 'Cao Bằng' && (
                          <select value={town} onChange={(e) => setTown(e.target.value)}>
                            <option value="">Quận Huyện</option>
                            {caobangTown.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.label}
                              </option>
                            ))}
                          </select>
                        )}
                      </>
                    )}
                    {country !== 'Vietnam' && (
                      <TextField
                        className="signUp_outline"
                        label="Town"
                        variant="outlined"
                        type="text"
                        size="small"
                        value={town}
                        onChange={(e) => setTown(e.target.value)}
                      />
                    )}
                    {country !== 'Vietnam' && (
                      <TextField
                        className="signUp_outline"
                        label="State"
                        variant="outlined"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        type="text"
                        size="small"
                      />
                    )}
                    {country === 'Vietnam' && city !== '' && (
                      <TextField
                        className="signUp_outline"
                        label="Phường Xã"
                        variant="outlined"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        type="text"
                        size="small"
                      />
                    )}
                    {!streetError && (
                      <TextField
                        className="signUp_outline"
                        label="Address"
                        variant="outlined"
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                        type="text"
                        size="small"
                      />
                    )}

                    {streetError && (
                      <TextField
                        className="signUp_outline"
                        label="Please Enter Your Address"
                        variant="outlined"
                        value={street}
                        error
                        onChange={(e) => setStreet(e.target.value)}
                        type="text"
                        size="small"
                      />
                    )}

                    {!postalError && (
                      <TextField
                        className="signUp_outline"
                        label="Postal Code"
                        variant="outlined"
                        type="text"
                        size="small"
                        value={postal}
                        onChange={(e) => setPostal(e.target.value)}
                      />
                    )}
                    {postalError && (
                      <TextField
                        className="signUp_outline"
                        label="Postal code is required"
                        error
                        variant="outlined"
                        type="text"
                        size="small"
                        value={postal}
                        onChange={(e) => setPostal(e.target.value)}
                      />
                    )}

                    <div className="nextBtn">
                      <Button startIcon={<BiChevronLeft />} className="back-singUp" onClick={returnAccountInfo}>
                        Back
                      </Button>
                      <Button variant="contained" className="next-signUp" onClick={create}>
                        Create Account
                      </Button>
                    </div>
                  </div>
                  <div className="next-btn-opt">
                    <img
                      src={address1}
                      //   style={{ width: '100%', height: '65%', borderRadius: '10px' }}
                      alt="image"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {finishSignUp && (
        <div style={{ height: '100vh', overflow: 'auto', background: 'rgb(236,236,236)' }}>
          <div className="term__container">
            <div className="term__content">
              <div className="term-invoice">
                <div className="term__header">
                  <center>
                    <img src="http://skydart.com.vn/vnt_upload/weblink/logo.png" alt="" />
                    <h3>THỎA THUẬN SỬ DỤNG DỊCH VỤ VẬN CHUYỂN SKYDART EXPRESS</h3>
                    <h3>CỦA CÔNG TY TNHH QUỲNH VŨ</h3>
                  </center>
                  <p>
                    Bằng cách chấp thuận bản thỏa thuận này, người sử dụng dịch vụ vận chuyển SKYDART EXPRESS có tên
                    dưới đây đã đồng ý các điều khoản và điểu kiện vận chuyển do công ty TNHH Quỳnh Vũ đưa ra.
                  </p>
                </div>
                <div className="term__content">
                  <div className="term__content__primary">
                    <h3>BÊN CHỦ HÀNG (ĐƯỢC GỌI LÀ BÊN A)</h3>
                  </div>
                  <div className="term__content__primary">
                    <h5>Tên công ty / Cá Nhân:</h5>
                    <h4>{company}</h4>
                  </div>
                  <div className="term__content__primary">
                    <h5>Đại diện:</h5>
                    <h4>{name}</h4>
                  </div>
                  <div className="term__content__primary">
                    <h5>Địa Chỉ:</h5>
                    <h4>{street}</h4>
                  </div>
                  <div className="term__content__primary">
                    <h5>Điện Thoại:</h5>
                    <h4>{phoneNumber}</h4>
                  </div>
                  <div className="term__content__primary">
                    <h5>Mã số thuế:</h5>
                    <input
                      type="text"
                      value={mst}
                      onChange={(e) => setMst(e.target.value)}
                      placeholder="Nhập Mã Số Thuế"
                      style={{ padding: 5 }}
                    />
                  </div>
                  <div className="term__content__primary">
                    <h3>BÊN CUNG CẤP DỊCH VỤ (ĐƯỢC GỌI LÀ BÊN B)</h3>
                  </div>
                  <div className="term__content__primary">
                    <h5>Tên công ty: </h5>
                    <h4>CÔNG TY TNHH QUỲNH VŨ</h4>
                  </div>
                  <div className="term__content__primary">
                    <h5>Địa chỉ: </h5>
                    <h4>32 LAM SƠN PHƯỜNG 2 QUẬN TÂN BÌNH TP. HỒ CHÍ MINH </h4>
                  </div>
                  <div className="term__content__primary">
                    <h5>Điện thoại: </h5>
                    <h4>+ 84 028 3848 7976</h4>
                  </div>
                  <div className="term__content__primary">
                    <h5>Mã số thuế: </h5>
                    <h4>0303369205</h4>
                  </div>
                  <div className="term__content__primary">
                    <p>
                      Bên A chỉ định và bên B đồng ý và là nhà cung cấp dịch vụ vận chuyển hàng hoá và chứng từ thương
                      mại từ Việt Nam đi các nước trên thế giới
                    </p>
                  </div>
                  <div className="term__content__container">
                    <h5>Điều 1: Dịch vụ</h5>
                    <div className="term__content__secondary">
                      <h5>1.1</h5>
                      <p>Tên dịch vụ: dịch vụ chuyển phát nhanh quốc tế (SKYDART EXPRESS).</p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>1.2</h5>
                      <p>
                        Mô tả dịch vụ: Vận chuyển chứng từ thương mại, hàng hóa đi quốc tế không bao gồm hàng nguy hiểm,
                        độc hại, thực phẩm, hoá chất, chất lỏng, thuốc men.
                      </p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>1.3</h5>
                      <p>
                        Thời gian nhận của chứng từ và hàng hoá là thới gian dự kiến và phải phụ thuộc vào thời gian
                        hàng hóa được hải quan nước đến cho phép thông quan
                      </p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>1.4</h5>
                      <p>
                        Trong trường hợp Bên A có các yêu cầu đặc biệt và cụ thể về thời gian hoặc yêu cầu 1 loại hình
                        vận chuyển hay một phương thức giao nhận vận chuyển khác thì 2 bên sẽ có những thỏa thuận cho
                        từng lô hàng.
                      </p>
                    </div>
                  </div>
                  <div className="term__content__container">
                    <h5>Điều 2: Giá cả</h5>
                    <p style={{ marginLeft: '55px', marginTop: 10, marginBottom: 10, fontWeight: 600, fontSize: 14 }}>
                      Giá cả được thoả thuận cụ thể giữa 2 bên. Bên B sẽ gửi cho bên A một bản chào giá cho từng lô hàng
                      hoặc một biểu phí tính cước. Trong trường hợp bản chào giá hoặc biếu phi tính cước không đề cập
                      tới phụ phí hoặc chỉ đề cập tới một phần phụ phí thì các phụ phí được liệt kê cụ thể dưới đây chưa
                      bao gồm trong giá cước và sẽ tính phí cho bên A nếu phụ phí phát sinh thêm trong quá trình vận
                      chuyển.
                    </p>
                    <div className="term__content__secondary">
                      <h5>2.1</h5>
                      <p>
                        Phụ phí xăng dầu được thông báo qua hình thức niêm yết tỉ lệ phụ phí trên giá cước dịch vụ tại
                        website của bên B{' '}
                        <a href="http://www.skydart.com.vn" target="_blank">
                          http://www.skydart.com.vn
                        </a>{' '}
                        hoặc qua 1 hình thức thông tin khác.
                      </p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>2.2</h5>
                      <p>Thuế VAT hoặc thuế xuất khẩu (nếu có) tại Việt Nam.</p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>2.3</h5>
                      <p>Thuế nhập khẩu tại nước đến.</p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>2.4</h5>
                      <p>
                        Phí hun trùng, phí kiểm dịch động thực vật trong trường hợp hàng hoá được làm từ động vật hoặc
                        thực vật, Phí kiểm tra văn hoá trong trường hợp hàng là văn hoá phẩm.{' '}
                      </p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>2.5</h5>
                      <p>
                        Phí khai báo hải quan trong trường hợp hàng có giá trị cao và phải chịu thuế nhập khẩu tại nước
                        đến.
                      </p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>2.6</h5>
                      <p>
                        Phí lưu kho lưu bãi khi hàng không hoàn thành thủ tục hải quan đúng thời hạn do thiếu giấy tờ từ
                        người nhận hàng.
                      </p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>2.7</h5>
                      <p>
                        Tiền phạt do khai báo hàng sai giá trị, hàng nguy hiểm không khai báo đầy đủ và bị cơ quan nhà
                        nước tại nước nhập khẩu phát hiện.
                      </p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>2.8</h5>
                      <p>Phụ phí phát hàng vào vùng sâu vùng xa.</p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>2.9</h5>
                      <p>
                        Phụ phí do hàng quá khổ (1 kiện hàng có 1 chiều dài quá 120cm), quá tải trọng trung bình (1 kiện
                        hàng có trọng lượng vượt quá 70 Kg).
                      </p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>2.10</h5>
                      <p>
                        Phụ phí phát hàng vào khu dân cư hoặc phát hàng nặng lên cao khi phải sử dụng thang bộ (nếu có)
                      </p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>2.11</h5>
                      <p>
                        Phụ phí phát hàng lại lần thứ 2 khi hàng đã được đưa đi giao nhưng không giao được do địa chỉ
                        sai
                      </p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>2.12</h5>
                      <p>
                        Phí trả hàng về trong trường hợp hàng không thể thông quan do khai báo sai hoặc gửi hàng không
                        theo quy định tại nước đến hoặc hàng thiếu giấy tờ mà không kịp bổ sung đúng thời hạn
                      </p>
                    </div>
                  </div>
                  <div className="term__content__container">
                    <h5>Điều 3: Vận Đơn</h5>
                    <div className="term__content__secondary">
                      <h5>3.1</h5>
                      <p>Vận đơn là chứng từ bắt buộc phải được phát hành để quản lý và kiểm soát từng lô hàng.</p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>3.2</h5>
                      <p>
                        Vận đơn được tạo qua hệ thống booking online của Skydart hoặc qua mẫu in sẵn do Skydart cung
                        cấp. Bên A kiểm tra đầy đủ các thông tin trên vận đơn và chịu trách nhiệm về tính chính xác các
                        nội dung trên vận đơn ngay cả khi Bên B bằng một lý do nào đó khai hộ cho bên A.
                      </p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>3.3</h5>
                      <p>
                        Vận đơn phải có đầy đủ các thông tin liên lạc của người gửi và người nhận. Bân A không có trách
                        nhiệm thông báo cho người nhận một cách trực tiếp bằng điện thoại mà người nhận hàng sẽ nhận đầy
                        đủ thông tin về lô hàng trên hệ thống tracing online có trên website của Skydart hoặc một bên
                        thứ 3 mà Skydart báo cho Bên A
                      </p>
                    </div>
                  </div>
                  <div className="term__content__container">
                    <h5>Điều 4: Thanh Toán</h5>
                    <div className="term__content__secondary">
                      <h5>4.1</h5>
                      <p>
                        Bên B sẽ gửi bảng kê chi phí sử dụng dịch vụ để bên A đối chiếu. Bên A có trách nhiệm thông báo
                        ngày thanh toán ngay sau khi nhận được bảng kê chi phí sử dụng dịch vụ. Hóa đơn điện tử Giá Trị
                        Gia Tăng do bên B phát hành và gửi qua E-mail do bên A cung cấp sau khi nhận được thanh toán.
                        Hình thức thanh toán bằng tiền mặt hoặc chuyển khoản do bên A chuyển cho bên B trước khi hàng
                        được vận chuyển. Nếu 2 bên có các hình thức thanh toán khác sẽ được thỏa thuận và đồng ý bằng
                        một bản phụ lục khác.
                      </p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>4.2</h5>
                      <div className="term__secondary__Option">
                        <p>
                          Bên A sẽ thanh toán cho bên B bằng VND hoặc chuyển khoản qua tài khoản ngân hàng có chi tiết
                          dưới đây:
                        </p>
                        <div className="term__option">
                          <p>Người hưởng thụ:</p>
                          <h4 style={{ textAlign: 'center' }}>Công ty TNHH Quỳnh Vũ</h4>
                        </div>
                        <div className="term__option">
                          <p>Số tài khoản (VND):</p>
                          <h4>112-0000-134-07</h4>
                        </div>
                        <div className="term__option">
                          <p>Tại:</p>
                          <h4>Ngân hàng TMCP Công Thương Việt Nam (Chi nhánh 1 – TPHCM)</h4>
                        </div>
                      </div>
                    </div>
                    <div className="term__content__secondary">
                      <h5>4.3</h5>
                      <p>
                        Trong trường hợp bên A chậm thanh toán cho bên B, bên B sẽ căn cứ vào lãi suất ngân hàng tại
                        thời điểm tính từ ngày quá hạn đầu tiên cho đến ngày thực tế bên A thanh toán cho bên B để tính
                        lãi suất chậm thanh toán. Lãi suất chậm thanh toán sẽ được tính căn cứ theo tỉ lệ lãi suất hàng
                        ngày của tổng số tiền nợ quá hạn và nhân với số ngày bên B chậm thanh toán.
                      </p>
                    </div>
                  </div>
                  <div className="term__content__container">
                    <h5>Điều 5: Trách nhiệm của các bên</h5>
                    <div className="term__content__option">
                      <div className="term__secondary__option">
                        <h5>5.1</h5>
                        <p>Trách nhiệm bên A</p>
                      </div>
                      <div className="term__secondary__term">
                        <p>
                          - Thông báo cho bên B nhu cầu cụ thể từng chuyến hàng, thời gian và địa điểm giao nhận hàng
                          tại Việt Nam.
                        </p>
                        <p>
                          - Lập chứng từ gửi hàng phù hợp với yêu cầu vận chuyển và phương thức vận chuyển. (Ghi vận
                          đơn, và lập phiếu xuất kho hoặc hoá đơn thương mại cho hàng hoá).
                        </p>
                        <p>
                          - Thông tin đầy đủ về tên, địa chỉ, số điện thoại của người nhận hàng tại nước ngoài. Đảm bảo
                          tính chính xác của tất cả thông tin bên A cung cấp cho bên B.
                        </p>
                        <p>
                          - Đối với hàng dễ bể vỡ, bên A phải thông báo cho bên B trước khi hàng được vận chuyển để được
                          hướng dẫn đóng gói hàng phù hợp với các điều kiện vận chuyển
                        </p>
                        <p>- Xác nhận thanh toán đầy đủ các cước phí phát sinh ngoài bảng báo giá (nếu có)</p>
                        <p>- Thanh toán đầy đủ kịp thời cho bên B số tiền theo hạn định tại điều 4</p>
                      </div>
                    </div>
                    <div className="term__content__container">
                      <div className="term__secondary__option">
                        <h5>5.2</h5>
                        <p>Trách nhiệm bên B</p>
                      </div>
                      <div className="term__secondary__term">
                        <p>- Sắp xếp giao nhận hàng theo đúng yêu cầu của bên A.</p>
                        <p>- Hướng dẫn cho bên A cách thức lập chứng từ phù hợp cho việc gửi hàng hoá</p>
                        <p>
                          - Hướng dẫn cho bên A cách thức đóng gói hàng dễ vỡ nếu có và nếu được thông báo. Trường hợp
                          bên A không thông báo cho bên B tính dễ vỡ của hàng trước khi vận chuyển, bên B không có trách
                          nhiệm bồi thường nếu hàng xảy ra bể vỡ trong quá trình vận chuyển.
                        </p>
                        <p>
                          - Thông tin và cập nhật đầy đủ về quá trình vận chuyển và lịch trình vận chuyển hàng hoá trên
                          website{' '}
                          <a href="http://skydart.com.vn/" target="_blank">
                            skydart.com.vn
                          </a>{' '}
                          để bên A tiện theo dõi và xử lý kịp thời khi có các vấn đề xảy ra.
                        </p>
                        <p>- Đảm bảo an toàn, chính xác và bí mật thông tin của khách hàng.</p>
                        <p>
                          - Phát hành thông báo thanh toán chính xác và đúng thời hạn ghi trên điều 4. Thông báo cho bên
                          A đầy đủ về các mức phụ phí phát sinh. Trong trường hợp bên A chậm thanh toán cho bên B và
                          không thực hiện đúng nghĩa vụ của điều 4, bên B có quyền từ chối vận chuyển hoặc từ chối tiếp
                          tục vận chuyển hàng nếu hàng đang trong quá trình vận chuyển. Trong trường hợp hàng hoá bị từ
                          chối tiếp tục vận chuyển bên B không chịu trách nhiệm nếu hàng bị mất mát hoặc phát sinh các
                          chi phí lưu kho.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="term__content__container">
                    <h5>Điều 6: Điều khoản bồi thường</h5>
                    <div className="term__content__secondary">
                      <h5>6.1</h5>
                      <div className="term__option1">
                        <p>Định nghĩa mất mát hàng hoá:</p>
                        <p>
                          Bên B là 1 nhà cung cấp dịch vụ vận chuyển theo phương thức chuyển tiếp cho 1 đơn vị vận tải
                          có phương tiện vận chuyển (máy bay hoặc tàu có khả năng đi biển), khi hàng hoá chưa được xác
                          định đã tới điểm đến, bên B sẽ tham chiếu theo các điều khoản và điều kiện vận chuyển của đơn
                          vị vận tải để xác định hàng hoá bị mất và xác định mức độ bồi thường theo các quy định của đơn
                          vị vận tải đó đưa ra.
                        </p>
                      </div>
                    </div>
                    <div className="term__content__secondary">
                      <h5>6.2</h5>
                      <p>
                        Trong trường hợp không chứng minh được hàng bị mất mát thuộc quá trình vận chuyển nào và do đơn
                        vị vận tải nào làm mất hàng, việc bồi thường sẽ căn cứ theo giá trị của lô hàng ghi trên hoá đơn
                        thương mại gửi kèm theo hàng (commercial invoice hoặc proforma invoice) và mức bồi thường không
                        vượt quá 1,000,000 Vnd (Một triệu đồng) trên 1 kiện hàng.
                      </p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>6.3</h5>
                      <p>
                        Đối với các lô hàng có giá trị vượt quá 10,000,000 Vnd (Mười triệu đồng), bên A phải chịu trách
                        nhiệm mua bảo hiểm cho hàng. Bên B có quyền từ chối vận chuyển hàng có giá trị vượt 10,000,000
                        Vnd (Mười triệu đồng) nếu như hàng chưa được mua bảo hiểm và trong trường hợp bên A đồng ý để
                        bên B vận chuyển hàng có giá trị vượt 10,000,000 Vnd (Mười triệu đồng) mà không có bảo hiểm kèm
                        theo bên B sẽ bồi thường theo điều khoản 5.2.
                      </p>
                    </div>
                  </div>
                  <div className="term__content__container">
                    <h5>Điều 7: Chấm dứt thỏa thuận trước thời hạn</h5>
                    <p style={{ marginLeft: '52px' }}>
                      Bên A và bên B có quyền chấm dứt thỏa thuận trước thời hạn trong các trường hợp sau:
                    </p>
                    <p style={{ marginLeft: '52px' }}>
                      - Bên đối tác vi phạm bất kỳ điều khoản nào trong thỏa thuận này.
                    </p>
                    <p style={{ marginLeft: '52px' }}>
                      - Bên đối tác không có khả năng thanh toán hoặc không có khả năng cung cấp dịch vụ.
                    </p>
                    <p style={{ marginLeft: '52px' }}>
                      - Thỏa thuận hết thời hạn và các bên không thỏa thuận gia hạn thêm.
                    </p>
                    <p style={{ marginLeft: '52px' }}>
                      - Các trường hợp khác nếu có do hai bên thỏa thuận bằng văn bản.
                    </p>
                  </div>
                  <div className="term__content__container">
                    <h5>Điều 8: Giải quyết tranh chấp:</h5>
                    <div className="term__content__secondary">
                      <h5>8.1</h5>
                      <p>
                        Mọi tranh chấp phát sinh liên quan đến Thỏa thuận này sẽ được giải quyết bằng thương lượng giữa
                        các bên trong vòng 30 ngày kể từ thời điểm phát sinh tranh chấp.
                      </p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>8.2</h5>
                      <p>
                        Nếu quá thời hạn trên mà các bên không tự giải quyết được thì các bên có thể đưa việc tranh chấp
                        giải quyết tại toà kinh tế tại thành phố Hồ Chí Minh. Phán quyết của toà sẽ là phán quyết cuối
                        cùng và có hiệu lực bắt buộc thi hành đối với hai bên. Chi phí cho các hoạt động kiểm tra, xác
                        minh và án phí do bên thua kiện chịu.
                      </p>
                    </div>
                  </div>
                  <div className="term__content__container">
                    <h5>Điều 9: Điều khoản chung</h5>
                    <div className="term__content__secondary">
                      <h5>8.1</h5>
                      <p>
                        Hai bên cùng cam kết thực hiện các điều khoản đã thoả thuận trong thỏa thuận, trong quá trình
                        thực hiện nếu có vấn đề gì phát sinh thì hai bên cùng nhau bàn bạc giải quyết
                      </p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>8.2</h5>
                      <p>
                        Bên B được miễn trách nhiệm bồi thường trong trường hợp bất khả kháng như hỏa hoạn, lũ lụt chiến
                        tranh , phong tỏa , cấm vận trong quá trình vận chuyển dẫn đến việc không thực hiện được một
                        phần hoặc toàn bộ thỏa thuận này
                      </p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>8.3</h5>
                      <p>Thỏa thuận có hiệu lực kể từ ngày {datePicker}.</p>
                    </div>
                    <div className="term__content__secondary">
                      <h5>8.4</h5>
                      <p>
                        Mọi thay đổi của thỏa thuận này chỉ có giá trị sau khi cả hai bên nhất trí ký phụ lục Thỏa
                        thuận.
                      </p>
                    </div>
                    <p>
                      Thỏa thuận này được làm thành ba bản, bên A giữ một bản, và bên B giữ hai bản có giá trị pháp lý
                      ngang nhau.
                    </p>
                  </div>
                  {/* <div className='term__bottom'>
                                        <h4>Đại diện bên A</h4>
                                        <h4 style={{ marginRight: "35px" }}>Đại diện bên B</h4>
                                    </div> */}
                  {/* <div className="providing-account">
                                        <h3 style={{ marginLeft: "5px" }}>Thông tin đăng nhập của bạn</h3>
                                        <p style={{ padding: "5px" }}>Tên đăng nhập (username): <strong>{email}</strong></p>
                                        <p style={{ padding: "5px" }}>Mật khẩu (password): <strong>{password}</strong></p>
                                    </div> */}
                  <div className="term__accept" style={{ display: 'flex' }}>
                    <Checkbox
                      color="primary"
                      onChange={handleCheck}
                      checked={checkboxState}
                      value={checkboxState}
                      type="checkbox"
                      className="accept-check"
                    />
                    <p style={{ fontWeight: 600, fontSize: 12 }}>
                      Tôi đã đọc, và chấp nhận những điều khoản của bên cung cấp dịch vụ vận chuyển đưa ra trong quá
                      trình vận chuyển và tạo tài khoản với Skydart Express.
                    </p>
                  </div>
                  {!checkboxState && (
                    <Button
                      variant="contained"
                      style={{
                        marginTop: 20,
                        borderRadius: 20,
                        color: 'gray',
                        marginLeft: '80%',
                        fontWeight: 600,
                        textTransform: 'capitalize',
                      }}
                    >
                      Tạo Tài Khoản
                    </Button>
                  )}
                  {checkboxState && (
                    <Button
                      variant="contained"
                      style={{
                        background: '#0269A4',
                        borderRadius: 20,
                        marginLeft: '80%',
                        color: 'white',
                        marginTop: 20,
                        fontWeight: 600,
                        textTransform: 'capitalize',
                      }}
                      onClick={register}
                    >
                      Tạo Tài Khoản
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {loading && (
            <div className="loading-screen">
              <div className="loading-circle">
                <CircularProgress fontSize="small" />
              </div>
            </div>
          )}
        </div>
      )}
      {/* <div className="login-phone">
                    <div className="signUp__phone__container">
                        <div className="signUp__phone__header">
                            <h3 className="crt-txt">Create An Account</h3>
                            <h3 className="have-txt" onClick={() => navigate("/")}>Already Have Account ?</h3>
                        </div>
                    </div>
                </div> */}
    </>
  );
}
const hcmTown = [
  { label: 'Quận 1', value: 'Quận 1' },
  { label: 'Quận 2', value: 'Quận 2' },
  { label: 'Quận 3', value: 'Quận 3' },
  { label: 'Quận 4', value: 'Quận 4' },
  { label: 'Quận 5', value: 'Quận 5' },
  { label: 'Quận 6', value: 'Quận 6' },
  { label: 'Quận 7', value: 'Quận 7' },
  { label: 'Quận 8', value: 'Quận 8' },
  { label: 'Quận 9', value: 'Quận 9' },
  { label: 'Quận 10', value: 'Quận 10' },
  { label: 'Quận 11', value: 'Quận 11' },
  { label: 'Quận 12', value: 'Quận 12' },
  { label: 'Bình Tân', value: 'Bình Tân' },
  { label: 'Bình Thạnh', value: 'Bình Thạnh' },
  { label: 'Gò Vấp', value: 'Gò Vấp' },
  { label: 'Phú Nhuận', value: 'Phú Nhuận' },
  { label: 'Tân Bình', value: 'Tân Bình' },
  { label: 'Tân Phú', value: 'Tân Phú' },
  { label: 'Thủ Đức', value: 'Thủ Đức' },
  { label: 'Bình Chánh', value: 'Bình Chánh' },
  { label: 'Cần Giờ', value: 'Cần Giờ' },
  { label: 'Củ Chi', value: 'Củ Chi' },
  { label: 'Hóc Môn', value: 'Hóc Môn' },
  { label: 'Nhà Bè', value: 'Nhà Bè' },
];
const hanoiTown = [
  { label: 'Hoàn Kiếm', value: 'Hoàn Kiếm' },
  { label: 'Ba Đình', value: 'Ba Đình' },
  { label: 'Đống Đa', value: 'Đống Đa' },
  { label: 'Hai Bà Trưng', value: 'Hai Bà Trưng' },
  { label: 'Thanh Xuân', value: 'Thanh Xuân' },
  { label: 'Tây Hồ', value: 'Tây Hồ' },
  { label: 'Cầu Giấy', value: 'Cầu Giấy' },
  { label: 'Hoàng Mai', value: 'Hoàng Mai' },
  { label: 'Long Biên', value: 'Long Biên' },
  { label: 'Đông Anh', value: 'Đông Anh' },
  { label: 'Gia Lâm', value: 'Gia Lâm' },
  { label: 'Sóc Sơn', value: 'Sóc Sơn' },
  { label: 'Thành Trì', value: 'Thành Trì' },
  { label: 'Nam Từ Liêm', value: 'Nam Từ Liêm' },
  { label: 'Bắc Từ Liêm', value: 'Bắc Từ Liêm' },
  { label: 'Hà Đông', value: 'Hà Đông' },
  { label: 'Sơn Tây', value: 'Sơn Tây' },
  { label: 'Mê Linh', value: 'Mê Linh' },
  { label: 'Ba Vì', value: 'Ba Vì' },
  { label: 'Phúc Thọ', value: 'Phúc Thọ' },
  { label: 'Đan Phượng', value: 'Đan Phượng' },
  { label: 'Hoài Đức', value: 'Hoài Đức' },
  { label: 'Quốc Oài', value: 'Quốc Oài' },
  { label: 'Thạch Thất', value: 'Thạch Thất' },
  { label: 'Chương Mỹ', value: 'Chương Mỹ' },
  { label: 'Thanh Oai', value: 'Thanh Oai' },
  { label: 'Thường Tín', value: 'Thường Tín' },
  { label: 'Phú Xuyên', value: 'Phú Xuyên' },
  { label: 'Ứng Hoà', value: 'Ứng Hoà' },
  { label: 'Mỹ Đức', value: 'Mỹ Đức' },
];
const danangTown = [
  { label: 'Cẩm Lệ', value: 'Cẩm Lệ' },
  { label: 'Hải Châu', value: 'Hải Châu' },
  { label: 'Liên Chiếu', value: 'Liên Chiếu' },
  { label: 'Ngũ Hành Sơn', value: 'Ngũ Hành Sơn' },
  { label: 'Sơn Trà', value: 'Sơn Trà' },
  { label: 'Thanh Khê', value: 'Thanh Khê' },
  { label: 'Hoà Vang', value: 'Hoà Vang' },
  { label: 'Hoàng Sa', value: 'Hoàng Sa' },
];
const binhduongTown = [
  { label: 'Bến Cát', value: 'Bến Cát' },
  { label: 'Dầu Tiếng', value: 'Dầu Tiếng' },
  { label: 'Dĩ An', value: 'Dĩ An' },
  { label: 'Phú Giao', value: 'Phú Giao' },
  { label: 'Tân Uyên', value: 'Tân Uyên' },
  { label: 'Thuận An', value: 'Thuận An' },
  { label: 'Thủ Dầu Một', value: 'Thủ Dầu Một' },
  { label: 'Bàu Bàng', value: 'Bàu Bàng' },
];
const dongnaiTown = [
  { label: 'Cẩm Mỹ', value: 'Cẩm Mỹ' },
  { label: 'Định Quán', value: 'Định Quán' },
  { label: 'Long Thành', value: 'Long Thành' },
  { label: 'Nhơn Thạch', value: 'Nhơn Thạch' },
  { label: 'Tân Phú', value: 'Tân Phú' },
  { label: 'Thống Nhất', value: 'Thống Nhất' },
  { label: 'Trảng Bom', value: 'Trảng Bom' },
  { label: 'Vĩnh Cửu', value: 'Vĩnh Cửu' },
  { label: 'Xuân Lộc', value: 'Xuân Lộc' },
  { label: 'Biên Hoà', value: 'Biên Hoà' },
];
const khanhhoaTown = [
  { label: 'Cam Lâm', value: 'Cam Lâm' },
  { label: 'Diên Khánh', value: 'Diên Khánh' },
  { label: 'Khánh Sơn', value: 'Khánh Sơn' },
  { label: 'Khánh Vinh', value: 'Khánh Vinh' },
  { label: 'Ninh Hoà', value: 'Ninh Hoà' },
  { label: 'Trường Sa', value: 'Trường Sa' },
  { label: 'Vạn Ninh', value: 'Vạn Ninh' },
  { label: 'Nha Trang', value: 'Nha Trang' },
  { label: 'Cam Ranh', value: 'Cam Ranh' },
];
const haiphongTown = [
  { label: 'Đồ Sơn', value: 'Đồ Sơn' },
  { label: 'Dương Kinh', value: 'Dương Kinh' },
  { label: 'Hải An', value: 'Hải An' },
  { label: 'Hồng Bàng', value: 'Hồng Bang' },
  { label: 'Kiến An', value: 'Kiến An' },
  { label: 'Lê Chân', value: 'Lê Chân' },
  { label: 'Ngô Quyền', value: 'Ngô Quyền' },
  { label: 'An Dương', value: 'An Dương' },
  { label: 'An Lão', value: 'An Lão' },
  { label: 'Bạch Long Vĩ', value: 'Bạch Long Vĩ' },
  { label: 'Cát Hải', value: 'Cát Hải' },
  { label: 'Kiến Thuỵ', value: 'Kiến Thuỵ' },
  { label: 'Thuỷ Nguyên', value: 'Thuỷ Nguyên' },
  { label: 'Tiên Lãng', value: 'Tiên Lãng' },
  { label: 'Vĩnh Bảo', value: 'Vĩnh Bảo' },
];
const longanTown = [
  { label: 'Bến Lực', value: 'Bến Lức' },
  { label: 'Cần Dước', value: 'Cần Dước' },
  { label: 'Cần Giuộc', value: 'Cần Giuộc' },
  { label: 'Châu Thành', value: 'Chầu Thành' },
  { label: 'Đức Hoà', value: 'Đức Hoà' },
  { label: 'Đức Huệ', value: 'Đức Huệ' },
  { label: 'Mộc Hoá', value: 'Mộc Hoá' },
  { label: 'Tân Hưng', value: 'Tân Hưng' },
  { label: 'Tân Thạnh', value: 'Tân Thạnh' },
  { label: 'Tân Trụ', value: 'Tân Trụ' },
  { label: 'Thạnh Hoá', value: 'Thạnh Hoá' },
  { label: 'Thủ Thừa', value: 'Thủ Thừa' },
  { label: 'Vĩnh Hưng', value: 'Vĩnh Hưng' },
  { label: 'Tân An', value: 'Tân An' },
  { label: 'Kiến Tường', value: 'Kiến Tường' },
];
const quangnamTown = [
  { label: 'Bắc Trà My', value: 'Bắc Trà My' },
  { label: 'Đại Lộc', value: 'Đại Lộc' },
  { label: 'Điện Bàn', value: 'Điện Bàn' },
  { label: 'Đông Giang', value: 'Đông Giang' },
  { label: 'Duy Xuyên', value: 'Duy Xuyên' },
  { label: 'Hiệp Đức', value: 'Hiệp Đức' },
  { label: 'Nam Giang', value: 'Nam Giang' },
  { label: 'Nam Trà My', value: 'Nam Trà My' },
  { label: 'Núi Thành', value: 'Núi Thành' },
  { label: 'Phú Ninh', value: 'Phú Ninh' },
  { label: 'Phước Sơn', value: 'Phước Sơn' },
  { label: 'Quế Sơn', value: 'Quế Sơn' },
  { label: 'Tây Giang', value: 'Tây Giang' },
  { label: 'Thăng Bình', value: 'Thăng Bình' },
  { label: 'Tiên Phước', value: 'Tiên Phước' },
  { label: 'Tam Kỳ', value: 'Hội An' },
  { label: 'Nông Sơn', value: 'Nông Sơn' },
];
const vungtauTown = [
  { label: 'Châu Đức', value: 'Châu Đức' },
  { label: 'Côn Đảo', value: 'Côn Đảo' },
  { label: 'Đất Đỏ', value: 'Đất Đỏ' },
  { label: 'Long Điền', value: 'Long Điền' },
  { label: 'Phú Mỹ', value: 'Phú Mỹ' },
  { label: 'Long Hải', value: 'Long Hải' },
  { label: 'Xuyên Mộc', value: 'Xuyên Mộc' },
  { label: 'Vũng Tàu', value: 'Vũng Tàu' },
  { label: 'Bà Rịa', value: 'Bà Rịa' },
];
const daklakTown = [
  { label: 'Buôn Đôn', value: 'Buôn Đôn' },
  { label: 'Cư Kuin', value: 'Cư Kuin' },
  { label: "Cư M'gar", value: "Cư M'gar" },
  { label: "Ea H'Leo", value: "Ea H'Leo" },
  { label: 'Ea Kar', value: 'Ea Kar' },
  { label: 'Ea Súp', value: 'Ea Súp' },
  { label: 'Krông Ana', value: 'Krông Ana' },
  { label: 'Krông Bông', value: 'Krông Bông' },
  { label: 'Krông Buk', value: 'Krông Buk' },
  { label: 'Krông Năng', value: 'Krông Năng' },
  { label: 'Krông Pắc', value: 'Krông Pắc' },
  { label: 'Lăk', value: 'Lăk' },
  { label: "M'Đrăk", value: "M'Đrăk" },
  { label: 'Buôn Ma Thuột', value: 'Buôn Ma Thuột' },
  { label: 'Buôn Hồ', value: 'Buôn Hồ' },
];
const canthoTown = [
  { label: 'Cờ Đỏ', value: 'Cờ Đỏ' },
  { label: 'Phong Điền', value: 'Phong Điền' },
  { label: 'Thốt Nốt', value: 'Thốt Nốt' },
  { label: 'Vĩnh Thạnh', value: 'Vĩnh Thạnh' },
  { label: 'Bình Thuỷ', value: 'Bình Thuỷ' },
  { label: 'Cái Răng', value: 'Cái Răng' },
  { label: 'Ninh Kiều', value: 'Ninh Kiều' },
  { label: 'Ô Môn', value: 'Ô Môn' },
  { label: 'Thới Lai', value: 'Thới Lai' },
];
const binhthuanTown = [
  { label: 'Bắc Bình', value: 'Bắc Bình' },
  { label: 'Đảo Phú Quý', value: 'Đảo Phú Quý' },
  { label: 'Đức Linh', value: 'Đức Linh' },
  { label: 'Hàm Tân', value: 'Hàm Tân' },
  { label: 'Hàm Thuận Bắc', value: 'Hàm Thuận Bắc' },
  { label: 'Hàm Thuận Nam', value: 'Hàm Thuận Nam' },
  { label: 'Tánh Linh', value: 'Tánh Linh' },
  { label: 'Tuy Phong', value: 'Tuy Phong' },
  { label: 'Phan Thiết', value: 'Phan Thiết' },
  { label: 'La Gi', value: 'La Gi' },
];
const lamdongTown = [
  { label: 'Bảo Lâm', value: 'Bảo Lâm' },
  { label: 'Cát Tiên', value: 'Cát Tiên' },
  { label: 'Đạ Huoai', value: 'Đạ Huoai' },
  { label: 'Đạ Tẻh', value: 'Đạ Tẻh' },
  { label: 'Đam Rông', value: 'Đam Rông' },
  { label: 'Di Linh', value: 'Di Linh' },
  { label: 'Đơn Dương', value: 'Đơn Dương' },
  { label: 'Đức Trọng', value: 'Đức Trọng' },
  { label: 'Lạc Dương', value: 'Lạc Dương' },
  { label: 'Lâm Hà', value: 'Lâm Hà' },
  { label: 'Đà Lạt', value: 'Đà Lạt' },
  { label: 'Bảo Lộc', value: 'Bảo Lộc' },
];
const hueTown = [
  { label: 'A Lưới', value: 'A Lưới' },
  { label: 'Hương Thuỷ', value: 'Hương Thuỷ' },
  { label: 'Hương Trà', value: 'Hương Trà' },
  { label: 'Nam Đông', value: 'Nam Đông' },
  { label: 'Phong Điền', value: 'Phong Điền' },
  { label: 'Phú Lộc', value: 'Phúc Lộc' },
  { label: 'Phú Vang', value: 'Phú Vang' },
  { label: 'Quảng Điền', value: 'Quảng Điền' },
  { label: 'Huế', value: 'Huế' },
];
const kiengiangTown = [
  { label: 'An Biên', value: 'An Biên' },
  { label: 'An Minh', value: 'An Minh' },
  { label: 'Châu Thành', value: 'Châu Thành' },
  { label: 'Giồng Riềng', value: 'Giồng Riềng' },
  { label: 'Gò Quao', value: 'Gò Quao' },
  { label: 'Hòn Đất', value: 'Hòn Đất' },
  { label: 'Kiên Hải', value: 'Kiên Hải' },
  { label: 'Kiên Lương', value: 'Kiên Lương' },
  { label: 'Phú Quốc', value: 'Phú Quốc' },
  { label: 'Tân Hiệp', value: 'Tân Hiệp' },
  { label: 'U Minh Thượng', value: 'U Minh Thượng' },
  { label: 'Vĩnh Thuận', value: 'Vĩnh Thuận' },
  { label: 'Vĩnh Thuận', value: 'Vĩnh Thuận' },
  { label: 'Rạch Giá', value: 'Rạch Giá' },
  { label: 'Hà Tiên', value: 'Hà Tiên' },
  { label: 'Giang Thành', value: 'Giang Thành' },
];
const bacninhTown = [
  { label: 'Gia Bình', value: 'Gia Bình' },
  { label: 'Lương Tài', value: 'Lương Tài' },
  { label: 'Quế Võ', value: 'Quế Võ' },
  { label: 'Thuận Thành', value: 'Thuận Thành' },
  { label: 'Tiên Du', value: 'Tiên Du' },
  { label: 'Từ Sơn', value: 'Từ Sơn' },
  { label: 'Yên Phong', value: 'Yên Phong' },
  { label: 'Bắc Ninh', value: 'Bắc Ninh' },
];
const quangninhTown = [
  { label: 'Ba Chẽ', value: 'Ba Chẽ' },
  { label: 'Bình Liêu', value: 'Bình Liêu' },
  { label: 'Cô Tô', value: 'Cô Tô' },
  { label: 'Đầm Hà', value: 'Đầm Hà' },
  { label: 'Đông Triều', value: 'Đông Triều' },
  { label: 'Hải Hà', value: 'Hải Hà' },
  { label: 'Tiên Yên', value: 'Tiên Yên' },
  { label: 'Vân Đồn', value: 'Vân Đồn' },
  { label: 'Hạ Long', value: 'Hạ Long' },
  { label: 'Cẩm Phả', value: 'Cẩm Phả' },
  { label: 'Móng Cái', value: 'Móng Cái' },
  { label: 'Uông Bí', value: 'Uông Bí' },
  { label: 'Quảng Yên', value: 'Quảng Yên' },
];
const thanhhoaTown = [
  { label: 'Bá Thước', value: 'Bá Thước' },
  { label: 'Cẩm Thuỷ', value: 'Cẩm Thuỷ' },
  { label: 'Đông Sơn', value: 'Đông Sơn' },
  { label: 'Hà Trung', value: 'Hà Trung' },
  { label: 'Hậu Lộc', value: 'Hậu Lộc' },
  { label: 'Hoằng Hà', value: 'Hoằng Hà' },
  { label: 'Lang Chánh', value: 'Lang Chánh' },
  { label: 'Mường Lát', value: 'Mường Lát' },
  { label: 'Nga Sơn', value: 'Nga Sơn' },
  { label: 'Ngọc Lặc', value: 'Ngọc Lặc' },
  { label: 'Như Thanh', value: 'Như Thanh' },
  { label: 'Như Xuân', value: 'Như Xuân' },
  { label: 'Nông Cổng', value: 'Nông Cổng' },
  { label: 'Quan Hoá', value: 'Quan Hoá' },
  { label: 'Quan Sơn', value: 'Quan Sơn' },
  { label: 'Quảng Xương', value: 'Quảng Xương' },
  { label: 'Thạch Thành', value: 'Thạch Thành' },
  { label: 'Thiệu Hoá', value: 'Thiệu Hoá' },
  { label: 'Thọ Xuân', value: 'Thọ Xuân' },
  { label: 'Thường Xuân', value: 'Thường Xuân' },
  { label: 'Nghi Sơn', value: 'Nghi Sơn' },
  { label: 'Triệu Sơn', value: 'Triệu Sơn' },
  { label: 'Vĩnh Lộc', value: 'Vĩnh Lộc' },
  { label: 'Yên Định', value: 'Yên Định' },
  { label: 'Thanh Hoá', value: 'Thanh Hoá' },
  { label: 'Bỉm Sơn', value: 'Bỉm Sơn' },
  { label: 'Sầm Sơn', value: 'Sầm Sơn' },
];
const ngheanTown = [
  { label: 'Anh Sơn', value: 'Anh Sơn' },
  { label: 'Con Cuông', value: 'Con Cuông' },
  { label: 'Diễn Châu', value: 'Diễn Châu' },
  { label: 'Đô Lương', value: 'Đô Lương' },
  { label: 'Hưng Nguyên', value: 'Hưng Nguyên' },
  { label: 'Kỳ Sơn', value: 'Kỳ Sơn' },
  { label: 'Nam Đàn', value: 'Nam Đàn' },
  { label: 'Nghi Lộc', value: 'Nghi Lộc' },
  { label: 'Nghĩa Đàn', value: 'Nghĩa Đàn' },
  { label: 'Quế Phong', value: 'Quế Phong' },
  { label: 'Quỳ Châu', value: 'Quỳ Châu' },
  { label: 'Quỳ Hợp', value: 'Quỳ Hợp' },
  { label: 'Quỳnh Lưu', value: 'Quỳnh Lưu' },
  { label: 'Tân Kỳ', value: 'Tân Kỳ' },
  { label: 'Thanh Chương', value: 'Thanh Chương' },
  { label: 'Tương Dương', value: 'Tương Dương' },
  { label: 'Yên Thành', value: 'Yên Thành' },
  { label: 'Vinh', value: 'Vinh' },
  { label: 'Cửa Lò', value: 'Cửa Lò' },
  { label: 'Thái Hoà', value: 'Thái Hoà' },
  { label: 'Hoàng Mai', value: 'Hoàng Mai' },
];
const haiduongTown = [
  { label: 'Bình Giang', value: 'Bình Giang' },
  { label: 'Cẩm Giàng', value: 'Cẩm Giàng' },
  { label: 'Chí Linh', value: 'Chí Linh' },
  { label: 'Gia Lộc', value: 'Gia Lộc' },
  { label: 'Kim Thành', value: 'Kim Thành' },
  { label: 'Kinh Môn', value: 'Kinh Môn' },
  { label: 'Nam Sách', value: 'Nam Sách' },
  { label: 'Ninh Giang', value: 'Ninh Giang' },
  { label: 'Thanh Hà', value: 'Thanh Hà' },
  { label: 'Thanh Miện', value: 'Thanh Miện' },
  { label: 'Tứ Kỳ', value: 'Tứ Kỳ' },
  { label: 'Hải Dương', value: 'Hải Dương' },
];
const gialaiTown = [
  { label: 'AYun Pa', value: 'AYun Pa' },
  { label: 'ChưPăh', value: 'ChưPăh' },
  { label: 'ChưSê', value: 'ChưSê' },
  { label: 'ChưPRông', value: 'ChưPRông' },
  { label: 'Đăk Đoa', value: 'Đăk Đoa' },
  { label: 'Đăk Pơ', value: 'Đăk Pơ' },
  { label: 'Đức Cơ', value: 'Đức Cơ' },
  { label: 'Ia Grai', value: 'Ia Grai' },
  { label: 'Ia Pa', value: 'Ia Pa' },
  { label: 'KBang', value: 'KBang' },
  { label: 'Kông Chro', value: 'Kông Chro' },
  { label: 'Kông Pa', value: 'Kông Pa' },
  { label: 'Mang Yang', value: 'Mang Yang' },
  { label: 'Phú Thiện', value: 'Phú Thiện' },
  { label: 'Plei Ku', value: 'Plei Ku' },
  { label: 'An Khê', value: 'An Khê' },
  { label: 'Chư Pưh', value: 'Chư Pưh' },
];
const binhphuocTown = [
  { label: 'Bình Long', value: 'Bình Long' },
  { label: 'Bù Đăng', value: 'Bù Đăng' },
  { label: 'Bù Đốp', value: 'Bù Đốp' },
  { label: 'Chơn Thành', value: 'Chơn Thành' },
  { label: 'Đồng Phù', value: 'Đồng Phù' },
  { label: 'Lộc Ninh', value: 'Lộc Ninh' },
  { label: 'Phước Long', value: 'Phước Long' },
  { label: 'Đồng Xoài', value: 'Đồng Xoài' },
  { label: 'Hớn Quản', value: 'Hớn Quản' },
  { label: 'Bù Gia Mập', value: 'Bù Gia Mập' },
  { label: 'Phú Riêng', value: 'Phú Riêng' },
];
const hungyenTown = [
  { label: 'Ân Thi', value: 'Ân Thi' },
  { label: 'Khoái Châu', value: 'Khoái Châu' },
  { label: 'Kim Động', value: 'Kim Động' },
  { label: 'Mỹ Hào', value: 'Mỹ Hào' },
  { label: 'Phù Cừ', value: 'Phù Cừ' },
  { label: 'Tiên Lữ', value: 'Tiên Lữ' },
  { label: 'Văn Giang', value: 'Văn Giang' },
  { label: 'Văn Lâm', value: 'Văn Lâm' },
  { label: 'Yên Mỹ', value: 'Yên Mỹ' },
  { label: 'Hưng Yên', value: 'Hưng Yên' },
];
const binhdinhTown = [
  { label: 'An Lão', value: 'An Lão' },
  { label: 'An Nhơn', value: 'An Nhơn' },
  { label: 'Hoài Ân', value: 'Hoài Ân' },
  { label: 'Hoài Nhơn', value: 'Hoài Nhơn' },
  { label: 'Phù Cát', value: 'Phù Cát' },
  { label: 'Phù Mỹ', value: 'Phù Mỹ' },
  { label: 'Tây Sơn', value: 'Tây Sơn' },
  { label: 'Tuy Phước', value: 'Tuy Phước' },
  { label: 'Vân Canh', value: 'Vân Canh' },
  { label: 'Vĩnh Thạnh', value: 'Vĩnh Thạnh' },
  { label: 'Quy Nhơn', value: 'Quy Nhơn' },
];
const tiengiangTown = [
  { label: 'Cái Bè', value: 'Cái Bè' },
  { label: 'Huyện Cai Lậy', value: 'Huyện Cai Lậy' },
  { label: 'Châu Thành', value: 'Châu Thành' },
  { label: 'Chợ Gạo', value: 'Chợ Gạo' },
  { label: 'Gò Công Đông', value: 'Gò Công Đông' },
  { label: 'Gò Công Tây', value: 'Gò Công Tây' },
  { label: 'Tân Phước', value: 'Tân Phước' },
  { label: 'Tân Phú Đông', value: 'Tân Phú Đông' },
  { label: 'Mỹ Tho', value: 'Mỹ Tho' },
  { label: 'Gò Công', value: 'Gò Công' },
  { label: 'Cai Lậy', value: 'Cai Lậy' },
];
const thaibinhTown = [
  { label: 'Đông Hưng', value: 'Đông Hưng' },
  { label: 'Hưng Hà', value: 'Hưng Hà' },
  { label: 'Kiến Xương', value: 'Kiến Xương' },
  { label: 'Quỳnh Phụ', value: 'Quỳnh Phụ' },
  { label: 'Thái Thuỵ', value: 'Thái Thuỵ' },
  { label: 'Tiền Hải', value: 'Tiền Hải' },
  { label: 'Vũ Thư', value: 'Vũ Thư' },
  { label: 'Thái Bình', value: 'Thái Bình' },
];
const bacgiangTown = [
  { label: 'Hiệp Hoà', value: 'Hiệp Hoà' },
  { label: 'Lạng Giang', value: 'Lạng Giang' },
  { label: 'Lục Nam', value: 'Lục Nam' },
  { label: 'Lục Ngạn', value: 'Lục Ngạn' },
  { label: 'Sơn Đông', value: 'Sơn Đông' },
  { label: 'Tân Yên', value: 'Tân Yên' },
  { label: 'Việt Yên', value: 'Việt Yên' },
  { label: 'Yên Dũng', value: 'Yên Dũng' },
  { label: 'Yên Thế', value: 'Yên Thế' },
  { label: 'Bắc Giang', value: 'Bắc Giang' },
];
const hoabinhTown = [
  { label: 'Cao Phong', value: 'Cao Phong' },
  { label: 'Đà Bắc', value: 'Đà Bắc' },
  { label: 'Kim Bôi', value: 'Kim Bôi' },
  { label: 'Lạc Sơn', value: 'Lạc Sơn' },
  { label: 'Lạc Thuỷ', value: 'Lạc Thuỷ' },
  { label: 'Lương Sơn', value: 'Lương Sơn' },
  { label: 'Mai Châu', value: 'Mai Châu' },
  { label: 'Tân Lạc', value: 'Tân Lạc' },
  { label: 'Yên Thuỷ', value: 'Yên Thuỷ' },
  { label: 'Hoà Bình', value: 'Hoà Bình' },
];
const angiangTown = [
  { label: 'Châu Phú', value: 'Châu Phú' },
  { label: 'An Phú', value: 'An Phú' },
  { label: 'Châu Thành', value: 'Châu Thành' },
  { label: 'Chợ Mới', value: 'Chợ Mới' },
  { label: 'Tri Tôn', value: 'Tri Tôn' },
  { label: 'Phú Tân', value: 'Phú Tân' },
  { label: 'Tân Châu', value: 'Tân Châu' },
  { label: 'Thoại Sơn', value: 'Thoại Sơn' },
  { label: 'Tịnh Biên', value: 'Tịnh Biên' },
  { label: 'Long Xuyên', value: 'Long Xuyên' },
  { label: 'Châu Đốc', value: 'Châu Đốc' },
];
const vinhphucTown = [
  { label: 'Bình Xuyên', value: 'Bình Xuyên' },
  { label: 'Lập Thạch', value: 'Lập Thạch' },
  { label: 'Tam Đảo', value: 'Tam Đảo' },
  { label: 'Tam Dương', value: 'Tam Dương' },
  { label: 'Vĩnh Tường', value: 'Vĩnh Tường' },
  { label: 'Yên Lạc', value: 'Yên Lạc' },
  { label: 'Vĩnh Yên', value: 'Vĩnh Yên' },
  { label: 'Phúc Yên', value: 'Phúc Yên' },
  { label: 'Sông Lô', value: 'Sông Lô' },
];
const tayninhTown = [
  { label: 'Bến Cầu', value: 'Bến Cầu' },
  { label: 'Châu Thành', value: 'Châu Thành' },
  { label: 'Dương Minh Châu', value: 'Dương Minh Châu' },
  { label: 'Gò Dầu', value: 'Gò Dầu' },
  { label: 'Hoà Thành', value: 'Hoà Thành' },
  { label: 'Tân Biên', value: 'Tân Biên' },
  { label: 'Tân Châu', value: 'Tân Châu' },
  { label: 'Trảng Bàng', value: 'Trảng Bàng' },
  { label: 'Tây Ninh', value: 'Tây Ninh' },
];
const thainguyenTown = [
  { label: 'Đại Từ', value: 'Đại Từ' },
  { label: 'Định Hoá', value: 'Định Hoá' },
  { label: 'Đồng Hỷ', value: 'Đồng Hỷ' },
  { label: 'Phổ Yên', value: 'Phổ Yên' },
  { label: 'Phú Bình', value: 'Phú Bình' },
  { label: 'Phú Lương', value: 'Phú Lương' },
  { label: 'Võ Nhai', value: 'Võ Nhai' },
  { label: 'Thái Nguyên', value: 'Thái Nguyên' },
  { label: 'Sông Công', value: 'Sông Công' },
];
const laocaiTown = [
  { label: 'Bắc Hà', value: 'Bắc Hà' },
  { label: 'Bảo Thắng', value: 'Bảo Thắng' },
  { label: 'Bảo Yên', value: 'Bảo Yên' },
  { label: 'Bát Xát', value: 'Bát Xát' },
  { label: 'Mường Khương', value: 'Mường Khương' },
  { label: 'Sa Pa', value: 'Sa Pa' },
  { label: 'Văn Bàn', value: 'Văn Bàn' },
  { label: 'Si Ma Cai', value: 'Si Ma Cai' },
  { label: 'Lào Cai', value: 'Lào Cai' },
];
const namdinhTown = [
  { label: 'Giao Thuỷ', value: 'Giao Thuỷ' },
  { label: 'Hải Hậu', value: 'Hải Hậu' },
  { label: 'Mỹ Lộc', value: 'Mỹ Lộc' },
  { label: 'Nam Trực', value: 'Nam Trực' },
  { label: 'Nghĩa Hưng', value: 'Nghĩa Hưng' },
  { label: 'Trục Ninh', value: 'Trục Ninh' },
  { label: 'Vụ Bản', value: 'Vụ Bản' },
  { label: 'Xuân Trường', value: 'Xuân Trường' },
  { label: 'Ý Yên', value: 'Ý Yên' },
  { label: 'Nam Định', value: 'Nam Định' },
];
const quangngaiTown = [
  { label: 'Ba Tơ', value: 'Ba Tơ' },
  { label: 'Bình Sơn', value: 'Bình Sơn' },
  { label: 'Đức Phổ', value: 'Đức Phổ' },
  { label: 'Lý Sơn', value: 'Lý Sơn' },
  { label: 'Minh Long', value: 'Minh Long' },
  { label: 'Mộ Đức', value: 'Mộ Đức' },
  { label: 'Nghĩa Hành', value: 'Nghĩa Hành' },
  { label: 'Sơn Hà', value: 'Sơn Hà' },
  { label: 'Sơn Tây', value: 'Sơn Tây' },
  { label: 'Sơn Tịnh', value: 'Sơn Tịnh' },
  { label: 'Tây Trà', value: 'Tây Trà' },
  { label: 'Trà Bồng', value: 'Trà Bồng' },
  { label: 'Tư Nghĩa', value: 'Tư Nghĩa' },
  { label: 'Quảng Ngãi', value: 'Quảng Ngãi' },
];
const bentreTown = [
  { label: 'Ba Tri', value: 'Ba Tri' },
  { label: 'Bình Đại', value: 'Bình Đại' },
  { label: 'Châu Thành', value: 'Châu Thành' },
  { label: 'Chợ Lách', value: 'Chợ Lách' },
  { label: 'Giồng Trôm', value: 'Giồng Trôm' },
  { label: 'Thạnh Phú', value: 'Thạnh Phú' },
  { label: 'Bến Tre', value: 'Bến Tre' },
  { label: 'Mỏ Cày Bắc', value: 'Mỏ Cày Bắc' },
  { label: 'Mỏ Cày Nam', value: 'Mỏ Cày Nam' },
];
const daknongTown = [
  { label: 'Cư Jút', value: 'Cư Jút' },
  { label: 'Dăk Glong', value: 'Dăk Glong' },
  { label: 'Dăk Mil', value: 'Dăk Mil' },
  { label: "Dăk R'Lấp", value: "Dăk R'Lấp" },
  { label: 'Dăk Song', value: 'Dăk Song' },
  { label: 'Krông Nô', value: 'Krông Nô' },
  { label: 'Tuy Đức', value: 'Tuy Đức' },
  { label: 'Gia Nghĩa', value: 'Gia Nghĩa' },
];
const camauTown = [
  { label: 'Cái Nước', value: 'Cái Nước' },
  { label: 'Đầm Dơi', value: 'Đầm Dơi' },
  { label: 'Năm Căn', value: 'Năm Căn' },
  { label: 'Ngọc Hiển', value: 'Ngọc Hiển' },
  { label: 'Phú Tân', value: 'Phú Tân' },
  { label: 'Thới Bình', value: 'Thới Bình' },
  { label: 'Trần Văn Thời', value: 'Trần Văn Thời' },
  { label: 'U Minh', value: 'U Minh' },
];
const vinhlongTown = [
  { label: 'Bình Minh', value: 'Bình Minh' },
  { label: 'Bình Tân', value: 'Bình Tân' },
  { label: 'Long Hồ', value: 'Long Hồ' },
  { label: 'Mang Thít', value: 'Mang Thít' },
  { label: 'Tam Bình', value: 'Tam Bình' },
  { label: 'Trà Ôn', value: 'Trà Ôn' },
  { label: 'Vũng Liêm', value: 'Vũng Liêm' },
  { label: 'Vĩnh Long', value: 'Vĩnh Long' },
];
const ninhbinhTown = [
  { label: 'Gia Viễn', value: 'Gia Viễn' },
  { label: 'Hoa Lư', value: 'Hoa Lư' },
  { label: 'Kim Sơn', value: 'Kim Sơn' },
  { label: 'Nho Quan', value: 'Nho Quan' },
  { label: 'Yên Khánh', value: 'Yên Khánh' },
  { label: 'Yên Mô', value: 'Yên Mô' },
  { label: 'Ninh Bình', value: 'Ninh Bình' },
  { label: 'Tam Điệp', value: 'Tam Điệp' },
];
const phuthoTown = [
  { label: 'Cẩm Khê', value: 'Cẩm Khê' },
  { label: 'Đoan Hùng', value: 'Đoan Hùng' },
  { label: 'Hạ Hoà', value: 'Hạ Hoà' },
  { label: 'Lâm Thao', value: 'Lâm Thao' },
  { label: 'Phù Ninh', value: 'Phù Ninh' },
  { label: 'Tam Nông', value: 'Tam Nông' },
  { label: 'Tân Sơn', value: 'Tân Sơn' },
  { label: 'Thanh Ba', value: 'Thanh Ba' },
  { label: 'Thanh Sơn', value: 'Thanh Sơn' },
  { label: 'Thanh Thuỷ', value: 'Thanh Thuỷ' },
  { label: 'Yên Lập', value: 'Yên Lập' },
  { label: 'Việt Trì', value: 'Việt Trì' },
];
const ninhthuanTown = [
  { label: 'Bác Ái', value: 'Bác Ái' },
  { label: 'Ninh Hải', value: 'Ninh Hải' },
  { label: 'Ninh Phước', value: 'Ninh Phước' },
  { label: 'Ninh Sơn', value: 'Ninh Sơn' },
  { label: 'Thuận Bắc', value: 'Thuận Bắc' },
  { label: 'Phan Rang - Tháp Chàm', value: 'Phan Rang - Tháp Chàm' },
  { label: 'Thuận Nam', value: 'Thuận Nam' },
];
const phuyenTown = [
  { label: 'Đông Hoà', value: 'Đông Hoà' },
  { label: 'Đồng Xuân', value: 'Đồng Xuân' },
  { label: 'Phú Hoà', value: 'Phú Hoà' },
  { label: 'Sơn Hoà', value: 'Sơn Hoà' },
  { label: 'Sông Cầu', value: 'Sông Cầu' },
  { label: 'Sông Hình', value: 'Sông Hình' },
  { label: 'Tây Hoà', value: 'Tây Hoà' },
  { label: 'Tuy An', value: 'Tuy An' },
  { label: 'Tuy Hoà', value: 'Tuy Hoà' },
];
const hanamTown = [
  { label: 'Bình Lục', value: 'Bình Lục' },
  { label: 'Duy Tiên', value: 'Duy Tiên' },
  { label: 'Kim Bảng', value: 'Kim Bảng' },
  { label: 'Lý Nhân', value: 'Lý Nhân' },
  { label: 'Thanh Liêm', value: 'Thanh Liêm' },
  { label: 'Phủ Lý', value: 'Phủ Lý' },
];
const hatinhTown = [
  { label: 'Cẩm Xuyên', value: 'Cẩm Xuyển' },
  { label: 'Can Lộc', value: 'Can Lộc' },
  { label: 'Đức Thọ', value: 'Đức Thọ' },
  { label: 'Hương Khê', value: 'Hương Khê' },
  { label: 'Hương Sơn', value: 'Hương Sơn' },
  { label: 'Kỳ Anh', value: 'Kỳ Anh' },
  { label: 'Lộc Hà', value: 'Lộc Hà' },
  { label: 'Nghi Xuân', value: 'Nghi Xuân' },
  { label: 'Thạch Hà', value: 'Thạch Hà' },
  { label: 'Vũ Quang', value: 'Vũ Quang' },
];
const dongthapTown = [
  { label: 'Huyện Cao Lãnh', value: 'Huyện Cao Lãnh' },
  { label: 'Châu Thành', value: 'Châu Thành' },
  { label: 'Hồng Ngự', value: 'Hồng Ngự' },
  { label: 'Lai Vung', value: 'Lai Vung' },
  { label: 'Lấp Vò', value: 'Lấp Vò' },
  { label: 'Tam Nông', value: 'Tam Nông' },
  { label: 'Tân Hồng', value: 'Tân Hồng' },
  { label: 'Thanh Bình', value: 'Thanh Bình' },
  { label: 'Tháp Mười', value: 'Tháp Mười' },
  { label: 'Sa Đéc', value: 'Sa Đéc' },
];
const soctrangTown = [
  { label: 'Cù Lao Dung', value: 'Cù Lao Dung' },
  { label: 'Kế Sách', value: 'Kế Sách' },
  { label: 'Long Phú', value: 'Long Phú' },
  { label: 'Mỹ Tú', value: 'Mỹ Tú' },
  { label: 'Mỹ Xuyên', value: 'Mỹ Xuyên' },
  { label: 'Ngã Năm', value: 'Ngã Năm' },
  { label: 'Thạnh Trị', value: 'Thạnh Trị' },
  { label: 'Vĩnh Châu', value: 'Vĩnh Châu' },
  { label: 'Sóc Trăng', value: 'Sóc Trăng' },
  { label: 'Châu Thành', value: 'Châu Thành' },
  { label: 'Trần Đề', value: 'Trần Đề' },
];
const kontumTown = [
  { label: 'Đăk Glei', value: 'Đăk Glei' },
  { label: 'Đăk Hà', value: 'Đăk Hà' },
  { label: 'Đăk Tô', value: 'Đăk Tô' },
  { label: 'Kon Plông', value: 'Kon Plông' },
  { label: 'Kon Rẫy', value: 'Kon Rẫy' },
  { label: 'Ngọc Hồi', value: 'Ngọc Hồi' },
  { label: 'Sa Thầy', value: 'Sa Thầy' },
  { label: 'Tu Mơ Rông', value: 'Tu Mơ Rông' },
  { label: 'KonTum', value: 'KonTum' },
  { label: "la H'Drai", value: "la H'Drai" },
];
const quangbinhTown = [
  { label: 'Bố Trạch', value: 'Bố Trạch' },
  { label: 'Lệ Thuỷ', value: 'Lệ Thuỷ' },
  { label: 'Minh Hoá', value: 'Minh Hoá' },
  { label: 'Quảng Ninh', value: 'Quảng Ninh' },
  { label: 'Quảng Trạch', value: 'Quảng Trạch' },
  { label: 'Tuyên Hoá', value: 'Tuyên Hoá' },
  { label: 'Đồng Hới', value: 'Đồng Hới' },
  { label: 'Ba Đồn', value: 'Ba Đồn' },
];
const quangtriTown = [
  { label: 'Cam Lộ', value: 'Cam Lộ' },
  { label: 'Đa Krông', value: 'Đa Krông' },
  { label: 'Đảo Cồn Cỏ', value: 'Đảo Cồn Cỏ' },
  { label: 'Gio Linh', value: 'Gio Linh' },
  { label: 'Hải Lăng', value: 'Hải Lăng' },
  { label: 'Hướng Hoá', value: 'Hướng Hoá' },
  { label: 'Triệu Phong', value: 'Triệu Phong' },
  { label: 'Vĩnh Linh', value: 'Vĩnh Linh' },
  { label: 'Đông Hà', value: 'Đông Hà' },
  { label: 'Quảng Trị', value: 'Quảng Trị' },
];
const travinhTown = [
  { label: 'Càng Long', value: 'Càng Long' },
  { label: 'Cầu Kè', value: 'Cầu Kè' },
  { label: 'Cầu Ngang', value: 'Cầu Ngang' },
  { label: 'Châu Thành', value: 'Châu Thành' },
  { label: 'Huyện Duyên Hải', value: 'Huyện Duyên Hải' },
  { label: 'Tiểu Cần', value: 'Tiểu Cần' },
  { label: 'Trà Cú', value: 'Trà Cú' },
  { label: 'Trà Vinh', value: 'Trà Vinh' },
  { label: 'Duyên Hải', value: 'Duyên Hải' },
];
const haugiangTown = [
  { label: 'Châu Thành', value: 'Châu Thành' },
  { label: 'Châu Thành A', value: 'Châu Thành A' },
  { label: 'Long Mỹ', value: 'Long Mỹ' },
  { label: 'Phụng Hiệp', value: 'Phụng Hiệp' },
  { label: 'Vị Thuỷ', value: 'Vị Thuỷ' },
  { label: 'Ngã Bảy', value: 'Ngã Bảy' },
  { label: 'Vị Thanh', value: 'Vị Thanh' },
  { label: 'Thị Xã Long Mỹ', value: 'Thị Xã Long Mỹ' },
];
const sonlaTown = [
  { label: 'Bắc Yên', value: 'Bắc Yên' },
  { label: 'Mai Sơn', value: 'Mai Sơn' },
  { label: 'Mộc Châu', value: 'Mộc Châu' },
  { label: 'Mường La', value: 'Mường La' },
  { label: 'Phù Yên', value: 'Phù Yên' },
  { label: 'Quỳnh Nhai', value: 'Quỳnh Nhai' },
  { label: 'Sông Mã', value: 'Sông Mã' },
  { label: 'Sốp Cộp', value: 'Sốp Cộp' },
  { labe: 'Thuận Châu', value: 'Thuận Châu' },
  { label: 'Yên Châu', value: 'Yên Châu' },
  { label: 'Sơn La', value: 'Sơn La' },
  { label: 'Vân Hồ', value: 'Vân Hồ' },
];
const baclieuTown = [
  { label: 'Đông Hải', value: 'Đông Hải' },
  { label: 'Giá Rai', value: 'Giá Rai' },
  { label: 'Hoà Binh', value: 'Hoà Binh' },
  { label: 'Hồng Dân', value: 'Hồng Dân' },
  { label: 'Phước Long', value: 'Phước Long' },
  { label: 'Vĩnh Lợi', value: 'Vĩnh Lợi' },
  { label: 'Bạc Liêu', value: 'Bạc Liêu' },
];
const yenbaiTown = [
  { label: 'Lục Yên', value: 'Lục Yên' },
  { label: 'Mù Cang Chải', value: 'Mù Cang Chải' },
  { label: 'Trạm Tấu', value: 'Trạm Tấu' },
  { label: 'Trấn Yên', value: 'Trấn Yên' },
  { label: 'Văn Chấn', value: 'Văn Chấn' },
  { label: 'Văn Yên', value: 'Văn Yên' },
  { label: 'Yên Bình', value: 'Yên Bình' },
  { label: 'Yên Bái', value: 'Yên Bái' },
  { label: 'Nghĩa Lộ', value: 'Nghĩa Lộ' },
];
const tuyenquangTown = [
  { label: 'Chiêm Hoá', value: 'Chiêm Hoá' },
  { label: 'Hàm Yên', value: 'Hàm Yên' },
  { label: 'Na Hang', value: 'Na Hang' },
  { label: 'Sơn Dương', value: 'Sơn Dương' },
  { label: 'Yên Sơn', value: 'Yên Sơn' },
  { label: 'Tuyên Quang', value: 'Tuyên Quang' },
  { label: 'Lâm Bình', value: 'Lâm Bình' },
];
const dienbienTown = [
  { label: 'Điện Biên', value: 'Điện Biên' },
  { label: 'Điên Biên Đông', value: 'Điên Biên Đông' },
  { label: 'Mường Ảng', value: 'Mường Ảng' },
  { label: 'Mường Chà', value: 'Mường Chà' },
  { label: 'Mường Nhé', value: 'Mường Nhé' },
  { label: 'Tủa Chùa', value: 'Tủa Chùa' },
  { label: 'Tuần Giáo', value: 'Tuần Giáo' },
  { label: 'Điện Biên Phủ', value: 'Điện Biên Phủ' },
  { label: 'Mường Lay', value: 'Mường Lay' },
  { label: 'Nậm Pồ', value: 'Nậm Pồ' },
];
const laichauTown = [
  { label: 'Điện Biên', value: 'Điện Biên' },
  { label: 'Điên Biên Đông', value: 'Điên Biên Đông' },
  { label: 'Mường Ảng', value: 'Mường Ảng' },
  { label: 'Mường Chà', value: 'Mường Chà' },
  { label: 'Mường Nhé', value: 'Mường Nhé' },
  { label: 'Tủa Chùa', value: 'Tủa Chùa' },
  { label: 'Tuần Giáo', value: 'Tuần Giáo' },
  { label: 'Điện Biên Phủ', value: 'Điện Biên Phủ' },
  { label: 'Mường Lay', value: 'Mường Lay' },
  { label: 'Nậm Pồ', value: 'Nậm Pồ' },
];
const langsonTown = [
  { label: 'Bắc Sơn', value: 'Bắc Sơn' },
  { label: 'Bình Gia', value: 'Bình Gia' },
  { label: 'Cao Lộc', value: 'Cao Lộc' },
  { label: 'Chi Lăng', value: 'Chi Lăng' },
  { label: 'Đình Lập', value: 'Đình Lập' },
  { label: 'Hữu Lũng', value: 'Hữu Lũng' },
  { label: 'Lộc Bình', value: 'Lộc Bình' },
  { label: 'Tràng Định', value: 'Tràng Định' },
  { label: 'Văn Lãng', value: 'Văn Lãng' },
  { label: 'Văn Quan', value: 'Văn Quan' },
];
const hagiangTown = [
  { label: 'Bắc Mê', value: 'Bắc Mê' },
  { label: 'Bắc Quang', value: 'Bắc Quang' },
  { label: 'Đồng Văn', value: 'Đồng Văn' },
  { label: 'Hoàng Su Phì', value: 'Hoàng Su Phì' },
  { label: 'Mèo Vạc', value: 'Mèo Vạc' },
  { label: 'Quản Bạ', value: 'Quản Bạ' },
  { label: 'Quang Bình', value: 'Quang Bình' },
  { label: 'Vị Xuyên', value: 'Vị Xuyên' },
  { label: 'Xín Mần', value: 'Xín Mần' },
  { label: 'Yên Minh', value: 'Yên Minh' },
];
const backanTown = [
  { label: 'Ba Bể', value: 'Ba Bể' },
  { label: 'Bạch Thông', value: 'Bạch Thông' },
  { label: 'Chợ Đồn', value: 'Chợ Đồn' },
  { label: 'Chợ Mới', value: 'Chợ Mới' },
  { label: 'Na Rì', value: 'Na Rì' },
  { label: 'Ngân Sơn', value: 'Ngân Sơn' },
  { label: 'Pác Nặm', value: 'Pác Nặm' },
];
const caobangTown = [
  { label: 'Bảo Lạc', value: 'Bảo Lạc' },
  { label: 'Bảo Lâm', value: 'Bảo Lâm' },
  { label: 'Hạ Lang', value: 'Hạ Lang' },
  { label: 'Hà Quảng', value: 'Hà Quảng' },
  { label: 'Hoà An', value: 'Hoà An' },
  { label: 'Nguyên Bình', value: 'Ngân Sơn' },
  { label: 'Phụ Hoà', value: 'Phụ Hoà' },
  { label: 'Quảng Uyên', value: 'Quảng Uyên' },
  { label: 'Thạch An', value: 'Thạch An' },
  { label: 'Thông Nông', value: 'Thông Nông' },
];
const vietnamCity = [
  { label: 'Hồ Chí Minh', value: 'Hồ Chí Minh' },
  { label: 'Hà Nội', value: 'Hà Nội' },
  { label: 'Đà Nẵng', value: 'Đà Nẵng' },
  { label: 'Bình Dương', value: 'Bình Dương' },
  { label: 'Đồng Nai', value: 'Đồng Nai' },
  { label: 'Khánh Hoà', value: 'Khánh Hoà' },
  { label: 'Hải Phòng', value: 'Hải Phòng' },
  { label: 'Long An', value: 'Long An' },
  { label: 'Quảng Nam', value: 'Quảng Nam' },
  { label: 'Bà Rịa Vũng Tàu', value: 'Bà Rịa Vũng Tàu' },
  { label: 'Đắk Lắk', value: 'Đắk Lắk' },
  { label: 'Cần Thơ', value: 'Cần Thơ' },
  { label: 'Bình Thuận', value: 'Bình Thuận' },
  { label: 'Lâm Đồng', value: 'Lâm Đồng' },
  { label: 'Thừa Thiên Huế', value: 'Thừa Thiên Huế' },
  { label: 'Kiên Giang', value: 'Kiên Giang' },
  { label: 'Bắc Ninh', value: 'Bắc Ninh' },
  { label: 'Quảng Ninh', value: 'Quảng Ninh' },
  { label: 'Thanh Hoá', value: 'Thanh Hoá' },
  { label: 'Nghệ An', value: 'Nghệ An' },
  { label: 'Hải Dương', value: 'Hải Dương' },
  { label: 'Gia Lai', value: 'Gia Lai' },
  { label: 'Bình Phước', value: 'Bình Phước' },
  { label: 'Hưng Yên', value: 'Hưng Yên' },
  { label: 'Bình Định', value: 'Bình Định' },
  { label: 'Tiền Giang', value: 'Tiền Giang' },
  { label: 'Thái Bình', value: 'Thái Bình' },
  { label: 'Bắc Giang', value: 'Bắc Giang' },
  { label: 'Hoà Bình', value: 'Hoà Bình' },
  { label: 'An Giang', value: 'An Giang' },
  { label: 'Vĩnh Phúc', value: 'Vĩnh Phúc' },
  { label: 'Tây Ninh', value: 'Tây Ninh' },
  { label: 'Thái Nguyên', value: 'Thái Nguyên' },
  { label: 'Lào Cai', value: 'Lào Cai' },
  { label: 'Nam Định', value: 'Nam Định' },
  { label: 'Quảng Ngãi', value: 'Quảng Ngãi' },
  { label: 'Bến Tre', value: 'Bến Tre' },
  { label: 'Đắk Nông', value: 'Đắk Nông' },
  { label: 'Cà Mau', value: 'Cà Mau' },
  { label: 'Vĩnh Long', value: 'Vĩnh Long' },
  { label: 'Ninh Bình', value: 'Ninh Bình' },
  { label: 'Phú Thọ', value: 'Phú Thọ' },
  { label: 'Ninh Thuận', value: 'Ninh Thuận' },
  { label: 'Phú Yên', value: 'Phú Yên' },
  { label: 'Hà Nam', value: 'Hà Nam' },
  { label: 'Hà Tĩnh', value: 'Hà Tĩnh' },
  { label: 'Đồng Tháp', value: 'Đồng Tháp' },
  { label: 'Sóc Trăng', value: 'Sóc Trăng' },
  { label: 'Kon Tum', value: 'Kon Tum' },
  { label: 'Quảng Bình', value: 'Quảng Bình' },
  { label: 'Quảng Trị', value: 'Quảng Trị' },
  { label: 'Trà Vinh', value: 'Trà Vinh' },
  { label: 'Hậu Giang', value: 'Hậu Giang' },
  { label: 'Sơn La', value: 'Sơn La' },
  { label: 'Bạc Liêu', value: 'Bạc Liêu' },
  { label: 'Yên Bái', value: 'Yên Bái' },
  { label: 'Tuyên Quang', value: 'Tuyên Quang' },
  { label: 'Điện Biên', value: 'Điện Biên' },
  { label: 'Lai Châu', value: 'Lai Châu' },
  { label: 'Lạng Sơn', value: 'Lạng Sơn' },
  { label: 'Hà Giang', value: 'Hà Giang' },
  { label: 'Bắc Kạn', value: 'Bắc Kạn' },
  { label: 'Cao Bằng', value: 'Cao Bằng' },
];
