import { CircularProgress, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { CiLocationOn } from 'react-icons/ci';
import { HiOutlineClipboardDocument, HiOutlineViewfinderCircle } from 'react-icons/hi2';
import { IoMdPricetags } from 'react-icons/io';
import { RiLockPasswordLine } from 'react-icons/ri';
import LoginHeader from './components/LoginHeader';
import { BiLogIn, BiSolidMessageRoundedError } from 'react-icons/bi';

import { motion } from 'framer-motion';
import shippingworld from './shippingworld.png';
import shippingshield from './shippingshield.png';
import shippingMain from './shippingMain.jpg';
import skydartMain2 from './skydartMain2.jpg';
import skydartMain3 from './skydartMain3.jpg';
import shippingwarehouse from './shippingwarehouse.png';
import shippingone from './shippingone.png';
import {
  MdEditDocument,
  MdNearbyError,
  MdNotificationImportant,
  MdOutlineClear,
  MdOutlineSupportAgent,
  MdTrackChanges,
} from 'react-icons/md';
import { PiFolderStarThin, PiPackage } from 'react-icons/pi';
import { BsUiChecksGrid } from 'react-icons/bs';
import { GrMultiple } from 'react-icons/gr';
import { countries } from '../staff/api/countries';
import { FaEarthAsia, FaSortDown, FaTruckPickup } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../server/firebase';
import { FiPackage } from 'react-icons/fi';
import OutsideClickHandler from 'react-outside-click-handler';
import { GoPackage, GoPackageDependencies } from 'react-icons/go';
export default function Login() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorContent, setErrorContent] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rate, setRate] = useState('');
  const [rateResult, setRateResult] = useState('');
  const [doxRate, setDoxRate] = useState('');
  const [currentStatus, setCurrentStatus] = useState('Track shipments');
  const navigate = useNavigate();
  const [trackInput, setTrackInput] = useState('');
  const [locationInput, setLocationInput] = useState('');
  const [countryPricing, setCountryPricing] = useState('');
  const [codePricing, setCodePricing] = useState('');
  const [weightPricing, setWeightPricing] = useState('');
  const [servicePricing, setServicePricing] = useState('');
  const [typePricing, setTypePricing] = useState('WPX');
  const [trackResult, setTrackResult] = useState([]);
  const [displayPricing, setDisplayPricing] = useState(false);
  const [displayCountry, setDisplayCountry] = useState(false);
  const [displayResult, setDisplayResult] = useState(false);
  const [loadingResult, setLoadingResult] = useState(false);
  const [pricingData, setPricingData] = useState({});
  const [fetchShipment, setFetchShipment] = useState([]);
  const mainArray = [
    { label: 'Login', id: 2, icon: <GoPackageDependencies size={65} /> },
    { label: 'Track shipments', id: 1, icon: <HiOutlineViewfinderCircle size={65} /> },
    { label: 'Pricing', id: 3, icon: <IoMdPricetags size={65} /> },
  ];

  const serviceArray = [
    {
      label: 'Flexible and smoothly pick up',
      id: 1,
      description:
        'Create label for your shipment and start bringing it to your friends, customers, and people you care about',
      icon: <FaTruckPickup size={30} />,
    },
    {
      label: 'Comfort & lean shipping tools',
      id: 2,
      description:
        "Manage all of your shipments with Skydart's tools, best shipping experience is our goal for both sender and receiver",
      icon: <BsUiChecksGrid size={30} />,
    },
    {
      label: 'Multiple shipment created',
      id: 3,
      description:
        'Create label for your shipment and start bringing it to your friends, customers, and people you care about',
      icon: <GrMultiple size={30} />,
    },
    {
      label: 'Friendly, fast and devoted cares',
      icon: <MdOutlineSupportAgent size={30} />,
      id: 4,
      description:
        "We always listen, understand and sympathize your needs and optimal the way we handle and advise to you and improve it' quality every day",
    },
    {
      label: 'Real time shipment tracked',
      icon: <MdTrackChanges size={30} />,
      id: 5,
      description:
        'Get on track with your packages in real time, to see when your friend and family will receive the gift!',
    },
    {
      label: 'Reasonable and affordable price',
      icon: <IoMdPricetags size={30} />,
      id: 6,
      description:
        'As a shipping center, we are offering leading prices for our partners to exploit more shipments together',
    },
  ];
  const onElementJumping = {
    initial: {
      y: 0,
    },
    animate: {
      y: [0, -15, 0],
      transition: {
        duration: 1.5,
        repeat: Infinity,
      },
    },
  };
  const handleCountryChange = (e) => {
    setCountryPricing(e.target.value);
  };
  const handleWeightChange = (event) => {
    const inputWeight = event.target.value;
    setWeightPricing(inputWeight);
  };
  const handleServiceChange = (e) => {
    setServicePricing(e.target.value);
  };

  const signIn = (e) => {
    e.preventDefault();
    setLoading(true);
    auth.signInWithEmailAndPassword(email, password).catch((err) => {
      setError(true);
      setErrorContent(err.toString().replaceAll('Firebase', 'Skydart'));
      setLoading(false);
    });
  };
  const trackingArray = trackInput.split(/\r?\n/);
  useEffect(() => {
    let arr = [];
    trackingArray.map((s) => {
      arr.push(s);
    });
    const filterArr = arr.filter((s) => {
      return s !== '';
    });
    setTrackResult(filterArr.toString());
  }, [trackingArray]);
  //   const filterTrackResult = trackResult?.filter((s) => {
  //     return s !== '';
  //   });

  const handleTrackShipment = () => {
    if (trackInput === '') return;
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate(`/tracking-result/${trackResult}`, '_blank');
    }, 2000);
  };

  const handleServeArea = () => {};
  //   const handleServeArea = async () => {
  //     if (locationInput === '') {
  //       return;
  //     }

  //     setLoading(true);

  //     try {
  //       const snapshot = await db.collection('shipments').where('toCountry', '==', locationInput).get();

  //       if (snapshot.empty) {
  //         alert('We have not found any shipments that are delivering to this area');
  //         setLoading(false);
  //         return;
  //       }

  //       const shipmentData = snapshot.docs.map((doc) => doc.data());
  //       setFetchShipment(shipmentData);
  //       console.log(shipmentData);
  //       setDisplayResult(true);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error('Error retrieving shipment data:', error);
  //       setLoading(false);
  //     }
  //   };

  const handlePricingShipment = () => {
    if (countryPricing === '') {
      return;
    } else if (weightPricing === '') {
      return;
    } else if (servicePricing === '') {
      return;
    } else {
      // setLoading(true);
      // const serviceCheck =
      //   servicePricing === 'SDH'
      //     ? 'dhl'
      //     : servicePricing === 'FIP'
      //       ? 'fip'
      //       : servicePricing === 'F3P'
      //         ? 'f3p'
      //         : servicePricing === 'FIE'
      //           ? 'fie'
      //           : servicePricing === 'DUS'
      //             ? 'dus'
      //             : '';
      // db.collection(serviceCheck)
      //   .doc(countryPricing)
      //   .get()
      //   .then((doc) => {
      //     if (doc.exists) {
      //       setLoading(false);
      //       setDisplayPricing(true);
      //       setPricingData(doc.data());
      //     } else {
      //       setError(true);
      //       setErrorContent('Can not find data as recent submit');
      //     }
      //   })
      //   .catch((err) => {
      //     setError(true);
      //     setErrorContent(err.toString().replaceAll('Firebase', 'Skydart'));
      //   });
      return;
    }
  };
  useEffect(() => {
    if (typePricing == 'DOX') {
      if (weightPricing == 0) {
        setDoxRate(0);
      } else if (weightPricing == 0.5) {
        setDoxRate(pricingData.doxHalf);
      } else if (weightPricing == 1) {
        setDoxRate(pricingData.doxOne);
      } else if (weightPricing == 1.5) {
        setDoxRate(pricingData.doxOneHalf);
      } else if (weightPricing == 2) {
        setDoxRate(pricingData.doxTwo);
      }
    } else if (typePricing == 'WPX') {
      if (weightPricing == 0) {
        setRate(0);
      } else if (weightPricing == 0.5) {
        setRate(pricingData.half);
      } else if (weightPricing == 1) {
        setRate(pricingData.one);
      } else if (weightPricing == 1.5) {
        setRate(pricingData.oneHalf);
      } else if (weightPricing == 2) {
        setRate(pricingData.two);
      } else if (weightPricing == 2.5) {
        setRate(pricingData.twoHalf);
      } else if (weightPricing == 3) {
        setRate(pricingData.three);
      } else if (weightPricing == 3.5) {
        setRate(pricingData.threeHalf);
      } else if (weightPricing == 4) {
        setRate(pricingData.four);
      } else if (weightPricing == 4.5) {
        setRate(pricingData.fourHalf);
      } else if (weightPricing == 5) {
        setRate(pricingData.five);
      } else if (weightPricing == 5.5) {
        setRate(pricingData.fiveHalf);
      } else if (weightPricing == 6) {
        setRate(pricingData.six);
      } else if (weightPricing == 6.5) {
        setRate(pricingData.sixHalf);
      } else if (weightPricing == 7) {
        setRate(pricingData.seven);
      } else if (weightPricing == 7.5) {
        setRate(pricingData.sevenHalf);
      } else if (weightPricing == 8) {
        setRate(pricingData.eight);
      } else if (weightPricing == 8.5) {
        setRate(pricingData.eightHalf);
      } else if (weightPricing == 9) {
        setRate(pricingData.nine);
      } else if (weightPricing == 9.5) {
        setRate(pricingData.nineHalf);
      } else if (weightPricing == 10) {
        setRate(pricingData.ten);
      } else if (weightPricing == 10.5) {
        setRate(pricingData.tenHalf);
      } else if (weightPricing == 11) {
        setRate(pricingData.eleven);
      } else if (weightPricing == 11.5) {
        setRate(pricingData.elevenHalf);
      } else if (weightPricing == 12) {
        setRate(pricingData.twelve);
      } else if (weightPricing == 12.5) {
        setRate(pricingData.twelveHalf);
      } else if (weightPricing == 13) {
        setRate(pricingData.thirteen);
      } else if (weightPricing == 13.5) {
        setRate(pricingData.thirteenHalf);
      } else if (weightPricing == 14) {
        setRate(pricingData.fourteen);
      } else if (weightPricing == 14.5) {
        setRate(pricingData.fourteenHalf);
      } else if (weightPricing == 15) {
        setRate(pricingData.fifteen);
      } else if (weightPricing == 15.5) {
        setRate(pricingData.fifteenHalf);
      } else if (weightPricing == 16) {
        setRate(pricingData.sixteen);
      } else if (weightPricing == 16.5) {
        setRate(pricingData.sixteenHalf);
      } else if (weightPricing == 17) {
        setRate(pricingData.seventeen);
      } else if (weightPricing == 17.5) {
        setRate(pricingData.seventeenHalf);
      } else if (weightPricing == 18) {
        setRate(pricingData.eightteen);
      } else if (weightPricing == 18.5) {
        setRate(pricingData.eightteenHalf);
      } else if (weightPricing == 19) {
        setRate(pricingData.nineteen);
      } else if (weightPricing == 19.5) {
        setRate(pricingData.nineteenHalf);
      } else if (weightPricing == 20) {
        setRate(pricingData.twenty);
      } else if (weightPricing > 20 && weightPricing < 50) {
        setRate(pricingData.small * weightPricing);
      } else if (weightPricing > 49 && weightPricing < 69) {
        setRate(pricingData.medium * weightPricing);
      } else if (weightPricing > 68 && weightPricing < 301) {
        setRate(pricingData.large * weightPricing);
      }
    }
  }, [pricingData]);

  const activeCss =
    'w-[180px] h-[180px] rounded-[7px] flex items-center justify-center flex-col bg-[darkblue] text-white shadow-[rgba(67,71,85,0.27)_0px_0px_0.25em,rgba(90,125,188,0.05)_0px_0.25em_1em] scale-[1.2] cursor-pointer transition-[0.1s] z-10';

  const inActiveCss =
    'w-[180px] h-[180px] rounded-[7px] flex items-center justify-center flex-col bg-white shadow-[rgba(67,71,85,0.27)_0px_0px_0.25em,rgba(90,125,188,0.05)_0px_0.25em_1em] hover:bg-slate-200 active:bg-slate-300 cursor-pointer transition-[0.1s]';
  return (
    <div className="w-full">
      {displayPricing && (
        <div className="fixed top-0 bottom-0 right-0 left-0 bg-[rgba(255,255,255,0.75)] flex items-center justify-center flex-col z-[9999]">
          <OutsideClickHandler
            onOutsideClick={() => {
              setDisplayPricing(false);
            }}
          >
            <div className="bg-white w-[550px] overflow-y-auto rounded-[10px] shadow-[rgba(0,0,0,0.25)_0px_0.0625em_0.0625em,rgba(0,0,0,0.25)_0px_0.125em_0.5em,rgba(255,255,255,0.1)_0px_0px_0px_1px_inset] flex flex-col">
              <div
                className="p-2 flex items-center justify-between"
                style={{ borderBottom: '1px solid rgb(230,230,230)' }}
              >
                <p className="text-xl font-semibold tracking-wide">Quote result</p>
                <div
                  className="w-[35px] h-[35px] rounded-[10px] bg-slate-100 flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 active:scale-90 cursor-pointer"
                  onClick={() => {
                    setDisplayPricing(false);
                    setDoxRate(0);
                    setRate(0);
                  }}
                >
                  <MdOutlineClear size={30} />
                </div>
              </div>
              <div className="p-2 flex flex-col gap-y-1.5">
                <div className="mb-2.5 rounded-[10px] bg-amber-50 flex">
                  <div
                    className="bg-amber-500 text-white p-1.5"
                    style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
                  >
                    <MdNotificationImportant size={28} />
                  </div>
                  <div className="ml-2.5 py-1">
                    <p className="text-amber-600 font-semibold text-lg">Important warning</p>
                    <p className="text-[15px] pb-1">
                      Shipping quote could be better if you join us and become our partner. We offer different kind of
                      special deal if you send shipments regularly. Skydart wish you all the best during your shipping
                      journey.
                    </p>
                  </div>
                </div>
                <p className="font-semibold text-xl text-[darkblue]">Country: {countryPricing}</p>
                <p className="font-semibold">Shipment Type: {typePricing}</p>
                <p className="text-slate-600">Services: {servicePricing}</p>
                <p className="text-slate-600">Weight: {weightPricing} KG</p>
                <p className="font-semibold text-emerald-600">
                  Estimated total shipping quote:{' '}
                  <span className="underline text-2xl">{typePricing === 'WPX' ? rate : doxRate} USD</span>
                </p>
                <p className="font-semibold text-emerald-600">
                  Unite quote:{' '}
                  <span className="underline">
                    {typePricing === 'WPX' ? (rate / weightPricing).toFixed(2) : (doxRate / weightPricing).toFixed(2)}{' '}
                    USD / per KG
                  </span>
                </p>
                <p className="font-semibold text-[purple]">
                  Estimated shipping days: <span className="underline">3 - 7 business days</span>
                </p>
                {typePricing === 'DOX' && weightPricing > 2 && (
                  <div className="mt-2.5 rounded-[10px] bg-rose-50 flex">
                    <div
                      className="bg-rose-500 text-white p-1.5"
                      style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
                    >
                      <BiSolidMessageRoundedError size={28} />
                    </div>
                    <div className="ml-2.5 py-1">
                      <p className="text-rose-600 font-semibold text-lg">Something is wrong</p>
                      <p className="text-[15px] pb-1">
                        Document are only available from 0kg to 2kg, please double check your object.
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="p-2 flex items-center sticky bottom-0 z-10 bg-white mt-auto gap-x-2.5">
                <div
                  className="p-1.5 bg-slate-100 hover:bg-slate-200 active:bg-slate-300 font-semibold rounded-[10px] px-3.5 cursor-pointer w-[50%] text-center"
                  onClick={() => {
                    setDisplayPricing(false);
                  }}
                >
                  <p>Understood, go back</p>
                </div>
                <div
                  className="p-1.5 bg-emerald-500 hover:bg-emerald-600 active:bg-emerald-700 font-semibold rounded-[10px] px-3.5 cursor-pointer text-white w-[50%] text-center"
                  onClick={() => navigate('/login')}
                >
                  <p>Ship now</p>
                </div>
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      )}
      {loading && (
        <div className="fixed top-0 bottom-0 right-0 left-0 bg-[rgba(255,255,255,0.75)] flex items-center justify-center flex-col z-[9999] gap-y-1.5">
          <CircularProgress size={35} style={{ color: 'black' }} />
          <p className="mt-1 font-semibold text-xl text-black">Loading</p>
        </div>
      )}
      {error && (
        <div className="fixed top-0 bottom-0 right-0 left-0 bg-[rgba(255,255,255,0.75)] flex items-center justify-center flex-col z-[9999] gap-y-1.5">
          <MdNearbyError size={35} style={{ color: 'red' }} />
          <p className="mt-1 font-semibold text-xl text-rose-500">{errorContent}</p>
          <button
            type="button"
            className="p-2 px-3.5 mt-2 bg-white hover:bg-slate-300 rounded-[7px] font-semibold active:bg-slate-400"
            onClick={() => {
              setError(false);
              setErrorContent('');
            }}
          >
            Try again
          </button>
        </div>
      )}
      <div className="bg-gradient-to-r from-slate-100 to-slate-200 flex p-7 items-center justify-center flex-col">
        <div className="flex items-center justify-center mt-5">
          <img
            src={shippingwarehouse}
            alt=""
            loading="lazy"
            className="w-[100px] responsive-hidden h-[100px] object-cover mr-[85px]"
          />
          <p className=" text-3xl font-semibold tracking-wide skydart-main-primary-left-header1">
            Suitable express shipping solution for everyone
          </p>
          <img
            src={shippingone}
            alt=""
            loading="lazy"
            className="w-[100px] h-[100px] responsive-hidden object-cover ml-[85px]"
          />
        </div>
        <p className="mb-5 text-slate-500">
          Get started now by to day with best price{' '}
          {/* <span className="text-black underline font-semibold">(FSC: 23.54)</span> */}
        </p>
        <div className="flex items-center gap-x-2.5 my-2.5 responsive-hidden">
          <div className="relative mr-5">
            <img src={shippingworld} alt="" loading="lazy" className="w-[230px] h-[230px] object-cover" />
            <img
              src={`https://flagsapi.com/VN/shiny/64.png`}
              className="w-[30px] h-[30px] absolute top-[-5px] left-[110px]"
            />
            <img
              src={`https://flagsapi.com/US/shiny/64.png`}
              className="w-[30px] h-[30px] absolute bottom-[93px] left-[60px]"
            />
            <img
              src={`https://flagsapi.com/CA/shiny/64.png`}
              className="w-[30px] h-[30px] absolute top-7 left-[75px]"
            />
            <div className="absolute top-[90px] left-[95px] bg-white rounded-[3px] shadow-[rgba(0,0,0,0.18)_0px_2px_4px]">
              <p className="text-[9px] px-1.5">200 mores</p>
            </div>
            <img
              src={`https://flagsapi.com/AU/shiny/64.png`}
              className="w-[30px] h-[30px] absolute bottom-[70px] right-7"
            />
            <img
              src={`https://flagsapi.com/SG/shiny/64.png`}
              className="w-[30px] h-[30px] absolute top-[50px] right-[50px]"
            />
          </div>

          {mainArray.map((item) => (
            <div
              className={currentStatus === item.label ? activeCss : inActiveCss}
              onClick={() => {
                setCurrentStatus(item.label);
              }}
            >
              {item.label === currentStatus && (
                <motion.div variants={onElementJumping} initial="initial" animate="animate">
                  {item.icon}
                </motion.div>
              )}
              {item.label !== currentStatus && <div>{item.icon}</div>}
              <p className="text-lg font-semibold tracking-wide mt-1.5">{item.label}</p>
            </div>
          ))}
          <img src={shippingshield} alt="" loading="lazy" className="w-[230px] h-[230px] object-cover ml-5" />
        </div>
        <div className="login1 my-3 responsive-hidden">
          {currentStatus === 'Track shipments' && (
            <TextField
              multiline
              label="Tracking number"
              value={trackInput}
              onChange={(e) => setTrackInput(e.target.value)}
              placeholder="Enter up to 20 shipments, seperate by break the line"
              className="bg-white w-[562px] text-[20px]"
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <button
                      type="button"
                      className="bg-emerald-600 text-white p-2.5 rounded-[5px] px-3.5 hover:bg-emerald-500 active:bg-emerald-400 font-semibold"
                      onClick={handleTrackShipment}
                    >
                      Track now
                    </button>
                  </InputAdornment>
                ),
              }}
            />
          )}
          {currentStatus === 'Login' && (
            <form className="flex flex-col my-2.5 items-center" onSubmit={signIn}>
              <input
                type="text"
                autoFocus
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                className="p-2 w-[350px] bg-white rounded-full shadow-[rgba(0,0,0,0.05)_0px_3px_3px_0px] outline-none px-[20px] search-column-input border-solid border-slate-300 border-[1px]"
                placeholder="Email address"
              />
              <input
                type="password"
                className="p-2 w-[350px] bg-white rounded-full shadow-[rgba(0,0,0,0.05)_0px_3px_3px_0px] outline-none px-[20px] search-column-input border-solid border-slate-300 border-[1px] mt-2"
                required
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="submit"
                className="p-2 w-[250px] bg-[darkblue] font-semibold tracking-wide text-white rounded-[12px] text-[18px] mt-3 hover:opacity-70 active:opacity-50 cursor-pointer"
              >
                Login
              </button>

              <p
                className="mt-3 cursor-pointer w-fit hover:underline active:opacity-50"
                onClick={() => navigate('/registration')}
              >
                Create a shipping account
              </p>
            </form>
          )}
          {currentStatus === 'Pricing' && (
            <div className="flex flex-col gap-y-[10px]">
              <div className="flex items-center mb-2 justify-center gap-x-2">
                <div
                  className="flex items-center bg-white rounded-[10px] p-2 w-[150px] hover:bg-slate-200 active:bg-slate-300 select-none cursor-pointer"
                  onClick={() => {
                    setTypePricing('WPX');
                  }}
                >
                  <FiPackage size={30} />
                  <p className="font-semibold text-xl ml-1.5">WPX</p>
                  <div className="w-[25px] h-[25px] rounded-full border-solid border-slate-300 border-[1px] flex items-center justify-center ml-auto">
                    {typePricing === 'WPX' && (
                      <div className="w-[15px] h-[15px] rounded-full bg-sky-600 flex items-center justify-center"></div>
                    )}
                  </div>
                </div>
                <div
                  className="flex items-center bg-white rounded-[10px] p-2 w-[150px] hover:bg-slate-200 active:bg-slate-300 select-none cursor-pointer"
                  onClick={() => {
                    setTypePricing('DOX');
                  }}
                >
                  <HiOutlineClipboardDocument size={25} />
                  <p className="font-semibold text-xl ml-1.5">DOX</p>
                  <div className="w-[25px] h-[25px] rounded-full border-solid border-slate-300 border-[1px] flex items-center justify-center ml-auto">
                    {typePricing === 'DOX' && (
                      <div className="w-[15px] h-[15px] rounded-full bg-sky-600 flex items-center justify-center"></div>
                    )}
                  </div>
                </div>
              </div>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setDisplayCountry(false);
                }}
              >
                <div className="relative">
                  <TextField
                    label="Location"
                    onFocus={() => {
                      setDisplayCountry(true);
                    }}
                    placeholder="Enter a country to see if Skydart does serve the area"
                    className="bg-white w-[562px] text-[20px]"
                    autoComplete="off"
                    value={countryPricing}
                    onChange={handleCountryChange}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {codePricing && (
                            <img
                              src={`https://flagsapi.com/${codePricing}/shiny/64.png`}
                              className="w-[30px] h-[30px] mr-5"
                            />
                          )}
                          {!codePricing && <FaEarthAsia size={23} className="mr-5" />}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <FaSortDown size={30} className="mt-[-12px]" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {displayCountry && (
                    <div className="absolute bg-white shadow-[rgba(0,0,0,0.18)_0px_2px_4px] z-10 max-h-[250px] overflow-y-auto mt-1.5 rounded-[10px] w-full">
                      {countries
                        .filter((s) => {
                          return s.label.toLowerCase().startsWith(countryPricing.toLowerCase());
                        })
                        .map((item, index) => (
                          <div
                            className="p-2 flex items-center hover:bg-slate-100 active:bg-slate-200 cursor-pointer"
                            key={index}
                            onClick={() => {
                              setCountryPricing(item.label);
                              setCodePricing(item.code);
                              setDisplayCountry(false);
                            }}
                          >
                            <img
                              src={`https://flagsapi.com/${item.code}/shiny/64.png`}
                              className="w-[30px] h-[30px] mr-5 ml-[5px]"
                            />
                            <p>{item.label}</p>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </OutsideClickHandler>
              <TextField
                label="Weight (KG)"
                placeholder="Enter your estimated weight"
                className="bg-white w-[562px] text-[20px]"
                autoComplete="off"
                value={weightPricing}
                onChange={handleWeightChange}
                size="small"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <p className="font-semibold text-black">KG</p>
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl size="small">
                <InputLabel>Services</InputLabel>
                <Select
                  label="Services"
                  className="bg-white w-[562px] text-[20px]"
                  value={servicePricing}
                  onChange={handleServiceChange}
                >
                  <MenuItem value="SDH">SDH</MenuItem>
                  <MenuItem value="FIP">FIP</MenuItem>
                  <MenuItem value="F3P">F3P</MenuItem>
                  <MenuItem value="FIE">FIE</MenuItem>
                  <MenuItem value="DUS">DUS</MenuItem>
                </Select>
              </FormControl>
              <p className="text-center">Please contact our customer services to learn more about the services</p>
              <button
                type="button"
                className="p-2 bg-white hover:bg-slate-300 active:bg-slate-400 font-semibold rounded-[10px] border-solid border-slate-300 border-[1px] text-[darkblue] text-[20px]"
                onClick={handlePricingShipment}
              >
                Get quote
              </button>
            </div>
          )}
          <div className="flex items-center justify-center">
            <div
              className="p-2 border-solid border-[1px] border-emerald-300 w-fit px-3.5 bg-white text-emerald-500 font-semibold rounded-[7px] mt-5 cursor-pointer hover:bg-slate-100 active:bg-slate-200 select-none flex items-center"
              onClick={() => navigate('/login')}
            >
              <PiFolderStarThin size={25} />
              <p className="mx-1.5">Start shipping now</p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-5 flex items-center justify-center flex-col">
        <p className="text-2xl font-semibold">Things that we are serving</p>
        <div className="flex flex-wrap justify-center my-7 gap-y-3 gap-x-2">
          {serviceArray.map((item) => (
            <div className="w-[370px] h-[120px] flex items-start m-2">
              <div className="p-2 flex items-center justify-center rounded-[10px] border-slate-200 border-solid border-[4px] mr-4">
                {item.icon}
              </div>
              <div className="w-full break-words">
                <p className="text-black font-semibold text-xl">{item.label}</p>
                <p className="text-slate-500 text-[15px] mt-1.5">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex items-center justify-center my-20">
        <div className="w-[1150px] flex items-start">
          <div className="w-full">
            <p className="text-2xl font-semibold">Customers's happiness and satisfaction are our goals</p>
            <p className="w-[400px] break-words text-slate-500 mt-5">
              Our goal is to create as many chance as we can to support the shippers, fast and neat receivers, and peace
              of mind with safety delivery
            </p>
          </div>
          <div className="w-[100%] responsive-hidden flex items-center relative">
            <img
              src={skydartMain3}
              alt=""
              loading="lazy"
              className="w-[400px] h-[200px] object-contain absolute ml-[-30px]"
            />
            <img
              src={skydartMain2}
              alt=""
              loading="lazy"
              className="w-[400px] h-[200px] object-contain absolute ml-[230px]"
            />
            <img
              src={shippingMain}
              alt=""
              loading="lazy"
              className="w-[400px] z-10 h-[200px] object-contain scale-[1.1] shadow-[rgba(0,0,0,0.2)_0px_10px_15px_-3px,rgba(0,0,0,0.25)_0px_4px_6px_-2px] ml-[75px] bg-white"
            />
            {/* <img src={shippingMain} alt="" loading="lazy" className="w-[100px] h-[50px] object-cover" /> */}
          </div>
        </div>
      </div>
      <div className="main-1 h-[350px] flex items-center justify-center flex-col responsive-hidden">
        <p className="text-white font-bold text-4xl">Get started now with Skydart!</p>
        <p
          className="mt-3 cursor-pointer text-white hover:underline active:opacity-50 select-none"
          onClick={() => {
            setLoading(true);
            setTimeout(() => {
              setLoading(false);
              navigate('/registration');
            }, 2000);
          }}
        >
          Create account now to start your experience
        </p>
        <form
          style={{ border: '1px solid white' }}
          className="mt-3.5 flex items-center rounded-[10px]"
          onSubmit={signIn}
        >
          <input
            type="text"
            placeholder="Email"
            className="bg-transparent text-white p-2 outline-none"
            value={email}
            style={{ borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            style={{ borderTopRightRadius: 7, borderBottomRightRadius: 7, borderLeft: '1px solid' }}
            className="bg-transparent text-white p-2 outline-none"
          />
          <div
            className="bg-white flex items-center hover:bg-slate-200 active:bg-slate-300"
            style={{ borderTopRightRadius: 10, borderBottomRightRadius: 10 }}
          >
            <button
              type="submit"
              className="bg-transparent text-white p-2 outline-none skydart-main-primary-left-header"
              style={{ borderTopRightRadius: 10, borderBottomRightRadius: 10 }}
            >
              Login
            </button>
            <BiLogIn size={25} className="mr-2" />
          </div>
        </form>
      </div>
      <form
        style={{ border: '1px solid white' }}
        className="mt-3.5 flex items-center flex-col responsive-show rounded-[10px] gap-2 mb-2"
        onSubmit={signIn}
      >
        <input
          type="text"
          placeholder="Email"
          className="bg-slate-100  p-2 outline-none rounded-[10px] w-[90%]"
          value={email}
          style={{ borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          style={{ borderTopRightRadius: 7, borderBottomRightRadius: 7, borderLeft: '1px solid' }}
          className="bg-slate-100 rounded-[10px] p-2 outline-none w-[90%]"
        />
        <div
          className="bg-white flex items-center hover:bg-slate-200 active:bg-slate-300"
          style={{ borderTopRightRadius: 10, borderBottomRightRadius: 10 }}
        >
          <button
            type="submit"
            className="bg-transparent text-white p-2 outline-none skydart-main-primary-left-header"
            style={{ borderTopRightRadius: 10, borderBottomRightRadius: 10 }}
          >
            Login
          </button>
          <BiLogIn size={25} className="mr-2" />
        </div>
      </form>
    </div>
  );
}
