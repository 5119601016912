import { Checkbox, CircularProgress, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { MdOutlineClear } from 'react-icons/md';
import OutsideClickHandler from 'react-outside-click-handler';
import axios from 'axios';
import { FcOk } from 'react-icons/fc';
export default function ExportScreen({
  handleCloseExport,
  startDay,
  endDay,
  debitExport,
  agentExport,
  trackingExport,
  dusReport,
  fileName,
  setFileName,
  traceTime,
  trueHeader,
  startDate,
  exportSelected,
  endDate,
  selected,
  user,
}) {
  const [loading, setLoading] = useState(false);
  const [loadingSuccess, setLoadingSuccess] = useState(false);
  const [exportStatus, setExportStatus] = useState('');
  const [includeInfo, setIncludeInfo] = useState(false);
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);
  const [shipments, setShipments] = useState([]);

  const getShipmentBydate = () => {
    setLoading(true);
    axios.get(`https://test.skydart.com.vn/api/shipmentttt/${start}/${end}/**/skd/shipment`).then((snapshot) => {
      let data = snapshot.data;
      setShipments(
        data
          .filter((s, i) => {
            return data.map((val) => val.hawb).indexOf(s.hawb) == i;
          })
          .map((s, i) => {
            let fsc = s.fsc;
            let fuelValue = ((+s.rate + +s.addressCorrection + +s.remote + +s.surcharge) * +s.fsc) / 100;
            let vatValue =
              ((+s.rate + +s.addressCorrection + +s.remote + +s.surcharge + +s.comestic + +fuelValue + +s.surcharge2) *
                +s.vat) /
              100;
            let total =
              +s.rate +
              +s.addressCorrection +
              +s.remote +
              +s.comestic +
              +s.surcharge +
              +fuelValue +
              +s.surcharge2 +
              +vatValue +
              +s.surcharge3;
            let value = parseFloat(s.value).toFixed(2) || '';
            let updated = s.updated || 'false';
            let removeSkd = s.hawb.substring(3);
            return {
              no: i + 1,
              select: false,
              contextMenu: false,
              updated: updated,
              account: s.account || '',
              currency: s.currency || '',
              service: s.service === 'VDH' ? 'DHL-VN' : s.service,
              date: s.date || '',
              dateTime: `${new Date(s.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })} - ${s.time}`,
              hawb: s.hawb || '',
              removeSkd: removeSkd,
              mawb: s.mawb || 'Pending',
              makh: s.makh || '',
              company: s.company || '',
              contact: s.contact || '',
              type: s.type || '',
              status: s.status || '',
              term: s.term || '',
              time: s.time || '',
              address: s.address || '',
              address2: s.address2 || '',
              address3: s.address3 || '',
              city: s.city || '',
              country: s.country || '',
              phone: s.phone || '',
              postal: s.postal || '',
              state: s.state || '',
              term: s.term,
              town: s.town || '',
              chargeable: (Math.round(s.chargeable * 100) / 100).toFixed(2).toString() || '',
              exactWeight: (Math.round(s.exactWeight * 100) / 100).toFixed(2).toString() || '',
              exactCharge: (Math.round(s.exactCharge * 100) / 100).toFixed(2).toString() || '',
              pickupNote: s.pickupNote || 'Pending',
              gnNote: s.gnNote || 'Pending',
              accountNote: s.accountNote,
              amount: s.amount || '',
              amountPcs: `${s.amount} pcs`,
              totalItem: s.totalItem || '',
              transport: s.transport || '',
              description: s.description || '',
              controller: s.controller || '',
              eamFormat: s.eamFormat || '',
              value: s.type === 'DOX' ? '0.00' : value,
              airline: s.airline || '',
              reweight: (Math.round(s.reweight * 100) / 100).toFixed(2).toString() || '',
              agent: s.agent || 'Pending',
              etd: s.etd || '',
              subStatus: s.subStatus || '',
              sin: s.sin || 'Pending',
              sender: s.sender || '',
              note: s.note || '',
              perform: s.perform || '',
              rate: s.rate?.toString() || '',
              fsc: s.fsc || '',
              vat: s.vat.toString() || '',
              remote: s.remote || '0.00',
              comestic: s.comestic || '0.00',
              addressCorrection: s.addressCorrection || '0.00',
              totalValue: total.toFixed(2) || '',
              surcharge: s.surcharge || '',
              surcharge2: s.surcharge2 || '',
              surcharge3: s.surcharge3 || '',
              fscValue: fuelValue.toFixed(2).toString() || '',
              vatValue: vatValue.toString() || '',
              sellNote: s.sellNote || '',
              paymentPickup: s.paymentPickup || '',
              paymentAcc: s.paymentAcc || '',
              paymentCompany: s.paymentCompany || '',
              fromEmail: s.fromEmail || '',
              fromCity: s.fromCity || '',
              com: s.com || '',
              rateUpdate:
                `${new Date(s.rateUpdate).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}` || '',
              paymentStatus: s.paymentStatus || '',
              paymentSubStatus: s.paymentSubStatus || '',
              code: s.code || '',
              eamService: s.eamService || '',
              pack: s.pack || '',
              plan: s.plan || 'Pending',
              accNote: s.accountNote || 'Pending',
              pickupBy: s.pickupBy,
              ref: 'Pending',
              overidedsiteId: '8423',
              arrivalAirport: 'JFK',
              weightCode: 'K',
              originAirport: 'SGN',
              shipperCity: 'Ho Chi Minh',
              waybill: 'F709',
              importingCarrier: '',
              gross: s.exactWeight,
              amendmentCode: '21',
              amendmentFlag: 'A',
              period: traceTime,
            };
          })
      );
      setTimeout(() => {
        setLoading(false);
        setLoadingSuccess(true);
      }, 2000);
    });
  };

  const headers = [
    { label: 'Class', key: 'plan' },
    { label: 'Sender', key: 'sender' },
    { label: 'HAWB', key: 'removeSkd' },
    { label: 'Date - Time', key: 'date' },
    { label: 'From', key: 'fromCity' },
    { label: 'Destination', key: 'country' },
    { label: 'status', key: 'status' },
    { label: 'type', key: 'type' },
    { label: 'Booked weight', key: 'chargeable' },
    { label: 'Reweight', key: 'reweight' },
    { label: 'Service', key: 'service' },
    { label: 'MAWB', key: 'mawb' },
    { label: 'INTL AWB', key: 'sin' },
  ];

  const customizeReport = {
    data: shipments,
    headers: trueHeader,
    filename: fileName || `Report ${start} to ${end}`,
    delimiter: ',',
  };

  const selectedReport = {
    data: exportSelected,
    headers: trueHeader,
    filename: fileName || `Report ${start} to ${end}`,
    delimiter: ',',
  };

  return (
    <div className="manifest-action-import">
      <div className="manifest-action-export-container relative">
        {loading && (
          <div className="absolute right-0 bottom-0 top-0 left-0 bg-[rgba(255,255,255,0.75)] flex items-center justify-center z-[9999] rounded-[10px]">
            <div className="flex items-center flex-col">
              <CircularProgress size={30} />
              <p className="mt-2">Loading</p>
            </div>
          </div>
        )}
        {loadingSuccess && !loading && (
          <div className="absolute right-0 bottom-0 top-0 left-0 bg-[rgba(255,255,255,0.75)] flex items-center justify-center z-[9999] rounded-[10px]">
            <div className="flex items-center flex-col">
              <FcOk size={30} />
              <p className="mt-2">Fetch shipment success</p>
              <p className="text-[14px] text-slate-500">
                Found <span className="text-black font-semibold underline">{shipments.length}</span> shipments from
                system
              </p>
              <CSVLink
                {...customizeReport}
                className="p-2 bg-sky-600 rounded-[10px] px-3.5 font-semibold mt-2 hover:bg-sky-700 active:bg-sky-800 text-white"
              >
                Export now
              </CSVLink>
              <button
                type="button"
                className="p-2 bg-slate-100 rounded-[10px] px-3.5 font-semibold mt-2 hover:bg-slate-200 active:bg-slate-300"
                onClick={() => {
                  setLoadingSuccess(false);
                  setLoading(false);
                }}
              >
                Go back
              </button>
            </div>
          </div>
        )}
        <div className="manifest-action-import-header">
          <h5>Export File</h5>
          <IconButton type="button" style={{ padding: 5 }} onClick={() => handleCloseExport()}>
            <MdOutlineClear />
          </IconButton>
        </div>
        <div className="skd-user-info-export-name">
          <h5 style={{ marginLeft: 1 }}>Export file name:</h5>
          <input
            type="text"
            placeholder={`Report ${startDay} to ${endDay} `}
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
          />
          {fileName !== '' && (
            <div className="">
              <MdOutlineClear
                size={25}
                className="absolute right-2 top-[38px] cursor-pointer"
                onClick={() => setFileName('')}
              />
            </div>
          )}
          <p style={{ fontSize: 12, marginTop: 5 }}>File name should not has any space</p>
        </div>
        {exportStatus === 'Customizing Columns' && (
          <div className="m-3 flex items-center mt-1.5">
            <p className="mr-2 text-[12px]">From</p>
            <input
              type="date"
              className="p-1 px-2 bg-slate-100 rounded-[10px]"
              value={start}
              onChange={(e) => setStart(e.target.value)}
            />
            <p className="mx-2 text-[12px]">To</p>
            <input
              type="date"
              className="p-1 px-2 bg-slate-100 rounded-[10px]"
              value={end}
              onChange={(e) => setEnd(e.target.value)}
            />
          </div>
        )}
        <div className="skd-user-info-export-select">
          <h5>Export as:</h5>
          <div
            className="skd-user-info-export-select-option"
            onClick={() => {
              setExportStatus('Booking export');
              setEnd('');
              setStart('');
            }}
          >
            <input
              type="radio"
              id="debit-note"
              name="export-group"
              checked={exportStatus === 'Booking export' ? true : false}
            />

            <label style={{ fontSize: 13, display: 'flex', alignItems: 'center', marginLeft: 7 }} for="debit-note">
              Booking export (.csv)
            </label>
          </div>
          <div
            className="skd-user-info-export-select-option"
            onClick={() => {
              setExportStatus('Selected shipments');
              setEnd('');
              setStart('');
            }}
          >
            <input
              type="radio"
              id="debit-note1"
              name="export-group"
              checked={exportStatus === 'Selected shipments' ? true : false}
            />

            <label style={{ fontSize: 13, display: 'flex', alignItems: 'center', marginLeft: 7 }} for="debit-note1">
              Selected shipments (.csv)
            </label>
          </div>

          {user.employee === 'yes' && (
            <div
              className="skd-user-info-export-select-option"
              onClick={() => {
                setExportStatus('Direct US Export');
                setEnd('');
                setStart('');
              }}
            >
              <input
                type="radio"
                id="pdf"
                name="export-group"
                checked={exportStatus === 'Direct US Export' ? true : false}
              />
              <label style={{ fontSize: 13, display: 'flex', alignItems: 'center', marginLeft: 7 }} for="pdf">
                Direct US Export (.csv)
              </label>
            </div>
          )}
          {user.employee === 'yes' && (
            <div
              className="skd-user-info-export-select-option"
              onClick={() => {
                setExportStatus('Customizing Columns');
              }}
            >
              <input
                type="radio"
                id="customize"
                name="export-group"
                checked={exportStatus === 'Customizing Columns' ? true : false}
              />
              <label style={{ fontSize: 13, display: 'flex', alignItems: 'center', marginLeft: 7 }} for="customize">
                Customizing Columns (.csv)
              </label>
            </div>
          )}
          <div
            className="skd-user-info-export-select-option"
            onClick={() => {
              setExportStatus('Tracking file');
            }}
          >
            <input
              type="radio"
              id="customize"
              name="export-group"
              checked={exportStatus === 'Tracking file' ? true : false}
            />
            <label style={{ fontSize: 13, display: 'flex', alignItems: 'center', marginLeft: 7 }} for="customize">
              Tracking file (.csv)
            </label>
          </div>
        </div>
        <div className="skd-user-info-export-select">
          <h5>Export Option:</h5>
          <div
            className="skd-user-shipment-last-header"
            style={{ padding: 10, cursor: 'pointer' }}
            onClick={() => {
              includeInfo ? setIncludeInfo(false) : setIncludeInfo(true);
            }}
          >
            <input type="checkbox" style={{ border: '1px solid lightgray' }} checked={includeInfo ? true : false} />
            <p style={{ marginLeft: 9, fontSize: 13 }}>Ordering data (by sender's name)</p>
          </div>
        </div>
        <div className="skd-user-info-export-select">
          <h5 className="text-[darkblue] tracking-wide" style={{ fontSize: 13 }}>
            Exporting {selected?.length} shipments
          </h5>
        </div>
        <div className="skd-user-info-export-button">
          <div className="skd-user-info-export-button-cancel" onClick={() => handleCloseExport()}>
            <h5>Cancel</h5>
          </div>
          {exportStatus === 'Customizing Columns' && (
            <button type="button" className="skd-user-info-export-button-apply2" onClick={getShipmentBydate}>
              Export
            </button>
          )}
          {exportStatus === 'Booking export' && (
            <CSVLink {...agentExport} className="skd-user-info-export-button-apply2">
              Export
            </CSVLink>
          )}
          {exportStatus === 'Selected shipments' && (
            <CSVLink {...selectedReport} className="skd-user-info-export-button-apply2">
              Export
            </CSVLink>
          )}
          {exportStatus === 'Tracking file' && (
            <CSVLink {...trackingExport} className="skd-user-info-export-button-apply2">
              Export
            </CSVLink>
          )}
          {exportStatus === 'Direct US Export' && (
            <CSVLink {...dusReport} className="skd-user-info-export-button-apply2">
              Export
            </CSVLink>
          )}
          {/* {exportStatus === "Raw" && <button type="button" className="skd-user-info-export-button-apply" onClick={handleExportExcel}>Export</button>}
                {exportStatus === "Debit Note" && <div onClick={() => { setDisplayExport(false); setExportStatus("Raw"); setGetExcel([]); setFileName("") }}>
                    <ReactToExcel className="skd-user-info-export-button-apply1" table="export-table" filename={excelFileName || `${exporterName} debit from ${startDay} to ${endDay}`} sheet="sheet" buttonText="Export" />
                </div>}
                {exportStatus === "CSV" && <CSVLink {...debitExport} className="skd-user-info-export-button-apply2">Export</CSVLink>}
                {exportStatus === "PDF" && <button type="button" className="skd-user-info-export-button-apply" onClick={generatePDF}>Export</button>} */}
        </div>
      </div>
    </div>
  );
}
