import React from 'react';
import { MdOutlineClear } from 'react-icons/md';

export default function MergeAll() {
  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 bg-[rgba(255,255,255,0.55)] flex items-center justify-center z-[9999]">
      <div className="w-[400px] bg-white shadow-[rgba(0,0,0,0.1)_0px_4px_6px_-1px,rgba(0,0,0,0.06)_0px_2px_4px_-1px] p-1 rounded-[10px]">
        <div className="flex items-center justify-between p-1.5">
          <div></div>
          <p className="text-slate-700 font-semibold text-xl">Gộp hàng tổng</p>
          <div className="w-[30px] h-[30px] bg-slate-100 rounded-[10px] hover:bg-slate-200 active:bg-slate-300 flex items-center justify-center cursor-pointer">
            <MdOutlineClear size={27} />
          </div>
        </div>
      </div>
    </div>
  );
}
