import { Button, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FaDownload } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { v1 as uuid } from 'uuid';
import * as XLSX from 'xlsx';
export default function TrackingContainer({ user }) {
  const [fileName, setFileName] = useState('');
  const navigate = useNavigate();
  const [fileType, setFileType] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [shipments, setShipments] = useState([]);
  const [trackingInput, setTrackingInput] = useState('');
  const [trackResult, setTrackResult] = useState('');
  const [fileResult, setFileResult] = useState('');
  const trackArray = trackingInput.split(/\r?\n/);
  useEffect(() => {
    let arr = [];
    trackArray.map((s) => {
      arr.push(s);
    });
    setTrackResult(arr.toString());
  }, [trackArray]);
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files[0]) {
      // at least one file has been selected so do something
      // handleFiles(e.target.files);
      setFileName(e.target.files[0].name);
      setFileSize(e.target.files[0].size);
      setFileType(e.target.files[0].type);
      readExcel(e.target.files[0]);
    }
  };
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((item) => {
      setShipments(item);
      setTrackingInput('');
    });
  };

  const convertShipment = shipments.map((item) => {
    return item.HAWB;
  });
  const checkSKD = convertShipment.map((item) => {
    return item;
  });

  useEffect(() => {
    let arr = [];
    checkSKD.slice(0, 20).map((s) => {
      arr.push(s);
    });
    setFileResult(arr.toString());
  }, [checkSKD]);
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  function niceBytes(x) {
    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }

  const handleTrackByInput = () => {
    if (trackingInput === '') return;
    navigate(`/tracking-result/${trackResult}/${user.uid}`);
  };
  const handleTrackByFile = () => {
    navigate(`/tracking-result/${fileResult}/${user.uid}`);
  };
  return (
    <div className="p-5 w-full h-full overflow-auto">
      <p className="text-3xl font-bold tracking-wide">TRACK YOUR SHIPMENT NOW</p>
      <p className="mt-1 text-slate-600">
        Get on track with your packages in real time, and make sure that your shipping journey is safe and assured
      </p>
      <div className="mt-5">
        <TextField
          label="Tracking numbers"
          placeholder="Enter up to 10 shipments, separate by (enter or return)"
          className="bg-white w-full"
          multiline
          disabled={shipments.length === 0 ? false : true}
          value={trackingInput}
          onChange={(e) => setTrackingInput(e.target.value)}
        />
        <p className="tracking-wide text-[14px] mt-2">
          Skydart is now serve multiple tracking shipments, and you can upload a file to track, drop your file or{' '}
          <span className="text-[darkblue] hover:underline cursor-pointer active:opacity-50">
            browse it from your computer
          </span>
        </p>
        <div className="mt-2 w-full bg-white rounded-[10px] border-dashed border-2 border-slate-300 h-[calc(100vh_-_20rem)] skydart-customer-tracking relative">
          <div className="absolute top-0 right-0 left-0 bottom-0 bg-[rgba(255,255,255,0.55)] flex items-center justify-center flex-col z-[9999]">
            {/* <p className="bg-white text-slate-500 font-semibold rounded-full px-2.5">Drag and drop your file here or</p> */}
            <input type="file" id="tracking-file" style={{ display: 'none' }} onChange={handleChange} />
            <label
              htmlFor="tracking-file"
              className="p-2 mt-2 px-4 cursor-pointer rounded-[7px] font-semibold bg-emerald-500 hover:bg-emerald-600 active:bg-emerald-700 text-white flex items-center"
            >
              <FaDownload size={23} />
              <p className="text-[17px] mx-2">
                {shipments.length === 0 ? 'Upload tracking file' : 'Upload another shipment file'}
              </p>
            </label>
            {shipments.length !== 0 && (
              <p className="mt-2 text-slate-500">
                Found <span className="text-black font-semibold underline bg-white">{shipments.length}</span> shipments
                from uploaded file
              </p>
            )}
            {shipments.length > 20 && (
              <p className="mt-2 text-rose-500">Found you can only track 20 shipments at a time</p>
            )}
          </div>
        </div>
        <p className="mt-2">
          You can export the file at "Manage shipment" {'->'} select shipments that you want to track {'->'} "Export"{' '}
          {'->'} "Tracking file" {'->'} "Export now" and go back drop the file to the upload area
        </p>
        {shipments.length === 0 && (
          <Button
            variant="contained"
            className="track-shipment-customer-btn"
            style={{ background: 'darkblue' }}
            fullWidth
            onClick={handleTrackByInput}
          >
            Track shipments
          </Button>
        )}
        {shipments.length > 0 && (
          <Button
            variant="contained"
            className="track-shipment-customer-btn"
            fullWidth
            onClick={handleTrackByFile}
            style={{ background: 'darkblue' }}
          >
            Track shipments
          </Button>
        )}
      </div>
    </div>
  );
}
