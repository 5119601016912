import React, { useState } from 'react';
import { useEffect } from 'react';
import { FcCheckmark, FcHighPriority } from 'react-icons/fc';

function NewBookingBody({
  rows,
  getTableBodyProps,
  prepareRow,
  shipments,
  data,
  id,
  isColumnResizing,
  row,
  onSelectingEdit,
}) {
  const [selectedShipment, setSelectedShipment] = useState([]);
  return (
    <div {...getTableBodyProps}>
      <div {...row.getRowProps()} className="tr3">
        <div style={{ display: 'flex' }}>
          {row.cells.map((cell, index) => {
            return (
              <div>
                {cell.column.active && (
                  <div {...cell.getCellProps()} className="td flex items-center">
                    {index === 5 && (
                      <img
                        src={`https://flagsapi.com/${cell.row.original.code}/shiny/64.png`}
                        className="w-[23px] h-[23px] mr-2"
                      />
                    )}
                    <p
                      className={
                        index === 6 && cell.row.original.service === 'SKD'
                          ? 'art tracking-wide w-full'
                          : index === 1
                            ? 'text-rose-600 w-full'
                            : index === 2
                              ? 'text-fuchsia-800 tracking-wide w-full'
                              : index === 3
                                ? 'text-sky-800 tracking-wide w-full'
                                : index === 11
                                  ? 'text-rose-600 underline tracking-wide w-full'
                                  : 'tracking-wide w-full'
                      }
                      style={
                        cell.row.original.select
                          ? { color: 'white', fontWeight: 600, textAlign: index === 0 ? 'center' : '' }
                          : cell.row.original.deliverySign
                            ? { color: 'white', textAlign: index === 0 ? 'center' : '' }
                            : index === 6 && cell.row.original.service === 'DUS'
                              ? {
                                  fontWeight: 600,
                                  color: 'white',
                                  background: 'black',
                                  textAlign: 'center',
                                  borderRadius: 3,
                                }
                              : index === 6 && cell.row.original.service === 'UPS'
                                ? {
                                    color: 'rgb(247,202,136)',
                                    background: 'brown',
                                    textAlign: 'center',
                                    fontWeight: 600,
                                    borderRadius: 3,
                                  }
                                : index === 6 && cell.row.original.service === 'FIP'
                                  ? {
                                      background: 'purple',
                                      color: 'white',
                                      textAlign: 'center',
                                      fontWeight: 600,
                                      borderRadius: 3,
                                    }
                                  : index === 6 && cell.row.original.service === 'FIE'
                                    ? {
                                        background: 'purple',
                                        color: 'white',
                                        textAlign: 'center',
                                        fontWeight: 600,
                                        borderRadius: 3,
                                      }
                                    : index === 6 && cell.row.original.service === 'DHL-VN'
                                      ? {
                                          borderRadius: 3,
                                          fontWeight: 600,
                                          background: 'yellow',
                                          color: 'red',
                                          textAlign: 'center',
                                        }
                                      : index === 6 && cell.row.original.service === 'DHL'
                                        ? {
                                            borderRadius: 3,
                                            fontWeight: 600,
                                            background: 'yellow',
                                            color: 'red',
                                            textAlign: 'center',
                                          }
                                        : index === 0
                                          ? { textAlign: 'center' }
                                          : index === 6
                                            ? { textAlign: 'center' }
                                            : index === 8
                                              ? { color: 'darkblue', textDecoration: 'underline' }
                                              : { textAlign: 'left' }
                      }
                    >
                      {index === 0 ? id + 1 : cell.value} {index === 8 ? 'KG' : ''}
                    </p>
                    {index === 2 && <span className="tr4-helper">{cell.value}</span>}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default React.memo(NewBookingBody, (prevProps, nextProps) => nextProps.isColumnResizing);
