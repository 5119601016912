import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { FaSortDown } from 'react-icons/fa6';
import { FiRefreshCcw } from 'react-icons/fi';
import OutsideClickHandler from 'react-outside-click-handler';
import { useNavigate } from 'react-router-dom';
export default function WorkSpace({
  finalShipment,
  refreshShipment,
  systemStatus,
  loading,
  currentRoute,
  lastUpdateStatus,
}) {
  const [displayWorkspace, setDisplayWorkspace] = useState(false);
  const navigate = useNavigate();
  const navArrays = [
    { label: 'New Booking', id: 0, path: '/new-booking' },
    { label: 'Shipments', id: 1, path: '/shipments' },
    { label: 'Packing', id: 2, path: '/packing' },
    { label: 'Customers', id: 3, path: '/customers' },
    { label: 'Transactions', id: 4, path: '/transaction' },
    // { label: 'Debit list', id: 5, path: '/debit' },
    // { label: 'Report Error', id: 6, path: '/report' },
    // { label: 'Market', id: 7, path: '/market' },
  ];
  return (
    <div className="p-1.5 pb-0 px-3.5 flex items-center">
      <p className="text-[17px] font-bold text-black">Workspace</p>
      <OutsideClickHandler
        onOutsideClick={() => {
          setDisplayWorkspace(false);
        }}
      >
        <div className="relative">
          <div
            className="ml-3 p-1 bg-white rounded-[7px] w-[250px] flex items-center justify-between px-3 cursor-pointer hover:bg-slate-200 active:bg-slate-300 select-none search-column-input1 border-slate-200 border-solid border-[1px]"
            onClick={() => {
              setDisplayWorkspace(!displayWorkspace);
            }}
          >
            <p className="tracking-wide">{currentRoute}</p>
            <FaSortDown size={28} className="mt-[-10px]" />
          </div>
          {displayWorkspace && (
            <div className="absolute bg-white top-[45px] w-full z-[9999] shadow-[rgba(0,0,0,0.15)_2.4px_2.4px_3.2px] rounded-[10px] max-h-[400px] overflow-y-auto left-1">
              {navArrays?.map((item) => (
                <div
                  className="m-1.5 p-1.5 px-2.5 flex items-center rounded-[10px] hover:bg-slate-100 hover:font-semibold active:bg-slate-200 cursor-pointer select-none"
                  onClick={() => navigate(item.path)}
                >
                  <p>{item.label}</p>
                  <div
                    className="w-[23px] h-[23px] rounded-full flex items-center justify-center ml-auto"
                    style={{ border: '1px solid rgb(225,225,225)' }}
                  >
                    {item.label === currentRoute && <div className="w-[15px] h-[15px] rounded-full bg-sky-600"></div>}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </OutsideClickHandler>
      <p className="ml-3 text-slate-500">
        Found <span className="font-bold text-black underline">{finalShipment?.length}</span>{' '}
        {currentRoute.toLowerCase()}
      </p>
      <div className="ml-auto flex items-center gap-x-2">
        <p className="text-emerald-600 tracking-wide text-[15px] mr-2">System status: {systemStatus}</p>
        {/* <p className="text-slate-500 tracking-wide text-[15px] mr-2">
          Last updated at: <span className="text-black">23:28</span>
        </p> */}
        <p>|</p>
        <p className="text-slate-500 text-[15px]">
          Last updated: <span className="text-black">{lastUpdateStatus}</span>
        </p>
        <p>|</p>
        {loading && (
          <div className="flex items-center">
            <CircularProgress size={20} />
            <p className="text-[14px] mx-1.5 ml-2">Loading {currentRoute.toLowerCase()}...</p>
          </div>
        )}
        <div
          className="p-2 flex items-center justify-center rounded-[10px] bg-slate-300 cursor-pointer hover:bg-slate-200 active:scale-90"
          onClick={() => refreshShipment()}
        >
          <FiRefreshCcw size={23} />
          <p className="px-2">Refresh</p>
        </div>
      </div>
    </div>
  );
}
