import { START_DATE, useDatepicker } from '@datepicker-react/hooks';
import { Checkbox, CircularProgress, IconButton, LinearProgress } from '@mui/material';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { AiOutlineMinus, AiOutlineSortAscending } from 'react-icons/ai';
import { BsArrowLeft, BsArrowRight, BsColumnsGap } from 'react-icons/bs';
import { CiSliderVertical } from 'react-icons/ci';
import { FaExternalLinkAlt, FaSearch } from 'react-icons/fa';
import {
  FaDownload,
  FaFileInvoice,
  FaFilterCircleXmark,
  FaPrint,
  FaRegCalendarDays,
  FaSortDown,
  FaSortUp,
  FaTruckPickup,
} from 'react-icons/fa6';
import emptyData from '../../staff/pages/shipments/components/emptyData.png';
import { FcCheckmark, FcHighPriority, FcOk } from 'react-icons/fc';
import { FiMoreVertical, FiPackage, FiRefreshCcw } from 'react-icons/fi';
import { GiConfirmed } from 'react-icons/gi';
import { GoCalendar, GoSearch } from 'react-icons/go';
import { IoIosArrowDropdown } from 'react-icons/io';
import { IoAddOutline, IoBookmarksOutline, IoCheckmarkDoneSharp } from 'react-icons/io5';
import { LuFolderCheck, LuMoreHorizontal } from 'react-icons/lu';
import {
  MdAutoFixHigh,
  MdConnectingAirports,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdOutlineClear,
  MdTransitEnterexit,
} from 'react-icons/md';
import { TbPackages } from 'react-icons/tb';
import { VscBeakerStop } from 'react-icons/vsc';
import OutsideClickHandler from 'react-outside-click-handler';
import { useNavigate } from 'react-router-dom';
import { useBlockLayout, useColumnOrder, useFlexLayout, useResizeColumns, useTable } from 'react-table';

import { db } from '../../server/firebase';
import DatepickerContext from '../../staff/pages/new-booking/components/datepickerContext';
import Month1 from '../../staff/pages/new-booking/components/Month1';
import ShipmentBody from '../../staff/pages/shipments/components/ShipmentBody';
import { HiDocumentText } from 'react-icons/hi2';
import { SlSizeActual, SlSizeFullscreen } from 'react-icons/sl';
import DashboardBody from '../components/DashboardBody';
import DashboardBottom from '../components/DashboardBottom';
import { PiColumnsLight } from 'react-icons/pi';
import ExportScreen from '../../staff/pages/shipments/components/ExportScreen';
import { BiLabel } from 'react-icons/bi';
import { useReactToPrint } from 'react-to-print';
import StaffBill2 from '../../components/StaffBill2';
import Inv2 from '../../components/Inv2';
import ShippingMark from '../../components/ShippingMark';
import DashboardEdit from '../components/DashboardEdit';

export default function Dashboard({ user, currentSidebar }) {
  // Date declared
  let newDate = new Date();
  const navigate = useNavigate();
  const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
  let date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();

  const month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
  const year = newDate.getFullYear();
  let firstDayOfCurrentWeek = date - (newDate.getDay() < 10 ? '0' : '');
  let last = firstDayOfCurrentWeek + 6;
  let firstday = firstDayOfCurrentWeek - 6;

  let lastDayOfCurrentWeek = last < 10 ? `0${last}` : last;
  let firstDayOfLastWeek = firstday < 10 ? `0${firstday}` : firstday;
  const first = (firstDay.getDate() < 10 ? '0' : '') + firstDay.getDate();
  let yesterday = date - 1;
  let months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  let lastMonth = month - 1;
  let lastDay = new Date(year, lastMonth, 0);
  let firstDayOfYear = new Date(year, 0, 1);
  let lastPrevMonthDay = newDate.setMonth(newDate.getMonth(), 0);
  let prevMonth = lastMonth < 10 ? `0${lastMonth}` : lastMonth;

  const [loading, setLoading] = useState(false);
  const [dateStatus, setDateStatus] = useState('Period');
  const [selectedHeader, setSelectedHeader] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState([]);
  const [displayMultipleSearch, setDisplayMultipleSearch] = useState(false);
  const [displayShipment, setDisplayShipment] = useState(false);
  const [displayPrint, setDisplayPrint] = useState(false);
  const [displayExport, setDisplayExport] = useState(false);
  const [displayColumn, setDisplayColumn] = useState(false);
  const [displaySelected, setDisplaySelected] = useState(false);
  const [displaySelectOption, setDisplaySelectOption] = useState(false);
  const [displayNoOption, setDisplayNoOption] = useState(false);
  const [noActive, setNoActive] = useState(true);
  const [hawbActive, setHawbActive] = useState(true);
  const [displayHawbOption, setDisplayHawbOption] = useState(false);
  const [displayFullScreen, setDisplayFullScreen] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [shipmentData, setShipmentData] = useState({});
  const [startDate, setStartDate] = useState(`${year}-${month}-${first}`);
  const [endDate, setEndDate] = useState(new Date().toISOString().substr(0, 10));
  const [displayDate, setDisplayDate] = useState(false);
  /// page state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(20);
  const [pageNumberLimit, setPageNumberLimit] = useState(10);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [maxPageLimit, setMaxPageLimit] = useState(5);
  /// search state
  const [searchCountry, setSearchCountry] = useState('');
  const [showEmpty, setShowEmpty] = useState(false);
  const [searchCode, setSearchCode] = useState('');
  const [searchWeight, setSearchWeight] = useState('');
  const [searchHawb, setSearchHawb] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [searchSin, setSearchSin] = useState('');
  const [searchAddress, setSearchAddress] = useState('');
  const [searchAddress2, setSearchAddress2] = useState('');
  const [searchCustomerName, setSearchCustomerName] = useState('');
  const [searchDate, setSearchDate] = useState('');
  const [searchService, setSearchService] = useState('');
  const [searchFrom, setSearchFrom] = useState('');
  const [searchTo, setSearchTo] = useState('');
  const [searchType, setSearchType] = useState('');
  const [searchPcs, setSearchPcs] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchDescription, setSearchDescription] = useState('');
  const [selectPrint, setSelectPrint] = useState([]);
  const [renderPrint, setRenderPrint] = useState(false);

  const [showMoreSelected, setShowMoreSelected] = useState(false);
  const [displaySelectList, setDisplaySelectList] = useState(false);
  const [searchSelected, setSearchSelected] = useState('');

  //Export status
  const [fileName, setFileName] = useState('');
  const onShowNoneShipment = () => {
    setTimeout(() => {
      setShowEmpty(true);
    }, 5000);
  };

  const [status, setStatus] = useState('');
  const [lastUpdateStatus, setLastUpdateStatus] = useState('');
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    focusedInput: START_DATE,
  });

  //Sort status

  const [sortHawb, setSortHawb] = useState(false);
  const [sortSin, setSortSin] = useState(false);
  const [sortDate, setSortDate] = useState(false);
  const [sortService, setSortService] = useState(false);
  const [sortFrom, setSortFrom] = useState(false);
  const [sortTo, setSortTo] = useState(false);
  const [sortAddress, setSortAddress] = useState(false);
  const [sortAddress2, setSortAddress2] = useState(false);
  const [sortRecipient, setSortRecipient] = useState(false);
  const [sortType, setSortType] = useState(false);
  const [sortWeight, setSortWeight] = useState(false);
  const [sortPcs, setSortPcs] = useState(false);
  const [sortStatus, setSortStatus] = useState(false);
  const [sortValue, setSortValue] = useState(false);
  const [sortDescription, setSortDescription] = useState(false);
  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    focusedInput: dateRange.focusedInput,
    onDatesChange: handleDateChange,
  });
  function handleDateChange(data) {
    if (!data.focusedInput) {
      setDateRange({ ...data, focusedInput: START_DATE });
    } else {
      setDateRange(data);
    }
    // console.log(data);
  }

  // Get date
  //   useEffect(() => {
  //     let newDate = new Date();
  //     const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
  //     const first = (firstDay.getDate() < 10 ? '0' : '') + firstDay.getDate();
  //     const date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
  //     const month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
  //     const year = newDate.getFullYear();
  //     setStartDate(`${year}-${month}-${first}`);
  //   }, []);
  const d = new Date(dateRange.startDate);
  const selectMonth = (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1);
  const selectDate = (d.getDate() < 10 ? '0' : '') + d.getDate();
  const selectYear = d.getFullYear();

  const e = new Date(dateRange.endDate);
  const selectEndMonth = (e.getMonth() + 1 < 10 ? '0' : '') + (e.getMonth() + 1);
  const selectEndDate = (e.getDate() < 10 ? '0' : '') + e.getDate();
  const selectEndYear = e.getFullYear();
  const currentYear = new Date().getFullYear();
  const handleToday = () => {
    setStartDate(`${year}-${month}-${date}`);
    setEndDate(`${year}-${month}-${date}`);
    setDisplayDate(false);
    setDateStatus('Today');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleApplyDate = () => {
    if (!dateRange.endDate) {
      setStartDate(`${selectYear}-${selectMonth}-${selectDate}`);
      setEndDate(`${selectYear}-${selectMonth}-${selectDate}`);
      setDisplayDate(false);
      setDateStatus('Period');
    } else {
      setStartDate(`${selectYear}-${selectMonth}-${selectDate}`);
      setEndDate(`${selectEndYear}-${selectEndMonth}-${selectEndDate}`);
      setDisplayDate(false);
      setDateStatus('Period');
    }
  };
  const editPrint = () => {
    setRenderPrint(true);
    setSelectPrint(exportSelected);
  };
  const printSingleBill = (shipmentValue) => {
    setRenderPrint(true);
    // console.log(shipmentValue);

    // setSelectPrint([shipmentValue])
  };
  const handleThisWeek = () => {
    setStartDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
    setEndDate(`${year}-${month}-${lastDayOfCurrentWeek}`);
    setDisplayDate(false);
    setDateStatus('This week');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleLastWeek = () => {
    setStartDate(`${year}-${month}-${firstDayOfLastWeek}`);
    setEndDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
    setDisplayDate(false);
    setDateStatus('Last week');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };

  const handleThisMonth = () => {
    setStartDate(`${year}-${month}-${first}`);
    setEndDate(new Date(currentYear, new Date().getMonth() + 1).toISOString().split('T')[0]);
    setDisplayDate(false);
    setDateStatus('This month');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleLastMonth = () => {
    setStartDate(`${year}-${prevMonth}-01`);
    setEndDate(`${year}-${prevMonth}-${new Date(lastPrevMonthDay).getDate().toString()}`);
    setDisplayDate(false);
    setDateStatus('Last month');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const getCurrentDateTime = () => {
    const options = { weekday: 'short', day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit' };
    const currentDate = new Date().toLocaleDateString('en-US', options);
    setLastUpdateStatus(currentDate);
  };
  const handleThisYear = () => {
    setStartDate(`${year}-01-01`);
    setEndDate(`${year}-12-31`);
    setDateStatus('This year');
    setDisplayDate(false);
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleCloseDate = () => {
    setDisplayDate(false);
  };
  const handleShowDate = () => {
    setDisplayDate(!displayDate);
  };
  const startDay = new Date(startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  const endDay = new Date(endDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  const handleChangeInputColumn = (index, e) => {
    setCurrentPage(1);
    if (index === 0) {
      setSearchSin(e.target.value);
    } else if (index === 1) {
      setSearchDate(e.target.value);
    } else if (index === 2) {
      setSearchService(e.target.value);
    } else if (index === 3) {
      setSearchFrom(e.target.value);
    } else if (index === 4) {
      setSearchTo(e.target.value);
    } else if (index === 5) {
      setSearchAddress(e.target.value);
    } else if (index === 6) {
      setSearchAddress2(e.target.value);
    } else if (index === 7) {
      setSearchCustomerName(e.target.value);
    } else if (index === 8) {
      setSearchType(e.target.value);
    } else if (index === 9) {
      setSearchWeight(e.target.value);
    } else if (index === 10) {
      setSearchPcs(e.target.value);
    } else if (index === 11) {
      setSearchStatus(e.target.value);
    } else if (index === 12) {
      setSearchValue(e.target.value);
    } else if (index === 13) {
      setSearchDescription(e.target.value);
    }
  };
  const handleResetSearchColumn = (index) => {
    if (index === 0) {
      setSearchSin('');
    } else if (index === 1) {
      setSearchDate('');
    } else if (index === 2) {
      setSearchService('');
    } else if (index === 3) {
      setSearchFrom('');
    } else if (index === 4) {
      setSearchTo('');
    } else if (index === 5) {
      setSearchAddress('');
    } else if (index === 6) {
      setSearchAddress2('');
    } else if (index === 7) {
      setSearchCustomerName('');
    } else if (index === 8) {
      setSearchType('');
    } else if (index === 9) {
      setSearchWeight('');
    } else if (index === 10) {
      setSearchPcs('');
    } else if (index === 11) {
      setSearchStatus('');
    } else if (index === 12) {
      setSearchValue('');
    } else if (index === 13) {
      setSearchDescription('');
    }
  };
  const handleChangeCode = (e) => {
    setSearchCode(e.target.value);
  };
  const handleYesterday = () => {
    if (yesterday === 0) {
      setStartDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
      setEndDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
      setDisplayDate(false);
      setDateStatus('Yesterday');
      setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
    } else {
      setStartDate(`${year}-${month}-${yesterday}`);
      setEndDate(`${year}-${month}-${yesterday}`);
      setDisplayDate(false);
      setDateStatus('Yesterday');
      setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
    }
  };
  const handleCancelDate = () => {
    setDisplayDate(false);
  };
  useEffect(() => {
    refreshShipment();
  }, [startDate, endDate]);
  const refreshShipment = () => {
    setLoading(true);
    db.collection('shipments')
      .where('fromEmail', '==', user.email)
      .where('createAt', '>=', startDate)
      .where('createAt', '<=', endDate)
      .orderBy('createAt', 'desc')
      .get()
      .then((snapshot) => {
        let data = snapshot.docs.map((doc) => doc.data());
        getCurrentDateTime();
        setShipments(
          data.map((s, index) => {
            const date = s.timestamp.toDate();

            // Months array for mapping month numbers to month names
            const months = [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ];

            // Get the month, day, year, hours, and minutes
            const month = months[date.getMonth()];
            const day = date.getDate();
            const year = date.getFullYear();
            let hours = date.getHours();
            let minutes = date.getMinutes();

            // Format hours to have leading zero if needed
            hours = hours < 10 ? `0${hours}` : hours;

            // Format minutes to have leading zero if needed
            minutes = minutes < 10 ? `0${minutes}` : minutes;
            const mergeDate = `${month} ${day}, ${year} - ${hours}:${minutes}`;
            return {
              no: index + 1,
              select: false,
              hawb: s.hawb,
              fromCity: s.fromCity,
              perform: s.perform,
              createAt: s.createAt,
              convertedDate: mergeDate,
              pickupAt: s.pickupAt,
              toCountry: s.toCountry,
              toTown: s.toTown,
              toCity: s.toCity,
              labelUrl: s.labelUrl,
              shipmentType: s.shipmentType,
              totalCharge: (Math.round(s.totalCharge * 100) / 100).toFixed(2).toString(),
              mst: s.mst,
              reweight: s.reweight,
              totalPackage: s.totalPackage.toString(),
              status: s.status,
              totalItemValue: s.totalItemValue,
              totalInvoiceValue: s.totalInvoiceValue,
              description: s.description,
              code: s.code,
              time: s.time,
              exporter: s.exporter,
              eori: s.eori,
              booked: s.booked,
              fromAddress: s.fromAddress,
              fromAddress2: s.fromAddress2,
              fromAddress3: s.fromAddress3,
              fromState: s.fromState,
              fromTown: s.fromTown,
              fromCity: s.fromCity,
              fromCountry: s.fromCountry,
              fromPostal: s.fromPostal,
              fromName: s.fromName,
              fromEmail: s.fromEmail,
              importer: s.importer,
              toAddress: s.toAddress.trim(),
              toAddress2: s.toAddress2.trim(),
              toAddress3: s.toAddress3.trim(),
              toCity: s.toCity,
              toState: s.toState,
              toTown: s.toTown,
              toPostal: s.toPostal,
              sin: s.sin,
              toCountry: s.toCountry,
              toPhone: s.toPhone,
              toName: s.toName,
              toEmail: s.toEmail,
              weight: s.weight,
              pickupBy: s.pickupBy,
              invoiceValue: s.invoiceValue,
              transportation: s.transportation,
              service: s.service,
              note: s.note,
              usage: s.usage,
              packageValue: s.packageValue,
              timestamp: s.timestamp,
            };
          })
        );
        setLoading(false);
      });
  };

  const sortedData = shipments.sort((a, b) => b.timestamp - a.timestamp);
  const getTrueWeight = sortedData.filter((s) => {
    if (searchWeight !== '') {
      return s.totalCharge === searchWeight;
    } else {
      return s;
    }
  });
  const getTrueInvoiceValue = getTrueWeight.filter((s) => {
    if (searchValue !== '') {
      return s.totalInvoiceValue === searchValue;
    } else {
      return s;
    }
  });

  const findExactDate = getTrueInvoiceValue.filter((s) => {
    if (searchDate !== '') {
      return s.createAt === searchDate;
    } else {
      return s;
    }
  });
  const finalShipment = findExactDate.filter((s) => {
    return (
      s.createAt >= startDate &&
      s.createAt <= endDate &&
      s.perform !== 'Draft' &&
      s.hawb.toLowerCase().includes(searchHawb.toLowerCase()) &&
      s.status.toLowerCase().includes(status.toLowerCase()) &&
      s.toCountry.toLowerCase().startsWith(searchTo.toLowerCase()) &&
      s.code.toLowerCase().startsWith(searchCode.toLowerCase()) &&
      s.service.toLowerCase().includes(searchService.toLowerCase()) &&
      s.fromCity.toLowerCase().includes(searchFrom.toLowerCase()) &&
      s.toAddress.toLowerCase().includes(searchAddress.toLowerCase()) &&
      s.toAddress2.toLowerCase().includes(searchAddress2.toLowerCase()) &&
      s.toName.toLowerCase().includes(searchCustomerName.toLowerCase()) &&
      s.shipmentType.toLowerCase().includes(searchType.toLowerCase()) &&
      s.totalPackage.includes(searchPcs.toLowerCase()) &&
      s.status.toLowerCase().includes(searchStatus.toLowerCase()) &&
      s.sin.toLowerCase().includes(searchSin.toLowerCase()) &&
      s.description.toLowerCase().includes(searchDescription.toLowerCase())
    );
  });
  const sortedShipment = finalShipment.sort((x, y) => {
    if (sortHawb) {
      return x.hawb.localeCompare(y.hawb);
    } else if (sortSin) {
      return x.sin.localeCompare(y.sin);
    } else if (sortDate) {
      return x.createAt.localeCompare(y.createAt);
    } else if (sortService) {
      return x.service.localeCompare(y.service);
    } else if (sortFrom) {
      return x.fromCity.localeCompare(y.fromCity);
    } else if (sortTo) {
      return x.toCountry.localeCompare(y.toCountry);
    } else if (sortAddress) {
      return x.toAddress.localeCompare(y.toAddress);
    } else if (sortAddress2) {
      return x.toAddress2.localeCompare(y.toAddress2);
    } else if (sortRecipient) {
      return x.toName.localeCompare(y.toName);
    } else if (sortType) {
      return x.shipmentType.localeCompare(y.shipmentType);
    } else if (sortWeight) {
      return x.totalCharge.localeCompare(y.totalCharge);
    } else if (sortPcs) {
      return x.totalPackage.localeCompare(y.totalPackage);
    } else if (sortStatus) {
      return x.status.localeCompare(y.status);
    } else if (sortValue) {
      return x.totalInvoiceValue.localeCompare(y.totalInvoiceValue);
    } else if (sortDescription) {
      return x.description.localeCompare(y.description);
    }
  });
  const exportSelected = shipments.filter((s) => {
    return s.select;
  });

  const holdShipment = shipments.filter((s) => {
    return s.status === 'On Hold';
  });
  const pickedupShipment = shipments.filter((s) => {
    return s.status === 'Picked Up';
  });
  const arrivedShipment = shipments.filter((s) => {
    return s.status === 'Arrived At Skydart Origin Depot';
  });
  const processShipment = shipments.filter((s) => {
    return s.status === 'In Process';
  });
  const clearanceShipment = shipments.filter((s) => {
    return s.status === 'In Clearance';
  });
  const transitShipment = shipments.filter((s) => {
    return s.status === 'In Transit';
  });
  const deliveredShipment = shipments.filter((s) => {
    return s.status === 'Delivered';
  });
  const clearedShipment = shipments.filter((s) => {
    return s.status === 'Cleared For Export';
  });
  const totalValue = finalShipment.reduce((a, v) => (a = +a + +v.totalInvoiceValue), 0);
  const totalWeight = finalShipment.reduce((a, v) => (a = +a + +v.totalCharge), 0);
  const totalSelectedWeight = exportSelected.reduce((a, v) => (a = +a + +v.totalCharge), 0);
  const totalPieces = finalShipment.reduce((a, v) => (a = +a + +v.totalPackage), 0);
  const totalReweight = finalShipment.reduce((a, v) => (a = +a + +v.reweight), 0);

  const statusArray = [
    {
      label: 'All',
      value: '',
      icon: <TbPackages size={23} className="mr-1.5" />,
      id: 0,
      shipmentLength: shipments.length,
    },
    {
      label: 'Picked up',
      value: 'Picked Up',
      icon: <FaTruckPickup size={23} className="mr-1.5" />,
      id: 1,
      shipmentLength: pickedupShipment.length,
    },
    {
      label: 'Arrived',
      value: 'Arrived',
      icon: <MdConnectingAirports size={23} className="mr-1.5" />,
      id: 2,
      shipmentLength: arrivedShipment.length,
    },
    {
      label: 'In process',
      value: 'In Process',
      icon: <LuFolderCheck size={23} className="mr-1.5" />,
      id: 3,
      shipmentLength: processShipment.length,
    },
    {
      label: 'On hold',
      value: 'On Hold',
      icon: <VscBeakerStop size={23} className="mr-1.5" />,
      id: 4,
      shipmentLength: holdShipment.length,
    },
    {
      label: 'Clearance',
      value: 'In Clearance',
      icon: <CiSliderVertical size={23} className="mr-1.5" />,
      id: 5,
      shipmentLength: clearanceShipment.length,
    },
    {
      label: 'In transit',
      value: 'In Transit',
      icon: <MdTransitEnterexit size={23} className="mr-1.5" />,
      id: 7,
      shipmentLength: transitShipment.length,
    },
    {
      label: 'Delivered',
      value: 'Delivered',
      icon: <IoCheckmarkDoneSharp size={23} className="mr-1.5" />,
      id: 8,
      shipmentLength: deliveredShipment.length,
    },
  ];

  const columns = useMemo(
    () => [
      {
        Header: 'REF',
        minWidth: 190,
        accessor: 'sin',
        active: true,
        id: 3,
        key: 'hawb',
        context: false,
        width: 190,
      },
      {
        Header: 'Booked time',
        minWidth: 190,
        accessor: 'convertedDate',
        active: true,
        id: 4,
        key: 'convertedDate',
        context: false,
        width: 190,
      },
      {
        Header: 'Service',
        minWidth: 100,
        accessor: 'service',
        active: true,
        id: 5,
        key: 'service',
        context: false,
        width: 100,
      },
      {
        Header: 'Ship from',
        minWidth: 190,
        accessor: 'fromCity',
        active: true,
        id: 6,
        key: 'from',
        context: false,
        width: 190,
      },
      {
        Header: 'Ship to',
        minWidth: 190,
        accessor: 'toCountry',
        active: true,
        id: 7,
        key: 'country',
        context: false,
        width: 190,
      },
      {
        Header: 'Address',
        minWidth: 190,
        accessor: 'toAddress',
        active: true,
        id: 1,
        key: 'toAddress2',
        context: false,
        width: 190,
      },
      {
        Header: 'Address 2',
        minWidth: 190,
        accessor: 'toAddress2',
        active: true,
        id: 2,
        key: 'toAddress2',
        context: false,
        width: 190,
      },
      {
        Header: 'Receipient name',
        minWidth: 190,
        accessor: 'toName',
        active: true,
        id: 14,
        context: false,
        width: 190,
      },
      {
        Header: 'Type',
        minWidth: 100,
        accessor: 'shipmentType',
        active: true,
        id: 8,
        key: 'shipmentType',
        context: false,
        width: 100,
      },
      {
        Header: 'Weight (KG)',
        minWidth: 100,
        accessor: 'totalCharge',
        active: true,
        id: 9,
        key: 'totalCharge',
        context: false,
        width: 100,
      },
      {
        Header: 'Pcs Amount',
        minWidth: 70,
        accessor: 'totalPackage',
        active: true,
        id: 10,
        key: 'totalPackage',
        context: false,
        width: 70,
      },
      {
        Header: 'Status',
        minWidth: 120,
        accessor: 'status',
        active: true,
        id: 11,
        key: 'createAt',
        context: false,
        width: 120,
      },
      {
        Header: 'Value',
        minWidth: 120,
        accessor: 'totalInvoiceValue',
        active: true,
        id: 12,
        key: 'value',
        context: false,
        width: 120,
      },
      {
        Header: 'Description',
        minWidth: 250,
        accessor: 'description',
        active: true,
        id: 13,
        key: 'description',
        context: false,
        width: 250,
      },
    ],
    []
  );
  const pages = [];
  for (let i = 1; i <= Math.ceil(sortedShipment.length / itemPerPage); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentShipment = sortedShipment.slice(indexOfFirstItem, indexOfLastItem);
  const handleChangePage = (e) => {
    setCurrentPage(+e.target.id);
  };
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageLimit + 1 && number > minPageLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleChangePage}
          className={currentPage == number ? 'active-page' : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });
  const data = React.useMemo(() => currentShipment);
  const handleFirstPage = () => {
    setCurrentPage(1);
    setMaxPageLimit(5);
    setMinPageLimit(0);
  };
  const handleLastPage = () => {
    setCurrentPage(pages[pages.length - 1]);
    setMaxPageLimit(pages.length);
    setMinPageLimit(pages.length - 5);
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
  };
  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
    }
  };

  const { headerGroups, rows, prepareRow, state, getTableProps, getTableBodyProps, setColumnOrder } = useTable(
    { columns, data },
    useResizeColumns,
    useFlexLayout,
    useBlockLayout,
    useColumnOrder
  );
  const handleSelectedAllHeader = () => {
    setSelectedHeader(
      headerGroups[0]?.headers?.map((sd) => {
        sd.active = true;
      })
    );
  };
  /// Select multiple shipment
  const handleSelectedAllShipment = (e) => {
    setSelectedShipment(
      shipments.map((sd) => {
        sd.select = e.target.checked;
      })
    );
  };
  const handleSelectedAllShipment1 = (propsValue) => {
    if (propsValue === 'all') {
      setSelectedShipment(
        shipments.map((sd) => {
          sd.select = !sd.select;
        })
      );
    } else if (propsValue === 'wpx') {
      setSelectedShipment(
        shipments
          .filter((s) => {
            return s.shipmentType === 'WPX';
          })
          .map((sd) => {
            sd.select = !sd.select;
          })
      );
    } else if (propsValue === 'dox') {
      setSelectedShipment(
        shipments
          .filter((s) => {
            return s.shipmentType === 'DOX';
          })
          .map((sd) => {
            sd.select = !sd.select;
          })
      );
    } else if (propsValue === 'currentPage') {
      setSelectedShipment(
        currentShipment.map((sd) => {
          sd.select = !sd.select;
        })
      );
    }
  };
  const handleSelectedShipment = (data, rowId, checkValue) => {
    setSelectedShipment(
      data?.map((sd) => {
        if (rowId === sd.id) {
          sd.original.select = checkValue;
        }
        return sd;
      })
    );
  };
  const handleSelectedContext = (headerGroupObject, headerId, headerActive) => {
    setSelectedHeader(
      headerGroupObject?.headers?.map((sd) => {
        if (sd.id === headerId) {
          sd.context = !sd.context;
        } else {
          sd.context = false;
        }
        return sd;
      })
    );
  };

  const handleCloseContext = (headerGroupObject, headerId, headerActive) => {
    setSelectedHeader(
      headerGroupObject?.headers?.map((sd) => {
        if (sd.id === headerId) {
          sd.context = false;
        }
        return sd;
      })
    );
  };
  const handleSelectedContextMenu = (hawb) => {
    setSelectedShipment(
      shipments.map((sd) => {
        if (sd.hawb === hawb) {
          sd.contextMenu = !sd.contextMenu;
        } else {
          sd.contextMenu = false;
        }
        return sd;
      })
    );
  };

  const handleCloseContextMenu = (hawb) => {
    setSelectedShipment(
      shipments.map((sd) => {
        if (sd.hawb === hawb) {
          sd.contextMenu = false;
        }
        return sd;
      })
    );
  };

  // Handle single column
  const handleSelectedHeader = (headerGroupObject, headerId, headerActive) => {
    setSelectedHeader(
      headerGroupObject?.headers?.map((sd) => {
        if (sd.id === headerId) {
          if (headerActive) {
            sd.active = false;
          } else {
            sd.active = true;
          }
        }
        return sd;
      })
    );
  };
  const handleSortShipmentColumn = (index, hawb) => {
    if (index === 0) {
      setSortSin(!sortSin);
    } else if (index === 1) {
      setSortDate(!sortDate);
    } else if (index === 2) {
      setSortService(!sortService);
    } else if (index === 3) {
      setSortFrom(!sortFrom);
    } else if (index === 4) {
      setSortTo(!sortTo);
    } else if (index === 5) {
      setSortAddress(!sortAddress);
    } else if (index === 6) {
      setSortAddress2(!sortAddress2);
    } else if (index === 7) {
      setSortRecipient(!sortRecipient);
    } else if (index === 8) {
      setSortType(!sortType);
    } else if (index === 9) {
      setSortWeight(!sortWeight);
    } else if (index === 10) {
      setSortPcs(!sortPcs);
    } else if (index === 11) {
      setSortStatus(!sortStatus);
    } else if (index === 12) {
      setSortValue(!sortValue);
    } else if (index === 13) {
      setSortDescription(!sortDescription);
    }
  };
  const duplicateCode = [...new Set(shipments.map((s) => s.code))];
  const duplicateService = [...new Set(shipments.map((s) => s.service))];
  const duplicateWeight = [...new Set(shipments.map((s) => s.totalCharge))];

  const duplicateStatus = [...new Set(shipments.map((s) => s.status))];
  const duplicateValue = [...new Set(shipments.map((s) => s.totalInvoiceValue))];
  const isColumnResizing = !!state.columnResizing.isResizingColumn;
  let labelRef = useRef();
  let invoiceRef = useRef();
  let shippingMarkRef = useRef();
  let printAllRef = useRef();
  const printLabel = useReactToPrint({
    content: () => labelRef.current,
  });
  const printInvoice = useReactToPrint({
    content: () => invoiceRef.current,
  });
  const printShippingMark = useReactToPrint({
    content: () => shippingMarkRef.current,
  });
  const printAll = useReactToPrint({
    content: () => printAllRef.current,
  });

  const trackingHeaders = [{ label: 'HAWB', key: 'hawb' }];
  const defaultHeaders = [
    { label: 'HAWB', key: 'hawb' },
    { label: 'REF', key: 'sin' },
    { label: 'Address', key: 'toAddress' },
    { label: 'Address 2', key: 'toAddress2' },
    { label: 'Address 3', key: 'toAddress3' },
  ];
  const agentExport = {
    data: exportSelected,
    headers: defaultHeaders,
    filename: fileName || `Report ${startDay} to ${endDay}`,
    delimiter: ',',
  };
  const trackingExport = {
    data: exportSelected,
    headers: trackingHeaders,
    filename: fileName || `Report ${startDay} to ${endDay}`,
    delimiter: ',',
  };
  const fullScreenCss = displayFullScreen ? 'fixed top-0 right-0 left-0 bottom-0 z-[9999] bg-slate-100' : '';

  const handleShowShipment = (shipmentValue) => {
    setShipmentData(shipmentValue);
    setDisplayShipment(!displayShipment);
  };
  const handleCloseShipment = () => {
    setDisplayShipment(false);
  };
  return (
    <div className={`w-full h-full overflow-y-auto ${fullScreenCss}`}>
      {displayShipment && (
        <DashboardEdit
          user={user}
          shipmentData={shipmentData}
          handleCloseShipment={handleCloseShipment}
          printSingleBill={printSingleBill}
          printLabel={printLabel}
          printInvoice={printInvoice}
          printShippingMark={printShippingMark}
          printAll={printAll}
        />
      )}
      <div ref={printAllRef}>
        <div className="skydart-booking-hawb1" ref={labelRef}>
          {selectPrint.map((s, index) => (
            <div className="printing3" key={index}>
              <StaffBill2
                hawb={s.hawb}
                exporter={s.exporter}
                fromAddress={s.fromAddress}
                fromAddress2={s.fromAddress2}
                fromAddress3={s.fromAddress3}
                fromState={s.fromState}
                fromTown={s.fromTown}
                fromCity={s.fromCity}
                fromPostal={s.fromPostal}
                fromCountry={s.fromCountry}
                fromPhone={s.fromPhone}
                fromName={s.fromName}
                fromEmail={s.fromEmail}
                importer={s.importer}
                toAddress={s.toAddress}
                toAddress2={s.toAddress2}
                toAddress3={s.toAddress3}
                toState={s.toState}
                toTown={s.toTown}
                toCity={s.toCity}
                toPostal={s.toPostal}
                toCountry={s.toCountry}
                toPhone={s.toPhone}
                toName={s.toName}
                toEmail={s.toEmail}
                amount={s.totalPackage}
                weight={s.weight}
                chargeable={s.totalCharge}
                type={s.shipmentType}
                date={s.createAt}
                exportSelected={selectPrint}
                description={s.description}
              />
            </div>
          ))}
        </div>
        <div className="skydart-booking-hawb1" ref={invoiceRef}>
          {selectPrint.map((s) => {
            if (s.shipmentType === 'WPX') {
              return (
                <Inv2
                  invoice={s.invoiceValue}
                  hawb={s.hawb}
                  datePicker={s.createAt}
                  fromCountry={s.fromCountry}
                  toCountry={s.toCountry}
                  usage={s.usage}
                  exporter={s.exporter}
                  fromAddress={s.fromAddress}
                  fromAddress2={s.fromAddress2}
                  fromAddress3={s.fromAddress3}
                  fromTown={s.fromTown}
                  fromState={s.fromState}
                  fromCity={s.fromCity}
                  fromPostal={s.fromPostal}
                  fromName={s.fromName}
                  fromPhone={s.fromPhone}
                  fromEmail={s.fromEmail}
                  mst={s.mst}
                  importer={s.importer}
                  toAddress={s.toAddress}
                  toAddress2={s.toAddress2}
                  toAddress3={s.toAddress3}
                  toState={s.toState}
                  toPostal={s.toPostal}
                  toTown={s.toTown}
                  toCity={s.toCity}
                  toName={s.toName}
                  toPhone={s.toPhone}
                  toEmail={s.toEmail}
                  eori={s.eori}
                  transportation={s.transportation}
                  totalGross={s.weight}
                  totalPackage={s.totalPackage}
                  totalItemValue={s.totalItemValue}
                  totalInvoiceValue={s.totalInvoiceValue}
                  exportSelected={selectPrint}
                />
              );
            }
          })}
        </div>
        <div className="skydart-booking-hawb1" ref={shippingMarkRef}>
          {/* {selectPrint.map((s, index) => (
                        <div className="printing4">
                            <PrintMark no={index + 1} exportSelected={selectPrint} packageData={s.packageValue} total={s.totalPackage} hawb={s.hawb} weight={s.weight} chargeable={s.totalCharge} importer={s.importer} address={s.toAddress} address2={s.toAddress2} address3={s.toAddress3} city={s.toCity} state={s.toState} town={s.toTown} postal={s.toPostal} country={s.toCountry} phone={s.toPhone} contact={s.toName} />
                        </div>
                    ))} */}
          {selectPrint.map((s) => {
            if (s.shipmentType === 'WPX') {
              return s.packageValue.map((item, index) => (
                <div className="printing4">
                  <ShippingMark
                    no={index + 1}
                    date={s.createAt}
                    length={item.packageLength}
                    width={item.packageWidth}
                    height={item.packageHeight}
                    total={s.packageValue.length}
                    hawb={s.hawb}
                    weight={item.packageWeight}
                    chargeable={item.packageCharge}
                    importer={s.importer}
                    address={s.toAddress}
                    address2={s.toAddress2}
                    address3={s.toAddress3}
                    city={s.toCity}
                    state={s.toState}
                    town={s.toTown}
                    postal={s.toPostal}
                    country={s.toCountry}
                    phone={s.toPhone}
                    contact={s.toName}
                  />
                </div>
              ));
            }
          })}
          {/* {selectPrint.map((s) => {
                        let package = s.packageValue;
                        return <>
                        </>
                    })} */}
        </div>
      </div>
      {displayExport && (
        <ExportScreen
          handleCloseExport={() => setDisplayExport(false)}
          fileName={fileName}
          setFileName={setFileName}
          startDate={startDate}
          endDate={endDate}
          startDay={startDay}
          endDay={endDay}
          agentExport={agentExport}
          trackingExport={trackingExport}
          selected={exportSelected}
          user={user}
        />
      )}
      <div className="p-5 pb-1 flex items-center">
        <p className="text-xl font-semibold tracking-wide">Manage shipments</p>
        <div className="ml-auto flex items-center gap-x-2 ">
          <p className="text-emerald-600 text-[15px]">System status: Very good</p>
          <p>|</p>
          <p className="text-slate-500 text-[15px]">
            Last updated at: <span className="text-black">{lastUpdateStatus}</span>
          </p>
          <p>|</p>
          {loading && (
            <div className="flex items-center">
              <CircularProgress size={25} />
              <p className="text-[15px] mx-2">Loading shipments...</p>
            </div>
          )}
          <div className="group relative">
            <div
              className="p-1.5 px-3 flex items-center justify-center rounded-[10px] bg-slate-200 hover:bg-slate-300 active:bg-slate-400 cursor-pointer"
              onClick={refreshShipment}
            >
              <FiRefreshCcw size={25} />
            </div>
            <div className="group-hover:flex items-center justify-center hidden absolute bg-black text-white text-[13px] p-0.5 z-10 px-2.5 rounded-[7px] mt-1.5 ml-[-10px]">
              <p>Refresh</p>
            </div>
          </div>
          <div
            className="p-[7px] px-3 flex items-center justify-center rounded-[10px] bg-slate-200 hover:bg-slate-300 active:bg-slate-400 cursor-pointer"
            onClick={() => {
              alert('Please contact our customer support team to resolve your problem');
            }}
          >
            <p>Report an error</p>
          </div>
        </div>
      </div>
      <div className="flex items-center p-3">
        <div className="w-full bg-white rounded-[10px] mr-auto shadow-[rgba(0,0,0,0.1)_0px_2px_4px] flex items-center p-1">
          <div className="relative flex items-center">
            <FaSearch size={30} className="absolute left-2 bg-slate-200 rounded-full p-1.5" />
            <input
              type="text"
              placeholder="Search shipments..."
              className="p-1.5 bg-white border-slate-300 border-solid border-[1px] outline-none rounded-full w-[300px] pl-[42px] pr-[35px] newBooking-search"
              value={searchHawb}
              onChange={(e) => setSearchHawb(e.target.value)}
            />
            {searchHawb !== '' && (
              <MdOutlineClear
                size={30}
                className="absolute right-1.5 cursor-pointer active:scale-90"
                onClick={() => {
                  setSearchHawb('');
                }}
              />
            )}
          </div>
          <div className="ml-auto mr-1.5">
            <p className="text-[14px]">
              Found <span className="underline font-semibold">{finalShipment.length}</span> shipment from account
            </p>
          </div>
        </div>
        <OutsideClickHandler
          onOutsideClick={() => {
            handleCloseDate();
          }}
        >
          <div className="relative ml-2">
            <div
              className="flex p-2 bg-slate-200 rounded-[10px] hover:bg-slate-300 active:bg-slate-400 select-none transition-[0.1s] cursor-pointer"
              onClick={() => handleShowDate()}
            >
              <GoCalendar size={24} />
              <p style={{ marginRight: 7, marginLeft: 7 }} className="overflow-hidden text-ellipsis whitespace-nowrap">
                {dateStatus}:
              </p>
              <p style={{ marginRight: 7 }} className="overflow-hidden text-ellipsis whitespace-nowrap">
                {startDay}
              </p>
              <AiOutlineMinus size={20} style={{ marginRight: 7 }} />
              <p style={{ marginRight: 7 }} className="overflow-hidden text-ellipsis whitespace-nowrap">
                {endDay}
              </p>
              <IoIosArrowDropdown size={24} />
            </div>
            {displayDate && (
              <div className="skd-user-date" style={{ right: 0 }}>
                <DatepickerContext.Provider
                  value={{
                    focusedDate,
                    isDateFocused,
                    isDateSelected,
                    isDateHovered,
                    isDateBlocked,
                    isFirstOrLastSelectedDate,
                    onDateSelect,
                    onDateFocus,
                    onDateHover,
                  }}
                >
                  <div className="skd-user-date-content">
                    <div className="skd-user-date-left">
                      <div
                        className={
                          dateStatus === 'Today'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleToday}
                      >
                        {dateStatus === 'Today' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>Today</p>
                      </div>
                      <div
                        className={
                          dateStatus === 'Yesterday'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleYesterday}
                      >
                        {dateStatus === 'Yesterday' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>Yesterday</p>
                      </div>
                      <div
                        className={
                          dateStatus === 'This week'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleThisWeek}
                      >
                        {dateStatus === 'This week' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>This week</p>
                      </div>
                      <div
                        className={
                          dateStatus === 'Last week'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleLastWeek}
                      >
                        {dateStatus === 'Last week' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>Last week</p>
                      </div>
                      <div
                        className={
                          dateStatus === 'This month'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleThisMonth}
                      >
                        {dateStatus === 'This month' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>This Month</p>
                      </div>
                      <div
                        className={
                          dateStatus === 'Last month'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleLastMonth}
                      >
                        {dateStatus === 'Last month' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>Last Month</p>
                      </div>
                      <div
                        className={
                          dateStatus === 'This year'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleThisYear}
                      >
                        {dateStatus === 'This year' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>This Year</p>
                      </div>
                    </div>
                    <div className="skd-user-date-right">
                      <div
                        style={{
                          display: 'flex',
                          margin: '15px 0 0',
                          gridTemplateColumns: `repeat(${activeMonths.length}, 300px)`,
                          gridGap: '0 24px',
                        }}
                      >
                        <div className="date-month-back" onClick={goToPreviousMonths}>
                          <BsArrowLeft />
                        </div>
                        <div className="date-month-back1" onClick={goToNextMonths}>
                          <BsArrowRight />
                        </div>
                        {activeMonths.map((month) => (
                          <Month1
                            key={`${month.year}-${month.month}`}
                            year={month.year}
                            month={month.month}
                            firstDayOfWeek={firstDayOfWeek}
                          />
                        ))}
                      </div>
                      <div className="skd-user-date-shown">
                        <div className="skd-user-date-shown-range">
                          <h5 style={{ fontWeight: 450 }}>
                            {!dateRange.startDate ? 'Start Date' : dateRange.startDate.toDateString()}
                          </h5>
                          <AiOutlineMinus />
                          <h5 style={{ fontWeight: 450, marginLeft: 7 }}>
                            {!dateRange.endDate ? 'End Date' : dateRange.endDate.toDateString()}
                          </h5>
                          <p style={{ fontSize: 10 }}>Ho Chi Minh City time</p>
                        </div>
                        <div className="skd-user-date-update-button">
                          <div className="skd-user-date-update-cancel" onClick={handleCancelDate}>
                            <h5 style={{ color: 'black', textAlign: 'center' }}>Cancel</h5>
                          </div>
                          <div className="skd-user-date-update-succeed" onClick={handleApplyDate}>
                            <h5 style={{ color: 'white', textAlign: 'center' }}>Update</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DatepickerContext.Provider>
              </div>
            )}
          </div>
        </OutsideClickHandler>
      </div>
      <div className="flex items-center gap-x-1.5 mt-2.5">
        {statusArray.map((status1, index) => (
          <div
            className={`p-1.5 ${index === 0 ? 'ml-7' : index === statusArray.length - 1 ? 'mr-7' : ''} ${
              status === status1.value ? 'bg-white text-sky-600 font-semibold' : 'bg-gray-100 text-black'
            } flex items-center w-full shadow-[rgba(0,0,0,0.1)_0px_0px_9px] cursor-pointer select-none relative`}
            style={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            onClick={() => setStatus(status1.value)}
          >
            {status1.icon}
            <p>{status1.label}</p>
            <div
              className={`absolute right-[-1px] top-[-9px] ${
                status === status1.value ? 'bg-rose-600 text-white' : 'text-slate-500'
              } font-semibold min-w-[23px] rounded-full flex items-center justify-center text-[14px]`}
            >
              <p className="px-1">{status1.shipmentLength}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="bg-white mx-3 shadow-[rgba(0,0,0,0.1)_0px_1px_1px_0px,rgba(0,0,0,0.1)_0px_1px_2px_0px] rounded-[10px] relative">
        <div className="p-2.5 flex items-center gap-x-1.5 whitespace-nowrap overflow-auto">
          <div
            className="p-1.5 rounded-[10px] bg-sky-600 text-white flex items-center font-semibold select-none cursor-pointer hover:bg-sky-700 active:bg-sky-800 whitespace-nowrap"
            onClick={() => navigate('/create')}
          >
            <IoAddOutline size={23} />
            <p className="mx-1">Create</p>
          </div>

          <OutsideClickHandler
            onOutsideClick={() => {
              setDisplayPrint(false);
            }}
          >
            <div
              className={`p-1.5 rounded-[10px] bg-slate-200 flex items-center font-semibold select-none  hover:bg-slate-300 active:bg-slate-400 ${
                exportSelected?.length === 0 ? 'opacity-40 cursor-not-allowed' : 'cursor-pointer'
              }`}
              onClick={() => {
                if (exportSelected.length !== 0) {
                  setDisplayPrint(!displayPrint);
                  editPrint();
                }
              }}
            >
              <FaPrint size={23} />
              <p className="mx-1.5">Print</p>
              <FaSortDown size={23} className="mt-[-10px]" />
            </div>
            {displayPrint && (
              <div className="absolute bg-white shadow-[rgba(136,165,191,0.48)_6px_2px_16px_0px,rgba(255,255,255,0.8)_-6px_-2px_16px_0px] z-[9999] w-[220px] mt-2 rounded-[10px]">
                <div className="p-2 flex flex-col gap-y-1">
                  <p className="text-xl font-semibold" style={{ borderBottom: '1px solid rgb(230,230,230)' }}>
                    Printing option
                  </p>
                  <div
                    className="p-1.5 rounded-[10px] hover:bg-slate-100 active:bg-slate-200 cursor-pointer select-none flex items-center"
                    onClick={printLabel}
                  >
                    <BiLabel size={20} />
                    <p className="ml-2">Print label</p>
                  </div>
                  <div
                    className="p-1.5 rounded-[10px] hover:bg-slate-100 active:bg-slate-200 cursor-pointer select-none flex items-center"
                    onClick={printInvoice}
                  >
                    <FaFileInvoice size={20} />
                    <p className="ml-2">Print invoice</p>
                  </div>
                  <div
                    className="p-1.5 rounded-[10px] hover:bg-slate-100 active:bg-slate-200 cursor-pointer select-none flex items-center"
                    onClick={printShippingMark}
                  >
                    <IoBookmarksOutline size={20} />
                    <p className="ml-2">Print shipping mark</p>
                  </div>
                  <div
                    className="p-1.5 rounded-[10px] hover:bg-slate-100 active:bg-slate-200 cursor-pointer select-none flex items-center"
                    onClick={printAll}
                  >
                    <FaExternalLinkAlt size={20} />
                    <p className="ml-2">Print all</p>
                  </div>
                </div>
              </div>
            )}
          </OutsideClickHandler>
          <div
            className={`p-1.5 rounded-[10px] bg-slate-200 flex items-center font-semibold select-none  hover:bg-slate-300 active:bg-slate-400 ${
              exportSelected?.length === 0 ? 'opacity-40 cursor-not-allowed' : 'cursor-pointer'
            }`}
            onClick={() => {
              if (exportSelected.length !== 0) {
                setDisplayExport(!displayExport);
              }
            }}
          >
            <FaDownload size={23} />
            <p className="mx-1.5">Export</p>
            <FaSortDown size={23} className="mt-[-10px]" />
          </div>
          <div className="group">
            <div
              className="p-1.5 rounded-[10px] bg-slate-200 flex items-center font-semibold select-none cursor-pointer hover:bg-slate-300 active:bg-slate-400"
              onClick={refreshShipment}
            >
              <FiRefreshCcw size={23} />
            </div>
            <div className="group-hover:flex items-center justify-center hidden absolute bg-black text-white text-[13px] p-0.5 z-[9999] px-2.5 rounded-[7px] mt-1.5 ml-[-15px]">
              <p>Refresh</p>
            </div>
          </div>
          <div className="group">
            <div
              className="p-1.5 rounded-[10px] bg-slate-200 flex items-center font-semibold select-none cursor-pointer hover:bg-slate-300 active:bg-slate-400"
              onClick={() => setDisplayFullScreen(!displayFullScreen)}
            >
              {!displayFullScreen && <SlSizeFullscreen size={23} />}
              {displayFullScreen && <SlSizeActual size={23} />}
            </div>
            <div className="group-hover:flex items-center justify-center hidden absolute bg-black text-white text-[13px] p-0.5 z-[9999] px-2.5 rounded-[7px] mt-1.5 ml-[-15px]">
              <p>{displayFullScreen ? 'Close full screen' : 'Full screen'}</p>
            </div>
          </div>
          <OutsideClickHandler
            onOutsideClick={() => {
              setDisplayColumn(false);
            }}
          >
            <div>
              <div
                className="p-1.5 rounded-[10px] bg-slate-200 flex items-center font-semibold select-none cursor-pointer hover:bg-slate-300 active:bg-slate-400"
                onClick={() => {
                  setDisplayColumn(!displayColumn);
                }}
              >
                <BsColumnsGap size={23} />
                <p className="mx-1.5">Columns</p>
                <FaSortDown size={23} className="mt-[-10px]" />
              </div>
              {displayColumn && (
                <div className="skydart-shipment-break-down" style={{ marginLeft: 0 }}>
                  {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps({})} style={{ display: 'grid' }}>
                      {headerGroup.headers.map((column, index) => (
                        <div
                          className="skydart-shipment-break-down-option cursor-pointer hover:bg-slate-200 active:bg-slate-300"
                          key={index}
                          onClick={() => {
                            handleSelectedHeader(headerGroup, column.id, column.active);
                          }}
                        >
                          <div className="w-[20px] h-[20px] rounded-full flex items-center justify-center bg-white border-solid border-slate-200 border-[1px]">
                            {column.active && <div className="w-[14px] h-[14px] bg-sky-600 rounded-full"></div>}
                          </div>
                          <label style={{ fontSize: 15, marginLeft: 7 }}>{column.render('Header')}</label>
                        </div>
                      ))}
                    </div>
                  ))}
                  <div
                    className="p-1.5 rounded-[5px] hover:bg-slate-200 active:bg-gray-300 cursor-pointer select-none text-[15px] font-semibold"
                    onClick={() => {
                      handleSelectedAllHeader();
                      setDisplayColumn(false);
                    }}
                  >
                    <p>Reset column</p>
                  </div>
                </div>
              )}
            </div>
          </OutsideClickHandler>
          <div className="flex items-center ml-auto gap-x-1.5">
            <OutsideClickHandler
              onOutsideClick={() => {
                setDisplaySelectList(false);
              }}
            >
              {exportSelected?.length !== 0 && (
                <div className="">
                  <div
                    className="p-1.5 rounded-[10px] bg-emerald-50 text-emerald-600 flex items-center font-semibold select-none cursor-pointer whitespace-nowrap px-2.5 hover:bg-emerald-100 active:bg-emerald-200 min-w-[150px] justify-center ml-auto"
                    onClick={() => setDisplaySelectList(!displaySelectList)}
                  >
                    <p className="mx-1.5">{exportSelected.length} Selected</p>
                    <FaSortDown size={23} className="mt-[-10px]" />
                  </div>
                  {displaySelectList && (
                    <div className="absolute bg-white rounded-[10px] w-[300px] shadow-[rgba(17,17,26,0.1)_0px_4px_16px,rgba(17,17,26,0.15)_0px_8px_32px] z-[9999] mt-1.5 max-h-[calc(100vh_-_20rem)] overflow-auto flex flex-col">
                      <div className="flex items-center sticky top-0 bg-white z-10 p-1 shadow-[rgba(0,0,0,0.05)_2.4px_2.4px_3.2px] mb-1">
                        <GoSearch
                          size={25}
                          className="p-1 bg-white rounded-full shadow-[rgba(67,71,85,0.27)_0px_0px_0.25em,rgba(90,125,188,0.05)_0px_0.25em_1em] absolute left-3"
                        />
                        <input
                          type="text"
                          value={searchSelected}
                          onChange={(e) => setSearchSelected(e.target.value)}
                          autoFocus
                          placeholder="Search HAWB"
                          className="bg-slate-100 m-1 rounded-[20px] p-1 w-[97%] outline-none pl-[33px]"
                        />
                      </div>
                      {exportSelected
                        .filter((s) => {
                          if (s.hawb.toLowerCase().includes(searchSelected.toLowerCase())) {
                            return s;
                          }
                        })
                        .slice(0, showMoreSelected ? exportSelected?.length : 20)
                        .map((item, index) => (
                          <div
                            className="p-1.5 px-2.5 flex items-center justify-between hover:bg-slate-200 rounded-[7px] active:bg-slate-300 mb-1 cursor-pointer select-none mx-2"
                            style={{
                              borderBottom: index === exportSelected.length - 1 ? '' : '1px solid rgb(220,220,220)',
                            }}
                            onClick={() => {
                              handleShowShipment(item);
                            }}
                          >
                            <p>{item.hawb}</p>
                            <p className="bg-emerald-50 text-emerald-500 font-semibold tracking-wide text-[14px] px-1.5 rounded-full">
                              {item.totalCharge} KG
                            </p>
                          </div>
                        ))}
                      <div
                        className="sticky bottom-0 bg-white z-10 p-1 mx-2.5 flex flex-col"
                        style={{ borderTop: '1px solid lightgray' }}
                      >
                        <div className="flex items-center">
                          <div className="flex items-center p-1 rounded-[10px] font-semibold bg-sky-50 text-[darkblue]">
                            <p className="text-lg">{((totalSelectedWeight * 100) / 100).toFixed(2)} KG</p>
                          </div>
                          <div
                            className="p-1 bg-slate-100 hover:bg-slate-200 active:bg-slate-300 flex items-center ml-auto px-2 rounded-[7px] cursor-pointer select-none"
                            onClick={() => {
                              setDisplayExport(!displayExport);
                              setDisplaySelectList(false);
                              setSearchSelected('');
                            }}
                          >
                            <FaDownload size={20} />
                            <p className="mx-1.5">Export</p>
                          </div>
                        </div>
                        <div className="mt-1.5" style={{ borderTop: '1px solid rgb(220,220,220)' }}>
                          {exportSelected?.length > 20 && (
                            <div
                              className={`p-1.5 rounded-[10px] flex items-center my-1.5 font-semibold cursor-pointer select-none ${
                                !showMoreSelected
                                  ? 'hover:bg-emerald-100 active:bg-emerald-200 bg-emerald-50 text-emerald-500'
                                  : 'bg-rose-50 text-rose-500 hover:bg-rose-100 active:bg-rose-200'
                              }`}
                              onClick={() => setShowMoreSelected(!showMoreSelected)}
                            >
                              <p>{showMoreSelected ? 'Show less shipments' : 'Show all selected shipments'}</p>
                              <FaSortDown size={25} className="mt-[-10px] ml-auto" />
                            </div>
                          )}
                          <p className="text-[15px] text-slate-500 px-1">
                            Showing <span className="text-black underline">{exportSelected.length}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </OutsideClickHandler>
            <div className="paginate1">
              <IconButton
                type="button"
                disabled={currentPage == pages[0] ? true : false}
                style={{ width: 35, height: 35 }}
                onClick={() => handleFirstPage()}
              >
                <MdKeyboardDoubleArrowLeft className="prev-btn-page" size={40} />
              </IconButton>
              <IconButton
                type="button"
                disabled={currentPage == pages[0] ? true : false}
                style={{ width: 35, height: 35 }}
                onClick={() => handlePrevPage()}
              >
                <MdKeyboardArrowLeft className="prev-btn-page" size={40} />
              </IconButton>
              {renderPageNumbers}
              <IconButton
                type="button"
                disabled={currentPage == pages[pages.length - 1] ? true : false}
                style={{ width: 35, height: 35 }}
                onClick={() => handleNextPage()}
              >
                <MdKeyboardArrowRight className="prev-btn-page" />
              </IconButton>
              <IconButton
                type="button"
                disabled={currentPage == pages[pages.length - 1] ? true : false}
                style={{ width: 35, height: 35 }}
                onClick={() => handleLastPage()}
              >
                <MdKeyboardDoubleArrowRight className="prev-btn-page" />
              </IconButton>
            </div>
          </div>
        </div>
        {loading && (
          <LinearProgress style={{ width: 190, height: 5, borderRadius: 9999, position: 'absolute', zIndex: 9999 }} />
        )}
        <div
          className={`${
            displayFullScreen ? 'table-wrapper3-full' : !currentSidebar ? 'table-wrapper3-sidebar' : 'table-wrapper3'
          }`}
        >
          <div className="flex items-center sticky top-0 bg-white z-[9997] w-fit shadow-[rgba(0,0,0,0.15)_2.4px_2.4px_3.2px]">
            <div
              className="sticky left-0 z-[9998] bg-white flex items-center shadow-[rgba(0,0,0,0.05)_2.4px_0px_0px]"
              style={{ border: '1px solid lightgray', borderBottom: 'none', borderRight: 'none' }}
            >
              <Checkbox
                style={{ padding: 7, margin: 1, height: 50 }}
                onChange={(e) => {
                  handleSelectedAllShipment(e);
                }}
              />
              <OutsideClickHandler
                onOutsideClick={() => {
                  setDisplaySelectOption(false);
                }}
              >
                <div>
                  <div
                    className={`w-[30px] h-[30px] ${
                      displaySelectOption ? 'bg-slate-300' : 'bg-slate-100'
                    } mr-1 hover:bg-slate-200 active:bg-slate-300 cursor-pointer flex items-center justify-center rounded-full`}
                    onClick={() => {
                      setDisplaySelectOption(!displaySelectOption);
                    }}
                  >
                    <FiMoreVertical size={25} />
                  </div>
                  {displaySelectOption && (
                    <div className="absolute bg-white w-[250px] mt-1.5 rounded-[10px] shadow-[rgba(0,0,0,0.2)_2.4px_2.4px_3.2px] p-1 gap-y-1 flex flex-col">
                      <div
                        className="p-1.5 flex items-center font-semibold tracking-wide hover:bg-slate-200 active:bg-slate-300 cursor-pointer select-none rounded-[10px] px-3"
                        onClick={() => {
                          handleSelectedAllShipment1('all');
                          setDisplaySelectOption(false);
                        }}
                      >
                        <p>Select all shipment</p>
                      </div>
                      <div
                        className="p-1.5 flex items-center font-semibold tracking-wide hover:bg-slate-200 active:bg-slate-300 cursor-pointer select-none rounded-[10px] px-3"
                        onClick={() => {
                          handleSelectedAllShipment1('wpx');
                          setDisplaySelectOption(false);
                        }}
                      >
                        <p>Select all WPX shipment</p>
                      </div>
                      <div
                        className="p-1.5 flex items-center font-semibold tracking-wide hover:bg-slate-200 active:bg-slate-300 cursor-pointer select-none rounded-[10px] px-3"
                        onClick={() => {
                          handleSelectedAllShipment1('dox');
                          setDisplaySelectOption(false);
                        }}
                      >
                        <p>Select all DOX shipment</p>
                      </div>
                      <div
                        className="p-1.5 flex items-center font-semibold tracking-wide hover:bg-slate-200 active:bg-slate-300 cursor-pointer select-none rounded-[10px] px-3"
                        onClick={() => {
                          handleSelectedAllShipment1('currentPage');
                          setDisplaySelectOption(false);
                        }}
                      >
                        <p>Select all current page</p>
                      </div>
                    </div>
                  )}
                </div>
              </OutsideClickHandler>
              <div className="th flex items-center justify-between text-gray-600 relative h-[53px] w-[50px]">
                <p className="font-semibold elipe-text">No.</p>
              </div>
              <div className="th flex items-center justify-between text-gray-600 relative w-[190px]">
                {searchHawb !== '' && (
                  <div
                    className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                    onClick={() => setSearchHawb('')}
                  >
                    <FaFilterCircleXmark size={17} />
                  </div>
                )}
                <p className="font-semibold text-[darkblue] elipe-text">HAWB</p>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setDisplayHawbOption(false);
                  }}
                >
                  <div>
                    <div
                      className="w-[30px] h-[30px] flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 active:scale-90 rounded-full cursor-pointer"
                      onClick={() => setDisplayHawbOption(!displayHawbOption)}
                      //   onClick={() => handleSelectedContext(headerGroup, column.id, column.active)}
                    >
                      {displayHawbOption ? (
                        <FaSortUp size={28} className="mt-[14px]" />
                      ) : (
                        <FaSortDown size={28} className="mt-[-10px]" />
                      )}
                    </div>
                    {displayHawbOption && (
                      <div className="absolute bg-white w-[320px] z-[9999] mt-1.5 rounded-[10px] shadow-[rgba(0,0,0,0.1)_0px_10px_15px_-3px,rgba(0,0,0,0.15)_0px_4px_6px_-2px] left-0">
                        <p className="p-[10px] text-slate-500 tracking-wide text-[15px]">
                          <span className="underline font-bold text-black">HAWB</span>'s context menu
                        </p>
                        <div className="p-2" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
                          <div className="relative">
                            <input
                              type="text"
                              placeholder="Enter search value"
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input"
                              autoFocus
                              value={searchHawb}
                              onChange={(e) => setSearchHawb(e.target.value)}
                            />

                            <div
                              className="absolute right-1 w-[25px] h-[25px] rounded-full flex items-center justify-center top-[5.5px] cursor-pointer hover: bg-slate-100 active:bg-slate-200 active:scale-90"
                              onClick={() => {
                                setSearchHawb('');
                              }}
                            >
                              <MdOutlineClear size={23} />
                            </div>
                          </div>
                        </div>
                        <div className="mx-1 pb-1.5">
                          <div
                            className="flex items-center p-1 m-0.5 rounded-[10px] hover:bg-slate-100 active:bg-slate-200 cursor-pointer select-none"
                            onClick={() => {
                              setSortHawb(!sortHawb);
                              setDisplayHawbOption(false);
                            }}
                          >
                            <AiOutlineSortAscending size={27} />
                            <p className="ml-1.5">Sort HAWB</p>
                          </div>
                          <div
                            className="flex items-center p-1 m-0.5 rounded-[10px] hover:bg-slate-100 active:bg-slate-200 cursor-pointer select-none"
                            onClick={() => {
                              setDisplayHawbOption(false);
                            }}
                          >
                            <MdOutlineClear size={27} />

                            <p className="ml-1.5">Close context</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </OutsideClickHandler>
              </div>
            </div>
            {headerGroups?.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps({})} className="tr">
                {headerGroup.headers.map((column, index) => (
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      handleCloseContext(headerGroup, column.id, column.active);
                    }}
                  >
                    <div>
                      {column.active && (
                        <div
                          {...column.getHeaderProps()}
                          className="th flex items-center justify-between text-gray-600 relative"
                        >
                          {index === 0 && searchSin !== '' && (
                            <div
                              className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                              onClick={() => handleResetSearchColumn(index)}
                            >
                              <FaFilterCircleXmark size={17} />
                            </div>
                          )}
                          {index === 1 && searchDate !== '' && (
                            <div
                              className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                              onClick={() => handleResetSearchColumn(index)}
                            >
                              <FaFilterCircleXmark size={17} />
                            </div>
                          )}
                          {index === 2 && searchService !== '' && (
                            <div
                              className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                              onClick={() => handleResetSearchColumn(index)}
                            >
                              <FaFilterCircleXmark size={17} />
                            </div>
                          )}
                          {index === 3 && searchFrom !== '' && (
                            <div
                              className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                              onClick={() => handleResetSearchColumn(index)}
                            >
                              <FaFilterCircleXmark size={17} />
                            </div>
                          )}
                          {index === 4 && searchTo !== '' && (
                            <div
                              className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                              onClick={() => handleResetSearchColumn(index)}
                            >
                              <FaFilterCircleXmark size={17} />
                            </div>
                          )}
                          {index === 5 && searchAddress !== '' && (
                            <div
                              className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                              onClick={() => handleResetSearchColumn(index)}
                            >
                              <FaFilterCircleXmark size={17} />
                            </div>
                          )}
                          {index === 6 && searchAddress2 !== '' && (
                            <div
                              className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                              onClick={() => handleResetSearchColumn(index)}
                            >
                              <FaFilterCircleXmark size={17} />
                            </div>
                          )}
                          {index === 7 && searchCustomerName !== '' && (
                            <div
                              className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                              onClick={() => handleResetSearchColumn(index)}
                            >
                              <FaFilterCircleXmark size={17} />
                            </div>
                          )}
                          {index === 8 && searchType !== '' && (
                            <div
                              className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                              onClick={() => handleResetSearchColumn(index)}
                            >
                              <FaFilterCircleXmark size={17} />
                            </div>
                          )}
                          {index === 9 && searchWeight !== '' && (
                            <div
                              className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                              onClick={() => handleResetSearchColumn(index)}
                            >
                              <FaFilterCircleXmark size={17} />
                            </div>
                          )}
                          {index === 10 && searchPcs !== '' && (
                            <div
                              className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                              onClick={() => handleResetSearchColumn(index)}
                            >
                              <FaFilterCircleXmark size={17} />
                            </div>
                          )}
                          {index === 11 && searchStatus !== '' && (
                            <div
                              className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                              onClick={() => handleResetSearchColumn(index)}
                            >
                              <FaFilterCircleXmark size={17} />
                            </div>
                          )}
                          {index === 12 && searchValue !== '' && (
                            <div
                              className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                              onClick={() => handleResetSearchColumn(index)}
                            >
                              <FaFilterCircleXmark size={17} />
                            </div>
                          )}
                          {index === 13 && searchDescription !== '' && (
                            <div
                              className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                              onClick={() => handleResetSearchColumn(index)}
                            >
                              <FaFilterCircleXmark size={17} />
                            </div>
                          )}
                          <p className={`elipe-text }`}>{column.render('Header')}</p>
                          <div
                            className="w-[30px] h-[30px] flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 active:scale-90 rounded-full cursor-pointer"
                            onClick={() => handleSelectedContext(headerGroup, column.id, column.active)}
                          >
                            {column.context ? (
                              <FaSortUp size={28} className="mt-[14px]" />
                            ) : (
                              <FaSortDown size={28} className="mt-[-10px]" />
                            )}
                          </div>
                          {column.canResize && (
                            <div
                              {...column.getResizerProps()}
                              className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                            />
                          )}
                        </div>
                      )}
                      {column.context && (
                        <div className="absolute bg-white w-[320px] z-[9999] mt-1.5 rounded-[10px] shadow-[rgba(0,0,0,0.1)_0px_10px_15px_-3px,rgba(0,0,0,0.15)_0px_4px_6px_-2px]">
                          <p className="p-[10px] text-slate-500 tracking-wide text-[15px]">
                            <span className="underline font-bold text-black">{column.render('Header')}</span>'s context
                            menu
                          </p>
                          <div className="p-2" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
                            {index !== 1 &&
                              index !== 2 &&
                              index !== 9 &&
                              index !== 8 &&
                              index !== 11 &&
                              index !== 12 && (
                                <div className="relative">
                                  <input
                                    type="text"
                                    placeholder="Enter search value"
                                    className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input"
                                    onChange={(e) => handleChangeInputColumn(index, e)}
                                    autoFocus
                                    value={
                                      index === 0
                                        ? searchSin
                                        : index === 1
                                          ? searchDate
                                          : index === 2
                                            ? searchService
                                            : index === 3
                                              ? searchFrom
                                              : index === 4
                                                ? searchTo
                                                : index === 5
                                                  ? searchAddress
                                                  : index === 6
                                                    ? searchAddress2
                                                    : index === 7
                                                      ? searchCustomerName
                                                      : index === 8
                                                        ? searchType
                                                        : index === 9
                                                          ? searchWeight
                                                          : index === 10
                                                            ? searchPcs
                                                            : index === 11
                                                              ? searchStatus
                                                              : index === 12
                                                                ? searchValue
                                                                : index === 13
                                                                  ? searchDescription
                                                                  : ''
                                    }
                                  />

                                  <div
                                    className="absolute right-1 w-[25px] h-[25px] rounded-full flex items-center justify-center top-[5.5px] cursor-pointer hover: bg-slate-100 active:bg-slate-200 active:scale-90"
                                    onClick={() => {
                                      handleResetSearchColumn(index);
                                    }}
                                  >
                                    <MdOutlineClear size={23} />
                                  </div>
                                </div>
                              )}
                            {index === 1 && (
                              <input
                                type="date"
                                className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                                value={searchDate}
                                onChange={(e) => handleChangeInputColumn(index, e)}
                              />
                            )}
                            {index === 2 && (
                              <select
                                className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                                value={searchService}
                                onChange={(e) => setSearchService(e.target.value)}
                              >
                                <option value="">Select value</option>
                                {duplicateService
                                  ?.sort((x, y) => {
                                    return x.localeCompare(y);
                                  })
                                  .map((element) => (
                                    <option value={element}>{element}</option>
                                  ))}
                              </select>
                            )}
                            {index === 4 && (
                              <div>
                                <p className="p-1 mt-1 text-slate-600">Search by country code:</p>
                              </div>
                            )}
                            {index === 4 && (
                              <select
                                className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                                value={searchCode}
                                onChange={(e) => handleChangeCode(e)}
                              >
                                <option value="">Select value</option>
                                {duplicateCode
                                  ?.sort((x, y) => {
                                    return x.localeCompare(y);
                                  })
                                  .map((element) => (
                                    <option value={element}>{element}</option>
                                  ))}
                              </select>
                            )}
                            {index === 8 && (
                              <select
                                className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                                value={searchType}
                                onChange={(e) => setSearchType(e.target.value)}
                              >
                                <option value="">Select value</option>
                                <option value="WPX">WPX</option>
                                <option value="DOX">DOX</option>
                              </select>
                            )}
                            {index === 11 && (
                              <select
                                className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                                value={searchStatus}
                                onChange={(e) => setSearchStatus(e.target.value)}
                              >
                                <option value="">Select value</option>
                                {duplicateStatus
                                  ?.sort((x, y) => {
                                    return x.localeCompare(y);
                                  })
                                  .map((element) => (
                                    <option value={element}>{element}</option>
                                  ))}
                              </select>
                            )}
                            {index === 12 && (
                              <select
                                className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                              >
                                <option value="">Select value</option>
                                {duplicateValue
                                  ?.sort((x, y) => {
                                    return x - y;
                                  })
                                  .map((element) => (
                                    <option value={element}>{element}</option>
                                  ))}
                              </select>
                            )}
                            {index === 9 && (
                              <select
                                className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                                value={searchWeight}
                                onChange={(e) => handleChangeInputColumn(index, e)}
                              >
                                <option value="">Select value</option>
                                {duplicateWeight
                                  .sort((x, y) => {
                                    if (x > 0 && y > 0) {
                                      return x - y;
                                    }
                                    return y - x;
                                  })
                                  .map((element) => (
                                    <option value={element}>{element}</option>
                                  ))}
                              </select>
                            )}
                          </div>
                          <div className="mx-1 pb-1.5">
                            <div
                              className="flex items-center p-1 m-0.5 rounded-[10px] hover:bg-slate-100 active:bg-slate-200 cursor-pointer select-none"
                              onClick={() => {
                                handleSortShipmentColumn(index);
                                handleCloseContext(headerGroup, column.id, column.active);
                              }}
                            >
                              <AiOutlineSortAscending size={27} />
                              <p className="ml-1.5">Sort {column.render('Header')}</p>
                            </div>
                            <div
                              className="flex items-center p-1 m-0.5 rounded-[10px] hover:bg-slate-100 active:bg-slate-200 cursor-pointer select-none"
                              onClick={() => {
                                handleSelectedHeader(headerGroup, column.id, column.active);
                                handleCloseContext(headerGroup, column.id, column.active);
                              }}
                            >
                              <PiColumnsLight size={27} />

                              <p className="ml-1.5">Hide column</p>
                            </div>
                            <div
                              className="flex items-center p-1 m-0.5 rounded-[10px] hover:bg-slate-100 active:bg-slate-200 cursor-pointer select-none"
                              onClick={() => {
                                handleCloseContext(headerGroup, column.id, column.active);
                              }}
                            >
                              <MdOutlineClear size={27} />

                              <p className="ml-1.5">Close context</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </OutsideClickHandler>
                ))}
              </div>
            ))}
            <div
              className="bg-white flex items-center sticky right-0 z-10 shadow-[rgba(0,0,0,0.05)_2.4px_0px_0px]"
              style={{ border: '1px solid lightgray', borderBottom: 'none', borderRight: 'none' }}
            >
              <div className="w-[37px] h-[52px]  mr-1 cursor-pointer flex items-center justify-center rounded-full"></div>
            </div>
          </div>
          {rows?.length === 0 && (
            <div className="flex items-center justify-center h-full text-xl tracking-wide flex-col p-7">
              {showEmpty && <img src={emptyData} alt="" loading="lazy" className="w-[125px] h-[125px] object-cover" />}
              <p className={`${showEmpty ? 'text-slate-400 text-[15px]' : ''}`}>
                System is looking for shipments, please wait...
              </p>
              {onShowNoneShipment()}
              {showEmpty && (
                <div className="flex flex-col items-center">
                  <p>Seems like we can't find any shipment recently, or look back for your internet</p>
                  <button
                    type="button"
                    className="p-1.5 bg-sky-50 text-sky-600 font-bold rounded-[12px] px-7 cursor-pointer hover:bg-sky-100 active:bg-sky-200 my-2.5 mt-3.5 flex items-center"
                    onClick={() => window.location.reload()}
                  >
                    <MdAutoFixHigh size={30} className="mr-2" />
                    Retry now
                  </button>
                </div>
              )}
            </div>
          )}
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <DashboardBody
                row={row}
                rows={rows}
                index={index + 1}
                getTableBodyProps={getTableBodyProps}
                isColumnResizing={isColumnResizing}
                handleShowShipment={handleShowShipment}
                handleSelectedContextMenu={handleSelectedContextMenu}
                handleCloseContextMenu={handleCloseContextMenu}
                handleSelectedShipment={handleSelectedShipment}
              />
            );
          })}
          <DashboardBottom
            headerGroups={headerGroups}
            isColumnResizing={isColumnResizing}
            totalWeight={totalWeight}
            totalPieces={totalPieces}
            totalValue={totalValue}
          />
        </div>
      </div>
    </div>
  );
}
