import { IconButton } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffeect } from 'react';
import { MdOutlineClear } from 'react-icons/md';
import { PiAirplaneInFlightFill } from 'react-icons/pi';
import OutsideClickHandler from 'react-outside-click-handler';

export default function FlightScreen({ handleCloseFlight, user, todayFlight, traceTime, mergeFlight }) {
  const [loading, setLoading] = useState(false);
  const [mawb, setMawb] = useState('');
  const [flight, setFlight] = useState('');
  const [loadSize, setLoadSize] = useState(0);

  const handleFlightForm = (e) => {
    e.preventDefault();
    const flightData = {
      flight: flight,
      mawb: mawb,
      loadSize: loadSize,
      createAt: traceTime,
      date: new Date(),
    };
    axios.post('https://test.skydart.com.vn/api/flight/info', flightData);
    alert('Alert Succeed');
    setMawb('');
    setFlight('');
    mergeFlight(flightData);
  };
  return (
    <div>
      <div className="shipment-note">
        <OutsideClickHandler
          onOutsideClick={() => {
            handleCloseFlight();
          }}
        >
          <div className="shipment-note-container" style={{ width: '40%', marginTop: '15%', borderRadius: 10 }}>
            <div
              className="flex items-center p-2 justify-between px-3.5"
              style={{ borderBottom: '1px solid rgb(230,230,230)' }}
            >
              <h3 className="font-semibold tracking-wide text-xl">Flight Info</h3>
              <IconButton type="button" onClick={() => handleCloseFlight()}>
                <MdOutlineClear size={25} />
              </IconButton>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }} className="px-1">
              <form className="p-2 px-3" onSubmit={handleFlightForm}>
                <div className="shipment-container-flight-info-option" style={{ marginTop: 0 }}>
                  <h5 className="text-[13px] text-slate-500">Flight Name</h5>
                  <input
                    type="text"
                    required
                    placeholder="Enter flight name"
                    value={flight}
                    className="p-1.5 bg-white rounded-[10px] outline:none mt-1 w-[250px] search-column-input border-solid border-slate-300 border-[1px] focus:bg-white"
                    onChange={(e) => setFlight(e.target.value)}
                  />
                </div>
                <div className="shipment-container-flight-info-option" style={{ marginTop: 10 }}>
                  <h5 className="text-[13px] text-slate-500">Flight Number</h5>
                  <input
                    type="text"
                    required
                    placeholder="Enter flight number"
                    value={mawb}
                    className="p-1.5 bg-white rounded-[10px] outline:none mt-1 w-[250px] border-solid border-slate-300 border-[1px] search-column-input focus:bg-white"
                    onChange={(e) => setMawb(e.target.value)}
                  />
                </div>
                <div className="shipment-container-flight-info-option" style={{ marginTop: 10 }}>
                  <h5 className="text-[13px] text-slate-500">Load size</h5>
                  <input
                    type="number"
                    placeholder="Load size"
                    value={loadSize}
                    className="p-1.5 bg-white rounded-[10px] outline:none mt-1 w-[250px] search-column-input focus:bg-white border-solid border-slate-300 border-[1px]"
                    onChange={(e) => setLoadSize(e.target.value)}
                  />
                  <button
                    type="submit"
                    className=" rounded-[10px] w-full bg-sky-600 text-white font-semibold p-1.5 mt-5 mb-2"
                  >
                    Submit
                  </button>
                </div>
              </form>
              <div className="p-2 w-full max-h-[280px] overflow-y-auto">
                {todayFlight?.map((item) => (
                  <div className="p-1.5 bg-slate-100 m-1 rounded-[12px] px-3 mt-2" style={{ borderLeft: '13px solid' }}>
                    <div className="flex items-center">
                      <PiAirplaneInFlightFill size={18} />
                      <p className="text-[18px] ml-1.5 leading-3">{item.flight}</p>
                    </div>
                    <p className="text-[15px]">{item.mawb}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </div>
  );
}
