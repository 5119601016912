import { START_DATE, useDatepicker } from '@datepicker-react/hooks';
import { CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { AiOutlineMinus } from 'react-icons/ai';
import { BsArrowLeft, BsArrowRight, BsFileEarmarkRuled, BsPersonGear, BsPersonPlus, BsSearch } from 'react-icons/bs';
import { FaDownload, FaPrint, FaRegCalendarDays, FaSortDown } from 'react-icons/fa6';
import skydartLogo from '../../../skydartLogo.png';
import { FcCheckmark } from 'react-icons/fc';
import { GoCalendar } from 'react-icons/go';
import { HiOutlineViewfinderCircle } from 'react-icons/hi2';

import { IoIosArrowDropdown, IoIosGitMerge } from 'react-icons/io';
import { IoAdd, IoSend } from 'react-icons/io5';
import { RiEditBoxLine } from 'react-icons/ri';
import OutsideClickHandler from 'react-outside-click-handler';
import { useNavigate } from 'react-router-dom';
import WorkSpace from '../../components/WorkSpace';
import DatepickerContext from '../new-booking/components/datepickerContext';
import Month1 from '../new-booking/components/Month1';
import { FiMoreHorizontal } from 'react-icons/fi';
import CreateDebit from './components/CreateDebit';

export default function Debit() {
  let newDate = new Date();
  const navigate = useNavigate();
  const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
  let date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
  const hour1 = (newDate.getHours() < 10 ? '0' : '') + newDate.getHours();
  const min1 = (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes();
  const etdHour = +hour1.substring(0, 2) + 2;
  const finalEtd = `${etdHour}:${min1}`;
  const month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
  const year = newDate.getFullYear();
  let firstDayOfCurrentWeek = date - (newDate.getDay() < 10 ? '0' : '');
  let last = firstDayOfCurrentWeek + 6;
  let firstday = firstDayOfCurrentWeek - 6;

  let lastDayOfCurrentWeek = last < 10 ? `0${last}` : last;
  let firstDayOfLastWeek = firstday < 10 ? `0${firstday}` : firstday;
  const first = (firstDay.getDate() < 10 ? '0' : '') + firstDay.getDate();
  let yesterday = date - 1;
  let lastMonth = month - 1;
  let lastDay = new Date(year, lastMonth, 0);
  let firstDayOfYear = new Date(year, 0, 1);
  let lastPrevMonthDay = newDate.setMonth(newDate.getMonth(), 0);
  let prevMonth = lastMonth < 10 ? `0${lastMonth}` : lastMonth;
  const [loading, setLoading] = useState(false);
  const [systemStatus, setSystemStatus] = useState('Very good');
  const [lastUpdateStatus, setLastUpdateStatus] = useState('');
  const [notes, setNotes] = useState([]);
  const [dateStatus, setDateStatus] = useState('Period');
  const [displayDate, setDisplayDate] = useState(false);
  const [startDate, setStartDate] = useState(`${year}-${month}-${first}`);
  const [endDate, setEndDate] = useState(new Date().toISOString().substr(0, 10));
  const [displayCreate, setDisplayCreate] = useState(false);
  const [displaySending, setDisplaySending] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    focusedInput: START_DATE,
  });
  function handleDateChange(data) {
    if (!data.focusedInput) {
      setDateRange({ ...data, focusedInput: START_DATE });
    } else {
      setDateRange(data);
    }
  }
  const handleToday = () => {
    setStartDate(`${year}-${month}-${date}`);
    setEndDate(`${year}-${month}-${date}`);
    setDisplayDate(false);
    setDateStatus('Today');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleYesterday = () => {
    if (yesterday === 0) {
      setStartDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
      setEndDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
      setDisplayDate(false);
      setDateStatus('Yesterday');
      setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
    } else {
      setStartDate(`${year}-${month}-${yesterday}`);
      setEndDate(`${year}-${month}-${yesterday}`);
      setDisplayDate(false);
      setDateStatus('Yesterday');
      setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
    }
  };

  const handleThisWeek = () => {
    setStartDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
    setEndDate(`${year}-${month}-${lastDayOfCurrentWeek}`);
    setDisplayDate(false);
    setDateStatus('This week');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleLastWeek = () => {
    setStartDate(`${year}-${month}-${firstDayOfLastWeek}`);
    setEndDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
    setDisplayDate(false);
    setDateStatus('Last week');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };

  const handleThisMonth = () => {
    setStartDate(`${year}-${month}-${first}`);
    setEndDate(new Date(currentYear, new Date().getMonth() + 1).toISOString().split('T')[0]);
    setDisplayDate(false);
    setDateStatus('This month');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleLastMonth = () => {
    setStartDate(`${year}-${prevMonth}-01`);
    setEndDate(`${year}-${prevMonth}-${new Date(lastPrevMonthDay).getDate().toString()}`);
    setDisplayDate(false);
    setDateStatus('Last month');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleThisYear = () => {
    setStartDate(`${year}-01-01`);
    setEndDate(`${year}-12-31`);
    setDateStatus('This year');
    setDisplayDate(false);
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    focusedInput: dateRange.focusedInput,
    onDatesChange: handleDateChange,
  });
  const startDay = new Date(startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  const endDay = new Date(endDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  const currentYear = new Date().getFullYear();
  const refreshLabel = () => {};
  const handleCloseDate = () => {
    setDisplayDate(false);
  };
  const handleApplyDate = () => {};
  const handleShowDate = () => {
    setDisplayDate(!displayDate);
  };
  const handleCancelDate = () => {};

  return (
    <div className="w-full overflow-auto">
      <WorkSpace
        refreshShipment={refreshLabel}
        loading={loading}
        finalShipment={notes}
        systemStatus={systemStatus}
        currentRoute="Debit list"
        lastUpdateStatus={lastUpdateStatus}
      />
      <CreateDebit />
      <div className="p-3 pb-0 w-full flex items-center" style={{ borderBottom: '1px solid lightgray' }}>
        <div
          className="h-[38px] font-semibold tracking-wide hover:opacity-70 active:opacity-50 select-none cursor-pointer px-2.5"
          style={{ borderBottom: '2px solid' }}
        >
          <p>Debit list</p>
        </div>
        <div className="h-[38px] font-semibold tracking-wide hover:opacity-70 active:opacity-50 select-none cursor-not-allowed opacity-50 px-2.5 text-slate-700 hover:text-black">
          <p>Debit preview</p>
        </div>
        <div className="ml-auto p-1.5 font-semibold px-3 rounded-[10px] flex items-center cursor-pointer hover:bg-slate-200 active:bg-slate-300 select-none">
          <IoAdd size={26} />
          <p className="ml-1.5">Create debit note</p>
        </div>
      </div>
      <div className="p-2 mx-1">
        <div className="flex items-center">
          <div className="flex items-center relative">
            <BsSearch
              className="absolute left-2 p-1 bg-slate-100 rounded-full border-solid border-slate-300 border-[1px]"
              size={28}
            />
            <input
              type="text"
              placeholder="Search debit..."
              className="bg-white border-solid border-slate-300 border-[1px] p-1.5 rounded-full px-4 w-[320px] pl-[42px] search-column-input"
            />
          </div>
          <div className="flex items-center ml-2">
            <CircularProgress size={25} />
            <p className="ml-2.5">Loading...</p>
          </div>
          <div className="p-2 ml-auto select-none cursor-pointer flex items-center bg-emerald-50 text-emerald-600 rounded-[10px] px-3 hover:bg-emerald-100 active:bg-emerald-200 font-semibold mx-2">
            <p>20 Selected</p>
            <FaSortDown size={23} className="mt-[-10px] ml-2" />
          </div>
          <div className="w-fit mr-1 whitespace-nowrap">
            <OutsideClickHandler
              onOutsideClick={() => {
                handleCloseDate();
              }}
            >
              <div className="relative">
                <div
                  className="flex p-2 bg-slate-200 rounded-[10px] hover:bg-slate-300 active:bg-slate-400 select-none transition-[0.1s] cursor-pointer"
                  onClick={() => handleShowDate()}
                >
                  <GoCalendar size={24} />
                  <p style={{ marginRight: 7, marginLeft: 7 }}>{dateStatus}:</p>
                  <p style={{ marginRight: 7 }}>{startDay}</p>
                  <AiOutlineMinus size={20} style={{ marginRight: 7 }} />
                  <p style={{ marginRight: 7 }}>{endDay}</p>
                  <IoIosArrowDropdown size={24} />
                </div>
                {displayDate && (
                  <div className="skd-user-date" style={{ right: 0 }}>
                    <DatepickerContext.Provider
                      value={{
                        focusedDate,
                        isDateFocused,
                        isDateSelected,
                        isDateHovered,
                        isDateBlocked,
                        isFirstOrLastSelectedDate,
                        onDateSelect,
                        onDateFocus,
                        onDateHover,
                      }}
                    >
                      <div className="skd-user-date-content">
                        <div className="skd-user-date-left">
                          <div
                            className={
                              dateStatus === 'Today'
                                ? 'skydart-date-range-right-option1'
                                : 'skydart-date-range-right-option'
                            }
                            onClick={handleToday}
                          >
                            {dateStatus === 'Today' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                            <p style={{ color: 'black' }}>Today</p>
                          </div>
                          <div
                            className={
                              dateStatus === 'Yesterday'
                                ? 'skydart-date-range-right-option1'
                                : 'skydart-date-range-right-option'
                            }
                            onClick={handleYesterday}
                          >
                            {dateStatus === 'Yesterday' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                            <p style={{ color: 'black' }}>Yesterday</p>
                          </div>
                          <div
                            className={
                              dateStatus === 'This week'
                                ? 'skydart-date-range-right-option1'
                                : 'skydart-date-range-right-option'
                            }
                            onClick={handleThisWeek}
                          >
                            {dateStatus === 'This week' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                            <p style={{ color: 'black' }}>This week</p>
                          </div>
                          <div
                            className={
                              dateStatus === 'Last week'
                                ? 'skydart-date-range-right-option1'
                                : 'skydart-date-range-right-option'
                            }
                            onClick={handleLastWeek}
                          >
                            {dateStatus === 'Last week' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                            <p style={{ color: 'black' }}>Last week</p>
                          </div>
                          <div
                            className={
                              dateStatus === 'This month'
                                ? 'skydart-date-range-right-option1'
                                : 'skydart-date-range-right-option'
                            }
                            onClick={handleThisMonth}
                          >
                            {dateStatus === 'This month' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                            <p style={{ color: 'black' }}>This Month</p>
                          </div>
                          <div
                            className={
                              dateStatus === 'Last month'
                                ? 'skydart-date-range-right-option1'
                                : 'skydart-date-range-right-option'
                            }
                            onClick={handleLastMonth}
                          >
                            {dateStatus === 'Last month' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                            <p style={{ color: 'black' }}>Last Month</p>
                          </div>
                          <div
                            className={
                              dateStatus === 'This year'
                                ? 'skydart-date-range-right-option1'
                                : 'skydart-date-range-right-option'
                            }
                            onClick={handleThisYear}
                          >
                            {dateStatus === 'This year' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                            <p style={{ color: 'black' }}>This Year</p>
                          </div>
                        </div>
                        <div className="skd-user-date-right">
                          <div
                            style={{
                              display: 'flex',
                              margin: '15px 0 0',
                              gridTemplateColumns: `repeat(${activeMonths.length}, 300px)`,
                              gridGap: '0 24px',
                            }}
                          >
                            <div className="date-month-back" onClick={goToPreviousMonths}>
                              <BsArrowLeft />
                            </div>
                            <div className="date-month-back1" onClick={goToNextMonths}>
                              <BsArrowRight />
                            </div>
                            {activeMonths.map((month) => (
                              <Month1
                                key={`${month.year}-${month.month}`}
                                year={month.year}
                                month={month.month}
                                firstDayOfWeek={firstDayOfWeek}
                              />
                            ))}
                          </div>
                          <div className="skd-user-date-shown">
                            <div className="skd-user-date-shown-range">
                              <h5 style={{ fontWeight: 450 }}>
                                {!dateRange.startDate ? 'Start Date' : dateRange.startDate.toDateString()}
                              </h5>
                              <AiOutlineMinus />
                              <h5 style={{ fontWeight: 450, marginLeft: 7 }}>
                                {!dateRange.endDate ? 'End Date' : dateRange.endDate.toDateString()}
                              </h5>
                              <p style={{ fontSize: 10 }}>Ho Chi Minh City time</p>
                            </div>
                            <div className="skd-user-date-update-button">
                              <div className="skd-user-date-update-cancel" onClick={handleCancelDate}>
                                <h5 style={{ color: 'black', textAlign: 'center' }}>Cancel</h5>
                              </div>
                              <div className="skd-user-date-update-succeed" onClick={handleApplyDate}>
                                <h5 style={{ color: 'white', textAlign: 'center' }}>Update</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </DatepickerContext.Provider>
                  </div>
                )}
              </div>
            </OutsideClickHandler>
          </div>
        </div>
      </div>
      <div className="mt-1 mb-3 bg-white rounded-[10px] shadow-[rgba(0,0,0,0.12)_0px_1px_3px,rgba(0,0,0,0.24)_0px_1px_2px] mx-3.5">
        <div className="p-2 flex items-center gap-x-2">
          <div className="flex items-center p-1.5 text-[white] cursor-pointer select-none bg-emerald-600 hover:bg-emerald-700 px-2.5 rounded-[10px] active:bg-emerald-800">
            <IoSend size={20} />
            <p className="mx-2 text-[15px] font-semibold tracking-wide">Send debit</p>
          </div>
          <div className="flex items-center p-1.5 px-2.5 bg-slate-100 cursor-pointer select-none hover:bg-slate-200 active:bg-slate-300 rounded-[10px]">
            <FaPrint size={20} />
            <p className="mx-2 text-[15px] font-semibold tracking-wide">Print draft</p>
          </div>
          <div className="flex items-center p-1.5 px-2.5 bg-slate-100 cursor-pointer select-none hover:bg-slate-200 active:bg-slate-300 rounded-[10px]">
            <FaDownload size={20} />
            <p className="mx-2 text-[15px] font-semibold tracking-wide">Export report</p>
          </div>
          <div className="flex items-center p-1.5 px-2.5 bg-slate-100 cursor-pointer select-none hover:bg-slate-200 active:bg-slate-300 rounded-[10px]">
            <IoIosGitMerge size={20} />
            <p className="mx-2 text-[15px] font-semibold tracking-wide">Merge debit</p>
          </div>
          <div className="flex items-center p-1.5 px-2.5 bg-slate-100 cursor-pointer select-none hover:bg-slate-200 active:bg-slate-300 rounded-[10px]">
            <RiEditBoxLine size={20} />
            <p className="mx-2 text-[15px] font-semibold tracking-wide">Action</p>
          </div>
        </div>
        <div
          className="h-[calc(100vh_-_17.3rem)] bg-gradient-to-r from-neutral-50 to-blue-50 overflow-auto"
          style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
        >
          <div className="p-2 my-1.5 flex flex-wrap items-start gap-2">
            <div className="w-[24.5%] bg-white shadow-[rgba(0,0,0,0.1)_0px_4px_12px] rounded-[10px] border-solid border-slate-300 border-[1px]">
              <div className="bg-slate-100 p-3" style={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }}>
                <div className="p-2.5 bg-white rounded-[12px]">
                  <p>Vu Nguyen</p>
                </div>
              </div>
              <p>VU Nguyen</p>
            </div>
            <div className="w-[24.5%] bg-white shadow-[rgba(0,0,0,0.1)_0px_4px_12px] rounded-[10px] border-solid border-slate-300 border-[1px]">
              <div className="bg-slate-100 p-3" style={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }}>
                <div className="p-2.5 bg-white rounded-[12px] relative">
                  <div className="absolute right-0 top-[-20px] p-1 px-2.5 rounded-[5px] bg-sky-50 text-sky-600">
                    <p>New</p>
                  </div>
                  <div className="flex items-center justify-between text-black">
                    <p className="text-[22px] font-semibold tracking-wide w-full overflow-hidden text-ellipsis whitespace-nowrap">
                      Dragon Expreqwfewfeqwwess
                    </p>
                  </div>
                  <p>D23023902</p>
                  <div className="flex items-center justify-between mt-1 text-black">
                    <p>Total shipments:</p>
                    <p>2k4</p>
                  </div>
                  <div className="flex items-center justify-between mt-1 text-emerald-600">
                    <p>Total weights:</p>
                    <p className="bg-emerald-50">2000.00 KG</p>
                  </div>
                  <div className="flex items-center justify-between mt-1 text-[darkblue]">
                    <p>Total value:</p>
                    <p className="underline">20,000 USD</p>
                  </div>
                  <div className="flex items-center justify-between mt-1 text-rose-600">
                    <p>Total VND:</p>
                    <p className="underline">20,000,000 VND</p>
                  </div>
                </div>
                <div className="flex items-center mt-1.5 text-slate-600">
                  <BsFileEarmarkRuled size={18} />
                  <p className="ml-1.5">Dragon debit note 2023-2024</p>
                </div>
                <div className="flex items-center mt-1.5 text-slate-600">
                  <FaRegCalendarDays size={18} />

                  <p className="ml-1.5">5 Apr, 2024</p>
                </div>
                <div className="flex items-center mt-1.5 text-slate-600">
                  <p className="ml-[1px]">
                    <span className="text-black">From: 20/03/2024 - To: 20/04/2024</span>
                  </p>
                </div>
                <div className="flex items-center mt-1.5 text-slate-600">
                  <BsPersonPlus size={18} />
                  <p className="ml-1.5">
                    Collected: <span className="text-black">VU Nguen</span>
                  </p>
                </div>
                <div className="flex items-center mt-1.5 text-slate-600">
                  <BsPersonGear size={18} />
                  <p className="ml-1.5">
                    <span className="text-black">VU Nguen</span>
                  </p>
                </div>
              </div>
              <div className="p-1.5 flex items-center gap-x-1.5">
                <div className="w-[33px] h-[33px] rounded-[10px] flex items-center justify-center bg-slate-100 cursor-pointer p-1 hover:bg-slate-200 active:bg-slate-300 active:scale-90">
                  <HiOutlineViewfinderCircle size={27} />
                </div>
                <div className="w-[33px] h-[33px] rounded-[10px] flex items-center justify-center bg-slate-100 cursor-pointer p-1 hover:bg-slate-200 active:bg-slate-300 active:scale-90">
                  <FaDownload size={27} />
                </div>
                <div className="w-[33px] h-[33px] rounded-[10px] flex items-center justify-center bg-white cursor-pointer p-1 active:scale-90 ml-auto text-slate-500">
                  <FiMoreHorizontal size={27} />
                </div>
              </div>
            </div>
            <div className="w-[24.5%] bg-white shadow-[rgba(0,0,0,0.1)_0px_4px_12px] rounded-[10px] border-solid border-slate-300 border-[1px]">
              <div className="bg-slate-100 p-3" style={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }}>
                <div className="p-2.5 bg-white rounded-[12px]">
                  <p>Vu Nguyen</p>
                </div>
              </div>
              <p>VU Nguyen</p>
            </div>
            <div className="w-[24.5%] bg-white shadow-[rgba(0,0,0,0.1)_0px_4px_12px] rounded-[10px] border-solid border-slate-300 border-[1px]">
              <div className="bg-slate-100 p-3" style={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }}>
                <div className="p-2.5 bg-white rounded-[12px]">
                  <p>Vu Nguyen</p>
                </div>
              </div>
              <p>VU Nguyen</p>
            </div>
            <div className="w-[24.5%] bg-white shadow-[rgba(0,0,0,0.1)_0px_4px_12px] rounded-[10px] border-solid border-slate-300 border-[1px]">
              <div className="bg-slate-100 p-3" style={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }}>
                <div className="p-2.5 bg-white rounded-[12px]">
                  <p>Vu Nguyen</p>
                </div>
              </div>
              <div className="p-1.5 flex items-center">
                <div className="w-[27px] h-[27px] rounded-[10px]">
                  <HiOutlineViewfinderCircle size={23} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
