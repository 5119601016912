import React from 'react';

function ShipmentBody({ row, getTableBodyProps }) {
  return (
    <div {...getTableBodyProps}>
      <div {...row.getRowProps()} className="tr3">
        <div style={{ display: 'flex' }}>
          {row.cells.map((cell, index) => {
            return (
              <div>
                {cell.column.active && (
                  <div {...cell.getCellProps()} className={`td flex items-center ${index === 2 ? '' : ''}`}>
                    {index === 4 && (
                      <img
                        src={`https://flagsapi.com/${cell.row.original.code}/shiny/64.png`}
                        className="w-[23px] h-[23px] mr-2"
                      />
                    )}
                    <p
                      className={
                        index === 6 && cell.row.original.service === 'SKD'
                          ? 'art tracking-wide w-full'
                          : index === 0
                            ? 'text-rose-600 w-full'
                            : index === 1
                              ? 'text-fuchsia-800 tracking-wide w-full'
                              : index === 10
                                ? 'text-rose-600 underline tracking-wide w-full'
                                : index === 29
                                  ? 'text-rose-600 underline tracking-wide w-full'
                                  : index === 18
                                    ? `${
                                        cell.row.original.select ? 'bg-emerald-500' : 'bg-emerald-50'
                                      } text-emerald-600 rounded-full px-2 w-fit tracking-wide`
                                    : index === 5 && cell.row.original.status === 'Picked Up'
                                      ? `bg-amber-500 text-white rounded-full px-3 w-fit tracking-wide`
                                      : index === 5 && cell.row.original.status === 'Arrived At Skydart Origin Depot'
                                        ? 'bg-sky-500 text-white rounded-full px-3 w-fit tracking-wide'
                                        : index === 5 && cell.row.original.status === 'In Process'
                                          ? 'bg-fuchsia-500 text-white rounded-full px-3 w-fit tracking-wide'
                                          : index === 5 && cell.row.original.status === 'On Hold'
                                            ? 'bg-rose-500 text-white rounded-full px-3 w-fit tracking-wide'
                                            : index === 5 && cell.row.original.status === 'In Clearance'
                                              ? 'bg-orange-500 text-white rounded-full px-3 w-fit tracking-wide'
                                              : index === 5 && cell.row.original.status === 'Cleared For Export'
                                                ? 'bg-pink-500 text-white rounded-full px-3 w-fit tracking-wide'
                                                : index === 5 && cell.row.original.status === 'In Transit'
                                                  ? 'bg-blue-500 text-white rounded-full px-3 w-fit tracking-wide'
                                                  : index === 5 && cell.row.original.status === 'Delivered'
                                                    ? 'bg-emerald-600 text-white rounded-full px-3 w-fit tracking-wide'
                                                    : 'tracking-wide w-full'
                      }
                      style={
                        cell.row.original.select
                          ? { color: 'white', fontWeight: 600, textAlign: index === 0 ? 'center' : '' }
                          : index === 14 && cell.row.original.service === 'DUS'
                            ? {
                                fontWeight: 600,
                                color: 'white',
                                background: 'black',
                                textAlign: 'center',
                                borderRadius: 3,
                              }
                            : index === 14 && cell.row.original.service === 'UPS'
                              ? {
                                  color: 'rgb(247,202,136)',
                                  background: 'brown',
                                  textAlign: 'center',
                                  fontWeight: 600,
                                  borderRadius: 3,
                                }
                              : index === 14 && cell.row.original.service === 'FIP'
                                ? {
                                    background: 'purple',
                                    color: 'white',
                                    textAlign: 'center',
                                    fontWeight: 600,
                                    borderRadius: 3,
                                  }
                                : index === 14 && cell.row.original.service === 'FIE'
                                  ? {
                                      background: 'purple',
                                      color: 'white',
                                      textAlign: 'center',
                                      fontWeight: 600,
                                      borderRadius: 3,
                                    }
                                  : index === 14 && cell.row.original.service === 'DHL-VN'
                                    ? {
                                        borderRadius: 3,
                                        fontWeight: 600,
                                        background: 'yellow',
                                        color: 'red',
                                        textAlign: 'center',
                                      }
                                    : index === 14 && cell.row.original.service === 'DHL'
                                      ? {
                                          borderRadius: 3,
                                          fontWeight: 600,
                                          background: 'yellow',
                                          color: 'red',
                                          textAlign: 'center',
                                        }
                                      : index === 0
                                        ? { textAlign: 'center' }
                                        : index === 14
                                          ? { textAlign: 'center' }
                                          : index === 7
                                            ? { color: 'darkblue', textDecoration: 'underline' }
                                            : { textAlign: 'left' }
                      }
                    >
                      {cell.value}{' '}
                      {index === 7
                        ? 'KG'
                        : index === 8
                          ? 'KG'
                          : index === 9
                            ? 'KG'
                            : index === 10
                              ? 'KG'
                              : index === 11
                                ? 'KG'
                                : ''}
                    </p>
                    {index === 1 && (
                      <h1 className="text-[13px] bg-sky-100 text-sky-600 whitespace-nowrap">
                        /{cell.row.original.makh}
                      </h1>
                    )}
                    {index === 1 && (
                      <span className="tr4-helper text-[15px] px-2">
                        {cell.value} <strong className="text-rose-500">/ {cell.row.original.makh}</strong>
                      </span>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default React.memo(ShipmentBody, (prevProps, nextProps) => nextProps.isColumnResizing);
