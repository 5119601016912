import { CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { FaPrint, FaSortDown } from 'react-icons/fa6';
import { FcCheckmark } from 'react-icons/fc';
import { FiPackage } from 'react-icons/fi';
import { GiEmptyHourglass } from 'react-icons/gi';
import { IoNewspaperOutline } from 'react-icons/io5';
import { MdOutlineClear } from 'react-icons/md';
import { PiGitPullRequest, PiSlideshowThin } from 'react-icons/pi';
import OutsideClickHandler from 'react-outside-click-handler';
import { useNavigate } from 'react-router-dom';

export default function DashboardEdit({ shipmentData, user, handleCloseShipment, stat }) {
  const [loading, setLoading] = useState(false);
  const [displayPrint, setDisplayPrint] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [traceTime, setTraceTime] = useState('');
  const [dateTime, setDateTime] = useState('');
  const handleShowPrint = () => {
    setDisplayPrint(!displayPrint);
  };
  useEffect(() => {
    let newDate = new Date();
    let date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
    let year = newDate.getFullYear();
    const monthName = months[newDate.getMonth()];
    setTraceTime(`${year}-${month}-${date}`);
    const hour = (newDate.getHours() < 10 ? '0' : '') + newDate.getHours();
    const min = (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes();
    setDateTime(`${hour}:${min}`);
  }, []);

  const rebookShipment = () => {
    setLoading(true);
    if (shipmentData?.shipmentType === 'WPX') {
      axios.post('https://test.skydart.com.vn/api/create/shipment', {
        account: '786009',
        currency: 'USD',
        service: shipmentData?.service,
        date: traceTime,
        hawb: shipmentData?.hawb,
        mawb: 'Pending',
        makh: shipmentData?.makh,
        company: shipmentData?.importer,
        contact: shipmentData?.toName,
        otherTerm: shipmentData?.otherTerm,
        type: shipmentData?.shipmentType,
        status: shipmentData?.status,
        term: shipmentData?.term,
        time: dateTime,
        address: shipmentData?.toAddress,
        address2: shipmentData?.toAddress2 || '.',
        address3: shipmentData?.toAddress3,
        city: shipmentData?.toCity,
        country: shipmentData?.toCountry,
        code: shipmentData?.code,
        phone: shipmentData?.toPhone,
        phone2: shipmentData?.toPhone2 || '.',
        postal: shipmentData?.toPostal,
        state: shipmentData?.toState,
        plan: shipmentData?.plan || 'Pending',
        value: shipmentData?.totalInvoiceValue,
        town: shipmentData?.toTown,
        chargeable: shipmentData?.totalCharge,
        amount: shipmentData?.totalPackage,
        totalItem: shipmentData?.totalItem,
        transport: shipmentData?.transportation,
        description: shipmentData?.description,
        controller: shipmentData?.controller || 'Pending',
        eamFormat: 'NDX',
        dateTime: new Date(),
        airline: 'Pending',
        reweight: shipmentData?.reweight,
        etd: 'Pending',
        agent: 'Pending',
        subStatus: 'Pending',
        sin: 'Null',
        sender: shipmentData?.exporter,
        note: 'Null',
        perform: 'Null',
        fromEmail: shipmentData?.fromEmail,
        fromCity: shipmentData?.fromCity,
        totalValue: '0.00',
        rate: '0.00',
        comestic: '0.00',
        addressCorrection: '0.00',
        remote: '0.00',
        surcharge: '0.00',
        surcharge2: '0.00',
        surcharge3: '0.00',
        fsc: '0',
        fscValue: '0.00',
        vat: '0.00',
        vatValue: '0.00',
        paymentPickup: 'CRE',
        paymentAcc: 'CRE',
        paymentCompany: 'CRE',
        paymentStatus: 'Un Paid',
        pickupBy: user.displayName,
        collectBy: 'Pending',
        rateUpdate: shipmentData?.createAt,
        sellNote: 'Pending',
        com: '0.00',
        sellNote: 'Pending',
        paymentSubStatus: 'Rebook',
        eamService: shipmentData?.eamService,
        usage: shipmentData?.usage,
        pack: 'Pending',
      });
      setTimeout(() => {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          handleCloseShipment();
        }, 5000);
      }, 1000);
    } else if (shipmentData?.shipmentType === 'DOX') {
      axios.post('https://test.skydart.com.vn/api/create/shipment', {
        account: '786009',
        currency: 'USD',
        service: shipmentData?.service,
        date: traceTime,
        hawb: shipmentData?.hawb,
        mawb: 'Pending',
        makh: shipmentData?.makh,
        company: shipmentData?.importer,
        contact: shipmentData?.toName,
        otherTerm: shipmentData?.otherTerm,
        type: shipmentData?.shipmentType,
        status: shipmentData?.status,
        term: shipmentData?.term,
        time: dateTime,
        address: shipmentData?.toAddress,
        address2: shipmentData?.toAddress2 || '.',
        address3: shipmentData?.toAddress3,
        city: shipmentData?.toCity,
        country: shipmentData?.toCountry,
        code: shipmentData?.code,
        phone: shipmentData?.toPhone,
        phone2: shipmentData?.toPhone2,
        postal: shipmentData?.toPostal,
        state: shipmentData?.toState,
        value: shipmentData?.totalInvoiceValue,
        town: shipmentData?.toTown,
        chargeable: shipmentData?.totalCharge,
        amount: shipmentData?.totalPackage,
        totalItem: shipmentData?.totalItem,
        transport: shipmentData?.transportation,
        description: 'Document',
        controller: shipmentData?.controller || 'Pending',
        eamFormat: 'DOX',
        dateTime: new Date(),
        airline: 'Pending',
        reweight: shipmentData?.totalCharge,
        etd: 'Pending',
        agent: 'Pending',
        subStatus: 'Pending',
        sin: 'Null',
        sender: shipmentData?.exporter,
        note: 'Null',
        perform: 'Null',
        fromEmail: shipmentData?.fromEmail,
        fromCity: shipmentData?.fromCity,
        totalValue: '0.00',
        rate: shipmentData?.rate || '0.00',
        comestic: '0.00',
        addressCorrection: '0.00',
        remote: '0.00',
        surcharge: '0.00',
        surcharge2: '0.00',
        surcharge3: '0.00',
        fsc: shipmentData?.fsc || '0',
        fscValue: shipmentData?.fscValue || '0.00',
        vat: '0',
        vatValue: '0.00',
        paymentPickup: 'CRE',
        paymentAcc: 'CRE',
        paymentCompany: 'CRE',
        paymentStatus: 'Un Paid',
        pickupBy: user.displayName,
        collectBy: 'Pending',
        rateUpdate: shipmentData?.createAt,
        sellNote: 'Pending',
        com: '0.00',
        sellNote: 'Pending',
        paymentSubStatus: 'Rebook',
        eamService: shipmentData?.eamService,
        usage: shipmentData?.usage,
        pack: 'Pending',
        plan: shipmentData?.plan || 'Pending',
      });
      setTimeout(() => {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          handleCloseShipment();
        }, 5000);
      }, 1000);
    }
  };

  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 bg-[rgba(0,0,0,0.55)] z-[9999] flex items-center justify-center">
      <OutsideClickHandler
        onOutsideClick={() => {
          handleCloseShipment();
        }}
      >
        <div className="w-[calc(100vw_-_20rem)] overflow-auto h-[calc(100vh_-_5rem)] bg-slate-100 rounded-[10px]">
          <div
            className="p-3 flex items-center justify-between bg-white sticky top-0 z-10"
            style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, borderBottom: '1px solid rgb(230,230,230)' }}
          >
            <p className="text-xl font-semibold tracking-wide">Shipment review</p>
            <div
              className="w-[35px] h-[35px] rounded-[10px] bg-slate-100 hover:bg-slate-200 active:bg-slate-300 active:scale-90 flex items-center justify-center cursor-pointer"
              onClick={() => handleCloseShipment()}
            >
              <MdOutlineClear size={28} />
            </div>
          </div>
          <div className="w-full flex items-center justify-center flex-col mt-2">
            {stat === 'cloud' && !loading && !success && (
              <div
                className="m-1.5 p-1.5 bg-[darkblue] text-white flex items-center font-semibold px-3 cursor-pointer rounded-[10px] hover:opacity-70 select-none active:scale-95 active:opacity-50"
                onClick={rebookShipment}
              >
                <PiGitPullRequest size={25} />
                <p className="mx-1.5">Pull shipment</p>
              </div>
            )}
            {success && !loading && (
              <div className="m-1.5 p-1.5 bg-emerald-50 text-emerald-600 flex items-center font-semibold px-3 cursor-pointer rounded-[10px] hover:opacity-70 select-none active:scale-95 active:opacity-50">
                <FcCheckmark size={25} />
                <p className="mx-1.5">Shipment pulled successfully, the window will close in a few seconds</p>
              </div>
            )}
            {loading && (
              <div className="m-1.5 p-1.5 bg-[darkblue] text-white flex items-center font-semibold px-3 cursor-pointer rounded-[10px] hover:opacity-70 select-none active:scale-95 active:opacity-50 opacity-50">
                <CircularProgress size={25} style={{ color: 'white' }} />
                <p className="mx-1.5">Pulling shipment...</p>
              </div>
            )}
            {stat === 'exist' && !loading && (
              <div className="m-1.5 p-1.5 bg-slate-200 flex items-center font-semibold px-3 cursor-pointer rounded-[10px] hover:bg-slate-300 active:bg-slate-400 select-none active:scale-95">
                <PiSlideshowThin size={25} />
                <p className="mx-1.5">
                  This shipment is already exist on the system please double check it back from shipments
                </p>
              </div>
            )}
            {stat === 'null' && !loading && (
              <div className="m-1.5 p-1.5 bg-rose-50 flex items-center font-semibold px-3 cursor-pointer rounded-[10px] hover:bg-rose-100 active:bg-rose-200 text-rose-600 select-none active:scale-95">
                <GiEmptyHourglass size={25} />
                <p className="mx-1.5">This shipment does not exist on the system yet</p>
              </div>
            )}
            {stat === 'err' && !loading && (
              <div className="m-1.5 p-1.5 bg-rose-50 flex items-center font-semibold px-3 cursor-pointer rounded-[10px] hover:bg-rose-100 active:bg-rose-200 text-rose-600 select-none active:scale-95">
                <GiEmptyHourglass size={25} />
                <p className="mx-1.5">Unexpected error occurred, please contact IT for more info</p>
              </div>
            )}
            {stat === 'new' && !loading && (
              <div className="m-1.5 p-1.5 bg-sky-50 flex items-center font-semibold px-3 cursor-pointer rounded-[10px] hover:bg-sky-100 active:bg-sky-200 text-sky-600 select-none active:scale-95">
                <IoNewspaperOutline size={25} />
                <p className="mx-1.5">Shipment booked but hasn't been picked up yet</p>
              </div>
            )}
          </div>
          <div className="p-5 w-full h-full flex items-start justify-center gap-x-5">
            <div className="w-[500px]">
              <div className="w-full bg-white rounded-[10px] shadow-[rgba(50,50,105,0.15)_0px_2px_5px_0px,rgba(0,0,0,0.05)_0px_1px_1px_0px] cursor-pointer">
                <div className="p-2 px-5 flex items-center">
                  <p>
                    Shipping account:{' '}
                    <span className="font-semibold text-slate-600 underline">
                      {user?.exporter || shipmentData?.sender}
                    </span>
                  </p>
                </div>
              </div>
              <div>
                <div className="w-full bg-white rounded-[10px] shadow-[rgba(50,50,105,0.15)_0px_2px_5px_0px,rgba(0,0,0,0.05)_0px_1px_1px_0px] mt-3.5">
                  <div className="p-5">
                    <div className="flex items-center">
                      <p className="text-xl">Shipment information</p>
                      {/* <div className="relative ml-auto">
                        <OutsideClickHandler
                          onOutsideClick={() => {
                            setDisplayPrint(false);
                          }}
                        >
                          <div
                            className="p-1.5 bg-white border-solid border-[darkblue] border-[1px] text-[darkblue] rounded-[5px] flex items-center px-2.5 select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300"
                            onClick={() => {
                              handleShowPrint();
                              printSingleBill(shipmentData);
                            }}
                          >
                            <FaPrint size={25} />
                            <p className="mx-1.5">Print bill</p>
                            <FaSortDown size={25} className="mt-[-11px]" />
                          </div>
                          {displayPrint && (
                            <div className="absolute bg-white mt-2 shadow-[rgba(50,50,105,0.15)_0px_2px_5px_0px,rgba(0,0,0,0.05)_0px_1px_1px_0px] p-1 w-[200px] rounded-[5px] right-0 flex flex-col gap-y-1">
                              <div
                                className="p-1 rounded-[5px] hover:bg-slate-200 active:bg-slate-300 cursor-pointer px-2.5"
                                onClick={printBill}
                              >
                                <p>Print bill</p>
                              </div>
                              <div className="p-1 rounded-[5px] hover:bg-slate-200 active:bg-slate-300 cursor-pointer px-2.5">
                                <p>Print invoice</p>
                              </div>
                              <div className="p-1 rounded-[5px] hover:bg-slate-200 active:bg-slate-300 cursor-pointer px-2.5">
                                <p>Print shipping mark</p>
                              </div>
                              <div className="p-1 rounded-[5px] hover:bg-slate-200 active:bg-slate-300 cursor-pointer px-2.5">
                                <p>Print all</p>
                              </div>
                            </div>
                          )}
                        </OutsideClickHandler>
                      </div> */}
                    </div>
                    <div className="mt-3.5">
                      <p className="bg-black text-white px-2.5 rounded-full font-semibold w-fit">
                        {shipmentData?.shipmentType || shipmentData?.type}
                      </p>
                      <div className="flex items-center mt-2">
                        <FiPackage size={30} className="text-[darkblue] mr-2" />
                        <p className="text-2xl font-semibold tracking-wide text-[darkblue]">{shipmentData?.hawb}</p>
                      </div>
                    </div>
                    <div className="mt-3.5">
                      <p className="text-slate-500">Weight (KG)</p>
                      <div className="flex items-center">
                        <p className="text-xl font-semibold tracking-wide text-black">
                          {shipmentData?.weight || shipmentData?.chargeable} KG /{' '}
                          {shipmentData?.totalPackage || shipmentData?.amount} PCS
                        </p>
                      </div>
                    </div>
                    {stat !== 'home' && (
                      <div className="mt-3.5">
                        <p className="text-slate-500">REF</p>
                        <div className="flex items-center">
                          <p className="text-xl font-semibold tracking-wide text-black">{shipmentData?.sin}</p>
                          <p
                            className="text-emerald-600 underline ml-auto hover:opacity-70 active:opacity-50 cursor-pointer"
                            onClick={() => {
                              if (user.employee === 'no') {
                                navigate(`/tracking-result/${shipmentData?.hawb}/${user.uid}`);
                              } else {
                                navigate(`/tracking-result/${shipmentData?.hawb}`);
                              }
                            }}
                          >
                            Track shipment
                          </p>
                        </div>
                      </div>
                    )}
                    {stat !== 'home' && (
                      <div className="flex mt-2.5 gap-x-2">
                        <div className="w-[50%]">
                          <p className="text-[13px] mb-1">Ship from</p>
                          <input
                            type="text"
                            value={shipmentData?.fromCountry || 'Vietnam'}
                            className="bg-white border-slate-300 border-solid border-[1px] outline-none p-1.5 rounded-full mt-1 w-full px-3.5"
                          />
                        </div>
                        <div className="w-[50%]">
                          <p className="text-[13px] mb-1">Ship to</p>
                          <input
                            type="text"
                            value={shipmentData?.toCountry || shipmentData?.country}
                            className="bg-white border-slate-300 border-solid border-[1px] outline-none p-1.5 rounded-full mt-1 w-full px-3.5"
                          />
                        </div>
                      </div>
                    )}
                    {stat !== 'home' && (
                      <div className="flex mt-2.5 gap-x-2">
                        <div className="w-full">
                          <p className="text-[13px] mb-1">Company name</p>
                          <input
                            type="text"
                            value={shipmentData?.importer || shipmentData?.company}
                            className="bg-white border-slate-300 border-solid border-[1px] outline-none p-1.5 rounded-full mt-1 w-full px-3.5"
                          />
                        </div>
                      </div>
                    )}
                    {stat !== 'home' && (
                      <div className="flex mt-2.5 gap-x-2">
                        <div className="w-full">
                          <p className="text-[13px] mb-1">Address</p>
                          <input
                            type="text"
                            value={shipmentData?.toAddress || shipmentData?.address}
                            className="bg-white border-slate-300 border-solid border-[1px] outline-none p-1.5 rounded-full mt-1 w-full px-3.5"
                          />
                        </div>
                      </div>
                    )}
                    {stat !== 'home' && (
                      <div className="flex mt-2.5 gap-x-2">
                        <div className="w-full">
                          <p className="text-[13px] mb-1">Address 2</p>
                          <input
                            type="text"
                            value={shipmentData?.toAddress2 || shipmentData?.address2}
                            className="bg-white border-slate-300 border-solid border-[1px] outline-none p-1.5 rounded-full mt-1 w-full px-3.5"
                          />
                        </div>
                      </div>
                    )}
                    {stat !== 'home' && (
                      <div className="flex mt-2.5 gap-x-2">
                        <div className="w-full">
                          <p className="text-[13px] mb-1">Address 3</p>
                          <input
                            type="text"
                            value={shipmentData?.toAddress3 || shipmentData?.address3}
                            className="bg-white border-slate-300 border-solid border-[1px] outline-none p-1.5 rounded-full mt-1 w-full px-3.5"
                          />
                        </div>
                      </div>
                    )}
                    {stat !== 'home' && (
                      <div className="flex mt-2.5 gap-x-2">
                        <div className="w-full">
                          <p className="text-[13px] mb-1">Town</p>
                          <input
                            type="text"
                            value={shipmentData?.toTown || shipmentData?.town}
                            className="bg-white border-slate-300 border-solid border-[1px] outline-none p-1.5 rounded-full mt-1 w-full px-3.5"
                          />
                        </div>
                      </div>
                    )}
                    {stat !== 'home' && (
                      <div className="flex mt-2.5 gap-x-2">
                        <div className="w-full">
                          <p className="text-[13px] mb-1">City</p>
                          <input
                            type="text"
                            value={shipmentData?.toCity || shipmentData?.city}
                            className="bg-white border-slate-300 border-solid border-[1px] outline-none p-1.5 rounded-full mt-1 w-full px-3.5"
                          />
                        </div>
                      </div>
                    )}
                    {stat !== 'home' && (
                      <div className="flex mt-2.5 gap-x-2">
                        <div className="w-full">
                          <p className="text-[13px] mb-1">State</p>
                          <input
                            type="text"
                            value={shipmentData?.toState || shipmentData?.state}
                            className="bg-white border-slate-300 border-solid border-[1px] outline-none p-1.5 rounded-full mt-1 w-full px-3.5"
                          />
                        </div>
                      </div>
                    )}
                    {stat !== 'home' && (
                      <div className="flex mt-2.5 gap-x-2">
                        <div className="w-full">
                          <p className="text-[13px] mb-1">Postal Code</p>
                          <input
                            type="text"
                            value={shipmentData?.toPostal || shipmentData?.postal}
                            className="bg-white border-slate-300 border-solid border-[1px] outline-none p-1.5 rounded-full mt-1 w-full px-3.5"
                          />
                        </div>
                      </div>
                    )}
                    {stat !== 'home' && (
                      <div className="flex mt-2.5 gap-x-2">
                        <div className="w-full">
                          <p className="text-[13px] mb-1">Recipient name</p>
                          <input
                            type="text"
                            value={shipmentData?.toName || shipmentData?.contact}
                            className="bg-white border-slate-300 border-solid border-[1px] outline-none p-1.5 rounded-full mt-1 w-full px-3.5"
                          />
                        </div>
                      </div>
                    )}
                    {stat !== 'home' && (
                      <div className="flex mt-2.5 gap-x-2">
                        <div className="w-full">
                          <p className="text-[13px] mb-1">Recipient phone number</p>
                          <input
                            type="text"
                            value={shipmentData?.toPhone || shipmentData?.phone}
                            className="bg-white border-slate-300 border-solid border-[1px] outline-none p-1.5 rounded-full mt-1 w-full px-3.5"
                          />
                        </div>
                      </div>
                    )}
                    {shipmentData?.toEmail && stat !== 'home' && (
                      <div className="flex mt-2.5 gap-x-2">
                        <div className="w-full">
                          <p className="text-[13px] mb-1">Email address</p>
                          <input
                            type="text"
                            value={shipmentData?.toEmail || shipmentData?.email}
                            className="bg-white border-slate-300 border-solid border-[1px] outline-none p-1.5 rounded-full mt-1 w-full px-3.5"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <p className="py-5 text-[14px]">Powered by Skydart Express</p>
              </div>
            </div>
            <div className="w-[500px] bg-white rounded-[10px] shadow-[rgba(50,50,105,0.15)_0px_2px_5px_0px,rgba(0,0,0,0.05)_0px_1px_1px_0px]">
              <div className={`${stat === 'home' ? '' : 'p-5'}`}>
                {stat !== 'home' && <p className="text-xl">Shipping method</p>}
                {stat !== 'home' && (
                  <div className="mt-2.5 flex flex-col gap-y-2">
                    <p>Services:</p>
                    <div className="flex items-center rounded-[10px] border-slate-300 border-solid border-[1px] p-2.5">
                      <div className="w-[20px] h-[20px] border-slate-300 flex items-center justify-center border-solid border-[1px] rounded-full bg-white">
                        <div className="w-[13px] h-[13px] rounded-full bg-sky-600"></div>
                      </div>
                      <div className="ml-2">
                        <p className="text-xl">{shipmentData?.service === 'DHL' ? 'SDH' : shipmentData?.service}</p>
                        <p className="leading-3 text-[15px] text-slate-500">
                          (Contact us to learn more about our services)
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {!shipmentData?.labelUrl && (
                <p className="text-rose-600 text-[13px] tracking-wide m-3.5 text-center">
                  *Label hasn't been created yet, please wait for a moment. We will let you know when its ready as soon
                  as possible. We are sorry for the inconvenient.
                </p>
              )}
              <iframe
                title="PDF Viewer"
                width="100%"
                height="530px"
                style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10, marginTop: 7 }}
                src={shipmentData?.labelUrl || 'https://skydart.com.vn'}
              />
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}
