import { CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { FaSortDown } from 'react-icons/fa6';
import { FcOk } from 'react-icons/fc';
import { GoPackage } from 'react-icons/go';
import { IoDocumentOutline } from 'react-icons/io5';
import { MdOutlineClear } from 'react-icons/md';
import OutsideClickHandler from 'react-outside-click-handler';
import { db } from '../../../../server/firebase';
import editShipment from './editShipment.png';
export default function EditScreen({
  fixedCss,
  handleCloseEdit,
  selected,
  exportSelected,
  shipments,
  onUpdateAll,
  loading,
  alertSuccess,
}) {
  const [displayEditShipmentList, setDisplayEditShipmentList] = useState(false);
  const [flightInput, setFlightInput] = useState('');
  const [mawbInput, setMawbInput] = useState('');
  const [agentInput, setAgentInput] = useState('');
  const [sinInput, setSinInput] = useState('');
  const [packingInput, setPackingInput] = useState('');

  const handleShowShipmentList = () => {
    setDisplayEditShipmentList(!displayEditShipmentList);
  };
  const closeShowShipmentList = () => {
    setDisplayEditShipmentList(false);
  };

  return (
    <div className={fixedCss}>
      <OutsideClickHandler
        onOutsideClick={() => {
          handleCloseEdit();
        }}
      >
        <div className="w-[750px] h-[530px] bg-white shadow-[rgba(0,0,0,0.18)_0px_2px_4px] rounded-[10px] overflow-y-auto flex flex-col">
          <div className="p-3 flex items-center justify-between sticky top-0 z-10 bg-white">
            <p className="text-slate-400 text-[15px] font-semibold">Update shipments</p>
            <div
              className="w-[34px] h-[34px] rounded-[10px] bg-slate-100 hover:bg-slate-200 active:bg-slate-300 flex items-center justify-center active:scale-90 cursor-pointer"
              onClick={() => handleCloseEdit()}
            >
              <MdOutlineClear size={30} />
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-[50%]">
              <div className="p-2">
                <p className="text-xl font-semibold text-black tracking-wide p-1">Flight name & Airline</p>
                <input
                  type="text"
                  placeholder="Enter value here"
                  value={flightInput}
                  onChange={(e) => setFlightInput(e.target.value)}
                  className="border-solid border-slate-300 border-[1px] outline-none p-1 px-3 rounded-[10px] bg-slate-200 mx-2 focus:bg-white w-[300px]"
                />
              </div>
              <div className="p-2">
                <p className="text-xl font-semibold text-black tracking-wide p-1">MAWB number</p>
                <input
                  type="text"
                  value={mawbInput}
                  onChange={(e) => setMawbInput(e.target.value)}
                  placeholder="Enter value here"
                  className="border-solid border-slate-300 border-[1px] outline-none p-1 px-3 rounded-[10px] bg-slate-200 mx-2 focus:bg-white w-[300px]"
                />
              </div>
              <div className="p-2">
                <p className="text-xl font-semibold text-black tracking-wide p-1">Agent name</p>
                <input
                  type="text"
                  value={agentInput}
                  onChange={(e) => setAgentInput(e.target.value)}
                  placeholder="Enter value here"
                  className="border-solid border-slate-300 border-[1px] outline-none p-1 px-3 rounded-[10px] bg-slate-200 mx-2 focus:bg-white w-[300px]"
                />
              </div>
              <div className="p-2">
                <p className="text-xl font-semibold text-black tracking-wide p-1">International AWB</p>
                <input
                  type="text"
                  value={sinInput}
                  onChange={(e) => setSinInput(e.target.value)}
                  placeholder="Enter value here"
                  className="border-solid border-slate-300 border-[1px] outline-none p-1 px-3 rounded-[10px] bg-slate-200 mx-2 focus:bg-white w-[300px]"
                />
              </div>
              <div className="p-2">
                <p className="text-xl font-semibold text-black tracking-wide p-1">Packing</p>
                <input
                  type="text"
                  placeholder="Enter value here"
                  value={packingInput}
                  onChange={(e) => setPackingInput(e.target.value)}
                  className="border-solid border-slate-300 border-[1px] outline-none p-1 px-3 rounded-[10px] bg-slate-200 mx-2 focus:bg-white w-[300px]"
                />
              </div>
            </div>
            <div>
              <div className="flex">
                <div className="ml-auto">
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      closeShowShipmentList();
                    }}
                  >
                    <div className="relative ml-auto">
                      <div
                        className="p-1.5 bg-white rounded-[12px] flex items-center hover:bg-slate-100 active:bg-slate-200 select-none cursor-pointer"
                        style={{ border: '1px solid rgb(230,230,230)' }}
                        onClick={handleShowShipmentList}
                      >
                        <p className="font-semibold mx-1.5">Selected {selected?.length} shipments</p>
                        <FaSortDown size={25} className="mt-[-10px] mr-1" />
                      </div>
                      {displayEditShipmentList && (
                        <div className="bg-white absolute rounded-[10px] shadow-[rgba(0,0,0,0.15)_2.4px_2.4px_3.2px] mt-2 w-full z-[9999]">
                          {selected.map((item) => (
                            <div
                              className="m-1 p-1.5 rounded-[10px] hover:bg-slate-100 active:bg-slate-200 cursor-pointer flex items-start"
                              style={{ border: '1px solid lightgray' }}
                            >
                              {item.type === 'WPX' ? (
                                <GoPackage size={20} className="mt-[2px]" />
                              ) : (
                                <IoDocumentOutline size={20} className="mt-[2px]" />
                              )}
                              <div className="mx-1">
                                <p className="text-[14px]">{item.hawb}</p>
                                <p className="text-slate-500 text-[13px]">10.00 kg</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </OutsideClickHandler>
                </div>
              </div>
              <div className="relative flex items-center justify-center">
                <img src={editShipment} alt="" loading="lazy" className="w-[350px] h-[350px] object-cover" />
                {loading && !alertSuccess && (
                  <div className="absolute w-[180px] h-[120px] rounded-[10px] bg-white flex flex-col items-center justify-center shadow-[rgba(0,0,0,0.15)_0px_15px_25px,rgba(0,0,0,0.05)_0px_5px_10px]">
                    <CircularProgress size={28} />
                    <p className="mt-2 tracking-wide text-slate-600">Loading...</p>
                  </div>
                )}
                {alertSuccess && (
                  <div className="absolute w-[180px] h-[120px] rounded-[10px] bg-white flex flex-col items-center justify-center shadow-[rgba(0,0,0,0.15)_0px_15px_25px,rgba(0,0,0,0.05)_0px_5px_10px]">
                    <FcOk size={28} />
                    <p className="mt-2 tracking-wide text-green-600">Update succeed</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="sticky bottom-0 bg-white z-10 mt-auto p-1 flex items-center"
            style={{ borderTop: '1px solid rgb(230,230,230)' }}
          >
            <div
              className="p-2 bg-slate-100 hover:bg-slate-200 rounded-[10px] font-semibold active:bg-slate-300 ml-auto w-[130px] flex items-center justify-center cursor-pointer select-none"
              onClick={() => handleCloseEdit()}
            >
              <p>Cancel</p>
            </div>
            {selected?.length === 0 && (
              <div className="p-2 bg-sky-600 text-white hover:bg-sky-700 rounded-[10px] font-semibold active:bg-sky-800 mx-1.5 w-[180px] flex items-center justify-center cursor-not-allowed select-none opacity-50">
                <p>Update</p>
              </div>
            )}
            {selected?.length !== 0 && (
              <div
                className="p-2 bg-sky-600 text-white hover:bg-sky-700 rounded-[10px] font-semibold active:bg-sky-800 mx-1.5 w-[180px] flex items-center justify-center cursor-pointer select-none"
                onClick={() => {
                  setFlightInput('');
                  setMawbInput('');
                  setAgentInput('');
                  setSinInput('');
                  setPackingInput('');
                  onUpdateAll(mawbInput, sinInput, flightInput, agentInput, packingInput);
                }}
              >
                <p>Update</p>
              </div>
            )}
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}
