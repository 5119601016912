import { CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { FaSortDown } from 'react-icons/fa6';
import { MdOutlineClear } from 'react-icons/md';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { v1 as uuid } from 'uuid';
import { db } from '../../../../server/firebase';

export default function CreateDebit({ user }) {
  const [loading, setLoading] = useState(false);
  const [loadingContent, setLoadingContent] = useState('Loading...');
  const [makh, setMakh] = useState('');
  const [debitNote, setDebitNote] = useState('');
  const [debit, setDebit] = useState([]);
  const [selectedDebit, setSelectedDebit] = useState([]);
  let newDate = new Date();
  const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
  let date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
  const hour1 = (newDate.getHours() < 10 ? '0' : '') + newDate.getHours();
  const min1 = (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes();
  const etdHour = +hour1.substring(0, 2) + 2;
  const finalEtd = `${etdHour}:${min1}`;
  const first = (firstDay.getDate() < 10 ? '0' : '') + firstDay.getDate();
  const month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
  const year = newDate.getFullYear();
  const [startDate, setStartDate] = useState(`${year}-${month}-${first}`);
  const [endDate, setEndDate] = useState(new Date().toISOString().substr(0, 10));
  const [totalWeight, setTotalWeight] = useState('');
  const [totalReweight, setTotalReweight] = useState('');
  const [totalRate, setTotalRate] = useState('');
  const [totalComestic, setTotalComestic] = useState('');
  const [totalAddressCorrection, setTotalAddressCorrection] = useState('');
  const [totalRemote, setTotalRemote] = useState('');
  const [totalSurcharge1, setTotalSurcharge1] = useState('');
  const [totalFscValue, setTotalFscValue] = useState('');
  const [totalSurcharge2, setTotalSurcharge2] = useState('');
  const [totalVatValue, setTotalVatValue] = useState('');
  const [totalSurcharge3, setTotalSurcharge3] = useState('');
  const [totalAmount, setTotalAmount] = useState('');

  const [uiState, setUiState] = useState('');
  function removeAccents(str) {
    var AccentsMap = [
      'aàảãáạăằẳẵắặâầẩẫấậ',
      'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
      'dđ',
      'DĐ',
      'eèẻẽéẹêềểễếệ',
      'EÈẺẼÉẸÊỀỂỄẾỆ',
      'iìỉĩíị',
      'IÌỈĨÍỊ',
      'oòỏõóọôồổỗốộơờởỡớợ',
      'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
      'uùủũúụưừửữứự',
      'UÙỦŨÚỤƯỪỬỮỨỰ',
      'yỳỷỹýỵ',
      'YỲỶỸÝỴ',
    ];
    for (var i = 0; i < AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
      var char = AccentsMap[i][0];
      str = str.replace(re, char);
    }
    return str;
  }
  const handleCreateDebit = (e) => {
    e.preventDefault();
    const newId = uuid();
    setLoading(true);
    const debitData = {
      makh,
      startDate,
      endDate,
      debitNote,
    };
    axios.get(`https://test.skydart.com.vn/api/shipment/${startDate}/${endDate}/${makh}`).then((snapshot) => {
      let data = snapshot.data;
      const newData = data
        .filter((s, i) => {
          return data.map((val) => val.hawb).indexOf(s.hawb) == i;
        })
        .map((s, i) => {
          let fuelValue = ((+s.rate + +s.addressCorrection + +s.remote + +s.surcharge) * +s.fsc) / 100;
          let vatValue =
            ((+s.rate + +s.addressCorrection + +s.remote + +s.surcharge + +fuelValue + +s.surcharge2) * +s.vat) / 100;
          let total =
            +s.rate +
            +s.addressCorrection +
            +s.comestic +
            +s.remote +
            +s.surcharge +
            +fuelValue +
            +s.surcharge2 +
            +vatValue +
            +s.surcharge3;
          let value = parseFloat(s.value).toFixed(2) || '';
          return {
            select: false,
            account: s.account || '',
            currency: s.currency || '',
            service: s.service === 'VDH' ? 'DHL-VN' : s.service,
            date: s.date || '',
            hawb: s.hawb || '',
            mawb: s.mawb || '',
            makh: s.makh || '',
            company: s.company || '',
            contact: s.contact || '',
            type: s.type || '',
            status: s.status || '',
            term: s.term || '',
            time: s.time || '',
            address: s.address || '',
            address2: s.address2 || '',
            address3: s.address3 || '',
            city: s.city || '',
            country: s.country || '',
            phone: s.phone || '',
            postal: s.postal || '',
            state: s.state || '',
            term: s.term,
            town: s.town || '',
            chargeable: s.chargeable || '',
            amount: s.amount || '',
            totalItem: s.totalItem || '',
            transport: s.transport || '',
            description: s.description || '',
            controller: s.controller || '',
            eamFormat: s.eamFormat || '',
            value: s.type === 'DOX' ? '0.00' : value,
            airline: s.airline || '',
            reweight: s.reweight || '',
            agent: s.agent || '',
            etd: s.etd || '',
            subStatus: s.subStatus || '',
            sin: s.sin || '',
            sender: removeAccents(s.sender) || '',
            rate: s.rate || '',
            fsc: s.fsc || '',
            vat: s.vat || '',
            remote: s.remote || '',
            comestic: s.comestic || '',
            addressCorrection: s.addressCorrection || '',
            totalValue: total || '',
            surcharge: s.surcharge || '',
            surcharge2: s.surcharge2 || '',
            surcharge3: s.surcharge3 || '',
            fscValue: fuelValue.toString() || '',
            vatValue: vatValue.toString() || '',
            sellNote: s.sellNote || '',
            accountNote: s.accountNote || '',
            paymentPickup: s.paymentPickup || '',
            paymentAcc: s.paymentAcc || '',
            paymentCompany: s.paymentCompany || '',
            fromEmail: s.fromEmail || '',
            fromCity: s.fromCity || '',
            com: s.com || '',
            rateUpdate: s.rateUpdate || '',
            paymentStatus: s.paymentStatus || '',
            paymentSubStatus: s.paymentSubStatus || '',
            code: s.code || '',
            eamService: s.eamService || '',
            pack: s.pack || '',
            plan: s.plan || 'Pending',
          };
        });
      setDebit(newData);
      setTotalWeight(data?.reduce((a, v) => (a = +a + +v.chargeable), 0));
      const totalReweight = data?.reduce((a, v) => (a = +a + +v.reweight), 0);
      const totalRate = data?.reduce((a, v) => (a = +a + +v.rate), 0);
      const totalComestic = data?.reduce((a, v) => (a = +a + +v.comestic), 0);
      const totalAddressCorrection = data?.reduce((a, v) => (a = +a + +v.addressCorrection), 0);
      const totalRemote = data?.reduce((a, v) => (a = +a + +v.remote), 0);
      const totalSurcharge1 = data?.reduce((a, v) => (a = +a + +v.surcharge), 0);
      const totalFscValue = data?.reduce((a, v) => (a = +a + +v.fscValue), 0);
      const totalSurcharge2 = data?.reduce((a, v) => (a = +a + +v.surcharge2), 0);
      const totalVatValue = data?.reduce((a, v) => (a = +a + +v.vatValue), 0);
      const totalSurcharge3 = data?.reduce((a, v) => (a = +a + +v.surcharge3), 0);
      const totalAmount = data?.reduce((a, v) => (a = +a + +v.totalValue), 0);
      setTotalWeight(totalWeight);
      setTotalReweight(totalReweight);
      setTotalRate(totalRate);
      setTotalComestic(totalComestic);
      setTotalAddressCorrection(totalAddressCorrection);
      setTotalRemote(totalRemote);
      setTotalSurcharge1(totalSurcharge1);
      setTotalFscValue(totalFscValue);
      setTotalSurcharge2(totalSurcharge2);
      setTotalVatValue(totalVatValue);
      setTotalSurcharge3(totalSurcharge3);
      setTotalAmount(totalAmount);
      setUiState('success');
    });
    // db.collection('users')
    //   .doc(user.email)
    //   .collection('debit-note')
    //   .doc(newId)
    //   .set(debitData)
    //   .then(() => {});
  };
  return (
    <div className="fixed bg-[rgba(255,255,255,0.55)] z-[9999] top-0 right-0 bottom-0 left-0 flex items-center justify-center">
      {uiState === '' && (
        <div className="w-[400px] overflow-auto h-[400px] bg-white rounded-[10px] shadow-[rgba(0,0,0,0.15)_0px_4px_12px] relative">
          {loading && (
            <div className="absolute left-0 right-0 top-0 bottom-0 z-[9999] h-[400px] bg-[rgba(255,255,255,0.85)] rounded-[10px] shadow-[rgba(0,0,0,0.15)_0px_4px_12px] flex items-center justify-center flex-col">
              <CircularProgress size={30} />
              <p className="text-xl tracking-wide mt-2">{loadingContent}</p>
            </div>
          )}
          <div className="p-2 flex items-center justify-between border-b-[1px] border-solid border-slate-200 sticky top-0 z-10 bg-white">
            <p className="text-xl font-semibold">Create debit</p>
            <div className="w-[30px] h-[30px] rounded-[10px] bg-slate-100 hover:bg-slate-200 active:bg-slate-300 active:scale-90 cursor-pointer flex items-center justify-center">
              <MdOutlineClear size={25} />
            </div>
          </div>
          <form className="px-2" onSubmit={handleCreateDebit}>
            <p className="text-[13px] mt-2 text-slate-600 ml-1.5 font-semibold">Customers (BY MAKH):</p>
            <div className="skd-user-info-export-name" style={{ margin: 0, paddingTop: 0 }}>
              <input
                type="text"
                required
                placeholder="Enter customer's MAKH here"
                value={makh}
                onChange={(e) => setMakh(e.target.value)}
              />
            </div>
            <p className="text-[13px] my-1 text-slate-600 ml-1.5 font-semibold">Query date:</p>
            <div className="mx-1 flex items-center justify-between">
              <input
                type="date"
                required
                className="search-column-input p-1 rounded-[7px] border-solid border-slate-200 border-[1px] w-full mr-3"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <p>To</p>
              <input
                type="date"
                required
                className="search-column-input p-1 rounded-[7px] border-solid border-slate-200 border-[1px] w-full ml-3"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <p className="text-[13px] mt-1.5 text-slate-600 ml-1.5 font-semibold">Debit note:</p>
            <div className="flex">
              <ReactTextareaAutosize
                placeholder="Enter note for this debit"
                className="m-1 p-1.5 w-full rounded-[10px] bg-white border-solid border-slate-300 border-[1px] outline-none search-column-input resize-none"
                value={debitNote}
                onChange={(e) => setDebitNote(e.target.value)}
              />
            </div>
            <div className=" sticky bottom-0 mt-2 bg-white z-10 border-t-[1px] border-slate-300 border-solid">
              <div className="flex items-center">
                <button
                  type="submit"
                  className="p-1.5 mt-2 px-3 rounded-[10px] m-1 bg-sky-600 text-white select-none font-semibold cursor-pointer hover:bg-sky-700 active:bg-sky-800 w-[120px] text-center ml-auto"
                >
                  <p>Create</p>
                </button>
                <div className="p-1.5 mt-2 px-3 rounded-[10px] m-1 ml-0 bg-slate-200 select-none font-semibold cursor-pointer hover:bg-slate-300 active:bg-slate-400">
                  <p>Cancel</p>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
