import { TextareaAutosize } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FaSortDown } from 'react-icons/fa6';
import OutsideClickHandler from 'react-outside-click-handler';
import { useNavigate, useLocation } from 'react-router-dom';
import skydartLogo from '../../skydartLogo.png';
export default function LoginHeader() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [trackInput, setTrackInput] = useState('');
  const [displayTracking, setDisplayTracking] = useState(false);
  const activeCss =
    'p-1.5 flex items-center border-b-[3px] hover:border-b-[darkblue] cursor-pointer active:opacity-50 border-b-[darkblue] px-3.5 text-black';

  const inActiveCss =
    'p-1.5 flex items-center border-b-[3px] hover:border-b-[darkblue] cursor-pointer active:opacity-50 border-b-transparent px-3.5 text-slate-500';
  return (
    <div className="p-1 bg-white sticky top-0 z-[9999] flex items-center px-4 border-solid border-slate-200 border-b-[1px]">
      <div className="flex items-center">
        <img
          src={skydartLogo}
          alt=""
          loading="lazy"
          className="w-[40px] h-[40px] cursor-pointer object-contain mt-1"
          onClick={() => navigate('/')}
        />
        <div className="ml-1.5">
          <p className="text-xl text-[darkblue] font-semibold tracking-wide">SKYDART EXPRESS</p>
          <p className="leading-3 ml-[1px] text-slate-600 text-[14px]">International Air Express Services</p>
        </div>
      </div>
      <div className="ml-auto flex items-center gap-x-2.5 text-lg tracking-wide font-semibold select-none responsive-hidden">
        <div className={pathname === '/' ? activeCss : inActiveCss} onClick={() => navigate('/')}>
          <p>Home</p>
        </div>
        <OutsideClickHandler
          onOutsideClick={() => {
            setDisplayTracking(false);
          }}
        >
          <div className="relative">
            <div
              className={displayTracking ? activeCss : inActiveCss}
              onClick={() => {
                setDisplayTracking(!displayTracking);
              }}
            >
              <p>Track your shipment</p>
              <FaSortDown size={25} className="mt-[-11px] ml-2" />
            </div>
            {displayTracking && (
              <div className="absolute bg-white my-1.5 w-[270px] shadow-[rgba(0,0,0,0.1)_0px_4px_6px_-1px,rgba(0,0,0,0.06)_0px_2px_4px_-1px] rounded-[10px] p-2 z-[9999]">
                <p className="text-[13px] text-slate-700 tracking-wide leading-4">
                  Enter your shipment list here to start tracking
                </p>
                <TextareaAutosize
                  value={trackInput}
                  onChange={(e) => setTrackInput(e.target.value)}
                  className="text-[15px] resize-none min-h-[20px] leading-5 p-2.5 w-full bg-slate-100 border-solid border-slate-300 border-[1px] outline-none rounded-[10px] mt-1.5 font-normal search-column-input focus:bg-white"
                  placeholder="Enter your tracking nunber here"
                />
                <p className="text-[13px] text-slate-500 tracking-wide leading-4 font-normal">
                  You can track multiple shipments by enter to break the line (Each line will be 1 shipments).
                </p>
                <div className="flex items-center justify-center mt-2">
                  <a
                    href="#"
                    className="m-1 p-1.5 px-3.5 bg-[darkblue] text-white font-semibold rounded-full hover:opacity-70 active:opacity-50"
                  >
                    Track shipments
                  </a>
                </div>
              </div>
            )}
          </div>
        </OutsideClickHandler>
        {/* <div className={pathname === '/docs' ? activeCss : inActiveCss}>
          <p>Docs</p>
        </div>
        <div className={pathname === '/contact' ? activeCss : inActiveCss}>
          <p>Contact Us</p>
        </div> */}
        <div className={pathname === '/login' ? activeCss : inActiveCss} onClick={() => navigate('/login')}>
          <p>Sign In</p>
        </div>
        <div
          className="p-1.5 flex items-center border-b-[3px] hover:bg-slate-200 active:bg-slate-300 cursor-pointer active:opacity-50 border-slate-200 border-solid border-[1px] rounded-[10px] px-3.5 text-[darkblue]"
          onClick={() => navigate('/registration')}
        >
          <p>Create a shipping account</p>
        </div>
      </div>
    </div>
  );
}
