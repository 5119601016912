import React, { useState, useEffect } from 'react';
import './Personal.css';
import data from './data.png';

import { useNavigate } from 'react-router-dom';

import { auth, db } from '../../server/firebase';
import { Button, CircularProgress, IconButton, TextField } from '@mui/material';
import { BiArrowBack } from 'react-icons/bi';
import { IoArrowForward } from 'react-icons/io5';

export default function Personal({ user }) {
  const [loading, setLoading] = useState(false);
  const [displayName, setDisplayName] = useState(false);
  const [displayPhone, setDisplayPhone] = useState(false);
  const [displayCompany, setDisplayCompany] = useState(false);
  const [displayCountry, setDisplayCountry] = useState(false);
  const [displayAddress, setDisplayAddress] = useState(false);
  const [displayState, setDisplayState] = useState(false);
  const [displayTown, setDisplayTown] = useState(false);
  const [displayCity, setDisplayCity] = useState(false);
  const [displayPostal, setDisplayPostal] = useState(false);
  const [displayPassword, setDisplayPassword] = useState(false);
  const [userData, setUserData] = useState([]);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [phone2, setPhone2] = useState('');
  const [company, setCompany] = useState('');
  const [country, setCountry] = useState('');
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [address3, setAddress3] = useState('');
  const [state, setState] = useState('');
  const [town, setTown] = useState('');
  const [city, setCity] = useState('');
  const [postal, setPostal] = useState('');
  const [password, setPassword] = useState('');
  const [newPassWord, setNewPassWord] = useState('');
  const [passConfirm, setPassConfirm] = useState('');
  const strongPassword = 'Your password should have at lease 6 characters*';
  const navigate = useNavigate();

  const renderName = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setDisplayName(true);
    }, 1000);
  };
  const renderPhone = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setDisplayPhone(true);
    }, 1000);
  };
  const renderCompany = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setDisplayCompany(true);
    }, 1000);
  };
  const renderCountry = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setDisplayCountry(true);
    }, 1000);
  };
  const renderAddress = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setDisplayAddress(true);
    }, 1000);
  };
  const renderState = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setDisplayState(true);
    }, 1000);
  };
  const renderTown = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setDisplayTown(true);
    }, 1000);
  };
  const renderCity = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setDisplayCity(true);
    }, 1000);
  };
  const renderPostal = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setDisplayPostal(true);
    }, 1000);
  };
  const closeProfile = () => {
    setLoading(true);
    setDisplayName(false);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  const closePhone = () => {
    setLoading(true);
    setDisplayPhone(false);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  const closeCompany = () => {
    setLoading(true);
    setDisplayCompany(false);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  const closeCountry = () => {
    setLoading(true);
    setDisplayCountry(false);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  const closeAddress = () => {
    setLoading(true);
    setDisplayAddress(false);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  const closeState = () => {
    setLoading(true);
    setDisplayState(false);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  const closeTown = () => {
    setLoading(true);
    setDisplayTown(false);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  const closeCity = () => {
    setLoading(true);
    setDisplayCity(false);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  const closePostal = () => {
    setLoading(true);
    setDisplayPostal(false);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const personalContact = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate('/contact/us'); //
    }, 1200);
  };
  const renderPassword = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setDisplayPassword(true);
    }, 1200);
  };
  const closePassword = () => {
    setLoading(true);
    setDisplayPassword(false);
    setTimeout(() => {
      setLoading(false);
      //
    }, 500);
  };

  const saveName = () => {
    if (name.length < 5) {
      alert('Your name is too short');
    } else if (name.length >= 5) {
      setLoading(true);
      setTimeout(() => {
        setDisplayName(false);
      }, 100);
      setTimeout(() => {
        auth.currentUser
          .updateProfile({
            displayName: name,
          })
          .then(() => {
            db.collection('users').doc(user.uid).update({
              displayName: auth.currentUser.displayName,
            });
          })
          .then(() => {
            setLoading(false);
            alert('Your name has been changed successfully');
            setName('');
          });
      }, 2000);
    }
  };
  const savePassword = () => {
    if (password !== user.password) {
      alert("Your password doesn't match");
    } else if (password === user.password && newPassWord.length >= 6 && passConfirm === newPassWord) {
      setLoading(true);
      setTimeout(() => {
        auth.currentUser
          .updatePassword(newPassWord)
          .then(() => {
            db.collection('users').doc(user.uid).update({
              password: newPassWord,
            });
          })
          .then(() => {
            setLoading(false);
            alert(`Your password has been changed to ${newPassWord}`);
            setPassword('');
            setNewPassWord('');
            setPassConfirm('');
          })
          .catch((err) => {
            alert(err);
          });
      }, 2000);
    } else {
      alert("Your password doesn't match with your old password or your password is too short");
    }
  };
  const savePhone = () => {
    if (phone2 === '') {
      setPhone2(user.phone2);
    }
    if (phone.length >= 10) {
      setLoading(true);
      setTimeout(() => {
        setDisplayPhone(false);
      }, 100);
      setTimeout(() => {
        db.collection('users').doc(auth.currentUser.uid).update({
          phone: phone,
          phone2: phone2,
        });
        setLoading(false);
        setPhone('');
        setPhone2('');
        alert('Your phone number has changed successfully');
      }, 2000);
    } else {
      alert('Your phone number should not be less than 10 digits number');
    }
  };
  const saveCompany = () => {
    if (company !== '') {
      setLoading(true);
      setTimeout(() => {
        setDisplayCompany(false);
      }, 100);
      setTimeout(() => {
        db.collection('users').doc(user.uid).update({
          exporter: company,
        });
        setLoading(false);
        setCompany('');
        setDisplayCompany(false);
        alert('Your company name has changed successfully');
      }, 2000);
    }
  };
  const saveCountry = () => {};
  const saveAddress = () => {};
  const saveState = () => {};
  const saveTown = () => {};
  const saveCity = () => {};
  const savePostal = () => {};

  return (
    <div className="personal">
      {loading && (
        <div className="price__loading">
          <div className="price__loading__container">
            <center>
              <CircularProgress size={45} />
            </center>
          </div>
        </div>
      )}
      {displayName && (
        <div className="personal__profile__container">
          <div className="personal__profile__content">
            <div className="personal__profile__header">
              <IconButton type="button" onClick={closeProfile}>
                <BiArrowBack htmlColor="#0074D9" />
              </IconButton>
              <h3>Full Name</h3>
            </div>
            <div className="personal__profile__body">
              <p>All changes will be display when you start booking a new shipment.</p>
              <TextField
                label="Full Name"
                variant="outlined"
                placeholder={user.displayName}
                type="text"
                fullWidth
                className="profile-txt-field"
              />
            </div>
            <div className="personal__profile__bottom">
              <Button className="personal-cancel-btn" onClick={closeProfile}>
                Cancel
              </Button>
              <Button variant="contained" className="personal-save-btn">
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
      {displayPhone && (
        <div className="personal__profile__container">
          <div className="personal__profile__content">
            <div className="personal__profile__header">
              <IconButton type="button" onClick={closePhone}>
                <BiArrowBack htmlColor="#0074D9" />
              </IconButton>
              <h3>Contact Phone Number</h3>
            </div>
            <div className="personal__profile__body">
              <p>All changes will be display when you start booking a new shipment.</p>
              <TextField
                label="Phone Number"
                variant="outlined"
                placeholder={user.phone}
                type="text"
                fullWidth
                className="profile-txt-field"
              />
            </div>
            <div className="personal__profile__bottom">
              <Button className="personal-cancel-btn" onClick={closePhone}>
                Cancel
              </Button>
              <Button variant="contained" className="personal-save-btn">
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
      {displayCompany && (
        <div className="personal__profile__container">
          <div className="personal__profile__content">
            <div className="personal__profile__header">
              <IconButton type="button" onClick={closeCompany}>
                <BiArrowBack htmlColor="#0074D9" />
              </IconButton>
              <h3>Company Name / Exporter</h3>
            </div>
            <div className="personal__profile__body">
              <p>All changes will be display when you start booking a new shipment.</p>
              <TextField
                label="Company Name"
                variant="outlined"
                placeholder={user.exporter}
                type="text"
                fullWidth
                className="profile-txt-field"
              />
            </div>
            <div className="personal__profile__bottom">
              <Button className="personal-cancel-btn" onClick={closeCompany}>
                Cancel
              </Button>
              <Button variant="contained" className="personal-save-btn">
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
      {displayCountry && (
        <div className="personal__profile__container">
          <div className="personal__profile__content">
            <div className="personal__profile__header">
              <IconButton type="button" onClick={closeCountry}>
                <BiArrowBack htmlColor="#0074D9" />
              </IconButton>
              <h3>Country / Territory</h3>
            </div>
            <div className="personal__profile__body">
              <p>All changes will be display when you start booking a new shipment.</p>
              <TextField
                label="Country"
                variant="outlined"
                placeholder={user.country}
                type="text"
                fullWidth
                className="profile-txt-field"
              />
            </div>
            <div className="personal__profile__bottom">
              <Button className="personal-cancel-btn" onClick={closeCountry}>
                Cancel
              </Button>
              <Button variant="contained" className="personal-save-btn">
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
      {displayAddress && (
        <div className="personal__profile__container">
          <div className="personal__profile__content">
            <div className="personal__profile__header">
              <IconButton type="button" onClick={closeAddress}>
                <BiArrowBack htmlColor="#0074D9" />
              </IconButton>
              <h3>Address</h3>
            </div>
            <div className="personal__profile__body">
              <p>All changes will be display when you start booking a new shipment.</p>
              <TextField
                label="Address"
                variant="outlined"
                placeholder={user.address}
                type="text"
                fullWidth
                className="profile-txt-field"
              />
              <TextField
                label="Address 2"
                variant="outlined"
                placeholder={user.address2}
                type="text"
                fullWidth
                className="profile-txt-field"
              />
              <TextField
                label="Address 3"
                variant="outlined"
                placeholder={user.address3}
                type="text"
                fullWidth
                className="profile-txt-field"
              />
            </div>
            <div className="personal__profile__bottom">
              <Button className="personal-cancel-btn" onClick={closeAddress}>
                Cancel
              </Button>
              <Button variant="contained" className="personal-save-btn">
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
      {displayState && (
        <div className="personal__profile__container">
          <div className="personal__profile__content">
            <div className="personal__profile__header">
              <IconButton type="button" onClick={closeState}>
                <BiArrowBack htmlColor="#0074D9" />
              </IconButton>
              <h3>State</h3>
            </div>
            <div className="personal__profile__body">
              <p>All changes will be display when you start booking a new shipment.</p>
              <TextField
                label="State"
                variant="outlined"
                placeholder={user.state}
                type="text"
                fullWidth
                className="profile-txt-field"
              />
            </div>
            <div className="personal__profile__bottom">
              <Button className="personal-cancel-btn" onClick={closeState}>
                Cancel
              </Button>
              <Button variant="contained" className="personal-save-btn">
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
      {displayTown && (
        <div className="personal__profile__container">
          <div className="personal__profile__content">
            <div className="personal__profile__header">
              <IconButton type="button" onClick={closeTown}>
                <BiArrowBack htmlColor="#0074D9" />
              </IconButton>
              <h3>Town</h3>
            </div>
            <div className="personal__profile__body">
              <p>All changes will be display when you start booking a new shipment.</p>
              <TextField
                label="Town"
                variant="outlined"
                placeholder={user.town}
                type="text"
                fullWidth
                className="profile-txt-field"
              />
            </div>
            <div className="personal__profile__bottom">
              <Button className="personal-cancel-btn" onClick={closeTown}>
                Cancel
              </Button>
              <Button variant="contained" className="personal-save-btn">
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
      {displayCity && (
        <div className="personal__profile__container">
          <div className="personal__profile__content">
            <div className="personal__profile__header">
              <IconButton type="button" onClick={closeCity}>
                <BiArrowBack htmlColor="#0074D9" />
              </IconButton>
              <h3>Town</h3>
            </div>
            <div className="personal__profile__body">
              <p>All changes will be display when you start booking a new shipment.</p>
              <TextField
                label="City"
                variant="outlined"
                placeholder={user.city}
                type="text"
                fullWidth
                className="profile-txt-field"
              />
            </div>
            <div className="personal__profile__bottom">
              <Button className="personal-cancel-btn" onClick={closeCity}>
                Cancel
              </Button>
              <Button variant="contained" className="personal-save-btn">
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
      {displayPostal && (
        <div className="personal__profile__container">
          <div className="personal__profile__content">
            <div className="personal__profile__header">
              <IconButton type="button" onClick={closePostal}>
                <BiArrowBack htmlColor="#0074D9" />
              </IconButton>
              <h3>Postal Code</h3>
            </div>
            <div className="personal__profile__body">
              <p>All changes will be display when you start booking a new shipment.</p>
              <TextField
                label="Postal Code"
                variant="outlined"
                placeholder={user.postal}
                type="text"
                fullWidth
                className="profile-txt-field"
              />
            </div>
            <div className="personal__profile__bottom">
              <Button className="personal-cancel-btn" onClick={closePostal}>
                Cancel
              </Button>
              <Button variant="contained" className="personal-save-btn">
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
      {displayPassword && (
        <div className="personal__profile__container">
          <div className="personal__profile__content">
            <div className="personal__profile__header">
              <IconButton type="button" onClick={closePassword}>
                <BiArrowBack htmlColor="#0074D9" />
              </IconButton>
              <h3>Password And Security</h3>
            </div>
            <div className="personal__profile__body">
              <p>
                You should not share your password with anyone else because it may caught fraud booking and flow up your
                shipping cost.
              </p>
              <TextField
                label="Current Password"
                variant="outlined"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                className="profile-txt-field"
              />
              <TextField
                label="New Password"
                type="password"
                value={newPassWord}
                onChange={(e) => setNewPassWord(e.target.value)}
                variant="outlined"
                fullWidth
                className="profile-txt-field"
              />
              <p style={{ color: 'red', fontSize: '10px' }}>{strongPassword}</p>
              <TextField
                label="Confirm New Password"
                type="password"
                variant="outlined"
                value={passConfirm}
                fullWidth
                onChange={(e) => setPassConfirm(e.target.value)}
                className="profile-txt-field"
              />
              <p>A strong password can help you protect your shipping account against fraud shipment.</p>
            </div>
            <div className="personal__profile__bottom">
              <Button className="personal-cancel-btn" onClick={closePassword}>
                Cancel
              </Button>
              <Button variant="contained" className="personal-save-btn" onClick={savePassword}>
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
      <center style={{ padding: '50px', marginLeft: '-160px' }}>
        <h1>Personal Information And Password</h1>
        <p>Manage your personal information, shipping address detail and security password to protect your account.</p>
      </center>
      <div className="personal__header">
        <div className="personal__header__left">
          <h3>All the information from your profile serving by Skydart services</h3>
          <p>
            Your information that contain in Skydart Express booking service help you ship your package or document
            faster with better quality and all of booked address will be save in your account for future shipping. You
            can also preview your summary account information and shipping address here.{' '}
          </p>
        </div>
        <div className="personal__header__right">
          <img src={data} alt="" />
        </div>
      </div>
      <div className="personal__body">
        <div className="personal__body__top">
          <h3>Contact Information</h3>
          <p>
            These contact info is using for problem solving during the shipping journey or when you have any question
            with Skydart. We will response to you base on your contact information.
          </p>
        </div>
        <div className="personal__body__content">
          <div className="personal__body__option" onClick={renderName}>
            <h3>Full Name</h3>
            <p>{user.displayName}</p>
            <IoArrowForward htmlColor="#0074D9" />
          </div>
          <div className="personal__body__option">
            <h3>Email Address</h3>
            <p>{user.email}</p>
          </div>
          <div className="personal__body__option" onClick={renderPhone}>
            <h3>Phone Number</h3>
            <p>{user.phone}</p>
            <IoArrowForward htmlColor="#0074D9" />
          </div>
        </div>
      </div>
      <div className="personal__body">
        <div className="personal__body__top">
          <h3>Shipping Information</h3>
          <p>
            All of your profile information will be use as shipper detail for online booking, you can change it anytime.
          </p>
        </div>
        <div className="personal__body__content">
          <div className="personal__body__option" onClick={renderCompany}>
            <h3>Company Name</h3>
            <p>{user.exporter}</p>
            <IoArrowForward htmlColor="#0074D9" />
          </div>
          <div className="personal__body__option" onClick={renderCountry}>
            <h3>Country / Territory</h3>
            <div className="personal__country">
              <img src="https://flagcdn.com/w20/vn.png" alt="flag" />
              <p>{user.country}</p>
            </div>
            <IoArrowForward htmlColor="#0074D9" />
          </div>
          <div className="personal__body__option" onClick={renderAddress}>
            <h3>Address</h3>
            <p>{user.address}</p>
            <IoArrowForward htmlColor="#0074D9" />
          </div>
          <div className="personal__body__option">
            <h3>Address 2</h3>
            <p>{user.address2}</p>
          </div>
          <div className="personal__body__option">
            <h3>Address 3</h3>
            <p>{user.address3}n</p>
          </div>
          <div className="personal__body__option" onClick={renderState}>
            <h3>State</h3>
            <p>{user.state}</p>
            <IoArrowForward htmlColor="#0074D9" />
          </div>
          <div className="personal__body__option" onClick={renderTown}>
            <h3>Town</h3>
            <p>{user.town}</p>
            <IoArrowForward htmlColor="#0074D9" />
          </div>
          <div className="personal__body__option" onClick={renderCity}>
            <h3>City</h3>
            <p>{user.city}</p>
            <IoArrowForward htmlColor="#0074D9" />
          </div>
          <div className="personal__body__option" onClick={renderPostal}>
            <h3>Postal Code</h3>
            <p>{user.postal}</p>
            <IoArrowForward htmlColor="#0074D9" />
          </div>
          <Button variant="contained" className="personal-btn" onClick={personalContact}>
            Contact Us
          </Button>
        </div>
      </div>
      <div className="personal__body">
        <div className="personal__body__top" style={{ display: 'flex', alignItems: 'center' }}>
          <div className="personal__body__top__option">
            <h3>Password And Security </h3>
            <p>Password management and some solutions can help you protect your account better during your journey.</p>
          </div>
          {/* <div className="personal__body__top__option">
                        <img src={pass} alt="" />
                    </div> */}
        </div>
        <div className="personal__body__content">
          <div
            className="personal__body__option"
            style={{ borderBottom: 'none', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
            onClick={renderPassword}
          >
            <h3>Your Password</h3>
            <div className="personal__body__Option">
              <input
                type="password"
                value="0909040236"
                style={{ border: 'none', outline: 'none', cursor: 'pointer' }}
                disabled
              />
              <p style={{ fontSize: '8px' }}>Last updated: 7/11/2021</p>
            </div>
            <IoArrowForward htmlColor="#0074D9" />
          </div>
        </div>
      </div>
    </div>
  );
}
