import axios from 'axios';
import React, { useState } from 'react';
import { BsSaveFill } from 'react-icons/bs';
import { FaRegCalendarDays, FaSortDown, FaSortUp } from 'react-icons/fa6';
import { FiPackage } from 'react-icons/fi';
import { MdOutlineClear } from 'react-icons/md';
import OutsideClickHandler from 'react-outside-click-handler';
import { db } from '../../../../server/firebase';
import firebase from 'firebase/compat/app';
import { CircularProgress } from '@mui/material';
import { FcHighPriority, FcOk } from 'react-icons/fc';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
export default function TracingScreen({ fixedCss, selected, handleCloseTracing }) {
  const [selectedShipment, setSelectedShipment] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const steps = [
    {
      label: 'Select campaign settings',
      description: `For each ad campaign that you create, you can control how much
                you're willing to spend on clicks and conversions, which networks
                and geographical locations you want your ads to show on, and more.`,
    },
    {
      label: 'Create an ad group',
      description: 'An ad group contains one or more ads which target a shared set of keywords.',
    },
    {
      label: 'Create an ad',
      description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    },
  ];
  return (
    <div className={fixedCss} style={{ height: '100vh', overflowY: 'auto', alignItems: 'start' }}>
      <OutsideClickHandler
        onOutsideClick={() => {
          handleCloseTracing();
        }}
      >
        <div className="w-[700px] bg-white rounded-[10px] my-10">
          <div className="p-3 flex items-center justify-between">
            <p className="text-black font-semibold text-xl">Track shipments</p>
            <div className="flex items-center">
              <div className="px-3 h-[35px] rounded-[10px] flex items-center justify-center bg-slate-100 hover:bg-slate-200 active:bg-slate-300 cursor-pointer mr-1.5 select-none">
                <BsSaveFill size={23} />
                <p className="ml-1.5">Update tracing</p>
              </div>
              <div
                className="w-[35px] h-[35px] rounded-[10px] flex items-center justify-center bg-slate-100 hover:bg-slate-200 active:bg-slate-300 cursor-pointer active:scale-90"
                onClick={() => handleCloseTracing()}
              >
                <MdOutlineClear size={32} />
              </div>
            </div>
          </div>
          <div className="bg-slate-100 p-1.5" style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
            <p className="p-1.5 px-2">Selecting {selected?.length} shipments</p>
            <div className="m-2">
              {selected?.map((element) => (
                <div className=" my-2.5 bg-white rounded-[10px] shadow-[rgba(17,17,26,0.05)_0px_1px_0px,rgba(17,17,26,0.1)_0px_0px_8px]">
                  <div
                    className="p-2 flex flex-col cursor-pointer hover:bg-slate-100 active:bg-slate-200"
                    onClick={() => {
                      setSelectedShipment(
                        selected?.map((item) => {
                          if (item.hawb === element.hawb) {
                            item.isTracing = !item.isTracing;
                            item.loading = true;
                            if (item.isTracing) {
                              const options = {
                                method: 'GET',
                                url: 'https://api-eu.dhl.com/track/shipments',
                                params: { trackingNumber: element.sin },
                                headers: { 'DHL-API-Key': 'FwexXAXennaJZrqGGTBgP1CwSGjaaxRB' },
                              };
                              axios
                                .request(options)
                                .then((res) => {
                                  item.loading = false;
                                  item.tracingResult = res.data.shipments[0].events;
                                  if (res.data.shipments[0].status.description === 'Delivered') {
                                    db.collection('shipments')
                                      .doc(item.hawb)
                                      .update({
                                        status: 'Delivered',
                                        desc: firebase.firestore.FieldValue.arrayUnion({
                                          time: res.data.shipments[0].events[0].timestamp,
                                          location: res.data.shipments[0].events[0].location.address.addressLocality,
                                          stat: res.data.shipments[0].events[0].description,
                                        }),
                                      });
                                    axios
                                      .put('https://test.skydart.com.vn/api/update/delivered', {
                                        hawb: item.hawb,
                                        status: 'Delivered',
                                      })
                                      .then(() => {
                                        setSelectedShipment(
                                          selected?.map((l) => {
                                            if (l.hawb === item.hawb) {
                                              l.loading = false;
                                            }
                                            return l;
                                          })
                                        );
                                      });
                                  } else {
                                    setSelectedShipment(
                                      selected?.map((l) => {
                                        if (l.hawb === item.hawb) {
                                          l.loading = false;
                                        }
                                        return l;
                                      })
                                    );
                                  }
                                })
                                .catch((err) => {
                                  item.tracingResult = [
                                    {
                                      description: 'Please correct the agent or double check back with your shipment',
                                      location: { address: { addressLocality: 'Can not find' } },
                                      timestamp: new Date(),
                                    },
                                  ];
                                  setSelectedShipment(
                                    selected?.map((l) => {
                                      if (l.hawb === item.hawb) {
                                        l.loading = false;
                                      }
                                      return l;
                                    })
                                  );
                                });
                            }
                          }
                          return item;
                        })
                      );
                    }}
                  >
                    <div className="flex items-center">
                      <FiPackage size={25} className="ml-1" />
                      <div className="flex items-center ml-2">
                        <p>
                          <span className="font-semibold">#{element.hawb}</span> |{' '}
                          <span className="underline">{element.chargeable} KG</span> | {element.country}
                        </p>
                      </div>
                      {!element.isTracing && <FaSortDown size={27} className="mt-[-10px] ml-auto" />}
                      {element.isTracing && <FaSortUp size={27} className="mt-[10px] ml-auto" />}
                    </div>
                  </div>
                  {element.isTracing && (
                    <div className="p-0.5 mt-1">
                      {element.loading && (
                        <div className="p-2 flex flex-col items-center">
                          <CircularProgress size={28} />
                          <p className="text-[14px] mt-1.5">Tracking shipment</p>
                        </div>
                      )}
                      {!element.loading && (
                        <div>
                          <div className="flex items-center p-2 pt-0">
                            <p
                              className={`${
                                element.status === 'Picked Up'
                                  ? 'bg-amber-400 text-[15px] font-semibold p-1 px-2.5 text-white rounded-[10px]'
                                  : 'bg-sky-600 text-[15px] font-semibold p-1 px-2.5 text-white rounded-[10px]'
                              }`}
                            >
                              {element.status}
                            </p>
                            <p className="ml-2 text-slate-600">
                              Shipment created at: <span className="font-semibold text-black">{element.date}</span>
                            </p>
                            <p className="ml-auto mr-1 text-slate-900">{element.contact}</p>
                          </div>
                          <div>
                            {element.tracingResult &&
                              element.tracingResult?.map((s) => {
                                const timestamp = s.timestamp;
                                const date = new Date(timestamp);
                                const options = {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  second: '2-digit',
                                };
                                const formattedDateTime = date.toLocaleString('en-GB', options);
                                return (
                                  <div className="p-2 flex items-center">
                                    <div className="flex flex-col items-center">
                                      {s.description !==
                                        'Please correct the agent or double check back with your shipment' && (
                                        <FcOk size={30} />
                                      )}
                                      {s.description ===
                                        'Please correct the agent or double check back with your shipment' && (
                                        <FcHighPriority size={30} />
                                      )}
                                      <div className="h-[55px] w-[2px] bg-slate-200"></div>
                                    </div>
                                    <div
                                      className="ml-2 p-1.5 w-full px-3.5 rounded-[5px] bg-white"
                                      style={{ border: '1px solid rgb(230,230,230)' }}
                                    >
                                      <div className="flex items-center font-semibold">
                                        <p>{s.location.address?.addressLocality || s.location}</p>
                                        <div className="items-center flex ml-auto">
                                          <FaRegCalendarDays size={20} />

                                          <p className="ml-1.5">{formattedDateTime}</p>
                                        </div>
                                      </div>
                                      <div className="flex items-center mt-1.5">
                                        <p className="text-slate-500 w-full break-words">
                                          {/* {s.description?.replaceAll('DHL', 'SKYDART') || s.stat} */}
                                          {s.description}
                                        </p>
                                        {/* <p>{s.location.address?.addressLocality || s.location}</p> */}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}
