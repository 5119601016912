import React, { useState, useEffect, useRef } from 'react';
import './debt.css';
import { NavLink } from 'react-router-dom';
import { MdArrowDropDown, MdCreditCard, MdCreditCardOff, MdGetApp, MdImportExport } from 'react-icons/md';
import { AiOutlineMinus } from 'react-icons/ai';
import { GoCalendar } from 'react-icons/go';
import axios from 'axios';
import { GrClear, GrDocumentCsv } from 'react-icons/gr';
import { SiMicrosoftexcel } from 'react-icons/si';
import { useDatepicker, START_DATE } from '@datepicker-react/hooks';
import datepickerContext from '../new-booking/components/datepickerContext';
import { CSVLink } from 'react-csv';
import { IoDownloadOutline } from 'react-icons/io5';
import { IoArrowDownCircleOutline, IoArrowUpCircleOutline } from 'react-icons/io5';
import Month1 from '../new-booking/components/Month1';
import { FiRefreshCw } from 'react-icons/fi';
import * as XLSX from 'xlsx';
import { IconButton, Checkbox, CircularProgress } from '@mui/material';
import { RiEdit2Line } from 'react-icons/ri';

import { db } from '../../../server/firebase';
import { FaSortDown } from 'react-icons/fa6';
import { BsArrowLeft, BsArrowRight, BsCheck, BsInfo, BsSearch } from 'react-icons/bs';
import DatepickerContext from '../new-booking/components/datepickerContext';
import WorkSpace from '../../components/WorkSpace';
export default function Debt({ user }) {
  let newDate = new Date();
  let date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
  let firstDayOfCurrentWeek = date - (newDate.getDay() < 10 ? '0' : '');
  let last1 = firstDayOfCurrentWeek + 6;
  let firstday = firstDayOfCurrentWeek - 6;
  let lastDayOfCurrentWeek = last1 < 10 ? `0${last1}` : last1;
  let firstDayOfLastWeek = firstday < 10 ? `0${firstday}` : firstday;
  let yesterday = date - 1;
  let months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  let month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
  let lastMonth = month - 1;
  let year = newDate.getFullYear();
  let lastDay = new Date(year, lastMonth, 0);
  let firstDayOfYear = new Date(year, 0, 1);
  let lastPrevMonthDay = newDate.setMonth(newDate.getMonth(), 0);
  let prevMonth = lastMonth < 10 ? `0${lastMonth}` : lastMonth;
  const monthName = months[newDate.getMonth()];
  const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
  const first = (firstDay.getDate() < 10 ? '0' : '') + firstDay.getDate();
  const hour = (newDate.getHours() < 10 ? '0' : '') + newDate.getHours();
  const min = (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes();
  const [shipments, setShipments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fscInput, setFscInput] = useState('');
  const [startDate, setStartDate] = useState(`${year}-${month}-${first}`);
  const [endDate, setEndDate] = useState(new Date().toISOString().substr(0, 10));
  const [search, setSearch] = useState('');
  const [displayDate, setDisplayDate] = useState(false);
  const [dateStatus, setDateStatus] = useState('Period');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(30);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [maxPageLimit, setMaxPageLimit] = useState(5);
  const [paymentStatus, setPaymentStatus] = useState('Un Paid');
  const [selectedShipment, setSelectedShipment] = useState([]);
  const [displayCustomer, setDisplayCustomer] = useState(false);
  const [sender, setSender] = useState('');
  const [makh, setMakh] = useState('');
  const [balance, setBalance] = useState('');
  const [controller, setController] = useState('');
  const [plan, setPlan] = useState('');
  const [loadCustomer, setLoadCustomer] = useState(false);
  const [title, setTitle] = useState('hawb');
  const [check, setCheck] = useState('is');
  const [input, setInput] = useState('');
  const [displayTitle, setDisplayTitle] = useState(false);
  const [displayIs, setDisplayIs] = useState(false);
  const [displayValue, setDisplayValue] = useState(false);
  const [totalWeight1, setTotalWeight1] = useState(0);
  const [apply, setApply] = useState(false);
  const [displayExportReport, setDisplayExportReport] = useState(false);
  const [displayExportCustomer, setDisplayExportCustomer] = useState(false);
  const [currentShipmentPage, setCurrentShipmentPage] = useState(1);
  const [shipmentPerPage, setShipmentPerPage] = useState(7);
  const [pageShipmentNumberLimit, setPageShipmentNumberLimit] = useState(5);
  const [minPageShipmentLimit, setMinPageShipmentLimit] = useState(0);
  const [maxPageShipmentLimit, setMaxPageShipmentLimit] = useState(5);
  const [sortDate, setSortDate] = useState(false);
  const [sortCountry, setSortCountry] = useState(false);
  const [displayPayment, setDisplayPayment] = useState(false);
  const [payOption, setPayOption] = useState('');
  const [accNote, setAccnote] = useState('');
  const [updateLoading, setUpdateLoading] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  let dateRef = useRef();
  let titleRef = useRef();
  let checkRef = useRef();
  let valueRef = useRef();
  let exportReportRef = useRef();
  let paymentRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!dateRef.current.contains(e.target)) {
        setDisplayDate(false);
      }
      if (!titleRef.current.contains(e.target)) {
        setDisplayTitle(false);
      }
      if (!checkRef.current.contains(e.target)) {
        setDisplayIs(false);
      }
      if (!valueRef.current.contains(e.target)) {
        setDisplayValue(false);
      }
      if (!exportReportRef.current.contains(e.target)) {
        setDisplayExportReport(false);
      }
      if (!paymentRef.current.contains(e.target)) {
        setDisplayPayment(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    focusedInput: START_DATE,
  });
  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    focusedInput: dateRange.focusedInput,
    onDatesChange: handleDateChange,
  });
  function handleDateChange(data) {
    if (!data.focusedInput) {
      setDateRange({ ...data, focusedInput: START_DATE });
    } else {
      setDateRange(data);
    }
  }
  const handleToday = () => {
    setStartDate(`${year}-${month}-${date}`);
    setEndDate(`${year}-${month}-${date}`);
    setDisplayDate(false);
    setDateStatus('Today');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleYesterday = () => {
    if (yesterday === 0) {
      setStartDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
      setEndDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
      setDisplayDate(false);
      setDateStatus('Yesterday');
      setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
    } else {
      setStartDate(`${year}-${month}-${yesterday}`);
      setEndDate(`${year}-${month}-${yesterday}`);
      setDisplayDate(false);
      setDateStatus('Yesterday');
      setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
    }
  };
  const handleThisWeek = () => {
    setStartDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
    setEndDate(`${year}-${month}-${lastDayOfCurrentWeek}`);
    setDisplayDate(false);
    setDateStatus('This week');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleLastWeek = () => {
    setStartDate(`${year}-${month}-${firstDayOfLastWeek}`);
    setEndDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
    setDisplayDate(false);
    setDateStatus('Last week');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };

  const handleThisMonth = () => {
    setStartDate(`${year}-${month}-${first}`);
    setEndDate(new Date(currentYear, new Date().getMonth() + 1).toISOString().split('T')[0]);
    setDisplayDate(false);
    setDateStatus('This month');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleLastMonth = () => {
    setStartDate(`${year}-${prevMonth}-01`);
    setEndDate(`${year}-${prevMonth}-${new Date(lastPrevMonthDay).getDate().toString()}`);
    setDisplayDate(false);
    setDateStatus('Last month');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleThisYear = () => {
    setStartDate(`${year}-01-01`);
    setEndDate(`${year}-12-31`);
    setDateStatus('This year');
    setDisplayDate(false);
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleApplyDate = () => {
    if (!dateRange.endDate) {
      setStartDate(`${selectYear}-${selectMonth}-${selectDate}`);
      setEndDate(`${selectYear}-${selectMonth}-${selectDate}`);
      setDisplayDate(false);
      setDateStatus('Period');
    } else {
      setStartDate(`${selectYear}-${selectMonth}-${selectDate}`);
      setEndDate(`${selectEndYear}-${selectEndMonth}-${selectEndDate}`);
      setDisplayDate(false);
      setDateStatus('Period');
    }
  };
  const handleCancelDate = () => {
    setDisplayDate(false);
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  function getLastDayOfYear(year) {
    return new Date(year, 11, 32);
  }
  const d = new Date(dateRange.startDate);
  const selectMonth = (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1);
  const selectDate = (d.getDate() < 10 ? '0' : '') + d.getDate();
  const selectYear = d.getFullYear();
  const e = new Date(dateRange.endDate);
  const selectEndMonth = (e.getMonth() + 1 < 10 ? '0' : '') + (e.getMonth() + 1);
  const selectEndDate = (e.getDate() < 10 ? '0' : '') + e.getDate();
  const selectEndYear = e.getFullYear();
  const currentYear = new Date().getFullYear();
  const startDay = new Date(startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  const endDay = new Date(endDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  useEffect(() => {
    if (user.role === 'Courier') alert('You dont have permission to access into this feature');
    refresh();
  }, [startDate, endDate]);
  const roleBase = shipments.filter((s) => {
    if (user.role === 'Sales') {
      return s.controller === user.displayName;
    } else if (user.role === 'CEO') {
      return s;
    } else if (user.role === 'Finance') {
      return s;
    } else if (user.role === 'Sales Manager') {
      return s;
    } else if (user.role === 'CFO') {
      return s;
    } else if (user.role === 'Admin') {
      return s;
    } else {
      return null;
    }
  });
  const getShipment = roleBase.filter((s) => {
    return s.paymentStatus === paymentStatus && s.sender.toLowerCase().includes(search.toLowerCase());
  });

  const customer = getShipment.reduce((res, s) => {
    const key = `${s.makh}`;
    res[key] = res[key] || {
      sender: s.sender,
      controller: s.controller,
      plan: s.plan,
      makh: s.makh,
      chargeable: 0,
      reweight: 0,
      rate: 0,
      addressCorrection: 0,
      remote: 0,
      surcharge: 0,
      comestic: 0,
      fscValue: 0,
      fsc: s.fsc,
      vat: s.vat,
      surcharge2: 0,
      vatValue: 0,
      surcharge3: 0,
      totalValue: 0,
      email: s.email,
    };
    res[key].comestic = +res[key].comestic + +s.comestic;
    res[key].chargeable = +res[key].chargeable + +s.chargeable;
    res[key].reweight = +res[key].reweight + +s.reweight;
    res[key].rate = +res[key].rate + +s.rate;
    res[key].addressCorrection = +res[key].addressCorrection + +s.addressCorrection;
    res[key].remote = +res[key].remote + +s.remote;
    res[key].surcharge = +res[key].surcharge + +s.surcharge;
    res[key].surcharge2 = +res[key].surcharge2 + +s.surcharge2;
    res[key].surcharge3 = +res[key].surcharge3 + +s.surcharge3;
    res[key].fscValue = +res[key].fscValue + +s.fscValue;
    res[key].vatValue = +res[key].vatValue + +s.vatValue;
    res[key].totalValue = +res[key].totalValue + +s.totalValue;
    return res;
  }, {});
  const last = Object.values(customer).sort((a, b) => {
    return b.totalValue - a.totalValue;
  });

  const excelColumn = last.map((s) => {
    return {
      Customer: s.sender,
      Controller: s.controller,
      Class: s.plan,
      MAKH: s.makh,
      Weight: s.chargeable,
      Reweight: s.reweight,
      Rate: s.rate,
      AddCorrection: s.addressCorrection,
      Remote: s.remote,
      Surcharge: s.comestic,
      FSC: s.fscValue,
      VAT: s.vatValue,
      Surcharge1: s.surcharge,
      Surcharge2: s.surcharge2,
      Surcharge3: s.surcharge3,
      TOTAL: s.totalValue,
    };
  });
  const headers = [
    { label: 'Customer', key: 'sender' },
    { label: 'Controller', key: 'controller' },
    { label: 'KG', key: 'chargeable' },
    { label: 'RW', key: 'reweight' },
    { label: 'Rate', key: 'rate' },
    { label: 'Address Correct', key: 'addressCorrection' },
    { label: 'RM', key: 'remote' },
    { label: 'Extra 1', key: 'surcharge' },
    { label: 'FSC', key: 'fscValue' },
    { label: 'Extra 2', key: 'surcharge2' },
    { label: 'VAT', key: 'vatValue' },
    { label: 'Extra 3', key: 'surcharge3' },
    { label: 'TOTAL', key: 'totalValue' },
  ];
  const exportReport = {
    data: last,
    headers: headers,
    filename:
      paymentStatus === 'Un Paid'
        ? `Debit report from ${startDay} to ${endDay}`
        : `Payment report from ${startDay} to ${endDay}`,
  };
  const handleExportExcel = () => {
    setDisplayExportReport(false);
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(excelColumn);
    if (paymentStatus === 'Un Paid') {
      XLSX.utils.book_append_sheet(wb, ws, 'Debit');
      XLSX.writeFile(wb, 'Debit.xlsx');
    } else if (paymentStatus === 'Pay') {
      XLSX.utils.book_append_sheet(wb, ws, 'Paid');
      XLSX.writeFile(wb, 'Paid.xlsx');
    }
    // XLSX.utils.book_append_sheet(wb, ws, `Repor`);
  };
  const foundShipment = shipments.filter((s) => {
    return s.makh === makh;
  });
  const selectedCustomer = foundShipment.filter((s) => {
    if (apply) {
      if (title === 'hawb') {
        if (check === 'is') {
          return s.hawb.toLowerCase().includes(input.toLowerCase());
        } else if (check === 'is not') {
          return s.hawb !== input;
        }
      } else if (title === 'country') {
        if (check === 'is') {
          return s.country.toLowerCase().startsWith(input.toLowerCase());
        } else if (check === 'is not') {
          return s.country !== input;
        }
      } else if (title === 'reweight') {
        if (check === 'is') {
          return s.reweight === input;
        } else if (check === 'is not') {
          return s.reweight !== input;
        }
      } else if (title === 'type') {
        if (check === 'is') {
          return s.type === input;
        } else if (check === 'is not') {
          return s.type !== input;
        }
      } else if (title === 'paymentCompany') {
        if (check === 'is') {
          return s.paymentCompany === input;
        } else if (check === 'is not') {
          return s.paymentCompany !== input;
        }
      }
    } else if (!apply) {
      return s;
    }
  });
  const totalWeight = last.reduce((a, v) => (a = +a + +v.chargeable), 0);
  const totalReweight = last.reduce((a, v) => (a = +a + +v.reweight), 0);
  const totalRate = last.reduce((a, v) => (a = +a + +v.rate), 0);
  const totalComestic = last.reduce((a, v) => (a = +a + +v.comestic), 0);
  const totalAddressCorrection = last.reduce((a, v) => (a = +a + +v.addressCorrection), 0);
  const totalRemote = last.reduce((a, v) => (a = +a + +v.remote), 0);
  const totalSurcharge1 = last.reduce((a, v) => (a = +a + +v.surcharge), 0);
  const totalFscValue = last.reduce((a, v) => (a = +a + +v.fscValue), 0);
  const totalSurcharge2 = last.reduce((a, v) => (a = +a + +v.surcharge2), 0);
  const totalVatValue = last.reduce((a, v) => (a = +a + +v.vatValue), 0);
  const totalSurcharge3 = last.reduce((a, v) => (a = +a + +v.surcharge3), 0);
  const totalAmount = last.reduce((a, v) => (a = +a + +v.totalValue), 0);
  const pages = [];
  for (let i = 1; i <= Math.ceil(last.length / itemPerPage); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentShipment = last.slice(indexOfFirstItem, indexOfLastItem);
  const handleChangePage = (e) => {
    setCurrentPage(Number(e.target.id));
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
  };
  const shipmentPages = [];
  for (let i = 1; i <= Math.ceil(selectedCustomer.length / shipmentPerPage); i++) {
    shipmentPages.push(i);
  }
  const indexOfLastShipment = currentShipmentPage * shipmentPerPage;
  const indexOfFirstShipment = indexOfLastShipment - shipmentPerPage;
  const currentCustomer = selectedCustomer.slice(indexOfFirstShipment, indexOfLastShipment);
  const handleChangePage1 = (e) => {
    setCurrentShipmentPage(Number(e.target.id));
  };
  const handleNextPage1 = () => {
    setCurrentShipmentPage(currentShipmentPage + 1);
    if (currentShipmentPage + 1 > maxPageShipmentLimit) {
      setMaxPageShipmentLimit(maxPageShipmentLimit + pageShipmentNumberLimit);
      setMinPageShipmentLimit(minPageShipmentLimit + pageShipmentNumberLimit);
    }
  };
  const handlePrevPage1 = () => {
    setCurrentShipmentPage(currentShipmentPage - 1);
    if ((currentShipmentPage - 1) % pageShipmentNumberLimit == 0) {
      setMaxPageShipmentLimit(maxPageShipmentLimit - pageShipmentNumberLimit);
      setMinPageShipmentLimit(minPageShipmentLimit - pageShipmentNumberLimit);
    }
  };
  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
    }
  };
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageLimit + 1 && number > minPageLimit) {
      return (
        <li
          key={number}
          id={number}
          style={{ borderRadius: 5, border: '1px solid rgb(230,230,230)', borderRadius: 5 }}
          onClick={handleChangePage}
          className={currentPage == number ? 'active-page' : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });
  const renderPageNumbers1 = shipmentPages.map((number) => {
    if (number < maxPageShipmentLimit + 1 && number > minPageShipmentLimit) {
      return (
        <li
          key={number}
          id={number}
          style={{ borderRadius: 5, border: '1px solid rgb(230,230,230)', borderRadius: 5 }}
          onClick={handleChangePage1}
          className={currentShipmentPage == number ? 'active-page' : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  function removeAccents(str) {
    var AccentsMap = [
      'aàảãáạăằẳẵắặâầẩẫấậ',
      'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
      'dđ',
      'DĐ',
      'eèẻẽéẹêềểễếệ',
      'EÈẺẼÉẸÊỀỂỄẾỆ',
      'iìỉĩíị',
      'IÌỈĨÍỊ',
      'oòỏõóọôồổỗốộơờởỡớợ',
      'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
      'uùủũúụưừửữứự',
      'UÙỦŨÚỤƯỪỬỮỨỰ',
      'yỳỷỹýỵ',
      'YỲỶỸÝỴ',
    ];
    for (var i = 0; i < AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
      var char = AccentsMap[i][0];
      str = str.replace(re, char);
    }
    return str;
  }
  const refresh = () => {
    setLoading(true);
    setDisplayCustomer(false);
    axios
      .get(`https://test.skydart.com.vn/api/shipmentttt/${startDate}/${endDate}/**/skd/shipment`)
      .then((snapshot) => {
        let data = snapshot.data;
        setShipments(
          data
            .filter((s, i) => {
              return data.map((val) => val.hawb).indexOf(s.hawb) == i;
            })
            .map((s, i) => {
              let fuelValue = ((+s.rate + +s.addressCorrection + +s.remote + +s.surcharge) * +s.fsc) / 100;
              let vatValue =
                ((+s.rate + +s.addressCorrection + +s.remote + +s.surcharge + +fuelValue + +s.surcharge2) * +s.vat) /
                100;
              let total =
                +s.rate +
                +s.addressCorrection +
                +s.comestic +
                +s.remote +
                +s.surcharge +
                +fuelValue +
                +s.surcharge2 +
                +vatValue +
                +s.surcharge3;
              let value = parseFloat(s.value).toFixed(2) || '';
              return {
                select: false,
                account: s.account || '',
                currency: s.currency || '',
                service: s.service === 'VDH' ? 'DHL-VN' : s.service,
                date: s.date || '',
                hawb: s.hawb || '',
                mawb: s.mawb || '',
                makh: s.makh || '',
                company: s.company || '',
                contact: s.contact || '',
                type: s.type || '',
                status: s.status || '',
                term: s.term || '',
                time: s.time || '',
                address: s.address || '',
                address2: s.address2 || '',
                address3: s.address3 || '',
                city: s.city || '',
                country: s.country || '',
                phone: s.phone || '',
                postal: s.postal || '',
                state: s.state || '',
                term: s.term,
                town: s.town || '',
                chargeable: s.chargeable || '',
                amount: s.amount || '',
                totalItem: s.totalItem || '',
                transport: s.transport || '',
                description: s.description || '',
                controller: s.controller || '',
                eamFormat: s.eamFormat || '',
                value: s.type === 'DOX' ? '0.00' : value,
                airline: s.airline || '',
                reweight: s.reweight || '',
                agent: s.agent || '',
                etd: s.etd || '',
                subStatus: s.subStatus || '',
                sin: s.sin || '',
                sender: removeAccents(s.sender) || '',
                rate: s.rate || '',
                fsc: s.fsc || '',
                vat: s.vat || '',
                remote: s.remote || '',
                comestic: s.comestic || '',
                addressCorrection: s.addressCorrection || '',
                totalValue: total || '',
                surcharge: s.surcharge || '',
                surcharge2: s.surcharge2 || '',
                surcharge3: s.surcharge3 || '',
                fscValue: fuelValue.toString() || '',
                vatValue: vatValue.toString() || '',
                sellNote: s.sellNote || '',
                accountNote: s.accountNote || '',
                paymentPickup: s.paymentPickup || '',
                paymentAcc: s.paymentAcc || '',
                paymentCompany: s.paymentCompany || '',
                fromEmail: s.fromEmail || '',
                fromCity: s.fromCity || '',
                com: s.com || '',
                rateUpdate: s.rateUpdate || '',
                paymentStatus: s.paymentStatus || '',
                paymentSubStatus: s.paymentSubStatus || '',
                code: s.code || '',
                eamService: s.eamService || '',
                pack: s.pack || '',
                plan: s.plan || 'Pending',
              };
            })
        );
        setLoading(false);
      });
  };

  const customerDebt = selectedCustomer.filter((s) => {
    return s.paymentStatus === 'Un Paid';
  });
  const customerPaid = selectedCustomer.filter((s) => {
    return s.paymentStatus === 'Pay';
  });
  const totalBalance = selectedCustomer.reduce((a, v) => (a = +a + +v.totalValue), 0);
  const totalUnpaid = customerDebt.reduce((a, v) => (a = +a + +v.totalValue), 0);
  const totalPaid = customerPaid.reduce((a, v) => (a = +a + +v.totalValue), 0);
  const wpxShipment = selectedCustomer.filter((s) => {
    return s.type === 'WPX';
  });
  const doxShipment = selectedCustomer.filter((s) => {
    return s.type === 'DOX';
  });
  const exportSelected = selectedCustomer.filter((s) => {
    return s.select;
  });
  const csvColumn = exportSelected.map((s) => {
    return {
      date: new Date(s.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }),
      hawb: s.hawb,
      country: s.country,
      weight: s.reweight,
      type: s.type,
      pickup: s.paymentPickup,
      acc: s.paymentAcc,
      company: s.paymentCompany,
      total: s.totalValue,
      note: s.accountNote,
    };
  });
  const csvHeader = [
    { label: 'DATE', key: 'date' },
    { label: 'SKD HAWB', key: 'hawb' },
    { label: 'Country', key: 'country' },
    { label: 'Weight (KG)', key: 'weight' },
    { label: 'Type', key: 'type' },
    { label: 'Payment Pick Up', key: 'pickup' },
    { label: 'Payment Accountant', key: 'acc' },
    { label: 'Payment Company', key: 'company' },
    { label: 'Total Value (USD)', key: 'total' },
    { label: 'Accountant Note', key: 'note' },
  ];
  const exportShipments = {
    data: csvColumn,
    headers: csvHeader,
    filename: `${sender} report from ${startDay} to ${endDay}.csv`,
  };
  const duplicateReweight = [...new Set(selectedCustomer.map((s) => s.reweight))];

  const updateMode = () => {
    setDisplayPayment(false);
    setUpdateLoading(true);
    exportSelected.forEach((s) => {
      db.collection('shipments').doc(s.hawb).update({
        paymentStatus: 'Pay',
        accountant: user.displayName,
      });
      axios.put('https://test.skydart.com.vn/api/accountant/note', { hawb: s.hawb, accountNote: accNote });
      axios.put('https://test.skydart.com.vn/api/update/shipment-paid', { hawb: s.hawb, paymentStatus: 'Pay' });
      axios
        .put('https://test.skydart.com.vn/api/payment-mode', {
          hawb: s.hawb,
          paymentPickup: payOption,
          paymentAcc: payOption,
          collectBy: user.displayName,
          paymentSubStatus: 'Paid To Accountant',
        })
        .then(() => {
          setTimeout(() => {
            setSelectedShipment(
              shipments.map((val) => {
                let paymentPickupValue = payOption;
                let paymentAccountValue = payOption;
                let paymentNote = accNote;
                if (val.hawb === s.hawb) {
                  val.paymentPickup = paymentPickupValue;
                  val.paymentAcc = paymentAccountValue;
                  val.accountNote = paymentNote;
                }
                return val;
              })
            );
            setUpdateLoading(false);
            setAlertSuccess(true);
          }, 2000);
          setTimeout(() => {
            setAlertSuccess(false);
          }, 5000);
        })
        .then(() => {
          setPayOption('');
          setAccnote('');
        });
    });
  };
  const updateAllMode = () => {
    setDisplayPayment(false);
    setUpdateLoading(true);
    exportSelected.forEach((s) => {
      db.collection('shipments').doc(s.hawb).update({
        paymentStatus: 'Pay',
        collect: user.displayName,
      });
      axios.put('https://test.skydart.com.vn/api/accountant/note', { hawb: s.hawb, accountNote: accNote });
      axios.put('https://test.skydart.com.vn/api/update/shipment-paid', { hawb: s.hawb, paymentStatus: 'Pay' });
      axios
        .put('https://test.skydart.com.vn/api/payment/all', {
          hawb: s.hawb,
          paymentPickup: payOption,
          paymentAcc: payOption,
          paymentCompany: payOption,
          collectBy: user.displayName,
          paymentSubStatus: 'Paid To Company',
        })
        .then(() => {
          setTimeout(() => {
            setSelectedShipment(
              shipments.map((val) => {
                let paymentPickupValue = payOption;
                let paymentAccountValue = payOption;
                let paymentCompanyValue = payOption;
                let paymentNote = accNote;
                if (val.hawb === s.hawb) {
                  val.paymentPickup = paymentPickupValue;
                  val.paymentAcc = paymentAccountValue;
                  val.paymentCompany = paymentCompanyValue;
                  val.accountNote = paymentNote;
                }
                return val;
              })
            );
            setUpdateLoading(false);
            setAlertSuccess(true);
          }, 2000);
          setTimeout(() => {
            setAlertSuccess(false);
          }, 5000);
        })
        .then(() => {
          setPayOption('');
          setAccnote('');
        });
    });
  };
  const updatePrepaid = () => {
    setDisplayPayment(false);
    setUpdateLoading(true);
    exportSelected.forEach((s) => {
      db.collection('shipments').doc(s.hawb).update({
        paymentStatus: 'Un Paid',
        collect: user.displayName,
      });
      axios.put('https://test.skydart.com.vn/api/accountant/note', { hawb: s.hawb, accountNote: accNote });
      axios.put('https://test.skydart.com.vn/api/update/shipment-paid', { hawb: s.hawb, paymentStatus: 'Un Paid' });
      axios
        .put('https://test.skydart.com.vn/api/payment/all', {
          hawb: s.hawb,
          paymentPickup: payOption,
          paymentAcc: payOption,
          paymentCompany: payOption,
          collectBy: user.displayName,
          paymentSubStatus: 'Paid To Company',
        })
        .then(() => {
          setTimeout(() => {
            setSelectedShipment(
              shipments.map((val) => {
                let paymentPickupValue = payOption;
                let paymentAccountValue = payOption;
                let paymentCompanyValue = payOption;
                let paymentNote = accNote;
                if (val.hawb === s.hawb) {
                  val.paymentPickup = paymentPickupValue;
                  val.paymentAcc = paymentAccountValue;
                  val.paymentCompany = paymentCompanyValue;
                  val.accountNote = paymentNote;
                }
                return val;
              })
            );
            setUpdateLoading(false);
            setAlertSuccess(true);
          }, 2000);
          setTimeout(() => {
            setAlertSuccess(false);
          }, 5000);
        })
        .then(() => {
          setPayOption('');
          setAccnote('');
        });
    });
  };
  const updateFinancePrepaid = () => {
    setDisplayPayment(false);
    setUpdateLoading(true);
    exportSelected.forEach((s) => {
      db.collection('shipments').doc(s.hawb).update({
        paymentStatus: 'Pay',
        accountant: user.displayName,
      });
      axios.put('https://test.skydart.com.vn/api/accountant/note', { hawb: s.hawb, accountNote: accNote });
      axios.put('https://test.skydart.com.vn/api/update/shipment-paid', { hawb: s.hawb, paymentStatus: 'Un Paid' });
      axios
        .put('https://test.skydart.com.vn/api/payment-mode', {
          hawb: s.hawb,
          paymentPickup: payOption,
          paymentAcc: payOption,
          collectBy: user.displayName,
          paymentSubStatus: 'Paid To Accountant',
        })
        .then(() => {
          setTimeout(() => {
            setSelectedShipment(
              shipments.map((val) => {
                let paymentPickupValue = payOption;
                let paymentAccountValue = payOption;
                let paymentNote = accNote;
                if (val.hawb === s.hawb) {
                  val.paymentPickup = paymentPickupValue;
                  val.paymentAcc = paymentAccountValue;
                  val.accountNote = paymentNote;
                }
                return val;
              })
            );
            setUpdateLoading(false);
            setAlertSuccess(true);
          }, 2000);
          setTimeout(() => {
            setAlertSuccess(false);
          }, 5000);
        })
        .then(() => {
          setPayOption('');
          setAccnote('');
        });
    });
  };

  // const onUpdate = () => {
  //     if (payOption === "CRE") {
  //         if (user.role === "CFO" && user.role === "CEO") {
  //             updatePrepaid();
  //         } else if (user.role === "Finance") {
  //             updateFinancePrepaid();
  //         }
  //     } else {
  //         if (user.role === "CFO" && user.role === "CEO") {
  //             updateAllMode();
  //         } else if (user.role === "Finance") {
  //             updateMode();
  //         }
  //     }

  // }
  const onCFOupdate = () => {
    if (payOption === 'CRE') {
      updatePrepaid();
    } else {
      updateAllMode();
    }
  };
  const onFinanceUpdate = () => {
    if (payOption === 'CRE') {
      updateFinancePrepaid();
    } else {
      updateMode();
    }
  };
  // useEffect(() => {
  //     function handleEscapeKey(event: KeyboardEvent) {
  //         if (event.code === 'Escape') {
  //             setDisplayCustomer(false);
  //             setDisplayPayment(false);
  //         }
  //     }
  //     document.addEventListener('keydown', handleEscapeKey)
  //     return () => document.removeEventListener('keydown', handleEscapeKey)
  // }, [])
  return (
    <div className="skydart-debt w-full">
      <WorkSpace
        // refreshShipment={''}
        // loading={loading}
        // finalShipment={finalShipment}
        // systemStatus={systemStatus}
        currentRoute="Transactions"
      />
      <div className="skydart-debt-content w-full">
        <div className="skydart-debt-body">
          <div className="skydart-debt-search relative">
            <BsSearch style={{ position: 'absolute', color: 'lightgray', marginLeft: 7 }} size={20} />
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search for customers"
            />
          </div>
          {paymentStatus === 'Un Paid' && (
            <p style={{ marginLeft: 10, fontSize: 12 }}>Un paid customers (money and debt that needs to be paid)</p>
          )}
          {paymentStatus === 'Pay' && <p style={{ marginLeft: 10, fontSize: 12 }}>Customers that already paid</p>}
          <div style={{ marginLeft: 'auto', display: 'flex' }}>
            <div
              className="w-[35px] h-[35px] rounded-[10px] bg-slate-100 hover:bg-slate-200 active:bg-slate-300 flex items-center justify-center group mr-1.5"
              onClick={refresh}
            >
              <FiRefreshCw size={23} />
              <p className="skd-user-info-header-right-help group-hover:block">Refresh</p>
            </div>
            <div ref={exportReportRef}>
              <div
                className="w-[35px] h-[35px] rounded-[10px] bg-slate-100 hover:bg-slate-200 active:bg-slate-300 flex items-center justify-center group"
                onClick={() => {
                  displayExportReport ? setDisplayExportReport(false) : setDisplayExportReport(true);
                }}
              >
                <MdGetApp style={{ fontSize: 25 }} />
                <p className="skd-user-info-header-right-help group-hover:block">Export</p>
              </div>
              {displayExportReport && (
                <div className="skd-debt-export-report">
                  <CSVLink
                    {...exportReport}
                    className="skd-debt-export-option"
                    onClick={() => {
                      setDisplayExportReport(false);
                    }}
                  >
                    <GrDocumentCsv />
                    <h5>Export as CSV</h5>
                  </CSVLink>
                  <div className="skd-debt-export-option" onClick={handleExportExcel}>
                    <SiMicrosoftexcel />
                    <h5>Export as Excel</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="skd-user-shipment-header-right" style={{ padding: 7 }} ref={dateRef}>
            <div
              className="skd-user-shipment-header-right-date1"
              onClick={() => {
                displayDate ? setDisplayDate(false) : setDisplayDate(true);
              }}
            >
              <GoCalendar size={20} style={{ marginRight: 10 }} />
              <p style={{ marginRight: 7 }}>{dateStatus}:</p>
              <p style={{ marginRight: 7 }}>{startDay}</p>
              <AiOutlineMinus size={10} style={{ marginRight: 7 }} />
              <p style={{ marginRight: 7 }}>{endDay}</p>
              <FaSortDown style={{ fontSize: 18, marginTop: -10 }} />
            </div>
            {displayDate && (
              <div className="skd-user-date">
                <DatepickerContext.Provider
                  value={{
                    focusedDate,
                    isDateFocused,
                    isDateSelected,
                    isDateHovered,
                    isDateBlocked,
                    isFirstOrLastSelectedDate,
                    onDateSelect,
                    onDateFocus,
                    onDateHover,
                  }}
                >
                  <div className="skd-user-date-content">
                    <div className="skd-user-date-left">
                      <div
                        className={
                          dateStatus === 'Today'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleToday}
                      >
                        {dateStatus === 'Today' && <BsCheck style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>Today</p>
                      </div>
                      <div
                        className={
                          dateStatus === 'Yesterday'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleYesterday}
                      >
                        {dateStatus === 'Yesterday' && <BsCheck style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>Yesterday</p>
                      </div>
                      <div
                        className={
                          dateStatus === 'This week'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleThisWeek}
                      >
                        {dateStatus === 'This week' && <BsCheck style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>This week</p>
                      </div>
                      <div
                        className={
                          dateStatus === 'Last week'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleLastWeek}
                      >
                        {dateStatus === 'Last week' && <BsCheck style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>Last week</p>
                      </div>
                      <div
                        className={
                          dateStatus === 'This month'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleThisMonth}
                      >
                        {dateStatus === 'This month' && <BsCheck style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>This Month</p>
                      </div>
                      <div
                        className={
                          dateStatus === 'Last month'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleLastMonth}
                      >
                        {dateStatus === 'Last month' && <BsCheck style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>Last Month</p>
                      </div>
                      <div
                        className={
                          dateStatus === 'This year'
                            ? 'skydart-date-range-right-option1'
                            : 'skydart-date-range-right-option'
                        }
                        onClick={handleThisYear}
                      >
                        {dateStatus === 'This year' && <BsCheck style={{ fontSize: 15, color: 'black' }} />}
                        <p style={{ color: 'black' }}>This Year</p>
                      </div>
                    </div>
                    <div className="skd-user-date-right">
                      <div
                        style={{
                          display: 'flex',
                          margin: '15px 0 0',
                          gridTemplateColumns: `repeat(${activeMonths.length}, 300px)`,
                          gridGap: '0 24px',
                        }}
                      >
                        <div className="date-month-back" onClick={goToPreviousMonths}>
                          <BsArrowLeft />
                        </div>
                        <div className="date-month-back1" onClick={goToNextMonths}>
                          <BsArrowRight />
                        </div>
                        {activeMonths.map((month) => (
                          <Month1
                            key={`${month.year}-${month.month}`}
                            year={month.year}
                            month={month.month}
                            firstDayOfWeek={firstDayOfWeek}
                          />
                        ))}
                      </div>
                      <div className="skd-user-date-shown">
                        <div className="skd-user-date-shown-range">
                          <h5 style={{ fontWeight: 450 }}>
                            {!dateRange.startDate ? 'Start Date' : dateRange.startDate.toDateString()}
                          </h5>
                          <AiOutlineMinus />
                          <h5 style={{ fontWeight: 450, marginLeft: 7 }}>
                            {!dateRange.endDate ? 'End Date' : dateRange.endDate.toDateString()}
                          </h5>
                          <p style={{ fontSize: 10 }}>Ho Chi Minh City time</p>
                        </div>
                        <div className="skd-user-date-update-button">
                          <div className="skd-user-date-update-cancel" onClick={handleCancelDate}>
                            <h5>Cancel</h5>
                          </div>
                          <div className="skd-user-date-update-succeed" onClick={handleApplyDate}>
                            <h5>Update</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DatepickerContext.Provider>
              </div>
            )}
          </div>
        </div>

        <div className="skydart-debt-fetch">
          <div ref={displayTitle ? null : titleRef}></div>
          <div ref={displayValue ? null : valueRef}></div>
          <div ref={displayIs ? null : checkRef}></div>
          <div ref={displayPayment ? null : paymentRef}></div>
          {displayCustomer && (
            <div className={loadCustomer ? 'skd-debt-customer-shown1' : 'skd-debt-customer-shown'}>
              {!loadCustomer && (
                <div className="skd-debt-customer-shown-container">
                  <div className="skd-debt-customer-shown-header">
                    <div>
                      <h1>{sender}</h1>
                      <h3 style={{ fontSize: 12, fontWeight: 600 }}>
                        from {startDay} to {endDay}
                      </h3>
                      <h3 style={{ fontSize: 12, fontWeight: 600 }}>ID: {makh}</h3>
                    </div>
                    <div
                      className="skd-debt-customer-shown-clear"
                      style={{ marginLeft: 'auto' }}
                      onClick={() => {
                        setDisplayCustomer(false);
                        setInput('');
                      }}
                    >
                      <GrClear />
                    </div>
                  </div>
                  <div className="skd-debt-customer-performance">
                    <h3 style={{ fontSize: 18, fontWeight: 700 }}>Performance</h3>
                    <div className="skd-debt-customer-performance-box">
                      <div className="skd-debt-customer-performance-box-option">
                        <div className="skd-debt-customer-performance-box-header">
                          <div className="skd-debt-customer-perform-box-headerPrimary">
                            <h5 style={{ marginRight: 5, fontSize: 14 }}>Balance</h5>
                            <BsInfo fontSize="small" />
                          </div>
                          <div className="skd-debt-customer-perform-summary">
                            <p>Total amount in (USD)</p>
                            <h5>
                              $
                              {totalBalance.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </h5>
                          </div>
                          <div className="skd-debt-customer-perform-stat">
                            <div className="skd-debt-customer-perform-stat-option">
                              <p>Debt</p>
                              <h5 style={{ color: '#cc3333' }}>
                                $
                                {totalUnpaid.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </h5>
                            </div>
                            <div className="skd-debt-customer-perform-stat-option">
                              <p>Paid</p>
                              <h5>
                                $
                                {totalPaid.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="skd-debt-customer-performance-box-option">
                        <div className="skd-debt-customer-performance-box-header">
                          <div className="skd-debt-customer-perform-box-headerPrimary">
                            <h5 style={{ marginRight: 5, fontSize: 14 }}>Shipments</h5>
                            <BsInfo fontSize="small" />
                          </div>
                          <div className="skd-debt-customer-perform-summary">
                            <p>Total shipments among date range</p>
                            <h5>{selectedCustomer.length}</h5>
                          </div>
                          <div className="skd-debt-customer-perform-stat">
                            <div className="skd-debt-customer-perform-stat-option">
                              <p>WPX</p>
                              <h5>{wpxShipment.length}</h5>
                            </div>
                            <div className="skd-debt-customer-perform-stat-option">
                              <p>DOX</p>
                              <h5>{doxShipment.length}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="skd-debt-customer-performance-box-option">
                        <div className="skd-debt-customer-performance-box-header">
                          <div className="skd-debt-customer-perform-box-headerPrimary">
                            <h5 style={{ marginRight: 5, fontSize: 14 }}>Summaries</h5>
                            <BsInfo fontSize="small" />
                          </div>
                          <div className="skd-debt-customer-perform-summary">
                            <p>Total shipped weight</p>
                            <h5>{(Math.round(totalWeight1 * 100) / 100).toFixed(2)} KG</h5>
                          </div>
                          <div className="skd-debt-customer-perform-stat">
                            <div className="skd-debt-customer-perform-stat-option">
                              <p>Controller</p>
                              <h5>{controller}</h5>
                            </div>
                            <div className="skd-debt-customer-perform-stat-option">
                              <p>Class</p>
                              <h5>{plan}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="skd-debt-customer-shipment-fetch">
                    <div className="skd-debt-customer-shipment-primary">
                      <h3 style={{ fontSize: 18, fontWeight: 700 }}>Review</h3>
                      <div className="skd-debt-customer-shipment-secondary">
                        {exportSelected.length !== 0 && (
                          <div className="selected-length-skd-debt">
                            <p>{exportSelected.length} selected</p>
                          </div>
                        )}
                        {exportSelected.length !== 0 && (
                          <div ref={displayPayment ? paymentRef : null}>
                            {user.role === 'CEO' && (
                              <div
                                className="skd-debt-customer-payment"
                                onClick={() => {
                                  displayPayment ? setDisplayPayment(false) : setDisplayPayment(true);
                                }}
                              >
                                <RiEdit2Line size={18} />
                                <h5>Edit</h5>
                              </div>
                            )}
                            {user.role === 'CFO' && (
                              <div
                                className="skd-debt-customer-payment"
                                onClick={() => {
                                  displayPayment ? setDisplayPayment(false) : setDisplayPayment(true);
                                }}
                              >
                                <RiEdit2Line size={18} />
                                <h5>Edit</h5>
                              </div>
                            )}
                            {user.role === 'Finance' && (
                              <div
                                className="skd-debt-customer-payment"
                                onClick={() => {
                                  displayPayment ? setDisplayPayment(false) : setDisplayPayment(true);
                                }}
                              >
                                <RiEdit2Line size={18} />
                                <h5>Edit</h5>
                              </div>
                            )}
                            {displayPayment && (
                              <div className="skd-debt-customer-payment-container">
                                <div className="skd-debt-arrow-up"></div>
                                <div className="skd-debt-customer-payment-content">
                                  <div className="skd-debt-customer-payment-header">
                                    <h3 style={{ fontSize: 15, marginLeft: 5, color: 'black' }}>Payment method</h3>
                                    <p style={{ fontSize: 11, padding: 5 }}>{sender} payment method</p>
                                    <div className="skd-debt-customer-payment-section">
                                      <div
                                        className="skd-debt-customer-payment-option"
                                        onClick={() => {
                                          setPayOption('CRE');
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          id="cre-method"
                                          name="method-radio"
                                          checked={payOption === 'CRE' ? true : false}
                                        />
                                        <label style={{ fontSize: 13 }} for="cre-method">
                                          CRE
                                        </label>
                                      </div>
                                      <div
                                        className="skd-debt-customer-payment-option"
                                        onClick={() => {
                                          setPayOption('PRE');
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          id="pre-method"
                                          name="method-radio"
                                          checked={payOption === 'PRE' ? true : false}
                                        />
                                        <label style={{ fontSize: 13 }} for="pre-method">
                                          PRE
                                        </label>
                                      </div>
                                      <div
                                        className="skd-debt-customer-payment-option"
                                        onClick={() => {
                                          setPayOption('CK');
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          id="ck-method"
                                          name="method-radio"
                                          checked={payOption === 'CK' ? true : false}
                                        />
                                        <label style={{ fontSize: 13 }} for="ck-method">
                                          CK
                                        </label>
                                      </div>
                                      <div
                                        className="skd-debt-customer-payment-option"
                                        onClick={() => {
                                          setPayOption('CRE-P');
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          id="cre-p-method"
                                          name="method-radio"
                                          checked={payOption === 'CRE-P' ? true : false}
                                        />
                                        <label style={{ fontSize: 13 }} for="cre-p-method">
                                          CRE-P
                                        </label>
                                      </div>
                                      <div
                                        className="skd-debt-customer-payment-option"
                                        onClick={() => {
                                          setPayOption('CK-CN');
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          id="ck-cn-method"
                                          name="method-radio"
                                          checked={payOption === 'CK-CN' ? true : false}
                                        />
                                        <label style={{ fontSize: 13 }} for="ck-cn-method">
                                          CK-CN
                                        </label>
                                      </div>
                                      <div
                                        className="skd-debt-customer-payment-option"
                                        onClick={() => {
                                          setPayOption('CK-CTY');
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          id="ck-cty-method"
                                          name="method-radio"
                                          checked={payOption === 'CK-CTY' ? true : false}
                                        />
                                        <label style={{ fontSize: 13 }} for="ck-cty-method">
                                          CK-CTY
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="skd-debt-customer-payment-header">
                                    <h3 style={{ fontSize: 15, marginLeft: 5, color: 'black' }}>Accountant note</h3>
                                    <div className="skd-debt-customer-payment-text">
                                      <p>Text {accNote.length}/300</p>
                                      <textarea
                                        type="text"
                                        maxLength={300}
                                        value={accNote}
                                        onChange={(e) => setAccnote(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                  <div className="skd-debt-customer-payment-bottom">
                                    <p style={{ marginLeft: 8, fontSize: 12, color: 'gray' }}>Updating on {endDay}</p>
                                    <div className="skd-debt-customer-payment-bottom-right">
                                      <div
                                        className="skd-debt-customer-payment-bottom-cancel"
                                        onClick={() => {
                                          setPayOption('');
                                          setAccnote('');
                                          setDisplayPayment(false);
                                        }}
                                      >
                                        <p>Cancel</p>
                                      </div>
                                      {payOption !== '' && user.role === 'CEO' && (
                                        <div className="skd-debt-customer-payment-bottom-update" onClick={onCFOupdate}>
                                          <p>Update</p>
                                        </div>
                                      )}
                                      {payOption !== '' && user.role === 'CFO' && (
                                        <div className="skd-debt-customer-payment-bottom-update" onClick={onCFOupdate}>
                                          <p>Update</p>
                                        </div>
                                      )}
                                      {payOption !== '' && user.role === 'Finance' && (
                                        <div
                                          className="skd-debt-customer-payment-bottom-update"
                                          onClick={onFinanceUpdate}
                                        >
                                          <p>Update</p>
                                        </div>
                                      )}
                                      {payOption === '' && (
                                        <div className="skd-debt-customer-payment-bottom-update1">
                                          <p>Update</p>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        {exportSelected.length !== 0 && (
                          <CSVLink {...exportShipments} className="skd-debt-customer-export">
                            <IoDownloadOutline size={18} />
                            <h5>Export</h5>
                          </CSVLink>
                        )}
                        {exportSelected.length === 0 && (
                          <div className="skd-debt-customer-export1">
                            <IoDownloadOutline size={18} />
                            <h5>Export</h5>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="skd-debt-customer-shipment-fetch-container">
                      <div className="skd-debt-customer-filtering">
                        {apply && (
                          <div className="skd-debt-customer-filtering-applied">
                            <div
                              className="skd-debt-customer-applied"
                              onClick={() => {
                                setApply(false);
                              }}
                            >
                              {title === 'hawb' && (
                                <h5>
                                  HAWB {check}: {input}
                                </h5>
                              )}
                              {title === 'country' && (
                                <h5>
                                  Country {check}: {input}
                                </h5>
                              )}
                              {title === 'reweight' && (
                                <h5>
                                  Weight {check}: {input}Kg
                                </h5>
                              )}
                              {title === 'type' && (
                                <h5>
                                  Type {check}: {input}
                                </h5>
                              )}
                              {title === 'paymentCompany' && (
                                <h5>
                                  Payment method {check}: {input}
                                </h5>
                              )}
                            </div>
                            <GrClear
                              className="applied-icon"
                              onClick={() => {
                                setTitle('hawb');
                                setCheck('is');
                                setInput('');
                                setApply(false);
                              }}
                            />
                          </div>
                        )}
                        {!apply && (
                          <div className="skd-debt-customer-filtering-searchBox">
                            <BsSearch style={{ marginLeft: 1 }} />
                            <div ref={displayTitle ? titleRef : null}>
                              <div
                                className={
                                  displayTitle
                                    ? 'skd-debt-customer-filtering-title1'
                                    : 'skd-debt-customer-filtering-title'
                                }
                                onClick={() => {
                                  displayTitle ? setDisplayTitle(false) : setDisplayTitle(true);
                                }}
                              >
                                {title === 'hawb' && <h5>HAWB</h5>}
                                {title === 'country' && <h5>Country</h5>}
                                {title === 'reweight' && <h5>Weight (KG)</h5>}
                                {title === 'type' && <h5>Type</h5>}
                                {title === 'paymentCompany' && <h5>Payment method</h5>}
                                <MdArrowDropDown fontSize="small" />
                              </div>
                              {displayTitle && (
                                <div className="skd-debt-customer-filtering-box">
                                  <div
                                    className="skd-debt-customer-filtering-box-option"
                                    onClick={() => {
                                      setTitle('hawb');
                                      setInput('');
                                      setDisplayTitle(false);
                                    }}
                                  >
                                    <input type="radio" id="debt-hawb" name="debt-group" checked={title === 'hawb'} />
                                    <label
                                      style={{ fontSize: 13, display: 'flex', alignItems: 'center' }}
                                      for="debt-hawb"
                                    >
                                      HAWB
                                    </label>
                                  </div>
                                  <div
                                    className="skd-debt-customer-filtering-box-option"
                                    onClick={() => {
                                      setTitle('country');
                                      setInput('');
                                      setDisplayTitle(false);
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      id="debt-country"
                                      name="debt-group"
                                      checked={title === 'country'}
                                    />
                                    <label
                                      style={{ fontSize: 13, display: 'flex', alignItems: 'center' }}
                                      for="debt-country"
                                    >
                                      Country
                                    </label>
                                  </div>
                                  <div
                                    className="skd-debt-customer-filtering-box-option"
                                    onClick={() => {
                                      setTitle('reweight');
                                      setInput('');
                                      setDisplayTitle(false);
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      id="debt-weight"
                                      name="debt-group"
                                      checked={title === 'reweight'}
                                    />
                                    <label
                                      style={{ fontSize: 13, display: 'flex', alignItems: 'center' }}
                                      for="debt-weight"
                                    >
                                      Weight (KG)
                                    </label>
                                  </div>
                                  <div
                                    className="skd-debt-customer-filtering-box-option"
                                    onClick={() => {
                                      setTitle('type');
                                      setInput('');
                                      setDisplayTitle(false);
                                    }}
                                  >
                                    <input type="radio" id="debt-type" name="debt-group" checked={title === 'type'} />
                                    <label
                                      style={{ fontSize: 13, display: 'flex', alignItems: 'center' }}
                                      for="debt-type"
                                    >
                                      Type
                                    </label>
                                  </div>
                                  <div
                                    className="skd-debt-customer-filtering-box-option"
                                    onClick={() => {
                                      setTitle('paymentCompany');
                                      setInput('');
                                      setDisplayTitle(false);
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      id="debt-payment"
                                      name="debt-group"
                                      checked={title === 'paymentCompany'}
                                    />
                                    <label
                                      style={{ fontSize: 13, display: 'flex', alignItems: 'center' }}
                                      for="debt-payment"
                                    >
                                      Payment method
                                    </label>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div ref={displayIs ? checkRef : null}>
                              <div
                                className={
                                  displayIs ? 'skd-debt-customer-filtering-title1' : 'skd-debt-customer-filtering-title'
                                }
                                onClick={() => {
                                  displayIs ? setDisplayIs(false) : setDisplayIs(true);
                                }}
                              >
                                <h5>{check}</h5>
                                <MdArrowDropDown fontSize="small" />
                              </div>
                              {displayIs && (
                                <div className="skd-debt-customer-filtering-box-is">
                                  <div
                                    className="skd-debt-customer-filtering-box-option"
                                    onClick={() => {
                                      setCheck('is');
                                      setDisplayIs(false);
                                    }}
                                  >
                                    <input type="radio" id="debt-is" name="debt-group1" checked={check === 'is'} />
                                    <label
                                      style={{ fontSize: 13, display: 'flex', alignItems: 'center' }}
                                      for="debt-is"
                                    >
                                      is
                                    </label>
                                  </div>
                                  <div
                                    className="skd-debt-customer-filtering-box-option"
                                    onClick={() => {
                                      setCheck('is not');
                                      setDisplayIs(false);
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      id="debt-isNot"
                                      name="debt-group1"
                                      checked={check === 'is not'}
                                    />
                                    <label
                                      style={{ fontSize: 13, display: 'flex', alignItems: 'center' }}
                                      for="debt-isNot"
                                    >
                                      is not
                                    </label>
                                  </div>
                                </div>
                              )}
                            </div>
                            {title === 'hawb' && (
                              <div className="skd-debt-customer-filtering-input">
                                <input
                                  type="text"
                                  placeholder="Enter a hawb"
                                  value={input}
                                  onChange={(e) => setInput(e.target.value)}
                                />
                              </div>
                            )}
                            {title === 'country' && (
                              <div className="skd-debt-customer-filtering-input">
                                <input
                                  type="text"
                                  placeholder="Enter a country"
                                  value={input}
                                  onChange={(e) => setInput(e.target.value)}
                                />
                              </div>
                            )}
                            {title === 'reweight' && (
                              <div ref={displayValue ? valueRef : null}>
                                <div
                                  className={
                                    displayValue
                                      ? 'skd-debt-customer-filtering-title1'
                                      : 'skd-debt-customer-filtering-title'
                                  }
                                  onClick={() => {
                                    displayValue ? setDisplayValue(false) : setDisplayValue(true);
                                  }}
                                >
                                  <h5>{input || 'Select a value'}</h5>
                                  <MdArrowDropDown fontSize="small" />
                                </div>
                                {displayValue && (
                                  <div className="skd-debt-customer-filtering-box3">
                                    {duplicateReweight
                                      .sort((a, b) => {
                                        return a.localeCompare(b);
                                      })
                                      .map((s, index) => (
                                        <div
                                          className="skd-debt-customer-filtering-box-option"
                                          onClick={() => {
                                            setInput(s);
                                          }}
                                        >
                                          <input type="radio" id={index} name="value-group" checked={input === s} />
                                          <label
                                            style={{ fontSize: 13, display: 'flex', alignItems: 'center' }}
                                            for={index}
                                          >
                                            {s} Kg
                                          </label>
                                        </div>
                                      ))}
                                  </div>
                                )}
                              </div>
                            )}
                            {title === 'type' && (
                              <div ref={displayValue ? valueRef : null}>
                                <div
                                  className={
                                    displayValue
                                      ? 'skd-debt-customer-filtering-title1'
                                      : 'skd-debt-customer-filtering-title'
                                  }
                                  onClick={() => {
                                    displayValue ? setDisplayValue(false) : setDisplayValue(true);
                                  }}
                                >
                                  <h5>{input || 'Select a value'}</h5>
                                  <MdArrowDropDown fontSize="small" />
                                </div>
                                {displayValue && (
                                  <div className="skd-debt-customer-filtering-box">
                                    <div
                                      className="skd-debt-customer-filtering-box-option"
                                      onClick={() => {
                                        setInput('WPX');
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        id="wpx-radio"
                                        name="value-group1"
                                        checked={input === 'WPX'}
                                      />
                                      <label
                                        style={{ fontSize: 13, display: 'flex', alignItems: 'center' }}
                                        for="wpx-radio"
                                      >
                                        WPX
                                      </label>
                                    </div>
                                    <div
                                      className="skd-debt-customer-filtering-box-option"
                                      onClick={() => {
                                        setInput('DOX');
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        id="dox-radio"
                                        name="value-group1"
                                        checked={input === 'DOX'}
                                      />
                                      <label
                                        style={{ fontSize: 13, display: 'flex', alignItems: 'center' }}
                                        for="dox-radio"
                                      >
                                        DOX
                                      </label>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                            {title === 'paymentCompany' && (
                              <div ref={displayValue ? valueRef : null}>
                                <div
                                  className={
                                    displayValue
                                      ? 'skd-debt-customer-filtering-title1'
                                      : 'skd-debt-customer-filtering-title'
                                  }
                                  onClick={() => {
                                    displayValue ? setDisplayValue(false) : setDisplayValue(true);
                                  }}
                                >
                                  <h5>{input || 'Select a value'}</h5>
                                  <MdArrowDropDown fontSize="small" />
                                </div>
                                {displayValue && (
                                  <div className="skd-debt-customer-filtering-box">
                                    <div
                                      className="skd-debt-customer-filtering-box-option"
                                      onClick={() => {
                                        setInput('CRE');
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        id="cre-radio"
                                        name="value-group2"
                                        checked={input === 'CRE'}
                                      />
                                      <label
                                        style={{ fontSize: 13, display: 'flex', alignItems: 'center' }}
                                        for="cre-radio"
                                      >
                                        CRE
                                      </label>
                                    </div>
                                    <div
                                      className="skd-debt-customer-filtering-box-option"
                                      onClick={() => {
                                        setInput('PRE');
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        id="pre-radio"
                                        name="value-group2"
                                        checked={input === 'PRE'}
                                      />
                                      <label
                                        style={{ fontSize: 13, display: 'flex', alignItems: 'center' }}
                                        for="pre-radio"
                                      >
                                        PRE
                                      </label>
                                    </div>
                                    <div
                                      className="skd-debt-customer-filtering-box-option"
                                      onClick={() => {
                                        setInput('CK');
                                      }}
                                    >
                                      <input type="radio" id="ck-radio" name="value-group2" checked={input === 'CK'} />
                                      <label
                                        style={{ fontSize: 13, display: 'flex', alignItems: 'center' }}
                                        for="ck-radio"
                                      >
                                        CK
                                      </label>
                                    </div>
                                    <div
                                      className="skd-debt-customer-filtering-box-option"
                                      onClick={() => {
                                        setInput('CRE-P');
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        id="creP-radio"
                                        name="value-group2"
                                        checked={input === 'CRE-P'}
                                      />
                                      <label
                                        style={{ fontSize: 13, display: 'flex', alignItems: 'center' }}
                                        for="creP-radio"
                                      >
                                        CRE-P
                                      </label>
                                    </div>
                                    <div
                                      className="skd-debt-customer-filtering-box-option"
                                      onClick={() => {
                                        setInput('CK-CN');
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        id="ckCn-radio"
                                        name="value-group2"
                                        checked={input === 'CK-CN'}
                                      />
                                      <label
                                        style={{ fontSize: 13, display: 'flex', alignItems: 'center' }}
                                        for="ckCn-radio"
                                      >
                                        CK-CN
                                      </label>
                                    </div>
                                    <div
                                      className="skd-debt-customer-filtering-box-option"
                                      onClick={() => {
                                        setInput('CK-CTY');
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        id="ckCty-radio"
                                        name="value-group2"
                                        checked={input === 'CK-CTY'}
                                      />
                                      <label
                                        style={{ fontSize: 13, display: 'flex', alignItems: 'center' }}
                                        for="ckCty-radio"
                                      >
                                        CK-CTY
                                      </label>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                            <div
                              className={
                                input === ''
                                  ? 'skd-debt-customer-filtering-apply1'
                                  : 'skd-debt-customer-filtering-apply'
                              }
                              onClick={() => {
                                input === '' ? console.log() : setApply(true);
                              }}
                            >
                              <h5>Apply filter</h5>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="skd-debt-customer-shipment-fetch-content">
                        <div className="skd-debt-customer-shipment-fetch-header">
                          <div className="skd-debt-customer-shipment-fetch-fixed">
                            <div className="skd-debt-sticky">
                              <div className="skd-debt-customer-shipment-fetch-checkbox-header">
                                <Checkbox
                                  size="small"
                                  color="primary"
                                  checked={exportSelected.length !== 0}
                                  indeterminate={
                                    exportSelected.length !== 0 && exportSelected.length !== selectedCustomer.length
                                  }
                                  onChange={(e) => {
                                    let value = e.target.checked;
                                    setSelectedShipment(
                                      selectedCustomer.map((d) => {
                                        d.select = value;
                                        return d;
                                      })
                                    );
                                  }}
                                />
                              </div>
                              <div
                                className="skd-debt-customer-shipment-fetch-date-header"
                                onClick={() => {
                                  sortDate ? setSortDate(false) : setSortDate(true);
                                }}
                                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                              >
                                <h5>Date</h5>
                                {!sortDate && <IoArrowDownCircleOutline style={{ marginLeft: 'auto' }} />}
                                {sortDate && <IoArrowUpCircleOutline style={{ marginLeft: 'auto' }} />}
                              </div>
                              <div className="skd-debt-customer-shipment-fetch-hawb-header">
                                <h5>HAWB</h5>
                              </div>
                              <div
                                className="skd-debt-customer-shipment-fetch-destination-header"
                                onClick={() => {
                                  sortCountry ? setSortCountry(false) : setSortCountry(true);
                                }}
                                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                              >
                                <h5>Country</h5>
                                {!sortCountry && <IoArrowDownCircleOutline style={{ marginLeft: 'auto' }} />}
                                {sortCountry && <IoArrowUpCircleOutline style={{ marginLeft: 'auto' }} />}
                              </div>
                            </div>
                            <div className="skd-debt-customer-shipment-fetch-weight-header">
                              <h5>Weight (KG)</h5>
                            </div>
                            <div className="skd-debt-customer-shipment-fetch-type-header">
                              <h5>Type</h5>
                            </div>
                            <div className="skd-debt-customer-shipment-fetch-payment-header">
                              <h5>Acc</h5>
                            </div>
                            <div className="skd-debt-customer-shipment-fetch-payment-header">
                              <h5>Company</h5>
                            </div>
                            <div className="skd-debt-customer-shipment-fetch-value-header">
                              <h5>Total Value</h5>
                            </div>

                            <div className="skd-debt-customer-shipment-fetch-note-header">
                              <h5>Accountant Note</h5>
                            </div>
                          </div>
                        </div>
                        {currentCustomer
                          .sort((a, b) => {
                            if (sortDate) {
                              a = a.date || '';
                              b = b.date;
                              return a.localeCompare(b);
                            }
                          })
                          .sort((a, b) => {
                            if (sortCountry) {
                              a = a.country || '';
                              b = b.country || '';
                              return a.localeCompare(b);
                            }
                          })
                          .map((s) => (
                            <div
                              className={
                                s.select
                                  ? 'skd-debt-customer-shipment-fetch-body1 w-full'
                                  : 'skd-debt-customer-shipment-fetch-body w-full'
                              }
                              onClick={() => {
                                if (s.select) {
                                  setSelectedShipment(
                                    selectedCustomer.map((sd) => {
                                      if (sd.hawb === s.hawb) {
                                        sd.select = false;
                                      }
                                      return sd;
                                    })
                                  );
                                } else if (!s.select) {
                                  setSelectedShipment(
                                    selectedCustomer.map((sd) => {
                                      if (sd.hawb === s.hawb) {
                                        sd.select = true;
                                      }
                                      return sd;
                                    })
                                  );
                                }
                              }}
                            >
                              <div className="skd-debt-customer-shipment-fetch-fixed">
                                <div className={s.select ? 'skd-debt-sticky1' : 'skd-debt-sticky'}>
                                  <div className="skd-debt-customer-shipment-fetch-checkbox-header">
                                    <Checkbox size="small" color="primary" checked={s.select} />
                                  </div>
                                  <div className="skd-debt-customer-shipment-fetch-date-header">
                                    <h5>
                                      {new Date(s.date).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                      })}
                                    </h5>
                                  </div>
                                  <div className="skd-debt-customer-shipment-fetch-hawb-header">
                                    <h5 style={{ fontWeight: 600 }}>{s.hawb}</h5>
                                  </div>
                                  <div className="skd-debt-customer-shipment-fetch-destination-header">
                                    <h5 style={{ fontWeight: 600 }}>{s.country}</h5>
                                  </div>
                                </div>
                                <div className="skd-debt-customer-shipment-fetch-weight-header">
                                  <h5 style={{ fontWeight: 600 }}>
                                    {(Math.round(s.chargeable * 100) / 100).toFixed(2)}
                                  </h5>
                                </div>
                                <div className="skd-debt-customer-shipment-fetch-type-header">
                                  <h5 style={{ fontWeight: 600 }}>{s.type}</h5>
                                </div>
                                <div className="skd-debt-customer-shipment-fetch-payment-header">
                                  <h5>{s.paymentAcc}</h5>
                                </div>
                                <div className="skd-debt-customer-shipment-fetch-payment-header">
                                  <h5>{s.paymentCompany}</h5>
                                </div>
                                <div
                                  className="skd-debt-customer-shipment-fetch-value-header"
                                  style={{ display: 'flex' }}
                                >
                                  <h5 style={{ color: '#0165b8', marginLeft: 'auto' }}>
                                    $
                                    {s.totalValue.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) || '0.00'}
                                  </h5>
                                </div>
                                <div className="skd-debt-customer-shipment-fetch-note-header">
                                  <h5>{s.accountNote}</h5>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="skd-debt-customer-filtering1">
                        <div className="skd-debt-customer-filtering1-left">
                          {!updateLoading && !alertSuccess && (
                            <p>All weight column stats are calculated by the reweight data</p>
                          )}
                          {updateLoading && (
                            <div className="skd-debt-update-loading">
                              <CircularProgress size={20} style={{ color: '#63BE09' }} />
                              <h5 style={{ fontSize: 12, fontWeight: 480, marginLeft: 5 }}>Updating...</h5>
                            </div>
                          )}
                          {!updateLoading && alertSuccess && (
                            <div className="skd-debt-update-loading1">
                              <BsCheck style={{ fontSize: 20 }} />
                              <h5 style={{ fontSize: 12, fontWeight: 480, marginLeft: 5 }}>Update succeed</h5>
                            </div>
                          )}
                        </div>
                        <div className="paginate" style={{ padding: 5 }}>
                          <div className="pageNumbers">
                            <IconButton
                              type="button"
                              disabled={currentShipmentPage == shipmentPages[0] ? true : false}
                              style={{ width: 35, height: 35 }}
                              onClick={handlePrevPage1}
                            >
                              <BsArrowLeft className="prev-btn-page" />
                            </IconButton>
                            {renderPageNumbers1}
                            <IconButton
                              type="button"
                              disabled={currentShipmentPage == shipmentPages[shipmentPages.length - 1] ? true : false}
                              style={{ width: 35, height: 35 }}
                              onClick={handleNextPage1}
                            >
                              <BsArrowRight className="prev-btn-page" />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {loadCustomer && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <CircularProgress style={{ color: '#0068be' }} size={35} />
                </div>
              )}
            </div>
          )}
          <div className="skydart-debt-fetch-body">
            <div className="skydart-debt-fetch-title">
              <div className="skd-user-shipment-last-header">
                <input type="checkbox" id="myIndeterminate" />
              </div>
              <div className="debt-customer">
                <h5>Customer</h5>
                <MdImportExport className="debt-sort-icon" />
              </div>
              <div className="debt-title" style={{ display: 'flex' }}>
                <h5 style={{ marginLeft: 'auto' }}>KG</h5>
                <MdArrowDropDown className="debt-sort-icon" />
              </div>
              <div className="debt-title">
                <h5>RW</h5>
                <MdArrowDropDown className="debt-sort-icon" />
              </div>
              <div className="debt-title">
                <h5>Rate</h5>
                <MdArrowDropDown className="debt-sort-icon" />
              </div>
              <div className="debt-title">
                <h5>Extra</h5>
                <MdArrowDropDown className="debt-sort-icon" />
              </div>
              <div className="debt-title">
                <h5>Add</h5>
                <MdArrowDropDown className="debt-sort-icon" />
              </div>
              <div className="debt-title">
                <h5>RM</h5>
                <MdArrowDropDown className="debt-sort-icon" />
              </div>
              <div className="debt-title">
                <h5>Extra 1</h5>
                <MdArrowDropDown className="debt-sort-icon" />
              </div>
              <div className="debt-title">
                <h5>FSC</h5>
                <MdArrowDropDown className="debt-sort-icon" />
              </div>
              <div className="debt-title">
                <h5>Extra 2 </h5>
                <MdArrowDropDown className="debt-sort-icon" />
              </div>
              <div className="debt-title">
                <h5>VAT </h5>
                <MdArrowDropDown className="debt-sort-icon" />
              </div>
              <div className="debt-title">
                <h5>Extra 3 </h5>
                <MdArrowDropDown className="debt-sort-icon" />
              </div>
              <div className="debt-title">
                <h5>Total</h5>
                <MdArrowDropDown className="debt-sort-icon" />
              </div>
            </div>

            <div className="skd-debt">
              {loading && (
                <p
                  style={{
                    display: 'flex',
                    fontWeight: 600,
                    alignItems: 'center',
                    padding: 100,
                    justifyContent: 'center',
                  }}
                >
                  Loading...
                </p>
              )}
              {!loading &&
                currentShipment.map((s, index) => {
                  let rate = s.rate.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                  let comestic = s.comestic.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                  let addressCorrection = s.addressCorrection.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                  let remote = s.remote.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                  let surcharge = s.surcharge.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                  let fscValue = s.fscValue.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                  let surcharge2 = s.surcharge2.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                  let vatValue = s.vatValue.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                  let surcharge3 = s.surcharge3.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                  let total = s.totalValue.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                  return (
                    <div
                      className={s.sender === sender ? 'skydart-debt-fetch-content1' : 'skydart-debt-fetch-content'}
                      key={index}
                      onClick={() => {
                        setSender(s.sender);
                        setMakh(s.makh);
                        setController(s.controller);
                        setPlan(s.plan);
                        setTotalWeight1(s.reweight);
                        setDisplayCustomer(true);
                        setLoadCustomer(true);
                        setTimeout(() => {
                          setLoadCustomer(false);
                        }, 700);
                      }}
                    >
                      <div className="skd-user-shipment-last-header">
                        <input type="checkbox" checked={s.sender === sender} />
                      </div>
                      <div className="debt-customer" style={{ alignItems: 'center' }}>
                        <h5 style={{ marginTop: 0, color: 'rgb(0, 104, 190)' }}>
                          {s.sender.length > 25 ? s.sender.slice(0, 25) + '...' : s.sender}
                        </h5>
                        <div className="debt-customer-show-onHover">
                          <p>{s.sender}</p>
                          <p>{s.controller}</p>
                          <p>{s.plan}</p>
                        </div>
                      </div>
                      <div className="debt-title" style={{ display: 'flex' }}>
                        <h5 style={{ marginLeft: 'auto', fontWeight: 450 }}>
                          {(Math.round(s.chargeable * 100) / 100).toFixed(2)}
                        </h5>
                      </div>
                      <div className="debt-title">
                        <h5 style={{ fontWeight: 450 }}>{(Math.round(s.reweight * 100) / 100).toFixed(2)}</h5>
                      </div>
                      <div className="debt-title">
                        <h5 style={{ fontWeight: 450 }}>{rate}</h5>
                      </div>
                      <div className="debt-title">
                        <h5 style={{ fontWeight: 450 }}>{comestic}</h5>
                      </div>
                      <div className="debt-title">
                        <h5 style={{ fontWeight: 450 }}>{addressCorrection}</h5>
                      </div>
                      <div className="debt-title">
                        <h5 style={{ fontWeight: 450 }}>{remote}</h5>
                      </div>
                      <div className="debt-title">
                        <h5 style={{ fontWeight: 450 }}>{surcharge}</h5>
                      </div>
                      <div className="debt-title">
                        <h5 style={{ fontWeight: 450 }}>{fscValue}</h5>
                      </div>
                      <div className="debt-title">
                        <h5 style={{ fontWeight: 450 }}>{surcharge2}</h5>
                      </div>
                      <div className="debt-title">
                        <h5 style={{ fontWeight: 450 }}>{vatValue}</h5>
                      </div>
                      <div className="debt-title">
                        <h5 style={{ fontWeight: 450 }}>{surcharge3}</h5>
                      </div>
                      <div className="debt-title">
                        <h5 style={{ color: '#0028be', fontWeight: 450 }}>{total}</h5>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {!loading && (
          <div className="skd-debt-paginate">
            <div className="skydart-debt-fetch-title1">
              <div className="debt-customer">
                <div className="paginate">
                  <div className="pageNumbers">
                    <IconButton
                      type="button"
                      disabled={currentPage == pages[0] ? true : false}
                      style={{ width: 35, height: 35 }}
                      onClick={handlePrevPage}
                    >
                      <BsArrowLeft className="prev-btn-page" />
                    </IconButton>
                    {renderPageNumbers}
                    <IconButton
                      type="button"
                      disabled={currentPage == pages[pages.length - 1] ? true : false}
                      style={{ width: 35, height: 35 }}
                      onClick={handleNextPage}
                    >
                      <BsArrowRight className="prev-btn-page" />
                    </IconButton>
                  </div>
                </div>
              </div>
              <div className="debt-title" style={{ display: 'flex', marginLeft: 7 }}>
                <h5>{(Math.round(totalWeight * 100) / 100).toFixed(2)}</h5>
              </div>
              <div className="debt-title">
                <h5>{(Math.round(totalReweight * 100) / 100).toFixed(2)}</h5>
              </div>
              <div className="debt-title">
                <h5>${totalRate.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h5>
              </div>
              <div className="debt-title">
                <h5>
                  ${totalComestic.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </h5>
              </div>
              <div className="debt-title">
                <h5>
                  $
                  {totalAddressCorrection.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h5>
              </div>
              <div className="debt-title">
                <h5>
                  ${totalRemote.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </h5>
              </div>
              <div className="debt-title">
                <h5>
                  ${totalSurcharge1.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </h5>
              </div>
              <div className="debt-title">
                <h5>
                  ${totalFscValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </h5>
              </div>
              <div className="debt-title">
                <h5>
                  ${totalSurcharge2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </h5>
              </div>
              <div className="debt-title">
                <h5>
                  ${totalVatValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </h5>
              </div>
              <div className="debt-title">
                <h5>
                  ${totalSurcharge3.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </h5>
              </div>
              <div className="debt-title">
                <h5 style={{ color: '#0028be' }}>
                  ${totalAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </h5>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
