import './App.css';
import { React, useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SkydartHeader from './staff/components/SkydartHeader';
import NewBooking from './staff/pages/new-booking/NewBooking';
import { auth, db } from './server/firebase';
import Login from './auth/Login';
import Shipments from './staff/pages/shipments/Shipments';
import Packing from './staff/pages/packing/Packing';
import Debt from './staff/pages/finance/Debt';
import Customer from './staff/pages/customers/Customer';
import MapComponent from './staff/pages/study/MapTest';
import MarketBooking from './staff/pages/market/MarketBooking';
import Landing from './staff/pages/main/Landing';
import Loading from './Loading';
import TrackingResult from './staff/pages/tracking/TrackingResult';
import LoginScreen from './auth/LoginScreen';
import Registration from './auth/Registration';
import LoginHeader from './auth/components/LoginHeader';
import Welcome from './Welcome';
function App() {
  // Variable declared
  const [loading, setLoading] = useState(true);
  const [theme, setTheme] = useState('auto');
  const [user, setUser] = useState([]);
  // On select theme
  const handleSelectTheme = (value) => {
    setTheme(value);
  };
  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        db.collection('users')
          .doc(authUser.email)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setUser(doc.data());
              setLoading(false);
            }
          });
      } else {
        setUser(false);
        setLoading(false);
      }
    });
  }, []);

  if (loading) return <Loading />;
  if (!user) {
    return (
      <Router>
        <div className="app">
          <LoginHeader />
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/login" element={<LoginScreen />} />
            <Route exact path="/registration" element={<Registration />} />
            <Route exact path="/tracking-result/:hawb" element={<TrackingResult />} />
          </Routes>
        </div>
      </Router>
    );
  }

  return (
    <div>
      <Welcome user={user} />
    </div>
  );
}

export default App;
