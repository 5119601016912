import { TextareaAutosize } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FaDownload } from 'react-icons/fa6';
import { RiPhoneFindFill } from 'react-icons/ri';
import { TbBrandBooking } from 'react-icons/tb';
import { LuPackage } from 'react-icons/lu';
import { PiAirplaneInFlightLight } from 'react-icons/pi';
import { FaUserCog } from 'react-icons/fa';
import { MdOutlinePayment } from 'react-icons/md';
import { SiGoogledocs } from 'react-icons/si';
import skydartMan from './skydartMan.jpg';
import { MdOutlineNearbyError } from 'react-icons/md';
import { FcShop } from 'react-icons/fc';
import { v1 as uuid } from 'uuid';
import landingArrow from './landingArrow.png';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
export default function Landing({ user }) {
  const navigate = useNavigate();
  const [displayHelp, setDisplayHelp] = useState(false);
  const [trackInput, setTrackInput] = useState('');
  const [trackResult, setTrackResult] = useState([]);
  const [hawbList, setHawbList] = useState([]);
  const [hawbListUrl, setHawbListUrl] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [body, setBody] = useState([]);
  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    readExcel(file);
    e.target.value = null;
    return;
  };
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((item) => {
      // console.log(item);
      setHawbListUrl(item);
    });
  };

  const trackingArray = trackInput.split(/\r?\n/);
  useEffect(() => {
    let arr = [];
    trackingArray.map((s) => {
      arr.push(s);
    });
    const filterArr = arr.filter((s) => {
      return s !== '';
    });
    setTrackResult(filterArr.toString());
  }, [trackingArray]);

  const trackByFile = hawbListUrl.map((item) => {
    return item.HAWB;
  });
  // const filterTrackResult = trackResult?.filter((s) => {
  //   return s !== '';
  // });
  const workspaceArray = [
    {
      label: 'New Booking',
      description: 'Place where all new shipments come from FWD at first place',
      icon: <TbBrandBooking size={35} />,
      path: '/new-booking',
    },
    {
      label: 'Shipments',
      description: 'Managing, editing and review all shipments that has been confirmed',
      icon: <LuPackage size={35} />,
      path: '/shipments',
    },
    {
      label: 'Packing',
      description: 'Getting all shipment detail by each pieces that appear per shipments and MAWB',
      icon: <PiAirplaneInFlightLight size={35} />,
      path: '/packing',
    },
    {
      label: 'Customers',
      description: 'Manage customer class and controller',
      icon: <FaUserCog size={35} />,
      path: '/customers',
    },
    {
      label: 'Transactions',
      description: 'Manage transaction, debit and customer status',
      icon: <MdOutlinePayment size={35} />,
      path: '/transaction',
    },
    // {
    //   label: 'Docs',
    //   description: 'Learn more about Skydart software, having best experience and work with higher performance',
    //   icon: <SiGoogledocs size={35} />,
    //   path: '/docs',
    // },
    // {
    //   label: 'Report Error',
    //   description: 'Let the IT team know the problem you are facing and solve it, report error during work',
    //   icon: <MdOutlineNearbyError size={35} />,
    //   path: '/report',
    // },
    // {
    //   label: 'Market',
    //   description: 'Communicate with customers, announce new surcharge and any unexpected about flights, shipments',
    //   icon: <FcShop size={35} />,
    //   path: '/market',
    // },
  ];

  const getLastWord = () => {
    const wordsArray = user?.displayName.split(' ');
    const lastWord = user?.displayName === 'Vu Nguyen' ? wordsArray[0] : wordsArray[wordsArray.length - 1];

    return lastWord;
  };

  const getGreeting = () => {
    const currentTime = new Date().getHours();

    if (currentTime >= 0 && currentTime < 12) {
      return (
        <div>
          <p>
            Good morning <span className="text-6xl">{getLastWord()}</span>, how are you doing today ?
          </p>
        </div>
      );
    } else if (currentTime >= 12 && currentTime < 18) {
      return (
        <div>
          <p>
            Good afternoon <span className="text-6xl">{getLastWord()}</span>
            {currentTime >= 12 && currentTime <= 14 ? ', let have some break!' : ', have a nice working day!'}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <p>
            Good evening <span className="text-6xl">{getLastWord()}</span>, let's finish up our work for today
          </p>
        </div>
      );
    }
  };
  return (
    <div className="w-full">
      <div className="main-1 h-[350px] w-full items-center justify-center flex flex-col relative">
        <p className="text-white text-4xl font-bold w-[430px] break-words text-center responsive-hidden">
          {getGreeting()}
        </p>
        <img
          src={skydartMan}
          alt=""
          loading="lazy"
          className="absolute w-[470px] top-[-35px] h-[470px] object-cover right-0"
        />
      </div>
      <div className="flex p-3 items-center justify-center  flex-col responsive-hidden">
        <p className="my-2.5 text-2xl font-semibold">Looking for shipments ? Track now</p>
        <TextareaAutosize
          type="text"
          value={trackInput}
          onChange={(e) => setTrackInput(e.target.value)}
          placeholder="Enter a list of shipments that needed to track here, seperate by breaking the line"
          className=" border-slate-300 border-solid border-[1px] p-2.5 text-[18px] w-[700px] rounded-[10px] outline-none newBooking-search bg-white mb-2 shadow-[rgba(0,0,0,0.15)_0px_3px_8px] resize-none px-5"
        />
        <p className="my-1.5 text-slate-500">
          Note: you can always track up for how many shipments you want so just enter the list or
        </p>
        <input type="file" style={{ display: 'none' }} id="landing-upload-shipment" onChange={handleChangeFile} />
        <label
          htmlFor={trackInput ? '' : 'landing-upload-shipment'}
          className={`p-1 bg-emerald-600 font-semibold px-2.5 my-1 rounded-[10px] text-white hover:bg-emerald-700 active:bg-emerald-800 select-none flex items-center ${
            trackInput !== '' ? 'opacity-40 cursor-not-allowed' : 'cursor-pointer'
          }`}
        >
          <FaDownload size={20} />
          <p className="mx-1.5">Upload shipments list file</p>
        </label>
        {hawbListUrl?.length !== 0 && <div className="">{hawbListUrl?.length} rows found</div>}
        <p className="my-1 hover:underline text-[17px] text-[purple] active:opacity-50 cursor-pointer select-none">
          How to upload the file in a right way ?
        </p>
        <div className="relative">
          <img
            src={landingArrow}
            alt=""
            loading="lazy"
            className="absolute w-[70px] h-[70px] object-cover right-[-80px] top-[-23px]"
          />
          <div className="absolute w-[200px] rounded-[7px] shadow-[rgba(0,0,0,0.12)_0px_1px_3px,rgba(0,0,0,0.24)_0px_1px_2px] bg-white z-10 right-[-280px] top-[-35px] p-1">
            <p className="text-[13px] text-slate-600 tracking-wide p-1">
              Ở trong sheet Excel, anh chị hãy tìm cột nào chứa dãy số vận đơn. Sau đó, anh chị hãy sửa cột đó thành giá
              trị <span className="font-bold underline tracking-wide text-black">"HAWB"</span>. Lưu file và tiến hành
              tải file{' '}
              <label
                className="text-[darkblue] font-semibold hover:underline cursor-pointer active:opacity-50 "
                htmlFor="landing-upload-shipment"
              >
                tại đây.
              </label>
            </p>
          </div>
          <div className="bg-white rounded-[10px] shadow-[rgba(0,0,0,0.12)_0px_1px_3px,rgba(0,0,0,0.24)_0px_1px_2px] p-2 max-h-[300px] overflow-auto">
            <div className="flex items-center">
              <div className="p-1 w-[100px] border-slate-300 border-solid border-[1px] text-center">
                <p className="ml-1">No</p>
              </div>
              <div className="p-1 w-[300px] border-slate-300 border-solid border-[1px]">
                <p className="ml-1 underline">HAWB</p>
              </div>
            </div>
            {hawbListUrl?.map((item, index) => (
              <div className="flex items-center">
                <div className="p-1 w-[100px] border-slate-300 border-solid border-[1px] text-center">
                  <p>{index + 1}</p>
                </div>
                <div className="p-1 w-[300px] border-slate-300 border-solid border-[1px] font-semibold">
                  <p>{item.HAWB}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {hawbListUrl.length === 0 && (
          <a
            href={`/tracking-result/${trackResult}`}
            className="p-2.5 bg-[darkblue] text-white rounded-full hover:opacity-70 active:opacity-50 font-semibold px-3.5 select-none cursor-pointer text-[20px] mt-3.5 flex items-center w-[350px] justify-center"
          >
            <RiPhoneFindFill size={27} />
            <p className="ml-1">Track shipments</p>
          </a>
        )}
        {hawbListUrl.length !== 0 && (
          <a
            href={`/tracking-result/${trackByFile.toString()}`}
            className="p-2.5 bg-[darkblue] text-white rounded-full hover:opacity-70 active:opacity-50 font-semibold px-3.5 select-none cursor-pointer text-[20px] mt-3.5 flex items-center w-[350px] justify-center"
          >
            <RiPhoneFindFill size={27} />
            <p className="ml-1">Track shipments</p>
          </a>
        )}
      </div>
      <div className="p-3">
        <p className="my-2.5 text-2xl font-semibold px-2.5">Workspaces</p>
        <div className="flex flex-wrap justify-center gap-x-3 gap-y-3 my-5 responsive-hidden">
          {workspaceArray.map((item) => (
            <div
              className="w-[300px] h-[180px] rounded-[10px] bg-white shadow-[rgba(0,0,0,0.12)_0px_1px_3px,rgba(0,0,0,0.24)_0px_1px_2px] flex flex-col items-center justify-center hover:bg-slate-100 active:bg-slate-200 cursor-pointer"
              onClick={() => {
                navigate(item.path);
              }}
            >
              {item.icon}
              <p className="font-semibold mt-1 text-xl">{item.label}</p>
              <p className="text-slate-500 text-center m-1">{item.description}</p>
            </div>
          ))}
        </div>
        <div
          className="w-[300px] h-[180px] rounded-[10px] bg-white shadow-[rgba(0,0,0,0.12)_0px_1px_3px,rgba(0,0,0,0.24)_0px_1px_2px] flex-col items-center justify-center hover:bg-slate-100 active:bg-slate-200 cursor-pointer responsive-show"
          onClick={() => {
            navigate('/packing');
          }}
        >
          <p className="font-semibold mt-1 text-xl">Packing</p>
          <p className="text-slate-500 text-center m-1">Getting all shipment detail from MAWB</p>
        </div>
      </div>
    </div>
  );
}
