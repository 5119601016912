import { CircularProgress, TextareaAutosize } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FaSortDown } from 'react-icons/fa6';
import { FcCheckmark } from 'react-icons/fc';
import { MdOutlineClear } from 'react-icons/md';
import OutsideClickHandler from 'react-outside-click-handler';

export default function PaymentScreen({
  fixedCss,
  selected,
  user,
  handleClosePayment,
  loading,
  updateMode,
  updateAllMode,
  updatePrepaid,
  alertSuccess,
}) {
  const [showSelected, setShowSelected] = useState(false);
  const [showPaymentType, setShowPaymentType] = useState(false);
  const [mode, setMode] = useState('');
  const [note, setNote] = useState('');

  const handleShowSelected = () => {
    setShowSelected(!showSelected);
  };

  const handleSubmitPayment = () => {
    if (user.role === 'Finance') {
      updateMode(mode, note);
    } else if (user.role === 'CFO') {
      updateAllMode(mode, note);
    } else if (user.role === 'CEO') {
      updateAllMode(mode, note);
    }
    // updateMode(mode, note);
  };

  const paymentMode = ['CRE', 'CK', 'CRE-P', 'CK-CN', 'CK-CTY'];
  return (
    <div className={fixedCss}>
      <OutsideClickHandler
        onOutsideClick={() => {
          handleClosePayment();
        }}
      >
        <div className="w-[550px]  h-[450px] rounded-[10px] bg-white overflow-y-auto flex flex-col relative">
          {loading && (
            <div className="absolute right-0 left-0 top-0 bottom-0 bg-[rgba(255,255,255,0.55)] flex items-center justify-center z-[9999]">
              <div className="w-[300px] rounded-[10px] bg-white flex items-center justify-center flex-col shadow-[rgba(0,0,0,0.1)_0px_4px_6px_-1px,rgba(0,0,0,0.06)_0px_2px_4px_-1px] p-2">
                <CircularProgress size={30} />
                <p className="mt-1 text-[17px]">Loading</p>
              </div>
            </div>
          )}
          {alertSuccess && !loading && (
            <div className="absolute right-0 left-0 top-0 bottom-0 bg-[rgba(255,255,255,0.55)] flex items-center justify-center z-[9999]">
              <div className="w-[300px] rounded-[10px] bg-white flex items-center justify-center flex-col shadow-[rgba(0,0,0,0.1)_0px_4px_6px_-1px,rgba(0,0,0,0.06)_0px_2px_4px_-1px] p-2">
                <FcCheckmark size={30} className="bg-emerald-50 p-1 rounded-full" />
                <p className="mt-1 text-[17px] text-emerald-600">Update succeed</p>
              </div>
            </div>
          )}
          <div className="p-2.5 pb-0 flex items-start justify-between">
            <div>
              <p className="text-2xl">Payment method</p>
              <p className="font-semibold text-slate-500 text-[14px] leading-5">Manage customer payment option</p>
            </div>
            <div
              className="w-[35px] h-[35px] rounded-[10px] bg-slate-100 flex items-center justify-center cursor-pointer hover:bg-slate-200 active:bg-slate-300 active:scale-90"
              onClick={() => handleClosePayment()}
            >
              <MdOutlineClear size={30} />
            </div>
          </div>
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowSelected(false);
            }}
          >
            <div className="relative w-fit">
              <div
                className="p-1.5  px-3 flex items-center rounded-[10px] bg-slate-100 m-1 w-fit mt-3 mx-2.5 hover:bg-slate-200 active:bg-slate-300 select-none cursor-pointer"
                onClick={handleShowSelected}
              >
                <p className="ml-1.5">{selected?.length} shipments selected</p>
                <FaSortDown size={25} className="mt-[-10px] ml-1.5" />
              </div>
              {showSelected && (
                <div className="absolute bg-white shadow-[rgba(0,0,0,0.1)_0px_4px_6px_-1px,rgba(0,0,0,0.06)_0px_2px_4px_-1px] ml-3 mt-1 w-full rounded-[10px] z-10">
                  {selected?.map((item) => (
                    <div className="p-1.5 bg-slate-200 rounded-[10px] m-1.5 select-none cursor-pointer" key={item.hawb}>
                      <p>{item.hawb}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </OutsideClickHandler>
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowPaymentType(false);
            }}
          >
            <div className="mt-2 mx-3 relative">
              <div
                className="flex items-center p-2 rounded-[10px] hover:bg-slate-200 active:bg-slate-300 select-none cursor-pointer search-column-input1 bg-white"
                onClick={() => {
                  setShowPaymentType(!showPaymentType);
                }}
              >
                <p className="pl-1.5">{mode || 'Select a payment mode for selected shipment'}</p>
                <FaSortDown size={25} className="mt-[-10px] ml-auto mr-1" />
              </div>
              {showPaymentType && (
                <div className="absolute bg-white mt-2 rounded-[10px] shadow-[rgba(0,0,0,0.1)_0px_10px_15px_-3px,rgba(0,0,0,0.05)_0px_4px_6px_-2px] w-full">
                  {paymentMode.map((item) => (
                    <div
                      className="p-2 hover:bg-slate-100 active:bg-slate-200 m-1 rounded-[7px] cursor-pointer"
                      onClick={() => {
                        setShowPaymentType(false);
                        setMode(item);
                      }}
                    >
                      <p>{item}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </OutsideClickHandler>
          <textarea
            placeholder="Enter note for selected shipments"
            className="m-2 p-2 bg-slate-100 rounded-[7px] mx-3 w-[95%] outline-none resize-none h-[220px]"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <div
            className="sticky bottom-0 bg-white z-10 p-1.5 mt-auto flex items-center"
            style={{ borderTop: '1px solid rgb(230,230,230)' }}
          >
            <p className="ml-1.5">Accountant: {user.displayName}</p>
            {mode !== '' && (
              <div
                className="p-2 rounded-[10px] cursor-pointer hover:bg-sky-700 active:bg-sky-800 px-3.5 bg-sky-600 text-white font-semibold select-none ml-auto mr-1.5"
                onClick={handleSubmitPayment}
              >
                <p>Update payment</p>
              </div>
            )}
            {mode === '' && (
              <div className="p-2 rounded-[10px] cursor-not-allowed opacity-50 hover:bg-sky-700 active:bg-sky-800 px-3.5 bg-sky-600 text-white font-semibold select-none ml-auto mr-1.5">
                <p>Update payment</p>
              </div>
            )}
            <div
              className="p-2 rounded-[10px] cursor-pointer hover:bg-slate-200 active:bg-slate-300 px-3.5 bg-slate-100 font-semibold select-none mr-1.5"
              onClick={() => handleClosePayment()}
            >
              <p>Cancel</p>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}
