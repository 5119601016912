import { Checkbox, LinearProgress, TextareaAutosize, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { db } from '../../../server/firebase';
import firebase from 'firebase/compat/app';
import { MdOutlineClear } from 'react-icons/md';
import { v1 as uuid } from 'uuid';
import OutsideClickHandler from 'react-outside-click-handler';
export default function InvoicesGrid({ invoiceValue, shipmentType, hawb, user, lastInvoiceUpdate, updateInvoiceBy }) {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayMergeShipment, setDisplayMergeShipment] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(invoiceValue || []);
  const [description, setDescription] = useState('');
  const [hsCode, setHsCode] = useState('');
  const [unitString, setUnitString] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [selected, setSelected] = useState([]);
  const getAlphabetLabel = (invoiceIndex) => {
    const letters = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ];
    let label = '';
    let remainder = invoiceIndex;
    let quotient = 0;

    while (remainder >= 0) {
      quotient = Math.floor(remainder / 26);
      label = letters[remainder % 26] + label;
      remainder = quotient - 1;
    }

    return label;
  };

  useEffect(() => {
    if (selectedInvoice === invoiceValue) {
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  }, [selectedInvoice]);
  const mergedData =
    shipmentType === 'WPX'
      ? selectedInvoice?.reduce((acc, curr) => {
          const existingItem = acc.find((item) => item.itemVietnamCommonity === curr.itemVietnamCommonity);
          if (existingItem) {
            existingItem.itemUnitPrice = +existingItem.itemUnitPrice + +curr.itemUnitPrice;
            existingItem.itemQuantity = +existingItem.itemQuantity + +curr.itemQuantity;
            existingItem.itemValue = +existingItem.itemValue + +curr.itemValue;
          } else {
            acc.push({ ...curr });
          }
          return acc;
        }, [])
      : Array(1)
          .fill(null)
          .map((item) => ({
            itemVietnamCommonity: 'Chứng từ',
            itemUnitPrice: 0,
            itemQuantity: 1,
            itemValue: 0,
          })) || [];
  const finalInvoice = mergedData?.map((s) => {
    return {
      ...s,
      itemVietnamCommonity: s.itemVietnamCommonity || s.itemCommonity,
      id: uuid(),
      select: s.select || false,
    };
  });
  const selectingInvoice = finalInvoice?.filter((s) => {
    return s.select;
  });
  return (
    <div className="w-full">
      {displayMergeShipment && (
        <form
          className="fixed top-0 right-0 bottom-0 left-0 bg-[rgba(0,0,0,0.55)] flex items-center justify-center z-[9999]"
          onSubmit={(e) => {
            e.preventDefault();
            const newInvoice = finalInvoice.map((item) => {
              const matchingItem = selectingInvoice.find((sd) => sd.id === item.id);
              if (matchingItem) {
                item.itemVietnamCommonity = description;
                item.itemHsCode = hsCode;
                item.itemUnit = unitString;
                return item;
              } else {
                return item;
              }
            });

            setLoading(true);
            db.collection('shipments')
              .doc(hawb)
              .update({
                invoiceValue: newInvoice,
                lastInvoiceUpdate: firebase.firestore.FieldValue.serverTimestamp(),
                updateInvoiceBy: user.displayName,
              })
              .then(() => {
                setLoading(false);
                setDescription('');
                setUnitString('');
                setHsCode('');
                setDisplayMergeShipment(false);
                setSelectedInvoice(newInvoice);
                alert('Cập nhật thành công, hãy làm mới để nhận được phiên bản mới nhất');
              });
          }}
        >
          <OutsideClickHandler
            onOutsideClick={() => {
              setDisplayMergeShipment(false);
            }}
          >
            <div className="w-[700px] h-[500px] bg-white rounded-[10px] overflow-auto">
              <div className="p-3 flex items-center border-b-[1px] border-slate-200 border-solid border-t-0 border-r-0 border-l-0 sticky top-0 bg-white z-1 shadow-[rgba(0,0,0,0.16)_0px_1px_4px]">
                <p className="text-xl font-semibold text-[darkblue]">{hawb}</p>
                <div
                  className="ml-auto mr-1 w-[32px] h-[32px] flex items-center justify-center bg-slate-200 hover:bg-slate-300 active:bg-slate-400 cursor-pointer rounded-[10px] active:scale-90"
                  onClick={() => {
                    setDisplayMergeShipment(false);
                  }}
                >
                  <MdOutlineClear size={30} />
                </div>
              </div>
              {loading && (
                <div className="">
                  <LinearProgress />
                  <p className="text-center mt-[2px] text-slate-500">Đang xử lý...</p>
                </div>
              )}
              <div className="p-3 flex">
                <div className="w-[50%] px-2 " style={{ borderRight: '1px solid rgb(230,230,230)' }}>
                  <p className="text-[14px] text-slate-500 my-1">Danh sách hàng hoá cần gộp:</p>
                  {selectingInvoice.map((item, index) => (
                    <div className="p-1.5 bg-slate-200 rounded-[5px] flex items-center my-1.5">
                      <p className="p-1 w-[22px] h-[22px] flex items-center justify-center rounded-full bg-white font-semibold">
                        {index + 1}
                      </p>
                      <p className="mx-2">{item.itemVietnamCommonity}</p>
                    </div>
                  ))}
                </div>
                <div
                  className="w-[50%] px-2 h-full overflow-auto"
                  style={{ borderRight: '1px solid rgb(230,230,230)' }}
                >
                  <p className="text-[14px] text-slate-500 my-1">Giá trị thay thế:</p>
                  <TextField
                    placeholder="Nhập giá trị gộp ở đây"
                    label="Tên mặt hàng gộp:"
                    variant="filled"
                    autoComplete="off"
                    style={{ margin: 1 }}
                    fullWidth
                    required
                    multiline
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <TextField
                    placeholder="Nhập giá trị gộp ở đây"
                    label="Mã hàng hoá:"
                    variant="filled"
                    autoComplete="off"
                    style={{ margin: 1 }}
                    fullWidth
                    multiline
                    required
                    value={hsCode}
                    onChange={(e) => setHsCode(e.target.value)}
                  />
                  <TextField
                    placeholder="Nhập giá trị gộp ở đây"
                    label="Giá trị tính:"
                    variant="filled"
                    autoComplete="off"
                    style={{ margin: 1 }}
                    fullWidth
                    multiline
                    required
                    value={unitString}
                    onChange={(e) => setUnitString(e.target.value)}
                  />
                  <p className="text-rose-500 text-[13px] m-1">
                    Lưu ý: Mọi giá trị ở cột khác liên quan đến mặt hàng sẽ được cộng tổng lại nếu anh chị thực hiện
                    hành động này, hãy cẩn thận để tránh gặp sai xót trong quá trình đóng hàng nhé.
                  </p>
                </div>
              </div>
              <div className="p-3 sticky bottom-0 z-10 bg-white border-t-[1px] border-b-0 border-r-0 border-l-0  border-slate-300 flex items-center gap-1">
                <div
                  className="p-1.5 px-3 rounded-[5px] hover:bg-slate-100 active:bg-slate-200 cursor-pointer select-none text-slate-600 font-medium"
                  onClick={() => {
                    setDisplayMergeShipment(false);
                  }}
                >
                  <p>Huỷ bỏ</p>
                </div>
                <button
                  type="submit"
                  className="p-1.5 px-3 rounded-[5px] hover:bg-emerald-100 active:bg-emerald-200 cursor-pointer select-none text-emerald-500 font-semibold"
                >
                  <p>Gộp tờ khai</p>
                </button>
              </div>
            </div>
          </OutsideClickHandler>
        </form>
      )}
      {shipmentType === 'WPX' &&
        finalInvoice?.map((item, index) => (
          <div className="bg-white w-full flex cursor-pointer hover:bg-slate-100 rounded-[5px] active:bg-slate-200">
            <div className="p-1.5 w-[50%] flex items-center  text-slate-600 hover:shadow-[rgba(0,0,0,0.15)_2.4px_2.4px_3.2px]">
              <Checkbox
                checked={item.select}
                onChange={(e) => {
                  setSelectedInvoice(
                    finalInvoice.map((sd, i) => {
                      if (sd.id === item.id) {
                        sd.select = e.target.checked;
                      }
                      return sd;
                    })
                  );
                }}
                size="small"
                style={{ padding: 7 }}
              />
              <p className="mx-1 w-[28px] h-[28px] rounded-full text-center flex items-center justify-center bg-slate-200 font-semibold">
                {index + 1}
              </p>
              <TextareaAutosize
                type="text"
                className="p-1.5 ml-1 rounded-[5px] font-semibold w-[90%]"
                name="itemVietnamCommonity"
                value={item.itemVietnamCommonity}
                onChange={(e) => {
                  const newInvoice = finalInvoice.map((sd, i) => {
                    if (item.id === sd.id) {
                      sd.itemVietnamCommonity = e.target.value;
                    }
                    return sd;
                  });
                  setSelectedInvoice(newInvoice);
                }}
              />
            </div>
            <div className="p-1.5 w-[23%] flex items-center  text-slate-600 border-t-0">
              <TextareaAutosize
                type="text"
                className="p-1.5 ml-1 rounded-[5px] font-semibold w-[90%] resize-none focus:shadow-[rgba(99,99,99,0.2)_0px_2px_8px_0px]"
                name="itemVietnamCommonity"
                value={item.itemHsCode}
                onChange={(e) => {
                  const newInvoice = finalInvoice.map((sd, i) => {
                    if (item.id === sd.id) {
                      sd.itemHsCode = e.target.value;
                    }
                    return sd;
                  });
                  setSelectedInvoice(newInvoice);
                }}
              />
            </div>
            <div className="p-1.5 w-[17%] flex items-center  text-slate-600 border-t-0">
              <img src={`https://flagsapi.com/VN/shiny/64.png`} className="w-[25px] h-[25px]" />
              <p className="ml-2 font-semibold">VN</p>
            </div>
            <div className="p-1.5 w-[17%] flex items-center  text-slate-600 border-t-0">
              <input
                type="number"
                className="p-1.5 ml-1 rounded-[5px] font-semibold w-[90%] resize-none focus:shadow-[rgba(99,99,99,0.2)_0px_2px_8px_0px]"
                name="itemVietnamCommonity"
                value={item.itemQuantity}
                onChange={(e) => {
                  const newInvoice = finalInvoice.map((sd, i) => {
                    if (item.id === sd.id) {
                      sd.itemQuantity = e.target.value;
                    }
                    return sd;
                  });
                  setSelectedInvoice(newInvoice);
                }}
              />
            </div>
            <div className="p-1.5 w-[17%] flex items-center  text-slate-600 border-t-0">
              <input
                type="text"
                className="p-1.5 ml-1 rounded-[5px] font-semibold w-[90%] resize-none focus:shadow-[rgba(99,99,99,0.2)_0px_2px_8px_0px]"
                name="itemVietnamCommonity"
                value={item.itemUnit}
                onChange={(e) => {
                  const newInvoice = finalInvoice.map((sd, i) => {
                    if (item.id === sd.id) {
                      sd.itemUnit = e.target.value;
                    }
                    return sd;
                  });
                  setSelectedInvoice(newInvoice);
                }}
              />
            </div>
            <div className="p-1.5 w-[17%] flex items-center  text-slate-600 border-t-0">
              <input
                type="number"
                className="p-1.5 ml-1 rounded-[5px] font-semibold w-[90%] resize-none focus:shadow-[rgba(99,99,99,0.2)_0px_2px_8px_0px]"
                name="itemVietnamCommonity"
                value={item.itemUnitPrice}
                onChange={(e) => {
                  const newInvoice = finalInvoice.map((sd, i) => {
                    if (item.id === sd.id) {
                      sd.itemUnitPrice = e.target.value;
                    }
                    return sd;
                  });
                  setSelectedInvoice(newInvoice);
                }}
              />
            </div>
            <div className="p-1.5 w-[17%] flex items-center  text-slate-600 border-t-0">
              <input
                type="number"
                className="p-1.5 ml-1 rounded-[5px] font-semibold w-[90%] resize-none focus:shadow-[rgba(99,99,99,0.2)_0px_2px_8px_0px]"
                name="itemVietnamCommonity"
                value={item.itemValue}
                onChange={(e) => {
                  const newInvoice = finalInvoice.map((sd, i) => {
                    if (item.id === sd.id) {
                      sd.itemValue = e.target.value;
                    }
                    return sd;
                  });
                  setSelectedInvoice(newInvoice);
                }}
              />
              <div
                className="p-1.5 flex items-center rounded-[10px] bg-rose-50 text-rose-500 ml-1 hover:bg-rose-100 active:bg-rose-200 cursor-pointer"
                onClick={() => {
                  if (selectedInvoice?.length < 2) return;
                  setSelectedInvoice(
                    finalInvoice.filter((s) => {
                      return s.id !== item.id;
                    })
                  );
                }}
              >
                <MdOutlineClear size={20} />
              </div>
            </div>
          </div>
        ))}
      {shipmentType === 'DOX' && (
        <div className="bg-white w-full flex cursor-pointer hover:bg-slate-100 rounded-[5px] active:bg-slate-200">
          <div className="p-1.5 w-[50%] flex items-center  text-slate-600 hover:shadow-[rgba(0,0,0,0.15)_2.4px_2.4px_3.2px]">
            <Checkbox size="small" style={{ padding: 7 }} />
            <p className="mx-1 w-[28px] h-[28px] rounded-full text-center flex items-center justify-center bg-slate-200 font-semibold">
              1
            </p>
            <p className="p-1">Chứng từ</p>
          </div>
          <div className="p-1.5 w-[23%] flex items-center  text-slate-600 border-t-0"></div>
          <div className="p-1.5 w-[17%] flex items-center  text-slate-600 border-t-0">
            <img src={`https://flagsapi.com/VN/shiny/64.png`} className="w-[25px] h-[25px]" />
            <p className="ml-2 font-semibold">VN</p>
          </div>
          <div className="p-1.5 w-[17%] flex items-center  text-slate-600 border-t-0"></div>
          <div className="p-1.5 w-[17%] flex items-center  text-slate-600 border-t-0"></div>
          <div className="p-1.5 w-[17%] flex items-center  text-slate-600 border-t-0"></div>
          <div className="p-1.5 w-[17%] flex items-center  text-slate-600 border-t-0"></div>
        </div>
      )}
      <div className="flex items-center">
        {isEdit && (
          <div
            className="p-1 bg-emerald-600 text-white px-3 rounded-[10px] ml-1 w-fit font-semibold my-1.5 cursor-pointer hover:bg-emerald-700 active:bg-emerald-800 select-none"
            onClick={() => {
              if (invoiceValue === selectedInvoice) {
                alert('Hãy chỉnh sửa trước khi lưu');
              } else {
                db.collection('shipments')
                  .doc(hawb)
                  .update({
                    invoiceValue: finalInvoice,
                    lastInvoiceUpdate: firebase.firestore.FieldValue.serverTimestamp(),
                    updateInvoiceBy: user.displayName,
                  })
                  .then(() => {
                    alert('Cập nhật thành công');
                    setIsEdit(false);
                  })
                  .catch((err) => {
                    alert(`${err.message}`);
                  });
              }
            }}
          >
            <p>Lưu thay đổi</p>
          </div>
        )}
        <div
          className={`p-1 bg-sky-50 text-sky-600 px-3 rounded-[10px] ml-1 w-fit font-semibold my-1.5  hover:bg-sky-100 active:bg-sky-200 select-none ${
            selectingInvoice?.length === 0 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
          }`}
          onClick={() => {
            if (selectingInvoice.length === 0) return;
            setDisplayMergeShipment(!displayMergeShipment);
          }}
        >
          <p>Gộp mặt hàng</p>
        </div>
        <div className="p-1 bg-slate-100 text-slate-700 px-3 rounded-[10px] ml-1 w-fit font-semibold my-1.5 cursor-pointer hover:bg-slate-200 active:bg-slate-300 select-none">
          <p>
            Đang chọn <span className="underline">{selectingInvoice.length}</span> mặt hàng
          </p>
        </div>
        <p className="ml-2 text-slate-500">
          | Thay đổi gần đây: {lastInvoiceUpdate || 'Chưa có cập nhật'} {updateInvoiceBy ? 'bởi' : ''} {updateInvoiceBy}
        </p>
      </div>
    </div>
  );
}
