import React, { useState, useEffect } from 'react';
import { BiRefresh } from 'react-icons/bi';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { FaSortDown } from 'react-icons/fa6';
import { IoAddSharp, IoArrowRedoSharp } from 'react-icons/io5';
import { MdOutlineClear } from 'react-icons/md';
import OutsideClickHandler from 'react-outside-click-handler';
import { countries } from '../../../api/countries';
import { v1 as uuid } from 'uuid';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { db } from '../../../../server/firebase';
export default function CustomizingSelect({
  handleSelectedCustomizeShipment,
  headerGroups,
  selected,
  handleSelectedCustomizeShipmentRemove,
  setDisplayCustomeSelectOption,
}) {
  const [columnArray, setColumnArray] = useState([{ headerValue: '', wrapValue: '', active: false, id: uuid() }]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [displayType, setDisplayType] = useState(false);
  const [displayCountry, setDisplayCountry] = useState(false);
  const [displaySelectOption, setDisplaySelectOption] = useState(false);
  const [fromWeight, setFromWeight] = useState('');
  const [toWeight, setToWeight] = useState('');
  const [showColumn, setShowColumn] = useState(true);
  const [showWeight, setShowWeight] = useState(true);
  const [type, setType] = useState('');
  const [country, setCountry] = useState('');
  const [selectedHeader, setSelectedHeader] = useState([]);
  const [selectedColumn, setSelectedColunm] = useState([]);

  const handleAddColumn = () => {
    let x = columnArray.length;
    setColumnArray([...columnArray, { headerValue: '', wrapValue: '', active: false, id: uuid() }]);
  };
  const handleResetColumn = () => {
    setColumnArray([{ headerValue: '', wrapValue: '', active: false, id: uuid() }]);
    setIsSelecting(false);
    setType('');
    setCountry('');
    setFromWeight('');
    setToWeight('');
    handleSelectedCustomizeShipmentRemove();
  };
  let handleChange = (i, e) => {
    let newFormValues = [...columnArray];
    newFormValues[i][e.target.name] = e.target.value;
    setColumnArray(newFormValues);
  };

  const handleSelectConfirm = (selectOption) => {
    handleSelectedCustomizeShipment(type, country, fromWeight, toWeight, columnArray, selectOption);
    setIsSelecting(true);
    setDisplaySelectOption(false);
  };

  const handleSelectColumn = (columnId) => {
    setSelectedColunm(
      columnArray.map((sd) => {
        if (sd.id === columnId) {
          sd.active = !sd.active;
        } else {
          sd.active = false;
        }
        return sd;
      })
    );
  };

  return (
    <div className="absolute bg-white mt-[-45px] w-[650px] rounded-[10px] z-10 shadow-[rgba(50,50,105,0.15)_0px_2px_5px_0px,rgba(0,0,0,0.2)_0px_1px_1px_0px] ml-[255px] max-h-[calc(100vh_-_20.7rem)] overflow-auto">
      {isSelecting && (
        <div className="absolute left-0 right-0 bottom-0 top-0 bg-[rgba(255,255,255,0.75)] flex items-center justify-center w-full h-full z-[9999]">
          <div className="p-2 flex flex-col items-center justify-center">
            <p className="text-[14px] text-slate-500">You are selecting:</p>
            <p className="text-[darkblue] text-lg">
              <span className="font-semibold underline">{selected?.length}</span> shipments that contained your option
            </p>
            <div
              className="p-1.5 bg-sky-50 text-sky-600 font-semibold hover:bg-sky-100 active:bg-sky-200 cursor-pointer select-none rounded-[7px] px-2.5 tracking-wide mt-2"
              onClick={handleResetColumn}
            >
              <p>Select another shipments</p>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col">
        <div className="p-2 sticky top-0 bg-white z-10 shadow-[rgba(0,0,0,0.1)_2.4px_2.4px_3.2px] flex items-center justify-between">
          <p className="text-xl tracking-wide font-semibold leading-5">Customizing shipments selection</p>
          <div className="flex items-center">
            {selected?.length !== 0 && (
              <div
                className="w-[30px] h-[30px] rounded-[10px] bg-slate-100 hover:bg-slate-200 active:bg-slate-300 active:scale-90 flex items-center justify-center mr-1 cursor-pointer"
                onClick={() => {
                  handleSelectedCustomizeShipmentRemove();
                }}
              >
                <BiRefresh size={25} />
              </div>
            )}
            <div
              className="w-[30px] h-[30px] rounded-[10px] bg-slate-100 hover:bg-slate-200 active:bg-slate-300 active:scale-90 flex items-center justify-center mr-1 cursor-pointer"
              onClick={() => {
                setDisplayCustomeSelectOption(false);
              }}
            >
              <MdOutlineClear size={25} />
            </div>
          </div>
        </div>
        <div className="flex items-center mt-2 px-2 cursor-pointer">
          <p className="text-[13px] text-slate-500">By columns:</p>
          <FaSortDown size={20} className="ml-auto mr-2" />
        </div>
        <div className="flex flex-col gap-y-1.5 p-2">
          {columnArray.map((item, index) => (
            <div className="flex items-center pt-1.5 px-1" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
              <p className="w-[95px]">Column {index + 1}</p>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setSelectedColunm(
                    columnArray.map((sd) => {
                      if (sd.id === item.id) {
                        sd.active = false;
                      }
                      return sd;
                    })
                  );
                }}
              >
                <div className="relative">
                  <div
                    className={`p-1 bg-white border-solid border-slate-300 border-[1px] rounded-[10px] px-2.5 flex items-center select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300 w-[250px] ${
                      item.active ? 'search-column-input1' : ''
                    }`}
                    onClick={() => handleSelectColumn(item.id)}
                  >
                    <p>{item.headerValue || 'Choose a column to select'}</p>
                    <FaSortDown size={25} className="mt-[-10px] ml-auto" />
                  </div>
                  {item.active && (
                    <div className="absolute bg-white w-full shadow-[rgba(17,17,26,0.1)_0px_4px_16px,rgba(17,17,26,0.05)_0px_8px_32px] mt-2 rounded-[10px] max-h-[170px] overflow-auto z-10">
                      {headerGroups.map((headerGroup) => (
                        <div {...headerGroup.getHeaderGroupProps({})} style={{ display: 'grid' }}>
                          {headerGroup.headers.map((column, index) => (
                            <>
                              {index !== 0 && (
                                <div
                                  className="p-1 px-2.5 rounded-[10px] hover:bg-slate-200 active:bg-slate-300 cursor-pointer m-1 my-0.5 hover:font-semibold"
                                  onClick={() => {
                                    setSelectedColunm(
                                      columnArray.map((sd) => {
                                        if (sd.id === item.id) {
                                          sd.headerValue = column.render('Header');
                                          sd.active = false;
                                        }
                                        return sd;
                                      })
                                    );
                                  }}
                                >
                                  <p>{column.render('Header')}</p>
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </OutsideClickHandler>
              <FaLongArrowAltRight size={25} className="mx-2" />
              <input
                type="text"
                className="p-1 bg-white border-solid border-slate-300 border-[1px] rounded-[10px] px-2.5 flex items-center select-none outline-none w-[250px] search-column-input"
                placeholder="Enter wrap value"
                value={item.wrapValue}
                name="wrapValue"
                onChange={(e) => handleChange(index, e)}
              />
            </div>
          ))}
        </div>
        <div className="flex gap-x-1.5 px-2">
          <div
            className="flex items-center my-2 p-[5px] bg-emerald-50 rounded-[10px] w-fit px-2.5 select-none hover:bg-emerald-100 active:bg-emerald-200 cursor-pointer text-emerald-600"
            onClick={handleAddColumn}
          >
            <IoAddSharp size={25} />
            <p className="mx-1.5">Add more column</p>
          </div>
          <div
            className="flex items-center my-2 p-[5px] bg-slate-200 rounded-[10px] w-fit px-2.5 select-none hover:bg-slate-300 active:bg-slate-400 cursor-pointer"
            onClick={handleResetColumn}
          >
            <BiRefresh size={25} />
            <p className="mx-1.5">Reset column</p>
          </div>
        </div>
        <div className="flex items-center mt-2 px-2 cursor-pointer">
          <p className="text-[13px] text-slate-500">By weight:</p>
          <FaSortDown size={20} className="ml-auto mr-2" />
        </div>
        <div className="flex flex-col gap-y-1.5 p-2">
          <div className="flex items-center pt-1.5 gap-x-1.5" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
            <OutsideClickHandler
              onOutsideClick={() => {
                setDisplayType(false);
              }}
            >
              <div className="relative">
                <div
                  className={`p-1 bg-white border-solid border-slate-300 border-[1px] rounded-[10px] px-2.5 flex items-center select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300 w-[90px] ${
                    displayType ? 'search-column-input1' : ''
                  }`}
                  onClick={() => {
                    setDisplayType(!displayType);
                  }}
                >
                  <p>{type || 'ALL'}</p>
                  <FaSortDown size={25} className="mt-[-10px] ml-auto" />
                </div>
                {displayType && (
                  <div className="absolute shadow-[rgba(17,17,26,0.1)_0px_4px_16px,rgba(17,17,26,0.1)_0px_8px_32px] mt-[-115px]  rounded-[5px] bg-white w-full top-0 p-1">
                    <div
                      className="p-1 hover:bg-slate-200 active:bg-slate-300 cursor-pointer rounded-[5px]"
                      onClick={() => {
                        setDisplayType(false);
                        setType('');
                      }}
                    >
                      <p>ALL</p>
                    </div>
                    <div
                      className="p-1 hover:bg-slate-200 active:bg-slate-300 cursor-pointer rounded-[5px]"
                      onClick={() => {
                        setDisplayType(false);
                        setType('DOX');
                      }}
                    >
                      <p>DOX</p>
                    </div>
                    <div
                      className="p-1 hover:bg-slate-200 active:bg-slate-300 cursor-pointer rounded-[5px]"
                      onClick={() => {
                        setDisplayType(false);
                        setType('WPX');
                      }}
                    >
                      <p>WPX</p>
                    </div>
                  </div>
                )}
              </div>
            </OutsideClickHandler>
            <OutsideClickHandler
              onOutsideClick={() => {
                setDisplayCountry(false);
              }}
            >
              <div className="relative">
                <div
                  className={`p-1 bg-white border-solid border-slate-300 border-[1px] rounded-[10px] px-2.5 flex items-center select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300 w-[243px] ${
                    displayCountry ? 'search-column-input1' : ''
                  }`}
                  onClick={() => {
                    setDisplayCountry(!displayCountry);
                  }}
                >
                  <p>{country || 'Select a country'}</p>
                  <FaSortDown size={25} className="mt-[-10px] ml-auto" />
                </div>
                {displayCountry && (
                  <div className="absolute shadow-[rgba(17,17,26,0.1)_0px_4px_16px,rgba(17,17,26,0.1)_0px_8px_32px] mt-[-160px]  rounded-[5px] bg-white w-full top-0 p-1 h-[150px] overflow-auto">
                    {countries.map((el, index) => (
                      <div
                        className="p-1 hover:bg-slate-200 active:bg-slate-300 cursor-pointer rounded-[5px]"
                        onClick={() => {
                          setDisplayCountry(false);
                          setCountry(el.label);
                        }}
                      >
                        <p>{el.label}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </OutsideClickHandler>
            <p>From</p>
            <FaLongArrowAltRight size={25} className="mx-2" />
            <input
              type="text"
              className="p-1 bg-white border-solid border-slate-300 border-[1px] rounded-[10px] px-2.5 flex items-center select-none outline-none w-[60px] search-column-input text-center"
              placeholder="KG"
              value={fromWeight}
              onChange={(e) => setFromWeight(e.target.value)}
            />
            <FaLongArrowAltRight size={25} className="mx-2" />
            <p>To</p>
            <input
              type="text"
              className="p-1 bg-white border-solid border-slate-300 border-[1px] rounded-[10px] px-2.5 flex items-center select-none outline-none w-[60px] search-column-input text-center"
              value={toWeight}
              onChange={(e) => setToWeight(e.target.value)}
              placeholder="KG"
            />
          </div>
        </div>
        <p className="text-[13px] p-2 text-slate-500 mb-auto" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
          Note: if you leave the "type" and "country" blank, system will get all of the shipments from every value
        </p>
        <div className="p-2.5 flex items-center gap-x-1.5 sticky bottom-0 z-10 bg-white">
          <div
            className="w-full bg-rose-50 text-rose-500 flex items-center justify-center p-1.5 font-semibold text-[17px] rounded-[10px] hover:bg-rose-100 active:bg-rose-200 cursor-pointer"
            onClick={() => {
              setDisplayCustomeSelectOption(false);
            }}
          >
            <p>Cancel</p>
          </div>
          <div className="relative w-full">
            <div
              className="w-full bg-emerald-50 text-emerald-600 flex items-center justify-center p-1.5 font-semibold text-[17px] cursor-pointer rounded-[10px] hover:bg-emerald-100 active:bg-emerald-200"
              onClick={() => {
                setDisplaySelectOption(!displaySelectOption);
              }}
            >
              <p className="mr-1.5">Select</p>
              <BsThreeDotsVertical size={20} />
            </div>
            {displaySelectOption && (
              <div className="absolute bg-white shadow-[rgba(17,17,26,0.1)_0px_4px_16px,rgba(17,17,26,0.05)_0px_8px_32px] top-0 mt-[-148px] w-full rounded-[10px] select-none">
                <div
                  className="m-1 p-1.5 rounded-[10px] border-solid border-slate-300 border-[1px] px-2.5 cursor-pointer hover:bg-slate-200 active:bg-slate-300 flex items-center "
                  onClick={() => {
                    handleSelectConfirm('Weight');
                  }}
                >
                  <p>Select by weight</p>
                  <IoArrowRedoSharp size={25} className="ml-auto" />
                </div>
                <div
                  className="m-1 p-1.5 rounded-[10px] border-solid border-slate-300 border-[1px] px-2.5 cursor-pointer hover:bg-slate-200 active:bg-slate-300 flex items-center "
                  onClick={() => {
                    handleSelectConfirm('Columns');
                  }}
                >
                  <p>Select by columns</p>
                  <IoArrowRedoSharp size={25} className="ml-auto" />
                </div>
                <div
                  className="m-1 p-1.5 rounded-[10px] border-solid border-slate-300 border-[1px] px-2.5 cursor-pointer hover:bg-slate-200 active:bg-slate-300 flex items-center"
                  onClick={() => {
                    handleSelectConfirm('All');
                  }}
                >
                  <p>Select all by requested option</p>
                  <IoArrowRedoSharp size={25} className="ml-auto" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
