import { React, useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import CustomerSidebar from './customer/components/CustomerSidebar';
import Booking3 from './customer/routes/Booking3';
import CustomerHome from './customer/routes/CustomerHome';
import Dashboard from './customer/routes/Dashboard';
import Draft from './customer/routes/Draft';
import Label from './customer/routes/Label';
import Personal from './customer/routes/Personal';
import TrackingContainer from './customer/routes/TrackingContainer';
import TrackingResult from './staff/pages/tracking/TrackingResult';

export default function CustomerView({ user }) {
  const [currentSidebar, setCurrentSidebar] = useState(true);
  return (
    <Router>
      {/* <div className={`flex ${pathname === '/create' ? 'skydart-bookShipment' : 'customer-app'}`}> */}
      <div className={`customer-app`}>
        <CustomerSidebar user={user} setCurrentSidebar={setCurrentSidebar} />
        <div className="w-full">
          {/* <CustomerHome /> */}
          <Routes>
            <Route exact path="/" element={<CustomerHome user={user} />} />
            <Route exact path="/create" element={<Booking3 user={user} />} />
            <Route exact path="/create/shipment/:countryParam/:codeParam" element={<Booking3 user={user} />} />
            <Route exact path="/drafts" element={<Draft user={user} />} />
            <Route exact path="/labels" element={<Label user={user} />} />
            <Route exact path="/shipments" element={<Dashboard user={user} currentSidebar={currentSidebar} />} />
            <Route exact path="/settings" element={<Personal user={user} />} />
            <Route exact path="/tracking" element={<TrackingContainer user={user} />} />
            <Route exact path="/tracking-result/:hawb/:uid" element={<TrackingResult user={user} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}
