import { useMonth } from '@datepicker-react/hooks';
import { jsx } from '@emotion/react';

import Day1 from './Day1';

function Month({ year, month, firstDayOfWeek }) {
  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
  });

  return (
    <div>
      <div style={{ textAlign: 'center', margin: '0 0 16px' }}>
        <strong style={{ fontSize: 13, fontWeight: 600 }}>{monthLabel}</strong>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr)',
          justifyContent: 'center',
          marginBottom: '10px',
          fontSize: '10px',
        }}
      >
        {weekdayLabels.map((dayLabel) => (
          <div style={{ textAlign: 'center', fontSize: 12 }} key={dayLabel}>
            {dayLabel}
          </div>
        ))}
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr)',
          justifyContent: 'center',
        }}
      >
        {days.map((day, index) => {
          if (typeof day === 'object') {
            return <Day1 date={day.date} key={day.date.toString()} dayLabel={day.dayLabel} />;
          }

          return <div key={index} />;
        })}
      </div>
    </div>
  );
}

export default Month;
