import { CircularProgress, TextField } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { MdOutlineClear } from 'react-icons/md';

export default function Endpoint({ selected, returnFunction }) {
  const [loading, setLoading] = useState(false);
  const [endpoint, setEndpoint] = useState('');

  const handleUpdateEndpoint = () => {
    setLoading(true);
    selected?.forEach((s, index) => {
      s.loading = true;
      axios.put('https://test.skydart.com.vn/api/update/endpoint', { hawb: s.hawb, endpoint }).then(() => {
        s.loading = false;
        s.updateSuccess = 'true';
        s.endpoint = endpoint;
        if (index === selected?.length - 1) {
          setLoading(false);
          alert('Cập nhật hoàn tất');
        }
        return s;
      });
    });
  };
  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 bg-[rgba(0,0,0,0.55)] flex items-center justify-center z-[9999]">
      {loading && (
        <div className="w-[420px] h-[200px] flex items-center justify-center rounded-[10px] bg-white gap-1.5">
          <CircularProgress size={23} />
          <p className="ml-1.5">Loading...</p>
        </div>
      )}
      {!loading && (
        <div className="w-[420px] max-h-[420px] overflow-auto bg-white rounded-[10px]">
          <div className="p-3 flex items-center" style={{ borderBottom: '1px solid rgb(230,230,230)' }}>
            <p className="text-xl font-semibold text-slate-600">Cập nhật cửa bay</p>
            <div className="w-[30px] h-[30px] rounded-[10px] bg-slate-200 hover:bg-slate-300 active:bg-slate-400 flex items-center justify-center cursor-pointer ml-auto" onClick={() => returnFunction()}>
              <MdOutlineClear size={25} />
            </div>
          </div>
          <div className="flex flex-wrap p-3">
            <p>Đang chọn {selected?.length} lô hàng</p>
          </div>
          <div className="flex" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
            <TextField
              value={endpoint}
              onChange={(e) => setEndpoint(e.target.value)}
              label="Đại lý xuất hàng"
              fullWidth
              multiline
              size="small"
              style={{ margin: 10 }}
            />
          </div>
          <div className="flex m-[10px] mt-0 gap-1">
            <button
              type="button"
              className="p-1.5 bg-sky-600 text-white font-semibold px-4 hover:bg-sky-700 active:bg-sky-800 rounded-[7px] cursor-pointer"
              onClick={() => {
                if (endpoint === '') return;
                handleUpdateEndpoint();
              }}
            >
              Cập nhật
            </button>
            <button
              type="button"
              className="p-1.5 bg-slate-200 font-semibold px-4 hover:bg-slate-300 active:bg-slate-400 rounded-[7px] cursor-pointer"
              onClick={() => returnFunction()}
            >
              Huỷ bỏ
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
