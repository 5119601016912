import React, { useState, useRef, useEffect } from 'react';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import ReactToExcel from 'react-html-table-to-excel';
import Debit2 from './Debit2';
import { MdOutlineClear } from 'react-icons/md';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
export default function ExportCustomer({
  displayExport,
  setDisplayExport,
  setGetPdf,
  fileName,
  setFileName,
  exporterName,
  startDay,
  endDay,
  currencyExchange,
  getPdf,
  monthName,
  finalShipment,
  totalWeight,
  totalReweight,
  totalRate,
  totalComestic,
  totalAddressCorrection,
  totalRemote,
  totalSurcharge1,
  totalFscValue,
  totalSurcharge2,
  totalVatValue,
  totalSurcharge3,
  totalAmount,
  totalVND,
  headers,
  excelFileName,
  startDate,
  endDate,
  customerDetail,
}) {
  let pdfRef = useRef();
  const [exportStatus, setExportStatus] = useState('Raw');
  const [getExcel, setGetExcel] = useState([]);
  const [includeInfo, setIncludeInfo] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState({});
  const generatePDF = (e) => {
    pdfRef.current.save();
    setDisplayExport(false);
    setGetExcel([]);
    setGetPdf([]);
    setFileName('');
    setExportStatus('Raw');
  };
  const debitExport = {
    data: finalShipment,
    headers: headers,
    filename: excelFileName || `${exporterName}'s report ${startDay} to ${endDay}`,
  };
  const excelExport = finalShipment
    ?.sort((a, b) => {
      if (includeInfo) {
        a = a.date || '';
        b = b.date || '';
        return a.localeCompare(b);
      }
    })
    .map((s) => {
      return {
        CUSTOMER: s.sender,
        DATE: new Date(s.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
        HAWB: s.hawb,
        SIN: s.sin,
        DESTINATION: s.country,
        SERVICE: s.service,
        TYPE: s.type,
        WEIGHT: s.chargeable,
        REWEIGHT: s.reweight,
        RATE: +s.rate,
        COMESTIC: +s.comestic,
        WrongAddress: +s.addressCorrection,
        REMOTE: +s.remote,
        EXTRA1: +s.surcharge,
        FSC: +s.fscExtra,
        EXTRA2: +s.surcharge2,
        VAT: +s.vatValue,
        EXTRA3: +s.surcharge3,
        TOTAL: +s.totalExtra,
        NOTE: s.accNote,
        NOTE2: s.sellNote,
      };
    });
  const handleExportExcel = () => {
    setDisplayExport(false);
    setFileName('');
    setGetExcel([]);
    setGetPdf([]);
    setExportStatus('Raw');
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(excelExport);
    XLSX.utils.book_append_sheet(wb, ws, excelFileName || `report`);
    XLSX.writeFile(wb, `${excelFileName}.csv` || `report.csv`);
  };
  useEffect(() => {
    setCurrentCustomer(customerDetail);
  }, [customerDetail]);
  return (
    <div className="skd-user-info-export">
      <div style={{ position: 'absolute', left: -9999, width: '70%', zIndex: 9999 }}>
        <PDFExport
          ref={pdfRef}
          paperSize="auto"
          margin={40}
          fileName={fileName || `${currentCustomer?.exporter}'s report ${startDay} to ${endDay}`}
        >
          <Debit2
            exporter={currentCustomer?.exporter}
            address={currentCustomer?.address}
            currency={currencyExchange}
            startDate={startDate}
            endDate={endDate}
            address2={currentCustomer?.address2}
            address3={currentCustomer?.address3}
            mst={currentCustomer?.mst}
            displayName={currentCustomer?.displayName}
            email={currentCustomer?.email}
            phone={currentCustomer?.phone}
            makh={currentCustomer?.makh}
            getCustomer={getPdf}
          />
        </PDFExport>
      </div>
      <table
        className="skd-user-info-export-excel-table"
        id="export-table"
        style={{ background: '#f0f3f4', width: 500 }}
      >
        <thead className="skd-user-info-export-excel-table-info">
          <tr
            className="skd-user-info-export-excel-table-info-container"
            style={{ background: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <img src="https://i.imgur.com/L6PnhXq.png" alt="" height="90" />
            <td
              style={{
                fontSize: 25,
                textAlign: 'center',
                fontWeight: 450,
                color: 'rgb(55,55,55)',
                marginBottom: 5,
                marginLeft: 10,
              }}
            ></td>
            <td style={{ fontSize: 24, textAlign: 'center', fontWeight: 450, color: 'rgb(55,55,55)', marginBottom: 5 }}>
              PAYMENT NOTIFICATION
            </td>
          </tr>
          <tr
            className="skd-user-info-export-excel-table-info-container"
            style={{ background: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <td
              style={{ fontSize: 25, textAlign: 'center', fontWeight: 450, color: 'rgb(55,55,55)', marginLeft: 10 }}
            ></td>
            <td style={{ fontSize: 24, textAlign: 'center', fontWeight: 450, color: 'darkblue' }}>Skydart Express</td>
          </tr>
          <tr
            className="skd-user-info-export-excel-table-info-container"
            style={{ background: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <td
              style={{
                fontSize: 25,
                textAlign: 'center',
                fontWeight: 450,
                color: 'rgb(55,55,55)',
                marginBottom: 5,
                marginLeft: 10,
              }}
            ></td>
            <td style={{ fontSize: 16, textAlign: 'center', fontWeight: 450, color: 'rgb(55,55,55)', marginBottom: 5 }}>
              International Air Express Services
            </td>
          </tr>
          <tr className="skd-user-info-export-excel-table-info-container" style={{ display: 'flex' }}>
            <td style={{ fontSize: 12, marginLeft: 12, marginTop: 20 }}>from</td>
            <td style={{ fontSize: 12, marginLeft: 12, marginTop: 20 }}>to</td>
            <td style={{ fontSize: 12, marginLeft: 12, marginTop: 20 }}></td>
            <td style={{ fontSize: 16, textAlign: 'right', marginTop: 10 }}>VND Account Number: 112 0000 13407</td>
          </tr>
          <tr className="skd-user-info-export-excel-table-info-container" style={{ display: 'flex' }}>
            <td style={{ fontSize: 18, marginLeft: 10, marginTop: 20 }}>CÔNG TY TNHH QUỲNH VŨ</td>
            <td style={{ fontSize: 18, marginLeft: 10, marginTop: 20 }}>{currentCustomer?.exporter}</td>
            <td style={{ fontSize: 12, marginLeft: 12, marginTop: 20 }}></td>
            <td style={{ fontSize: 16, textAlign: 'right', marginTop: 10 }}>USD Account Number: 114 0001 98472</td>
          </tr>
          <tr className="skd-user-info-export-excel-table-info-container" style={{ display: 'flex' }}>
            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10, marginTop: 20 }}>32 Lam Sơn</td>
            <td style={{ fontSize: 15, fontWeight: 450, marginTop: 20, marginLeft: 10 }}>{currentCustomer?.address}</td>
            <td style={{ fontSize: 12, marginLeft: 12, marginTop: 20 }}></td>
            <td style={{ fontSize: 16, textAlign: 'right', marginLeft: 10, marginTop: 10 }}>Swift Code: ICBVVNVX902</td>
          </tr>
          <tr className="skd-user-info-export-excel-table-info-container" style={{ display: 'flex' }}>
            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10, marginTop: 20 }}>Phường 2</td>
            <td style={{ fontSize: 15, fontWeight: 450, marginTop: 20, marginLeft: 10 }}>{currentCustomer?.state}</td>
          </tr>
          <tr className="skd-user-info-export-excel-table-info-container" style={{ display: 'flex' }}>
            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10, marginTop: 20 }}>Quận Tân Bình</td>
            <td style={{ fontSize: 15, fontWeight: 450, marginTop: 20, marginLeft: 10 }}>{currentCustomer?.town}</td>
          </tr>
          <tr className="skd-user-info-export-excel-table-info-container" style={{ display: 'flex' }}>
            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>Thành phố Hồ Chí Minh, 70500</td>
            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>
              {currentCustomer?.city}, {currentCustomer?.postal}
            </td>
            <td style={{ fontSize: 12, marginLeft: 12, marginTop: 20 }}></td>
            <td style={{ fontSize: 16, textAlign: 'center', marginTop: 10 }}>
              Ngân Hàng thương mại cổ phần công thương Việt Nam - CN 1 - TPHCM
            </td>
          </tr>
          <tr className="skd-user-info-export-excel-table-info-container" style={{ display: 'flex' }}>
            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>Contact: Skydart Express</td>
            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>
              Contact: {currentCustomer?.exporterdisplayName}
            </td>
            <td style={{ fontSize: 12, marginLeft: 12, marginTop: 20 }}></td>
            <td style={{ fontSize: 16, textAlign: 'center', marginTop: 10 }}>VIETINBANK</td>
          </tr>
          <tr className="skd-user-info-export-excel-table-info-container" style={{ display: 'flex' }}>
            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>Phone: +84 28 3848 7976</td>
            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>Phone: {currentCustomer?.phone}</td>
            <td style={{ fontSize: 12, marginLeft: 12, marginTop: 20 }}></td>
            <td style={{ fontSize: 15, textAlign: 'center', marginTop: 10 }}>
              Địa chỉ 93 - 95 Hàm Nghi Quận 1 - TP Hồ Chí Minh
            </td>
          </tr>
          <tr className="skd-user-info-export-excel-table-info-container" style={{ display: 'flex' }}>
            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>TAX ID: 0303369205</td>
            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>TAX ID: {currentCustomer?.mst}</td>
          </tr>
          <tr className="skd-user-info-export-excel-table-info-container" style={{ display: 'flex' }}>
            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>Export time/ {monthName}</td>
            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10 }}>MAKH: {currentCustomer?.makh}</td>
            <td style={{ fontSize: 12, marginLeft: 12, marginTop: 20 }}></td>
            <td style={{ fontSize: 16, width: 330, fontWeight: 700, textAlign: 'center', marginTop: 10 }}>
              Date of issue: {startDay} - {endDay}
            </td>
          </tr>
          <tr className="skd-user-info-export-excel-table-info-container" style={{ background: 'white' }}></tr>
          <tr className="skd-user-info-export-excel-table-info-container" style={{ background: 'white' }}>
            <td style={{ fontSize: 16, fontWeight: 450, marginLeft: 10 }}>Controller:</td>
            <td style={{ fontSize: 16, fontWeight: 450, marginLeft: 10 }}>{currentCustomer?.controller}</td>
          </tr>
          <tr className="skd-user-info-export-excel-table-info-container" style={{ background: 'white' }}></tr>
          <tr className="skd-user-info-export-excel-table-info-container" style={{ background: 'white' }}></tr>
        </thead>
        <tbody>
          <tr
            className="skd-user-info-export-excel-table-info-container"
            style={{ display: 'flex', background: 'white' }}
          >
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
              }}
            >
              DATE
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
              }}
            >
              SKD HAWB
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
                width: 190,
              }}
            >
              INTL AWB
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
                width: 190,
              }}
            >
              MAWB
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
              }}
            >
              Ship To
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
              }}
            >
              SERVICE
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
                width: 100,
              }}
            >
              TYPE
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
              }}
            >
              WEIGHT (KG)
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
              }}
            >
              REWEIGHT (KG)
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
              }}
            >
              RATE (USD)
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
              }}
            >
              EXTRA (USD)
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
              }}
            >
              ADD (USD)
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
              }}
            >
              REMOTE (USD)
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
              }}
            >
              EXTRA 1 (USD)
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
              }}
            >
              FSC (USD)
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
              }}
            >
              EXTRA 2 (USD)
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
              }}
            >
              VAT (USD)
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
              }}
            >
              EXTRA 3 (USD)
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
                borderRight: '1px solid',
              }}
            >
              TOTAL VALUE (USD)
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
                borderRight: '1px solid',
              }}
            >
              Selling Note
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                textAlign: 'center',
                borderTop: '1px solid',
                borderRight: '1px solid',
                background: '#2887E6',
                color: 'white',
                height: 40,
                borderRight: '1px solid',
              }}
            >
              Accountant Note
            </td>
          </tr>

          {getExcel
            ?.sort((a, b) => {
              if (includeInfo) {
                a = a.date || '';
                b = b.date || '';
                return a.localeCompare(b);
              }
            })
            .map((s, index) => (
              <tr
                className="skd-user-info-export-excel-table-info-container"
                key={index}
                style={{ display: 'flex', background: 'white' }}
              >
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'center',
                    border: '1px solid',
                  }}
                >
                  {new Date(s.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'center',
                    border: '1px solid',
                    color: 'darkblue',
                    height: 35,
                  }}
                >
                  {s.hawb}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'center',
                    border: '1px solid',
                  }}
                >
                  {+s.sin}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'center',
                    border: '1px solid',
                  }}
                >
                  {s.mawb}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'center',
                    border: '1px solid',
                  }}
                >
                  {s.country}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'center',
                    border: '1px solid',
                  }}
                >
                  {s.service}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'center',
                    border: '1px solid',
                  }}
                >
                  {s.type}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'center',
                    border: '1px solid',
                  }}
                >
                  {(Math.round(s.chargeable * 100) / 100).toFixed(2)}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'center',
                    border: '1px solid',
                  }}
                >
                  {(Math.round(s.reweight * 100) / 100).toFixed(2)}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'right',
                    border: '1px solid',
                  }}
                >
                  {(Math.round(s.rate * 100) / 100).toFixed(2)}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'right',
                    border: '1px solid',
                  }}
                >
                  {(Math.round(s.comestic * 100) / 100).toFixed(2)}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'right',
                    border: '1px solid',
                  }}
                >
                  {(Math.round(s.addressCorrection * 100) / 100).toFixed(2)}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'right',
                    border: '1px solid',
                  }}
                >
                  {(Math.round(s.remote * 100) / 100).toFixed(2)}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'right',
                    border: '1px solid',
                  }}
                >
                  {(Math.round(s.surcharge * 100) / 100).toFixed(2)}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'right',
                    border: '1px solid',
                  }}
                >
                  {(Math.round(s.fscValue * 100) / 100).toFixed(2)}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'right',
                    border: '1px solid',
                  }}
                >
                  {(Math.round(s.surcharge2 * 100) / 100).toFixed(2)}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'right',
                    border: '1px solid',
                  }}
                >
                  {(Math.round(s.vatValue * 100) / 100).toFixed(2)}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'right',
                    border: '1px solid',
                  }}
                >
                  {(Math.round(s.surcharge3 * 100) / 100).toFixed(2)}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'right',
                    border: '1px solid',
                  }}
                >
                  {(Math.round(s.totalValue * 100) / 100).toFixed(2)}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'right',
                    border: '1px solid',
                  }}
                >
                  {s.sellNote}
                </td>
                <td
                  style={{
                    fontSize: 17,
                    fontWeight: 450,
                    marginLeft: 10,
                    background: 'white',
                    textAlign: 'right',
                    border: '1px solid',
                  }}
                >
                  {s.accNote}
                </td>
              </tr>
            ))}
          <tr
            className="skd-user-info-export-excel-table-info-container"
            style={{ display: 'flex', background: 'white' }}
          >
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                borderBottom: '1px solid',
                textAlign: 'center',
                borderRight: '1px solid',
                background: '#f0f3f4',
                height: 35,
              }}
            ></td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                borderBottom: '1px solid',
                textAlign: 'center',
                borderRight: '1px solid',
                background: '#f0f3f4',
                height: 35,
              }}
            ></td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                borderBottom: '1px solid',
                textAlign: 'center',
                borderRight: '1px solid',
                background: '#f0f3f4',
                height: 35,
                width: 150,
              }}
            ></td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                borderBottom: '1px solid',
                textAlign: 'center',
                borderRight: '1px solid',
                background: '#f0f3f4',
                height: 35,
              }}
            ></td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                borderBottom: '1px solid',
                textAlign: 'center',
                borderRight: '1px solid',
                background: '#f0f3f4',
                height: 35,
              }}
            ></td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                borderBottom: '1px solid',
                textAlign: 'center',
                borderRight: '1px solid',
                background: '#f0f3f4',
                height: 35,
                width: 100,
              }}
            ></td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                borderBottom: '1px solid',
                textAlign: 'center',
                borderRight: '1px solid',
                background: '#f0f3f4',
                height: 35,
                width: 100,
              }}
            ></td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                borderBottom: '1px solid',
                textAlign: 'center',
                borderRight: '1px solid',
                background: '#f0f3f4',
                height: 35,
              }}
            >
              {(Math.round(totalWeight * 100) / 100).toFixed(2)} Kg
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                borderBottom: '1px solid',
                textAlign: 'center',
                borderRight: '1px solid',
                background: '#f0f3f4',
                height: 35,
              }}
            >
              {(Math.round(totalReweight * 100) / 100).toFixed(2)} KG
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                borderBottom: '1px solid',
                textAlign: 'right',
                borderRight: '1px solid',
                background: '#f0f3f4',
                height: 35,
              }}
            >
              {(Math.round(totalRate * 100) / 100).toFixed(2)}
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                borderBottom: '1px solid',
                textAlign: 'right',
                borderRight: '1px solid',
                background: '#f0f3f4',
                height: 35,
              }}
            >
              {(Math.round(totalComestic * 100) / 100).toFixed(2)}
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                borderBottom: '1px solid',
                textAlign: 'right',
                borderRight: '1px solid',
                background: '#f0f3f4',
                height: 35,
              }}
            >
              {(Math.round(totalAddressCorrection * 100) / 100).toFixed(2)}
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                borderBottom: '1px solid',
                textAlign: 'right',
                borderRight: '1px solid',
                background: '#f0f3f4',
                height: 35,
              }}
            >
              {(Math.round(totalRemote * 100) / 100).toFixed(2)}
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                borderBottom: '1px solid',
                textAlign: 'right',
                borderRight: '1px solid',
                background: '#f0f3f4',
                height: 35,
              }}
            >
              {(Math.round(totalSurcharge1 * 100) / 100).toFixed(2)}
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                borderBottom: '1px solid',
                textAlign: 'right',
                borderRight: '1px solid',
                background: '#f0f3f4',
                height: 35,
              }}
            >
              {(Math.round(totalFscValue * 100) / 100).toFixed(2)}
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                borderBottom: '1px solid',
                textAlign: 'right',
                borderRight: '1px solid',
                background: '#f0f3f4',
                height: 35,
              }}
            >
              {(Math.round(totalSurcharge2 * 100) / 100).toFixed(2)}
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                borderBottom: '1px solid',
                textAlign: 'right',
                borderRight: '1px solid',
                background: '#f0f3f4',
                height: 35,
              }}
            >
              {(Math.round(totalVatValue * 100) / 100).toFixed(2)}
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                borderBottom: '1px solid',
                textAlign: 'right',
                borderRight: '1px solid',
                background: '#f0f3f4',
                height: 35,
              }}
            >
              {(Math.round(totalSurcharge3 * 100) / 100).toFixed(2)}
            </td>
            <td
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
                borderBottom: '1px solid',
                textAlign: 'right',
                borderRight: '1px solid',
                background: '#f0f3f4',
                height: 35,
              }}
            >
              {(Math.round(totalAmount * 100) / 100).toFixed(2)}
            </td>
          </tr>
          <tr
            className="skd-user-info-export-excel-table-info-container"
            style={{ display: 'flex', background: 'white' }}
          >
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center', width: 150 }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center', width: 100 }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
          </tr>
          <tr
            className="skd-user-info-export-excel-table-info-container"
            style={{ display: 'flex', background: 'white' }}
          >
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center', width: 150 }}></td>
            <td style={{ fontSize: 13, fontWeight: 450, marginLeft: 10 }}>
              Contact us if you see any wrong calculation
            </td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center', width: 100 }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
          </tr>
          <tr
            className="skd-user-info-export-excel-table-info-container"
            style={{ display: 'flex', background: '#f0f3f4' }}
          >
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center', width: 150 }}></td>
            <td
              style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center', background: '#63BE09' }}
            ></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center', width: 100 }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
          </tr>
          <tr
            className="skd-user-info-export-excel-table-info-container"
            style={{ display: 'flex', background: '#f0f3f4' }}
          >
            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10, textAlign: 'right' }}>Total Value In USD</td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 15, fontWeight: 450, marginLeft: 10, textAlign: 'right' }}>Currency Exchange</td>
            <td
              style={{
                fontSize: 15,
                fontWeight: 450,
                marginLeft: 10,
                textAlign: 'right',
                background: '#63BE09',
                color: 'white',
              }}
            >
              Total Value in VND
            </td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center', width: 100 }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
          </tr>
          <tr
            className="skd-user-info-export-excel-table-info-container"
            style={{ display: 'flex', background: '#f0f3f4' }}
          >
            <td style={{ fontSize: 25, fontWeight: 450, marginLeft: 10, textAlign: 'right' }}>
              ${totalAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </td>
            <td style={{ fontSize: 25, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}>X</td>
            <td style={{ fontSize: 25, fontWeight: 450, marginLeft: 10, textAlign: 'right' }}>{currencyExchange}</td>
            <td
              style={{
                fontSize: 26,
                fontWeight: 450,
                marginLeft: 10,
                textAlign: 'right',
                background: '#63BE09',
                color: 'white',
              }}
            >
              {totalVND.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center', width: 100 }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
            <td style={{ fontSize: 17, fontWeight: 600, marginLeft: 10, textAlign: 'center' }}></td>
          </tr>
        </tbody>
      </table>

      <div className="skd-user-info-export-container">
        <div className="skd-user-info-export-header">
          <h3>Export report</h3>
          <MdOutlineClear
            className="w-[30px] h-[30px] rounded-[10px] bg-slate-100 flex items-center justify-center cursor-pointer hover:bg-slate-200 active:bg-slate-300"
            onClick={() => setDisplayExport(false)}
          />
        </div>
        <div className="skd-user-info-export-name">
          <p className="text-[13px]">Export file name:</p>
          <input
            type="text"
            placeholder={`${customerDetail.exporter}'s report ${startDay} to ${endDay} `}
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
          />
          {fileName !== '' && (
            <MdOutlineClear
              fontSize="small"
              className="w-[25px] h-[25px] flex items-center justify-center absolute right-2.5 cursor-pointer mt-[-30px]"
              onClick={() => setFileName('')}
            />
          )}
          <p style={{ fontSize: 12, marginTop: 5 }}>File name should not has any space</p>
          <p className="text-[13px] text-slate-500">
            {finalShipment?.length} shipments found from{' '}
            <span className="italic text-[darkblue]">{customerDetail?.exporter}</span>
          </p>
        </div>
        <div className="skd-user-info-export-select">
          <h5>Export as:</h5>
          <div
            className="skd-user-info-export-select-option"
            onClick={() => {
              setExportStatus('Raw');
              setGetExcel([]);
              setGetPdf([]);
            }}
          >
            <input type="radio" id="raw" name="export-group" checked={exportStatus === 'Raw' ? true : false} />
            <label style={{ fontSize: 13, display: 'flex', alignItems: 'center' }} for="raw">
              Raw data table (.xlsx)
            </label>
          </div>
          <div
            className="skd-user-info-export-select-option"
            onClick={() => {
              setExportStatus('Debit Note');
              setGetExcel(finalShipment);
              setGetPdf([]);
            }}
          >
            <input
              type="radio"
              id="debit-note"
              name="export-group"
              checked={exportStatus === 'Debit Note' ? true : false}
            />
            <label style={{ fontSize: 13, display: 'flex', alignItems: 'center' }} for="debit-note">
              Debit note (.xlsx)
            </label>
          </div>

          <div
            className="skd-user-info-export-select-option"
            onClick={() => {
              setExportStatus('CSV');
              setGetExcel([]);
              setGetPdf([]);
            }}
          >
            <input type="radio" id="csv" name="export-group" checked={exportStatus === 'CSV' ? true : false} />
            <label style={{ fontSize: 13, display: 'flex', alignItems: 'center' }} for="csv">
              CSV (.csv)
            </label>
          </div>
          <div
            className="skd-user-info-export-select-option"
            onClick={() => {
              setExportStatus('PDF');
              setGetPdf(finalShipment);
              setGetExcel([]);
            }}
          >
            <input type="radio" id="pdf" name="export-group" checked={exportStatus === 'PDF' ? true : false} />
            <label style={{ fontSize: 13, display: 'flex', alignItems: 'center' }} for="pdf">
              PDF (.pdf)
            </label>
          </div>
        </div>
        <div className="skd-user-info-export-select">
          <h5>Export Option:</h5>
          <div
            className="skd-user-shipment-last-header"
            style={{ padding: 10, cursor: 'pointer' }}
            onClick={() => {
              includeInfo ? setIncludeInfo(false) : setIncludeInfo(true);
            }}
          >
            <input type="checkbox" style={{ border: '1px solid lightgray' }} checked={includeInfo ? true : false} />
            <p style={{ marginLeft: 9, fontSize: 13 }}>Ascendance data ordering</p>
          </div>
        </div>
        <div className="skd-user-info-export-button flex items-center">
          <div className="skd-user-info-export-button-cancel" onClick={() => setDisplayExport(false)}>
            <h5>Cancel</h5>
          </div>
          {exportStatus === 'Raw' && (
            <button
              type="button"
              className="p-1.5 bg-sky-600 text-white font-semibold px-3 rounded-[5px] ml-1.5 hover:bg-sky-700 active:bg-sky-800"
              onClick={handleExportExcel}
            >
              Export
            </button>
          )}
          {exportStatus === 'Debit Note' && (
            <div
              onClick={() => {
                setDisplayExport(false);
                setExportStatus('Raw');
                setGetExcel([]);
                setFileName('');
              }}
            >
              <ReactToExcel
                className="p-1.5 bg-sky-600 text-white font-semibold px-3 rounded-[5px] ml-1.5 hover:bg-sky-700 active:bg-sky-800"
                table="export-table"
                filename={excelFileName || `${exporterName} debit from ${startDay} to ${endDay}`}
                sheet="sheet"
                buttonText="Export"
              />
            </div>
          )}
          {exportStatus === 'CSV' && (
            <CSVLink
              {...debitExport}
              className="p-1.5 bg-sky-600 text-white font-semibold px-3 rounded-[5px] ml-1.5 hover:bg-sky-700 active:bg-sky-800"
            >
              Export
            </CSVLink>
          )}
          {exportStatus === 'PDF' && (
            <button
              type="button"
              className="p-1.5 bg-sky-600 text-white font-semibold px-3 rounded-[5px] ml-1.5 hover:bg-sky-700 active:bg-sky-800"
              onClick={generatePDF}
            >
              Export
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
